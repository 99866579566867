import { EnumsService } from './enums.service';
import { Injectable } from '@angular/core';
import { ISelectorAvailability, ISiteMap } from '../interfaces/IAppConfig';

@Injectable()
export class AppConfigService {

  // list of active page where only the branch selector should be available
  public selectorAvailability: ISelectorAvailability;

  public siteMapConfig: ISiteMap;

  constructor(
    private enumsService: EnumsService
  ) {
    this.selectorAvailability = {
      onlyBranch: [],
      onlyEnterprise: [],
      bothBranchAndEnterprise: [],
      neitherBranchOrEnterprise: [],
    };

    this.siteMapConfig = {
      analytics: {
        selector: '',
        displayName: 'Analytics',
        reports: {
          selector: this.enumsService.AnaliyticsPages.newReports,
          displayName: 'Reports',
        },
        dashboard: {
          selector: this.enumsService.AnaliyticsPages.dashboardComponent,
          displayName: 'Dashboard',
        },
      }
    };
  }


}
