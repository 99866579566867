import module from './module';
export default module.directive('dirApplyIndicator', function() {
  return {
    restrict: 'E',
    scope: {
      job: '='
    },
    templateUrl: 'app/views/templates/apply_indicator.html'
  };
});
