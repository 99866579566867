import angular from 'angular';
import module from './module';
import moment from 'moment-timezone';

export default module.factory('Session', [
  '$http',
  'BASE_URL',
  '$q',
  'DOMAIN',
  'FormatHelperService',
  'TimeFormatService',
  function ($http, BASE_URL, $q, DOMAIN, FormatHelperService, TimeFormatService) {
    function Session(sessionData, ent_id) {
      if (sessionData) {
        this.setData(sessionData, ent_id);
      }
    }

    function setAttribDisplayInfo(attrib) {
      switch (attrib.widget_type) {
        case 1: // standard text
          attrib.display_value = attrib.value_text || 'N/A';
          break;
        case 3: // single list
          var values = [];
          if (attrib.choice_list) {
            values = attrib.choice_list.filter(function (obj) {
              return obj.selected == true;
            });
          } else if (attrib.value_list) {
            values = attrib.value_list.filter(function (obj) {
              return obj.selected == true;
            });
          }
          var displayValues = values.map(function (obj) {
            return obj.name;
          });
          attrib.display_value = displayValues.join(', ') || 'N/A';
          break;
        case 4: // multi list
        case 15:
          var values = attrib.value_list.filter(function (obj) {
            return obj.selected == true;
          });
          var displayValues = values.map(function (obj) {
            return obj.name;
          });
          attrib.display_value = displayValues.join(', ') || 'N/A';
          break;
        case 5: // date (month, day, year)
          break;
        case 6: // time (hour and minute of day)
          break;
        case 7: // date and time
          var formated_date_time = moment
            .utc(attrib.value_text, TimeFormatService.format('fullDateTimeNoSeparator'))
            .local()
            .format(TimeFormatService.format('dateTimeLong'));
          var guess = moment.tz.guess();
          if (guess) {
            const abbr = moment.tz(guess).zoneAbbr();

            formated_date_time += ` ${abbr}`;
          }
          attrib.display_value = formated_date_time;
          break;
        case 8: // Standard Image/Picture
          attrib.display_value = attrib.value_text ? DOMAIN + attrib.value_text : null;
          break;
        case 9: // boolean flag
          attrib.display_value = attrib.value_flag ? 'Yes' : 'No';
          break;
        case 10: // count (integer)
          if (attrib.value_count) {
            attrib.display_value = FormatHelperService.returnDurationDisplayVal(attrib.value_count);
          } else {
            attrib.display_value = 'N/A';
          }

          if (attrib.name === 'requester_issue_late_arrival') {
            attrib.display_name = 'Late Arrival';
          }
          break;
        case 11: // location
          if (Array.isArray(attrib.value_location)) {
            var value = attrib.value_location.filter(function (obj) {
              return obj.selected == true;
            })[0];
            attrib.display_value = value.name;
          } else {
            attrib.display_value = (attrib.value_location || {}).name;
          }
          break;
        case 12: // meeting place
          if (Array.isArray(attrib.value_place)) {
            var value = attrib.value_place.filter(function (obj) {
              return obj.selected == true;
            })[0];
            if (value && value.name) {
              attrib.display_value = value.name;
            } else {
              attrib.display_value = 'N/A';
            }
          } else {
            attrib.display_value = (attrib.value_place || {}).name || 'N/A';
          }
          break;
        case 13: // Standard Decimal (16 digits with 2 decimal places)
          attrib.display_value = attrib.value_decimal ? attrib.value_decimal : 'N/A';
          break;
        case 14: // Gender Single Select
          var value;
          if (attrib.choice_list) {
            value = attrib.choice_list.filter(function (obj) {
              return obj.selected == true;
            })[0];
          } else {
            value = attrib.value_list.filter(function (obj) {
              return obj.selected == true;
            })[0];
          }
          attrib.display_value = (value || {}).name || 'N/A';
          break;
        case 17: // Guideline Image
          attrib.display_value = attrib.value_text ? DOMAIN + attrib.value_text : 'N/A';
          break;
        case 18: // Count (Integer) with Plus (+) and Minus (-)
          attrib.display_value = attrib.value_count ? attrib.value_count + ' mins' : 'N/A';
          break;
        case 19: // Count (Integer) for Rating
          attrib.display_value = attrib.value_count ? attrib.value_count + ' star rating' : 'N/A';
          break;
        case 20: // Count (Integer) for Length of Time (in minutes)
          attrib.display_value = attrib.value_count ? attrib.value_count + ' mins' : 'N/A';
          break;
        case 21: // Phone Number
          break;
        case 22: // Email Address
          break;
        case 33: // image
          attrib.display_value = attrib.value_text;
          break;
      } // end of switch
    }

    function setAttribValues(attrib_list) {
      angular.forEach(attrib_list, function (attrib) {
        setAttribDisplayInfo(attrib);
        if (attrib.child_attribs) {
          angular.forEach(attrib.child_attribs, function (childAttrib) {
            setAttribDisplayInfo(childAttrib);
          });
        }
      });
    }

    function setTypeName(type) {
      if (type == 1) {
        return 'Scheduled';
      } else if (type == 2) {
        return 'Now';
      } else {
        return 'N/A';
      }
    }

    function getFullNameDisplayVal(first, last) {
      const _val = FormatHelperService.returnFullName(first, last);
      return _val;
    }

    function buildDateTimeDetails(session, sessionData) {
      if (session.attrib_list) {
        const duration = session.attrib_list.filter(function (obj) {
          return obj.name === 'duration';
        })[0];
        duration ? (session.duration = duration.value_count) : (session.duration = 0);
      } else if (session.estimated_duration) {
        session.duration = session.estimated_duration;
      }
    }

    Session.prototype = {
      setData: function (sessionData, ent_id) {
        angular.extend(this, sessionData);
        const _session = this;

        if (sessionData.attrib_list) {
          _session.attrib_list.sort(function (a, b) {
            return a.order - b.order;
          });
          _session.location = sessionData.attrib_list.filter(function (obj) {
            return obj.name === 'location';
          })[0];
          // _session.place = sessionData.attrib_list.filter(function(obj){ return obj.name == 'place';})[0];
          // _session.escalation = sessionData.attrib_list.filter(function(obj){ return obj.name == 'escalation_list';})[0];
        }

        // For getting start and end times
        buildDateTimeDetails(_session, sessionData);

        // NEEDED For table sorting and filtering in que list
        _session.start_date_time = this.request_date + ' ' + this.request_time;
        _session.typeName = setTypeName(sessionData.type);
        _session.req_full_name = getFullNameDisplayVal(sessionData.req_first_name, sessionData.req_last_name);
        _session.serv_full_name = getFullNameDisplayVal(sessionData.serv_first_name, sessionData.serv_last_name);
        _session.tracking = sessionData.tracking;

        // Need this for setting geocoords on google maps
        if (_session.location) {
          if (Array.isArray(_session.location.value_location)) {
            _session.location.value_location = _session.location.value_location.filter(function (obj) {
              return obj.selected == true;
            })[0];
            _session.location_id = _session.location.value_location.location_id;
          } else {
            // value location is object
            _session.location_id = _session.location.value_location.location_id;
          }
        }

        ent_id && (_session.enterprise_id = ent_id);
        sessionData.attrib_list && setAttribValues(sessionData.attrib_list);
        sessionData.key_attrib_info && setAttribDisplayInfo(sessionData.key_attrib_info);
        _session.time_rule_info = sessionData.time_rule_info;
      },
      displayAttribValue: function (attrib) {
        const attribObj = this.attrib_list.filter(function (obj) {
          return obj.name.toLowerCase() === attrib.toLowerCase();
        })[0];
        if (attribObj && attribObj.display_value) {
          return attribObj.display_value;
        } else {
          return 'N/A';
        }
      },
    };

    return Session;
  },
]);
