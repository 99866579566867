import { environment } from './../environment';

export default {
  BASE_URL: `https://${environment.BASE_URL}/api`,
  ADMIN_API: `https://${environment.BASE_URL}/admin-api`,
  DOMAIN: `https://${environment.BASE_URL}`,
  WEB_URL: environment.WEB_URL || undefined,
  DISPLAY_WEB_URL: environment.DISPLAY_WEB_URL,
  APP_TOKEN: 'bc516c3fc075489aaafdfb8b9df0df4f',
  WS_URL: `wss://${environment.BASE_URL}/ws/`,
  ANALYTICS_CODE: 'UA-99869532-1',
  PENTAHO_URL: environment.PENTAHO_URL,
};
