import { Injectable } from '@angular/core';
import { Observable, Observer, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { SecureHttpService } from './secure.http.service';
import { CommonSelectors } from '../store/selectors';
import { EnumsService } from './enums.service';

const fallbackName = 'Key Attribute';

@Injectable()
export class GeneralDataService {
  constructor(
    private http: SecureHttpService,
    private commonSelectors: CommonSelectors,
    private enumsService: EnumsService
  ) {}

  fetchKeyAttribute(dataContent) {
    return this.commonSelectors.getAttribsForRealm(this.enumsService.Realms.Service).pipe(
      switchMap((response) => {
        if (response.success) {
          let attrib = response.attrib_list.find((a) => a.name === dataContent);
          if (attrib) {
            return of(attrib.display_name);
          }
        }
        return of(fallbackName);
      })
    );
  }

  getRealmAttribs(realm_id: number, enterprise_id?: number) {
    return this.http
      .post('get_realm_attribs', {
        realm_list: [{ realm: realm_id }],
        enterprise_id,
      })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.attrib_list;
          }
        })
      );
  }

  getKeyAttribute() {
    return new Observable<any>((obs: Observer<any>) => {
      let rawData = localStorage.getItem('ngStorage-keyAttribute');
      if (rawData) {
        try {
          let parsed = JSON.parse(rawData);
          if (!parsed.display_name) {
            this.fetchKeyAttribute(parsed.data_content).subscribe((value) => {
              obs.next({
                name: value,
                value: parsed.data_content,
              });
              obs.complete();
            });
          } else {
            obs.next({
              name: parsed.display_name,
              value: parsed.data_content,
            });
            obs.complete();
          }
        } catch (e) {
          obs.next({
            name: fallbackName,
            value: null,
          });
        }
      }
    });
  }

  getKeyAttributeSync() {
    let rawData = localStorage.getItem('ngStorage-keyAttribute');
    if (rawData) {
      try {
        return JSON.parse(rawData);
      } catch (e) {
        return null;
      }
    }
  }

  getKeySession() {
    return new Observable<any>((obs: Observer<any>) => {
      let rawData = localStorage.getItem('ngStorage-generalData');
      if (rawData) {
        try {
          let parsed = JSON.parse(rawData);
          if (parsed && parsed.length) {
            let value = parsed.find((x) => x.data_name === 'key_session');
            if (value && value.data_content) {
              obs.next(value.data_content);
              obs.complete();
              return;
            }
          }
          obs.next('Session');
          obs.complete();
        } catch (e) {
          obs.next('Session');
          obs.complete();
        }
      }
    });
  }

  getKeyProfession() {
    return new Observable<any>((obs: Observer<any>) => {
      let rawData = localStorage.getItem('ngStorage-generalData');
      if (rawData) {
        try {
          let parsed = JSON.parse(rawData);
          if (parsed && parsed.length) {
            let value = parsed.find((x) => x.data_name === 'key_profession');
            if (value && value.data_content) {
              obs.next(value.data_content);
              obs.complete();
              return;
            }
          }
          obs.next('Worker');
          obs.complete();
        } catch (e) {
          obs.next('Worker');
          obs.complete();
        }
      }
    });
  }

  readKeyAttributeValue(attribList, keyAttribName) {
    const keyAttrib = attribList.find((x) => x.name === keyAttribName);
    if (keyAttrib) {
      switch (keyAttrib.type) {
        case 'single_list':
        case 'multi_list':
          return keyAttrib.value_list?.length ? keyAttrib?.value_list[0]?.name : '';
        case 'text':
          return keyAttrib.value_text;
        default:
          return '';
      }
    } else {
      return '';
    }
  }
}
