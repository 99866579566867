import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, map } from 'rxjs';

@Component({
  templateUrl: './smart-link-page.component.html',
})
export class SmartLinkPage implements OnInit {
  public type$: Observable<'workn' | 'avionte' | 'info'>;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.type$ = this.route.queryParams.pipe(map((p) => (!!p['error'] ? p['error'] : 'info')));
  }
}
