import { NgModule, forwardRef, ErrorHandler } from '@angular/core';
import { UpgradeAdapter } from '@angular/upgrade';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  sessionServiceProvider,
  providerServiceProvider,
  enterpriseFilterServiceProvider,
} from '../upgraded.providers';
import { AdminUpgradeDirective } from './directives/admin-upgrade.directive';
import { AdminPageComponent } from './pages/admin.page';

@NgModule({
  imports: [BrowserModule, BrowserAnimationsModule, FormsModule, ReactiveFormsModule, HttpClientModule],
  declarations: [AdminUpgradeDirective, AdminPageComponent],
  entryComponents: [],
  providers: [sessionServiceProvider, providerServiceProvider, enterpriseFilterServiceProvider],
})
export class AdminModule {
  constructor() {}
}
