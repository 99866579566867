import * as angular from 'angular';
import * as moment from 'moment';
import { Component, ChangeDetectionStrategy, OnInit, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Observable, of } from 'rxjs';
import { BirdsEyeService } from '../../services/birds-eye.service';
import { BirdsEyeModel } from '../../../shared/models/birds-eye';
import Swal from 'sweetalert2';
import { catchError, map, switchMap, take } from 'rxjs/operators';

enum BirdsEyeView {
  Weekly = 'weekly',
  Monthly = 'monthly',
}

@Component({
  templateUrl: './birds-eye.page.html',
  styleUrls: ['./birds-eye.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BirdsEyePage implements OnInit {
  public orderId: string;
  public model$: Observable<BirdsEyeModel>;
  public view = BirdsEyeView.Weekly;
  public sessions: { [key: number]: number[] } = {};
  public mode: 'schedule' | 'offer' = 'schedule';

  @Input() jobId: string;
  @Input() jobInstance: string;
  @Input() closeCallback: any;

  BirdsEyeView = BirdsEyeView;

  constructor(private birdsEyeService: BirdsEyeService) {}

  ngOnInit() {
    this.model$ = this.birdsEyeService.fetchOrder(this.jobId, this.jobInstance);
  }

  public getJobDates(model: BirdsEyeModel): string {
    const startDate = moment(model.job_start_date, 'YYYY-MM-DD');
    const endDate = moment(model.job_end_date, 'YYYY-MM-DD');

    let startText = startDate.format('MMM D');
    let endText = endDate.format('Do');

    if (!startDate.isSame(endDate, 'month')) {
      endText = endDate.format('MMM Do');
    }

    return `${startText}-${endText}`;
  }

  public setView(view: BirdsEyeView) {
    this.view = view;
  }

  public sessionsChanged(sessions: { [key: number]: number[] }) {
    this.sessions = { ...sessions };
  }

  public submit() {
    let rotated = {};
    Object.keys(this.sessions).forEach((sessionId) => {
      this.sessions[sessionId].forEach((providerId) => {
        const existing = rotated[providerId] || [];
        rotated[providerId] = [...existing, sessionId];
      });
    });

    of({})
      .pipe(
        switchMap(() => {
          if (this.mode === 'offer') {
            return this.birdsEyeService.offer(this.sessions);
          } else {
            return this.birdsEyeService.schedule(rotated);
          }
        }),
        take(1),
        map((res: any) => {
          if (res.success) {
            return Swal.fire({
              title: 'Success',
              text: res.message || 'Submit succesful.',
              icon: 'success',
              timer: 2000,
              showConfirmButton: false,
            }).then(() => {
              this.closeCallback && this.closeCallback.doClose();
            });
          } else {
            return Swal.fire({
              title: 'Error',
              text: res.message || 'There was an error submitting.',
              icon: 'error',
              timer: 2000,
              showConfirmButton: false,
            });
          }
        }),
        catchError((err) => {
          return Swal.fire({
            title: 'Error',
            text: err.message || 'There was an error submitting.',
            icon: 'error',
            timer: 2000,
            showConfirmButton: false,
          });
        })
      )
      .subscribe();
  }

  public submitEnabled() {
    return this.sessions && Object.keys(this.sessions).some((key) => this.sessions[key].length);
  }

  public goBack() {
    this.closeCallback && this.closeCallback.doClose();
  }
}

angular.module('ServetureWebApp').directive('birdsEye', downgradeComponent({ component: BirdsEyePage }));
