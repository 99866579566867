import { Pipe, PipeTransform } from '@angular/core';
import Env from '../../scripts/env.js';

@Pipe({
  name: 'workerPicture',
})
export class WorkerProfilePicturePipe implements PipeTransform {
  constructor() {}

  transform(value: string) {
    if (value) {
      return Env.DOMAIN + value;
    }
    return 'assets/images/avatar.png';
  }
}
