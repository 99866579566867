import module from '../module';
export default module.controller('BulkLoadedModalController', [
  '$scope',
  '$modalInstance',
  '$modal',
  'adminUserService',
  'enterprise_selection',
  'branch_selection',
  'branchFilterService',
  'AuthenticationService',
  'roleManagerService',
  'EnterpriseFilterService',
  'WEB_URL',
  function (
    $scope,
    $modalInstance,
    $modal,
    adminUserService,
    enterprise_selection,
    branch_selection,
    branchFilterService,
    AuthenticationService,
    roleManagerService,
    EnterpriseFilterService,
    WEB_URL
  ) {
    $scope.initialize = async function () {
      $scope.CURRENT_USER = AuthenticationService.getCurrentUser();

      $scope.admins = await adminUserService.fetchBulkloadedAdmin();
      $scope.selection = [];

      var enterpriseData = $scope.getEnterpriseData();
      $scope.showBranches = !enterpriseData.enterprise && (!enterpriseData.branch || enterpriseData.branch === -1);
      $scope.formData = {
        enterprise: enterpriseData.enterprise,
        marketplace: enterpriseData.marketplace,
        branch: enterpriseData.branch,
        selection: [],
        webUrl: window.location.host.indexOf('localhost') > -1 ? WEB_URL : window.location.host,
      };

      $scope.branches = await branchFilterService.fetchBranches();
      $scope.branches = $scope.branches?.filter((b) => b.id !== -1);

      roleManagerService.getAllRoles().then(function (list) {
        if (enterprise_selection) {
          $scope.roles = EnterpriseFilterService.filterManageableItems(angular.copy(list));
        } else {
          $scope.roles = angular.copy(list);
        }
        $scope.showSpinner = false;
      });
    };

    $scope.ok = function () {
      const modalInstance = $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/views/admin/user/bulkInviteWarning.html',
        size: 'lg',
        backdrop: 'static',
        controller: 'BulkInviteWarningController',
        resolve: {
          passedData: function () {
            return $scope.formData;
          },
          enterprise_selection: function () {
            return enterprise_selection;
          },
          branch_selection: function () {
            return branch_selection;
          },
        },
      });

      modalInstance.result.then(function (selectedItem) {
        $modalInstance.close();
      });
    };

    $scope.getEnterpriseData = function () {
      var passedEnt = enterprise_selection || {
        enterprise_id: $scope.CURRENT_USER.enterprise_info && $scope.CURRENT_USER.enterprise_info.id,
      };

      var enterprise, marketplace, branch;

      if (!passedEnt.enterprise_id || passedEnt.enterprise_id === -1) {
        enterprise = null;
      } else {
        enterprise = passedEnt.enterprise_id;
      }

      return {
        enterprise,
        marketplace: $scope.CURRENT_USER.user_type == 2 ? $scope.CURRENT_USER.market_place_info.id : null,
        branch: branch_selection && branch_selection.id,
      };
    };

    $scope.toggleSelection = function (id) {
      var idx = $scope.formData.selection.indexOf(id);

      // Is currently selected
      if (idx > -1) {
        $scope.formData.selection.splice(idx, 1);
      }

      // Is newly selected
      else {
        $scope.formData.selection.push(id);
      }
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.initialize();
  },
]);
