import module from '../module';
export default module.controller('SupportController', [
  '$scope',
  'AuthenticationService',
  function ($scope, AuthenticationService) {
    const CURRENT_USER = AuthenticationService.getCurrentUser();
    $scope.isMarketplace = false;
    $scope.pageTitle = 'Support';

    const setSupportNumber = (function () {
      if (CURRENT_USER.user_type == 3) {
        $scope.support_number = CURRENT_USER.enterprise_info.support_phone;
      } else if (CURRENT_USER.user_type == 2) {
        $scope.isMarketplace = true;
        $scope.support_number = CURRENT_USER.market_place_info.support_phone;
      } else if (CURRENT_USER.user_type == 5) {
        $scope.support_number = CURRENT_USER.enterprise_info.support_phone;
      }
    })();
  },
]);
