import angular from 'angular';
import module from '../module';
import * as enums from '../../enums';
export default module.controller('NewJobModalController', [
  '$scope',
  '$modalInstance',
  'AlertService',
  'jobManagerService',
  'enterprise_selection',
  'FormatHelperService',
  'AuthenticationService',
  '$modal',
  'sessionManagerService',
  function (
    $scope,
    $modalInstance,
    AlertService,
    jobManagerService,
    enterprise_selection,
    FormatHelperService,
    AuthenticationService,
    $modal,
    sessionManagerService
  ) {
    const CURRENT_USER = AuthenticationService.getCurrentUser();
    $scope.submitBtnDisbaled = false;
    $scope.title = 'Add Job Template';
    $scope.type = 'new';
    $scope.formData = {
      name: '',
      desc: '',
    };
    $scope.entFilter = angular.copy(enterprise_selection);
    let locationValueWatcher;

    if (CURRENT_USER.user_type == 2) {
      $scope.submitAndScheduleJobsBtn = false;
    } else {
      $scope.submitAndScheduleJobsBtn = true;
    }

    // Get Dynamic Job Attribs Realm 16
    sessionManagerService.getRealmAttribs(enums.Realms.JobCreation).then(
      function (response) {
        if (response.success) {
          const formData = response.attrib_list.sort(function (a, b) {
            return a.order - b.order;
          });
          $scope.originalFormData = angular.copy(formData);
          $scope.attrib_list = angular.copy(formData);
          $scope.locationAttrib = $scope.attrib_list.find((x) => x.type === 'location');
          $scope.locationAttrib.type_flags.other_required = true;
        } else {
          AlertService.errorAlert(response);
          $modalInstance.close();
        }
      },
      function (reason) {
        AlertService.serverRequestErrorAlert(reason);
        $modalInstance.close();
      }
    );

    // modal controls
    $scope.ok = async function (action) {
      $scope.submitBtnDisbaled = true;
      // Market place admin
      if (CURRENT_USER.user_type == 2) {
        $scope.formData.market_place_id = CURRENT_USER.market_place_info.id;
        if ($scope.entFilter.enterprise_id != -1) {
          $scope.formData.enterprise_id = $scope.entFilter.enterprise_id;
        }
      }
      if ($scope.locationAttrib.value?.location_id === 0) {
        try {
          const result = await $scope.handleOtherLocation($scope.locationAttrib.value);
          if (!result) {
            $scope.submitBtnDisbaled = false;
            return;
          } else {
            $scope.locationAttrib.value.location_id = result.location_id;
          }
        } catch (e) {
          $scope.submitBtnDisbaled = false;
          return;
        }
      }
      //format dynamic attribs
      const formatedAttribList = FormatHelperService.format_data($scope.attrib_list);
      if (formatedAttribList.length > 0) {
        $scope.formData.attrib_list = formatedAttribList;
      }
      sendToServer($scope.formData, action);
    };

    $scope.handleOtherLocation = async function (location) {
      const modalInstance = $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/views/admin/saveJobLocationModal.html',
        size: 'lg',
        backdrop: 'static',
        controller: 'SaveJobLocationModal',
        resolve: {
          passedData: function () {
            return { location };
          },
          enterprise_selection: function () {
            return $scope.entFilter;
          },
        },
      });

      return await modalInstance.result;
    };

    const sendToServer = function (jobObj, action) {
      if ($scope.entFilter.enterprise_id != -1) {
        jobObj.enterprise_id = $scope.entFilter.enterprise_id;
      }

      if (CURRENT_USER.market_place_info) {
        jobObj.market_place_id = CURRENT_USER.market_place_info.id;
      }

      jobManagerService.submitJobRequest(jobObj).then(
        function (response) {
          if (response.success) {
            response.followOnAction = action;
            AlertService.successAlert(response.message);
            $modalInstance.close(response);
          } else {
            AlertService.errorAlert(response.message);
            $modalInstance.dismiss('cancel');
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.dismiss('cancel');
        }
      );
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    // listen for instance_count attrib clicks
    $scope.$on('addAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      const _count = field.instanceCountIndex || 1;
      const _field = angular.copy(
        $scope.originalFormData.filter(function (obj) {
          return obj.attrib_id == field.attrib_id;
        })[0]
      );
      _field.instanceCountIndex = _count + 1;
      _field.showRemoveInstance = true;
      $scope.attrib_list[index].doNotShowAddInstance = true;
      $scope.attrib_list[index].showRemoveInstance = false;
      $scope.attrib_list.splice(index + 1, 0, _field); // add after current field
    });

    $scope.$on('removeAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      $scope.attrib_list[index - 1].doNotShowAddInstance = false;
      $scope.attrib_list[index - 1].showRemoveInstance = true;
      $scope.attrib_list.splice(index, 1);
    });

    $scope.$on('$destroy', function () {
      if (locationValueWatcher) {
        locationValueWatcher();
      }
    });
    ////////////////////////////////////////////////////////////////////////////////
  },
]);
