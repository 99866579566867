import module from '../module';
export default module.controller('SessionController', [
  '$scope',
  '$localStorage',
  'EnterpriseFilterService',
  'AlertService',
  'sessionManagerService',
  'AuthenticationService',
  'commonSelectors',
  function (
    $scope,
    $localStorage,
    EnterpriseFilterService,
    AlertService,
    sessionManagerService,
    AuthenticationService,
    commonSelectors
  ) {
    var keyProfession = $localStorage.generalData.filter(function (obj) {
      return obj.data_name == 'key_profession';
    })[0];

    $scope.headFilter = ['', keyProfession.data_content, 'Requester', 'Statuses', 'Type'];
    $scope.data = {
      selectedFilter: '',
      columnFilter: '',
    };
    $scope.showSpinner = true;
    $scope.mainSessionDiv = true;
    $scope.sessionListDiv = true;
    $scope.notificationSessionDiv = true;

    var CURRENT_USER = AuthenticationService.getCurrentUser();

    var notificationPermission = CURRENT_USER.permissionCheck(1);
    var approvalPermission = CURRENT_USER.permissionCheck(2);
    var providerTabPermission = CURRENT_USER.permissionCheck(12);
    var sessionListPermission = CURRENT_USER.permissionCheck(3);
    let websocketMessagesSubscription;

    if (!notificationPermission && !approvalPermission) {
      $scope.notificationSessionDiv = false;
    }
    if (!sessionListPermission) {
      $scope.sessionListDiv = false;
    }

    $scope.local = {
      entFilter: null,
    };

    var setDisplay = function () {
      if ($scope.local.entFilter.enterprise_id == -1) {
        $scope.sessionListDiv = false;
        $scope.notificationSessionDiv = false;
      } else {
        $scope.sessionListDiv = true;
        $scope.notificationSessionDiv = true;
        getAllSessionRequests();
      }
    };
    ////////////////////////////////////////////////////////////////////////////
    // Grab the selected enterprise when first come to provider tab
    if (CURRENT_USER.user_type == 2) {
      $scope.scheduleActions = false;
      $scope.$watch('entFilter', function (newVal, oldVal) {
        $scope.local.entFilter = EnterpriseFilterService.fetchSelectedEnterprise();
        setDisplay();
        if (websocketMessagesSubscription) {
          websocketMessagesSubscription.unsubscribe();
        }
        websocketMessagesSubscription = commonSelectors
          .getWebsocketMessagesForEnterprise($scope.local.entFilter.enterprise_id)
          .subscribe((messages) => {
            $scope.notifications = messages;
          });
      });
    } else {
      $scope.scheduleActions = true;
      $scope.local.entFilter = EnterpriseFilterService.fetchSelectedEnterprise();
      setDisplay();
      if (websocketMessagesSubscription) {
        websocketMessagesSubscription.unsubscribe();
      }
      websocketMessagesSubscription = commonSelectors
        .getWebsocketMessagesForEnterprise($scope.local.entFilter.enterprise_id)
        .subscribe((messages) => {
          $scope.notifications = messages;
        });
    }
    ////////////////////////////////////////////////////////////////////////////
    //Filters
    $scope.removeDups = function (list) {
      var result = [];
      $.each(list, function (i, e) {
        if ($.inArray(e, result) == -1) result.push(e);
      });
      return result.sort();
    };
    $scope.getFilters = function (sessionsArray) {
      try {
        var allRequesterFilter = sessionsArray.map(function (obj) {
          return obj.req_full_name;
        });
        $scope.requesterFilter = $scope.removeDups(allRequesterFilter);
        var allProviderFilter = sessionsArray.map(function (obj) {
          return obj.serv_full_name;
        });
        $scope.providerFilter = $scope.removeDups(allProviderFilter);
        var allTypeFilter = sessionsArray.map(function (obj) {
          return obj.typeName;
        });
        $scope.typeFilter = $scope.removeDups(allTypeFilter);
        var allStatusesFilter = sessionsArray.map(function (obj) {
          return obj.status.name;
        });
        $scope.statusesFilter = $scope.removeDups(allStatusesFilter);
      } catch (e) {}
      $scope.enableFilter = true;
    };
    $scope.changeHeaderFilter = function (selected) {
      switch (selected) {
        case '':
          $scope.selectedColumnFilter = '';
          break;
        case 'Requester':
          $scope.selectedColumnFilter = $scope.requesterFilter;
          break;
        case keyProfession.data_content:
          $scope.selectedColumnFilter = $scope.providerFilter;
          break;
        case 'Type':
          $scope.selectedColumnFilter = $scope.typeFilter;
          break;
        case 'Statuses':
          $scope.selectedColumnFilter = $scope.statusesFilter;
          break;
      }
    };
    $scope.resetFilters = function () {
      $scope.data.columnFilter = '';
      $scope.data.selectedFilter = '';
      $scope.selectedColumnFilter = '';
    };
    ////////////////////////////////////////////////////////////////////////////
    // Grab session list
    function getAllSessionRequests() {
      $scope.showSpinner = true;
      sessionManagerService.getAllSessions($scope.local.entFilter.enterprise_id).then(
        function (sessions) {
          $scope.showSpinner = false;
          if (sessions) {
            $scope.sessionsArray = sessions;
            $scope.getFilters($scope.sessionsArray);
          } else {
            $scope.waitingApproval = new Array();
            $scope.sessionsArray = new Array();
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $scope.showSpinner = false;
        }
      );
    }

    $scope.$on('$destroy', function () {
      if (websocketMessagesSubscription) {
        websocketMessagesSubscription.unsubscribe();
      }
    });
  },
]);
