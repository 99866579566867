import module from './module';
export default module.controller('WorkersController', [
  '$window',
  '$scope',
  '$rootScope',
  'AuthenticationService',
  'EnterpriseFilterService',
  'branchFilterService',
  'passedData',
  '$modalInstance',
  function (
    $window,
    $scope,
    $rootScope,
    AuthenticationService,
    EnterpriseFilterService,
    branchFilterService,
    passedData,
    $modalInstance
  ) {
    $scope.checkTabPermission = function () {
      const CURRENT_USER = AuthenticationService.getCurrentUser();
      $scope.isMarketplaceUser = CURRENT_USER.user_type === 2;
    };

    if (passedData) {
      $scope.workerId = passedData.workerId;
      $scope.providerId = passedData.providerId;
    }

    $scope.closeCallback = {
      doClose: function () {
        $modalInstance.close();
      },
    };

    $rootScope.$on('EnterpriseSelectionChanged', $scope.determineState);

    $scope.determineState = function () {
      const branch = branchFilterService.getSelectedBranch();
      const enterprise = EnterpriseFilterService.fetchSelectedEnterprise();
      // console.log(branch, enterprise);

      if (branch) {
        if (branch.id === -1) {
          $scope.selectionType = 'Marketplace';
          $scope.selectionData = '';
        } else {
          $scope.selectionType = 'Branch';
          $scope.selectionData = ` ${branch.name}`;
        }
      }

      if (enterprise) {
        if (enterprise.enterprise_id !== -1 && enterprise.enterprise_id !== -2) {
          $scope.selectionType = 'Enterprise ';
          $scope.selectionData = ` ${enterprise.name}`;
        }
      }
    };

    $scope.checkTabPermission();
    $scope.determineState();
  },
]);
