import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { SecureHttpService } from '../../../shared/services/secure.http.service';

@Injectable()
export class WorkerService {
  private data: any[];
  constructor(private http: SecureHttpService) { }

  loadFromCache(enterpriseId) {
    let cached = localStorage.getItem(`get_service_providers/${enterpriseId}`);
    if (cached) {
      this.data = JSON.parse(cached);
    }
  }

  fetch(enterpriseId = -1) {
    if (enterpriseId === -1) {
      return of([]);
    }
    this.data = null;
    this.loadFromCache(enterpriseId);

    if (this.data) {
      return of(this.data);
    }
    let passedId = enterpriseId === -1 ? 1 : enterpriseId;

    return this.http.post('get_service_providers', { enterprise_id: passedId, pageSize: 100 }).pipe(
      switchMap(response => {
        let result = response;
        localStorage.setItem(`get_service_providers/${enterpriseId}`, JSON.stringify(result));
        return of(result);
      }));
  }

  getWorker(id) {
    return this.http.post('get_profile_info', { user_id: id });
  }
}
