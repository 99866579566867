import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  Worker,
  ProfileField,
  ChoiceItem,
} from '../../../shared/models/worker';

@Component({
  templateUrl: './vetting-status.component.html',
  styleUrls: ['./vetting-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-vetting-status',
})
export class VettingStatusComponent implements OnInit, OnChanges {
  @Input() worker: Worker;
  @Input() canEdit =false
  @Output() updateStatus = new EventEmitter<any>();

  public formGroup: FormGroup;

  private get vettingStatus(): ProfileField | undefined {
    return this.worker?.profile_info.find(
      (x) => x.name === 'provider_vetting_status'
    );
  }

  public get value(): string | undefined {
    return this.vettingStatus?.display_value;
  }

  public get label(): string | undefined {
    return this.vettingStatus?.display_name;
  }

  public get items(): ChoiceItem[] {
    return this.vettingStatus?.choice_list;
  }

  public isUpdate = false;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      vettingStatus: [''],
    });
  }

  ngOnChanges(changes) {
    // Close update mode if the status was changed successfully.
    const previousChoiceId = changes.worker.previousValue?.profile_info
      ?.find((x) => x.name === 'provider_vetting_status')
      ?.choice_list?.find((x) => x.selected)?.choice_id;

    const currentChoiceId = changes.worker.currentValue?.profile_info
      ?.find((x) => x.name === 'provider_vetting_status')
      ?.choice_list?.find((x) => x.selected)?.choice_id;

    if (previousChoiceId !== currentChoiceId) {
      this.isUpdate = false;
    }
  }

  public toggleMode() {
    this.isUpdate = !this.isUpdate;

    if (this.isUpdate) {
      this.formGroup.patchValue({
        vettingStatus: this.vettingStatus.choice_list.find((x) => x.selected)
          .choice_id,
      });
    }
  }

  public submit() {
    const payload = {
      user_id: this.worker.user_id,
      profile_info: [
        {
          attrib_id: this.vettingStatus.attrib_id,
          name: this.vettingStatus.name,
          value_single_list: this.formGroup.value.vettingStatus,
        },
      ],
    };

    this.updateStatus.emit(payload);
  }
}
