import module from '../module';
import * as enums from '../../enums';
import moment from 'moment';

export default module.controller('AssignInterestedProvidersModalController', [
  'sessionManagerService',
  '$scope',
  '$modal',
  '$modalInstance',
  'AlertService',
  'generalDataService',
  'interestService',
  'passedSession',
  'Provider',
  'DOMAIN',
  'providerManagerService',
  'passed_job_order_id',
  'TimeFormatService',
  function (
    sessionManagerService,
    $scope,
    $modal,
    $modalInstance,
    AlertService,
    generalDataService,
    interestService,
    passedSession,
    Provider,
    DOMAIN,
    providerManagerService,
    passed_job_order_id,
    TimeFormatService
  ) {
    $scope.domain = DOMAIN;
    const profession = generalDataService.getKeyProfession();
    $scope.keyProfession = (profession && profession.data_content) || 'Worker';
    $scope.keyAttribute = generalDataService.getKeyAttribute();
    $scope.title = `Schedule Interested ${$scope.keyProfession}s`;
    $scope.okButton = 'Schedule';
    $scope.exportExcelIcon = `${enums.AssetsFolder.images}export-excel.ico`;

    if (passed_job_order_id || passedSession.jobOrderId) {
      $scope.bundled = true;
      if (passedSession) {
        $scope.session = passedSession;
      }
      interestService.showForJobOrder(passed_job_order_id || passedSession.jobOrderId).then(function (result) {
        if (result.success) {
          $scope.providerList = result.provider_list.map((prov) => {
            prov.login_status_code = prov.login_status;
            prov.vetting_status_code = prov.status;
            prov.interest_date = moment
              .utc(prov.notify_date_time)
              .local()
              .format(TimeFormatService.format('interestDate'));
            prov.interest_time = moment.utc(prov.notify_date_time).local().format('hh:mma');
            return prov;
          });
        } else {
          AlertService.errorAlert(result.message);
          $modalInstance.dismiss('cancel');
        }
      });
    } else {
      interestService.showForRequest(passedSession.session_id).then(function (result) {
        if (result.success) {
          $scope.providerList = result.provider_list.map((prov) => {
            prov.login_status_code = prov.login_status;
            prov.vetting_status_code = prov.status;
            prov.interest_date = moment
              .utc(prov.notify_date_time)
              .local()
              .format(TimeFormatService.format('interestDate'));
            prov.interest_time = moment.utc(prov.notify_date_time).local().format('hh:mma');
            return prov;
          });
        } else {
          AlertService.errorAlert(result.message);
          $modalInstance.dismiss('cancel');
        }
      });
    }

    $scope.getProfileUrl = function (picture) {
      if (picture.data) {
        return DOMAIN + picture.data;
      }
      return `${enums.AssetsFolder.images}avatar.png`;
    };

    $scope.loadProvider = function (userId) {
      providerManagerService.getProvider(userId).then(function (provider) {
        const modalInstance = $modal.open({
          animation: true,
          templateUrl: 'app/views/job/providerModal.html',
          controller: 'ProviderModalController',
          size: 'lg',
          backdrop: 'static',
          resolve: {
            provider: function () {
              provider.hidePermissions = true;
              return provider;
            },
          },
        });
        modalInstance.result.then(function () {});
      });
    };

    $scope.checkSelection = function (provider) {
      const totalWorkers = $scope.session ? $scope.session.total_workers : passed_total_worker_count;
      if (provider.selected) {
        const selectedLength = $scope.providerList.filter((x) => x.selected).length;
        if ($scope.bundled && totalWorkers < selectedLength) {
          provider.selected = false;
          AlertService.warningAlert(
            `You are attempting to select more ${$scope.keyProfession}s than required for this job.`
          );
        } else if (!$scope.bundled && selectedLength > 1) {
          provider.selected = false;
          AlertService.warningAlert(
            `You are attempting to select more ${$scope.keyProfession}s than required for this job.`
          );
        }
      }
    };

    $scope.loggedInStatus = function (status_code) {
      if (status_code == 1) {
        return 'logged-in-state';
      } else if (status_code == 0) {
        return 'logged-out-state';
      } else {
        return 'no-logged-in-state';
      }
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.ok = function () {
      const result = $scope.providerList.filter((x) => x.selected).map((x) => x.provider_id);
      if (passedSession.jobOrderId) {
        interestService.assignForJobOrder(passedSession.jobOrderId, result).then(
          function (response) {
            if (response.success) {
              AlertService.successAlert(response.message);
            } else {
              AlertService.errorAlert(response.message);
            }
            $modalInstance.close();
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
            $modalInstance.close();
          }
        );
      } else {
        interestService.assignForRequest(passedSession.session_id, result).then(
          function (response) {
            if (response.success) {
              AlertService.successAlert(response.message);
            } else {
              AlertService.errorAlert(response.message);
            }
            $modalInstance.close();
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
            $modalInstance.close();
          }
        );
      }
    };

    $scope.exportToCsv = function () {
      let result = [];
      result.push(`data:text/csv;charset=utf8,Interested ${$scope.keyProfession}s,`);
      const csvBody = $scope.providerList.map((provider) => provider.full_name);
      result = result.concat(csvBody);
      const csv = result.join('\n');
      $scope.generateDownload(csv);
    };

    $scope.generateDownload = function (dataUrl) {
      const hiddenElement = document.createElement('a');
      hiddenElement.href = encodeURI(dataUrl);
      hiddenElement.download = `interested_${$scope.keyProfession}.csv`;
      hiddenElement.click();
    };

    $scope.getStatus = function (provider, name) {
      const status = provider.profile_info && provider.profile_info.find((x) => x.name === name);
      if (status) {
        const value = status.choice_list.find((x) => x.selected);
        if (value) {
          return {
            code: value.choice_id,
            name: value.name,
          };
        }
      }
    };
  },
]);
