import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Worker } from '../../../shared/models/worker';

@Component({
  templateUrl: './worker-stats.page.html',
  styleUrls: ['./worker-stats.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-worker-stats-page',
})
export class WorkerStatsPage {
  @Input() worker: Worker;
  @Input() providerId: number;
}
