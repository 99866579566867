import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  sessionServiceProvider,
  providerServiceProvider,
  enterpriseFilterServiceProvider,
} from '../upgraded.providers';
import { SupportUpgradeDirective } from './components/support-upgrade.directive';
import { SupportPageComponent } from './pages/support.page';

@NgModule({
  imports: [BrowserModule, BrowserAnimationsModule, FormsModule, ReactiveFormsModule, HttpClientModule],
  declarations: [SupportUpgradeDirective, SupportPageComponent],
  entryComponents: [],
  providers: [sessionServiceProvider, providerServiceProvider, enterpriseFilterServiceProvider],
})
export class SupportingModule {
  constructor() {}
}
