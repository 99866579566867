import module from './module';

export default module.factory('interestService', [
  '$http',
  'BASE_URL',
  '$q',
  function ($http, BASE_URL, $q) {
    function callSvc(endpoint, payload) {
      const deferred = $q.defer();

      $http
        .post(BASE_URL + endpoint, payload)
        .then(function (res) {
          const response = res.data;
          deferred.resolve(response);
        })
        .catch(function () {
          deferred.reject();
        });

      return deferred.promise;
    }

    return {
      showForRequest: function (request_id) {
        return callSvc('/get_interested_providers', { request_id });
      },

      showForJobOrder: function (job_order_id) {
        return callSvc('/get_interested_providers_for_job_order', { job_order_id });
      },

      offerForRequest: function (request_id, providers) {
        return callSvc('/offer_interested_providers', {
          request_id,
          provider_list: providers,
        });
      },

      offerForJobOrder: function (job_order_id, providers) {
        return callSvc('/offer_interested_providers_for_job_order', {
          job_order_id,
          provider_list: providers,
        });
      },

      assignForRequest: function (request_id, providers) {
        return callSvc('/assign_interested_providers', {
          request_id,
          provider_list: providers,
          force_accept: true,
        });
      },

      assignForJobOrder: function (job_order_id, providers) {
        return callSvc('/assign_interested_providers_for_job_order', {
          job_order_id,
          provider_list: providers,
          force_accept: true,
        });
      },

      getBirdsEyeView: function (job_id, job_instance) {
        return callSvc('/get_birdseye_worker_shifts', {
          job_id,
          job_instance,
        });
      },
    };
  },
]);
