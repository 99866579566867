'use strict';

import module from '../module';
import moment from 'moment';
import * as _ from 'lodash';

export default module.factory('adminUserService', [
  '$http',
  'ADMIN_API',
  '$q',
  function ($http, ADMIN_API, $q) {
    return {
      _pool: {},
      _retrieveInstance: function (id, current) {
        let instance = this._pool[id];
        if (instance) {
          Object.assign(instance, current);
        } else {
          instance = current;
          this._pool[id] = instance;
        }
      },

      _loadAllInstances: function (deferred, payload) {
        $http
          .get(ADMIN_API + `/admin-users?branch_id=${payload.branch_id}&ent_id=${payload.ent_id}&page=${payload.page}&size=${payload.size}&order_by=${payload.order_by}&descending=${payload.descending}`)
          .then(function (res) {
            const response = res.data;
            if (response) {
              deferred.resolve(response);
            } else {
              deferred.reject(response.message);
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },

      _loadInstance: function (deferred, id) {
        const scope = this;
        $http
          .get(ADMIN_API + '/admin-users/' + id + '/')
          .then(function (response) {
            const item = response.data;
            if (item) {
              scope._retrieveInstance(item.id, item);
              deferred.resolve(item);
            } else {
              deferred.reject();
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },

      _addInstance: function (deferred, instance) {
        $http
          .post(ADMIN_API + '/admin-users/', instance)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function (response) {
            deferred.reject(response);
          });
      },

      _editInstance: function (deferred, instance) {
        $http
          .patch(ADMIN_API + '/admin-users/' + instance.id + '/', instance)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function (response) {
            deferred.reject(response.data);
          });
      },

      _removeInstance: function (deferred, id) {
        const scope = this;
        $http
          .delete(ADMIN_API + '/admin-users/' + id + '/')
          .then(function () {
            delete scope._pool[id];
            deferred.resolve();
          })
          .catch(function () {
            deferred.reject();
          });
      },

      getAllAdminUsers: function (payload) {
        const deferred = $q.defer();
        this._loadAllInstances(deferred, payload);
        return deferred.promise;
      },

      getAdminUserDetails: function (id) {
        const deferred = $q.defer();
        this._loadInstance(deferred, id);
        return deferred.promise;
      },

      addNewUser: function (user) {
        const deferred = $q.defer();
        this._addInstance(deferred, user);
        return deferred.promise;
      },
      editUser: function (user) {
        const deferred = $q.defer();
        this._editInstance(deferred, user);
        return deferred.promise;
      },
      removeUser: function (id) {
        const deferred = $q.defer();
        this._removeInstance(deferred, id);
        return deferred.promise;
      },
      referAdmin: function (payload) {
        const deferred = $q.defer();
        $http
          .post(ADMIN_API + '/admin-users/refer_admin/', payload)
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              deferred.resolve();
            } else {
              deferred.reject();
            }
          })
          .catch(function () {
            deferred.reject();
          });

        return deferred.promise;
      },
      signup: function (payload) {
        const deferred = $q.defer();
        $http
          .post(ADMIN_API + '/admin-users/signup/', payload)
          .then(function (res) {
            const response = res.data;
            if (response.username) {
              deferred.resolve({
                message: 'Successfully created new user!',
              });
            } else {
              deferred.reject();
            }
          })
          .catch(function (response) {
            if (response?.detail?.indexOf('Not found') > -1) {
              deferred.reject('Invite not found');
            } else if (response?.username) {
              deferred.reject('The username is already in use');
            } else {
              deferred.reject('There was a problem with the invite');
            }
          });

        return deferred.promise;
      },
      getInvites: function () {
        const deferred = $q.defer();
        $http
          .get(ADMIN_API + '/admin-users/referred_users/')
          .then(function (res) {
            const response = res.data;
            if (response && response.invitations) {
              deferred.resolve(
                response.invitations.map((i) => ({
                  email: i.referred_email,
                  date: moment(i.date_sent).fromNow(),
                  status: i.status,
                  token: i.token,
                  origin: i.origin,
                }))
              );
            }
            deferred.resolve(response.invitations);
          })
          .catch(function (response) {
            deferred.reject(response);
          });

        return deferred.promise;
      },
      resendInvite: function (token) {
        const deferred = $q.defer();
        $http
          .post(ADMIN_API + '/admin-users/resend_admin_refer_link/', { token })
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function (response) {
            deferred.reject(response);
          });

        return deferred.promise;
      },
      removeInvite: function (token) {
        const deferred = $q.defer();
        $http
          .delete(ADMIN_API + '/admin-users/referred_users/', { data: { token } })
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function (response) {
            deferred.reject(response);
          });

        return deferred.promise;
      },
      deactivateUser: function (user_id) {
        const deferred = $q.defer();
        $http
          .post(ADMIN_API + '/admin-users/deactivate_admin_account/', {
            user_id,
          })
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function (err) {
            deferred.reject(err);
          });

        return deferred.promise;
      },
      logoutUser: function (user_id) {
        const deferred = $q.defer();
        $http
          .post(ADMIN_API + '/admin-users/admin_force_logout/', {
            user_id,
          })
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function (err) {
            deferred.reject(err);
          });
      },

      fetchBulkloadedAdmin: function () {
        var deferred = $q.defer();
        $http
          .get(ADMIN_API + '/admin-users/get_bulk_loaded_admins/')
          .then(function (res) {
            const response = res.data;
            deferred.resolve(
              response.map((x) => ({
                id: x.user_id,
                email: x.referred_email,
              }))
            );
          })
          .catch(function (response) {
            deferred.reject(response);
          });

        return deferred.promise;
      },

      saveBulkloadedAdmin: function (ids) {
        var deferred = $q.defer();
        deferred.resolve();
        return deferred.promise;
      },
    };
  },
]);
