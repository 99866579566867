import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
require('jspdf-autotable');

import { IWidget } from '../interfaces/IWidget';

@Injectable()
export class ExportService {
  private generateDownload(dataUrl, fileName) {
    let hiddenElement = document.createElement('a');
    hiddenElement.href = encodeURI(dataUrl);
    hiddenElement.target = '_blank';
    hiddenElement.download = `${fileName}.csv`;
    hiddenElement.click();
  }

  private parseToCSV(input: any, widget: IWidget) {
    let result = [];
    result.push(`data:text/csv;charset=utf-8,${widget.title}`);
    result.push(`${widget.mainData},${widget.mainText || ''}`);
    input.forEach((data, index) => {
      result.push(`"${data.name}",${data.value}`);
    });

    return result.join('\n');
  }

  public exportToCSV(input: any[], widget: IWidget) {
    let csvString = this.parseToCSV(input, widget);
    this.generateDownload(csvString, widget.name);
  }

  public exportToCSVDetails(name: string, labels: string[], data: any[]) {
    let result = [];
    result.push(`data:text/csv;charset=utf8,${labels.join(',')}`);
    data.forEach((item) => {
      let keys = Object.keys(item);
      result.push(
        keys
          .map((k) => {
            let data = `"${item[k]}"`;
            data = data.replace(/#/g, '');
            return data;
          })
          .join(',')
      );
    });

    let csv = result.join('\n');
    this.generateDownload(csv, name);
  }

  public exportToPDFDetails(name: string, labels: any, data: any) {
    let doc = new jsPDF('landscape', 'pt');
    let dataAsArray = data.map((x) => {
      return Object.keys(x).map((y) => x[y]);
    });
    (doc as any).autoTable(labels, dataAsArray);
    doc.save(`${name}.pdf`);
  }
}
