import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../store';
import * as moment from 'moment';
import * as actions from '../store/actions/index';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';

@Component({
  selector: 'date-range',
  templateUrl: './date.range.component.html',
})
export class DateRangeComponent {
  public fromDate: any;
  public toDate: any;

  @Input() skipDateAdjustment = false;
  @Output() dateSelectionChanged = new EventEmitter<any>();

  constructor(private store: Store<fromRoot.State>) { }

  fromDateChanged() {
    if (this.fromDate) {
      if (this.toDate && this.toDate.getDate) {
        const fromdate = moment(this.fromDate);
        const todate = moment(this.toDate);
        if (todate.isSameOrBefore(fromdate)) {
          delete this.toDate;
          return;
        } else if (!this.skipDateAdjustment && todate.isAfter(fromdate.add(1, 'months'))) {
          this.toDate = fromdate.toDate();
        }
        this.sendAction();
      }
    }
  }

  toDateChanged() {
    if (this.toDate) {
      if (this.fromDate && this.fromDate.getDate) {
        const fromdate = moment(this.fromDate);
        const todate = moment(this.toDate);
        if (fromdate.isSameOrAfter(todate)) {
          delete this.fromDate;
          return;
        } else if (!this.skipDateAdjustment && fromdate.isBefore(todate.subtract(1, 'months'))) {
          this.fromDate = todate.toDate();
        }
        this.sendAction();
      }
    }
  }

  sendAction() {
    let action = new actions.TimeRangeChanged({
      start: moment(this.fromDate).startOf('day'),
      end: moment(this.toDate).endOf('day'),
    });

    if (this.dateSelectionChanged?.observed) {
      this.dateSelectionChanged.emit(action)
    } else {
      this.store.dispatch(action);
    }
  }
}

angular
  .module('ServetureWebApp')
  .directive('dateRange', downgradeComponent({ component: DateRangeComponent }));