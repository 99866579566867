import { CommonSelectors } from './../store/selectors';
import { EnumsService } from './enums.service';
import { Injectable } from '@angular/core';

import { IUser, IPermissionInfo } from '../interfaces';
import { PermissionsService } from './permissions.service';
import { Store } from '@ngrx/store';
import { State } from '../../store';
import { take, tap } from 'rxjs';
@Injectable()
export class AuthenticationService {
  constructor(
    private enumsService: EnumsService,
    private permissionService: PermissionsService,
    private selectors: CommonSelectors,
    private store: Store<State>
  ) {}

  public getCurrentUser(): IUser | undefined {
    let rawData = localStorage.getItem('ngStorage-current_user');

    if (rawData) {
      return JSON.parse(rawData);
    }
  }

  public getCurrentUserType() {
    const user = this.getCurrentUser();
    if (!user) {
      return null;
    } else {
      switch (user.user_type) {
        case this.enumsService.UserType.MarketPlaceAdmin:
          if (this.getPermission(this.permissionService.PERMISSIONS.MULTI_BRANCH_ADMIN)) {
            return this.enumsService.UserType.MultiBranchAdmin;
          } else {
            return this.enumsService.UserType.MarketPlaceAdmin;
          }
        case this.enumsService.UserType.EnterpriseAdmin:
          return this.enumsService.UserType.EnterpriseAdmin;
        case this.enumsService.UserType.BranchAdmin: {
          return this.enumsService.UserType.BranchAdmin;
        }
      }
    }
  }

  public getPermission(permissionId): IPermissionInfo | boolean {
    let user: IUser = this.getCurrentUser();

    if (user && user.permission_info) {
      return user.permission_info.find((obj) => {
        return obj.priv_id == permissionId;
      });
    } else {
      return false;
    }
  }

  public getEnterpriseList() {
    let user: IUser = this.getCurrentUser();
    let enterprise_list = [];
    this.store
      .select((x) => x.commonData.enterpriseList)
      .pipe(
        take(1),
        tap((list) => console.log(list))
      )
      .subscribe((list) => (enterprise_list = list));

    if (user && enterprise_list) {
      let list = enterprise_list;

      const userType = this.getCurrentUserType();

      if (userType === this.enumsService.UserType.BranchAdmin && !list.some((l) => l.enterprise_id === -1)) {
        list.unshift({
          name: 'Branch',
          enterprise_id: -1,
        });
      } else if (
        userType !== this.enumsService.UserType.MultiBranchAdmin &&
        !list.some((l) => l.enterprise_id === -1)
      ) {
        list.unshift({
          name: 'Marketplace',
          enterprise_id: -1,
        });
      }

      return list;
    } else if (user && user.enterprise_info) {
      return [
        {
          enterprise_id: user.enterprise_info.id,
          name: user.enterprise_info.name,
        },
      ];
    }
  }

  public isLoggedIn(): boolean {
    return !!localStorage.getItem('ngStorage-token');
  }
}
