import angular from 'angular';
import module from '../module';
import Swal from 'sweetalert2';
import * as enums from '../../enums';

export default module.controller('NewEscalationModalController', [
  '$scope',
  '$modalInstance',
  'AlertService',
  'enterprise_selection',
  'poolManagerService',
  'escalationManagerService',
  'FormatHelperService',
  'AuthenticationService',
  'timeConversionService',
  'generalDataService',
  'sessionManagerService',
  function (
    $scope,
    $modalInstance,
    AlertService,
    enterprise_selection,
    poolManagerService,
    escalationManagerService,
    FormatHelperService,
    AuthenticationService,
    timeConversionService,
    generalDataService,
    sessionManagerService
  ) {
    $scope.submitBtnDisbaled = false;
    $scope.keySession = generalDataService.getKeySession();
    let passedEnt = enterprise_selection;
    $scope.entFilter = enterprise_selection;
    const CURRENT_USER = AuthenticationService.getCurrentUser();
    if (!passedEnt) {
      passedEnt = {
        enterprise_id: CURRENT_USER.enterprise_info.id,
      };
    }

    $scope.title = 'Add a New Escalation';
    $scope.btnLabel = 'Create Escalation';
    $scope.options = {
      mstep: [
        { label: '1 min', value: 1 },
        { label: '5 mins', value: 5 },
        { label: '10 mins', value: 10 },
        { label: '15 mins', value: 15 },
        { label: '30 mins', value: 30 },
        { label: '1 hr', value: 60 },
        { label: '1.5 hrs', value: 90 },
        { label: '2 hrs', value: 120 },
        { label: '3 hrs', value: 180 },
        { label: '4 hrs', value: 240 },
        { label: '5 hrs', value: 300 },
        { label: '6 hrs', value: 360 },
        { label: '8 hrs', value: 480 },
        { label: '12 hrs', value: 720 },
        { label: '18 hrs', value: 1080 },
        { label: '1 day', value: 1440 },
        { label: '1.5 days', value: 2160 },
        { label: '2 days', value: 2880 },
        { label: '3 days', value: 4320 },
        { label: '4 days', value: 5760 },
        { label: '5 days', value: 7200 },
        { label: '6 days', value: 8640 },
        { label: '1 week', value: 10080 },
        { label: '2 weeks', value: 20160 },
        { label: '3 weeks', value: 30240 },
        { label: '1 month', value: 43200 },
        { label: '2 months', value: 86400 },
        { label: '3 months', value: 129600 },
        { label: '6 months', value: 259200 },
        { label: '1 year', value: 525600 },
      ],
    };

    $scope.formData = {
      enterprise_id: passedEnt.enterprise_id,
      name: '',
      order: 10,
      pool_list: [
        {
          order: 1,
          pool_id: '',
          wait_time: 10,
        },
      ],
    };

    // Grabbing Realm attrib list
    sessionManagerService.getRealmAttribs(enums.Realms.Escalation).then(
      function (response) {
        if (response.success) {
          const formData = response.attrib_list.sort(function (a, b) {
            return a.order - b.order;
          });
          $scope.originalFormData = angular.copy(formData);
          $scope.attrib_list = angular.copy(formData);
        } else {
          AlertService.errorAlert(response.message);
        }
      },
      function () {
        AlertService.serverRequestErrorAlert('Unable to load escalation attribute list');
        $modalInstance.close();
      }
    );

    // Grabbing Pools from API
    const getPools = function () {
      poolManagerService.getAllPools({ enterprise_id: $scope.formData.enterprise_id }).then(
        function (poolsArray) {
          $scope.pools = poolsArray.filter(function (obj) {
            return $scope.formData.enterprise_id > 0
              ? obj.enterprise_id == $scope.formData.enterprise_id || obj.pool_id == -1
              : obj.enterprise_id == undefined;
          });
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
        }
      );
    };
    getPools();

    $scope.getTotalTime = function (pools) {
      const time = pools.map(function (pool) {
        return pool.wait_time;
      });
      let total_time = time.reduce(function (a, b) {
        return a + b;
      });
      total_time = timeConversionService.convertMinutes(total_time);
      return total_time;
    };

    $scope.addPool = function () {
      const poolNo = $scope.formData.pool_list.length - 1;
      if ($scope.formData.pool_list[poolNo]) {
        $scope.formData.pool_list[poolNo] = {
          order: poolNo + 1,
          pool_id: $scope.formData.pool_list[poolNo].pool_id,
          wait_time: $scope.formData.pool_list[poolNo].wait_time,
        };
      }
      $scope.formData.pool_list.push({ order: poolNo + 2, pool_id: '', wait_time: 10 });
    };
    $scope.removePool = function () {
      $scope.formData.pool_list.pop();
    };

    // Modal controls
    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      // ensures last pool is stored in pools array
      const last_item = $scope.formData.pool_list.length - 1;
      $scope.formData.pool_list[last_item] = {
        order: last_item + 1,
        pool_id: $scope.formData.pool_list[last_item].pool_id,
        wait_time: $scope.formData.pool_list[last_item].wait_time,
      };
      // checks for dup pools and throws an error is present
      const poolIds = $scope.formData.pool_list.map(function (pool) {
        return pool.pool_id;
      });
      const isDuplicate = poolIds.some(function (item, idx) {
        return poolIds.indexOf(item) !== idx;
      });
      if (isDuplicate) {
        AlertService.warningAlert(
          'You have duplicate pools selected. Please ensure each selected pool is unique before continuing'
        );
        $scope.submitBtnDisbaled = false;
      } else {
        $scope.formatData();
      }
    }; // end of ok method

    $scope.formatData = async function () {
      if (CURRENT_USER.user_type == 2) {
        $scope.formData.market_place_id = CURRENT_USER.market_place_info.id;
        if ($scope.entFilter.enterprise_id != -1) {
          $scope.formData.enterprise_id = $scope.entFilter.enterprise_id;
        }
      }

      const formatedAttribList = FormatHelperService.format_data($scope.attrib_list);
      if (formatedAttribList.length > 0) {
        $scope.formData.attrib_list = formatedAttribList;
        $scope.sendToServer($scope.formData);
      } else {
        const config = {
          title: 'Warning',
          text:
            'The escalation you are about to add has no attributes selected - this means all future ' +
            $scope.keySession +
            's could use this escalation if there are no other escalations with higher priority',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#cc514c',
          confirmButtonText: 'OK',
        };

        const returnHandler = function (isConfirm) {
          if (isConfirm) {
            $scope.sendToServer($scope.formData);
          } else {
            $scope.submitBtnDisbaled = false;
          }
        };

        const { value: returnValue } = await Swal.fire(config);
        returnHandler(returnValue);
      }
    };

    $scope.sendToServer = function (formData) {
      escalationManagerService.addNewEscalation(formData).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
          } else {
            AlertService.errorAlert(response.message);
          }
          $modalInstance.close();
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    // listen for instance_count attrib clicks
    $scope.$on('addAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      const _count = field.instanceCountIndex || 1;
      const _field = angular.copy(
        $scope.originalFormData.filter(function (obj) {
          return obj.attrib_id == field.attrib_id;
        })[0]
      );
      _field.instanceCountIndex = _count + 1;
      _field.showRemoveInstance = true;
      $scope.attrib_list[index].doNotShowAddInstance = true;
      $scope.attrib_list[index].showRemoveInstance = false;
      $scope.attrib_list.splice(index + 1, 0, _field); // add after current field
    });

    $scope.$on('removeAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      $scope.attrib_list[index - 1].doNotShowAddInstance = false;
      $scope.attrib_list[index - 1].showRemoveInstance = true;
      $scope.attrib_list.splice(index, 1);
    });

    // Override multiselect label
    $scope.translation = {
      nothingSelected: 'All',
      selectAll: 'Select All',
      selectNone: 'Select None',
      reset: 'Done',
    };
  },
]);
