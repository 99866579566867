import module from './module';
import moment from 'moment';
export default module.directive('dirDynamicChildAttribFormField', function () {
  function alphabetical(a, b) {
    var A = a.name.toLowerCase();
    var B = b.name.toLowerCase();
    if (A < B) {
      return -1;
    } else if (A > B) {
      return 1;
    } else {
      return 0;
    }
  }
  return {
    restrict: 'E',
    scope: {
      field: '=',
      type: '=',
      form: '=',
      entFilter: '=',
      index: '=',
    },
    controller: [
      '$scope',
      'locationManagerService',
      'DOMAIN',
      'TimeFormatService',
      'LocationProvider',
      function ($scope, locationManagerService, DOMAIN, TimeFormatService, LocationProvider) {
        $scope.onImageChangeHandler = function (event, file) {
          if (file[0].type.indexOf('image') != -1) {
            if (FileReader) {
              var _canvasId = $scope.field.name + '_' + $scope.index;
              var canvas = document.getElementById(_canvasId);
              var context = canvas.getContext('2d');

              //Draw imag to canvas inroder to convert to jpeg and resize
              var imageObj = new Image();
              imageObj.onload = function () {
                canvas.width = 856;
                canvas.height = 1147;
                context.drawImage(imageObj, 0, 0, 856, 1147);
                $scope.field.jpeg = canvas.toDataURL('image/jpeg', 0.75).replace(/^data:image\/(jpeg);base64,/, '');
              };

              //File Reader to set image obj
              var fr = new FileReader();
              fr.onload = function () {
                imageObj.src = fr.result;
              };
              fr.readAsDataURL(file[0]);
            } //end if FileReader Present

            $scope.showRemoveBtn = true;
            delete $scope.imageError;
            delete $scope.imageUrl;
          } else {
            $scope.removeFile();
            $scope.imageError = 'Only images are allowed';
          }
        };

        $scope.removeFile = function (e) {
          e ? e.preventDefault() : null;
          var _name = $scope.field.name + '_' + $scope.index.toString();
          $('input[name=' + _name + ']').val(null);
          delete $scope.field.value;
          $scope.field.jpeg ? delete $scope.field.jpeg : null;
          $scope.showRemoveBtn = false;
        };

        $scope.addAdditionalAttrib = function (e) {
          e ? e.preventDefault() : null;
          $scope.$emit('addAttribInstanceClicked', $scope.field, $scope.index);
        };

        $scope.removeAdditionalAttrib = function (e) {
          e ? e.preventDefault() : null;
          $scope.removeFile();
          $scope.$emit('removeAttribInstanceClicked', $scope.field, $scope.index);
        };

        $scope.addInstanceBtnDisplay = function () {
          var _count = $scope.field.instanceCountIndex || 1;
          if (_count == $scope.field.instance_count || $scope.field.doNotShowAddInstance) {
            return false;
          } else {
            return true;
          }
        };
        $scope.removeInstanceBtnDisplay = function () {
          if ($scope.field.showRemoveInstance && $scope.field.instanceCountIndex > 1) {
            return true;
          }
        };

        $scope.multipleInstances = function () {
          if ($scope.field.instance_count && $scope.field.instance_count != 1) {
            return true;
          }
        };
        //////////////////////////////////////////////////////////////////////////////
        var editSwitch = function (field) {
          switch (field.type) {
            case 'password':
              break;
            case 'single_list':
              if (field.value_list) {
                $scope.field.value = field.value_list.filter(function (obj) {
                  return obj.selected == true;
                })[0];
                $scope.field.choice_list = field.value_list;
              }
              break;
            case 'multi_list':
              $scope.field.value = field.value_list;
              $scope.field.choice_list = field.value_list;
              break;
            case 'text':
              if ($scope.field.name == 'biography') {
                $scope.rows = 4;
              } else {
                $scope.rows = 1;
              }
              $scope.field.value = field.value_text;
              break;
            case 'image':
              if (field.data) {
                $scope.imageUrl = DOMAIN + field.data;
              }
              break;
            case 'count':
              $scope.field.min_amount = 0;
              $scope.field.value = field.value_count;
              break;
            case 'decimal':
              $scope.field.min_amount = 0;
              $scope.field.value = field.value_decimal;
              break;
            case 'location':
              locationManagerService.getAllLocations($scope.entFilter.enterprise_id).then(function (locationsObject) {
                if (locationsObject && $scope.entFilter.enterprise_id != -1) {
                  $scope.locations = LocationProvider.handleOtherLocation(field.value_location);
                  $scope.field.value = field.value_location.filter(function (obj) {
                    return obj.selected == true;
                  })[0]; //assign selected location for display
                } else {
                  $scope.locations = LocationProvider.handleOtherLocation(field.value_location);
                  $scope.field.value = field.value_location.filter(function (obj) {
                    return obj.selected == true;
                  })[0]; //assign selected location for display
                }
              });
              break;
            case 'place':
              $scope.$watch(function () {
                $scope.location = locationManagerService.getSelectedLocation();
                // checks to see if selected location is in set list
                if ($scope.location.location_id) {
                  $scope.field.type = 'place';
                  $scope.getPlaces($scope.location.location_id);
                } else {
                  $scope.field.type = null;
                }
              });
              //assign selected place for display
              $timeout(function () {
                if ($scope.field.type) {
                  var selected = $scope.field.value_place.filter(function (obj) {
                    return obj.selected == true;
                  })[0];
                  $scope.field.value = $scope.field.value_place.filter(function (obj) {
                    return obj.name == selected.name;
                  })[1];
                }
              }, 200);

              // get list of places for selected location
              $scope.getPlaces = function (loc_id) {
                $scope.location.place_list = $scope.field.value_place.filter(function (obj) {
                  return obj.location_id == loc_id;
                });
              };
              break;
            case 'date':
              if (field.value_text) {
                var date = moment(field.value_text, TimeFormatService.format('fullDateNoSeparator')).clone();
                $scope.field.value = date;
              }
              //For contorlling opening and closing of date picker
              $scope.dateField = { opened: false };
              $scope.openDateField = function () {
                $scope.dateField.opened = true;
              };
              break;
            case 'flag':
              if (field.data) {
                $scope.field.value = field.data;
              } else if (field.value_flag) {
                $scope.field.value = field.value_flag;
              }
              break;
          } // end switch
        }; // end edit switch function

        var newSwitch = function (field) {
          switch (field.type) {
            case 'password':
              break;
            case 'single_list':
              break;
            case 'multi_list':
              break;
            case 'text':
              if (field.name == 'biography') {
                $scope.rows = 4;
              } else {
                $scope.rows = 1;
              }
              if (field.default_text) {
                field.value = field.default_text;
              }
              break;
            case 'image':
              break;
            case 'count':
            case 'decimal':
              field.min_amount = 0;
              break;

            case 'location':
              locationManagerService.getAllLocations($scope.entFilter.enterprise_id).then(function (locationsObject) {
                if (locationsObject && $scope.entFilter.enterprise_id != -1) {
                  var locations = locationsObject[$scope.entFilter.enterprise_id];
                  $scope.locations = LocationProvider.handleOtherLocation(locations);
                  $scope.locations.sort(alphabetical);
                } else {
                  $scope.locations = LocationProvider.handleOtherLocation([]);
                  field.value = $scope.locations[0];
                }
              });
              break;
            case 'place':
              break;
            case 'date':
              $scope.dateField = { opened: false };
              $scope.openDateField = function () {
                $scope.dateField.opened = true;
              };
              break;
            case 'flag':
              break;
          } // end switch
        }; // end new switch function

        // switch case based on field type
        if ($scope.type && $scope.type.toLowerCase().indexOf('edit') != -1) {
          editSwitch($scope.field);
        } else {
          newSwitch($scope.field);
        }
      },
    ], //end controller
    templateUrl: 'app/views/templates/dynamic_child_attrib_fields.html',
  };
});
