import module from './module';

export default module.directive('dirToggleButton', [
  function () {
    return {
      restrict: 'E',
      scope: {
        selected: '=',
        change: '&',
        label: '@',
        status: '@',
      },
      templateUrl: 'app/views/templates/toggle_button.html',
      link: function (scope) {
        scope.toggleChanged = () => {
          scope.change();
        };
      },
    };
  },
]);
