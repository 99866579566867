import module from '../module';
import * as enums from '../../enums';
import Swal from 'sweetalert2';

export default module.controller('ProviderTabController', [
  '$scope',
  '$modal',
  'AlertService',
  'providerManagerService',
  '$localStorage',
  'AuthenticationService',
  'DOMAIN',
  'trackingService',
  'branchFilterService',
  'EnterpriseFilterService',
  'NgrxStore',
  'generalDataService',
  'PERMISSIONS',
  'adminUserService',
  function (
    $scope,
    $modal,
    AlertService,
    providerManagerService,
    $localStorage,
    AuthenticationService,
    DOMAIN,
    trackingService,
    branchFilterService,
    EnterpriseFilterService,
    NgrxStore,
    generalDataService,
    PERMISSIONS,
    adminUserService
  ) {
    const CURRENT_USER = AuthenticationService.getCurrentUser();
    $scope.hideBranchFilter = true;
    $scope.isMarketplace = CURRENT_USER.user_type == enums.UserType.MarketPlaceAdmin;
    $scope.showSpinner = false;
    $scope.reverse = true;
    $scope.keyAttrib = $localStorage.keyAttribute;
    generalDataService.getKeyAttributeAsync().then((res) => ($scope.keyAttributeName = res));
    $scope.keyProfession = generalDataService.getKeyProfession();
    $scope.pageTitle = 'Workers';

    $scope.pageSize = 50;
    $scope.currentPage = 1;
    $scope.sortProperty = {
      field_id: 2,
      descending: false,
    };
    $scope.filters = [];
    $scope.systemFilters = [];
    $scope.attribFilters = [];
    $scope.branchFilter = branchFilterService.getSelectedBranch();
    $scope.enterpriseFilter = EnterpriseFilterService.fetchSelectedEnterprise();

    // Permission Check
    $scope.addProviderPermission = CURRENT_USER.permissionCheck(22);
    $scope.editProviderPermission = CURRENT_USER.permissionCheck(23);
    $scope.deleteProviderPermission = CURRENT_USER.permissionCheck(24);
    $scope.logoutProviderPermission = CURRENT_USER.permissionCheck(PERMISSIONS.LOGOUT_USER);
    // grabbing all from SERVETURE
    $scope.resetState = function () {
      $scope.pageSize = 50;
      $scope.currentPage = 1;
      $scope.sortProperty = {
        field_id: 2,
        descending: false,
      };
      if ($localStorage.workerFilter) {
        $scope.filters = JSON.parse($localStorage.workerFilter);
      } else {
        $scope.filters = [];
      }
      $scope.systemFilters = [];
    };

    NgrxStore.select((x) => x.workers.rawResult).subscribe((res) => {
      if (res) {
        $scope.providers = res.providers;
        if (res.totalCount) {
          $scope.numberOfProviders = res.totalCount;
        }
        $scope.showSpinner = false;
      }
    });

    $scope.getProviders = function () {
      if (!$scope.showSpinner) {
        $scope.showSpinner = true;
        $scope.enterpriseId = EnterpriseFilterService.fetchSelectedEnterprise().enterprise_id;
        const branch = branchFilterService.getSelectedBranch();
        const payload = {
          enterprise_id: $scope.enterpriseId,
          page_number: $scope.currentPage,
          page_size: $scope.pageSize,
          order_by: $scope.sortProperty,
          attrib_filters: $scope.attribFilters,
          system_filters: $scope.systemFilters,
          branch_id: branch && branch.id,
        };

        if (!branch || branch.id === -1) {
          delete payload.branch_id;
        }

        if (payload.enterprise_id === -1) {
          delete payload.enterprise_id;
        }

        NgrxStore.dispatch({
          type: '[Workers] Fetch workers',
          payload: payload,
        });
      }
    };

    // Table Sorting
    $scope.sort = function (keyname) {
      $scope.sortProperty.field_id = keyname; // set the sortKey to the param passed
      $scope.sortProperty.descending = !$scope.sortProperty.descending; // if true make it false and vice versa
      $scope.getProviders();
    };

    // delete provider
    $scope.deleteProvider = async function (provider) {
      const config = {
        title: 'Are you sure?',
        text: 'You are about to delete Worker ' + provider.full_name,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Yes, delete Worker',
      };

      const returnHandler = function (isConfirm) {
        if (isConfirm) {
          providerManagerService.removeProvider(provider).then(
            async function (response) {
              if (response.success) {
                const configPositive = {
                  title: 'Deleted!',
                  text: response.message,
                  icon: 'success',
                  timer: 2000,
                  showConfirmButton: false,
                };
                await Swal.fire(configPositive);

                $scope.getProviders();
              } else {
                const configError = {
                  title: 'Error',
                  text: response.message,
                  icon: 'error',
                  timer: 2000,
                  showConfirmButton: false,
                };
                await Swal.fire(configError);
              }
            },
            async function (reason) {
              const configError = {
                title: 'Error',
                text: 'Error contacting the server',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configError);
            }
          );
        }
      };

      const { value: returnValue } = await Swal.fire(config);
      returnHandler(returnValue);

      trackingService.trackEvent(trackingService.ADMIN, 'provider', 'delete');
    };

    $scope.logoutProvider = async function (provider) {
      const config = {
        title: 'Are you sure?',
        text: 'You are about to log out Worker ' + provider.full_name,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Yes, log out Worker',
      };

      const returnHandler = function (isConfirm) {
        if (isConfirm) {
          adminUserService.logoutUser(provider.user_id).then(
            async function (response) {
              if (response.success) {
                const configPositive = {
                  title: 'Logged out!',
                  text: 'Worker was logged out.',
                  icon: 'success',
                  timer: 2000,
                  showConfirmButton: false,
                };
                await Swal.fire(configPositive);

                $scope.getProviders();
              } else {
                const configError = {
                  title: 'Error',
                  text: 'There was a problem logging out the worker.',
                  icon: 'error',
                  timer: 2000,
                  showConfirmButton: false,
                };
                await Swal.fire(configError);
              }
            },
            async function (reason) {
              const configError = {
                title: 'Error',
                text: 'Error contacting the server',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configError);
            }
          );
        }
      };

      const { value: returnValue } = await Swal.fire(config);
      returnHandler(returnValue);
    };

    //////////////////////////////////////////////////////////////////////////////
    // View provider Modal
    const showProviderModal = function (provider) {
      $modal.open({
        animation: true,
        templateUrl: 'app/views/job/providerModal.html',
        controller: 'ProviderModalController',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          provider: function () {
            provider.hidePermissions = !$scope.editProviderPermission;
            return provider;
          },
        },
      });

      trackingService.trackEvent(trackingService.ADMIN, 'worker', 'view');
    };

    $scope.showProvider = function (provider) {
      location.href = `/#/workers/${provider.user_id}/${provider.provider_id}`;
    };

    $scope.$on('ProviderModalController.ProviderUpdated', function () {
      $scope.getProviders();
    });

    // Add/Create new Provider Modal
    $scope.openNewProviderModal = function () {
      const modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/admin/provider/providerRegistrationModal.html',
        controller: 'NewProviderModalController',
        keyboard: false,
        size: 'lg',
        backdrop: 'static',
        resolve: {
          enterprise_selection: function () {
            return $scope.entFilter;
          },
          branch_selection: () => $scope.branchFilter,
        },
      });

      modalInstance.result.then(function (response) {
        if (response.success) {
          $scope.getProviders('newProviderPresent');
          AlertService.successAlert(response.message);
          $scope.getProviders();
        } else {
          AlertService.errorAlert(response.message);
        }
      });
      trackingService.trackEvent(trackingService.ADMIN, 'worker', 'new');
    }; // end of open modal method

    // Edit Provider Modal
    $scope.openEditProviderModal = function (provider) {
      providerManagerService.getProvider(provider.user_id).then(function (provider) {
        editModal(provider);
      });
    };

    const editModal = function (provider) {
      const modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/admin/provider/providerRegistrationModal.html',
        controller: 'EditProviderModalController',
        keyboard: false,
        size: 'lg',
        backdrop: 'static',
        resolve: {
          selectedProvider: function () {
            return provider;
          },
          enterprise_selection: function () {
            return $scope.entFilter;
          },
        },
      });

      modalInstance.result.then(function () {
        $scope.getProviders('newProviderPresent');
      });
      trackingService.trackEvent(trackingService.ADMIN, 'worker', 'edit');
    }; // end of open modal method

    $scope.gotoPage = function (page) {
      $scope.currentPage = page;
      $scope.getProviders();
      trackingService.trackEvent(trackingService.ADMIN, 'worker', 'page');
    };

    $scope.updateFilters = function (systemFilters, attribFilters, forceQuery) {
      const STAFF_TYPE = 3,
        STAFF_ONLY = 1,
        CONTRACTORS_ONLY = 2;
      // If the user is marketplace admin, and not selected an enterprise
      // then filter automatically to contractors only
      if ($scope.isMarketplace && (!$scope.enterpriseId || $scope.enterpriseId === -1)) {
        systemFilters.push({
          type: STAFF_TYPE,
          options: [CONTRACTORS_ONLY],
        });
        // Else filter to staff only
      } else {
        systemFilters.push({
          type: STAFF_TYPE,
          options: [STAFF_ONLY],
        });
      }

      $scope.currentPage = 1;
      $scope.systemFilters = systemFilters;
      $scope.attribFilters = attribFilters;

      if (forceQuery) {
        $scope.getProviders();
      }
      trackingService.trackEvent(trackingService.ADMIN, 'worker', 'filter');
    };

    $scope.getProfileUrl = function (picture) {
      if (picture) {
        return DOMAIN + picture;
      }
      return `${enums.AssetsFolder.images}avatar.png`;
    };

    $scope.loggedInStatus = function (status_code) {
      if (status_code == 1) {
        return 'logged-in-state';
      } else if (status_code == 0) {
        return 'logged-out-state';
      } else {
        return 'no-logged-in-state';
      }
    };

    $scope.export = async () => {
      const result = await Swal.fire({
        title:
          'Please note you will receive an email when the report is finished generating with a link to the report.',
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
      });

      if (result.isConfirmed) {
        const email =
          CURRENT_USER &&
          CURRENT_USER.profile_info &&
          CURRENT_USER.profile_info.find((x) => x.name === 'email_address');
        if (email && email.data) {
          $scope.showSpinner = true;
          $scope.enterpriseId = EnterpriseFilterService.fetchSelectedEnterprise().enterprise_id;
          const branch = branchFilterService.getSelectedBranch();
          const payload = {
            enterprise_id: $scope.enterpriseId,
            attrib_filters: $scope.attribFilters,
            system_filters: $scope.systemFilters,
            branch_id: branch && branch.id,
          };

          if (!branch || branch.id === -1) {
            delete payload.branch_id;
          }

          if (payload.enterprise_id === -1) {
            delete payload.enterprise_id;
          }

          providerManagerService.adminWorkerExtract(payload).then(
            function (response) {
              const swalConfig = {
                title: response.message,
                icon: response.success ? 'success' : 'error',
                showConfirmButton: true,
              };

              Swal.fire(swalConfig);
              $scope.showSpinner = false;
            },
            function (reason) {
              $scope.showSpinner = false;

              AlertService.serverRequestErrorAlert(reason);
            }
          );
        } else {
          Swal.fire({
            title: 'No email on record - please update your email address before proceeding.',
            icon: 'error',
          });
        }
      }
    };

    // market_place enterprise filter
    if (CURRENT_USER.user_type == 2 || CURRENT_USER.user_type == 6) {
      $scope.$watch('entFilter', function (val) {
        if (val.enterprise_id == -1) {
          delete $scope.enterpriseId;
        } else {
          $scope.enterpriseId = val.enterprise_id;
        }

        $scope.resetState();
        $scope.updateFilters($scope.systemFilters, $scope.attribFilters);
        $scope.getProviders();
      });
      $scope.$on('BranchSelectionChanged', function (evt, branchId, triggeredByEnterpriseChange) {
        if (!triggeredByEnterpriseChange) {
          $scope.resetState();
          $scope.updateFilters($scope.systemFilters, $scope.attribFilters);
          $scope.getProviders();
        }
      });
    } else {
      $scope.resetState();
      $scope.updateFilters($scope.systemFilters, $scope.attribFilters);
      $scope.getProviders();
    }
  },
]);
