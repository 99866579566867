import module from './module';

export default module.directive('dirBreakPenalty', [
  'timeReportCsvService',
  function (timeReportCsvService) {
    return {
      restrict: 'E',
      scope: {
        type: '@',
        session: '=',
        isReadOnly: '=',
        close: '&',
        recalculateBreakHeight: '&',
      },
      templateUrl: 'app/views/templates/break_penalty.html',
      link: function (scope, element, attrs) {
        scope.timePickerConfig = {
          hoursStep: 1,
          minuteStep: 1,
          mouseWheel: true,
        };

        scope.sections = scope.session.break_penalties;
        scope.times = {};
        scope.timeController = {};

        scope.openTime = function (section) {
          scope.times[section.id] = true;
          const hours = Math.floor(section.duration / 60);
          scope.timeController = {
            break_time_hours: hours,
            break_time_minutes: section.duration - hours * 60,
          };
        };

        scope.closeTime = function (controller, isOk, section) {
          if (isOk) {
            const time_hours = parseInt(controller.break_time_hours == '' ? 0 : controller.break_time_hours);
            const time_minutes = parseInt(controller.break_time_minutes == '' ? 0 : controller.break_time_minutes);
            section.duration = time_minutes + time_hours * 60;
            section.displayDuration = timeReportCsvService.getDurationDisplayVal(section.duration);
          }
          scope.times[section.id] = false;
        };

        scope.addSection = function () {
          scope.sections.push({
            id: 3 + Math.max(0, scope.sections.length - 3),
            time_detail_id: null,
            reason: '',
            duration: 0,
            waived: false,
            isAdding: true,
            displayDuration: '0 mins',
            allow_waive: true,
          });
          scope.recalculateBreakHeight();
        };

        scope.waive = function (section) {
          section.waived = true;
        };

        scope.unwaive = function (section) {
          section.waived = false;
        };

        scope.isAdding = function () {
          return scope.sections.some((x) => x.isAdding);
        };

        scope.savePenalty = function (section) {
          delete section.isAdding;
          section.duration = parseInt(section.duration);
          scope.recalculateBreakHeight();
        };

        scope.saveChanges = function () {
          scope.session.break_penalties = scope.sections;
        };

        scope.closePopup = function (event, session, isOk) {
          if (isOk) {
            scope.saveChanges();
          }
          if (event) {
            event.stopImmediatePropagation();
            event.preventDefault();
          }
          scope.close({ session, isOk });
        };
      },
    };
  },
]);
