import module from '../module';
export default module.controller('NewLocationModalController', [
  '$scope',
  '$modalInstance',
  '$timeout',
  'LocationTabService',
  'AlertService',
  'enterprise_selection',
  'locationManagerService',
  'AuthenticationService',
  function (
    $scope,
    $modalInstance,
    $timeout,
    LocationTabService,
    AlertService,
    enterprise_selection,
    locationManagerService,
    AuthenticationService
  ) {
    $scope.submitBtnDisbaled = false;
    let passedEnt = enterprise_selection;
    const CURRENT_USER = AuthenticationService.getCurrentUser();
    if (!passedEnt) {
      passedEnt = {
        enterprise_id: CURRENT_USER.enterprise_info.id,
      };
    }
    $scope.title = 'Add New Location';
    $scope.btnLabel = 'Create Location';
    $scope.state_list = LocationTabService.getStateList();

    $scope.formData = {
      enterprise_id: passedEnt.enterprise_id,
      name: '',
      country: 'USA',
      latitude: 0.0,
      longitude: 0.0,
      radius: 100,
      value: {
        details: {},
        location: '',
      },
      places: [],
    };

    $scope.$watch('formData.value.location', function (newValue) {
      if (newValue) {
        $timeout(function () {
          const address = LocationTabService.parseLocation($scope.formData.value.details, $scope.state_list);

          if (!address) {
            AlertService.errorAlert('Cannot find address, please try a different address');
            return;
          }

          $scope.formData.latitude = address.latitude;
          $scope.formData.longitude = address.longitude;
          $scope.formData.address_line_1 = address.address_line_1;
          $scope.formData.state = address.state;
          $scope.formData.zip_code = address.zip_code;
          $scope.formData.city = address.city;
          $scope.formData.isForeign = address.isForeign;

          if (address.isForeign) {
            if (address.city && address.country) {
              $scope.formData.city = address.city + ',' + address.country;
            }
          }
        }, 200);
      }
    });
    ////////////////////////////////////////////////////
    $scope.addPlace = function () {
      $scope.formData.places.push({ name: '' });
    };
    $scope.removePlace = function () {
      $scope.formData.places.pop();
    };
    ////////////////////////////////////////////////////////////////////////////////
    //Modal controls
    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      if ($scope.formData.longitude == 0 || $scope.formData.latitude == 0) {
        AlertService.errorAlert('Unable to add address, please try a different address');
        $scope.submitBtnDisbaled = false;
      } else {
        const formatedSubmission = LocationTabService.formatSubmission($scope.formData);
        createLocation(formatedSubmission);
      }
      $scope.submitBtnDisbaled = false;
    };

    const createLocation = function (formatedSubmission) {
      locationManagerService.addNewLocation(formatedSubmission).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
          } else {
            AlertService.errorAlert(response.message);
          }
          $modalInstance.close();
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    };
    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  },
]);
