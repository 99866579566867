import angular from 'angular';
import module from './module';
import * as enums from '../enums';

export default module.factory('branchFilterService', [
  '$rootScope',
  '$q',
  'branchService',
  'AuthenticationService',
  'NgrxStore',
  'commonSelectors',
  function ($rootScope, $q, branchService, AuthenticationService, NgrxStore, commonSelectors) {
    let branchList = [];
    let selectedBranch;

    const fetchBranches = (forceRefresh) => {
      const deferred = $q.defer();
      if (branchList.length && !forceRefresh) {
        deferred.resolve(branchList);
      } else {
        branchService.getAll().then((result) => {
          branchList = result;
          branchList.sort((a, b) => a.name.localeCompare(b.name));

          const currentUserType = AuthenticationService.getCurrentUserType();
          if (currentUserType !== enums.UserType.MultiBranchAdmin) {
            branchList.unshift({ name: 'Marketplace', id: -1 });
          }
          deferred.resolve(branchList);
        });
      }
      return deferred.promise;
    };

    const fetchBranchesForList = () => {
      const deferred = $q.defer();
      fetchBranches().then((res) => {
        const branches = angular.copy(res);
        if (branches && branches.length) {
          const mp = branches.find((x) => x.id === -1);
          if (mp) {
            mp.name = '';
          }
          deferred.resolve(branches);
        } else {
          deferred.resolve([]);
        }
      });
      return deferred.promise;
    };

    const setSelectedBranch = (id, triggeredByEnterpriseChange) => {
      NgrxStore.dispatch({
        type: '[Common] Set selected branch',
        selection: id,
        updateEnterprise: true,
      });

      $rootScope.$broadcast('BranchSelectionChanged', selectedBranch, triggeredByEnterpriseChange);
    };

    return {
      fetchBranches,
      setSelectedBranch,
      fetchBranchesForList,
      getSelectedBranch: function () {
        let result = {};
        commonSelectors.getBranchSelection().subscribe((x) => (result = x));
        return result;
      },
    };
  },
]);
