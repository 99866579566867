import { Injectable } from '@angular/core';
import { SecureHttpService } from '../secure.http.service';
import Env from '../../../scripts/env.js';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BranchService {
  private endpointRoot = `${Env.ADMIN_API}/branches/`;
  constructor(private http: SecureHttpService) {}
  public getAll() {
    return this.http
      .get(this.endpointRoot)
      .pipe(map((response) => response.branch_list))
      .toPromise();
  }

  public getById(id) {
    return this.http
      .get(`${this.endpointRoot}/${id}/`)
      .pipe(map((response) => response.branch))
      .toPromise();
  }

  public update(data) {
    return this.http.patch(`${this.endpointRoot}/${data.id}/`, data).toPromise();
  }

  public add(data) {
    return this.http.post(this.endpointRoot, data).toPromise();
  }

  delete(id) {
    return this.http.delete(`${this.endpointRoot}/${id}/`).toPromise();
  }
}
