import angular from 'angular';
import module from '../module';
export default module.controller('EscalationModalController', [
  '$scope',
  '$modalInstance',
  'AlertService',
  'passedSession',
  'escalationManagerService',
  'passedEnt',
  'sessionManagerService',
  'trackingService',
  function (
    $scope,
    $modalInstance,
    AlertService,
    passedSession,
    escalationManagerService,
    passedEnt,
    sessionManagerService,
    trackingService
  ) {
    $scope.submitBtnDisbaled = false;
    $scope.title = 'Escalate';
    $scope.entFilter = angular.copy(passedEnt);
    $scope.session = angular.copy(passedSession);
    $scope.formData = {
      request_id: $scope.session.session_id || $scope.session.request_id,
      escalation_id: 0,
    };

    escalationManagerService.getAllEscalations({ enterprise_id: $scope.entFilter.enterprise_id }).then(
      function (escalations) {
        $scope.escalations = angular.copy(escalations[$scope.entFilter.enterprise_id]) || [];
        $scope.escalations.push({ name: 'Dynamic', escalation_id: 0 });
      },
      function (reason) {
        AlertService.serverRequestErrorAlert(reason);
        $modalInstance.close();
      }
    );

    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      sessionManagerService.escalateSession($scope.formData).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
            $modalInstance.close();
          } else {
            AlertService.errorAlert(response.message);
            $modalInstance.dismiss('cancel');
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.dismiss('cancel');
        }
      );
      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'submit');
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'cancel');
    };
  },
]);
