import angular from 'angular';
import module from './module';

export default module.factory('Price', [
  'BASE_URL',
  '$q',
  '$http',
  function (BASE_URL, $q, $http) {
    function Price(priceData) {
      if (priceData) {
        this.setData(priceData);
      }
    }

    Price.prototype = {
      setData: function (priceData) {
        angular.extend(this, priceData);
      },
      delete: function () {
        const deferred = $q.defer();
        $http
          .post(BASE_URL + '/delete_price_structure', { price_structure_id: this.price_structure_id })
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function (response) {
            deferred.resolve(response);
          });
        return deferred.promise;
      },
    };

    return Price;
  },
]);
