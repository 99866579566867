import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { Worker } from '../../../shared/models/worker';
import { Store } from '@ngrx/store';
import { WorkersSelectors } from '../../store/selectors';
import { FetchWorkerBlacklist, UpdateWorkerBlacklist } from '../../store/actions';
import { AuthenticationService } from '../../../shared/services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { filter, map, take, tap } from 'rxjs/operators';
import { remove } from 'lodash';

@Component({
  templateUrl: './blacklist.page.html',
  styleUrls: ['./blacklist.page.scss'],
  selector: 'app-blacklist-page',
})
export class BlacklistPage implements OnInit {
  @Input() worker: Worker;

  public blacklistedEnterprises$: Observable<{ id: string; name: string }[]>;
  public enterprises: { id: number; name: string; selected: boolean }[];
  public modalRef: BsModalRef;

  constructor(
    private store: Store,
    private workerSelectors: WorkersSelectors,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.store.dispatch(new FetchWorkerBlacklist({ userId: this.worker.user_id }));
    this.enterprises = this.authenticationService
      .getEnterpriseList()
      .filter((x) => x.enterprise_id > -1)
      .map((x) => ({
        id: x.enterprise_id,
        name: x.name,
        selected: false,
      }));
    this.blacklistedEnterprises$ = this.workerSelectors.getEnterpriseBlacklistForWorker(this.worker.user_id).pipe(
      filter((x) => !!x),
      tap(
        (list) =>
          (this.enterprises = this.enterprises.map((ent) => ({
            ...ent,
            selected: !!list.find((x) => +x.id == ent.id),
          })))
      )
    );
  }

  addToEnterprises(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { animated: false });
  }

  async removeEnterpriseFromBlacklist(enterprise: { id: number; name: string }) {
    const result = await Swal.fire({
      title: 'Are you sure?',
      html: `Would you like to remove the enterprise "${enterprise.name}" from the worker's blocklist?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#cc514c',
    });

    if (result.isConfirmed) {
      this.blacklistedEnterprises$.pipe(take(1)).subscribe((blacklist) => {
        const payload = [...blacklist].map((x) => Number(x.id));
        remove(payload, (x) => x === enterprise.id);

        this.store.dispatch(new UpdateWorkerBlacklist(this.worker.user_id, payload));
      });
    }
  }

  submit() {
    const enterprises = this.enterprises.filter((x) => x.selected).map((x) => x.id);
    this.store.dispatch(new UpdateWorkerBlacklist(this.worker.user_id, enterprises));

    this.modalRef.hide();
  }
}
