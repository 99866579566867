import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';

import * as actions from '../store/actions/index';
import * as fromRoot from '../store';
import { DashboardWidgetsComponent } from './dashboard.widgets.component';
import PERMISSIONS from '../../../scripts/permissions';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { filter, take } from 'rxjs';
import { CommonSelectors } from '../../../shared/store/selectors';
import { IEnterpriseSelection } from 'app/shared/interfaces';

@Component({
  selector: 'dashboard-component',
  templateUrl: 'dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  public widgets: any[] = [];
  public dateVisible = false;
  public dragMode = false;
  public categoryId = -1;
  public hourlyId = 0;
  public hasWidget = false;
  public hasValidFilter = false;
  public showFinancial = false;
  public hasTotalTimeReportPermission = false;

  constructor(
    private store: Store<fromRoot.State>,
    private modalService: BsModalService,
    private selectors: CommonSelectors
  ) {
    this.selectors.getEnterpriseSelection().subscribe((value: IEnterpriseSelection) => {
      this.onEnterpriseChange(value.id);
    });
  }

  ngOnInit() {
    this.selectors
      .getEnterpriseList()
      .pipe(
        filter((list) => !!list?.length),
        take(1)
      )
      .subscribe(() => {
        this.store.dispatch(new actions.FetchEnterprises());
        this.store.dispatch(new actions.TimeFilterChange(1));
        this.store.dispatch(new actions.CategoryFilterChange(-1));
        this.store.dispatch(new actions.FetchKeyAttribute());
        this.store.dispatch(new actions.FetchWorkers());
        this.store.dispatch(new actions.FetchKeySession());
        this.store.dispatch(new actions.FetchKeyProfession());
        this.store.dispatch(new actions.CheckPermission(PERMISSIONS.DASHBOARD_FINANCIAL));
        this.store.dispatch(new actions.CheckPermission(PERMISSIONS.TOTAL_TIME_REPORT));

        this.store.select(fromRoot.getWidgets).subscribe((widgets) => {
          this.widgets = widgets;
          this.hasWidget = false;
        });

        this.store.select(fromRoot.getSelectedWidget).subscribe((w) => {
          if (w) {
            this.hasWidget = true;
          }
        });

        this.store
          .select(fromRoot.getHasValidFilter)
          .subscribe((hasValidFilter) => (this.hasValidFilter = hasValidFilter));

        this.store.select(fromRoot.getPermissions).subscribe((permissions) => {
          this.showFinancial = !!permissions[PERMISSIONS.DASHBOARD_FINANCIAL];
          this.hasTotalTimeReportPermission = !!permissions[PERMISSIONS.TOTAL_TIME_REPORT];
        });
      });
  }

  onBranchChange(value) {
    let action = new actions.BranchFilterChange(parseInt(value));
    this.store.dispatch(action);
  }

  onEnterpriseChange(value) {
    let action = new actions.EnterpriseFilterChange(parseInt(value));
    this.store.dispatch(action);
  }

  onTimeChange(value) {
    if (value != 8) {
      let action = new actions.TimeFilterChange(parseInt(value));
      this.store.dispatch(action);
    } else {
      this.dateVisible = true;
    }
  }

  onCategoryChange(value) {
    this.categoryId = parseInt(value);
    let action = new actions.CategoryFilterChange(this.categoryId);
    this.store.dispatch(action);
  }

  onHourlyChange(value) {
    this.hourlyId = parseInt(value);
    let action = new actions.HourlyFilterChange(this.hourlyId);
    this.store.dispatch(action);
  }

  onStaffFilterChange(value) {
    let action = new actions.StaffFilterChange(parseInt(value));
    this.store.dispatch(action);
  }

  selectedWidgetChange(widget) {
    let action = new actions.SelectedWidgetChanged(widget);
    this.store.dispatch(action);
  }

  editWidgets() {
    this.modalService.show(DashboardWidgetsComponent, { animated: false });
  }

  widgetsReordered(event) {
    moveItemInArray(this.widgets, event.previousIndex, event.currentIndex);
    console.log(this.widgets);
    let action = new actions.WidgetOrderChanged(this.widgets, this.categoryId);
    this.store.dispatch(action);
  }

  dragModeChanged() {
    this.dragMode = !this.dragMode;
    let action = new actions.DragModeChanged(this.dragMode);
    this.store.dispatch(action);
  }

  backToDashboard() {
    let action = new actions.FilterRequestList({});
    this.store.dispatch(action);
  }
}
