import module from '../module';

export default module.service('timeReportUIService', [
  'generalDataService',
  function (generalDataService) {
    const getSwalCongrats = () => {
      return {
        title: 'Completed!',
        text: 'Congrats. You have nothing else to Verify at this time. Your work here is done!',
        icon: 'success',
        showConfirmButton: true,
      };
    };

    const getSwalJobOrderCompleted = () => {
      return {
        title: 'Completed!',
        text: 'Job Order Completed - Congrats',
        icon: 'success',
        showConfirmButton: true,
      };
    };

    const getSwalSaveDataBeforeLeave = () => {
      return {
        title: 'Are you sure?',
        text: 'You have modified some data on the page without verifying them, do you want to move anyway?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Yes, move without verifying',
        cancelButtonText: 'No',
      };
    };

    const getSwalVerifyAllQuestion = (numberOfActiveShifts) => {
      return {
        title: 'Verify All',
        html: numberOfActiveShifts
          ? `This Job Order contains <b>${numberOfActiveShifts}</b> Active Shifts. Please confirm how you want to proceed`
          : 'This Job Order contains no Active Shifts. Please confirm how you want to proceed',
        icon: 'warning',
        showDenyButton: numberOfActiveShifts,
        denyButtonText: 'Verify All - Excluding Active Shifts',
        confirmButtonColor: '#cc514c',
        confirmButtonText: numberOfActiveShifts ? 'Verify All - Including Active Shifts' : 'Verify All',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancel - Don't verify Anything",
      };
    };

    const getSwalUnverifySessionQuestion = () => {
      return {
        title: 'Are you sure?',
        text: `Please acknowledge that you wish to unverify this shift.
          If payroll has already been processed, unverify will not go to payroll.
          If you have questions about this process, you may want to contact your payroll department.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'UNVERIFY',
        cancelButtonText: 'CANCEL',
      };
    };

    const getSwalWrongEnterpriseId = () => {
      return {
        title: 'Error',
        text: 'Wrong Enterprise Id in the link what you opened',
        icon: 'error',
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Ok',
      };
    };

    const getSwalWrongJobOrderId = (jobOrderId) => {
      return {
        title: 'Work Order Verification',
        text: `Work Order No. ${jobOrderId} has already been completed and verified.`,
        icon: 'info',
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Ok',
      };
    };

    const getSwalNoShowQuestion = () => {
      return {
        title: 'Are you sure?',
        text: 'You are about to set this shift as a No Show, this action cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'CONTINUE',
        cancelButtonText: 'CANCEL',
      };
    };

    const selectInput = (inputSelector) => {
      const break_time_minutes_element = document.querySelector(inputSelector);
      if (break_time_minutes_element) {
        break_time_minutes_element.select();
      }
    };

    const getTimePickerConfig = () => {
      return {
        hoursStep: 1,
        minuteStep: 1,
        mouseWheel: true,
      };
    };

    const getTranslations = () => {
      return {
        firstFilterName: 'Job Order',
        secondFilterName: 'Time Period',
        hideVerified: 'Hide Verified Jobs',
        showVerified: 'Show Verified Jobs',
        showCancelled: 'Show Cancelled Shifts',
        hideCancelled: 'Hide Cancelled Shifts',
        showFuture: 'Show Future Shifts',
        hideFuture: 'Hide Future Shifts',
        exportButton: 'Export',
        repeatJobOrderButton: 'Do you want to repeat this order?',
        jobOrderRepeated: 'Work Order has been set to repeat',
        jobOrderNotRepeated: 'Work Order has been set to not repeat',
        jobOrderView: 'View',
        verifyAllBtn: 'Verify All',
        keySessionId: `${generalDataService.getKeySession()} ID`,
        status: 'Status',
        startDate: 'Start Date',
        checkIn: 'Check-In',
        checkOut: 'Check-Out',
        break: 'Break',
        penalty: 'Penalty',
        scheduleFor: 'Scheduled For',
        timeWorked: 'Time Worked',
        amount: 'Amount',
        rating: 'Rating',
        verification: 'Verification',
        verifyBtn: 'Verify',
        verifiedBtn: 'Verified',
        unverifyBtn: 'Unverify',
        cancelBtn: 'Close',
        changeBtn: 'Change',
        verify_user_full_name: 'Verifier',
        verified_timestamp: 'Verifier Date & Time',
        enterprise: 'Enterprise',
        branch: 'Branch',
        extractButton: 'Create CTM Extract',
        noShowBtn: 'No show',
        verifiedShifts: 'Verified Shifts:',
        cancelledShifts: 'Cancelled Shifts:',
        futureShifts: 'Future Shifts:',
        displayed: 'Displayed',
        hidden: 'Hidden',
        actions: 'Job Order Actions',
        allActions: 'All Actions',
        totalShifts: 'Total Shifts:',
        sortBy: 'Sort List by: ',
        sortShift: 'Shift Count',
        sortTime: 'Chronological',
        sortAbc: 'Alphabetical',
        yes: 'Yes',
        no: 'No',
        filterByDate: 'Filter List by Date:',
        dateFilterMonthToDate: 'Month To Date',
        dateFilterMonthTrailing: 'One Month Trailing',
        dateFilterWeekToDate: 'Week To Date',
        dateFilterLastWeek: 'Last Week',
        dateFilterCustom: 'Custom',
        dateFilterLastMonth: 'Last Month',
        dateFilterToday: 'Today',
        dateFilterFuture: 'Future',
      };
    };

    let closeAllPicker = null;

    const checkIfElementInsideViewport = (className, _closeAllPicker) => {
      // check if it's the mobile or desktop view, return if mobile
      const element = document.querySelector('.time-reports-tab-menu-items');
      if (!element) {
        console.log('Parent Element is null');
        return;
      }

      const popupElement = document.querySelector(className);
      if (!popupElement) {
        console.log('PopupElement Element is null');
        return;
      }

      // add a transparent layer to click to cancel the small popups
      closeAllPicker = _closeAllPicker;
      createPopupOverlay();

      const isOut = isOutOfViewport(popupElement);
      // checking whether fully visible
      if (!isOut.any) {
        console.log('Element is fully visible in screen');
        return;
      }
      repositionPopup(isOut, className, popupElement);
    };

    function popupClickListener() {
      closePopupOverlay();
      if (typeof closeAllPicker === 'function') {
        closeAllPicker();
      }
    }

    function closePopupOverlay() {
      const popupOverlay = document.querySelector('.popup-overlay');
      if (popupOverlay) {
        popupOverlay.classList.remove('visible-overlay');
      }
      popupOverlay.removeEventListener('click', popupClickListener);
    }

    function createPopupOverlay() {
      const popupOverlay = document.querySelector('.popup-overlay');
      if (popupOverlay) {
        popupOverlay.classList.add('visible-overlay');
      }
      popupOverlay.addEventListener('click', popupClickListener);
    }

    function repositionPopup(isOut, className, popupElement) {
      console.log('Element is NOT fully visible in screen');
      console.log(isOut);

      if (isOut.bottom) {
        repositionPopupBottom(className, popupElement);
      }
      if (isOut.right) {
        repositionPopupRight(className, popupElement);
      }
      if (isOut.left) {
        repositionPopupLeft(className, popupElement);
      }
    }

    function repositionPopupBottom(className, popupElement) {
      console.log('repositionPopupBottom');
      let newOffsetTop = popupElement.offsetTop - popupElement.offsetHeight - 10;

      if (className === '.time-interval-container') {
        newOffsetTop -= popupElement.previousElementSibling.offsetHeight;
        popupElement.style.top = `${newOffsetTop}px`;
      } else if (className === '.time-picker-container') {
        newOffsetTop -= popupElement.parentElement.offsetHeight;
        popupElement.style.top = `${newOffsetTop}px`;
      } else if (className === '.datetime-picker-container div') {
        const tableTop = document.querySelector('.timereports-tab-table');
        const currentDiff = popupElement.getBoundingClientRect().top - tableTop.getBoundingClientRect().top;
        const granGrandParent = popupElement.parentElement.parentElement;
        if (
          currentDiff < popupElement.offsetHeight + granGrandParent.offsetHeight - 5 ||
          popupElement.style.position === 'fixed'
        ) {
          popupElement.style.position = 'fixed';
          popupElement.style.top =
            granGrandParent.getBoundingClientRect().top - popupElement.offsetHeight / 2 - 15 + 'px';
          if (parseInt(popupElement.style.top) + popupElement.offsetHeight > window.innerHeight) {
            popupElement.style.top = `${window.innerHeight - popupElement.offsetHeight}px`;
          }
          if (parseInt(popupElement.style.top) < 0) {
            popupElement.style.top = '0px';
          }
          popupElement.style.left =
            granGrandParent.getBoundingClientRect().left +
            granGrandParent.offsetWidth / 2 -
            popupElement.offsetWidth / 2 +
            'px';
        } else {
          newOffsetTop = newOffsetTop - granGrandParent.offsetHeight - 15;
          popupElement.style.top = `${newOffsetTop}px`;
        }
      }
    }

    function repositionPopupRight(className, popupElement) {
      console.log('repositionPopupRight');
      const newOffsetRight = 0;

      if (className === '.time-interval-container') {
        popupElement.style.right = `${newOffsetRight}px`;
      } else if (className === '.time-picker-container') {
        popupElement.style.right = `${newOffsetRight}px`;
      } else if (className === '.datetime-picker-container div') {
        popupElement.style.right = `${newOffsetRight}px`;
        const granGrandParent = popupElement.parentElement.parentElement;
        popupElement.style.left = (700 - granGrandParent.offsetWidth) * -1;
        // const tableTop = document.querySelector('.timereports-tab-table');
        // const currentDiff = popupElement.getBoundingClientRect().top - tableTop.getBoundingClientRect().top;
        // const granGrandParent = popupElement.parentElement.parentElement;
        // if (currentDiff < popupElement.offsetHeight + granGrandParent.offsetHeight - 5) {
        //   popupElement.style.position = 'fixed';
        //   popupElement.style.top = granGrandParent.getBoundingClientRect().top - popupElement.offsetHeight - 5 + 'px';
        //   popupElement.style.left = granGrandParent.getBoundingClientRect().left + (granGrandParent.offsetWidth / 2) - (popupElement.offsetWidth / 2) + 'px'
        // }
        // else {
        //   newOffsetRight -= granGrandParent.offsetHeight - 15;
        //   popupElement.style.top = `${newOffsetRight}px`;
        // }
      }
    }

    function repositionPopupLeft(className, popupElement) {
      console.log('repositionPopupLeft');
      const newOffsetLeft = 0;

      if (className === '.time-interval-container') {
        popupElement.style.left = `${newOffsetLeft}px`;
      } else if (className === '.time-picker-container') {
        popupElement.style.left = `${newOffsetLeft}px`;
      } else if (className === '.datetime-picker-container div') {
        popupElement.style.left = `${newOffsetLeft}px`;
      }
    }

    function isOutOfViewport(elem) {
      // Get element's bounding
      const bounding = elem.getBoundingClientRect();
      const parent = document.querySelector('#timereports-tab-table').getBoundingClientRect();
      if (!parent.x && !parent.y && !parent.width && !parent.height) {
        return {};
      }

      // Check if it's out of the viewport on each side
      const out = {};
      out.top = bounding.top < parent.top;
      out.left = bounding.left < 0;
      out.bottom = window.innerHeight < bounding.bottom || bounding.bottom > parent.bottom;
      out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
      out.any = out.top || out.left || out.bottom || out.right;
      out.all = out.top && out.left && out.bottom && out.right;

      return out;
    }

    return {
      getSwalCongrats,
      getSwalJobOrderCompleted,
      getSwalSaveDataBeforeLeave,
      getSwalVerifyAllQuestion,
      getSwalUnverifySessionQuestion,
      getSwalWrongEnterpriseId,
      getSwalNoShowQuestion,
      selectInput,
      getTimePickerConfig,
      getTranslations,
      checkIfElementInsideViewport,
      getSwalWrongJobOrderId,
    };
  },
]);
