import { Injectable } from '@angular/core';

import { CancelBreakDownComponent } from '../widgets/cancel.break.down.component';
import { FillRateComponent } from '../widgets/fill.rate.component';
import { LateComponent } from '../widgets/late.component';
import { ShiftQualityComponent } from '../widgets/shift.quality.component';
import { ShiftsAcceptedComponent } from '../widgets/shifts.accepted.component';
import { ShiftsOfferedComponent } from '../widgets/shifts.offered.component';
import { TopKeyAttributeComponent } from '../widgets/top.key.attribute.component';
import { ShiftWorkerLocationComponent } from '../widgets/shift.worker.location.component';

import { FinancialKeyAttributeComponent } from '../widgets/financial/financial.key.attribute.component';
import { FinancialLocationComponent } from '../widgets/financial/financial.location.component';

import { RequestListComponent } from '../details/request.list.component';
import { TotalTimeListComponent } from '../details/total-time-list.component';
import { VerifyReportComponent } from '../details/verify.report.component';

import { AuthenticationService } from '../../../shared/services/authentication.service';
import PERMISSIONS from '../../../scripts/permissions';
import { TotalTimeComponent } from '../widgets/total.time.component';

@Injectable()
export class WidgetListService {
  private allWidgets = [
    {
      name: 'fillRate',
      category: 3,
      type: FillRateComponent,
      detailType: RequestListComponent,
      title: 'Shift Count',
      order: 1,
    },
    {
      name: 'cancelBreakDown',
      category: 3,
      type: CancelBreakDownComponent,
      detailType: RequestListComponent,
      title: 'Cancel Breakdown',
      chartMode: true,
      order: 2,
    },
    {
      name: 'late',
      category: 4,
      type: LateComponent,
      detailType: RequestListComponent,
      title: 'Late',
      chartMode: true,
      order: 3,
    },
    {
      name: 'topKeyAttribute',
      category: 3,
      type: TopKeyAttributeComponent,
      detailType: RequestListComponent,
      customTitle: true,
      title: 'Top Key Attribute',
      order: 4,
    },
    {
      name: 'shiftQuality',
      category: 4,
      type: ShiftQualityComponent,
      detailType: RequestListComponent,
      title: 'Shift Quality',
      customTitle: true,
      order: 99,
    },
    {
      name: 'shiftsAccepted',
      category: 3,
      type: ShiftsAcceptedComponent,
      detailType: RequestListComponent,
      title: 'Shifts Accepted',
      chartMode: true,
      customTitle: true,
      order: 99,
    },
    {
      name: 'shiftsOffered',
      category: 3,
      type: ShiftsOfferedComponent,
      detailType: RequestListComponent,
      title: 'Shifts Offered',
      customTitle: true,
      order: 99,
    },

    {
      name: 'shiftWorkerLocation',
      category: 3,
      type: ShiftWorkerLocationComponent,
      detailType: RequestListComponent,
      title: 'Shift Worker Location',
      customTitle: true,
      order: 99,
    },
    {
      name: 'totalTime',
      category: 3,
      type: TotalTimeComponent,
      detailType: TotalTimeListComponent,
      title: 'Total Time',
      chartMode: true,
      order: 99,
      jobName: 'Job Title',
      enterpriseName: 'Facility Name',
      permission: PERMISSIONS.TOTAL_TIME_REPORT,
      id: 'total-time-report',
    },
    {
      name: 'financialKeyAttribute',
      category: 5,
      type: FinancialKeyAttributeComponent,
      detailType: VerifyReportComponent,
      customTitle: true,
      title: '$ By Key Attribute',
      permission: PERMISSIONS.DASHBOARD_FINANCIAL,
      order: 99,
    },
    {
      name: 'financialLocation',
      category: 5,
      type: FinancialLocationComponent,
      detailType: VerifyReportComponent,
      title: '$ By Location ',
      permission: PERMISSIONS.DASHBOARD_FINANCIAL,
      order: 99,
    },
  ];
  constructor(private authenticationService: AuthenticationService) {
    this.allWidgets = this.allWidgets.filter((x: any) => {
      if (!x.permission) {
        return true;
      } else {
        return this.authenticationService.getPermission(x.permission);
      }
    });
  }

  public fetchWidgets(categoryId) {
    let result;
    let preferences = localStorage.getItem('widgets');
    if (preferences) {
      let parsed = JSON.parse(preferences);
      result = this.allWidgets
        .filter((w) => w.category === categoryId || categoryId === -1)
        .filter((w) => {
          let widget = parsed.find((p) => p.name === w.name);
          return !widget || (widget && !widget.isClosed);
        })
        .map((w: any) => {
          let widget = parsed.find((p) => p.name === w.name);
          if (widget) {
            w.order = widget.order;
          }
          return w;
        });
    } else if (categoryId === -1) {
      result = this.allWidgets;
    } else {
      result = this.allWidgets.filter((w) => w.category === categoryId);
    }

    result.sort((a, b) => {
      return a.order - b.order;
    });

    return result;
  }

  public fetchAllWidgets(categoryId) {
    let result;
    let preferences = localStorage.getItem('widgets');
    if (preferences) {
      let parsed = JSON.parse(preferences);
      let filtered;
      if (categoryId === -1) {
        filtered = this.allWidgets;
      } else {
        filtered = this.allWidgets.filter((w) => w.category === categoryId);
      }
      result = filtered.map((w) => {
        let widget = parsed.find((p) => p.name === w.name);
        if (widget) {
          w.isClosed = widget.isClosed;
          w.order = widget.order;
        }
        return w;
      });
    } else if (categoryId === -1) {
      result = this.allWidgets;
    } else {
      result = this.allWidgets.filter((w) => w.category === categoryId);
    }
    return result;
  }

  public toggleWidget(widget, isClosed) {
    let preferences = localStorage.getItem('widgets');
    let widgetList;
    if (!preferences) {
      widgetList = this.allWidgets;
    } else {
      widgetList = JSON.parse(preferences);
    }
    let target = widgetList.find((x) => x.name === widget.name);
    target.isClosed = isClosed;

    localStorage.setItem('widgets', JSON.stringify(widgetList));
  }

  public removeWidget(widget) {
    this.toggleWidget(widget, true);
  }

  public addWidget(widget) {
    this.toggleWidget(widget, false);
  }

  public persistOrder(widgets, categoryId) {
    widgets = widgets.map((widget, index) => {
      widget.order = index;
      return widget;
    });
    widgets.sort((a, b) => {
      return a.order - b.order;
    });
    localStorage.setItem('widgets', JSON.stringify(widgets));
  }

  public getWidgetType(name) {
    return this.allWidgets.find((w) => w.name === name).type;
  }

  public getDetailType(name) {
    return this.allWidgets.find((w) => w.name === name).detailType;
  }
}
