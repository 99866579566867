import angular from 'angular';
import module from '../module';
import * as enums from '../../enums';

export default module.controller('EditEscalationModalController', [
  '$scope',
  '$modalInstance',
  'passedEscalation',
  'escalationManagerService',
  'AlertService',
  'EscalationTabService',
  'poolManagerService',
  'FormatHelperService',
  'enterprise_selection',
  'timeConversionService',
  'sessionManagerService',
  function (
    $scope,
    $modalInstance,
    passedEscalation,
    escalationManagerService,
    AlertService,
    EscalationTabService,
    poolManagerService,
    FormatHelperService,
    enterprise_selection,
    timeConversionService,
    sessionManagerService
  ) {
    $scope.submitBtnDisbaled = false;
    $scope.entFilter = enterprise_selection;
    $scope.formData = angular.copy(passedEscalation);
    // $scope.originalData = angular.copy(passedEscalation);
    $scope.title = 'Edit Escalation';
    $scope.btnLabel = 'Update Escalation';
    $scope.options = {
      mstep: [
        { label: '1 min', value: 1 },
        { label: '5 mins', value: 5 },
        { label: '10 mins', value: 10 },
        { label: '15 mins', value: 15 },
        { label: '30 mins', value: 30 },
        { label: '1 hr', value: 60 },
        { label: '1.5 hrs', value: 90 },
        { label: '2 hrs', value: 120 },
        { label: '3 hrs', value: 180 },
        { label: '4 hrs', value: 240 },
        { label: '5 hrs', value: 300 },
        { label: '6 hrs', value: 360 },
        { label: '8 hrs', value: 480 },
        { label: '12 hrs', value: 720 },
        { label: '18 hrs', value: 1080 },
        { label: '1 day', value: 1440 },
        { label: '1.5 days', value: 2160 },
        { label: '2 days', value: 2880 },
        { label: '3 days', value: 4320 },
        { label: '4 days', value: 5760 },
        { label: '5 days', value: 7200 },
        { label: '6 days', value: 8640 },
        { label: '1 week', value: 10080 },
        { label: '2 weeks', value: 20160 },
        { label: '3 weeks', value: 30240 },
        { label: '1 month', value: 43200 },
        { label: '2 months', value: 86400 },
        { label: '3 months', value: 129600 },
        { label: '6 months', value: 259200 },
        { label: '1 year', value: 525600 },
      ],
    };
    // Grabbing Realm attrib list
    const getEscalationAttribs = function () {
      sessionManagerService.getRealmAttribs(enums.Realms.Escalation).then(
        function (response) {
          if (response.success) {
            $scope.attrib_list = response.attrib_list.sort(function (a, b) {
              return a.order - b.order;
            });
            // $scope.originalData.attrib_list = angular.copy($scope.attrib_list);
          } else {
            AlertService.errorAlert(response.message);
          }
        },
        function () {
          AlertService.serverRequestErrorAlert('Unable to load escalation attribute list');
          $modalInstance.close();
        }
      );
    };
    // Grabbing Pools from API
    const getPools = function () {
      poolManagerService.getAllPools({ enterprise_id: $scope.formData.enterprise_id }).then(
        function (poolsArray) {
          $scope.pools = poolsArray.filter(function (obj) {
            return $scope.formData.enterprise_id > 0
              ? obj.enterprise_id == $scope.formData.enterprise_id || obj.pool_id == -1
              : obj.enterprise_id == undefined;
          });
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
        }
      );
    };

    $scope.getTotalTime = function () {
      let total_time;
      if ($scope.formData.pool_list.length > 0) {
        const time = $scope.formData.pool_list.map(function (pool) {
          return pool.wait_time;
        });
        total_time = time.reduce(function (a, b) {
          return a + b;
        });
        total_time = timeConversionService.convertMinutes(total_time);
      } else {
        total_time = 0;
      }
      return total_time;
    };

    $scope.addPool = function () {
      const poolNo = $scope.formData.pool_list.length - 1;
      if ($scope.formData.pool_list[poolNo]) {
        $scope.formData.pool_list[poolNo] = {
          order: poolNo + 1,
          pool_id: $scope.formData.pool_list[poolNo].pool_id,
          wait_time: $scope.formData.pool_list[poolNo].wait_time,
        };
      }
      $scope.formData.pool_list.push({ order: poolNo + 2, pool_id: '', wait_time: 10 });
    };
    $scope.removePool = function () {
      $scope.formData.pool_list.pop();
    };

    // Modal controls
    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      // ensures last pool is stored in pools array
      const last_item = $scope.formData.pool_list.length - 1;
      $scope.formData.pool_list[last_item] = {
        order: last_item + 1,
        pool_id: $scope.formData.pool_list[last_item].pool_id,
        wait_time: $scope.formData.pool_list[last_item].wait_time,
      };

      // checks for dup pools and throws an error is present
      const poolIds = $scope.formData.pool_list.map(function (pool) {
        return pool.pool_id;
      });
      const isDuplicate = poolIds.some(function (item, idx) {
        return poolIds.indexOf(item) !== idx;
      });
      if (isDuplicate) {
        AlertService.warningAlert(
          'You have duplicate pools selected. Please ensure each selected pool is unique before continuing'
        );
        $scope.submitBtnDisbaled = false;
      } else {
        // call to format data before sending to server
        formatData();
      }
    };

    const formatData = function () {
      const formatedAttribList = FormatHelperService.format_data($scope.attrib_list);
      if (formatedAttribList.length > 0) {
        $scope.formData.attrib_list = formatedAttribList;
      }

      $scope.updateEscalation($scope.formData);
    };

    $scope.updateEscalation = function (formatedFormData) {
      escalationManagerService.updateEscalation(formatedFormData).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
          } else {
            AlertService.errorAlert(response.message);
          }
          $modalInstance.close();
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
        }
      );
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    getPools();
    getEscalationAttribs();

    // Override multiselect label
    $scope.translation = {
      nothingSelected: 'All',
      selectAll: 'Select All',
      selectNone: 'Select None',
      reset: 'Done',
    };
  },
]);
