import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';

import { tabSelectorAnimation } from './tab-selector.animations';

import { ITabSelectorConfig } from '../../interfaces/ITabSelectorConfig';

@Component({
  selector: 'tab-selector',
  templateUrl: 'tab-selector.component.html',
  animations: tabSelectorAnimation,
})
export class TabSelectorComponent implements OnInit {

  @Input() selectedTab: string;
  @Input() tabSelectorConfig: ITabSelectorConfig[];
  @Output() selectingTab: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    // console.dir(this.tabSelectorConfig)
    this.checkPreSelectedTab();
  }

  public selectTab(selectedTab): void {
    this.selectedTab = selectedTab;
    this.selectingTab.emit(selectedTab);
  }

  private checkPreSelectedTab() {
    let selected = this.tabSelectorConfig.find(tab => tab.tabName === this.selectedTab);

    if (selected && selected.enabled) {
      return;
    }

    selected = this.tabSelectorConfig.find(tab => tab.enabled);
    this.selectTab(selected.tabName);
  }

}
