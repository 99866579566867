import { Injectable } from '@angular/core';

import * as moment from 'moment';

import { ExportService } from '../../services/export.service';

@Injectable()
export class TabHeaderService {

  constructor(
    private exportService: ExportService,
  ) { }

  public exportToCSV(name: string, labels: string[], data: any[]) {
    if (!data) {
      return;
    }

    this.exportService.exportToCSVDetails(this.getFileName(name), labels, data);
  }

  public exportToPDF(name: string, labels: string[], data: any[]) {
    if (!data) {
      return;
    }

    this.exportService.exportToPDFDetails(this.getFileName(name), labels, data);
  }

  private getFileName(name: string): string {
    return `${name} ${moment(new Date).format('YYYY-MM-DD HH-mm-ss')}`;
  }

}
