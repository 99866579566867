import { Injectable, Injector } from '@angular/core';

@Injectable()
export class EnterpriseFilterWrapperService {
  constructor(private injector: Injector) {}

  public setSelectedEnterprise(enterprise) {
    let svc = this.injector.get('EnterpriseFilterService');
    svc.setSelectedEnterprise(enterprise);
  }

  public fetchSelectedEnterprise() {
    let svc = this.injector.get('EnterpriseFilterService');
    return svc.fetchSelectedEnterprise();
  }

  public async fetchEnterpriseList() {
    let svc = this.injector.get('EnterpriseFilterService');
    return await svc.fetchEnterpriseList();
  }
}
