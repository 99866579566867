import angular from 'angular';
import module from './module';
import moment from 'moment';

export default module.service('scheduleTotalWorkersService', [
  'FormatHelperService',
  'TimeFormatService',
  function (FormatHelperService, TimeFormatService) {
    function getNextDays(startTime, numberOfDays) {
      const daysBase = {
        value: {
          workers: 0,
          workerIds: [],
          durationMins: 0,
          text: '0 workers - 0 hrs',
        },
        writable: true,
      };
      const daysList = [];
      const days = {};
      const firstDay = moment(startTime);
      daysList.push(`D${FormatHelperService.returnLocalTimeString(firstDay, TimeFormatService.format('apiDate'))}`);
      Object.defineProperty(
        days,
        `D${FormatHelperService.returnLocalTimeString(firstDay, TimeFormatService.format('apiDate'))}`,
        angular.copy(daysBase)
      );

      for (let i = 0; i < numberOfDays - 1; i++) {
        const day = firstDay.add(1, 'day');
        daysList.push(`D${FormatHelperService.returnLocalTimeString(firstDay, TimeFormatService.format('apiDate'))}`);
        Object.defineProperty(
          days,
          `D${FormatHelperService.returnLocalTimeString(day, TimeFormatService.format('apiDate'))}`,
          angular.copy(daysBase)
        );
      }

      return {
        daysList,
        days,
      };
    }

    function countWorkersAndHoursForSession(session, _days, filterList) {
      const days = _days;
      const start = FormatHelperService.returnLocalTimeString(session.start_time, TimeFormatService.format('apiDate'));

      if (
        start &&
        days &&
        days.days &&
        days.days[`D${start}`] &&
        session.state.name !== 'Canceled' &&
        session.state.name !== 'Open' &&
        session.state.name !== 'Pending' &&
        session.state.name !== 'Interest' &&
        session.state.name !== 'Waiting Decision'
      ) {
        const startDay = days.days[`D${start}`];

        if (!startDay.workerIds.find((elm) => elm === session.user_id)) {
          if (filterList && filterList.length) {
            const filterLength = filterList.length;
            let foundAttrib = 0;
            filterList.forEach((filter) => {
              session.attrib_list.forEach((attrib) => {
                if (attrib.attrib_id == filter.attrib_id) {
                  if (attrib.value_list.find((value) => value.choice_id == filter.value_single_list)) {
                    console.log('ASDADS');
                    foundAttrib++;
                  }
                }
              });
            });
            if (foundAttrib === filterLength) {
              console.log('QWEQWEQW');
              startDay.workers++;
              startDay.workerIds.push(session.user_id);
              startDay.durationMins += session.duration;
            }
          } else {
            startDay.workers++;
            startDay.workerIds.push(session.user_id);
            startDay.durationMins += session.duration;
          }
        } else {
          if (filterList && filterList.length) {
            const filterLength = filterList.length;
            let foundAttrib = 0;
            filterList.forEach((filter) => {
              session.attrib_list.forEach((attrib) => {
                if (attrib.attrib_id == filter.attrib_id) {
                  if (attrib.value_list.find((value) => value.choice_id == filter.value_single_list)) {
                    foundAttrib++;
                  }
                }
              });
            });
            if (foundAttrib === filterLength) {
              startDay.workers++;
              startDay.workerIds.push(session.user_id);
              startDay.durationMins += session.duration;
            }
          } else {
            startDay.durationMins += session.duration;
          }
        }

        const workerText = startDay.workers === 1 ? `${startDay.workers} worker` : `${startDay.workers} workers`;

        const hours = Math.floor(startDay.durationMins / 60);
        const minutes = startDay.durationMins % 60;
        const hoursText = minutes ? `${hours}:${minutes} hrs` : `${hours} hrs`;

        startDay.text = `${workerText} - ${hoursText}`;
      }

      return days;
    }

    function getWorkersHoursForProvider(startTime, data, numberOfDays, filterList, search) {
      const providers = data;
      const searchTerm = search;
      let days = getNextDays(startTime, numberOfDays);

      providers.forEach(function (provider) {
        if (
          provider.name !== 'Canceled' &&
          provider.name !== 'Open' &&
          provider.name !== 'Pending' &&
          provider.name !== 'Interest' &&
          provider.name !== 'Waiting Decision'
        ) {
          if (searchTerm && searchTerm.length > 0) {
            // check for the smart search
            if (
              provider.searchables.findIndex((text) => text.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) > -1
            ) {
              provider.job_list.forEach(function (job) {
                job.session_list.forEach(function (session) {
                  days = countWorkersAndHoursForSession(session, days, filterList);
                });
              });
            }
          } else {
            provider.job_list.forEach(function (job) {
              job.session_list.forEach(function (session) {
                days = countWorkersAndHoursForSession(session, days, filterList);
              });
            });
          }
        }
      });

      return days;
    }

    function getWorkersHoursForJobs(startTime, data, numberOfDays, filterList, searchTerm) {
      const jobs = data;
      let days = getNextDays(startTime, numberOfDays);

      jobs.forEach(function (job) {
        if (searchTerm && searchTerm.length > 0) {
          if (job.searchables.findIndex((text) => text.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) > -1) {
            job.session_list.forEach(function (session) {
              days = countWorkersAndHoursForSession(session, days, filterList, searchTerm);
            });
          }
        } else {
          job.session_list.forEach(function (session) {
            days = countWorkersAndHoursForSession(session, days, filterList, searchTerm);
          });
        }
      });

      return days;
    }

    function updateTotalWorkersAndHours(startTime, data, dateFilter, typeFilter, filterList, searchTerm) {
      const oneWeek = 7;
      const oneDay = 1;
      // Providers Week View
      if (dateFilter === 'week' && typeFilter === 'Providers') {
        const days = getWorkersHoursForProvider(startTime, data, oneWeek, filterList, searchTerm);

        return days.daysList.map((day) => days.days[day].text);
      }

      // Providers Day View
      if (dateFilter !== 'week' && typeFilter === 'Providers') {
        const days = getWorkersHoursForProvider(startTime, data, oneDay, filterList, searchTerm);

        return days.daysList.map((day) => days.days[day].text);
      }

      // Jobs Week View
      if (dateFilter === 'week' && typeFilter !== 'Providers') {
        const days = getWorkersHoursForJobs(startTime, data, oneWeek, filterList, searchTerm);

        return days.daysList.map((day) => days.days[day].text);
      }

      // Jobs Day View
      if (dateFilter !== 'week' && typeFilter !== 'Providers') {
        const days = getWorkersHoursForJobs(startTime, data, oneDay, filterList, searchTerm);

        return days.daysList.map((day) => days.days[day].text);
      }
    }

    function filteredTotalWorkersHours(data) {
      return updateTotalWorkersAndHours(
        data.startTime,
        data.data,
        data.dateFilter,
        data.typeFilter,
        data.filterList,
        data.searchTerm
      );
    }

    return {
      filteredTotalWorkersHours,
    };
  },
]);
