import { Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { ScopeProvider } from '../../../app/upgraded.providers';

@Directive({
  selector: 'app-reporting',
  providers: [ScopeProvider],
})
export class ReportingUpgradeDirective extends UpgradeComponent {
  constructor(elementRef: ElementRef, injector: Injector) {
    super('reportingDirective', elementRef, injector);
  }
}
