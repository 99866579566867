import module from '../module';
export default module.controller('NowJobModalController', [
  '$scope',
  '$controller',
  '$modalInstance',
  'FormatHelperService',
  'AlertService',
  'EnterpriseFilterService',
  'enterprise_selection',
  'sessionManagerService',
  function (
    $scope,
    $controller,
    $modalInstance,
    FormatHelperService,
    AlertService,
    EnterpriseFilterService,
    enterprise_selection,
    sessionManagerService
  ) {
    $controller('BaseJobModalController', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      FormatHelperService: FormatHelperService,
      AlertService: AlertService,
      EnterpriseFilterService: EnterpriseFilterService,
      enterprise_selection: enterprise_selection,
      sessionManagerService: sessionManagerService,
    });

    $scope.title = 'Schedule Now ' + $scope.keySession;
    $scope.requestType = 2;

    $scope.checkForErrors = function (formData) {
      return [];
    };
  },
]);
