import { Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { ScopeProvider } from 'app/upgraded.providers';

@Directive({
  selector: 'app-job-order-report',
  providers: [ScopeProvider],
})
export class JobOrderReportUpgradeDirective extends UpgradeComponent {
  constructor(elementRef: ElementRef, injector: Injector) {
    super('jobOrderReportDirective', elementRef, injector);
  }
}
