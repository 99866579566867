import { Inject, Injectable } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SecureHttpService } from './secure.http.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ForceFilterService {
  private cachedAttribs: any[];
  private currentFilter = {};
  private currentLocationId = undefined;

  constructor(private http: SecureHttpService, @Inject('locationManagerService') private locationManagerService) {}

  public async fetchRequiredAttribs(): Promise<any> {
    if (this.cachedAttribs) {
      return Promise.resolve(this.cachedAttribs);
    }
    return this.http
      .post('get_realm_attribs', {
        realm_list: [{ realm: 15 }],
      })
      .pipe(
        switchMap((response) => {
          if (response.success) {
            this.cachedAttribs = response.attrib_list.filter((a) => a.should_force_filter);

            const locationAttrib = this.cachedAttribs.find((x) => x.type === 'location');
            if (locationAttrib) {
              return combineLatest([of(this.cachedAttribs), this.locationManagerService.getAllLocations()]);
            }

            return of([this.cachedAttribs, null]);
          }
        }),
        map(([attribs, locations]) => {
          if (locations) {
            const result = _.flatten(Object.keys(locations).map((key) => locations[key]));
            const locationAttrib = this.cachedAttribs.find((x) => x.type === 'location');
            locationAttrib.choice_list = result.map((x) => ({
              choice_id: x.location_id,
              name: x.name,
            }));
          }
          return attribs;
        })
      )
      .toPromise();
  }

  public setFilter(filters) {
    const tuples = [];
    filters
      .filter((x) => x.type !== 'location')
      .forEach((filter) => {
        tuples.push(...filter.value.map((choice) => [filter.attrib_id, choice.choice_id]));
      });
    this.currentFilter = tuples;

    sessionStorage.setItem('admin_filter', JSON.stringify(tuples));

    const location = filters.find((x) => x.type === 'location');
    if (location) {
      this.currentLocationId = location.value[0]?.choice_id;
      if (this.currentLocationId) {
        sessionStorage.setItem('admin_location_id', JSON.stringify(this.currentLocationId));
      }
    }
  }

  public getFilter() {
    this.currentFilter = JSON.parse(sessionStorage.getItem('admin_filter'));
    return this.currentFilter || [];
  }

  public getLocationId() {
    this.currentLocationId = sessionStorage.getItem('admin_location_id');
    return +this.currentLocationId || undefined;
  }
}
