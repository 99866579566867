import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as moment from 'moment';

import { SecureHttpService } from '../../../../shared/services/secure.http.service';
import { TimeService } from '../../../../shared/services/time.service';
import { AuthenticationService } from '../../../../shared/services/authentication.service';
import { EnterpriseFilterWrapperService } from './../../../../shared/services/enterprise-filer-wrapper.service';
import { BranchFilterWrapperService } from './../../../../shared/services/branch-filer-wrapper.service';
import { EnumsService } from './../../../../shared/services/enums.service';

import { ChartModel } from '../../models/chart.model';
import { ChartFilterOption } from '../../models/chart-filter.model';
import { TimeFilterOption } from '../../models/time-filter.model';

import { IChart } from '../../../../shared/interfaces/IChart';
import { IChartDataSource } from '../../../../shared/interfaces/IChartDataSource';
import { ITimeRange } from '../../../../shared/interfaces/ITimeRange';
import { IUser } from '../../../../shared/interfaces/IUser';

import { WorkerReportExportLabels, JobOrderReportExportLabels } from './chart-labels';

@Injectable()
export class NewReportsService {

  private currentUser: IUser;

  constructor(
    private secureHttpService: SecureHttpService,
    private timeService: TimeService,
    private enumsService: EnumsService,
    private authenticationService: AuthenticationService,
    private branchFilterWrapperService: BranchFilterWrapperService,
    private enterpriseFilterWrapperService: EnterpriseFilterWrapperService,
  ) {
    this.currentUser = this.authenticationService.getCurrentUser();
  }

  public getChartData(data): Observable<any> {
    switch (data ?.chartType) {

      case this.enumsService.NewReportTypes.WorkerReport:
        const payload = this.getPayload(data ?.selectedTime);

        return this.secureHttpService.postLocalhost('get_worker_report', payload)
          .pipe(
            map((data) => { return this.convertData(data, ['worker_list', 'login_dict', 'status_dict']) })
          );

      case this.enumsService.NewReportTypes.JobOrderReport:
        return this.secureHttpService.postLocalhost('get_job_order_report', payload)
          .pipe(
            map((data) => { return this.convertData(data, ['jobs_list', 'cancel_dict', 'status_dict']) })
          );
    }
  }

  public getFilters() {
    const filters = [];

    // if you update the options array you should update the switch in the getChart method below
    filters.push({
      name: 'Reports',
      options: [
        new ChartFilterOption('Please select a report', this.enumsService.NewReportTypes.PleaseSelect),
        new ChartFilterOption('Worker Report', this.enumsService.NewReportTypes.WorkerReport),
      ],
    }
    );

    // if you update the options array you should update the switch in the getTImeRagne method in the TimeService
    filters.push({
      name: 'Time Range',
      options: [
        new TimeFilterOption('Month To Date', 1),
        new TimeFilterOption('One Month Trailing', 2),
        new TimeFilterOption('Week To Date', 3),
        new TimeFilterOption('Last Week', 4),
        new TimeFilterOption('Last Month', 5),
        new TimeFilterOption('Today', 6),
        new TimeFilterOption('Future', 7),
        new TimeFilterOption('Custom', 8),
      ],
    });

    return filters;
  }

  public getChart(id) {
    switch (id) {
      case this.enumsService.NewReportTypes.PleaseSelect:
        return new ChartModel('-1', '', '');
      case this.enumsService.NewReportTypes.WorkerReport:
        return new ChartModel('Worker Report', 'Date', 'Workers');
      case this.enumsService.NewReportTypes.JobOrderReport:
        return new ChartModel('Job Order Report', 'Date', 'Job Orders');
    }
  }

  public getTime(id): ITimeRange {
    return this.timeService.getRange(id);
  }

  public getPayload(selectedTime: ITimeRange, intervalLen?: number) {
    const payload = {
      ...selectedTime,
      branch_id: this.branchFilterWrapperService.getSelectedBranch() ?.id,
      enterprise_id: this.enterpriseFilterWrapperService.fetchSelectedEnterprise() ?.enterprise_id,
    };

    if (intervalLen) {
      payload['interval_len'] = intervalLen;
    }

    return payload;
  }

  private convertData = (data: any, dataProps: string[]): IChartDataSource => {
    if (!data) {
      return data;
    }

    const data_list = data.data ?.[dataProps[0]];
    if (!data_list || !data_list.length) {
      return data;
    }

    const newDataList = [];

    Object.keys(data_list[0]).forEach((item) => {
      if (
        typeof data_list[0][item] === 'string'
        && moment(data_list[0][item]).isValid()
      ) {
        console.log(data_list[0][item])
        return;
      }

      const newObj = this.convertDataListToObject(item, data_list);

      newDataList.push(newObj);
    });

    const chartData: IChartDataSource = this.getConvertedChartData(data, data_list, newDataList, dataProps);

    return chartData;
  }

  private convertDataListToObject(item: string, data_list: any) {
    return {
      name: this.convertItemName(item),
      series: data_list.map((listItem) => {
        return {
          name: moment(listItem.date).format('YYYY MM DD'),
          value: listItem[item],
        };
      }),
    };
  }

  private getConvertedChartData(data: any, origDataList: any[], newDataList: any[], dataProps: string[]) {
    const retVal = {
      branch_id: data.branch_id,
      enterprise_id: data.enterprise_id,
      market_place_id: data.market_place_id,
      start_date: data.start_date,
      end_date: data.end_date,
      execute_level: data.execute_level,
      message: data.message,
      success: data.success,
      list: newDataList,
      origList: origDataList,
      [dataProps[1]]: data ?.data[dataProps[1]],
      [dataProps[2]]: data ?.data[dataProps[2]],
    };

    return retVal;
  }

  private convertItemName(word: string): string {
    if (typeof word !== "string") {
      return '';
    }

    return word
      .split('_')
      .map(this.upperCaseFirstLetter)
      .join(' ')
  }

  private upperCaseFirstLetter(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1)
  }

  public getChartDataToExport(selectedChart, chartData): IChart {
    const labels: string[] = this.getLabels(selectedChart ?.value);
    const data: any[] = this.getConvertedExportData(selectedChart ?.value, chartData);

    const convertedData = {
      name: selectedChart ?.displayValue,
      labels,
      data,
    };

    return convertedData;
  }

  private getConvertedExportData(selectedChart: number, chartData: any): any[] {
    switch (selectedChart) {
      case this.enumsService.NewReportTypes.WorkerReport:
        return this.convertWorkerReportToExport(chartData ?.origList);
      case this.enumsService.NewReportTypes.JobOrderReport:
        return this.convertJobOrderReportToExport(chartData ?.origList);
    }
  }

  private getLabels(selectedChart: number): string[] {
    switch (selectedChart) {
      case this.enumsService.NewReportTypes.WorkerReport:
        return WorkerReportExportLabels;
      case this.enumsService.NewReportTypes.JobOrderReport:
        return JobOrderReportExportLabels;
    }
  }

  private convertWorkerReportToExport(list: any[]): any[] {
    return list.map(item => {
      item.date = item.date ? moment(item.date).format('YYYY MM DD') : item.date
      return item
    });
  }

  private convertJobOrderReportToExport(list: any[]): any[] {
    return list.map(item => {
      item.date = item.date ? moment(item.date).format('YYYY MM DD') : item.date
      return item
    });
  }

}