import module from './module';
export default module.filter('providerOnline', [
  function () {
    return function (items, param) {
      if (!param || !items) {
        return items;
      }
      return items.filter(function (provider) {
        switch (param) {
          case 'All':
            return true;
          case 'Online':
            return provider.login_status === 1;
          case 'Offline':
            return provider.login_status === -1 || provider.login_status === 0;
          default:
            return false;
        }
      });
    };
  },
]);
