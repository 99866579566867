import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxChartsModule } from '@swimlane/ngx-charts';

import { PAGES, PAGES_SERVICES } from './pages';
import { COMPONENTS } from './components';

import {
  sessionServiceProvider,
  providerServiceProvider,
  enterpriseFilterServiceProvider,
  branchServiceProvider,
} from '../../upgraded.providers';

import { SharedModule } from './../../shared/shared.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxChartsModule,
    SharedModule,
  ],
  declarations: [COMPONENTS, PAGES],
  exports: [COMPONENTS, PAGES],
  providers: [
    sessionServiceProvider,
    providerServiceProvider,
    enterpriseFilterServiceProvider,
    branchServiceProvider,
    PAGES_SERVICES,
  ],
})
export class NewReportingModule {
  constructor() {}
}
