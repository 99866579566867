import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseWidget } from './base.widget';
import * as fromRoot from '../store';
import { FilterRequestList } from '../store/actions';

@Component({
  selector: 'fill-rate-component',
  templateUrl: './fill.rate.component.html'
})
export class FillRateComponent extends BaseWidget {
  public chartData: any[];
  public chartColors: any;
  constructor(store: Store<fromRoot.State>) {
    super(store);
  }

  loadDataTemplate(list) {
    if (!list) {
      return;
    }
    let colorMap = {
      Open: '#fbc53e',
      Completed: '#2d3941',
      Committed: '#7f5fa4',
      Canceled: '#c74347',
      Active: '#90c755',
      Denied: '#2d3941',
      Pending: '#5684ab'
    };

    this.count = list.length;
    this.titleValue = this.count;
    this.titleText = 'Requested';
    let statusMap = {};
    list.forEach(item => {
      let statusCount = 1;
      if (item.status.name === 'Pending' && item.total_worker_count) {
        statusCount = item.total_worker_count;
      }

      if (statusMap[item.status.name]) {
        statusMap[item.status.name].count += statusCount;
      } else {
        statusMap[item.status.name] = {
          count: statusCount,
          type: item.status.type
        };
      }
    });
    this.titleValue = Object.keys(statusMap)
      .map(key => statusMap[key].count)
      .reduce((a, b) => a + b, 0);

    this.data = Object.keys(statusMap).map(key => {
      let value = statusMap[key];
      let percentage = Math.floor((value.count / this.titleValue) * 100);
      return {
        name: key,
        value: value.count,
        type: value.type,
        percentage: percentage,
        color: colorMap[key]
      };
    });
    this.chartColors = { domain: this.data.map(d => d.color) };
    this.data.sort((a, b) => {
      return b.value - a.value;
    });
  }

  filterAll() {
    let action = new FilterRequestList({
      showAll: true
    });
    this.store.dispatch(action);
  }

  filterByStatus(status: number) {
    let action = new FilterRequestList({
      showLate: false,
      showLong: false,
      status: status,
      cancelCode: null
    });
    this.store.dispatch(action);
  }

  clearStatusFilter() {
    let action = new FilterRequestList({
      showLate: false,
      showLong: false,
      status: null,
      cancelCode: null
    });
    this.store.dispatch(action);
  }

  chartSelect(e) {
    let point = this.data.find(p => p.name === e.name);
    if (point) {
      this.filterByStatus(point.type);
    }
  }
}
