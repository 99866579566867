import { Component, OnInit } from '@angular/core';
import version from '../../../scripts/version';
import { IUser } from 'app/shared/interfaces';
import { EnumsService } from '../../../shared/services/enums.service';
import { AppStateService } from '../../../shared/services/app-state.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';


type footerDetails = {
  footerCopyright: string;
  footerEmail: string;
  footerPhone: string;
}
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  footerDetails: footerDetails = null;
  supportPhoneNumber = '';
  version = version?.semantic ?? ''
  token = '';

  private currentUser: IUser;

  constructor(private readonly appStateService: AppStateService, private readonly authenticationService: AuthenticationService, private readonly enumsService: EnumsService) {
    this.currentUser = this.authenticationService.getCurrentUser();
    this.token = this.currentUser?.token ?? ''
    this.setSupportPhoneNumber();
  }

  ngOnInit(): void {
    this.loadInitData();
  }

  private loadInitData(): void {
    const initData = this.appStateService.initData;

    if (initData) {
      this.setFooterDetails(initData);
    } else {
      setTimeout(() => {
        this.loadInitData();
      }, 250);
    }
  }

  private setFooterDetails(initData: any): void {
    if (initData?.enterprise_list?.length > 0) {
      this.footerDetails = {
        footerCopyright: initData.enterprise_list[0].footer_copyright,
        footerEmail: initData.enterprise_list[0].footer_email,
        footerPhone: initData.enterprise_list[0].footer_phone || {},
      }
    }
  }

  private setSupportPhoneNumber(): void {
    if (this.currentUser?.user_type == this.enumsService.UserType.MarketPlaceAdmin) {
      this.supportPhoneNumber = this.currentUser?.market_place_info.support_phone;
    } else if (
      this.currentUser?.user_type == this.enumsService.UserType.EnterpriseAdmin ||
      this.currentUser?.user_type == this.enumsService.UserType.Requester
    ) {
      this.supportPhoneNumber = this.currentUser?.enterprise_info.support_phone;
    }
  }
}
