import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Worker } from '../../../shared/models/worker';

@Component({
  templateUrl: './worker-back-profile.component.html',
  styleUrls: ['./worker-back-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-worker-back-profile',
})
export class WorkerBackProfileComponent {
  @Input() worker: Worker;
  @Output() backClick = new EventEmitter<any>();

  public goBack() {
    // If there are any subscribers to the backClick output, invoke them
    if (this.backClick.observers.length > 0) {
      this.backClick.emit();
    } else {
      window.history.back();
    }
  }
}
