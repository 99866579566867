import angular from 'angular';
import module from '../module';
import * as enums from '../../enums';

export default module.controller('JobReceiptModalController', [
  '$scope',
  '$modalInstance',
  'completionDetails',
  'AlertService',
  '$location',
  'providerManagerService',
  'sessionManagerService',
  'DOMAIN',
  'generalDataService',
  function (
    $scope,
    $modalInstance,
    completionDetails,
    AlertService,
    $location,
    providerManagerService,
    sessionManagerService,
    DOMAIN,
    generalDataService
  ) {
    $scope.keySession = generalDataService.getKeySession();
    $scope.submitBtnDisbaled = false;
    $scope.domain = DOMAIN;
    $scope.data = {};
    $scope.receipt = angular.copy(completionDetails);
    $scope.request = $scope.receipt.requestDetails;
    $scope.reviewDetails = $scope.receipt.review;
    $scope.avatarIcon = `${enums.AssetsFolder.images}avatar.png`;

    const getProvider = function (userId) {
      providerManagerService.getProvider(userId).then(function (provider) {
        $scope.provider = provider;
      });
    };
    getProvider($scope.request.serv_user_id);

    // logged in status check for class association
    $scope.loggedInStatus = function (status_code) {
      if (status_code == 1) {
        return 'logged-in-state';
      } else if (status_code == 0) {
        return 'logged-out-state';
      } else {
        return 'no-logged-in-state';
      }
    };
    $scope.popover = {
      loggedInStatus: 'app/views/templates/avatar_loggedin_status_legend.html',
    };

    $scope.formatData = function () {
      $scope.submitBtnDisbaled = true;
      const formatedReview = sessionManagerService.formatSessionReviewData($scope.reviewDetails);
      $scope.ok(formatedReview); // call to send to server
    };

    $scope.ok = function (attribs) {
      const obj = {
        end_type: 1,
        request_id: $scope.request.request_id,
        attrib_list: attribs,
      };
      sessionManagerService.endSessionRequest(obj).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
            $modalInstance.close();
            location.href = '/#/session';
          } else {
            AlertService.errorAlert(response.message);
            $modalInstance.close();
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
        }
      );
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  },
]);
