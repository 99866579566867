import module from './module';
import 'jquery';

module.directive('contenteditable', [
  '$sce',
  function ($sce) {
    return {
      restrict: 'A', // only activate on element attribute
      require: '?ngModel', // get a hold of NgModelController
      link: function (scope, element, attrs, ngModel) {
        if (!ngModel) return; // do nothing if no ng-model

        // Specify how UI should be updated
        ngModel.$render = function () {
          const pattern = /\*\|(.*?)\|\*/g;
          let newVal = ngModel.$viewValue;
          if (!attrs.disableparsing) {
            if (ngModel.$viewValue) {
              const highlight = ngModel.$viewValue.match(pattern);
              if (highlight) {
                newVal = ngModel.$viewValue.slice();
                highlight.forEach((item) => {
                  newVal = newVal.replace(item, `<span class="template-highlight">${item}</span>`);
                });
              }
            }
          }
          element.html($sce.trustAsHtml(newVal || '').toString());
        };

        // Listen for change events to enable binding
        element.on('blur', function () {
          scope.$evalAsync(read);
        });
        ngModel.$render();

        // Write data to the model
        function read() {
          let html = element.html();
          // When we clear the content editable the browser leaves a <br> behind
          // If strip-br attribute is provided then we strip this out
          if (attrs.stripBr && html === '<br>') {
            html = '';
          }
          if (html && !attrs.disableparsing) {
            const div = document.createElement('div');
            div.innerHTML = html;
            html = div.innerText;
          }
          ngModel.$setViewValue(html);
          ngModel.$render();
        }
      },
    };
  },
]);
