import module from './module';
import './mainController';
import './workersController';
import './analyticsController';

// Admin
import './adminControllers/adminController';
import './adminControllers/apiLogs/apiLogsTabController';
import './adminControllers/apiLogs/outboundApiLogsTabController';
import './adminControllers/apiLogs/apiLogsDetailModalController';
import './adminControllers/editEnterpriseModalController';
import './adminControllers/editEscalationModalController';
import './adminControllers/editJobModalController';
import './adminControllers/editLocationModalController';
import './adminControllers/editMeetingPlaceModalController';
import './adminControllers/editPoolModalController';
import './adminControllers/editPriceModalController';
import './adminControllers/editProviderModalController';
import './adminControllers/editRequesterModalController';
import './adminControllers/editRoleModalController';
import './adminControllers/editUserModalController';
import './adminControllers/enterpriseTabController';
import './adminControllers/escalationsTabController';
import './adminControllers/jobsTabController';
import './adminControllers/meetingPlaceTabController';
import './adminControllers/newEnterpriseModalController';
import './adminControllers/newEscalationModalController';
import './adminControllers/newJobModalController';
import './adminControllers/newLocationModalController';
import './adminControllers/newMeetingPlaceModalController';
import './adminControllers/newPoolModalController';
import './adminControllers/newPriceModalController';
import './adminControllers/newProviderModalController';
import './adminControllers/newRequesterModalController';
import './adminControllers/newRoleModalController';
import './adminControllers/newUserModalController';
import './adminControllers/poolTabController';
import './adminControllers/priceTabController';
import './adminControllers/providerTabController';
import './adminControllers/providerImageModalController';
import './adminControllers/requesterTabController';
import './adminControllers/roleTabController';
import './adminControllers/UserTabController';
import './adminControllers/invitationsModalController';
import './adminControllers/communications/communicationsTabController';
import './adminControllers/communications/previewEmailController';
import './adminControllers/communications/previewTextMessageController';
import './adminControllers/communications/previewNotificationController';
import './adminControllers/communications/editEmail';
import './adminControllers/communications/editNotification';
import './adminControllers/communications/editTextMessage';
import './adminControllers/bulkLoadedModalController';
import './adminControllers/bulkInviteWarningController';
import './adminControllers/saveJobLocationModal';

import './jobControllers/jobTabController';
import './jobControllers/jobRootController';

// Job
import './jobControllers/imageViewerModalController';
import './jobControllers/jobController';
import './jobControllers/jobReceiptModalController';
import './jobControllers/providerModalController';
import './jobControllers/birdsEyeController';
import './jobControllers/massCancelModalController';
import './jobControllers/endAssignmentModalController';

// Login
import './loginControllers/modalController';
import './loginControllers/setupController';
import './loginControllers/loggedOutController';

// Reporting
import './reportingControllers/analyticsController';
import './reportingControllers/changeVerifyController';
import './reportingControllers/changeVerifyModalController';
import './reportingControllers/mapDetailViewModalController';
import './reportingControllers/mapViewsController';
import './reportingControllers/reportingController';
import './reportingControllers/reportsDashboardController';
import './reportingControllers/timeReportsController';
import './reportingControllers/repeatJobOrderModalController';
import './reportingControllers/repeatDataShowController';
import './reportingControllers/termsAndConditionsModalController';
import './reportingControllers/shiftDetailsModalController';
import './reportingControllers/timeReportsChangeMobileModalController';

// Schedule
import './scheduleControllers/assignmentModalController';
import './scheduleControllers/changeTimeModalController';
import './scheduleControllers/checkInModalController';
import './scheduleControllers/checkOutModalController';
import './scheduleControllers/escalationModalController';
import './scheduleControllers/newScheduleJobModalController';
import './scheduleControllers/scheduleController';
import './scheduleControllers/sessionDetailsModalController';
import './scheduleControllers/transferSplitModalController';
import './scheduleControllers/modalPickListController';
import './scheduleControllers/editJobOrderModalController';
import './scheduleControllers/changeJobOrderModalController';

// Session
import './sessionControllers/baseEditController';
import './sessionControllers/baseJobModalController';
import './sessionControllers/nowJobModalController';
import './sessionControllers/replicateSessionModalController';
import './sessionControllers/changeSessionAssignmentModalController';
import './sessionControllers/requestScheduleJobModalController';
import './sessionControllers/resendModalController';
import './sessionControllers/sessionController';
import './sessionControllers/sessionNotificationController';
import './sessionControllers/sessionQueueController';
import './sessionControllers/trackSessionModalController';
import './sessionControllers/waitingApprovalModalController';
import './sessionControllers/showInterestedProvidersModalController';
import './sessionControllers/updateRequestAttribsModalController';
import './sessionControllers/assignInterestedProvidersModalController';
import './sessionControllers/offerInterestedProvidersModalController';
import './sessionControllers/resendJobOrderModalController';

// Support
import './supportControllers/supportController';

import './adminControllers/branchTabController';
import './adminControllers/newBranchModalController';
import './adminControllers/editBranchModalController';

export default module;
