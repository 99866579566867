import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ProfileField, ProfileFieldType } from '../../models/worker';
import Env from '../../../scripts/env.js';
import { AuthenticationService, PermissionsService } from '../../../shared/services';
import { Store } from '@ngrx/store';
import { State } from '../../../store';
import { LogConfidentialView } from '../../../workers/store/actions';

@Component({
  templateUrl: './profile-attrib.component.html',
  styleUrls: ['./profile-attrib.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-profile-attrib',
  encapsulation: ViewEncapsulation.None,
})
export class ProfileAttribComponent {
  @Input() attrib: ProfileField;
  @Output() imageClick = new EventEmitter<string>();
  @Output() logConfidentialView = new EventEmitter<ProfileField>();

  constructor(
    private authenticationService: AuthenticationService,
    private permissionService: PermissionsService,
    private store: Store<State>
  ) {}

  public showConfidentialData = false;

  ProfileFieldType = ProfileFieldType;

  public hasConfidentialPermission = this.authenticationService.getPermission(
    this.permissionService.PERMISSIONS.CONFIDENTIAL_FIELDS
  );

  public get canShowValue(): boolean {
    return !this.attrib.confidential || (this.attrib.confidential && this.showConfidentialData);
  }

  public getSingleListValue() {
    const result = this.attrib.choice_list.find((x) => x.selected);
    if (result) {
      return result.name;
    }
    return '';
  }

  public getImagePath() {
    return Env.DOMAIN + this.attrib.data;
  }

  public imageClicked(src) {
    this.imageClick.emit(src);
  }

  public isLink() {
    if (this.attrib?.display_value) {
      return this.attrib.display_value.indexOf('http://') === 0 || this.attrib.display_value.indexOf('https://') === 0;
    }
    return false;
  }

  public toggleConfidential() {
    this.showConfidentialData = !this.showConfidentialData;
    if (this.showConfidentialData) {
      this.store.dispatch(new LogConfidentialView(this.attrib));
    }
  }
}
