import module from './module';
export default module.directive('dirSimplePieChart', function () {
  return {
    restrict: 'E',
    scope: {
      verifiedrequests: '=',
      totalrequest: '='
    },
    controller: [
      '$scope',
      function ($scope) {
        $scope.calculatePercent = function () {
          $scope.fillPercent = $scope.verifiedrequests / $scope.totalrequest * -100 + 0.1 + 's';
        };

        $scope.calculatePercent();
        $scope.$watch('verifiedrequests', $scope.calculatePercent);
        $scope.$watch('totalrequest', $scope.calculatePercent);
      }
    ],
    templateUrl: 'app/views/templates/simple_pie_chart.html'
  };
});
