import * as actions from './actions';
import { initialWorkersState, WorkersState } from './state';

export function reducer(state = initialWorkersState, action: actions.Actions): WorkersState {
  switch (action.type) {
    case actions.FETCH_WORKERS:
      return {
        ...state,
        rawResult: null,
      };

    case actions.WORKERS_FETCHED:
      const providers = state.list || {};
      action.result.forEach((provider) => {
        providers[provider.provider_id] = provider;
      });

      return {
        ...state,
        rawResult: action.rawResult,
        list: { ...providers },
      };

    case actions.WORKER_DETAILS_FETCHED:
      const providers2 = state.list || {};
      let worker = providers2[action.providerId];
      worker = {
        ...worker,
        ...action.worker,
        detailsLoaded: true,
        profile_picture: worker ? worker.profile_picture : '',
      };

      providers2[action.providerId] = worker;
      return {
        ...state,
        list: { ...providers2 },
      };

    case actions.SELECT_TAB:
      return {
        ...state,
        selectedTab: action.tab,
      };

    case actions.SELECT_CALENDAR_VIEW:
      return {
        ...state,
        selectedCalendarView: action.view,
      };

    case actions.EVETNS_FETCHED:
      return {
        ...state,
        events: action.events,
      };

    case actions.WORKER_BLACKLIST_FETCHED:
      const blacklist = state.blacklistedEnterprises || {};
      blacklist[action.userId] = action.enterprises;
      blacklist[action.userId]?.sort((a, b) => a.name?.localeCompare(b.name));
      return {
        ...state,
        blacklistedEnterprises: { ...blacklist },
      };

    case actions.ENTERPRISE_BLACKLIST_FETCHED:
      const workerList = state.blacklistedWorkers || {};
      workerList[action.enterpriseId] = action.workers;
      return {
        ...state,
        blacklistedWorkers: { ...workerList },
      };

    default:
      return state;
  }
}
