import module from '../module';
import * as enums from '../../enums';
import Swal from 'sweetalert2';

export default module.controller('InvitationsModalController', [
  '$scope',
  '$modalInstance',
  '$timeout',
  'adminUserService',
  'AlertService',
  async function ($scope, $modalInstance, $timeout, adminUserService, AlertService) {
    $scope.submitBtnDisbaled = false;

    $scope.invites = await adminUserService.getInvites();
    $scope.icons = {
      pending: `${enums.AssetsFolder.svg}invitations/pending.svg`,
      expired: `${enums.AssetsFolder.svg}invitations/expired.svg`,
      delete: `${enums.AssetsFolder.svg}invitations/delete.svg`,
      resend: `${enums.AssetsFolder.svg}invitations/resend.svg`,
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.removeInvite = async function (invite) {
      const config = {
        title: 'Are you sure?',
        // eslint-disable-next-line quotes
        text: `This will remove the invite for the user and they won't be able to sign up.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Yes, remove invite',
      };
      const returnHandler = (isConfirm) => {
        if (isConfirm) {
          adminUserService.removeInvite(invite.token).then(
            async function (result) {
              AlertService.successAlert('Invite removed successfully!');
              $scope.invites = await adminUserService.getInvites();
            },
            function () {
              AlertService.errorAlert('There was an error removing the invite!');
            }
          );
        }
      };
      const { value: returnValue } = await Swal.fire(config);
      returnHandler(returnValue);
    };

    $scope.resendInvite = async function (invite) {
      const config = {
        title: 'Are you sure?',
        text: 'This will resend the invite for the user.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#159f7b',
        confirmButtonText: 'Yes, resend invite',
      };
      const returnHandler = (isConfirm) => {
        if (isConfirm) {
          adminUserService.resendInvite(invite.token).then(
            async function (result) {
              AlertService.successAlert('Invite resent successfully!');
              $scope.invites = await adminUserService.getInvites();
            },
            function () {
              AlertService.errorAlert('There was an error resending the invite!');
            }
          );
        }
      };
      const { value: returnValue } = await Swal.fire(config);
      returnHandler(returnValue);
    };
  },
]);
