import { Injectable } from '@angular/core';
import * as parser from 'ua-parser-js';

/**
* This class provides the App State service temporaryly, this should be changed to a Store.
*/
@Injectable()
export class UserAgentService {

  private uap: parser.UAParser;

  constructor() {
    this.uap = new parser.UAParser();
  }

  public getUaParser() {
    return this.uap;
  }

  public getDeviceType() {
    return this.uap.getDevice().type;
  }

  public getBrowser() {
    return this.uap.getBrowser();
  }

  public getOs() {
    return this.uap.getOS();
  }

  public isMobileDevice() {
    return this.uap.getDevice().type === 'mobile';
  }

  public isMobileOrTabletDevice() {
    return this.uap.getDevice().type === 'mobile' || this.uap.getDevice().type === 'tablet';
  }

  public isTabletDevice() {
    return this.uap.getDevice().type === 'tablet'
  }
}
