import angular from 'angular';
import module from './module';
import moment from 'moment';

export default module.factory('CalendarJob', [
  '$http',
  'BASE_URL',
  'FormatHelperService',
  'OverlapService',
  'TimeFormatService',
  function ($http, BASE_URL, FormatHelperService, OverlapService, TimeFormatService) {
    // Sort sesison list by time...needed in order to build overlap blocks
    function sortByStartTime(a, b) {
      return FormatHelperService.returnSortedValue(new Date(b.start_time), new Date(a.start_time));
    }
    // remove displayValue false sessions
    function sessionDisplayFilter(session) {
      if (session.displayValue == true) {
        return true;
      } else {
        return false;
      }
    }

    // setProviderListDisplayBoolean
    const _displayableStateNames = [
      'open',
      'committed',
      'completed',
      'committed staff',
      'committed contractor',
      'active',
      'canceled',
    ];

    function isSessionDisplayable(stateName) {
      return _displayableStateNames.indexOf(stateName);
    }
    function setProviderSessionDisplay(session) {
      const status = session.state.name.toLowerCase();
      if (status === 'pending') {
        session.displayValue = false;
      }
      if (status === 'committed' && session.committed != session.user_id) {
        session.displayValue = false;
      }
      if (status === 'open' && session.association == false) {
        session.displayValue = false;
      }
    }

    // 1. setting times and initial session display value
    function setSessionDetails(session, dateRangeObj) {
      const _dateRangeObj = dateRangeObj;
      const tempStart = moment.utc(session.start_time).local().clone();
      session.start = tempStart.clone().format(TimeFormatService.format('fullDateTimeSecs'));
      session.end = tempStart.add(session.duration, 'm').format(TimeFormatService.format('fullDateTimeSecs'));
      if (
        tempStart.isBefore(moment(_dateRangeObj.start, TimeFormatService.format('api'))) &&
        moment(session.end, TimeFormatService.format('fullDateTimeSecs')).isSameOrBefore(
          moment(_dateRangeObj.start, TimeFormatService.format('api'))
        )
      ) {
        session.displayValue = false;
      } else {
        session.displayValue = true;
      }
    }

    // 3. Set duration total
    function setDurationTotal(list, parentObj, dateRange) {
      angular.forEach(list, function (session) {
        const _start = moment(session.start, TimeFormatService.format('fullDateTimeSecs')).clone();
        const _end = moment(session.end, TimeFormatService.format('fullDateTimeSecs')).clone();
        const _viewStart = moment(dateRange.start, TimeFormatService.format('api')).clone();
        const _viewEnd = moment(dateRange.end, TimeFormatService.format('api')).clone();

        if (session.state.type === 7) {
          return;
        }

        if (!session.child || (session.association && session.state.type !== 4)) {
          // 4 is open and 7 is canceled
          // entire session within view
          if (_start.isSameOrAfter(_viewStart) && _end.isSameOrBefore(_viewEnd)) {
            parentObj.durationTotal += session.duration;
          }
          // starts before view BUT ends within
          if (_start.isBefore(_viewStart) && _end.isSameOrBefore(_viewEnd)) {
            const _tempDur = _end.diff(_viewStart, 'm');
            parentObj.durationTotal += _tempDur;
          }
          // starts within view AND ends AFTER
          if (_start.isSameOrBefore(_viewEnd) && _end.isAfter(_viewEnd)) {
            const _tempDur = _viewEnd.diff(_start, 'm');
            parentObj.durationTotal += _tempDur;
          }
        }
      });
    }
    // 6. Set Provider Details
    function setProviderDetails(provider, sessionIds, dateRangeObj, jobId) {
      const _provider = provider;

      _provider.durationTotal = 0;
      _provider.session_list.sort(sortByStartTime);
      _provider.session_list.forEach((session) => {
        session.isJob = session.session_id !== jobId;
        session.user_id = _provider.user_id;
        session.child = true;
        // set display association value to current job sessions
        // needed for color block filter display later
        if (sessionIds.indexOf(session.session_id) != -1) {
          session.association = true;
        } else {
          session.association = false;
        }
        setSessionDetails(session, dateRangeObj);
        setProviderSessionDisplay(session);
      });

      // Remove hidden sessions
      const _a = _provider.session_list.filter(sessionDisplayFilter);
      _provider.session_list = _a;

      // Calculate Provider duration total off of displayable sessions
      setDurationTotal(_provider.session_list, _provider, dateRangeObj);
    }

    // build child row color block display
    function buildChildColorBlockDisplay(provider) {
      const childColorSessionList = provider.session_list.filter(childColorBlockCheck);
      if (childColorSessionList.length > 0) {
        const displaybleSessionList = provider.session_list.filter(applicableSessionDisplay);
        provider.session_list = displaybleSessionList;
        provider.displayValue = true;
      } else {
        provider.session_list = [];
        provider.displayValue = false;
      }
    }
    // filter job > provider > session list checking for color display blocks only
    // If color blocks then will need to run applicableSessionDisplay filter
    function childColorBlockCheck(session) {
      if (session.association == true && session.state.name.toLowerCase() === 'open') {
        return true;
      } else if (session.association == true && session.committed == session.user_id) {
        return true;
      } else {
        return false;
      }
    }
    // filter job > provider > session list
    function applicableSessionDisplay(session) {
      if (session.association == true && session.displayValue == true) {
        return true;
      } else if (session.association == true && session.state.name.toLowerCase() === 'open') {
        return true;
      } else if (session.association == false && session.displayValue == true) {
        return true;
      } else {
        return false;
      }
    }

    function buildSearchableTextList(sessionList, providerList) {
      const searchables = [];

      sessionList.forEach((sessionListItem) => {
        sessionListItem.attrib_list
          .filter((att) => att.value_list)
          .forEach((valueListItem) => {
            valueListItem.value_list.forEach((listItem) => {
              if (searchables.indexOf(listItem.name) === -1) {
                searchables.push(listItem.name);
              }
            });
          });
      });

      providerList.forEach((provider) => {
        if (searchables.indexOf(provider.name) === -1) {
          searchables.push(provider.name);
        }
      });

      return searchables;
    }
    // Set data
    function CalendarJob(calendarJobData, startTime, endTime, provider_list, session_list) {
      if (calendarJobData) {
        this.setData(calendarJobData, startTime, endTime, provider_list, session_list);
      }
    }
    CalendarJob.prototype = {
      setData: function (calendarJobData, startTime, endTime, provider_list, session_list) {
        angular.extend(this, calendarJobData);
        const _currentJob = this;
        _currentJob.displayValue = true;
        _currentJob.durationTotal = 0;
        _currentJob.dateRange = {
          start: startTime,
          end: endTime,
        };

        _currentJob.session_list = _currentJob.session_ids.map((id) =>
          angular.copy(session_list.find((session) => session.session_id == id))
        );

        _currentJob.provider_list = provider_list
          .filter((provider) => provider.job_list.indexOf(_currentJob.job_map) !== -1)
          .map((provider) => {
            provider.session_list = provider.session_ids.map((id) =>
              angular.copy(session_list.find((session) => session.session_id == id))
            );
            return provider;
          });

        // 1. Set additional attribs needed for sorting and displaying sessions
        angular.forEach(_currentJob.session_list, function (session) {
          session.isJob = _currentJob.job_id === session.session_id;
          session.jobOrderId = _currentJob.job_order_id;
          session.child = false;
          session.job_id = session.isJob && _currentJob.job_map;
          session.bundled_job_id = _currentJob.job_id;
          session.jobOrderId = _currentJob.job_order_id;
          session.child = false;
          session.user_id = _currentJob.provider_list[0].user_id;
          session.bundled = _currentJob.bundled;
          session.apply = session.state.type === 8 || session.state.type === 9;
          _currentJob.apply = session.apply;
          session.job_instance = _currentJob.job_instance;
          session.current_workers = _currentJob.current_workers;

          setSessionDetails(session, _currentJob.dateRange);
          // For unbundled jobs we need to manually keep track of candidates
          if (!_currentJob.bundled && session.displayValue) {
            if (session.number_candidates) {
              _currentJob.number_candidates += session.number_candidates;
            }
            if (!_currentJob.total_workers) {
              _currentJob.total_workers = 1;
            } else {
              _currentJob.total_workers++;
            }
          }
          session.start = moment(session.start, TimeFormatService.format('fullDateTimeSecs'));
          session.end = moment(session.end, TimeFormatService.format('fullDateTimeSecs'));
        });

        _currentJob.showBundled =
          _currentJob.bundled &&
          !_currentJob.provider_list.filter((prov) => prov.name !== 'Pending' && prov.name !== 'Canceled').length;
        _currentJob.showApply =
          _currentJob.apply &&
          !_currentJob.provider_list.filter((prov) => prov.name !== 'Waiting Decision' && prov.name !== 'Interest')
            .length;

        _currentJob.jobOrderId = _currentJob.job_order_id;
        // 2. Filter out non displayed sessions on parent job row
        const _a = _currentJob.session_list.filter(function (obj) {
          return obj.displayValue == true;
        });
        _currentJob.session_list = _a;
        _a.length > 0 ? (_currentJob.displayValue = true) : (_currentJob.displayValue = false);

        // 3. Build duration total off of displayable sessions
        setDurationTotal(_currentJob.session_list, _currentJob, _currentJob.dateRange);

        // 4. Sort session list in order to build overlap blocks
        _currentJob.session_list.sort(sortByStartTime);
        OverlapService.buildOverlapSesisons(_currentJob.session_list);

        // 6. Set session details on child provider lists sessions
        _currentJob.provider_list.forEach((provider) => {
          setProviderDetails(provider, _currentJob.session_ids, _currentJob.dateRange, _currentJob.job_id);
          buildChildColorBlockDisplay(provider);
        });

        _currentJob.searchables = buildSearchableTextList(_currentJob.session_list, _currentJob.provider_list);

        const firstSession =
          _currentJob.session_list &&
          _currentJob.session_list.find(function (session) {
            return !session.isJob;
          });
        if (firstSession) {
          _currentJob.firstSessionTime = firstSession.start_time || '';
        } else {
          _currentJob.firstSessionTime = 'a';
        }

        const specialSessions = ['Open', 'Pending', 'Canceled'];
        const special =
          _currentJob.session_list &&
          _currentJob.session_list.some(function (session) {
            return specialSessions.indexOf(session.state.name) !== -1;
          });
        if (special) {
          _currentJob.firstSessionTime = '0';
        }

        const isPending =
          _currentJob.session_list && _currentJob.session_list.some((session) => session.state.name === 'Pending');
        if (isPending && _currentJob.bundled && _currentJob.session_list.length) {
          _currentJob.durationTotal =
            _currentJob.session_list.length *
            _currentJob.session_list[0].duration *
            (_currentJob.total_workers - _currentJob.current_workers);
        }
      },

      // Methods on class obj
      setProviderListDisplayBoolean: function () {
        const scope = this;
        // var stateListNames = scope.session_list.map(function(obj){ return obj.state.name;});
        for (let i = 0; i < scope.session_list.length; i++) {
          if (isSessionDisplayable(scope.session_list[i].state.name.toLowerCase()) != -1) {
            scope.displayProviderList = true;
            break;
          } else {
            scope.displayProviderList = false;
          }
        }
      },
    };

    return CalendarJob;
  },
]);
