import module from '../module';

export default module.directive('adminDirective', [
  function () {
    return {
      restrict: 'E',
      templateUrl: 'app/views/admin/index.html',
      controller: 'AdminController',
    };
  },
]);
