import module from './module';
import moment from 'moment';
import * as enums from '../enums';
export default module.directive('dirRegistrationForm', function () {
  function alphabetical(a, b) {
    var A = a.name.toLowerCase();
    var B = b.name.toLowerCase();
    if (A < B) {
      return -1;
    } else if (A > B) {
      return 1;
    } else {
      return 0;
    }
  }
  return {
    restrict: 'E',
    scope: {
      field: '=',
      entFilter: '=',
      formType: '=',
      user: '=',
      index: '=',
      isdisabled: '=',
    },
    controller: [
      '$scope',
      '$modal',
      'locationManagerService',
      'DOMAIN',
      'TimeFormatService',
      'LocationProvider',
      'AuthenticationService',
      'PERMISSIONS',
      'NgrxStore',
      function (
        $scope,
        $modal,
        locationManagerService,
        DOMAIN,
        TimeFormatService,
        LocationProvider,
        AuthenticationService,
        PERMISSIONS,
        NgrxStore
      ) {
        $scope.icons = {
          eyes: `${enums.AssetsFolder.svg}eyes.svg`,
          crossed: `${enums.AssetsFolder.svg}eyes-crossed.svg`,
        };
        $scope.setupConfidential = () => {
          $scope.toggleShowConfidential = (field) => {
            $scope.showConfidential = !$scope.showConfidential;
            if ($scope.showConfidential) {
              NgrxStore.dispatch({
                type: '[Workers] Log confidential view',
                attrib: field,
              });
            }
          };
          $scope.hasConfidentialPermission = AuthenticationService.getCurrentUser().permissionCheck(
            PERMISSIONS.CONFIDENTIAL_FIELDS
          );
        };

        $scope.onImageClick = function (e) {
          if ($scope.imageUrl) {
            const modalInstance = $modal.open({
              animation: true,
              template: `
              <div style="text-align: center; padding: 20px;">
                <div>
                  <button class="close" ng-click="cancelSmallModal()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <img src="${$scope.imageUrl}" />
              </div> `,
              keyboard: false,
              size: 'lg',
              controller: 'providerImageModalController',
            });
          }
        };

        //////////////////////////////////////////////////////////////////////////////
        $scope.onImageChangeHandler = function (event, file) {
          if (file[0].type.indexOf('image') != -1) {
            if (FileReader) {
              var _canvasId = $scope.field.name + '_' + $scope.index;
              var canvas = document.getElementById(_canvasId);
              var context = canvas.getContext('2d');

              //Draw imag to canvas inroder to convert to jpeg and resize
              var imageObj = new Image();
              imageObj.onload = function () {
                canvas.width = 300;
                canvas.height = 300;
                context.drawImage(imageObj, 0, 0, 300, 300);
                $scope.field.jpeg = canvas.toDataURL('image/jpeg', 0.75).replace(/^data:image\/(jpeg);base64,/, '');
                $scope.imageUrl = canvas.toDataURL();
                $scope.$apply();
              };

              //File Reader to set image obj
              var fr = new FileReader();
              fr.onload = function () {
                imageObj.src = fr.result;
              };
              fr.readAsDataURL(file[0]);
            } //end if FileReader Present

            $scope.showRemoveBtn = true;
            delete $scope.imageError;
            delete $scope.imageUrl;
          } else {
            $scope.removeFile();
            $scope.imageError = 'Only images are allowed';
          }
        };

        $scope.removeFile = function (e) {
          e ? e.preventDefault() : null;
          var _name = $scope.field.name + '_' + $scope.index.toString();
          $('input[name=' + _name + ']').val(null);
          delete $scope.field.value;
          delete $scope.imageUrl;
          $scope.field.jpeg ? delete $scope.field.jpeg : null;
          $scope.showRemoveBtn = false;
        };

        $scope.addAdditionalAttrib = function (e, newField) {
          e ? e.preventDefault() : null;
          $scope.$emit('addAttribInstanceClicked', $scope.field, $scope.index, newField);
        };

        $scope.removeAdditionalAttrib = function (e) {
          e ? e.preventDefault() : null;
          $scope.removeFile();
          $scope.$emit('removeAttribInstanceClicked', $scope.field, $scope.index);
        };

        $scope.addInstanceBtnDisplay = function () {
          var _count = $scope.field.instanceCountIndex || 1;
          if (_count == $scope.field.instance_count || $scope.field.doNotShowAddInstance) {
            return false;
          } else {
            return true;
          }
        };
        $scope.removeInstanceBtnDisplay = function () {
          if ($scope.field.showRemoveInstance && $scope.field.instanceCountIndex > 1) {
            return true;
          } else if ($scope.field.type === 'image') {
            return true;
          }
        };

        $scope.multipleInstances = function () {
          if ($scope.field.instance_count && $scope.field.instance_count != 1) {
            return true;
          }
        };

        function returnProfileAttrib(name) {
          var value = $scope.user.profile_info.filter(function (obj) {
            return obj.name == name;
          })[0];
          return value;
        }

        function returnMultipleInstances(name) {
          var value = $scope.user.profile_info.filter(function (obj) {
            return obj.name == name;
          });
          return value;
        }

        function generateImageUrl(data) {
          if (data) {
            return DOMAIN + data + '?rand=' + Math.random().toString(36).substring(7);
          }
        }

        //////////////////////////////////////////////////////////////////////////////
        // switch case based on field type
        var newSwitch = function (field) {
          // on new all fields need to be enabled.
          $scope.showConfidential = true;

          switch (field.type) {
            case 'password':
              break;
            case 'single_list':
              if (field.choice_list && field.choice_list.length) {
                const selected = field.choice_list.find((x) => x.selected);
                if (selected) {
                  field.value = selected;
                }
              }
              break;
            case 'multi_list':
              break;
            case 'text':
              if ($scope.field.name == 'biography') {
                $scope.rows = 4;
              } else {
                $scope.rows = 1;
              }
              if (field.default_text) {
                field.value = field.default_text;
              }
              break;
            case 'image':
              if (field.instance_count == -1) {
                $scope.enableMultiplePhotos = true;
              }
              break;
            case 'count':
              if (field.default_count) {
                field.value = field.default_count;
              }
              break;
            case 'decimal':
              if (field.default_decimal) {
                field.value = field.default_decimal;
              }
              break;
            case 'location':
              locationManagerService.getAllLocations($scope.entFilter.enterprise_id).then(function (locationsObject) {
                if (locationsObject && $scope.entFilter.enterprise_id != -1) {
                  var locations = locationsObject[$scope.entFilter.enterprise_id];
                  $scope.locations = LocationProvider.handleOtherLocation(locations);
                  $scope.locations.sort(alphabetical);
                } else {
                  $scope.locations = LocationProvider.handleOtherLocation([]);
                  field.value = $scope.locations[0];
                }
              });
              break;
            case 'date':
              $scope.dateField = {
                opened: false,
              };
              $scope.openDateField = function () {
                $scope.dateField.opened = true;
              };
              break;
            case 'flag':
              break;
          } //end switch
        }; //end new

        var editSwitch = function (field) {
          const userAttrib = returnProfileAttrib(field.name);
          if (userAttrib && userAttrib.confidential) {
            field.confidential = userAttrib.confidential;
            $scope.setupConfidential();
          } else {
            field.confidential = false;
          }
          switch (field.type) {
            case 'password':
              field.type_flags.required = false;
              break;
            case 'single_list':
              var value = returnProfileAttrib(field.name);
              if (value && value.choice_list) {
                field.value = value.choice_list.filter(function (obj) {
                  return obj.selected == true;
                })[0];
              }
              break;
            case 'multi_list':
              var value = returnProfileAttrib(field.name);
              if (value && value.choice_list) {
                field.value = value.choice_list.filter(function (obj) {
                  return obj.selected == true;
                });
                field.choice_list = value.choice_list;
              } else {
                field.value = [];
              }
              break;
            case 'text':
              if ($scope.field.name == 'biography') {
                $scope.rows = 4;
              } else {
                $scope.rows = 1;
              }
              var value = returnProfileAttrib(field.name);
              if (value && value.data) {
                field.value = value.data;
              }
              break;
            case 'image':
              field.type_flags.required = false;

              if ($scope.multipleInstances() && !field.addedByUser) {
                if (!field.record_id) {
                  // Multi instance without record_id, this is the first image in the row
                  var value = returnProfileAttrib(field.name);
                  $scope.imageUrl = generateImageUrl(value.data);
                  field.showRemoveInstance = true;
                  var allInstances = returnMultipleInstances(field.name);
                  var currentInstance = allInstances.shift();
                  field.record_id = currentInstance.record_id;
                  // If there are more images trigger another field addition with the next item
                  if (allInstances.length) {
                    $scope.addAdditionalAttrib(null, allInstances.shift());
                  }
                } else {
                  // Multi instance with record id, triggered by iterating over the images
                  var allInstances = returnMultipleInstances(field.name);
                  // Find the current image by record_id
                  var currentIndex = allInstances.findIndex(function (item) {
                    return item.record_id === field.record_id;
                  });
                  $scope.imageUrl = generateImageUrl(allInstances[currentIndex].data);
                  if (currentIndex + 1 < allInstances.length) {
                    // If there are more images, trigger an addition
                    $scope.addAdditionalAttrib(null, allInstances[currentIndex + 1]);
                  }
                }
              } else if (!field.addedByUser) {
                // Simple, non-multi case, just generate url and be done with it
                var value = returnProfileAttrib(field.name);
                $scope.imageUrl = generateImageUrl(value.data);
              }
              break;
            case 'count':
            case 'decimal':
              var value = returnProfileAttrib(field.name);
              if (value && value.data) {
                field.value = value.data;
                field.max_amount = value.max_amount;
                field.min_amount = value.min_amount;
              }
              break;
            case 'location':
              var locationAttrib = returnProfileAttrib(field.name);
              $scope.locations = LocationProvider.handleOtherLocation(locationAttrib.value_location);
              field.value = locationAttrib.value_location.filter(function (obj) {
                return obj.selected == true;
              })[0];
              break;
            case 'place':
              break;
            case 'date':
              var value = returnProfileAttrib(field.name);
              if (value && value.data) {
                var date = moment(value.data, TimeFormatService.format('fullDate'));
                if (!date.isValid()) {
                  date = moment(value.data, TimeFormatService.format('fullDateNoSeparator'));
                }
                field.value = date.format(TimeFormatService.format('middleDate'));
              }
              //For contorlling opening and closing of date picker
              $scope.dateField = {
                opened: false,
              };
              $scope.openDateField = function () {
                $scope.dateField.opened = true;
              };
              break;
            case 'flag':
              var value = returnProfileAttrib(field.name);
              if (value && value.data) {
                field.value = value.data;
              }
              break;
          } // end switch
        }; // end edit switch function

        // switch case based on field type
        if ($scope.formType.toLowerCase() == 'edit') {
          editSwitch($scope.field);
        } else {
          newSwitch($scope.field);
        }
      },
    ],
    templateUrl: 'app/views/templates/dynamic_attrib_fields.html',
  };
});
