import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  templateUrl: './worker-skills.component.html',
  styleUrls: ['./worker-skills.component.scss'],
  selector: 'app-worker-skills',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkerSkillsComponent implements OnChanges {
  @Input() inline = false;
  @Input() keyAttribute: any;
  public label: string;

  @Input() set worker(value: any) {
    if (this.keyAttribute) {
      this.skills = value && this.getKeyAttribNames(value);
      this.label = value && this.getKeyAttribLabel(value);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.keyAttribute && changes.keyAttribute.currentValue && changes.worker && changes.worker.currentValue) {
      this.skills = this.getKeyAttribNames(changes.worker.currentValue);
      this.label = this.getKeyAttribLabel(changes.worker.currentValue);
    }
  }

  private getKeyAttribLabel(worker) {
    const attrib = worker.profile_info.find((x) => x.name == this.keyAttribute.data_content);
    if (attrib) {
      return attrib.display_name;
    }
    return this.keyAttribute.data_content;
  }

  private getKeyAttribNames(worker): string[] {
    const attrib = worker.profile_info.find((x) => x.name == this.keyAttribute.data_content);
    if (attrib) {
      if (attrib.value_text) {
        return [attrib.value_text];
      } else if (attrib.choice_list) {
        return attrib.choice_list.filter((cl) => cl.selected).map((cl) => cl.name);
      }
    }
    return [''];
  }

  public skills: string[];
}
