import angular from 'angular';
import module from './module';

export default module.factory('Role', [
  '$http',
  'BASE_URL',
  function ($http, BASE_URL) {
    function Role(roleData, dataType) {
      if (roleData) {
        this.setData(roleData, dataType);
      }
    }

    Role.prototype = {
      setData: function (roleData) {
        angular.extend(this, roleData);
      },
    };

    return Role;
  },
]);
