import module from '../module';

export default module.directive('jobOrderReportDirective', [
  function () {
    return {
      restrict: 'E',
      templateUrl: 'app/views/job/jobRoot.html',
      controller: 'JobRootController',
    };
  },
]);
