import module from '../module';
import * as enums from '../../enums';
import moment from 'moment';

export default module.service('timeReportDataService', [
  'AuthenticationService',
  'FormatHelperService',
  'TimeFormatService',
  function (AuthenticationService, FormatHelperService, TimeFormatService) {
    const CURRENT_USER = AuthenticationService.getCurrentUser();

    const uuidv4 = () => {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
      );
    };

    const getBranchAndEnterpriseId = (branchFilter, entFilter) => {
      const data = {};

      if (CURRENT_USER.user_type == enums.UserType.MarketPlaceAdmin) {
        if (branchFilter && branchFilter.id && branchFilter.id != -1) {
          data.branch_id = branchFilter.id;
        }
        if (entFilter && entFilter.enterprise_id && entFilter.enterprise_id != -1) {
          data.enterprise_id = entFilter.enterprise_id;
        }
      } else if (CURRENT_USER.user_type == enums.UserType.BranchAdmin) {
        if (entFilter && entFilter.enterprise_id) {
          data.enterprise_id = entFilter.enterprise_id;
        }
      }

      return data;
    };

    const getTimesheetChoicesPayload = (choicesPayloadData) => {
      const data = {
        show_verified: !!choicesPayloadData.getVerified,
        start_date: choicesPayloadData.startDate ? moment(choicesPayloadData.startDate).format(TimeFormatService.format('api')) : undefined,
        end_date: choicesPayloadData.endDate ? moment(choicesPayloadData.endDate).format(TimeFormatService.format('api')) : undefined
      };
      if (choicesPayloadData.verifyAttribute) {
        data.verify_attribute = choicesPayloadData.verifyAttribute.data_content;
      }

      const data2 = getBranchAndEnterpriseId(choicesPayloadData.branchFilter, choicesPayloadData.entFilter);

      return { ...data, ...data2 };
    };

    const addGuidToOrder = (order) => {
      order.id = uuidv4();
      return order;
    };

    const getVerifyJobSessionsPayload = (verifJobSessPaylData) => {
      let data;
      if (verifJobSessPaylData.selectedFilterType === enums.SelectedTimeReportFilterType.JobOrder) {
        if (verifJobSessPaylData.verifyAttribute) {
          data = {
            verify_attribute: verifJobSessPaylData.verifyAttribute.data_content,
            attrib_value: verifJobSessPaylData.selectedItem.attrib_value,
            start_date: moment(verifJobSessPaylData.selectedItem.start_date).format(TimeFormatService.format('api')),
            end_date: moment(verifJobSessPaylData.selectedItem.end_date).format(TimeFormatService.format('api')),
          };
        } else {
          data = {
            job_id: verifJobSessPaylData.selectedItem.job_id,
            job_instance: verifJobSessPaylData.selectedItem.job_instance,
            start_date: moment(verifJobSessPaylData.selectedItem.start_date).format(TimeFormatService.format('api')),
            end_date: moment(verifJobSessPaylData.selectedItem.end_date).format(TimeFormatService.format('api')),
          };
        }
      } else {
        data = {
          start_date: verifJobSessPaylData.selectedItem.start,
          end_date: verifJobSessPaylData.selectedItem.end,
        };
      }

      const data2 = getBranchAndEnterpriseId(verifJobSessPaylData.branchFilter, verifJobSessPaylData.entFilter);

      return { ...data, ...data2 };
    };

    const getMinutesFromDuration = (value) => {
      const splitted = value.split(' ');
      if (
        splitted.length === 5 &&
        typeof parseInt(splitted[0]) === 'number' &&
        typeof parseInt(splitted[3]) === 'number'
      ) {
        return parseInt(splitted[0]) * 60 + parseInt(splitted[3]);
      } else if (splitted.length === 2 && typeof parseInt(splitted[0]) === 'number') {
        return parseInt(splitted[0]);
      } else {
        return 'invalid';
      }
    };

    const getDateChanges = (data, checkDateTime, orig_checkin_date, orig_checkin_time, propName) => {
      const time = moment(checkDateTime, TimeFormatService.format('api'));
      const check_date_utc = FormatHelperService.returnUtcString(time, TimeFormatService.format('fullDate'));
      const check_time_utc = FormatHelperService.returnUtcString(time, 'HH:mm');
      const check_time_orig = moment(orig_checkin_time, 'HH:mm:ss').format('HH:mm');
      if (orig_checkin_date != check_date_utc || check_time_orig != check_time_utc) {
        const changed_datetime = FormatHelperService.returnUtcString(time, TimeFormatService.format('fullDateTime'));
        data.changes = data.changes || {};
        data.changes[propName] = changed_datetime;
      }

      return data;
    };

    const buildRequestList = (sessions, orignalSessions, verifyAttribute) => {
      const changedProps = ['worker_rating', 'request_total_time', 'break_total_time', 'total_amt'];
      const requestList = {
        request_list: [],
      };
      if (verifyAttribute) {
        requestList.verify_attribute = verifyAttribute.data_content;
      }
      // The total amount and break time would NOT be sent to the server unless the user manually enters/changes those values.
      sessions.forEach((session, index) => {
        if (session.verified) {
          return;
        }

        let data = {
          request_id: session.request_id,
        };

        const origsession = orignalSessions[index];

        for (const prop of changedProps) {
          if (prop === 'total_amt' && session[prop] == '') {
            continue;
          }

          if (prop === 'break_total_time' && session[prop] == '') {
            continue;
          }

          if (session[prop] !== origsession[prop]) {
            data.changes = data.changes || {};

            if (prop === 'worker_rating') {
              data.changes[prop] = parseInt(session[prop]);
            } else {
              data.changes[prop] = session[prop];
            }
          }
        }

        data = getDateChanges(
          data,
          session.checkinDateTime,
          origsession.checkin_date,
          origsession.checkin_time,
          'checkin_datetime'
        );
        data = getDateChanges(
          data,
          session.checkoutDateTime,
          origsession.checkout_date,
          origsession.checkout_time,
          'checkout_datetime'
        );

        if (session.breakSections?.length && session.breakSections.some((x) => (x.breakIn && x.breakOut) || x.delete)) {
          data.changes = data.changes || {};
          data.changes.breaks = buildBreakPayload(session);
        } else if (session.break_total_time || session.break_total_time === 0) {
          data.changes = data.changes || {};
          data.changes.break_total_time = session.break_total_time;
        }
        if (session.break_penalties) {
          data.changes = data.changes || {};
          data.changes.break_penalties = session.break_penalties;
        }

        requestList.request_list.push(data);
      });

      return requestList;
    };

    const getHoursFromTime = (time) => {
      if (time === -1) {
        return 0;
      }
      return Math.floor(time / 60);
    };

    const getMinutesFromTime = (time) => {
      if (time === -1) {
        return 0;
      }
      return time % 60;
    };

    const getInitializedSession = (session, showProjectedTime) => {
      // set default checkin date/time
      if (!session.checkin_date || !session.checkin_time) {
        session.checkin_date = session.request_date;
        session.checkin_time = session.request_time;
      }
      const checkinDateTimeUtc = `${session.checkin_date} ${session.checkin_time}`;
      const checkinDateTime = FormatHelperService.returnLocalTimeString(
        checkinDateTimeUtc,
        TimeFormatService.format('fullDateTimeSecs')
      );

      // set default checkout date/time
      if (!session.checkout_date || !session.checkout_time) {
        session.checkout_date = moment(checkinDateTimeUtc)
          .add(session.duration, 'minutes')
          .format(TimeFormatService.format('fullDate'));
        session.checkout_time = moment(checkinDateTimeUtc).add(session.duration, 'minutes').format('HH:mm:ss');
      }
      const checkoutDateTime = FormatHelperService.returnLocalTimeString(
        `${session.checkout_date} ${session.checkout_time}`,
        TimeFormatService.format('fullDateTimeSecs')
      );

      // format dates and times
      session.checkinDateTime = moment(checkinDateTime).format(TimeFormatService.format('api'));
      session.checkoutDateTime = moment(checkoutDateTime).format(TimeFormatService.format('api'));

      session.checkin_date = moment(checkinDateTime).format(TimeFormatService.format('fullDate'));
      session.checkout_date = moment(checkoutDateTime).format(TimeFormatService.format('fullDate'));

      session.checkin_time = moment(checkinDateTime).format('LT');
      session.checkout_time = moment(checkoutDateTime).format('LT');

      session.checkin_time_obj = moment(checkinDateTime);
      session.checkout_time_obj = moment(checkoutDateTime);

      // set default worker rating
      if (!session.worker_rating || session.worker_rating === -1) {
        session.worker_rating = 5;
      }

      // set default request_total_time
      if (session.request_total_time !== 0 && (!session.request_total_time || session.request_total_time === -1)) {
        session.request_total_time = session.duration;
        if (session.break_total_time && session.break_total_time > -1) {
          session.request_total_time -= session.break_total_time;
        }
      }

      // set total total time display
      session.total_time_hours = getHoursFromTime(session.request_total_time);
      session.total_time_minutes = getMinutesFromTime(session.request_total_time);

      // set default break total amount
      if (!session.break_total_time || session.break_total_time === -1) {
        session.break_time_hours = '';
        session.break_time_minutes = '';
      } else {
        session.break_time_hours = getHoursFromTime(session.break_total_time);
        session.break_time_minutes = getMinutesFromTime(session.break_total_time);
      }

      if (!showProjectedTime && !session.retainTotalTime && session.status !== 'Completed') {
        delete session.request_total_time;
      }

      // set default total amount
      if (!session.total_amt || session.total_amt === -1) {
        session.total_amt = '';
      }

      // break transformation
      if (session.breaks) {
        session.breakSections = session.breaks.map((b) => ({
          breakIn: moment(
            FormatHelperService.returnLocalTimeString(
              b.break_start_datetime,
              TimeFormatService.format('fullDateTimeSecs')
            )
          ).toDate(),
          breakOut: moment(
            FormatHelperService.returnLocalTimeString(
              b.break_end_datetime,
              TimeFormatService.format('fullDateTimeSecs')
            )
          ).toDate(),
          breakDuration: b.break_time,
          break_id: b.id,
        }));
      }

      // state_id 6 is California, we have custom break logic for it.
      session.isCalifornian = session.state_id === 6;

      return session;
    };

    const buildNoShowPayload = (session) => ({
      request_id: session.request_id,
      end_type: 11, // cancel code for now show
    });

    const buildBreakPayload = (session) => {
      return session.breakSections
        .filter((section) => (section.breakIn && section.breakOut) || section.delete)
        .map((section) => {
          const start = FormatHelperService.returnUtcString(
            moment(section.breakIn),
            TimeFormatService.format('fullDateTimeSecs')
          );
          const end = FormatHelperService.returnUtcString(
            moment(section.breakOut),
            TimeFormatService.format('fullDateTimeSecs')
          );

          return {
            break_start_datetime: moment(start).format(TimeFormatService.format('api')),
            break_end_datetime: moment(end).format(TimeFormatService.format('api')),
            break_id: section.break_id,
            delete: section.delete,
            break_time: section.breakDuration,
          };
        });
    };

    return {
      addGuidToOrder,
      getTimesheetChoicesPayload,
      getVerifyJobSessionsPayload,
      getMinutesFromDuration,
      buildRequestList,
      getHoursFromTime,
      getMinutesFromTime,
      getInitializedSession,
      buildNoShowPayload,
      buildBreakPayload,
    };
  },
]);
