import module from '../module';
export default module.controller('ModalController', [
  '$scope',
  '$modalInstance',
  'AuthenticationService',
  'AlertService',
  function ($scope, $modalInstance, AuthenticationService, AlertService) {
    $scope.formData = {
      username: '',
    };

    $scope.ok = function () {
      AuthenticationService.passwordReset($scope.formData, function (response) {
        if (response.success) {
          AlertService.successAlert(response.message);
          $modalInstance.close();
        } else {
          $modalInstance.close();
          AlertService.errorAlert(response.message);
        }
      });
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  },
]);
