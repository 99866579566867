import module from '../module';
import * as enums from '../../enums';

export default module.service('timeReportCsvService', [
  'FormatHelperService',
  'timeReportUIService',
  function (FormatHelperService, timeReportUIService) {
    const separator = ',';

    const getDurationDisplayVal = (value) => {
      if (value === -1) {
        return '';
      }
      return FormatHelperService.returnDurationDisplayVal(value);
    };

    const getFilteredSessions = (data) => {
      return data.sessions.filter((session) => {
        if (data.showCancelled) {
          return true;
        } else {
          if (session.status.type === enums.RequestStatus.Canceled) {
            return false;
          } else {
            return true;
          }
        }
      });
    };

    const getCsvHeader = (data, hasAmount) => {
      const translations = timeReportUIService.getTranslations();
      const headers = [
        translations.keySessionId,
        data.keyProfession.data_content,
        data.keyAttrib.display_name,
        translations.branch,
        translations.enterprise,
        translations.status,
        translations.startDate,
        translations.checkIn,
        translations.checkOut,
        translations.break,
        translations.scheduleFor,
        translations.timeWorked,
      ];

      if (hasAmount) {
        headers.push(translations.amount);
      }

      headers.push(translations.rating);
      headers.push(translations.verification);
      headers.push(translations.verify_user_full_name);
      headers.push(translations.verified_timestamp);

      return headers.join(separator);
    };

    const getCsvBody = (filteredSessions, hasAmount) => {
      return filteredSessions
        .map(function (session) {
          const csv = {
            id: session.request_id,
            worker_name: session.worker_name,
            keyAttribute: session.key_attribute,
            branch_name: session.branch_name,
            enterprise_name: session.enterprise_name,
            status: session.status,
            checkin_date: session.checkin_date,
            checkin_time: session.checkin_time,
            checkout_time: session.checkout_time,
            break_total_time: getDurationDisplayVal(session.break_total_time),
            scheduleFor: getDurationDisplayVal(session.duration),
            request_total_time: getDurationDisplayVal(session.request_total_time),
          };

          if (hasAmount) {
            csv.total_amt = session.total_amt;
          }

          csv.worker_rating = session.worker_rating;
          csv.verified = session.verified;

          if (session.verified) {
            csv.verify_user_full_name = session.verify_user_full_name;
            csv.verified_timestamp = `${session.verification_date} ${session.verification_time}`;
          }

          return csv;
        })
        .map(function (session) {
          return Object.keys(session)
            .map(function (key) {
              let value = session[key];
              if (typeof value === 'string') {
                // '#' breaks the CSV rendering in Excel, we need to remove it
                value = value.replace(/#/g, '');
                value = `"${value}"`;
              }
              return value;
            })
            .join(separator);
        });
    };

    const getCsv = (data) => {
      const hasAmount = !!data.sessions.find((session) => session.total_amt > 0);

      let result = [];
      result.push('data:text/csv;charset=utf8,');
      result.push(getCsvHeader(data, hasAmount));

      const filteredSessions = getFilteredSessions(data);
      const csvBody = getCsvBody(filteredSessions, hasAmount);

      result = result.concat(csvBody);

      return result.join('\n');
    };

    const generateDownload = (data) => {
      const dataUri = getCsv(data);

      const hiddenElement = document.createElement('a');
      hiddenElement.href = encodeURI(dataUri);

      let fileNameExtra = '';

      if (data.selectedFilterType === enums.SelectedTimeReportFilterType.Extract && data.selectedRange) {
        fileNameExtra = `-Time-Period ${data.selectedRange.display}`;
      } else if (data.selectedJobOrder && data.selectedJobOrder.desc) {
        fileNameExtra = `-Job-Order ${data.selectedJobOrder.desc.replace(/\//g, '-')}`;
      }

      hiddenElement.download = `Time-Verification${fileNameExtra}.csv`;
      document.body.appendChild(hiddenElement); // Required for FF
      hiddenElement.click();
      document.body.removeChild(hiddenElement);
    };

    return {
      generateDownload,
      getDurationDisplayVal,
    };
  },
]);
