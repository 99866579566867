import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  OnDestroy,
  ViewEncapsulation,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';
import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';
import { AuthenticationService, GeneralDataService, PermissionsService } from '../../../shared/services';
import { FormField, ProfileFieldType } from '../../../shared/models/worker';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

import * as moment from 'moment';
import { takeUntil, tap, filter, map, mergeMap, debounceTime } from 'rxjs/operators';
import { from, Subject } from 'rxjs';
import { TimeFormatService } from '../../../shared/services/time-format.service';

@Component({
  templateUrl: './ad-hoc-schedule.component.html',
  styleUrls: ['./ad-hoc-schedule.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdhocScheduleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formData: any;
  @Input() attribList: FormField[];
  @Input() durationAttrib: FormField;
  @Input() affectedDays: Date[];
  @Input() dateAttrib: FormField;

  @Output() onChange = new EventEmitter<{ valid: boolean; sessions: any[] }>();

  private readonly stop$ = new Subject<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private generalDataService: GeneralDataService,
    private timeFormatService: TimeFormatService,
    @Inject('timeZoneStore') private timeZoneStore,
    private permissionService: PermissionsService,
    private authenticationService: AuthenticationService,
    @Inject('FormatHelperService') private formatHelperService
  ) {}

  public sessions2 = [
    {
      date: new Date(),
    },
  ];

  public keyAttrib: FormField;
  public locationAttrib: FormField;

  public sessionsForm: FormGroup;

  public fromOpened = [];
  public toOpened = [];

  public hasAttribPermission = false;

  public get sessions(): FormArray {
    return this.sessionsForm.get('sessions') as FormArray;
  }

  ngOnInit() {
    this.hasAttribPermission = !!this.authenticationService.getPermission(
      this.permissionService.PERMISSIONS.ADHOC_EDIT_ATTRIBS
    );

    this.sessionsForm = this.formBuilder.group({
      sessions: this.formBuilder.array([]),
    });

    setTimeout(() => {
      for (let i = 0; i < this.affectedDays.length; i++) {
        this.addSession(this.affectedDays[i]);
      }
    }, 500);

    this.sessionsForm.valueChanges
      .pipe(
        debounceTime(100),
        takeUntil(this.stop$),
        filter((x) => !!x),
        mergeMap(() => {
          const val = this.sessionsForm.getRawValue();
          return from(this.sessionsToPayload(val.sessions));
        }),
        map((sessions) => {
          this.onChange.emit({
            valid: this.sessionsForm.valid,
            sessions: sessions,
          });
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.attribList && changes.attribList.currentValue) {
      this.keyAttrib = this.attribList.find(
        (x) => x.name === this.generalDataService.getKeyAttributeSync().data_content
      );

      if (this.keyAttrib && this.keyAttrib.choice_list) {
        this.keyAttrib.choice_list.sort((a, b) => a.name.localeCompare(b.name));
      }

      this.locationAttrib = this.attribList.find((x) => x.name === 'location');
      if (this.locationAttrib) {
        (<any>this.locationAttrib).locations.sort((a, b) => a.name.localeCompare(b.name));
      }
    }
  }

  ngOnDestroy() {
    this.stop$.next(true);
    this.stop$.complete();
  }

  public sortArray() {
    const items = this.sessions.value;
    items.sort((a, b) => a.date - b.date);
    this.sessions.patchValue(items);
  }

  public newSession(startDate?: Date): FormGroup {
    let start;
    if (startDate) {
      const time = this.getStartTime();
      let momentStart = moment(startDate);
      momentStart.hours(time.getHours());
      momentStart.minutes(time.getMinutes());
      start = momentStart.toDate();
    } else {
      start = this.getStartDateTime();
    }

    const duration = this.getDuration();
    const endTime = this.getEndTime(start, this.durationAttrib.value as number);
    const keyAttribute = this.getSelectedKeyAttrib();
    const location = this.getSelectedLocation();

    const group = this.formBuilder.group({
      date: [start, Validators.required],
      from: [start],
      to: [endTime],
      duration: [duration, Validators.required],
      keyAttribute: [{ value: keyAttribute, disabled: !this.hasAttribPermission }],
      location: [{ value: location, disabled: !this.hasAttribPermission }],
      count: [1],
    });

    this.wireupValueChanges(group);

    return group;
  }

  public addSession(startDate?: Date) {
    if (!this.sessions.length || this.sessions.valid) {
      this.sessions.push(this.newSession(startDate));
    }
  }

  public removeSession(index) {
    this.sessions.removeAt(index);
  }

  public dateChange(date: Date, index: number) {
    const from = this.sessions.at(index).get('from').value as Date;
    from.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());

    this.sessions.at(index).patchValue({ date, from });
  }

  public cloneSession(session, index: number) {
    if (this.sessions.valid) {
      const cloned = this.formBuilder.group({
        date: [new Date(session.value.date), Validators.required],
        from: [new Date(session.value.from)],
        to: [new Date(session.value.to)],
        duration: [session.value.duration, Validators.required],
        keyAttribute: [{ value: session.value.keyAttribute, disabled: !this.hasAttribPermission }],
        location: [{ value: session.value.location, disabled: !this.hasAttribPermission }],
        count: [session.value.count],
      });

      this.wireupValueChanges(cloned);

      this.sessions.insert(index + 1, cloned);
    }
  }

  public toOnFocus(i) {
    this.fromOpened[i] = false;
  }

  public durationFocus(i) {
    this.toOpened[i] = false;
  }

  public countChange(e) {
    const value = parseInt(e.target.value);
    const items = this.sessions.value;
    items.forEach((session) => (session.count = value));
    this.sessions.patchValue(items);
  }

  public filterOtherLocation(locationAttrib) {
    if (locationAttrib.value?.location_id != 0) {
      return locationAttrib.locations.filter((i) => i.location_id != 0);
    } else {
      const other = locationAttrib.locations.find((x) => x.location_id == 0);
      other.name = locationAttrib.value.location;
      return locationAttrib.locations;
    }
  }

  private getStartTime() {
    return moment(this.formData.startTime).toDate();
  }

  private getStartDateTime() {
    const date = moment(this.formData.startDate).format('YYYY-MM-DD');
    const time = moment(this.formData.startTime).format('HH:mm');
    return moment(`${date} ${time}`).toDate();
  }

  private getEndTime(startTime: Date, duration: number) {
    return moment(startTime).clone().add(duration, 'm').toDate();
  }

  private getDuration() {
    return this.durationAttrib.value;
  }

  private getSelectedKeyAttrib() {
    const attrib = this.attribList.find((x) => x.name === this.generalDataService.getKeyAttributeSync().data_content);

    if (attrib) {
      if (attrib.value) {
        if (Array.isArray(attrib.value)) {
          return attrib.value?.map((x) => x.choice_id);
        } else if (attrib.value.choice_id) {
          return attrib.value.choice_id;
        } else {
          return attrib.value;
        }
      }
    }
  }

  private getSelectedLocation() {
    return this.locationAttrib && this.locationAttrib.value && this.locationAttrib.value.location_id;
  }

  private getLocationValue(locationId) {
    if (locationId == 0) {
      return this.formatHelperService.format_data([this.locationAttrib])[0]?.value_location;
    } else {
      return this.locationAttrib.locations.find((x) => x.location_id == locationId);
    }
  }

  private async sessionsToPayload(sessions: any[]) {
    const formatDate = async (value, locationId) => {
      if (!value._isUTC) {
        if (this.locationAttrib.value) {
          const result = await this.timeFormatService.formatTimezone(
            '',
            value,
            value,
            this.getLocationValue(locationId),
            true
          );
          return moment.utc(result).format(this.timeFormatService.format('fullDateTimeNoSeparator'));
        } else {
          return moment
            .utc(this.timeZoneStore.calcTime(value))
            .format(this.timeFormatService.format('fullDateTimeNoSeparator')); //format to UTC and then string text
        }
      } else {
        return moment(value).format(this.timeFormatService.format('fullDateTimeNoSeparator'));
      }
    };

    const formatLocation = (session) => {
      // location_id 0 is the "other" location that can be added on the form
      if (session.location == 0 && this.locationAttrib.value && !this.locationAttrib.value.location_id) {
        return this.formatHelperService.format_data([this.locationAttrib])[0]?.value_location;
        // location_id undefined is an "other" location that comes from the job template
        // in that case there is no location_id set.
        // string "undefined" is used because Angular converts the option value to string
      } else if (!session.location || session.location == 'undefined') {
        const target = this.locationAttrib.locations.find((x) => x.location_id === undefined);
        return {
          city: target.city,
          state: target.state,
          zip_code: target.zip_code,
          longitude: target.longitude,
          latitude: target.latitude,
          address_line_1: target.address_line_1,
          address_line_2: target.address_line_2,
          name: target.name,
        };
      } else {
        return { location_id: session.location };
      }
    };

    const formatKeyAttrib = (session) => {
      switch (this.keyAttrib.type) {
        case ProfileFieldType.MultiList:
          return {
            attrib_id: this.keyAttrib.attrib_id,
            value_multi_list: session.keyAttribute.map((x) => ({ choice_id: Number(x) })),
          };
        case ProfileFieldType.Text:
          return {
            attrib_id: this.keyAttrib.attrib_id,
            value_text: session.keyAttribute,
          };
        default:
          return { attrib_id: this.keyAttrib.attrib_id, value_single_list: Number(session.keyAttribute) };
      }
    };

    return await Promise.all(
      sessions.map(async (s) => {
        const payload = [
          { attrib_id: this.dateAttrib.attrib_id, value_text: await formatDate(s.from, s.location) },
          { attrib_id: this.durationAttrib.attrib_id, value_count: s.duration },
          { attrib_id: this.locationAttrib.attrib_id, value_location: formatLocation(s) },
          formatKeyAttrib(s),
        ];

        return Array(s.count)
          .fill(0)
          .map((_) => payload);
      })
    );
  }

  private wireupValueChanges(formGroup: FormGroup) {
    formGroup
      .get('from')
      .valueChanges.pipe(
        takeUntil(this.stop$),
        tap((value) => {
          const session = formGroup.value;
          const endTime = this.getEndTime(value, session.duration);

          formGroup.patchValue({ to: endTime }, { emitEvent: false });
        })
      )
      .subscribe();

    formGroup
      .get('to')
      .valueChanges.pipe(
        takeUntil(this.stop$),
        tap((value) => {
          const session = formGroup.value;
          const duration = moment(value).diff(moment(session.from), 'minute');

          formGroup.patchValue({ duration }, { emitEvent: false });
        })
      )
      .subscribe();

    formGroup
      .get('duration')
      .valueChanges.pipe(
        takeUntil(this.stop$),
        tap((value) => {
          const session = formGroup.value;
          const endTime = this.getEndTime(session.from, value);

          formGroup.patchValue({ to: endTime }, { emitEvent: false });
        })
      )
      .subscribe();
  }
}

angular.module('ServetureWebApp').directive('adhocSchedule', downgradeComponent({ component: AdhocScheduleComponent }));
