import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  forwardRef,
  ViewEncapsulation,
  ChangeDetectorRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacySelect } from '@angular/material/legacy-select';
import { BehaviorSubject, filter, take, tap } from 'rxjs';

@Component({
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  selector: 'app-select',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSelectComponent),
      multi: true,
    },
  ],
})
export class CustomSelectComponent implements ControlValueAccessor {
  private selectedIndex = 0;
  public model;
  public filteredOptions: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private didSelectBeforeClose = false;

  private _options: any[];
  get options(): any[] {
    return this._options;
  }

  @Input() idProperty = 'id';

  @Input() set options(value: any[]) {
    this._options = value;
    this.filteredOptions.next(value);
    this.cdRef.detectChanges();
  }
  @Output() valueChanged = new EventEmitter<any>();
  @ViewChild(CdkVirtualScrollViewport, { static: false })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
  @ViewChild(MatLegacySelect, { static: false })
  matSelect: MatLegacySelect;

  constructor(private cdRef: ChangeDetectorRef) {}

  filterMyOptions(e) {
    const values = this.options.filter((x) => x?.name?.toLowerCase().indexOf(e?.toLowerCase()) > -1);
    this.filteredOptions.next(values);
  }

  openChange($event: boolean) {
    if ($event) {
      this.didSelectBeforeClose = false;
    }
    if (!this.didSelectBeforeClose) {
      this.cdkVirtualScrollViewPort.scrollToIndex(this.selectedIndex - 1);
      this.cdkVirtualScrollViewPort.checkViewportSize();
    }
  }

  selectionChange(e) {
    this.selectedIndex = this.options.findIndex((x) => x === e.value);
    this.propagateChange(this.model);
    this.valueChanged.emit(this.model);
    this.didSelectBeforeClose = true;
  }

  writeValue(value: any) {
    if (isNaN(value)) {
      this.model = value;
    } else {
      this.filteredOptions
        .pipe(
          filter((x) => !!x.length),
          take(1),
          tap((result) => {
            this.model = result.find((x) => x[this.idProperty] === value);
            this.cdRef.detectChanges();
          })
        )
        .subscribe();
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  trackByFunc(index, item): void {
    return item.enterprise_id;
  }
}
