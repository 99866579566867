import angular from 'angular';
import module from '../module';
import Swal from 'sweetalert2';

export default module.controller('EnterpriseTabController', [
  '$scope',
  '$modal',
  '$window',
  'enterpriseListService',
  'AuthenticationService',
  'PERMISSIONS',
  'trackingService',
  'generalDataService',
  'EnterpriseFilterService',
  'branchFilterService',
  'AlertService',
  function (
    $scope,
    $modal,
    $window,
    enterpriseListService,
    AuthenticationService,
    PERMISSIONS,
    trackingService,
    generalDataService,
    EnterpriseFilterService,
    branchFilterService,
    AlertService
  ) {
    'use strict';
    let savedAttribFilters = null;
    let delayFetch;

    $scope.getKeyProfession = function () {
      return generalDataService.getKeyProfession().data_content;
    };

    $scope.initialized = false;

    $scope.keySession = generalDataService.getKeySession();

    $scope.initialize = function () {
      const CURRENT_USER = AuthenticationService.getCurrentUser();
      $scope.reverse = false;
      $scope.order_by = 1;
      $scope.currentPage = 1;
      $scope.pageSize = 25;

      $scope.addPermission = CURRENT_USER.permissionCheck(PERMISSIONS.ADD_ENTERPRISE);
      $scope.editPermission = CURRENT_USER.permissionCheck(PERMISSIONS.EDIT_ENTERPRISE);
      $scope.deletePermission = CURRENT_USER.permissionCheck(PERMISSIONS.DELETE_ENTERPRISE);

      if (CURRENT_USER.user_type != 2 && CURRENT_USER.user_type != 6) {
        $window.location.reload();
      }

      delayFetch = setTimeout(() => $scope.getEnterprises, 1000);
      $scope.keyProfession = $scope.getKeyProfession();
    };

    $scope.$on('BranchSelectionChanged', () => $scope.getEnterprises());

    $scope.getEnterprises = function () {
      $scope.showSpinner = true;
      const currentBranch = branchFilterService.getSelectedBranch();

      const payload = {
        page: $scope.currentPage,
        size: $scope.pageSize,
        order_by: $scope.order_by,
        descending: $scope.reverse,
        branch_id: currentBranch && currentBranch.id,
      };

      if (savedAttribFilters) {
        savedAttribFilters.forEach((filter) => {
          payload[filter.name] = filter.value;
        });
      }

      enterpriseListService.getFilteredEnterprises(payload).then(function (response) {
        $scope.enterprises = angular.copy(response.results);
        $scope.numberOfEnterprises = response.count;
        $scope.showSpinner = false;
      });
    };

    $scope.gotoPage = function (page) {
      $scope.currentPage = page;
      $scope.getEnterprises();
    };

    $scope.openModal = function (type, enterprise) {
      let ctrl;
      if (type === 'new') ctrl = 'NewEnterpriseModalController';
      else if (type === 'edit') {
        ctrl = 'EditEnterpriseModalController';
      }

      const modalInstance = $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/views/admin/enterprise/enterpriseModal.html',
        controller: ctrl,
        size: 'lg',
        backdrop: 'static',
        resolve: {
          passedEnterprise: function () {
            return enterprise;
          },
          branch_selection: () => branchFilterService.getSelectedBranch(),
        },
      });
      modalInstance.result.then(function () {
        $scope.getEnterprises();
      });
      trackingService.trackEvent(trackingService.ADMIN, 'enterprise', type);
    };

    $scope.remove = async function (enterprise) {
      const config = {
        title: 'Are you sure?',
        html: `This action will remove the enterprise from lists and drop down selections. This action does not remove Admin Users, Workers or Shifts associated with the enterprise.<br/>You will need to log out and then log back in to view this change.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Yes, Remove Enterprise',
        cancelButtonText: 'No',
      };

      const returnHandler = function (isConfirm) {
        if (isConfirm) {
          enterpriseListService.removeEnterprise(enterprise.id).then(
            async function () {
              const configPositive = {
                title: 'Removed!',
                text: 'Enterprise was removed',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configPositive);

              EnterpriseFilterService.deleteEnterprise(enterprise.id);
              $scope.getEnterprises();
            },

            async function () {
              const configError = {
                title: 'Error',
                text: 'There was a problem removing the Enterprise.',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configError);
            }
          );
        }
      };

      const { value: returnValue } = await Swal.fire(config);
      returnHandler(returnValue);

      trackingService.trackEvent(trackingService.ADMIN, 'enterprise', 'delete');
    };

    $scope.sort = function (orderKey) {
      $scope.order_by = orderKey; // set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; // if true make it false and vice versa
      $scope.getEnterprises();
    };

    $scope.export = async () => {
      const result = await Swal.fire({
        title:
          'Please note you will receive an email when the report is finished generating with a link to the report.',
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
      });

      if (result.isConfirmed) {
        const CURRENT_USER = AuthenticationService.getCurrentUser();
        const email =
          CURRENT_USER &&
          CURRENT_USER.profile_info &&
          CURRENT_USER.profile_info.find((x) => x.name === 'email_address');
        if (email && email.data) {
          $scope.showSpinner = true;

          enterpriseListService.extractEnterprises().then(
            function (response) {
              const swalConfig = {
                title: response.message,
                icon: response.success ? 'success' : 'error',
                showConfirmButton: true,
              };

              Swal.fire(swalConfig);
              $scope.showSpinner = false;
            },
            function (reason) {
              $scope.showSpinner = false;

              AlertService.serverRequestErrorAlert(reason);
            }
          );
        } else {
          Swal.fire({
            title: 'No email on record - please update your email address before proceeding.',
            icon: 'error',
          });
        }
      }
    };

    $scope.initialize();

    $scope.applyFilters = (attribFilters) => {
      savedAttribFilters = attribFilters;
      if (delayFetch) {
        clearTimeout(delayFetch);
        delayFetch = null;
      }
      $scope.getEnterprises();
    };
  },
]);
