import module from './module';

export default module.factory('requesterManagerService', [
  '$http',
  'BASE_URL',
  '$q',
  'Requester',
  function ($http, BASE_URL, $q, Requester) {
    const requestersManager = {
      _pool: {},
      _requestersArray: [],
      _selectedRequester: {},
      _retrieveInstance: function (userId, requesterData, dataType) {
        let instance = this._pool[userId];
        if (instance) {
          instance.setData(requesterData, dataType);
        } else {
          instance = new Requester(requesterData, dataType);
          this._pool[userId] = instance;
          this._requestersArray.push(instance);
        }
        return instance;
      },
      _load: function (userId, deferred) {
        const scope = this;
        $http
          .post(BASE_URL + '/get_profile_info', { user_id: userId })
          .then(function (res) {
            const requesterData = res.data;
            const requester = scope._retrieveInstance(userId, requesterData, 'DETAIL_DISPLAY');
            deferred.resolve(requester);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _loadAllRequesters: function (deferred) {
        const scope = this;
        $http
          .post(BASE_URL + '/get_requesters', {})
          .then(function (res) {
            const response = res.data;
            response.requester_list.forEach(function (requesterData) {
              scope._retrieveInstance(requesterData.user_id, requesterData, 'TABLE_DISPLAY');
            });
            deferred.resolve(scope._requestersArray);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _regNewRequester: function (requesterDetails, deferred) {
        $http
          .post(BASE_URL + '/create_new_user', requesterDetails)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _updateRequester: function (updateObj, deferred) {
        $http
          .post(BASE_URL + '/set_profile_info', updateObj)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function (response) {
            deferred.reject();
          });
      },
      /* Public Methods */
      getRequester: function (userId) {
        const deferred = $q.defer();
        this._load(userId, deferred);
        return deferred.promise;
      },
      getAllRequesters: function (requestType) {
        const deferred = $q.defer();
        if (requestType === 'newRequesterPresent') {
          this._loadAllRequesters(deferred);
        } else {
          const requesters = this._requestersArray;
          if (requesters.length > 0) {
            deferred.resolve(requesters);
          } else {
            this._loadAllRequesters(deferred);
          }
        }
        return deferred.promise;
      },
      registerRequester: function (requesterData) {
        const deferred = $q.defer();
        this._regNewRequester(requesterData, deferred);
        return deferred.promise;
      },
      updateRequesterAttribs: function (requesterData) {
        const deferred = $q.defer();
        this._updateRequester(requesterData, deferred);
        return deferred.promise;
      },
    };
    return requestersManager;
  },
]);
