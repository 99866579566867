import _ from 'lodash';
import angular from 'angular';
import module from '../module';
export default module.controller('EditLocationModalController', [
  '$scope',
  '$modalInstance',
  'passedLocation',
  'LocationTabService',
  'AlertService',
  'locationManagerService',
  'googleLocationService',
  function (
    $scope,
    $modalInstance,
    passedLocation,
    LocationTabService,
    AlertService,
    locationManagerService,
    googleLocationService
  ) {
    $scope.submitBtnDisbaled = false;
    const originalPlaces = angular.copy(passedLocation.place_list);
    $scope.title = 'Edit Location';
    $scope.btnLabel = 'Update Location';
    $scope.state_list = LocationTabService.getStateList();
    $scope.location = angular.copy(passedLocation);
    $scope.formData = LocationTabService.parseFormData($scope.location);
    // Modal controls
    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      const formatedPlaces = LocationTabService.formatUpdateLocation($scope.formData, originalPlaces);
      const formatedForm = LocationTabService.formatSubmission(formatedPlaces);
      formatedForm.location_id = $scope.location.location_id;
      updateLocation(formatedForm);
    };
    const updateLocation = function (formatedSubmission) {
      locationManagerService.updateLocation(formatedSubmission).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
          } else {
            AlertService.errorAlert(response.message);
          }
          $modalInstance.close();
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    };
    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
    $scope.addPlace = function () {
      $scope.formData.places.push({ name: '' });
    };
    $scope.removePlace = function () {
      $scope.formData.places.pop();
    };

    $scope.$watch(
      'formData.address_line_1',
      _.debounce(() => $scope.recalculate(), 1000)
    );
    $scope.$watch(
      'formData.address_line_2',
      _.debounce(() => $scope.recalculate(), 1000)
    );
    $scope.$watch(
      'formData.city',
      _.debounce(() => $scope.recalculate(), 1000)
    );

    $scope.recalculate = function () {
      if ($scope.formData.address_line_1 && $scope.formData.city) {
        let address;
        if ($scope.formData.address_line_2) {
          address = `${$scope.formData.address_line_1} ${$scope.formData.address_line_2} ${$scope.formData.city}`;
        } else {
          address = `${$scope.formData.address_line_1} ${$scope.formData.city}`;
        }
        googleLocationService.geocodeAddress(address).then((loc) => {
          if (loc) {
            $scope.formData.latitude = loc.lat;
            $scope.formData.longitude = loc.lng;
          }
        });
      }
    };
  },
]);
