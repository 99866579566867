import module from './module';
export default module.directive('dirDynamicStyle', [
  '$http',
  function ($http) {
    return {
      restrict: 'A',
      link: function ($scope, element, attrs) {
        function changeColors(data) {
          function hexToRgb(hex) {
            // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
            var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shorthandRegex, function (m, r, g, b) {
              return r + r + g + g + b + b;
            });

            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : null;
          }

          document.documentElement.style.setProperty('--main-color', data.main);
          if (data.main) {
            document.documentElement.style.setProperty('--main-color-light', `rgba(${hexToRgb(data.main)}, .2)`);
          }
          document.documentElement.style.setProperty('--highlight-color', data.highlight);
        }

        $scope.$on('UI.ColorChange', function (event, data) {
          changeColors(data);
        });
      },
    };
  },
]);
