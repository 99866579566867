import { Action } from '@ngrx/store';
import { CalendarView } from 'angular-calendar';
import { WorkerEvent } from '../../shared/models/worker-event';
import { Worker } from '../../shared/models/worker';

export const FETCH_WORKERS = '[Workers] Fetch workers';
export const WORKERS_FETCHED = '[Workers] Workers fetched';

export const FETCH_WORKER_DETAILS = '[Workers] Fetch details';
export const WORKER_DETAILS_FETCHED = '[Workers] Details fetched';

export const SELECT_TAB = '[Workers] Select tab';
export const SELECT_CALENDAR_VIEW = '[Workers] Select calendar view';

export const FETCH_EVENTS = '[Workers] Fetch events';
export const EVETNS_FETCHED = '[Workers] Events fetched';

export const FETCH_WORKER_BLACKLIST = '[Workers] Fetch worker blacklist';
export const WORKER_BLACKLIST_FETCHED = '[Workers] Worker blacklist fetched';
export const UPDATE_WORKER_BLACKLIST = '[Workers] Update worker blacklist';
export const WORKER_BLACKLIST_UPDATED = '[Workers] Worker blacklist updated';

export const FETCH_ENTERPRISE_BLACKLIST = '[Workers] Fetch enterprise blacklist';
export const ENTERPRISE_BLACKLIST_FETCHED = '[Workers] Enterprise blacklist fetched';
export const UPDATE_ENTERPRISE_BLACKLIST = '[Workers] Update enterprise blacklist';
export const ENTERPRISE_BLACKLIST_UPDATED = '[Workers] Enterprise blacklist updated';

export const LOG_Confidential_VIEW = '[Workers] Log confidential view';

export class FetchWorkers implements Action {
  public readonly type = FETCH_WORKERS;

  constructor(public payload?: any) {}
}

export class WorkersFetched implements Action {
  public readonly type = WORKERS_FETCHED;

  constructor(public result: any[], public rawResult: any) {}
}

export class FetchWorkerDetails implements Action {
  public readonly type = FETCH_WORKER_DETAILS;

  constructor(public userId: number, public providerId: number) {}
}

export class WorkerDetailsFetched implements Action {
  public readonly type = WORKER_DETAILS_FETCHED;

  constructor(public worker: Worker, public providerId: number) {}
}

export class SelectTab implements Action {
  public readonly type = SELECT_TAB;

  constructor(public tab: number) {}
}

export class SelectCalendarView implements Action {
  public readonly type = SELECT_CALENDAR_VIEW;

  constructor(public view: CalendarView) {}
}

export class FetchEvents implements Action {
  public readonly type = FETCH_EVENTS;

  constructor(public payload: { date: Date; view: CalendarView; providerId: number }) {}
}

export class EventsFetched implements Action {
  public readonly type = EVETNS_FETCHED;

  constructor(public events: WorkerEvent[]) {}
}

export class FetchWorkerBlacklist implements Action {
  public readonly type = FETCH_WORKER_BLACKLIST;

  constructor(public payload: { userId: number }) {}
}

export class WorkerBlacklistFetched implements Action {
  public readonly type = WORKER_BLACKLIST_FETCHED;

  constructor(public userId: number, public enterprises: { id: string; name: string }[]) {}
}

export class UpdateWorkerBlacklist implements Action {
  public readonly type = UPDATE_WORKER_BLACKLIST;

  constructor(public userId: number, public enterprises: number[]) {}
}

export class WorkerBlacklistUpdated implements Action {
  public readonly type = WORKER_BLACKLIST_UPDATED;

  constructor(public userId: number) {}
}

export class FetchEnterpriseBlacklist implements Action {
  public readonly type = FETCH_ENTERPRISE_BLACKLIST;

  constructor(public enterpriseId: number) {}
}

export class EnterpriseBlacklistFetched implements Action {
  public readonly type = ENTERPRISE_BLACKLIST_FETCHED;

  constructor(public enterpriseId: number, public workers: { id: number; first_name: string; last_name: string }[]) {}
}

export class UpdateEnterpriseBlacklist implements Action {
  public readonly type = UPDATE_ENTERPRISE_BLACKLIST;

  constructor(public enterpriseId: number, public workers: number[]) {}
}

export class EnterpriseBlacklistUpdated implements Action {
  public readonly type = ENTERPRISE_BLACKLIST_UPDATED;

  constructor(public enterpriseId: number) {}
}

export class LogConfidentialView implements Action {
  public readonly type = LOG_Confidential_VIEW;

  constructor(public attrib: any) {}
}

export type Actions =
  | FetchWorkers
  | WorkersFetched
  | FetchWorkerDetails
  | WorkerDetailsFetched
  | SelectTab
  | SelectCalendarView
  | FetchEvents
  | EventsFetched
  | FetchWorkerBlacklist
  | WorkerBlacklistFetched
  | UpdateWorkerBlacklist
  | WorkerBlacklistUpdated
  | FetchEnterpriseBlacklist
  | EnterpriseBlacklistFetched
  | UpdateEnterpriseBlacklist
  | EnterpriseBlacklistUpdated
  | LogConfidentialView;
