import module from '../../module';

export default module.controller('apiLogsTabDetailModal', [
  '$scope',
  '$modalInstance',
  'passedLog',
  function ($scope, $modalInstance, passedLog) {
    $scope.passedLog = passedLog;

    $scope.close = () => {
      $modalInstance.close();
    };
  },
]);
