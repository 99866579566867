export const WorkerReportExportLabels: string[] = [
  'Date',
  'New Workers',
  'Total Workers'
];

export const JobOrderReportExportLabels: string[] = [
  'Date',
  'New Jobs',
  'New Cancelled',
  'Total Cancelled',
  'Total Jobs',
];
