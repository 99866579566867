import { AppStateService } from './app-state.service';
import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { PermissionsService } from './permissions.service';

@Injectable()
export class LocationProvider {
  constructor(private authService: AuthenticationService, private permissionService: PermissionsService) { }

  public handleOtherLocation(originalList: any[], hideOtherLocation: boolean) {
    const target = originalList || [];
    const otherLocationSettingValue = (this.authService.getCurrentUser()?.should_allow_other_location ?? false) && !hideOtherLocation;
    if (
      otherLocationSettingValue &&
      !this.authService.getPermission(this.permissionService.PERMISSIONS.FORCE_KNOWN_LOCATION)
    ) {
      if (!target.find((x) => x.location_id === 0)) {
        target.push({
          name: 'Other',
          location_id: 0,
        });
      }
    }
    return target;
  }
}
