import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../store';
import { ToggleWidget } from '../store/actions';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'dashboard-widgets',
  templateUrl: './dashboard.widgets.component.html',
})
export class DashboardWidgetsComponent implements OnInit {
  public widgets = [];

  constructor(private store: Store<fromRoot.State>, public bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.store.select(fromRoot.getAllWidgets).subscribe((widgets) => {
      this.widgets = widgets;
    });
  }

  checkChange(widget) {
    let action = new ToggleWidget(widget, !widget.isClosed);
    this.store.dispatch(action);
  }

  close() {
    this.bsModalRef.hide();
  }
}
