import angular from 'angular';
import module from '../module';
import Swal from 'sweetalert2';

export default module.controller('BranchTabController', [
  '$scope',
  '$modal',
  '$window',
  'enterpriseListService',
  'AuthenticationService',
  'PERMISSIONS',
  'trackingService',
  'branchService',
  function (
    $scope,
    $modal,
    $window,
    enterpriseListService,
    AuthenticationService,
    PERMISSIONS,
    trackingService,
    branchService
  ) {
    $scope.initialize = function () {
      const CURRENT_USER = AuthenticationService.getCurrentUser();
      $scope.showSpinner = true;
      $scope.reverse = true;

      $scope.addPermission = CURRENT_USER.permissionCheck(PERMISSIONS.ADD_BRANCH);
      $scope.editPermission = CURRENT_USER.permissionCheck(PERMISSIONS.EDIT_BRANCH);
      $scope.deletePermission = CURRENT_USER.permissionCheck(PERMISSIONS.DELETE_BRANCH);

      if (CURRENT_USER.user_type !== 2) {
        $window.location.reload();
      }

      $scope.getEnterprises().then(() => $scope.getBranches());
      $scope.sort('name');
    };

    $scope.getEnterprises = function () {
      return enterpriseListService.getAllEnterprisesSimple().then(function (enterprises) {
        $scope.enterprises = angular.copy(enterprises);
      });
    };

    $scope.getBranches = function () {
      branchService.getAll().then(function (branches) {
        $scope.branches = branches.map((b) => ({
          id: b.id,
          name: b.name,
          enterprises: b.enterprises
            .map((e) => $scope.enterprises.find((x) => x.id === e))
            .filter((x) => !!x)
            .map((e) => e.name),
        }));
        $scope.showSpinner = false;
      });
    };

    $scope.openModal = function (type, branch) {
      let ctrl;
      if (type === 'new') {
        ctrl = 'NewBranchModalController';
      } else if (type === 'edit') {
        ctrl = 'EditBranchModalController';
      }

      const modalInstance = $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/views/admin/branch/branchModal.html',
        controller: ctrl,
        size: 'lg',
        backdrop: 'static',
        resolve: {
          passedBranchId: function () {
            return branch && branch.id;
          },
        },
      });
      modalInstance.result.then(function () {
        $scope.getBranches();
      });
      trackingService.trackEvent(trackingService.ADMIN, 'branch', type);
    };

    $scope.remove = async function (branch) {
      const config = {
        title: 'Are you sure?',
        text: `You are about to delete Branch ${branch.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Yes, delete Branch',
      };

      const returnHandler = function (isConfirm) {
        if (isConfirm) {
          branchService.delete(branch.id).then(
            async function () {
              const configPositive = {
                title: 'Deleted!',
                text: 'Branch was deleted',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configPositive);

              $scope.$emit('Branches.Updated');
              $scope.getBranches();
            },

            async function () {
              const configError = {
                title: 'Error',
                text: 'There was a problem deleting the Branch.',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configError);
            }
          );
        }
      };

      const { value: returnValue } = await Swal.fire(config);
      returnHandler(returnValue);

      trackingService.trackEvent(trackingService.ADMIN, 'branch', 'delete');
    };

    $scope.sort = function (keyname) {
      $scope.sortKey = keyname; // set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; // if true make it false and vice versa
    };

    $scope.initialize();
  },
]);
