import module from './module';
export default module.directive('dirOnLastRepeat', ['$timeout', function($timeout) {
    return function (scope, elm, attrs) {
        if (scope.$last) {
            $timeout(function () {
                scope.$eval(attrs.dirOnLastRepeat);
            });
        }
    };
}]);
