import { Pipe, PipeTransform } from '@angular/core';
import { Worker } from '../models/worker';
import { EnumsService } from '../services';
import Env from '../../scripts/env.js';

@Pipe({
  name: 'profilePicture',
})
export class ProfilePicturePipe implements PipeTransform {
  constructor(private enums: EnumsService) {}

  transform(value: Worker) {
    if (value && value.profile_picture) {
      return Env.DOMAIN + value.profile_picture;
    }

    if (value && value.profile_info) {
      const picture = value.profile_info.find((x) => x.type === 'image');

      if (picture && typeof picture === 'string') {
        return Env.DOMAIN + picture;
      } else if (picture && picture.data) {
        return Env.DOMAIN + picture.data;
      } else {
        return `${this.enums.AssetsFolder.images}avatar.png`;
      }
    }
  }
}
