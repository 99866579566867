import { Component, forwardRef, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { generateTimeFromShorthand } from '../../../shared/utils/time-utils';

@Component({
  templateUrl: './duration-input.component.html',
  styleUrls: ['./duration-input.component.scss'],
  selector: 'duration-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DurationInputComponent),
      multi: true,
    },
  ],
})
export class DurationInputComponent implements ControlValueAccessor {
  @Input() required: boolean = false;
  @Output() onFocus = new EventEmitter<void>();

  onChange: (value: number) => void = () => {};
  onTouched: Function = () => {};
  disabled = false;

  value: string;

  constructor(private cdr: ChangeDetectorRef) {}

  writeValue(value: number) {
    this.value = this.valueToString(value);
  }

  onValueChanged(e) {
    const newValue = this.stringToValue(e.target.value);
    this.onChange(newValue);
    this.onTouched();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  stringToValue(input: string): number {
    if (input) {
      const [hours, minutes] = input.split(':');
      return +hours * 60 + +minutes;
    } else {
      return 0;
    }
  }

  valueToString(input: number): string {
    const hours = Math.floor(input / 60);
    const minutes = input - hours * 60;
    let minuteLabel;
    if (minutes < 10) {
      minuteLabel = `0${minutes}`;
    } else {
      minuteLabel = minutes;
    }

    return `${hours}:${minuteLabel}`;
  }

  onValueFocus(e) {
    this.onFocus.emit();
    setTimeout(() => {
      e.target.select();
    }, 100);
  }

  onKeyDown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
    }
  }

  onValueBlur(e) {
    setTimeout(() => {
      const { value } = e.target;
      if (value.length < 4) {
        const stringValue = generateTimeFromShorthand(value);
        console.log(stringValue);
        const newValue = this.stringToValue(stringValue);
        console.log(newValue);

        this.onChange(newValue);
        this.value = stringValue;
        e.target.value = stringValue;
        this.onTouched();
        this.cdr.detectChanges();
      }
    }, 200);
  }
}
