import { JobService } from './job.service';
import { RequestService } from './request.service';
import { SessionFilterService } from './session.filter.service';
import { VerifyReportService } from './verify.report.service';
import { WidgetListService } from './widget.list.service';
import { WorkerService } from './worker.service';

export const SERVICES = [
  JobService,
  RequestService,
  SessionFilterService,
  VerifyReportService,
  WidgetListService,
  WorkerService
];

export * from './job.service';
export * from './request.service';
export * from './session.filter.service';
export * from './verify.report.service';
export * from './widget.list.service';
export * from './worker.service';
