import { DynamicDetailComponent } from './dynamic.detail.component';
import { OtherListComponent } from './other.list.component';
import { ProviderDetailsComponent } from './provider.details.component';
import { RequestListComponent } from './request.list.component';
import { TotalTimeListComponent } from './total-time-list.component';
import { SessionDetailsComponent } from './session.details.component';
import { VerifyReportComponent } from './verify.report.component';

export const DETAILS_COMPONENT = [
  DynamicDetailComponent,
  OtherListComponent,
  ProviderDetailsComponent,
  RequestListComponent,
  TotalTimeListComponent,
  SessionDetailsComponent,
  VerifyReportComponent,
];

export * from './dynamic.detail.component';
export * from './other.list.component';
export * from './provider.details.component';
export * from './request.list.component';
export * from './session.details.component';
export * from './verify.report.component';
export * from './total-time-list.component';
