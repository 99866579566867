import module from '../module';
import moment from 'moment';
export default module.controller('TrackSessionModalController', [
  '$scope',
  '$modal',
  '$modalInstance',
  'AlertService',
  'passedSession',
  'sessionManagerService',
  'EnterpriseFilterService',
  'trackingService',
  'generalDataService',
  'passed_job_id',
  'providerExportService',
  'TimeFormatService',
  function (
    $scope,
    $modal,
    $modalInstance,
    AlertService,
    passedSession,
    sessionManagerService,
    EnterpriseFilterService,
    trackingService,
    generalDataService,
    passed_job_id,
    providerExportService,
    TimeFormatService
  ) {
    $scope.keySession = generalDataService.getKeySession();

    // $scope.title = 'Track ' + $scope.keySession;
    $scope.title = 'Track Job';

    $scope.workerList = [];

    var entFilter = EnterpriseFilterService.fetchSelectedEnterprise();

    $scope.mapWorker = function (worker) {
      var mappedStatus, statusClass, date;

      date = moment.utc(worker.notify_date + ' ' + worker.notify_time, 'YYYY-MM-DD hh:mm:s A').local();
      switch (worker.status) {
        case 1:
          mappedStatus = 'Accepted';
          statusClass = 'accepted';
          break;
        case 2:
          mappedStatus = 'Rejected';
          statusClass = 'rejected';
          break;
        case 3:
          mappedStatus = 'Notified';
          statusClass = 'no-answer';
          break;
        case 4:
          mappedStatus = 'Not Notified Yet';
          statusClass = 'not-notified';
          break;
      }
      return {
        firstName: worker.first_name,
        lastName: worker.last_name,
        notifyDate: moment(date).format('D MMM YY'),
        notifyTime: moment(date).format('HH:mm'),
        status: mappedStatus,
        statusClass: statusClass,
        score: Math.round(worker.score * 10) / 10,
      };
    };
    $scope.ok = function () {
      $modalInstance.close();
      trackingService.trackEvent(trackingService.MODALS, $scope.title, '');
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'cancel');
    };

    $scope.buildSubmitDate = function () {
      if ($scope.session) {
        return moment
          .utc($scope.session.submit_date + ' ' + $scope.session.submit_time)
          .local()
          .format(TimeFormatService.format('timeDateLong'));
      }
    };

    $scope.export = () => {
      providerExportService.generateDownload($scope.workerList);
    };

    if (entFilter.enterprise_id) {
      if (passedSession && passedSession.worker_list) {
        $scope.workerList = passedSession.worker_list.map($scope.mapWorker);
      } else {
        sessionManagerService.getTrackingInfo(passedSession ? passedSession.request_id : passed_job_id).then(
          function (result) {
            if (result.success) {
              $scope.workerList = result.worker_list.map($scope.mapWorker);
            }
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
            $modalInstance.dismiss('cancel');
          }
        );
      }
    } else {
      $modalInstance.dismiss('cancel');
    }
  },
]);
