import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseWidget } from './base.widget';
import * as fromRoot from '../store';
import { FilterRequestList } from '../store/actions';

@Component({
  selector: 'shift-count-component',
  templateUrl: './shift.count.component.html'
})
export class ShiftCountComponent extends BaseWidget {
  public titleValue: any;
  public titleText = 'Requested';
  public customTitle: string;
  constructor(store: Store<fromRoot.State>) {
    super(store);
  }

  loadDataTemplate(list) {
    if (!list) {
      return;
    }
    this.store
      .select(fromRoot.getKeySession)
      .subscribe(keySession => (this.customTitle = `${keySession} Count`))
      .unsubscribe();
    this.count = list.length;
    this.titleValue = this.count;
    let statusMap = {};

    list.forEach(item => {
      if (statusMap[item.status.name]) {
        statusMap[item.status.name].count++;
      } else {
        statusMap[item.status.name] = { count: 1, status: item.status.type };
      }
    });

    this.data = Object.keys(statusMap).map(key => {
      let value = statusMap[key];
      return {
        name: key,
        value: value.count,
        status: value.status
      };
    });
  }

  filterAll() {
    let action = new FilterRequestList({
      showAll: true
    });
    this.store.dispatch(action);
  }

  filterByState(state: number) {
    let action = new FilterRequestList({ status: state });

    this.store.dispatch(action);
  }
}
