import { Component, Input, EventEmitter, Output } from '@angular/core';

import { TabHeaderService } from './tab-header.service';
import { tabHeaderAnimation } from './tab-header.animation';

import { IChart } from '../../interfaces/IChart';
import { IFilter } from '../../interfaces/IFilter';

@Component({
  selector: 'tab-header',
  templateUrl: 'tab-header.component.html',
  animations: tabHeaderAnimation,
})
export class TabHeaderComponent {

  @Input() chart: IChart;
  @Input() filters: IFilter;
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private tabHeaderService: TabHeaderService
  ) { }

  public exportToCSV() {
    this.tabHeaderService.exportToCSV(this.chart.name, this.chart.labels, this.chart.data);
  }

  public exportToPDF() {
    this.tabHeaderService.exportToPDF(this.chart.name, this.chart.labels, this.chart.data);
  }

  public onSelectionChanged(value: any) {
    this.selectionChanged.emit(value);
  }
}
