import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { ISelectionItem } from '../../interfaces/ISelectionItem';

@Component({
  selector: 'filter-component',
  templateUrl: 'filter.component.html',
})
export class FilterComponent implements OnInit {

  @Input() name: string;
  @Input() options: ISelectionItem[];
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();
  public selected: any;

  public ngOnInit() {
    this.selected = this.options[0]?.value;
    this.onSelectionChanged(this.selected);
  }

  public onSelectionChanged(value: any) {
    const id = parseInt(value, 10);
    this.selectionChanged.emit({ selected: this.options[id] });
  }
}
