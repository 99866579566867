import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, combineLatest, of } from 'rxjs';
import { exhaustMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { State } from '../../../../store';

import { WidgetListService, WorkerService, VerifyReportService, RequestService } from '../../services/';
import { TimeService } from '../../../../shared/services/time.service';

import {
  ENTERPRISE_FILTER_CHANGE,
  CATEGORY_FILTER_CHANGE,
  FETCH_MY_REQUESTS,
  TIME_FILTER_CHANGE,
  FETCH_ENTERPRISES,
  FETCH_KEY_ATTRIBUTE,
  FETCH_WORKERS,
  TOGGLE_WIDGET,
  WIDGET_SORT_CHANGED,
  FILTER_REQUEST_LIST,
  FETCH_REQUEST_DETAILS,
  TIME_RANGE_CHANGED,
  FILTER_REPORT_LIST,
  STAFF_FILTER_CHANGE,
  LOADED_ENTERPRISES,
  EXPORT_TO_CSV,
  EXPORT_TO_CSV_DETAILS,
  EXPORT_TO_PDF_DETAILS,
  CHECK_PERMISSION,
  FETCH_PROVIDER_DETAILS,
  FETCH_KEY_SESSION,
  FETCH_KEY_PROFESSION,
  PermissionsChanged,
  WidgetsChanged,
  LoadedMyRequests,
  TimeRangeChanged,
  LoadedEnterprises,
  LoadedKeyAttribute,
  LoadedWorkers,
  LoadedJobs,
  CategoryFilterChange,
  HasValidFilter,
  LoadedRequestDetails,
  LoadedVerifyReport,
  EnterpriseFilterChange,
  LoadedProviderDetails,
  LoadedKeySession,
  LoadedKeyProfession,
  ToggleWidget,
  StaffFilterChange,
  BranchFilterChange,
  BRANCH_FILTER_CHANGE,
} from '../actions';

import {
  AuthenticationService,
  EnterpriseFilterWrapperService,
  ExportService,
  GeneralDataService,
} from '../../../../shared/services/';

@Injectable()
export class WidgetEffects {
  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private widgetListService: WidgetListService,
    private requestService: RequestService,
    private timeService: TimeService,
    private authenticationService: AuthenticationService,
    private generalDataService: GeneralDataService,
    private workerService: WorkerService,
    private verifyReportService: VerifyReportService,
    private exportService: ExportService,
    private enterpriseFilterWrapperService: EnterpriseFilterWrapperService
  ) {}

  loadedEnterprises$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LOADED_ENTERPRISES),
      switchMap((action: any) => {
        if (action.enterprises) {
          let selected = this.enterpriseFilterWrapperService.fetchSelectedEnterprise();
          if (selected) {
            let enterprise = action.enterprises.find((x) => x.enterprise_id === selected.enterprise_id);
            if (enterprise) {
              return of(new EnterpriseFilterChange(enterprise.enterprise_id));
            }
          }
          return of(new EnterpriseFilterChange(action.enterprises[0].enterprise_id));
        }
      })
    )
  );

  widgetCategoryChanged$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CATEGORY_FILTER_CHANGE),
      map((action: any) => {
        let selected = this.widgetListService.fetchWidgets(action.categoryFilterId);
        let all = this.widgetListService.fetchAllWidgets(action.categoryFilterId);
        return new WidgetsChanged(selected, all);
      })
    )
  );

  myRequests$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ENTERPRISE_FILTER_CHANGE),
      map((action: EnterpriseFilterChange) => action),
      withLatestFrom(this.store),
      exhaustMap(([action, state]) => {
        if (!state.dashboard.timeRange) {
          return [
            {
              type: 'UNUSED',
            },
          ];
        }
        return combineLatest(
          this.requestService.fetch(
            action.enterpriseId,
            state.dashboard.timeRange,
            state.dashboard.staffFilter,
            state.commonData.selectedBranch?.id
          ),
          this.verifyReportService.fetch(
            action.enterpriseId,
            state.dashboard.timeRange,
            state.dashboard.staffFilter,
            state.commonData.selectedBranch?.id
          )
        );
      }),
      mergeMap((result) => {
        return [new LoadedMyRequests(result[0]), new LoadedVerifyReport(result[1])];
      })
    )
  ) as Observable<Action>;

  branchFilterChange$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(BRANCH_FILTER_CHANGE),
      map((action: BranchFilterChange) => action),
      withLatestFrom(this.store),
      exhaustMap(([action, state]) => {
        if (!state.dashboard.timeRange || action.branchId == -1) {
          return [
            {
              type: 'UNUSED',
            },
          ];
        }
        return combineLatest(
          this.requestService.fetch(-2, state.dashboard.timeRange, state.dashboard.staffFilter, action.branchId),
          this.verifyReportService.fetch(-2, state.dashboard.timeRange, state.dashboard.staffFilter, action.branchId)
        );
      }),
      mergeMap((result) => {
        return [new LoadedMyRequests(result[0]), new LoadedVerifyReport(result[1])];
      })
    )
  ) as Observable<Action>;

  timeFilterChanged$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TIME_FILTER_CHANGE),
      map((action: any) => {
        return new TimeRangeChanged(this.timeService.getRange(action.timeFilterId));
      })
    )
  );

  fetchKeyAttribute$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(FETCH_KEY_ATTRIBUTE),
      mergeMap((action: any) => {
        return this.generalDataService.getKeyAttribute().pipe(
          map((attrib) => {
            return new LoadedKeyAttribute(attrib);
          })
        );
      })
    )
  ) as Observable<Action>;

  fetchWorkers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ENTERPRISE_FILTER_CHANGE),
      mergeMap((action: any) => {
        return this.workerService.fetch(action.enterpriseId).pipe(
          map((data) => {
            return new LoadedWorkers(data);
          })
        );
      })
    )
  ) as Observable<Action>;

  toggleWidget$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TOGGLE_WIDGET),
      map((action: ToggleWidget) => action),
      withLatestFrom(this.store),
      mergeMap(([action, state]) => {
        if (action.status) {
          this.widgetListService.removeWidget(action.widget);
        } else {
          this.widgetListService.addWidget(action.widget);
        }
        return [new CategoryFilterChange(state.dashboard.categoryFilter)];
      })
    )
  );

  widgetOrderChanged$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(WIDGET_SORT_CHANGED),
      map((action: any) => {
        this.widgetListService.persistOrder(action.widgets, action.category);
        return {
          type: 'UNUSED',
        };
      })
    )
  );

  requestsFiltered$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(FILTER_REQUEST_LIST),
      map((action: any) => {
        if (action.filter) {
          let hasFilter = false;
          for (var key in action.filter) {
            if (action.filter[key]) {
              hasFilter = true;
            }
          }
          return new HasValidFilter(hasFilter);
        }
        return new HasValidFilter(false);
      })
    )
  );

  verifyListFiltered$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(FILTER_REPORT_LIST),
      map((action: any) => {
        if (action.filter) {
          let hasFilter = false;
          for (var key in action.filter) {
            if (action.filter[key]) {
              hasFilter = true;
            }
          }
          return new HasValidFilter(hasFilter);
        }
        return new HasValidFilter(false);
      })
    )
  );

  requestDetailsLoaded$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(FETCH_REQUEST_DETAILS),
      mergeMap((action: any) => {
        return this.requestService.getDetails(action.id).pipe(
          map((details) => {
            return new LoadedRequestDetails(details);
          })
        );
      })
    )
  ) as Observable<Action>;

  timeRangeChanged$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TIME_RANGE_CHANGED),
      map((action: TimeRangeChanged) => action),
      withLatestFrom(this.store),
      mergeMap(([action, state]) => {
        return combineLatest(
          this.verifyReportService.fetch(
            state.dashboard.enterpriseFilter,
            action.timeRange,
            state.dashboard.staffFilter,
            state.commonData.selectedBranch?.id
          ),
          this.requestService.fetch(
            state.dashboard.enterpriseFilter,
            action.timeRange,
            state.dashboard.staffFilter,
            state.commonData.selectedBranch?.id
          )
        );
      }),
      mergeMap((results) => {
        return [new LoadedVerifyReport(results[0]), new LoadedMyRequests(results[1])];
      })
    )
  ) as Observable<Action>;

  staffFilterChanged$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(STAFF_FILTER_CHANGE),
      map((action: StaffFilterChange) => action),
      withLatestFrom(this.store),
      mergeMap(([action, state]) => {
        return combineLatest(
          this.verifyReportService.fetch(state.dashboard.enterpriseFilter, state.dashboard.timeRange, action.code),
          this.requestService.fetch(state.dashboard.enterpriseFilter, state.dashboard.timeRange, action.code)
        );
      }),
      mergeMap((results) => {
        return [new LoadedVerifyReport(results[0]), new LoadedMyRequests(results[1])];
      })
    )
  );

  exportToCsv$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(EXPORT_TO_CSV),
      map((action: any) => {
        this.exportService.exportToCSV(action.data, action.widget);
        return {
          type: 'UNUSED',
        };
      })
    )
  );

  exportToCsvDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(EXPORT_TO_CSV_DETAILS),
      map((action: any) => {
        this.exportService.exportToCSVDetails(action.name, action.labels, action.data);
        return {
          type: 'UNUSED',
        };
      })
    )
  );

  exportToPdfDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(EXPORT_TO_PDF_DETAILS),
      map((action: any) => {
        this.exportService.exportToPDFDetails(action.name, action.labels, action.data);
        return {
          type: 'UNUSED',
        };
      })
    )
  );

  checkPermission$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CHECK_PERMISSION),
      map((action: any) => {
        let hasPermission = this.authenticationService.getPermission(action.permissionId);
        return new PermissionsChanged(action.permissionId, hasPermission);
      })
    )
  );

  ProviderDetailsLoaded$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(FETCH_PROVIDER_DETAILS),
      mergeMap((action: any) => {
        return this.workerService.getWorker(action.userId).pipe(
          map((details) => {
            return new LoadedProviderDetails(details);
          })
        );
      })
    )
  ) as Observable<Action>;

  KeySessionLoaded$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(FETCH_KEY_SESSION),
      mergeMap((action: any) => {
        return this.generalDataService.getKeySession().pipe(
          map((session) => {
            return new LoadedKeySession(session);
          })
        );
      })
    )
  );

  KeyProfessionLoaded$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(FETCH_KEY_PROFESSION),
      mergeMap((action: any) => {
        return this.generalDataService.getKeyProfession().pipe(
          map((profession) => {
            return new LoadedKeyProfession(profession);
          })
        );
      })
    )
  ) as Observable<Action>;
}
