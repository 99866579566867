import * as moment from 'moment';

export class SessionFilterService {
  filterLateSessions(sessions) {
    let now = moment();
    return sessions
      .filter((req) => req.status.type === 2)
      .map((req) => {
        req.lateDate = moment.utc(`${req.request_date} ${req.request_time}`).local();
        return req;
      })
      .filter((req) => now.isAfter(req.lateDate));
  }

  filterGoingLongSessions(sessions) {
    let now = moment();
    return sessions
      .filter((res) => res.status.type === 1)
      .map((req) => {
        let startTime = moment(`${req.request_date} ${req.request_time}`);
        let endTime = startTime.add(req.duration, 'minutes');
        req.goingLongTime = endTime;
        return req;
      })
      .filter((req) => now.isAfter(req.goingLongTime));
  }

  filterKeyAttribute(sessions, attribName, attribValue) {
    return sessions.filter((item) => {
      let theAttribValue;
      let theAttrib = item.attrib_list && item.attrib_list.find((attrib) => attrib.name === attribName);

      if (theAttrib) {
        if (theAttrib.type === 'single_list' || theAttrib.type === 'multi_list') {
          theAttribValue =
            theAttrib.value_list && theAttrib.value_list[0] && theAttrib.value_list[0].name === attribValue;
        } else if (theAttrib.type === 'text') {
          theAttribValue = theAttrib.value_text === attribValue;
        }
      }
      return theAttribValue;
    });
  }

  filterAllAccepted(sessions) {
    let states = [1, 2, 5];
    return sessions.filter((item) => states.indexOf(item.status.type) !== -1);
  }

  filterAllOffered(sessions) {
    let states = [1, 2, 3, 5, 6];
    return sessions.filter((item) => states.indexOf(item.status.type) !== -1);
  }
}
