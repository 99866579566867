import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseWidget } from './base.widget';

import * as fromRoot from '../store';
import { FilterRequestList } from '../store/actions';

import { SessionFilterService } from '../services/session.filter.service';

@Component({
  selector: 'late-component',
  templateUrl: './common.widget.template.html'
})
export class LateComponent extends BaseWidget {
  public titleValue;
  public titleText = 'Affected';

  constructor(store: Store<fromRoot.State>, private filterService: SessionFilterService) {
    super(store);
  }

  loadDataTemplate(list) {
    if (!list) {
      return;
    }
    let statusMap = {};

    let goingLongSessions = this.filterService.filterGoingLongSessions(list).length;
    let late = this.filterService.filterLateSessions(list).length;

    this.data = [];
    if (goingLongSessions) {
      this.data.push({
        name: 'Going Long',
        value: goingLongSessions
      });
    }
    if (late) {
      this.data.push({
        name: 'Late',
        value: late
      });
    }
    this.count = goingLongSessions + late;
    this.titleValue = this.count;
  }

  filterAll() {
    let action = new FilterRequestList({
      showAllLate: true
    });
    this.store.dispatch(action);
  }

  filterData(data) {
    let payload: any = {};
    if (data.name === 'Late') {
      payload.showLate = true;
    } else if (data.name === 'Going Long') {
      payload.showLong = true;
    }
    let action = new FilterRequestList(payload);
    this.store.dispatch(action);
  }
}
