import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { EnumsService } from '../../../../shared/services/enums.service';
import { NewReportsService } from './new-reports.service';

import { IChart } from '../../../../shared/interfaces/IChart';
import { IFilter } from '../../../../shared/interfaces/IFilter';
import { ISelectionItem } from '../../../../shared/interfaces/ISelectionItem';
import { ITimeRange } from '../../../../shared/interfaces/ITimeRange';

@Component({
  selector: 'new-reports',
  templateUrl: 'new-reports.component.html',
})
export class NewReportsComponent implements OnInit {

  public selectedChart: any;
  public chartProperties: any;
  public selectedTime: ITimeRange;
  public filters: IFilter[];
  public chartDataToExport: IChart;
  public isLoading: boolean;

  public chartData: Observable<any>;

  constructor(
    private newReportsService: NewReportsService,
    private enumsService: EnumsService,
  ) {
    this.filters = [];
    this.selectedChart = {} as any;
    this.chartProperties = {} as any;
    this.chartDataToExport = {} as IChart;
  }

  public ngOnInit() {
    this.filters = this.newReportsService.getFilters();
  }

  public onSelectionChanged(value: any) {
    if (!value) {
      return;
    }

    const selected: ISelectionItem = value.selected;
    if (selected.filterType === this.enumsService.FilterTypes.ChartFilter) {
      this.selectedChartUpdate(selected);
    }
    else {
      this.selectedTimeUpdate(selected);
    }
  }

  private selectedChartUpdate(chart: ISelectionItem) {
    this.selectedChart = chart;
    this.chartProperties = this.newReportsService.getChart(chart.value);
    this.chartUpdate();
  }

  private selectedTimeUpdate(time: ISelectionItem) {
    this.selectedTime = this.newReportsService.getTime(time.value);
    this.chartUpdate();
  }

  private chartUpdate() {
    this.isLoading = true;

    this.chartData = this.newReportsService.getChartData({ chartType: this.selectedChart.value, selectedTime: this.selectedTime });

    this.chartData?.subscribe((data) => {
      this.isLoading = false;
      this.chartDataToExport = this.newReportsService.getChartDataToExport(this.selectedChart, data);
    });
  }
}
