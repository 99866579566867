import module from './module';
import moment from 'moment';
import angular from 'angular';
import jQuery from 'jquery';

export default module
  .constant('dirDateTimePickerConfig', {
    configureOn: null,
    dropdownSelector: null,
    minuteStep: 5,
    minView: 'minute',
    renderOn: null,
    startViewDate: 'day',
    startViewTime: 'hour',
  })
  .directive('dirDatetimepicker', [
    '$log',
    'dirDateTimePickerConfig',
    'TimeFormatService',
    function dirDatetimepickerDirective($log, defaultConfig, TimeFormatService) {
      function DateObject() {
        const tempDate = new Date();
        const localOffset = tempDate.getTimezoneOffset() * 60000;
        this.utcDateValue = tempDate.getTime();
        this.selectable = true;

        this.localDateValue = function () {
          return this.utcDateValue + localOffset;
        };

        const validProperties = ['utcDateValue', 'localDateValue', 'display', 'active', 'selectable', 'past', 'future'];

        for (const prop in arguments[0]) {
          /* istanbul ignore else */
          // noinspection JSUnfilteredForInLoop
          if (validProperties.indexOf(prop) >= 0) {
            // noinspection JSUnfilteredForInLoop
            this[prop] = arguments[0][prop];
          }
        }
      }

      const validateConfiguration = function validateConfiguration(configuration) {
        const validOptions = [
          'configureOn',
          'dropdownSelector',
          'minuteStep',
          'minView',
          'renderOn',
          'startViewDate',
          'startViewTime',
        ];

        for (const prop in configuration) {
          // noinspection JSUnfilteredForInLoop
          if (validOptions.indexOf(prop) < 0) {
            throw 'invalid option: ' + prop;
          }
        }

        // Order of the elements in the validViews array is significant.
        const validViewsDate = ['day', 'month', 'year'];
        const validViewsTime = ['minute', 'hour'];

        if (validViewsDate.indexOf(configuration.startViewDate) < 0) {
          throw 'invalid startViewDate value: ' + configuration.startViewDate;
        }

        if (validViewsTime.indexOf(configuration.minView) < 0) {
          throw 'invalid minView value: ' + configuration.minView;
        }

        if (validViewsTime.indexOf(configuration.startViewTime) < 0) {
          throw 'invalid startViewTime value: ' + configuration.startViewTime;
        }

        if (!angular.isNumber(configuration.minuteStep)) {
          throw 'minuteStep must be numeric';
        }
        if (configuration.minuteStep <= 0 || configuration.minuteStep >= 60) {
          throw 'minuteStep must be greater than zero and less than 60';
        }
        if (configuration.configureOn !== null && !angular.isString(configuration.configureOn)) {
          throw 'configureOn must be a string';
        }
        if (configuration.configureOn !== null && configuration.configureOn.length < 1) {
          throw 'configureOn must not be an empty string';
        }
        if (configuration.renderOn !== null && !angular.isString(configuration.renderOn)) {
          throw 'renderOn must be a string';
        }
        if (configuration.renderOn !== null && configuration.renderOn.length < 1) {
          throw 'renderOn must not be an empty string';
        }
        if (configuration.dropdownSelector !== null && !angular.isString(configuration.dropdownSelector)) {
          throw 'dropdownSelector must be a string';
        }

        /* istanbul ignore next */
        if (
          configuration.dropdownSelector !== null &&
          (typeof jQuery === 'undefined' || typeof jQuery().dropdown !== 'function')
        ) {
          $log.error(
            'Please DO NOT specify the dropdownSelector option unless you are using jQuery AND Bootstrap.js. ' +
              'Please include jQuery AND Bootstrap.js, or write code to close the dropdown in the on-set-time callback. \n\n' +
              'The dropdownSelector configuration option is being removed because it will not function properly.'
          );
          delete configuration.dropdownSelector;
        }
      };

      return {
        restrict: 'E',
        require: 'ngModel',
        template:
          '<div class="datetimepicker table-responsive">' +
          '<div class="picker-header">' +
          '   <span id="picker-error-msg" class="clear-date-time" ng-if="showError">{{errorMsg}}</span>' +
          '   <span id="picker-close-btn" class="clear-date-time" ng-click="onClose()">Close</span>' +
          '</div>' +
          '<table class="table table-condensed  {{ data.date.currentView }}-view">' +
          '   <thead>' +
          '       <tr>' +
          '           <th class="left" data-ng-click="changeView(data.date.currentView, data.date.leftDate, $event)" data-ng-show="data.date.leftDate.selectable"><i class="glyphicon glyphicon-arrow-left"/></th>' +
          '           <th class="switch" colspan="5" data-ng-show="data.date.previousViewDate.selectable" data-ng-click="changeView(data.date.previousView, data.date.previousViewDate, $event)">{{ data.date.previousViewDate.display }}</th>' +
          '           <th class="right" data-ng-click="changeView(data.date.currentView, data.date.rightDate, $event)" data-ng-show="data.date.rightDate.selectable"><i class="glyphicon glyphicon-arrow-right"/></th>' +
          '       </tr>' +
          '       <tr>' +
          '           <th class="dow" data-ng-repeat="day in data.date.dayNames" >{{ day }}</th>' +
          '       </tr>' +
          '   </thead>' +
          '   <tbody>' +
          '       <tr data-ng-if="data.date.currentView !== \'day\'" >' +
          '           <td colspan="7" >' +
          '              <span    class="{{ data.date.currentView }}" ' +
          '                       data-ng-repeat="dateObject in data.date.dates"  ' +
          '                       data-ng-class="{active: dateObject.active, past: dateObject.past, future: dateObject.future, disabled: !dateObject.selectable}" ' +
          '                       data-ng-click="changeView(data.date.nextView, dateObject, $event)">{{ dateObject.display }}</span> ' +
          '           </td>' +
          '       </tr>' +
          '       <tr data-ng-if="data.date.currentView === \'day\'" data-ng-repeat="week in data.date.weeks">' +
          '           <td data-ng-repeat="dateObject in week.dates" ' +
          '               data-ng-click="changeView(data.date.nextView, dateObject, $event)"' +
          '               class="day" ' +
          '               data-ng-class="{active: dateObject.active, past: dateObject.past, future: dateObject.future, disabled: !dateObject.selectable}" >{{ dateObject.display }}</td>' +
          '       </tr>' +
          '   </tbody>' +
          '</table>' +
          '<div class="vertical-divider"></div>' +
          '<table class="table table-condensed  {{ data.time.currentView }}-view">' +
          '   <thead>' +
          '       <tr>' +
          '           <th class="left" data-ng-click="changeView(data.time.currentView, data.time.leftDate, $event)" data-ng-show="data.time.leftDate.selectable"><i class="glyphicon glyphicon-arrow-left"/></th>' +
          '           <th class="switch" colspan="5" data-ng-show="data.time.previousViewDate.selectable" data-ng-click="changeView(data.time.previousView, data.time.previousViewDate, $event)">{{ data.time.previousViewDate.display }}</th>' +
          '           <th class="right" data-ng-click="changeView(data.time.currentView, data.time.rightDate, $event)" data-ng-show="data.time.rightDate.selectable"><i class="glyphicon glyphicon-arrow-right"/></th>' +
          '       </tr>' +
          '       <tr>' +
          '           <th class="dow" data-ng-repeat="day in data.time.dayNames" >{{ day }}</th>' +
          '       </tr>' +
          '   </thead>' +
          '   <tbody>' +
          '       <tr data-ng-if="data.time.currentView !== \'day\'" >' +
          '           <td colspan="7" >' +
          '              <span    class="{{ data.time.currentView }}" ' +
          '                       data-ng-repeat="dateObject in data.time.dates"  ' +
          '                       data-ng-class="{active: dateObject.active, past: dateObject.past, future: dateObject.future, disabled: !dateObject.selectable}" ' +
          '                       data-ng-click="changeView(data.time.nextView, dateObject, $event)">{{ dateObject.display }}</span> ' +
          '           </td>' +
          '       </tr>' +
          '       <tr data-ng-if="data.time.currentView === \'day\'" data-ng-repeat="week in data.time.weeks">' +
          '           <td data-ng-repeat="dateObject in week.dates" ' +
          '               data-ng-click="changeView(data.time.nextView, dateObject, $event)"' +
          '               class="day" ' +
          '               data-ng-class="{active: dateObject.active, past: dateObject.past, future: dateObject.future, disabled: !dateObject.selectable}" >{{ dateObject.display }}</td>' +
          '       </tr>' +
          '   </tbody>' +
          '</table>' +
          '</div>',
        scope: {
          onSetTime: '&',
          onClose: '&',
          beforeRender: '&',
          isCheckinDate: '=',
          referenceDate: '=',
        },
        replace: true,
        link: function link(scope, element, attrs, ngModelController) {
          const configure = function configure() {
            let directiveConfig = {};

            if (attrs.datetimepickerConfig) {
              directiveConfig = scope.$parent.$eval(attrs.datetimepickerConfig);
            }

            const configuration = {};

            angular.extend(configuration, defaultConfig, directiveConfig);

            validateConfiguration(configuration);

            return configuration;
          };

          let configuration = configure();

          const startOfDecade = function startOfDecade(unixDate) {
            const startYear = parseInt(moment.utc(unixDate).year() / 10, 10) * 10;
            return moment.utc(unixDate).year(startYear).startOf('year');
          };

          const dataFactory = {
            year: function year(unixDate) {
              const selectedDate = moment.utc(unixDate).startOf('year');
              // View starts one year before the decade starts and ends one year after the decade ends
              // i.e. passing in a date of 1/1/2013 will give a range of 2009 to 2020
              // Truncate the last digit from the current year and subtract 1 to get the start of the decade
              const startDecade = parseInt(selectedDate.year() / 10, 10) * 10;
              const startDate = moment.utc(startOfDecade(unixDate)).subtract(1, 'year').startOf('year');

              const activeYear = ngModelController.$modelValue ? moment(ngModelController.$modelValue).year() : 0;

              const result = {
                currentView: 'year',
                nextView: configuration.minView === 'year' ? 'setTime' : 'month',
                previousViewDate: new DateObject({
                  utcDateValue: null,
                  display: startDecade + '-' + (startDecade + 9),
                }),
                leftDate: new DateObject({
                  utcDateValue: moment.utc(startDate).subtract(9, 'year').valueOf(),
                }),
                rightDate: new DateObject({
                  utcDateValue: moment.utc(startDate).add(11, 'year').valueOf(),
                }),
                dates: [],
              };

              for (let i = 0; i < 12; i += 1) {
                const yearMoment = moment.utc(startDate).add(i, 'years');
                const dateValue = {
                  utcDateValue: yearMoment.valueOf(),
                  display: yearMoment.format('YYYY'),
                  past: yearMoment.year() < startDecade,
                  future: yearMoment.year() > startDecade + 9,
                  active: yearMoment.year() === activeYear,
                };

                result.dates.push(new DateObject(dateValue));
              }

              return result;
            },

            month: function month(unixDate) {
              const startDate = moment.utc(unixDate).startOf('year');
              const previousViewDate = startOfDecade(unixDate);
              const activeDate = ngModelController.$modelValue
                ? moment(ngModelController.$modelValue).format('YYYY-MMM')
                : 0;

              const result = {
                previousView: 'year',
                currentView: 'month',
                nextView: configuration.minView === 'month' ? 'setTime' : 'day',
                previousViewDate: new DateObject({
                  utcDateValue: previousViewDate.valueOf(),
                  display: startDate.format('YYYY'),
                }),
                leftDate: new DateObject({
                  utcDateValue: moment.utc(startDate).subtract(1, 'year').valueOf(),
                }),
                rightDate: new DateObject({
                  utcDateValue: moment.utc(startDate).add(1, 'year').valueOf(),
                }),
                dates: [],
              };

              for (let i = 0; i < 12; i += 1) {
                const monthMoment = moment.utc(startDate).add(i, 'months');
                const dateValue = {
                  utcDateValue: monthMoment.valueOf(),
                  display: monthMoment.format('MMM'),
                  active: monthMoment.format('YYYY-MMM') === activeDate,
                };

                result.dates.push(new DateObject(dateValue));
              }

              return result;
            },

            day: function day(unixDate) {
              const selectedDate = moment.utc(unixDate);
              const startOfMonth = moment.utc(selectedDate).startOf('month');
              const previousViewDate = moment.utc(selectedDate).startOf('year');
              const endOfMonth = moment.utc(selectedDate).endOf('month');

              const startDate = moment.utc(startOfMonth).subtract(Math.abs(startOfMonth.weekday()), 'days');

              let activeDate;
              if (selectedDate) {
                activeDate = moment(selectedDate).format(TimeFormatService.format('fullDateLongMonth'));
                ngModelController.$modelValue = activeDate;
              } else {
                activeDate = ngModelController.$modelValue
                  ? moment(ngModelController.$modelValue).format(TimeFormatService.format('fullDateLongMonth'))
                  : '';
              }

              const result = {
                previousView: 'month',
                currentView: 'day',
                nextView: 'day',

                previousViewDate: new DateObject({
                  utcDateValue: previousViewDate.valueOf(),
                  display: startOfMonth.format('YYYY-MMM'),
                }),
                leftDate: new DateObject({
                  utcDateValue: moment.utc(startOfMonth).subtract(1, 'months').valueOf(),
                }),
                rightDate: new DateObject({
                  utcDateValue: moment.utc(startOfMonth).add(1, 'months').valueOf(),
                }),
                dayNames: [],
                weeks: [],
              };

              for (let dayNumber = 0; dayNumber < 7; dayNumber += 1) {
                result.dayNames.push(moment.utc().weekday(dayNumber).format('dd'));
              }

              for (let i = 0; i < 6; i += 1) {
                const week = { dates: [] };
                for (let j = 0; j < 7; j += 1) {
                  const monthMoment = moment.utc(startDate).add(i * 7 + j, 'days');
                  const dateValue = {
                    utcDateValue: monthMoment.valueOf(),
                    display: monthMoment.format('D'),
                    active: monthMoment.format(TimeFormatService.format('fullDateLongMonth')) === activeDate,
                    past: monthMoment.isBefore(startOfMonth),
                    future: monthMoment.isAfter(endOfMonth),
                  };
                  week.dates.push(new DateObject(dateValue));
                }
                result.weeks.push(week);
              }

              return result;
            },

            hour: function hour(unixDate) {
              const selectedDate = moment.utc(unixDate).startOf('day');
              const previousViewDate = moment.utc(selectedDate).startOf('month');

              const activeFormat = ngModelController.$viewValue
                ? moment(ngModelController.$viewValue).format(TimeFormatService.format('fullDateHour'))
                : '';

              const result = {
                // 'previousView': 'day',
                currentView: 'hour',
                nextView: configuration.minView === 'hour' ? 'setTime' : 'minute',
                previousViewDate: new DateObject({
                  utcDateValue: previousViewDate.valueOf(),
                  display: selectedDate.format('LT'),
                }),
                leftDate: new DateObject({
                  utcDateValue: moment.utc(selectedDate).subtract(1, 'days').valueOf(),
                }),
                rightDate: new DateObject({
                  utcDateValue: moment.utc(selectedDate).add(1, 'days').valueOf(),
                }),
                dates: [],
              };

              for (let i = 0; i < 24; i += 1) {
                const hourMoment = moment.utc(selectedDate).add(i, 'hours');
                const dateValue = {
                  utcDateValue: hourMoment.valueOf(),
                  display: hourMoment.format('LT'),
                  active: hourMoment.format(TimeFormatService.format('fullDateHour')) === activeFormat,
                };

                result.dates.push(new DateObject(dateValue));
              }

              return result;
            },

            minute: function minute(unixDate) {
              const selectedDate = moment.utc(unixDate).startOf('hour');
              const previousViewDate = moment.utc(selectedDate).startOf('day');
              const activeFormat = ngModelController.$viewValue
                ? moment(ngModelController.$viewValue).format(TimeFormatService.format('fullDateHourMinute'))
                : '';

              const result = {
                previousView: 'hour',
                currentView: 'minute',
                nextView: 'setTime',
                previousViewDate: new DateObject({
                  utcDateValue: previousViewDate.valueOf(),
                  display: selectedDate.format('LT'),
                }),
                leftDate: new DateObject({
                  utcDateValue: moment.utc(selectedDate).subtract(1, 'hours').valueOf(),
                }),
                rightDate: new DateObject({
                  utcDateValue: moment.utc(selectedDate).add(1, 'hours').valueOf(),
                }),
                dates: [],
              };

              const limit = 60 / configuration.minuteStep;

              for (let i = 0; i < limit; i += 1) {
                const hourMoment = moment.utc(selectedDate).add(i * configuration.minuteStep, 'minute');
                const dateValue = {
                  utcDateValue: hourMoment.valueOf(),
                  display: hourMoment.format('mm'),
                  active: hourMoment.format(TimeFormatService.format('fullDateHourMinute')) === activeFormat,
                };

                result.dates.push(new DateObject(dateValue));
              }

              return result;
            },

            setTime: function setTime(unixDate) {
              const tempDate = new Date(unixDate);
              const newDate = new Date(
                tempDate.getUTCFullYear(),
                tempDate.getUTCMonth(),
                tempDate.getUTCDate(),
                tempDate.getUTCHours(),
                tempDate.getUTCMinutes(),
                tempDate.getUTCSeconds(),
                tempDate.getUTCMilliseconds()
              );

              const referenceDate = moment(scope.referenceDate).valueOf();
              if (scope.isCheckinDate && newDate.getTime() >= referenceDate) {
                scope.showError = true;
                scope.errorMsg = 'Please choose a smaller date and time than the checkout';
                setTimeout(() => {
                  scope.showError = false;
                }, 5000);
                return;
              } else if (!scope.isCheckinDate && newDate.getTime() <= referenceDate) {
                scope.showError = true;
                scope.errorMsg = 'Please choose a bigger date and time than the checkin';
                setTimeout(() => {
                  scope.showError = false;
                }, 5000);
                return;
              }

              var oldDate = ngModelController.$modelValue;
              ngModelController.$setViewValue(newDate);

              if (configuration.dropdownSelector) {
                jQuery(configuration.dropdownSelector).dropdown('toggle');
              }

              scope.onSetTime({ newDate: newDate, oldDate: oldDate });

              return dataFactory[configuration.startViewDate](unixDate);
            },
          };

          var getUTCTime = function getUTCTime(modelValue) {
            const tempDate = modelValue ? moment(modelValue).toDate() : new Date();
            return tempDate.getTime() - tempDate.getTimezoneOffset() * 60000;
          };

          scope.onSave = function () {
            scope.onSetTime({ newDate: newDate, oldDate: oldDate });
          };

          scope.changeView = function changeView(viewName, dateObject, event) {
            if (event) {
              event.stopPropagation();
              event.preventDefault();
            }

            if (viewName && dateObject.utcDateValue > -Infinity && dateObject.selectable && dataFactory[viewName]) {
              var result = dataFactory[viewName](dateObject.utcDateValue);

              var weekDates = [];
              if (!result) {
                return;
              }
              if (result.weeks) {
                for (var i = 0; i < result.weeks.length; i += 1) {
                  var week = result.weeks[i];
                  for (var j = 0; j < week.dates.length; j += 1) {
                    var weekDate = week.dates[j];
                    weekDates.push(weekDate);
                  }
                }
              }

              scope.beforeRender({
                $view: result.currentView,
                $dates: result.dates || weekDates,
                $leftDate: result.leftDate,
                $upDate: result.previousViewDate,
                $rightDate: result.rightDate,
              });

              var validViewsDate = ['day', 'month', 'year'];
              if (!scope.data) {
                scope.data = {};
              }

              if (validViewsDate.indexOf(result.currentView) < 0) {
                scope.data.time = result;
              } else {
                scope.data.date = result;
                // adjust the time selector to the new selected date
                if (scope.data.time && scope.data.time.currentView) {
                  var newTimeData = dataFactory[scope.data.time.currentView](dateObject.utcDateValue);
                  scope.data.time = newTimeData;
                }
              }
            }
          };

          ngModelController.$render = function $render() {
            scope.changeView(
              configuration.startViewDate,
              new DateObject({
                utcDateValue: getUTCTime(ngModelController.$viewValue),
              })
            );
            scope.changeView(
              configuration.startViewTime,
              new DateObject({
                utcDateValue: getUTCTime(ngModelController.$viewValue),
              })
            );
          };

          if (configuration.configureOn) {
            scope.$on(configuration.configureOn, function () {
              configuration = configure();
              ngModelController.$render();
            });
          }
          if (configuration.renderOn) {
            scope.$on(configuration.renderOn, ngModelController.$render);
          }
        },
      };
    },
  ]);
