import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { catchError, take } from 'rxjs';

@Component({
  selector: 'app-info-page',
  templateUrl: './info-page.component.html',
  styleUrls: ['./info-page.component.scss'],
})
export class InfoPageComponent implements OnInit {
  private readonly SMART_LINK_PARAM = 'smart_link';

  public smartLink = '';

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        take(1),
        catchError((err) => {
          this.smartLink = '';
          throw `Error retrieving Smart link: ${err}`;
        })
      )
      .subscribe((params) => {
        if (params[this.SMART_LINK_PARAM]) {
          const decodedSmartLink = atob(params[this.SMART_LINK_PARAM]);
          this.smartLink = decodedSmartLink;
        }
      });
  }

  // Test Code for testing base64  smart links
  testSmartLink(base64Str: string): void {
    // const testStr = 'https://google.com'; aHR0cHM6Ly9nb29nbGUuY29t
    //const encode = btoa(testStr);
    const decode = atob(base64Str);

    // console.log('encode:', encode)
    console.log('decode:', decode);
  }

  navigateToSmartLink(): void {
    if (this.smartLink) {
      window.open(this.smartLink, '_blank');
    }
  }
}
