import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseWidget } from './base.widget';
import * as fromRoot from '../store';
import { FilterRequestList } from '../store/actions';

@Component({
  selector: 'shift-quality-component',
  templateUrl: './common.widget.template.html'
})
export class ShiftQualityComponent extends BaseWidget {
  public customTitle: string;
  public titleValue;
  public titleText = 'Requested';
  constructor(store: Store<fromRoot.State>) {
    super(store);
  }

  loadDataTemplate(list) {
    if (!list) {
      return;
    }
    this.store
      .select(fromRoot.getKeySession)
      .subscribe(keySession => (this.customTitle = `${keySession} Quality`))
      .unsubscribe();

    this.count = list.length;
    this.titleValue = this.count;
    let statusMap = {
      Accepted: 0,
      'Cancelled By Requester': 0,
      'Canceled by Admin': 0,
      'Cancelled By Worker': 0
    };

    list.forEach(item => {
      switch (item.status.type) {
        case 1:
        case 2:
          statusMap.Accepted++;
          break;
        case 7:
          if (item.cancelled_reason === 1) {
            statusMap['Canceled by Admin']++;
          } else if (item.cancelled_reason === 4) {
            statusMap['Cancelled By Requester']++;
          } else if (item.cancelled_reason === 5) {
            statusMap['Cancelled By Worker']++;
          }
          break;
      }
    });

    this.data = Object.keys(statusMap).map(key => {
      let value = statusMap[key];
      return {
        name: key,
        value: value
      };
    });
    if (!this.data.some(x => x.value)) {
      this.data = [];
    }
  }

  filterAll() {
    let action = new FilterRequestList({
      showAll: true
    });
    this.store.dispatch(action);
  }

  filterData(data) {
    let payload: any = {};
    if (data.name === 'Accepted') {
      payload.showAccepted = true;
    } else if (data.name === 'Resent') {
      payload.cancelCode = 1;
    } else if (data.name === 'Cancelled By Requester') {
      payload.cancelCode = 4;
    } else if (data.name === 'Cancelled By Worker') {
      payload.cancelCode = 5;
    }

    let action = new FilterRequestList(payload);
    this.store.dispatch(action);
  }
}
