import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { BirdsEyeModel, BirdsEyeSession } from '../../../shared/models/birds-eye';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  templateUrl: './birds-eye-weekly.component.html',
  styleUrls: ['./birds-eye-weekly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-birds-eye-weekly',
})
export class BirdsEyeWeeklyComponent implements OnInit, OnChanges {
  @Input() model: BirdsEyeModel;
  @Input() mode: 'schedule' | 'offer';
  @Output() sessionsChanged = new EventEmitter<{ [key: number]: number[] }>();
  @Output() modeChange = new EventEmitter<'schedule' | 'offer'>();
  @Input() sessions: { [key: number]: number[] } = {};
  public pages = [];
  public currentPage = 0;

  public sessionsOnPage: BirdsEyeSession[];

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model && changes.model.currentValue) {
      const sessionList = changes.model.currentValue.session_list as BirdsEyeSession[];
      this.pages = [];
      for (var i = 0; i < sessionList.length; i += 10) {
        this.pages.push(sessionList.slice(i, i + 10));
      }

      this.sessionsOnPage = this.pages[0];
    }
  }

  public previousPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.sessionsOnPage = this.pages[this.currentPage];
    }
  }

  public nextPage() {
    if (this.pages.length > this.currentPage + 1) {
      this.currentPage++;
      this.sessionsOnPage = this.pages[this.currentPage];
    }
  }

  public getSessionDate(session: BirdsEyeSession) {
    return moment(session.start_date, 'YYYY-MM-DD').format('MMM D');
  }

  public getSessionInfo(session: BirdsEyeSession) {
    const hourFormat = 'h A';
    const hourMintueFormat = 'h:mm A';

    const startTime = moment(`${session.start_date} ${session.start_time}`, 'YYYY-MM-DD hh:mm').format(
      +session.start_time.split(':')[1] ? hourMintueFormat : hourFormat
    );
    const endTime = moment(`${session.start_date} ${session.end_time}`, 'YYYY-MM-DD hh:mm').format(
      +session.end_time.split(':')[1] ? hourMintueFormat : hourFormat
    );
    const timeFrame = `${startTime}-${endTime}`;

    return `${timeFrame}`;
  }

  public isSessionActive(session_id, provider_id) {
    if (!this.sessions[session_id]) {
      return false;
    }
    return this.sessions[session_id].indexOf(provider_id) > -1;
  }

  public isSessionAvailable(session_id) {
    return !this.sessions[session_id]?.length;
  }

  public associateSession(session_id, provider_id) {
    if (this.isSessionActive(session_id, provider_id)) {
      const idx = this.sessions[session_id].indexOf(provider_id);
      this.sessions[session_id].splice(idx, 1);
    } else {
      if (this.checkOverlap(session_id, provider_id)) {
        this.showOverlapError();
        return;
      }

      const newSessions = this.sessions[session_id] || [];
      this.sessions[session_id] = [...newSessions, provider_id];
    }
    this.sessionsChanged.emit(this.sessions);
  }

  public allDaysClicked(payload: { provider_id: number; remove: boolean }) {
    if (payload.remove) {
      console.log(payload, this.model.session_list);
      this.model.session_list
        .filter((sessions) => sessions.provider_ids.indexOf(payload.provider_id) > -1)
        .forEach((session) => {
          const idx = this.sessions[session.session_id].indexOf(payload.provider_id);
          if (idx > -1) {
            this.sessions[session.session_id].splice(idx, 1);
          }
        });
    } else {
      this.model.session_list
        .filter((sessions) => sessions.provider_ids.indexOf(payload.provider_id) > -1)
        .forEach((session) => {
          if (this.checkOverlap(session.session_id, payload.provider_id)) {
            this.showOverlapError();
            return;
          }

          if (this.isSessionAvailable(session.session_id)) {
            const newSessions = this.sessions[session.session_id] || [];
            if (newSessions.indexOf(payload.provider_id) === -1) {
              this.sessions[session.session_id] = [...newSessions, payload.provider_id];
            }
          }
        });
    }

    this.sessionsChanged.emit(this.sessions);
  }

  public providerInterested(session: BirdsEyeSession, provider_id: number): boolean {
    return session.provider_ids.indexOf(provider_id) > -1;
  }

  private checkOverlap(session_id, provider_id) {
    const providerSessions = this.model.session_list.filter(
      (x) => this.sessions[x.session_id]?.indexOf(provider_id) > -1
    );

    if (providerSessions.length) {
      const newSession = this.model.session_list?.find((x) => x.session_id === session_id);
      const newStart = moment(`${newSession.start_date} ${newSession.start_time}`);
      const newEnd = moment(`${newSession.start_date} ${newSession.end_time}`);

      for (let i = 0; i < providerSessions.length; i++) {
        const existingStart = moment(`${providerSessions[i].start_date} ${providerSessions[i].start_time}`);
        const existingEnd = moment(`${providerSessions[i].start_date} ${providerSessions[i].end_time}`);

        if (existingStart.isSameOrBefore(newEnd) && newStart.isSameOrBefore(existingEnd)) {
          return true;
        }
      }
    }
    return false;
  }

  private showOverlapError() {
    return Swal.fire({
      title: 'Error',
      html: `<p>Please note we cannot schedule this interest due to overlapping shifts.</p>
      <div>Please adjust the schedule and resubmit.</div>`,
      icon: 'error',
      confirmButtonColor: '#cc514c',
      confirmButtonText: 'Ok',
    });
  }
}
