import module from './module';

export default module.directive('dirSupervisorSignOff', ['sessionManagerService',
  function (sessionManagerService) {
    return {
      restrict: 'E',
      scope: {
        session: '=',
        close: '&',
        recalculateBreakHeight: '&',
      },
      templateUrl: 'app/views/templates/supervisor_signoff.html',
      link: function (scope, element, attrs) {
        scope.showSpinner = true;
        scope.supervisorDetails = {};

        const payload = {
          svc_req_id: scope.session.request_id
        };

        sessionManagerService
          .getSupervisorSignOffDetails(payload)
          .then(function (response) {
            scope.showSpinner = false;
            if (response?.success) {
              scope.supervisorDetails.supervisorFirstName = response.supervisor_first_name ?? '';
              scope.supervisorDetails.supervisorLastName = response.supervisor_last_name ?? '';
              scope.supervisorDetails.image = response.image_attribute?.data ? response.image_attribute.data : '';
            }
          });

        scope.closePopup = function (event, session) {
          if (event) {
            event.stopImmediatePropagation();
            event.preventDefault();
          }
          scope.close({ session });
        };
      },
    };
  },
]);
