import angular from 'angular';
import module from '../module';
export default module.controller('EditRoleModalController', [
  '$scope',
  '$modalInstance',
  'passedRole',
  'roleManagerService',
  'AlertService',
  'permissionService',
  function ($scope, $modalInstance, passedRole, roleManagerService, AlertService, permissionService) {
    $scope.type = 'Edit Role';
    $scope.btnLabel = 'Update Role';
    $scope.submitBtnDisbaled = false;
    $scope.formData = angular.copy(passedRole);

    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      $scope.sendToServer($scope.formData);
    };

    $scope.sendToServer = function (roleDetails) {
      roleDetails.permissions = $scope.permissions
        .filter(function (permission) {
          return permission.selected;
        })
        .map(function (permission) {
          return permission.id;
        });
      roleManagerService.updateRole(roleDetails).then(
        function (response) {
          AlertService.successAlert(response.message);
          $modalInstance.close();
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.sort = function (keyname) {
      $scope.sortKey = keyname; // set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; // if true make it false and vice versa
    };

    permissionService.getAllPermissions().then(function (list) {
      const permissions = angular.copy(list);
      permissions.forEach(function (permission) {
        if (passedRole.permissions && passedRole.permissions.indexOf(permission.id) !== -1) {
          permission.selected = true;
        }
      });
      $scope.permissions = permissions;
      $scope.showSpinner = false;
      $scope.reverse = true;
      $scope.sort('name');
    });
  },
]);
