import angular from 'angular';
import module from '../module';
export default module.controller('NewRoleModalController', [
  '$scope',
  '$modalInstance',
  'AlertService',
  'enterprise_selection',
  'roleManagerService',
  'AuthenticationService',
  'permissionService',
  function (
    $scope,
    $modalInstance,
    AlertService,
    enterprise_selection,
    roleManagerService,
    AuthenticationService,
    permissionService
  ) {
    const CURRENT_USER = AuthenticationService.getCurrentUser();

    $scope.submitBtnDisbaled = false;
    $scope.showSpinner = true;
    $scope.type = 'Add New Role'; // Modal Title
    $scope.btnLabel = 'Add Role';
    $scope.sortKey = 'name';
    $scope.reverse = false;

    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      $scope.addNewRole();
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      $scope.formData = {};
    };

    $scope.initializeForm = function () {
      let passedEnt = enterprise_selection || { enterprise_id: CURRENT_USER.enterprise_info.id };

      let enterprise, marketplace;

      if (!passedEnt) {
        passedEnt = {
          enterprise_id: CURRENT_USER.enterprise_info.id,
        };
      }
      enterprise = passedEnt.enterprise_id === -1 ? null : passedEnt.enterprise_id;
      marketplace = CURRENT_USER.user_type == 2 ? CURRENT_USER.market_place_info.id : null;

      $scope.formData = {
        enterprise: enterprise,
        marketplace: marketplace,
        permissions: [],
      };
    };

    $scope.addNewRole = function () {
      $scope.formData.permissions = $scope.permissions
        .filter(function (permission) {
          return permission.selected;
        })
        .map(function (permission) {
          return permission.id;
        });
      roleManagerService.addNewRole($scope.formData).then(
        function () {
          AlertService.successAlert('New role was added.');
          $modalInstance.close();
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    };

    $scope.sort = function (keyname) {
      $scope.sortKey = keyname; // set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; // if true make it false and vice versa
    };

    permissionService.getAllPermissions().then(function (list) {
      $scope.permissions = angular.copy(list);
      $scope.showSpinner = false;
      $scope.reverse = true;
      $scope.sort('name');
    });

    $scope.initializeForm();
  },
]);
