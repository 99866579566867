import module from '../../module';
import * as enums from '../../../enums';

export default module.controller('PreviewTextMessageController', [
  '$scope',
  '$modalInstance',
  'communicationsService',
  'passedData',
  function ($scope, $modalInstance, communicationsService, passedData) {
    $scope.initialize = function () {
      $scope.template = passedData.template;
      $scope.isSaving = passedData.mode === 'save';
    };

    $scope.cancel = function () {
      $modalInstance.close();
    };

    $scope.save = function () {
      $modalInstance.close(true);
    };

    $scope.icons = {
      smsTemplate: `${enums.AssetsFolder.svg}emails/smsTemplate.svg`,
    };

    $scope.initialize();
  },
]);
