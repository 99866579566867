import * as _ from 'lodash';
import module from './module';

export default module.factory('roleManagerService', [
  '$http',
  'ADMIN_API',
  '$q',
  'Role',
  function ($http, ADMIN_API, $q, Role) {
    const rolesManager = {
      _pool: {},
      _rolesObj: {},
      _retrieveInstance: function (roleId, roleData) {
        let instance = this._pool[roleId];
        if (instance) {
          instance.setData(roleData);
        } else {
          instance = new Role(roleData);
          this._pool[roleId] = instance;
        }
      },

      _loadAllRoles: function (deferred, roleParams) {
        const scope = this;
        $http
          .get(ADMIN_API + '/roles/', roleParams)
          .then(function (res) {
            const response = res.data;
            if (response) {
              response.forEach(function (roleData) {
                scope._retrieveInstance(roleData.id, roleData);
              });
              deferred.resolve(_.values(scope._pool));
            } else {
              deferred.reject();
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },

      _newRole: function (deferred, roleParams) {
        $http
          .post(ADMIN_API + '/roles/', roleParams)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },

      _editRole: function (deferred, roleParams) {
        $http
          .put(ADMIN_API + '/roles/' + roleParams.id + '/', roleParams)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },

      _removeRole: function (deferred, roleId) {
        const scope = this;
        $http
          .delete(ADMIN_API + '/roles/' + roleId + '/')
          .then(function () {
            delete scope._pool[roleId];
            deferred.resolve();
          })
          .catch(function () {
            deferred.reject();
          });
      },

      getAllRoles: function (roleParams) {
        const deferred = $q.defer();
        this._loadAllRoles(deferred, roleParams);
        return deferred.promise;
      },

      addNewRole: function (formData) {
        const deferred = $q.defer();
        this._newRole(deferred, formData);
        return deferred.promise;
      },

      removeRole: function (roleId) {
        const deferred = $q.defer();
        this._removeRole(deferred, roleId);
        return deferred.promise;
      },

      updateRole: function (formData) {
        const deferred = $q.defer();
        this._editRole(deferred, formData);
        return deferred.promise;
      },
    };
    return rolesManager;
  },
]);
