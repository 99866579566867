import module from '../module';

export default module.controller('termsAndConditionsModalController', [
  '$scope',
  '$modalInstance',
  'AuthenticationService',
  'isTerms',
  '$sce',
  function ($scope, $modalInstance, AuthenticationService, isTerms, $sce) {
    $scope.content = '';

    AuthenticationService.getInitDataCache().then((response) => {
      $scope.initialData = response;
      initialization();
    });

    function initialization() {
      if ($scope.initialData && $scope.initialData.general_data && Array.isArray($scope.initialData.general_data)) {
        const content = $scope.initialData.general_data.find((data) => {
          if (isTerms && data.data_name === 'terms_conditions') {
            return true;
          } else if (!isTerms && data.data_name === 'privacy_policy') {
            return true;
          }
          return false;
        });
        $scope.content = $sce.trustAsHtml(content.data_content);
      }
    }

    $scope.close = function () {
      $modalInstance.close('cancel');
    };
  },
]);
