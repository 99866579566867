import angular from 'angular';
import module from '../module';
import moment from 'moment';
import * as enums from '../../enums';

export default module.controller('ChangeVerifyController', [
  '$scope',
  '$timeout',
  '$modal',
  '$localStorage',
  'AlertService',
  'sessionManagerService',
  'FormatHelperService',
  'AuthenticationService',
  'generalDataService',
  'EnterpriseFilterService',
  'ReportingSectionService',
  'TimeFormatService',
  'PERMISSIONS',
  function (
    $scope,
    $timeout,
    $modal,
    $localStorage,
    AlertService,
    sessionManagerService,
    FormatHelperService,
    AuthenticationService,
    generalDataService,
    EnterpriseFilterService,
    ReportingSectionService,
    TimeFormatService,
    PERMISSIONS
  ) {
    const CURRENT_USER = AuthenticationService.getCurrentUser();
    $scope.keySession = generalDataService.getKeySession();
    $scope.showSpinner = false;
    $scope.disableVerifySubmitBtn = true;
    $scope.currencySymbol = AuthenticationService.getCurrencySymbol();
    $scope.formData = {
      verify: false,
      filterId: 1,
      selectedRange: {},
    };
    $scope.pickerOptions = {
      format: TimeFormatService.format('dateSlashTemplate'),
    };
    $scope.filterChoices = [
      { id: 1, name: 'All' },
      { id: 2, name: 'Amount > 0' },
      { id: 3, name: 'Time Worked > 0' },
    ];

    $scope.hasUnverifyPermission = CURRENT_USER.permissionCheck(PERMISSIONS.ALLOW_UNVERIFY);
    $scope.hasAmountPermission = CURRENT_USER.permissionCheck(PERMISSIONS.SHOW_AMOUNT_VERIFY);

    $scope.hideVerifyButton = CURRENT_USER.permissionCheck(PERMISSIONS.HIDE_VERIFY_BUTTON);

    // For making initial change verify server call to get billing info to build date selection dropdown
    let unwatchEntFilter1;
    function getVerifyInitialData(ent_id) {
      $scope.verifyDateRangeObj = null;
      sessionManagerService
        .getAllVerifySessions(
          moment().format(TimeFormatService.format('api')),
          moment().add(1, 'month').format(TimeFormatService.format('api')),
          ent_id
        )
        .then(
          function (response) {
            if (response.success) {
              // build date range selection obj in reporting service
              $scope.verifyDateRangeObj = ReportingSectionService.buildVerifyChangeDateRange(response.billing_info);
            } else {
              AlertService.errorAlert(response.message);
            }
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
          }
        );
    }

    function userTypeCheck() {
      if (CURRENT_USER.user_type == 2) {
        unwatchEntFilter1 = $scope.$watch('entFilter', function () {
          if ($scope.entFilter.enterprise_id !== -1) {
            getVerifyInitialData($scope.entFilter.enterprise_id); // will possible need ent_id
          }
        });
      } else {
        $scope.entFilter = EnterpriseFilterService.fetchSelectedEnterprise();
        getVerifyInitialData($scope.entFilter.enterprise_id); // will possible need ent_id
      }
    }
    userTypeCheck();
    // Verify All check box
    $scope.updatedAllToVerified = function (field) {
      if (field) {
        angular.forEach($scope.sessions, function (session) {
          if ($scope.selectedFilter(session)) {
            session.verified = true;
          }
        });
      } else {
        $scope.sessions = angular.copy($scope.orignalSessions);
      }
    };

    // filter to only show those sessions matching filter
    $scope.selectedFilter = function (session) {
      switch ($scope.formData.filterId) {
        case 1:
          return true;
        case 2:
          if (session.total_cost > 0) {
            return true;
          } else {
            return false;
          }
        case 3:
          if (session.request_total_time > 0) {
            return true;
          } else {
            return false;
          }
      }
    };
    // reset checkbox
    $scope.resetVerifyAllBox = function () {
      $scope.formData.verify = false;
    };

    // KEy profession and attribute variables
    $scope.keyProfession = $localStorage.generalData.filter(function (obj) {
      return obj.data_name === 'key_profession';
    })[0];
    $scope.keyAttrib = $localStorage.keyAttribute;
    function getKeyAttribDisplayName() {
      sessionManagerService.getRealmAttribs(enums.Realms.Service).then(
        function (response) {
          const filteredName = response.attrib_list.filter(function (obj) {
            return obj.name === $localStorage.keyAttribute.data_content;
          });

          if (filteredName && filteredName[0]) {
            $localStorage.keyAttribute.display_name = filteredName[0].display_name;
          }

          $scope.keyAttrib = $localStorage.keyAttribute;
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
        }
      );
    }
    if (!$scope.keyAttrib.display_name) {
      getKeyAttribDisplayName();
    }

    $scope.getVerifySessions = function (dateSelection) {
      if (dateSelection.start) {
        $scope.disableVerifySubmitBtn = true;
        $scope.showSpinner = true;
        $scope.resetVerifyAllBox();
        sessionManagerService
          .getAllVerifySessions(dateSelection.start, dateSelection.end, $scope.entFilter.enterprise_id)
          .then(
            function (response) {
              if (response.success) {
                $scope.orignalSessions = angular.copy(response.request_list);
                $scope.sessions = angular.copy(response.request_list);
                if ($scope.sessions.length > 0) {
                  $scope.disableVerifySubmitBtn = false;
                  delete $scope.displayNoReportsMsg;
                } else {
                  $scope.displayNoReportsMsg = 'No reports available for selected time period';
                }
              } else {
                $scope.sessions = [];
                AlertService.errorAlert(response.message);
              }
              $scope.showSpinner = false;
            },
            function (reason) {
              AlertService.serverRequestErrorAlert(reason);
              $scope.showSpinner = false;
            }
          );
      }
    };

    // Send verify list to server
    function submitVerifyReportsToServer(verify_list) {
      $scope.disableVerifySubmitBtn = true;
      sessionManagerService.verifyRequests(verify_list).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
            $scope.getVerifySessions($scope.formData.selectedRange);
          } else {
            AlertService.errorAlert(response.message);
          }

          $timeout(function () {
            $scope.disableVerifySubmitBtn = false;
          }, 3000);
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
        }
      );
    }

    $scope.submitVerifyReports = function () {
      const verifyList = [];
      angular.forEach($scope.sessions, function (sesison) {
        const obj = {
          request_id: sesison.request_id,
          verified: sesison.verified,
        };
        verifyList.push(obj);
      });
      submitVerifyReportsToServer(verifyList);
    };

    // Change Verify  Modal
    $scope.openVerifyModal = function (session) {
      const modalInstance = $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/views/reporting/verify/changeVerifyModal.html',
        controller: 'ChangeVerifyModalController',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          selectedSession: function () {
            return session;
          },
        },
      });
      modalInstance.result.then(function (request_id) {
        if (request_id) {
          $scope.getVerifySessions($scope.formData.selectedRange);
        }
      });
    };

    // current user name display
    $scope.userNameDisplay = function () {
      const firstNameObj = CURRENT_USER.profile_info.filter(function (obj) {
        return obj.name.toLowerCase() === 'first_name';
      })[0];
      const lastNameObj = CURRENT_USER.profile_info.filter(function (obj) {
        return obj.name.toLowerCase() === 'last_name';
      })[0];
      if (firstNameObj || lastNameObj) {
        return firstNameObj.data + lastNameObj.data;
      } else {
        return 'N/A';
      }
    };

    // attribute display methods
    $scope.getDurationDisplayVal = function (value) {
      const _displayVal = FormatHelperService.returnDurationDisplayVal(value);
      return _displayVal;
    };

    $scope.getLocalTimeVal = function (value) {
      const momObj = moment.utc(value, 'HH:mm').clone();
      const _displayVal = FormatHelperService.returnLocalTimeString(momObj, 'HH:mm');
      return _displayVal;
    };

    $scope.getDateDisplay = function (value) {
      let momObj = moment(value, TimeFormatService.format('fullDate')).clone();
      if (momObj.isValid()) {
        momObj = momObj.format(TimeFormatService.format('middleDate'));
      } else {
        momObj = 'N/A';
      }
      return momObj;
    };

    $scope.getKeyAttribDisplay = function (value) {
      if (value) {
        return value.display_value;
      } else {
        return 'N/A';
      }
    };

    // market_place enterprise filter
    let unwatchEntFilter2;
    if (CURRENT_USER.user_type == 2) {
      unwatchEntFilter2 = $scope.$watch('entFilter', function () {
        $scope.sessions = null;
        if ($scope.entFilter.enterprise_id !== -1) {
          $scope.changeVerifyDisplay = true;
        } else {
          $scope.changeVerifyDisplay = false;
        }
      });
    } else {
      $scope.changeVerifyDisplay = true;
    }

    // Table Sorting
    $scope.sort = function (keyname) {
      $scope.sortKey = keyname; // set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; // if true make it false and vice versa
    };

    $scope.exportToCsv = function () {
      let result = [];
      result.push('data:text/csv;charset=utf8,Verify Report,' + $scope.formData.selectedRange.display);
      result.push(
        $scope.keySession +
          ' ID,' +
          $scope.keyProfession.data_content +
          ',' +
          'Status,' +
          $scope.keyAttrib.display_name +
          ',' +
          'Duration,' +
          'Date,' +
          'Check In,' +
          'Check Out,' +
          'Time Worked,' +
          'Amount,' +
          'Verified,'
      );
      const csvBody = $scope.sessions
        .map(function (session) {
          return {
            id: session.request_id,
            worker: session.serv_full_name,
            status: session.status.name,
            keyAttribute: $scope.getKeyAttribDisplay(session.key_attrib_info),
            duration: $scope.getDurationDisplayVal(session.estimated_duration),
            date: $scope.getDateDisplay(session.checkin_date),
            checkin: $scope.getLocalTimeVal(session.checkin_time),
            checkout: $scope.getLocalTimeVal(session.checkout_time),
            timeWorked: $scope.getDurationDisplayVal(session.request_total_time),
            amount: $scope.currencySymbol + session.total_cost,
            verified: session.verified ? 'Yes' : 'No',
          };
        })
        .map(function (session) {
          return Object.keys(session)
            .map(function (key) {
              return session[key];
            })
            .join(',');
        });
      result = result.concat(csvBody);
      const csv = result.join('\n');
      $scope.generateDownload(csv);
    };

    $scope.generateDownload = function (dataUrl) {
      const hiddenElement = document.createElement('a');
      hiddenElement.href = encodeURI(dataUrl);
      hiddenElement.download = 'verify_report.csv';
      hiddenElement.click();
    };

    $scope.$on('$destroy', function () {
      if (typeof unwatchEntFilter1 === 'function') {
        unwatchEntFilter1();
      }
      if (typeof unwatchEntFilter2 === 'function') {
        unwatchEntFilter2();
      }
    });
  },
]);
