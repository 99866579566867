import { Component, OnInit, OnDestroy } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { app } from '../app/scripts/app';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { AppStateService } from './shared/services';
@Component({
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  hideFooter = false;
  routerSubscription: Subscription | undefined;
  loginBackground$: Observable<string>;
  showBackground = false;

  constructor(
    private upgrade: UpgradeModule,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly appStateService: AppStateService
  ) {}

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.routerSubscription = undefined;
  }

  ngOnInit(): void {
    this.upgrade.bootstrap(document.documentElement, [app.name]);
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.hideFooter = event.url.includes('info');
          this.showBackground = this.route.root.firstChild.snapshot.data['showBg'];
        }
      });
    this.loginBackground$ = this.appStateService.initData$.pipe(
      filter((x) => !!x),
      take(1),
      map((appState) => {
        if (this.showBackground && appState.enterprise_list[0].login_page_background) {
          return 'url(' + appState.domain + appState.enterprise_list[0].login_page_background + ')';
        } else {
          return '';
        }
      })
    );
  }
}
