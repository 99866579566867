import module from '../module';

export default module.directive('workersDirective', [
  function () {
    return {
      restrict: 'E',
      templateUrl: 'app/views/admin/provider/providerTab.html',
      controller: 'ProviderTabController',
    };
  },
]);
