import * as _ from 'lodash';

import module from './module';
export default module
  .controller('dirPickListController', [
    '$scope',
    '$modal',
    '$localStorage',
    'sessionManagerService',
    'poolManagerService',
    'providerManagerService',
    'AuthenticationService',
    'PERMISSIONS',
    'AlertService',
    'EnterpriseFilterService',
    'providerExportService',
    function (
      $scope,
      $modal,
      $localStorage,
      sessionManagerService,
      poolManagerService,
      providerManagerService,
      AuthenticationService,
      PERMISSIONS,
      AlertService,
      EnterpriseFilterService,
      providerExportService
    ) {
      $scope.usedForm = $scope.serviceRequestForm || $scope.scheduleJobForm;
      var CURRENT_USER = AuthenticationService.getCurrentUser();
      $scope.assignQualifiedPermission = CURRENT_USER.permissionCheck(PERMISSIONS.ASSIGN_QUALIFIED_WORKER);

      $scope.keyProfession = $localStorage.generalData.filter(function (obj) {
        return obj.data_name == 'key_profession';
      })[0];
      $scope.keyAttrib = $localStorage.keyAttribute;
      $scope.isScheduleJob = $scope.title === 'Add Job' || $scope.title === 'Schedule Job';
      $scope.ratingSearch = _.range(0, 4.5, 0.5).map(function (item) {
        return { label: '>= ' + item, value: item };
      });
      $scope.lastAddedOrder = 0;
      $scope.lastRemovedOrder = 0;
      $scope.sortKey = 'last_name';
      $scope.reverse = false;

      $scope.selectionText = 'Selected ' + $scope.keyProfession.data_content + 's';

      $scope.$watch('jobType.selection', function (val) {
        switch (val) {
          case 'escalation':
            $scope.pickListLabel = 'Offer Pick List';
            break;
          case 'assign':
            $scope.pickListLabel = 'Schedule Pick List';
            break;
          default:
            $scope.pickListLabel = 'Interest Pick List';
            break;
        }
      });

      $scope.showJob = function (orig) {
        if (!$scope.formData.bundled) {
          $scope.cancelPickList();
        }

        if (orig === 'interest' && $scope.applyAttrib && $scope.applyAttrib.value) {
          $scope.pickListAvailable = true;
          $scope.pickListLabel = 'Interest Pick List';
          $scope.jobType.pickListRequested = false;
          $scope.jobType.bundledAssign = false;

          if ($scope.qualifiedWorkers && $scope.qualifiedWorkers.filter((x) => x.selected).length) {
            AlertService.warningAlert('Interest and Schedule may not be combined - your Pick List has been cleared.');
          }

          // delete $scope.qualifiedWorkers;
          // delete $scope.pickList;
          $scope.jobType.selection = '';
          $scope.pickListValid = false;
          $scope.scheduleWorkers = 0;
          $scope.offerWorkers = $scope.formData.worker_count;

          if ($scope.calculateBundlePercents) {
            $scope.calculateBundlePercents();
          }
        } else if ($scope.jobType.selection === 'escalation') {
          $scope.pickListAvailable = true;
          $scope.pickListLabel = 'Offer Pick List';
          $scope.applyAttrib.value = false;
        } else if ($scope.jobType.selection === 'assign') {
          $scope.pickListAvailable = $scope.assignQualifiedPermission;
          $scope.pickListLabel = 'Schedule Pick List';
          $scope.applyAttrib.value = false;
        } else {
          $scope.pickListAvailable = false;
          $scope.cancelPickList();
        }
      };

      $scope.$watch('usedForm.$dirty', function (value) {
        if (value && $scope.jobType.pickListRequested) {
          $scope.jobType.pickListRequested = false;
          $scope.showPickList(true);
          $scope.jobType.bundledAssign = false;
          $scope.jobType.bundledPickList = false;
        }
      });

      $scope.getEnterpriseData = function () {
        var CURRENT_USER = AuthenticationService.getCurrentUser(),
          enterprise_id;

        if (CURRENT_USER.user_type == 3) {
          enterprise_id = CURRENT_USER.enterprise_info.id;
        } else if (CURRENT_USER.user_type == 2 && $scope.entFilter.enterprise_id != -1) {
          enterprise_id = $scope.entFilter.enterprise_id;
        } else {
          enterprise_id = EnterpriseFilterService.fetchSelectedEnterprise().enterprise_id;
        }

        return {
          market_place_id: CURRENT_USER.market_place_info.id,
          enterprise_id: enterprise_id,
        };
      };

      $scope.showPickList = function (skipPrelim) {
        if ($scope.jobType.pickListRequested) {
          $scope.usedForm.$setPristine();
          var payload = $scope.preparePickListPayload();
          var enterpriseData = $scope.getEnterpriseData();
          payload.market_place_id = enterpriseData.market_place_id;
          payload.enterprise_id = enterpriseData.enterprise_id;

          if ($scope.jobType.selection === 'assign') {
            if ($scope.staffProviderAttrib) {
              //Remove selection from staff providers, working for single select component
              $scope.staffProviderAttrib.value = [];
              //Remove selection for multi select component
              if ($scope.staffProviderAttrib.choice_list) {
                $scope.staffProviderAttrib.isDisabled = true;
                $scope.staffProviderAttrib.choice_list.forEach(function (item) {
                  item.selected = false;
                });
              }
            }

            //On the Schedule Job dialog multiple workers can be selected
            //The server side attribute is hacked to allow this, so we need
            //to check on the client
            if ($scope.isScheduleJob) {
              //Indicate that multiple workers can be selected
              $scope.selectionText = 'Selected ' + $scope.keyProfession.data_content + 's';
            } else {
              //Indicate that only a single worker can be selected
              $scope.selectionText = 'Select a(n) ' + $scope.keyProfession.data_content;
            }
          }

          if (!skipPrelim) {
            let prelimCall = $scope.isScheduleJob
              ? sessionManagerService.prelimJob
              : sessionManagerService.prelimRequest;

            if (payload.req_list) {
              prelimCall = sessionManagerService.prelimAdhocJob;
            } else {
              // always use bundled payload for non-adhoc pick lists
              if (!$scope.formData.bundled && $scope.jobType.selection !== 'assign') {
                payload.bundled = false;
              } else {
                payload.bundled = true;
              }
            }

            $scope.showPickListSpinner = true;

            prelimCall.call(sessionManagerService, payload).then(function (response) {
              $scope.pickListValid =
                $scope.request && $scope.request.provider_pick_list && $scope.request.provider_pick_list.length;

              $scope.pickList = response.map(function (provider) {
                if ($scope.request && $scope.request.provider_pick_list) {
                  provider.selected = $scope.request.provider_pick_list.indexOf(provider.provider_id) !== -1;
                } else {
                  provider.selected = false;
                }

                provider.addedOrder = 0;
                provider.removedOrder = 0;

                return provider;
              });
              $scope.qualifiedWorkers = angular.copy($scope.pickList);
              $scope.showPickListSpinner = false;
            });
          }

          if (!$scope.pools) {
            poolManagerService
              .getAllPools({ enterprise_id: $scope.getEnterpriseData().enterprise_id })
              .then(function (pools) {
                $scope.pools = pools;
                $scope.selectDefaultPool();
              });
          }
        } else {
          $scope.cancelPickList();
        }
      };

      $scope.getStatus = function (provider, name) {
        var status = provider.profile_info && provider.profile_info.find((x) => x.name === name);
        if (status) {
          var value = status.choice_list.find((x) => x.selected);
          if (value) {
            return {
              code: value.choice_id,
              name: value.name,
            };
          }
        }
      };

      $scope.selectDefaultPool = () => {
        if ($scope.pools && Array.isArray($scope.pools)) {
          const selectedPool = $scope.pools.find((pool) => {
            return pool.pool_id === -1;
          });

          if (selectedPool) {
            $scope.selectedPool = selectedPool.pool_id;
            $scope.poolSelected(selectedPool.pool_id);
          }
        }
      };

      $scope.poolSelected = function (poolId) {
        if (poolId !== -1) {
          $scope.showPickListSpinner = true;
          poolManagerService.getPool(poolId).then(function (pool) {
            $scope.poolProviders = pool.provider_list.map(function (provider) {
              return provider.provider_id;
            });
            $scope.showPickListSpinner = false;
          });
        } else {
          $scope.showPickListSpinner = false;
          $scope.poolProviders = null;
        }
      };

      $scope.pickListChange = function (provider) {
        if (provider.selected) {
          $scope.lastAddedOrder++;
          provider.removedOrder = 0;
          provider.addedOrder = $scope.lastAddedOrder;
        } else {
          $scope.lastRemovedOrder++;
          provider.addedOrder = 0;
          provider.removedOrder = $scope.lastRemovedOrder;
        }
        $scope.pickListValid = $scope.pickList.filter(function (provider) {
          return provider.selected;
        }).length;
        $scope.$broadcast('PickList.PickListChange');
      };

      $scope.qualifiedWorkersChange = function (provider) {
        if (!$scope.isScheduleJob) {
          $scope.qualifiedWorkers.forEach(function (otherProv) {
            if (provider !== otherProv) {
              otherProv.selected = false;
            }
          });
        }
        $scope.pickListValid = $scope.qualifiedWorkers.filter(function (provider) {
          return provider.selected;
        }).length;
        $scope.$broadcast('PickList.QualifiedChange');
      };

      $scope.cancelPickList = function () {
        if (!$scope.formData.bundled) {
          delete $scope.qualifiedWorkers;
          delete $scope.pickList;
          $scope.jobType.pickListRequested = false;
          $scope.pickListValid = false;
          $scope.poolProviders = null;
        } else {
          delete $scope.qualifiedWorkers;
          delete $scope.pickList;
          $scope.jobType.pickListRequested = false;
        }
      };

      $scope.sort = function (keyname) {
        $scope.sortKey = keyname; //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
      };

      $scope.selectAll = function () {
        $scope.pickList.forEach(function (provider) {
          if ($scope.poolProviders) {
            if ($scope.poolProviders.indexOf(provider.provider_id) === -1) {
              return;
            }
          }
          provider.selected = true;
          $scope.lastAddedOrder++;
          provider.removedOrder = 0;
          provider.addedOrder = $scope.lastAddedOrder;
        });
        $scope.pickListValid = true;
        $scope.$broadcast('PickList.PickListChange');
      };

      $scope.deselectAll = function () {
        $scope.pickList.forEach(function (provider) {
          provider.selected = false;
          $scope.lastRemovedOrder++;
          provider.addedOrder = 0;
          provider.removedOrder = $scope.lastRemovedOrder;
        });
        $scope.pickListValid = false;
        $scope.$broadcast('PickList.PickListChange');
      };

      $scope.showProviderModal = function (userId) {
        providerManagerService.getProvider(userId).then(function (provider) {
          var modalInstance = $modal.open({
            animation: true,
            templateUrl: 'app/views/job/providerModal.html',
            controller: 'ProviderModalController',
            size: 'lg',
            backdrop: 'static',
            resolve: {
              provider: function () {
                provider.hidePermissions = true;
                return provider;
              },
            },
          });
          modalInstance.result.then(function () {});
        });
      };

      $scope.validatePickList = function () {
        var count = $scope.pickList.filter(function (provider) {
          return provider.selected;
        }).length;

        if (count === 1 && $scope.jobType.selection === 'escalation') {
          return {
            message:
              'You have selected only 1 potential worker. If this person is unavailable or cancels after accepting, the work may go unfilled.',
          };
        }
      };

      $scope.export = (type) => {
        let providers = [];

        if ($scope.jobType.selection == 'assign') {
          switch (type) {
            case 'select':
              providers = $scope.qualifiedWorkers;
              break;
            case 'deselect':
              providers = $scope.qualifiedWorkers.filter((worker) => worker.selected);
              break;
          }
        } else {
          switch (type) {
            case 'select':
              providers = $scope.pickList;
              break;
            case 'deselect':
              providers = $scope.qualifiedWorkers.filter((worker) => worker.selected);
              break;
          }
        }

        providerExportService.generateDownload(providers);
      };

      $scope.$on('LocationsLoaded', function () {
        if ($scope.request && $scope.request.provider_pick_list) {
          $scope.pickListValid = true;
          $scope.jobType.selection = 'escalation';
          $scope.showJob();
          $scope.jobType.pickListRequested = true;
          $scope.showPickList();
        }
      });
    },
  ])
  .directive('dirPickList', [
    function () {
      return {
        restrict: 'E',
        scope: '=',
        controller: 'dirPickListController',
        link: function (scope, element, attr) {},
        templateUrl: 'app/views/session/pickList.html',
      };
    },
  ]);
