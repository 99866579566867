import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class TimeService {
  getRange(id) {
    let start, end;
    switch (parseInt(id)) {
      case 1:
        start = moment().startOf('month');
        end = moment().endOf('day');
        break;
      case 2:
        start = moment().subtract(1, 'months');
        end = moment().endOf('day');
        break;
      case 3:
        start = moment().startOf('week');
        end = moment().endOf('day');
        break;
      case 4:
        start = moment()
          .subtract(1, 'weeks')
          .startOf('week');
        end = moment()
          .subtract(1, 'weeks')
          .endOf('week');
        break;
      case 5:
        start = moment().startOf('day');
        end = moment().endOf('day');
        break;
      case 6:
        start = moment().endOf('day');
        end = moment()
          .add(1, 'years')
          .endOf('day');
        break;
      case 7:
        start = moment()
          .subtract(1, 'months')
          .startOf('month');
        end = moment()
          .subtract(1, 'months')
          .endOf('month');
        break;
    }
    return { start: start, end: end };
  }
}
