import module from './module';
import * as enums from '../enums';

export default module.directive('dirApiLogFilter', [
  function () {
    return {
      restrict: 'E',
      templateUrl: 'app/views/templates/api_logs_filter.html',
      controller: [
        '$scope',
        '$localStorage',
        'FormatHelperService',
        ($scope, $localStorage, FormatHelperService) => {
          $scope.pickFilterName = 'Pick Filter';
          $scope.filterList = [];

          $scope.prepareFilters = function () {
            $scope.filterList.push({
              attrib_id: 1,
              isSystem: true,
              systemType: 1,
              display_name: 'Username',
              type: enums.FilterableTypes.text,
              widget_type: 3,
              type_flags: {
                display: true,
                type: 0,
              },
            });
            $scope.filterList.push({
              attrib_id: 2,
              isSystem: true,
              systemType: 2,
              display_name: 'Date from / to',
              type: enums.FilterableTypes.date_range,
              widget_type: 3,
              type_flags: {
                display: true,
                type: 0,
              },
            });
            $scope.filterList.push({
              attrib_id: 3,
              isSystem: true,
              systemType: 3,
              display_name: 'Endpoint',
              type: enums.FilterableTypes.text,
              widget_type: 3,
              type_flags: {
                display: true,
                type: 0,
              },
            });
            $scope.filterList.push({
              attrib_id: 4,
              isSystem: true,
              systemType: 4,
              display_name: 'Status',
              type: enums.FilterableTypes.single_list,
              widget_type: 3,
              type_flags: {
                display: true,
                type: 0,
              },
              choice_list: [
                { choice_id: 1, selected: false, name: 'Success' },
                { choice_id: 2, selected: false, name: 'Failed' },
                { choice_id: 3, selected: false, name: 'Ignored' },
              ],
            });
            $scope.filterList.push({
              attrib_id: 5,
              isSystem: true,
              systemType: 3,
              display_name: 'ATS ID',
              type: enums.FilterableTypes.text,
              widget_type: 3,
              type_flags: {
                display: true,
                type: 0,
              },
            });

            if ($localStorage.apilogsFilter) {
              $scope.filters = JSON.parse($localStorage.apilogsFilter);
            } else {
              $scope.filters = [];
            }
            if ($scope.filters.length) {
              $scope.pickFilterName = 'Pick Another Filter';
            }
            $scope.setFilters();
          };

          $scope.addFilter = function (attrib_id) {
            if (attrib_id && attrib_id !== 'none') {
              const filter = $scope.filterList.find(function (item) {
                return item.attrib_id == attrib_id;
              });
              if (
                !$scope.filters.find(function (item) {
                  return item.attrib_id == attrib_id;
                })
              ) {
                $scope.filters.push(filter);
              }
              $scope.selectedFilter = 'none';
              $scope.pickFilterName = 'Pick Another Filter';
            }
          };

          $scope.removeFilter = function (index) {
            $scope.filters.splice(index, 1);
            if (!$scope.filters.length) {
              $scope.pickFilterName = 'Pick Filter';
            }
            $scope.setFilters();
          };

          $scope.setFilters = function () {
            $scope.filters.forEach(function (filter) {
              if (filter.type === enums.FilterableTypes.single_list) {
                if (filter.value && filter.value.choice_id) {
                  filter.choice_list.forEach(function (choice) {
                    if (choice.choice_id === filter.value.choice_id) {
                      choice.selected = true;
                    } else {
                      choice.selected = false;
                    }
                  });
                }
              }
            });

            $localStorage.apilogsFilter = JSON.stringify($scope.filters);
            let attribFilters = FormatHelperService.format_data($scope.filters);

            attribFilters = attribFilters.filter(function (item) {
              return item.attrib_id > 0;
            });

            $scope.applyFilters(attribFilters);
          };

          $scope.keyPressed = function (e) {
            // User pressed enter, submit the filters
            if (e.keyCode === 13) {
              e.preventDefault();
              $scope.setFilters();
            }
          };

          $scope.prepareFilters();
        },
      ],
    };
  },
]);
