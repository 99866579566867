import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';

@Component({
  templateUrl: './google-map.component.html',
  selector: 'app-google-map',
})
export class GoogleMapComponent implements OnInit {
  @ViewChild(GoogleMap) googleMap;

  @Input()
  public options: google.maps.MapOptions = {};

  @Input()
  public markers: google.maps.MarkerOptions[];

  @Output()
  public selected = new EventEmitter<google.maps.MarkerOptions>();

  public markerClustererImagePath =
    'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    // Expose the forceFit function on the element so it can be called as a 'web component'.
    this.element.nativeElement.forceFit = () => this.doForceFit();
  }

  public markerClicked(event: google.maps.MapMouseEvent): void {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    const target = this.markers.find((x) => x.position.lat === lat && x.position.lng === lng);
    if (target) {
      this.selected.emit(target);
    }
  }

  private doForceFit(): void {
    if (this.googleMap) {
      const bounds = new google.maps.LatLngBounds();
      this.markers.forEach((marker) => bounds.extend(marker.position));

      this.googleMap.fitBounds(bounds, 2);
    }
  }
}
