import module from '../module';
import Swal from 'sweetalert2';

export default module.controller('MeetingPlaceTabController', [
  '$scope',
  '$rootScope',
  '$modal',
  'AlertService',
  'locationManagerService',
  'AuthenticationService',
  'trackingService',
  'EnterpriseFilterService',
  function (
    $scope,
    $rootScope,
    $modal,
    AlertService,
    locationManagerService,
    AuthenticationService,
    trackingService,
    EnterpriseFilterService
  ) {
    let unbindHandler = null;
    const CURRENT_USER = AuthenticationService.getCurrentUser();

    $scope.showSpinner = true;
    $scope.animationsEnabled = true; // for modals
    $scope.data = {};
    $scope.locationBtnStatus = true;
    $scope.entFilter = EnterpriseFilterService.fetchSelectedEnterprise();

    // Permission Check
    $scope.addPlacePermission = CURRENT_USER.permissionCheck(31);
    $scope.editPlacePermission = CURRENT_USER.permissionCheck(32);
    $scope.deletePlacePermission = CURRENT_USER.permissionCheck(33);

    // Sorting list
    function alphabetical(a, b) {
      const A = a.name.toLowerCase();
      const B = b.name.toLowerCase();
      if (A < B) {
        return -1;
      } else if (A > B) {
        return 1;
      } else {
        return 0;
      }
    }

    // Grabbing locations from API
    const getLocations = function (message) {
      locationManagerService.getAllLocations($scope.entFilter.enterprise_id).then(
        function (locationsObj) {
          if (locationsObj[$scope.entFilter.enterprise_id]) {
            $scope.locations = locationsObj[$scope.entFilter.enterprise_id];
            $scope.locations.sort(alphabetical);
            $scope.locationBtnStatus = true;
            if (message === 'newLoc') {
              const index = $scope.locations.length - 1;
              $scope.data.selectedLocation = $scope.locations[index];
            } else {
              $scope.data.selectedLocation = $scope.locations[0];
            }
          } else {
            $scope.locations = null;
            $scope.selectedLocation = null;
            $scope.data = {};
          }
          $scope.showSpinner = false;
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $scope.showSpinner = false;
        }
      );
    };

    // market_place enterprise filter
    if (CURRENT_USER.user_type == 2 || CURRENT_USER.user_type == 6) {
      $scope.$watch('entFilter', function () {
        getLocations();
        if ($scope.entFilter.enterprise_id == -1) {
          $scope.locationBtnStatus = false;
        } else {
          $scope.locationBtnStatus = true;
        }
      });
    } else {
      getLocations();
    }

    // Table Sorting
    $scope.sort = function (keyname) {
      $scope.sortKey = keyname; // set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; // if true make it false and vice versa
    };

    // Delete location
    $scope.deleteLocation = async function (location) {
      const config = {
        title: 'Are you sure?',
        text: 'You are about to delete ' + location.name,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Yes, delete Location',
      };

      const returnHandler = function (isConfirm) {
        if (!isConfirm) {
          return;
        } // if confirm

        location.deleteLocation().then(
          async function (response) {
            if (response.success) {
              locationManagerService.removeDeletedLocation(location).then(function (locationsObj) {
                $scope.locations = locationsObj[$scope.entFilter.enterprise_id];
                $scope.data.selectedLocation = $scope.locations[0];
              });

              const configPositive = {
                title: 'Success',
                text: response.message,
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configPositive);
            } else {
              const configError = {
                title: 'Error',
                text: response.message,
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configError);
            }
          },
          async function () {
            const configError = {
              title: 'Error',
              text: 'Error contacting the server',
              icon: 'error',
              timer: 2000,
              showConfirmButton: false,
            };
            await Swal.fire(configError);
          }
        );
      };

      const { value: returnValue } = await Swal.fire(config);
      returnHandler(returnValue);

      trackingService.trackEvent(trackingService.ADMIN, 'locations', 'delete');
    };

    // Add New location Modal
    $scope.openAddLocationModal = function () {
      const modalInstance = $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/views/admin/meetingPlace/locationModal.html',
        controller: 'NewLocationModalController',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          enterprise_selection: function () {
            return $scope.entFilter;
          },
        },
      });

      modalInstance.result.then(function () {
        getLocations('newLoc');
      });

      trackingService.trackEvent(trackingService.ADMIN, 'locations', 'new');
    };

    // Edit current location Modal
    $scope.editLocationModal = function (location) {
      const modalInstance = $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/views/admin/meetingPlace/locationModal.html',
        controller: 'EditLocationModalController',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          passedLocation: function () {
            return location;
          },
        },
      });

      modalInstance.result.then(function () {
        locationManagerService.getLocation(location.location_id).then(
          function (returnedLoc) {
            $scope.data.selectedLocation = returnedLoc;
          },
          function () {
            getLocations();
          }
        );
      });
      trackingService.trackEvent(trackingService.ADMIN, 'locations', 'edit');
    };

    // Add New Meeting place to location Modal
    $scope.openAddMeetingPlaceModal = function () {
      const modalInstance = $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/views/admin/meetingPlace/meetingPlaceModal.html',
        controller: 'NewMeetingPlaceModalController',
        backdrop: 'static',
        resolve: {
          locationsList: function () {
            return $scope.locations;
          },
        },
      });

      modalInstance.result.then(function (location) {
        locationManagerService.getLocation(location.location_id).then(
          function (returnedLoc) {
            $scope.data.selectedLocation = returnedLoc;
          },
          function () {
            getLocations();
          }
        );
      });

      trackingService.trackEvent(trackingService.ADMIN, 'meetingPlace', 'new');
    };

    // Edit Place Modal
    $scope.editMeetingPlaceModal = function (place, location) {
      const modalInstance = $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/views/admin/meetingPlace/meetingPlaceModal.html',
        controller: 'EditMeetingPlaceModalController',
        backdrop: 'static',
        resolve: {
          passedPlace: function () {
            return place;
          },
          passedLocation: function () {
            return location;
          },
        },
      });

      modalInstance.result.then(function (location) {
        locationManagerService.getLocation(location.location_id).then(
          function (returnedLoc) {
            $scope.data.selectedLocation = returnedLoc;
          },
          function () {
            getLocations();
          }
        );
      });

      trackingService.trackEvent(trackingService.ADMIN, 'meetingPlace', 'edit');
    };

    // Delete Meeting Place
    $scope.deleteMeetingPlace = async function (place) {
      const config = {
        title: 'Are you sure?',
        text: 'You are about to delete ' + place.name,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Yes, delete Location',
      };

      const returnHandler = function (isConfirm) {
        if (!isConfirm) {
          return;
        } // if confirm

        $scope.data.selectedLocation.deletePlace(place.place_id).then(
          async function (response) {
            if (response.success) {
              const configPositive = {
                title: 'Deleted!',
                text: response.message,
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configPositive);
            } else {
              const configError = {
                title: 'Error',
                text: response.message,
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configError);
            }
          },
          async function () {
            const configError = {
              title: 'Error',
              text: 'Error contacting the server',
              icon: 'error',
              timer: 2000,
              showConfirmButton: false,
            };
            await Swal.fire(configError);
          }
        );
      };

      const { value: returnValue } = await Swal.fire(config);
      returnHandler(returnValue);

      trackingService.trackEvent(trackingService.ADMIN, 'meetingPlace', 'delete');
    }; // end of delete method

    unbindHandler = $rootScope.$on('EnterpriseSelectionChanged', function (data) {
      $scope.entFilter = EnterpriseFilterService.fetchSelectedEnterprise();
      getLocations();
    });

    $scope.$on('$destroy', function () {
      unbindHandler();
    });
  },
]);
