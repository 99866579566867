import angular from 'angular';
import module from '../module';
import moment from 'moment';

export default module.controller('ChangeSessionAssignmentModalController', [
  '$scope',
  '$controller',
  '$modalInstance',
  'FormatHelperService',
  'AlertService',
  'sessionManagerService',
  'enterprise_selection',
  'generalDataService',
  'passedSession',
  'locationManagerService',
  'AuthenticationService',
  'PERMISSIONS',
  'DOMAIN',
  'TimeFormatService',
  function (
    $scope,
    $controller,
    $modalInstance,
    FormatHelperService,
    AlertService,
    sessionManagerService,
    enterprise_selection,
    generalDataService,
    passedSession,
    locationManagerService,
    AuthenticationService,
    PERMISSIONS,
    DOMAIN,
    TimeFormatService
  ) {
    $controller('BaseJobModalController', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      FormatHelperService: FormatHelperService,
      AlertService: AlertService,
      sessionManagerService: sessionManagerService,
      enterprise_selection: enterprise_selection,
    });

    $scope.keySession = generalDataService.getKeySession();
    $scope.title = `Change ' ${$scope.keySession}`;
    $scope.requestType = 1;
    $scope.type = 'new';

    $scope.checkForErrors = function (formData) {
      const result = [];
      // Check if session starts in the past
      let date;
      const dateAttrib = formData.find(function (attrib) {
        return attrib.name === 'date_time';
      });
      if ($scope.jobType.selection == 'escalation' && dateAttrib) {
        date = moment(dateAttrib.value);
        if (date.isBefore(moment())) {
          result.push('The time you entered is for a shift starting in the past. Please adjust to start in the future');
        }
      }

      return result;
    };

    async function populateAttribs(source, target) {
      switch (source.type) {
        case 'single_list':
          target.value = source.value_list.filter(function (obj) {
            return obj.selected == true;
          })[0];
          break;
        case 'multi_list':
          target.value = source.value_list;
          target.choice_list = source.value_list;
          break;
        case 'text':
          target.value = source.value_text;
          break;
        case 'count':
          target.value = source.value_count;
          break;
        case 'location':
          // TODO continue location fix here
          // console.log(source.value_location);
          if (source.value_location) {
            locationManagerService
              .getAllLocations(enterprise_selection && enterprise_selection.enterprise_id)
              .then(async function (locationsObject) {
                const locations = locationsObject[enterprise_selection.enterprise_id];
                const loc = locations.find(function (location) {
                  return location.location_id === source.value_location.location_id;
                });
                target.value = loc;
                $scope.selectedLocation = loc;
                await $scope.handleFormDate();
              });
          } else {
            await $scope.handleFormDate();
          }
          break;

        case 'place':
          $scope.$watch('selectedLocation', function (val) {
            if (val) {
              const selected = source.value_place.filter(function (obj) {
                return obj.selected == true;
              })[0];
              const selectedPlace = $scope.selectedLocation.place_list.find(function (place) {
                return place.place_id === selected.place_id;
              });
              target.value = selectedPlace;
            }
          });
          break;
        case 'image':
          var path = DOMAIN + source.value_text;
          var request = new XMLHttpRequest();
          request.responseType = 'blob';
          request.open('GET', path);
          request.onload = function () {
            const blob = request.response;
            const fileReader = new FileReader();
            fileReader.onload = function (e) {
              target.value = e.target.result;
            };
            fileReader.readAsDataURL(blob);
          };
          request.send();

          break;
      }
    }

    $scope.attribsLoadedCallback = function (attribs) {
      if (passedSession && passedSession.start) {
        sessionManagerService.setSession(passedSession.session_id).then(function (session) {
          $scope.unchangedFormData = FormatHelperService.format_data_back(angular.copy(session.attrib_list));
          $scope.session = session;
          attribs.forEach(function (origAttrib) {
            // TODO continue location fix from here
            // console.log(origAttrib)
            const loadedAttrib = session.attrib_list.find(function (attrib) {
              return attrib.attrib_id === origAttrib.attrib_id;
            });
            if (loadedAttrib) {
              try {
                populateAttribs(loadedAttrib, origAttrib);
              } catch (e) {}
            }
          });
        });

        const dateAttrib = attribs.find(function (attrib) {
          return attrib.attrib_id === 4; // 4 is date and time
        });
        $scope.dateAttrib = dateAttrib;
      }
    };

    $scope.handleFormDate = async function () {
      if ($scope.dateAttrib) {
        const adjustedTime = await TimeFormatService.formatTimezone(
          'fullDateTimeSecs',
          passedSession.start,
          passedSession.start,
          $scope.selectedLocation
        );

        setTimeout(() => {
          $scope.dateAttrib.value_text = moment(adjustedTime, TimeFormatService.format('fullDateTimeSecs'));
          $scope.dateAttrib.value = moment(adjustedTime, TimeFormatService.format('fullDateTimeSecs'));
        });
      }
    };

    $scope.ok = function () {
      const attribList = angular.copy($scope.formData);
      const newList = FormatHelperService.format_data(attribList)
        .filter((attr) => {
          if (!$scope.unchangedFormData) {
            return false;
          }
          const matchingPair = $scope.unchangedFormData.find((x) => x.attrib_id === attr.attrib_id);
          if (!matchingPair) {
            return true;
          }
          // See if the attrib value is changed.
          // Only add those attribs with values changed.
          const fields = Object.keys(attr);
          for (let i = 0; i < fields.length; i++) {
            const key = fields[i];
            if (attr[key] != matchingPair[key]) {
              return true;
            }
          }
        })
        .map(function (attrib) {
          const newAttrib = angular.copy(attrib);
          newAttrib.action = 1;
          return newAttrib;
        });

      const payload = {
        session_id: $scope.session.request_id,
        attrib_list: newList,
      };

      sessionManagerService.changeSessionAssignment(payload).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
          } else {
            AlertService.errorAlert(response.message);
          }
          $scope.formData = {};
          $modalInstance.close();
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $scope.formData = {};
          $modalInstance.close();
        }
      );
    };
  },
]);
