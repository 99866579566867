import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormField } from '../../models/worker';

@Component({
  templateUrl: './key-attribute-editor.component.html',
  styleUrls: ['./key-attribute-editor.component.scss'],
  selector: 'app-key-attribute-editor',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KeyAttributeEditorComponent),
      multi: true,
    },
  ],
})
export class KeyAttributeEditorComponent implements OnInit, ControlValueAccessor {
  @Input() keyAttribute: FormField;

  public selectedValue = null;

  ngOnInit() {}

  selectionChange(e) {
    this.propagateChange(this.selectedValue);
  }

  writeValue(value: any) {
    this.selectedValue = value;
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}
}
