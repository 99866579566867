import module from '../module';
import Swal from 'sweetalert2';

export default module.controller('SessionNotificationsController', [
  'sessionManagerService',
  '$scope',
  '$rootScope',
  '$modal',
  'AlertService',
  '$location',
  '$localStorage',
  'FormatHelperService',
  'AuthenticationService',
  'pleaseReviewService',
  'BrowserNotificationService',
  'EnumsService',
  'UserAgentService',
  'TimeFormatService',
  function (
    sessionManagerService,
    $scope,
    $rootScope,
    $modal,
    AlertService,
    $location,
    $localStorage,
    FormatHelperService,
    AuthenticationService,
    pleaseReviewService,
    BrowserNotificationService,
    EnumsService,
    UserAgentService,
    TimeFormatService
  ) {
    $scope.openJobRequests = [];
    const CURRENT_USER = AuthenticationService.getCurrentUser();
    const keyAttrib = $localStorage.keyAttribute;
    /// /////////////////////////////////////////////////////////////////////////
    // Permission Check
    $scope.notificationPermission = CURRENT_USER.permissionCheck(1);
    $scope.approvalPermission = CURRENT_USER.permissionCheck(2);
    /// ///////////////////////////////////////////////////////////////////////////
    // Toggle Job Div Show
    $scope.viewRequest = function (sessionId) {
      if (sessionId && !$scope.showSpinner) {
        sessionManagerService.setSession(sessionId).then(
          function (response) {
            if (response.success) {
              location.href = '/#/job';
            } else {
              AlertService.errorAlert(response);
            }
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
          }
        );
      }
    };

    pleaseReviewService.getUnratedServices().then(function (result) {
      $scope.unratedList = result.slice(0, 100);
    });

    $scope.$on('NOTIFICATION_UPDATE', (e, msg) => {
      if (msg.action === 'PRO_COMPLETE_REQUEST') {
        pleaseReviewService.getUnratedServices().then(function (result) {
          $scope.unratedList = result.slice(0, 100);
        });
      }
    });

    /// /////////////////////////////////////////////////////////////////////////
    function getRequestTimeDisplayVal(utcString) {
      const _momentObj = moment.utc(utcString, TimeFormatService.format('fullDateTime')).clone();
      const _val = FormatHelperService.returnLocalTimeString(_momentObj, TimeFormatService.format('dateTimeLong'));
      return _val;
    }
    // filtering for waiting for approval section
    $scope.approvalFilter = function (request) {
      const sessionKeyAttribObj = request.attrib_list.find(function (obj) {
        return obj.name == keyAttrib.data_content;
      });
      const _startTime = getRequestTimeDisplayVal(request.start_date_time);
      return (
        'Pending Open ' +
        (sessionKeyAttribObj ? sessionKeyAttribObj.display_value : '') +
        ' request for ' +
        _startTime +
        ' from ' +
        request.req_full_name
      );
    };
    $scope.waitingForApproval = function (request) {
      if (request.status.type == 4) {
        return true;
      }
    };
    $scope.formatReview = function (request) {
      return pleaseReviewService.formatReview(request);
    };

    $scope.removeItem = function (id) {
      const justRatedIndex = $scope.unratedList.findIndex(function (unrated) {
        return unrated.request_id === id;
      });
      $scope.unratedList.splice(justRatedIndex, 1);
    };

    $scope.$on('PleaseReview.Reviewed.Widget', function (e, id) {
      $scope.removeItem(id);
    });

    $scope.submitReview = function (request) {
      pleaseReviewService.review(request.request_id, request.review_rate).then(function () {
        $scope.removeItem(request.request_id);
        $rootScope.$broadcast('PleaseReview.Reviewed.Admin', request.request_id);
        $rootScope.$broadcast('PleaseReview.Fetched', $scope.unratedList.length);
      });
    };
    /// ///////////////////////////////////////////////////////////////////////////
    // Waiting Approval Job Modal
    $scope.ok = function (request) {
      sessionManagerService.setSession(request.request_id).then(
        function (response) {
          if (response.success) {
            showWaitingApprovalModal();
          } else {
            AlertService.errorAlert(response.message);
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
        }
      );
    };

    var showWaitingApprovalModal = function () {
      const modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/session/requestSessionModal.html',
        controller: 'WaitingApprovalModalController',
        keyboard: false,
        size: 'lg',
        backdrop: 'static',
        resolve: {
          enterprise_selection: function () {
            return $scope.entFilter;
          },
        },
      });
      modalInstance.result.then(function () {});
    };

    $scope.data = {
      isBrowserNotificationOn: !!BrowserNotificationService.enabled,
    };

    $scope.toggleBrowserNotification = function () {
      if (BrowserNotificationService.permission === EnumsService.NotificationPermission.granted) {
        // toggle the browser notifications
        toggleNotification();
      } else {
        // show a swal modal to the user to enable the notifications in the browser
        showModal();
      }
    };

    function toggleNotification() {
      console.log(UserAgentService.getBrowser());
      BrowserNotificationService.enabled = !BrowserNotificationService.enabled;
      $scope.data.isBrowserNotificationOn = BrowserNotificationService.enabled;
    }

    async function showModal() {
      const config = {
        title: 'Browser Notifications Blocked',
        text: 'Your browser notifications for this site are blocked, you can allow them in your browser',
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Ok',
        icon: 'info',
      };

      switch (UserAgentService.getBrowser()?.name?.toLowerCase()) {
        case EnumsService.Browsers.Chrome.toLowerCase():
          config.html = `
            <div class="notification-helper-container">
              <p>Your browser notifications for this site are blocked, you can allow them in your browser if you follow this short guide:</p>
              <ol class="helper-list">
                <li>Click on the icon next to the website address</li>
                <li>Click on the dropdown next to the Notifications</li>
                <li>Click on the Allow</li>
                <li>The browser will ask for reloading the page to enable notifications, you should click on the Reload</li>
              </ol>
              <img src="assets/images/notifications-helpers/chrome_enable_notifications.png" width="400px">
            </div>
          `;
          break;

        case EnumsService.Browsers.Safari.toLowerCase():
          config.html = `
            <div class="notification-helper-container">
              <p>Your browser notifications for this site are blocked, you can allow them in your browser if you follow this short guide:</p>
              <ol class="helper-list">
                <li>Click on the icon next to the website address</li>
                <li>Click on the dropdown next to the Notifications</li>
                <li>Click on the Allow</li>
                <li>The browser will ask for reloading the page to enable notifications, you should click on the Reload</li>
              </ol>
              <img src="assets/images/notifications-helpers/safari_enable_notifications_p1.png" width="400px">
              <img src="assets/images/notifications-helpers/safari_enable_notifications_p2.png" width="400px">
            </div>
          `;
          break;

        case EnumsService.Browsers.Firefox.toLowerCase():
          config.html = `
            <div class="notification-helper-container">
              <p>Your browser notifications for this site are blocked, you can allow them in your browser if you follow this short guide:</p>
              <ol class="helper-list">
                <li>Click on the icon next to the website address</li>
                <li>Click on the dropdown next to the Notifications</li>
                <li>Click on the Allow</li>
                <li>The browser will ask for reloading the page to enable notifications, you should click on the Reload</li>
              </ol>
              <img src="assets/images/notifications-helpers/firefox_enable_notifications_p1.png" width="400px">
              <img src="assets/images/notifications-helpers/firefox_enable_notifications_p2.png" width="400px">
            </div>
          `;
          break;

        case EnumsService.Browsers.Edge.toLowerCase():
          config.html = `
            <div class="notification-helper-container">
              <p>Your browser notifications for this site are blocked, you can allow them in your browser if you follow this short guide:</p>
              <ol class="helper-list">
                <li>Click on the icon next to the website address</li>
                <li>Click on the dropdown next to the Notifications</li>
                <li>Click on the Allow</li>
                <li>The browser will ask for reloading the page to enable notifications, you should click on the Reload</li>
              </ol>
              <img src="assets/images/notifications-helpers/edge_enable_notifications.png" width="400px">
            </div>
          `;
          break;

        default:
          config.text = 'Your browser notifications for this site are blocked, you can allow them in your browser';
      }

      const returnHandler = () => {
        BrowserNotificationService.enabled = false;
        $scope.data.isBrowserNotificationOn = false;
      };

      await Swal.fire(config);
      returnHandler();
    }
  },
]);
