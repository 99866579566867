import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  selector: 'app-tab',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent {
  @Input() selectedIndex = 0;

  @Input() items: { name: string }[];
  @Output() selectionChanged = new EventEmitter<number>();

  public selectTab(index: number) {
    this.selectedIndex = index;
    this.selectionChanged.emit(index);
  }
}
