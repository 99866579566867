import module from './module';
export default module.directive('dirCalendarFilter', [
  function () {
    return {
      restrict: 'E',
      scope: false,
      link: function ($scope, element, attr) {
        if ($scope.field && ['single_list', 'multi_list'].indexOf($scope.field.type) > -1) {
          if ($scope.field.choice_list && Array.isArray($scope.field.choice_list)) {
            $scope.field.choice_list.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
          }
        }
      },
      templateUrl: 'app/views/templates/calendar_filter.html',
    };
  },
]);
