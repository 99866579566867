import { Component, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseWidget } from './base.widget';

import * as fromRoot from '../store';
import { FilterRequestList } from '../store/actions';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TooltipService } from '@swimlane/ngx-charts';
import * as html2PDF from 'jspdf-html2canvas';

@Component({
  selector: 'total.time.component',
  templateUrl: './common.widget.template.html',
})
export class TotalTimeComponent extends BaseWidget {
  public titleValue;
  public titleText = 'Hours';
  public chartType = 'line';
  public tickCounter = 0;
  public tickInterval = 4;
  public hourlyFilter$ = new BehaviorSubject<number>(0);
  public savedList = null;

  public statusMap = {
    0: 'Total Hours',
    8: 'Interest Hours',
    3: 'Pending Hours',
    2: 'Committed Hours',
    1: 'Active Hours',
    5: 'Completed Hours',
    7: 'Cancelled Hours',
  };

  constructor(
    store: Store<fromRoot.State>,
    private tooltipService: TooltipService,
    private viewContainerRef: ViewContainerRef
  ) {
    super(store);

    this.store
      .select(fromRoot.getHourlyFilter)
      .pipe(
        tap((value) => {
          this.hourlyFilter$.next(value);
          this.savedList && this.loadDataTemplate(this.savedList);
          this.titleText = this.statusMap[value];
        })
      )
      .subscribe();

    this.tooltipService.injectionService.setRootViewContainer(this.viewContainerRef);
  }

  loadDataTemplate(list) {
    this.savedList = list;
    if (!list) {
      return;
    }
    let statusMap = {};
    let filteredList = null;
    const uniqueTimes = {};

    filteredList =
      this.hourlyFilter$.value === 0 ? list : list.filter((x) => x.status.type === this.hourlyFilter$.value);

    filteredList.forEach((item) => {
      if (statusMap[item.status.name]) {
        statusMap[item.status.name].push(item);
      } else {
        statusMap[item.status.name] = [item];
      }

      if (!uniqueTimes[item.request_date]) {
        uniqueTimes[item.request_date] = true;
      }
    });

    this.data = Object.keys(statusMap).map((key) => {
      const timeMap = {};
      statusMap[key].forEach((session) => {
        if (timeMap[session.request_date]) {
          timeMap[session.request_date] += Math.ceil(session.duration / 60);
        } else {
          timeMap[session.request_date] = Math.ceil(session.duration / 60);
        }
      });
      const series = Object.keys(uniqueTimes).map((item) => ({ name: item, value: timeMap[item] || 0 }));
      series.sort((a, b) => a.name.localeCompare(b.name));
      return {
        name: key,
        series,
      };
    });

    this.data.forEach((item) => {
      if (item.series.length > 15) {
        for (let i = 0; i < item.series.length; i += 2) {
          if (item.series[i + 1] !== undefined) {
            item.series[i].value += item.series[i + 1].value;
            item.series[i + 1].toRemove = true;
          }
        }
        item.series = item.series.filter((x) => !x.toRemove);
      }
    });
    this.count = Math.ceil(filteredList.map((x) => x.duration).reduce((a, b) => a + b, 0) / 60);
    this.titleValue = this.count;
  }

  filterAll() {
    let payload: any = {};
    if (this.hourlyFilter$.value) {
      payload.status = this.hourlyFilter$.value;
    } else {
      payload.showAll = true;
    }
    let action = new FilterRequestList(payload);
    this.store.dispatch(action);
  }

  filterData(data) {
    let payload: any = {};
    let action = new FilterRequestList(payload);
    this.store.dispatch(action);
  }

  exportToPDF() {
    const targetElement = document.getElementById('total-time-report');
    const clone = targetElement.cloneNode(true) as HTMLElement;
    clone.style.display = 'block';
    clone.style.letterSpacing = '0.1px';
    document.body.appendChild(clone);
    html2PDF(clone, {
      jsPDF: {
        unit: 'pt',
        format: 'a4',
        orientation: 'p',
      },
      html2canvas: {
        logging: true,
      },
    });
    clone.remove();
  }
}
