import angular from 'angular';
import module from './module';

export default module.factory('Location', [
  '$http',
  'BASE_URL',
  '$q',
  function ($http, BASE_URL, $q) {
    function Location(locationData, ent_id) {
      if (locationData && ent_id) {
        this.setUnknownData(locationData, ent_id);
      } else if (locationData && !ent_id) {
        this.setData(locationData);
      }
    }

    Location.prototype = {
      setData: function (locationData) {
        angular.extend(this, locationData);
      },
      setUnknownData: function (locationData, ent_id) {
        angular.extend(this, locationData);
        this.enterprise_id = ent_id;
      },
      addSessions: function (sessions) {
        this.sessions = sessions;
      },
      deleteLocation: function () {
        const deferred = $q.defer();
        $http
          .post(BASE_URL + '/delete_location', { location_id: this.location_id })
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function (response) {
            deferred.reject();
          });
        return deferred.promise;
      },
      deletePlace: function (place_id) {
        const location = this;
        const deferred = $q.defer();
        $http
          .post(BASE_URL + '/delete_place', { place_id })
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              const place = location.place_list.filter(function (obj) {
                return obj.place_id == place_id;
              })[0];
              const index = location.place_list.indexOf(place);
              if (index !== -1) {
                location.place_list.splice(index, 1);
              }
            }
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
        return deferred.promise;
      },
      addNewPlace: function (place) {
        const deferred = $q.defer();
        $http
          .post(BASE_URL + '/add_place', place)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function (response) {
            deferred.reject();
          });
        return deferred.promise;
      },
      updatePlace: function (place) {
        const deferred = $q.defer();
        $http
          .post(BASE_URL + '/update_place', place)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function (response) {
            deferred.reject();
          });
        return deferred.promise;
      },
    };

    return Location;
  },
]);
