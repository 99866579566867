import { Component, OnInit, Inject } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Store } from '@ngrx/store';
import * as fromRoot from '../store';
import env from '../../../scripts/env';

@Component({
  selector: 'provider-details-component',
  templateUrl: './provider.details.component.html',
})
export class ProviderDetailsComponent implements OnInit {
  private providerService;
  public provider: any;
  public domain = env.DOMAIN;

  constructor(
    private store: Store<fromRoot.State>,
    public bsModalRef: BsModalRef,
    @Inject('Provider') providerService
  ) {
    this.providerService = providerService;
  }

  ngOnInit() {
    this.store.select(fromRoot.getProviderDetails).subscribe((details) => {
      if (details) {
        this.provider = new this.providerService(details, 'DETAIL_DISPLAY');
      }
    });
  }

  close() {
    this.bsModalRef.hide();
  }

  loggedInStatus(status_code) {
    if (status_code == 1) {
      return 'logged-in-state';
    } else if (status_code == 0) {
      return 'logged-out-state';
    } else {
      return 'no-logged-in-state';
    }
  }
}
