import { Injector } from '@angular/core';

export const sessionServiceProvider = {
  provide: 'Session',
  useFactory: ($injector) => $injector.get('Session'),
  deps: ['$injector'],
};

export const providerServiceProvider = {
  provide: 'Provider',
  useFactory: ($injector) => $injector.get('Provider'),
  deps: ['$injector'],
};

export const providerManagerServiceProvider = {
  provide: 'ProviderManagerService',
  useFactory: ($injector) => $injector.get('providerManagerService'),
  deps: ['$injector'],
};

export const enterpriseFilterServiceProvider = {
  provide: 'EnterpriseFilterService',
  useFactory: ($injector) => $injector.get('EnterpriseFilterService'),
  deps: ['$injector'],
};

export const locationServiceProvider = {
  provide: 'LocationService',
  useFactory: (i: any) => i.get('$location'),
  deps: ['$injector'],
};

export const modalServiceProvider = {
  provide: 'ModalService',
  useFactory: (i: any) => i.get('$modal'),
  deps: ['$injector'],
};

export const branchServiceProvider = {
  provide: 'branchFilterService',
  useFactory: ($injector) => $injector.get('branchFilterService'),
  deps: ['$injector'],
};
export const alertServiceProvider = {
  provide: 'AlertService',
  useFactory: (i: any) => i.get('AlertService'),
  deps: ['$injector'],
};
export const timezoneStoreProvider = {
  provide: 'timeZoneStore',
  useFactory: (i: any) => i.get('timeZoneStore'),
  deps: ['$injector'],
};

export const googleLocationServiceProvider = {
  provide: 'googleLocationService',
  useFactory: (i: any) => i.get('googleLocationService'),
  deps: ['$injector'],
};

export const sessionManagerServiceProvider = {
  provide: 'sessionManagerService',
  useFactory: ($injector) => $injector.get('sessionManagerService'),
  deps: ['$injector'],
};

export const locationManagerServiceProvider = {
  provide: 'locationManagerService',
  useFactory: ($injector) => $injector.get('locationManagerService'),
  deps: ['$injector'],
};

export const formatHelperServiceProvider = {
  provide: 'FormatHelperService',
  useFactory: ($injector) => $injector.get('FormatHelperService'),
  deps: ['$injector'],
};

// allow $scope to be provided to ng1
export const ScopeProvider = {
  deps: ['$injector'],
  provide: '$scope',
  useFactory: (injector: Injector) => {
    return injector.get('$rootScope').$new();
  },
};

export const RootScopeProvider = {
  deps: ['$injector'],
  provide: '$rootScope',
  useFactory: (injector: Injector) => injector.get('$rootScope'),
};

export const LocalStorageProvider = {
  provide: 'localStorage',
  useFactory: ($injector) => $injector.get('$localStorage'),
  deps: ['$injector'],
};