import module from './module';
import * as enums from '../enums';

export default module.directive('dirEnterpriseFilter', [
  function () {
    return {
      restrict: 'E',
      templateUrl: 'app/views/templates/enterprise_filter.html',
      controller: [
        '$scope',
        '$localStorage',
        'FormatHelperService',
        ($scope, $localStorage, FormatHelperService) => {
          $scope.pickFilterName = 'Pick Filter';
          $scope.filterList = [];

          $scope.prepareFilters = function () {
            $scope.filterList.push({
              attrib_id: 1,
              name: 'name',
              isSystem: true,
              systemType: 1,
              display_name: 'Enterprise Name',
              type: enums.FilterableTypes.text,
              widget_type: 3,
              type_flags: {
                display: true,
                type: 0,
              },
            });
            $scope.filterList.push({
              attrib_id: 2,
              name: 'web_url',
              isSystem: true,
              systemType: 1,
              display_name: 'Custom URL',
              type: enums.FilterableTypes.text,
              widget_type: 3,
              type_flags: {
                display: true,
                type: 0,
              },
            });

            if ($localStorage.enterpriseFilter) {
              $scope.filters = JSON.parse($localStorage.enterpriseFilter);
            } else {
              $scope.filters = [];
            }
            if ($scope.filters.length) {
              $scope.pickFilterName = 'Pick Another Filter';
            }
            $scope.setFilters();
          };

          $scope.addFilter = function (attrib_id) {
            if (attrib_id && attrib_id !== 'none') {
              var filter = $scope.filterList.find(function (item) {
                return item.attrib_id == attrib_id;
              });
              if (
                !$scope.filters.find(function (item) {
                  return item.attrib_id == attrib_id;
                })
              ) {
                $scope.filters.push(filter);
              }
              $scope.selectedFilter = 'none';
              $scope.pickFilterName = 'Pick Another Filter';
            }
          };

          $scope.removeFilter = function (index) {
            $scope.filters.splice(index, 1);
            if (!$scope.filters.length) {
              $scope.pickFilterName = 'Pick Filter';
            }
            $scope.setFilters();
          };

          $scope.setFilters = function () {
            $scope.filters.forEach(function (filter) {
              if (filter.type === enums.FilterableTypes.single_list) {
                if (filter.value && filter.value.choice_id) {
                  filter.choice_list.forEach(function (choice) {
                    if (choice.choice_id === filter.value.choice_id) {
                      choice.selected = true;
                    } else {
                      choice.selected = false;
                    }
                  });
                }
              }
            });

            $localStorage.enterpriseFilter = JSON.stringify($scope.filters);
            let attribFilters = $scope.filters.map((f) => ({
              name: f.name,
              value: f.value,
            }));

            $scope.applyFilters(attribFilters);
          };

          $scope.keyPressed = function (e) {
            //User pressed enter, submit the filters
            if (e.keyCode === 13) {
              e.preventDefault();
              $scope.setFilters();
            }
          };

          $scope.prepareFilters();
        },
      ],
    };
  },
]);
