import { Injectable } from '@angular/core';

import { EnumsService } from './../../services/enums.service';
import { AuthenticationService } from './../../services/authentication.service';

import { IUser } from '../../interfaces/IUser';

@Injectable()
export class PageHeaderService {
  private currentUser: IUser;

  constructor(private authenticationService: AuthenticationService, private enumsService: EnumsService) {
    this.currentUser = this.authenticationService.getCurrentUser();
  }

  public getBranchSelectorVisibility(selectedTab: string): boolean {
    if (this.currentUser?.user_type === this.enumsService.UserType.MarketPlaceAdmin) {
      return true;
    }

    return false;
  }

  public getEnterpriseSelectorVisibility(): boolean {
    if (
      this.currentUser?.user_type === this.enumsService.UserType.MarketPlaceAdmin ||
      this.currentUser?.user_type === this.enumsService.UserType.BranchAdmin
    ) {
      return true;
    }

    return false;
  }
}
