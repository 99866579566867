import _ from 'lodash';
import angular from 'angular';
import module from '../module';
export default module.controller('EditEnterpriseModalController', [
  '$scope',
  '$modalInstance',
  'passedEnterprise',
  'AlertService',
  'enterpriseListService',
  'timezoneService',
  'DISPLAY_WEB_URL',
  'generalDataService',
  'WizardHandler',
  'EnterpriseFilterService',
  'branchFilterService',
  'AuthenticationService',
  'PERMISSIONS',
  'NgrxStore',
  'enterpriseUtilityService',
  function (
    $scope,
    $modalInstance,
    passedEnterprise,
    AlertService,
    enterpriseListService,
    timezoneService,
    DISPLAY_WEB_URL,
    generalDataService,
    WizardHandler,
    EnterpriseFilterService,
    branchFilterService,
    AuthenticationService,
    PERMISSIONS,
    NgrxStore,
    enterpriseUtilityService
  ) {
    $scope.initialize = function () {
      $scope.CURRENT_USER = AuthenticationService.getCurrentUser();
      $scope.showBranch =
        $scope.CURRENT_USER.user_type === 2 && $scope.CURRENT_USER.permissionCheck(PERMISSIONS.BRANCH_TAB);

      $scope.showCheckin =
        $scope.CURRENT_USER.user_type === 2 && $scope.CURRENT_USER.permissionCheck(PERMISSIONS.WORKN_CHECKIN);

      $scope.loaded = false;
      $scope.keySession = generalDataService.getKeySession();
      $scope.submitBtnDisbaled = false;
      $scope.showSpinner = true;
      $scope.wizardStepText = 'Hide Steps';
      $scope.webUrl = DISPLAY_WEB_URL;
      $scope.type = 'Edit Enterprise'; // Modal Title
      $scope.btnLabel = 'Update Enterprise';
      $scope.headerLogoIndex = 1;
      $scope.tabIconIndex = 1;
      $scope.headerLogo = {
        type: 'image',
        editType: 'edit',
        name: 'headerLogo',
        display_name: 'Header Logo',
        type_flags: {
          required: false,
        },
        value: '',
        attrib_list: [
          {
            attrib_id: 1,
            type: 'image',
            data: '',
          },
        ],
        attrib_id: 1,
      };

      $scope.tabIcon = {
        type: 'image',
        editType: 'edit',
        name: 'tabIcon',
        display_name: 'Web Tab Icon',
        type_flags: {
          required: false,
        },
        value: '',
        attrib_list: [
          {
            attrib_id: 1,
            type: 'image',
            data: '',
          },
        ],
        attrib_id: 1,
      };

      $scope.loginBackground = {
        type: 'image',
        editType: 'edit',
        name: 'loginBackground',
        display_name: 'Login Background',
        type_flags: {
          required: false,
        },
        value: '',
        attrib_list: [
          {
            attrib_id: 1,
            type: 'image',
            data: '',
          },
        ],
        attrib_id: 1,
      };

      $scope.loginImage = {
        type: 'image',
        editType: 'edit',
        name: 'loginImage',
        display_name: 'Login Image',
        type_flags: {
          required: false,
        },
        value: '',
        attrib_list: [
          {
            attrib_id: 1,
            type: 'image',
            data: '',
          },
        ],
        attrib_id: 1,
      };

      $scope.checkinOptions = [
        { id: -1, name: 'Marketplace Setting' },
        { id: 0, name: 'Off' },
        { id: 1, name: 'On' },
      ];

      $scope.geoFenceSettings = [
        { id: -1, name: 'Follow Marketplace Setting' },
        { id: 0, name: 'Override Marketplace Setting' },
      ];

      $scope.gpsWarningOptions = ['WARNING', 'STOP', 'OFF'];

      $scope.enterpriseId = passedEnterprise.id;
      $scope.showBlacklist = $scope.enterpriseId && $scope.CURRENT_USER.permissionCheck(PERMISSIONS.BLACKLIST);

      $scope.blacklistLoaded = (blackList) => {
        if ($scope.formData) {
          $scope.formData.provider_list = [];
        } else {
          $scope.formData = {
            provider_list: [],
          };
        }
        $scope.provider_list = blackList;
        $scope.$broadcast('Provider_List.Loaded');
      };

      enterpriseListService.getEnterprise(passedEnterprise.id).then(function (enterprise) {
        $scope.originalFormData = angular.copy(enterprise);
        $scope.formData = angular.copy(enterprise);

        if (enterprise.details.header_image) {
          $scope.headerLogo.attrib_list[0].data = enterprise.details.header_image;
        }
        if (enterprise.details.favicon) {
          $scope.tabIcon.attrib_list[0].data = enterprise.details.favicon;
        }
        if (enterprise.details.login_page_background) {
          $scope.loginBackground.attrib_list[0].data = enterprise.details.login_page_background;
        }
        if (enterprise.details.login_page_image) {
          $scope.loginImage.attrib_list[0].data = enterprise.details.login_page_image;
        }
        if (enterprise.details.checkin_buffer !== -1 || enterprise.details.checkin_gps_warning !== 'MarketPlace' || enterprise.details.checkin_radius !== -1) {
          $scope.formData.geoFenceOption = 0;
        } else {
          $scope.formData.geoFenceOption = -1;
        }

        $scope.loaded = true;
      });

      branchFilterService.fetchBranchesForList().then((branches) => {
        $scope.branches = branches;
      });

      timezoneService.getTimezones().then(function (timezones) {
        $scope.timezones = timezones;
      });
    };

    $scope.toggleWizard = function () {
      if ($scope.hideWizard) {
        $scope.wizardStepText = 'Show Steps';
      } else {
        $scope.wizardStepText = 'Hide Steps';
        WizardHandler.wizard().goTo(1);
        WizardHandler.wizard().goTo(0);
      }
    };

    $scope.showPrevStep = function () {
      const currentStepNumber = WizardHandler.wizard().currentStepNumber();
      return currentStepNumber > 1 && currentStepNumber < 4;
    };

    $scope.showNextStep = function () {
      return WizardHandler.wizard().currentStepNumber() < WizardHandler.wizard().totalStepCount();
    };

    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;

      const detailsDiff = $scope.diff($scope.formData.details, $scope.originalFormData.details);
      const formDiff = $scope.diff($scope.formData, $scope.originalFormData);
      formDiff.details = detailsDiff;
      formDiff.id = $scope.originalFormData.id;
      if ($scope.headerLogo.jpeg) {
        formDiff.details.header_image = $scope.headerLogo.jpeg;
      }
      if ($scope.tabIcon.jpeg) {
        formDiff.details.favicon = $scope.tabIcon.jpeg;
      }
      if ($scope.loginBackground.jpeg) {
        formDiff.details.login_page_background = $scope.loginBackground.jpeg;
      }
      if ($scope.loginImage.jpeg) {
        formDiff.details.login_page_image = $scope.loginImage.jpeg;
      }
      if ($scope.formData.branch === -1) {
        formDiff.branch = null;
      }
      if ($scope.formData.geoFenceOption === -1) {
        formDiff.details.checkin_buffer = -1;
        formDiff.details.checkin_radius = -1;
        formDiff.details.checkin_radius_buffer = -1;
        formDiff.details.warning_radius = -1;
        formDiff.details.checkout_radius = -1;
        formDiff.details.checkout_radius_buffer = -1;
        formDiff.details.checkin_gps_warning = 'MarketPlace';
        formDiff.details.gps_warning_radius = -1;
      }

      if ($scope.validateFormData()) {
        $scope.sendToServer(formDiff);
      }
    };

    $scope.validateFormData = function () {
      const formErrors = enterpriseUtilityService.validateEnterpriseFormData($scope.formData);
      if (formErrors.length > 0) {
        AlertService.errorListAlert(formErrors, 3000);
        $scope.submitBtnDisbaled = false;
        return false;
      }
      return true;
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      $scope.formData = {};
    };

    $scope.diff = function (current, original) {
      return _.pickBy(current, function (value, key) {
        const res = !_.isEqual(current[key], original[key]);
        return res;
      });
    };

    $scope.sendToServer = function (data) {
      enterpriseListService.editEnterprise(data).then(
        function (res) {
          EnterpriseFilterService.updateEnterpriseName(res);
          AlertService.successAlert('Enterprise was updated.');
          $modalInstance.close();
        },
        function (reason) {
          AlertService.detailedErrorAlert(reason);
          $scope.submitBtnDisbaled = false;
        }
      );
    };

    $scope.saveBlacklist = function (providers, selectedProviders) {
      const workers = providers.filter((p) => p.poolSelected).map((p) => p.user_id);
      const selection = selectedProviders.map((p) => p.user_id);
      const result = new Set([...workers, ...selection]);

      NgrxStore.dispatch({
        type: '[Workers] Update enterprise blacklist',
        enterpriseId: $scope.enterpriseId,
        workers: Array.from(result),
      });
    };

    $scope.geoFenceSettingsOptionChanged = function () {
      if ($scope.formData.geoFenceOption === 0) {
        // set Defaults
        $scope.formData.details.checkin_buffer = 30;
        $scope.formData.details.checkin_radius = 500;
        $scope.formData.details.checkin_radius_buffer = 180;
        $scope.formData.details.warning_radius = 1;
        $scope.formData.details.checkout_radius = 500;
        $scope.formData.details.checkout_radius_buffer = 15;
        $scope.formData.details.checkin_gps_warning = 'STOP';
        $scope.formData.details.gps_warning_radius = 300;
      }
    };

    $scope.initialize();
  },
]);
