import module from './module';
import angular from 'angular';

export default module.factory('Requester', [
  '$http',
  'BASE_URL',
  function ($http, BASE_URL) {
    function Requester(requesterData, dataType) {
      if (requesterData) {
        this.setData(requesterData, dataType);
      }
    }

    Requester.prototype = {
      setData: function (requesterData, dataType) {
        angular.extend(this, requesterData);
        if (dataType === 'TABLE_DISPLAY') {
          const firstName = requesterData.profile_info.filter(function (obj) {
            return obj.name === 'first_name';
          })[0];
          firstName ? (this.first_name = firstName.data) : (this.first_name = 'N/A');

          const lastName = requesterData.profile_info.filter(function (obj) {
            return obj.name === 'last_name';
          })[0];
          lastName ? (this.last_name = lastName.data) : (this.last_name = 'N/A');

          this.full_name = this.first_name + ' ' + this.last_name;

          this.login_status = requesterData.profile_info.filter(function (obj) {
            return obj.name === 'login_status';
          })[0];
          if (this.login_status) {
            this.login_status.selected = this.login_status.choice_list.filter(function (obj) {
              return obj.selected == true;
            })[0].name;
          }

          this.vetting_status = requesterData.profile_info.filter(function (obj) {
            return obj.name === 'requester_vetting_status';
          })[0];
          if (this.vetting_status) {
            const selectedStatus = this.vetting_status.choice_list.filter(function (obj) {
              return obj.choice_id == requesterData.status;
            })[0];
            selectedStatus
              ? (this.vetting_status.selected = selectedStatus.name)
              : (this.vetting_status.selected = 'N/A');
          }
        } else if (dataType === 'DETAIL_DISPLAY') {
          // TODO add further details if needed
        }
      },
    };

    return Requester;
  },
]);
