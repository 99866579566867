import {
  Component,
  forwardRef,
  Input,
  HostListener,
  ElementRef,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

@Component({
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  selector: 'time-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeInputComponent),
      multi: true,
    },
  ],
})
export class TimeInputComponent implements ControlValueAccessor {
  @Input() required: boolean = false;
  @Input() opened = false;

  @Output() onFocus = new EventEmitter<void>();
  @Output() onOpen = new EventEmitter<void>();
  @Output() onClose = new EventEmitter<void>();

  onChange: (value: Date) => void = () => {};
  onTouched: Function = () => {};
  disabled = false;

  value: string;

  public editorValue;

  constructor(private eRef: ElementRef, private cdRef: ChangeDetectorRef) {}

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      if (this.opened) {
        this.closePopup(true);
      }
    }
  }

  @HostListener('document:keydown', ['$event'])
  keyDown(event) {
    if (this.opened) {
      // Enter
      if (event.keyCode === 13) {
        event.preventDefault();
        event.target.blur();
        this.cdRef.detectChanges();
        setTimeout(() => {
          this.closePopup(true);
        });
        // Escape
      } else if (event.keyCode === 27) {
        this.closePopup(false);
      }
    }
  }

  writeValue(date: Date) {
    this.value = moment(date).format('h:mm A');
    this.editorValue = date;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  openPopup() {
    this.onOpen.emit();

    setTimeout(() => {
      const hourInput = document.querySelector('.bs-timepicker-field') as HTMLInputElement;
      if (hourInput) {
        hourInput.focus();
        hourInput.select();
      }
    });
  }

  valueFocused() {
    this.onFocus.emit();
  }

  closePopup(save: boolean) {
    this.onClose.emit();

    if (save) {
      this.value = moment(this.editorValue).format('h:mm A');
      this.onChange(this.editorValue);
      this.onTouched();
    }
  }
}
