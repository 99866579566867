import module from './module';

export default module.factory('escalationManagerService', [
  '$http',
  'BASE_URL',
  '$q',
  'Escalation',
  function ($http, BASE_URL, $q, Escalation) {
    const escalationsManager = {
      _pool: {},
      _escalationsObj: {},
      _selectedEscalation: {},
      _retrieveInstance: function (escalationId, escalationData) {
        const instance = new Escalation(escalationData);
        this._pool[escalationId] = instance;
        if (escalationData.enterprise_id) {
          if (!this._escalationsObj[escalationData.enterprise_id]) {
            this._escalationsObj[escalationData.enterprise_id] = [];
          }
          if (instance.pool_list) {
            instance.pool_list.sort(function (a, b) {
              return a.order - b.order;
            });
          }
          this._escalationsObj[escalationData.enterprise_id].push(instance);
        } else {
          if (!this._escalationsObj[-1]) {
            this._escalationsObj[-1] = [];
          }
          this._escalationsObj[-1].push(instance);
        }
        return instance;
      },
      _removeInstance: function (escalation, deferred) {
        const instance = this._pool[escalation.escalation_id];
        if (instance) {
          delete this._pool[escalation.escalation_id];
          if (escalation.enterprise_id) {
            const index = this._escalationsObj[escalation.enterprise_id].indexOf(instance);
            if (index !== -1) {
              this._escalationsObj[escalation.enterprise_id].splice(index, 1);
            }
          } else {
            const index = this._escalationsObj[-1].indexOf(instance);
            if (index !== -1) {
              this._escalationsObj[-1].splice(index, 1);
            }
          }
        }
        deferred.resolve(this._escalationsObj);
      },
      _loadAllEscalations: function (deferred, escalationParams) {
        const scope = this;
        $http
          .post(BASE_URL + '/get_escalations', escalationParams)
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              scope._pool = {};
              scope._escalationsObj = {};
              response.escalation_list.forEach(function (escalationData) {
                scope._retrieveInstance(escalationData.escalation_id, escalationData);
              });
              deferred.resolve(scope._escalationsObj);
            } else {
              deferred.reject();
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _newEscalation: function (escalationData, deferred) {
        const scope = this;
        $http
          .post(BASE_URL + '/add_escalation', escalationData)
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              scope._retrieveInstance(response.escalation_id, response);
            }
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _updateEscalation: function (escalationData, deferred) {
        $http
          .post(BASE_URL + '/update_escalation', escalationData)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      getAllEscalations: function (escalationParams) {
        const deferred = $q.defer();
        this._loadAllEscalations(deferred, escalationParams);
        return deferred.promise;
      },
      addNewEscalation: function (escalationData) {
        const deferred = $q.defer();
        this._newEscalation(escalationData, deferred);
        return deferred.promise;
      },
      updateEscalation: function (escalationData) {
        const deferred = $q.defer();
        this._updateEscalation(escalationData, deferred);
        return deferred.promise;
      },
      removeDeletedEscalation: function (escalation) {
        const deferred = $q.defer();
        this._removeInstance(escalation, deferred);
        return deferred.promise;
      },
    };
    return escalationsManager;
  },
]);
