import angular from 'angular';
import module from '../module';
import * as enums from '../../enums';

export default module.controller('AssignmentModalController', [
  '$scope',
  '$modal',
  '$modalInstance',
  'AlertService',
  'passedSession',
  'sessionManagerService',
  'providerManagerService',
  'trackingService',
  'generalDataService',
  'DOMAIN',
  'passed_job_order_id',
  'passed_total_worker_count',
  function (
    $scope,
    $modal,
    $modalInstance,
    AlertService,
    passedSession,
    sessionManagerService,
    providerManagerService,
    trackingService,
    generalDataService,
    DOMAIN,
    passed_job_order_id,
    passed_total_worker_count
  ) {
    $scope.initialize = function () {
      $scope.submitBtnDisbaled = false;
      $scope.title = 'Assign';
      $scope.session = angular.copy(passedSession);
      $scope.passed_job_order_id = passed_job_order_id;
      $scope.loadProviders();
      $scope.oldUserId =
        $scope.session && !isNaN($scope.session.user_id) ? angular.copy($scope.session.user_id) : undefined;
      if ($scope.session) {
        $scope.formData = {
          request_id: $scope.session.session_id,
          user_id: $scope.session.user_id,
        };
      }
      const profession = generalDataService.getKeyProfession();
      $scope.keyProfession = (profession && profession.data_content) || 'Worker';
    };

    $scope.loadProviders = function () {
      if (passed_job_order_id || ($scope.session && $scope.session.jobOrderId)) {
        $scope.title = 'Schedule';
        providerManagerService
          .getQualifiedProviders({
            job_order_id: $scope.session ? $scope.session.jobOrderId : passed_job_order_id,
          })
          .then(function (providers) {
            $scope.providers = providers;

            if (!providers) {
              return;
            }

            $scope.providers.forEach((p) => {
              p.login_status_code = p.login_status;
              p.vetting_status_code = p.vetting_status;
            });
          });
      } else {
        providerManagerService
          .getQualifiedProviders({
            request_id: $scope.session.session_id,
          })
          .then(function (providers) {
            $scope.providers = providers;
            $scope.providers.forEach((p) => {
              p.login_status_code = p.login_status;
              p.vetting_status_code = p.vetting_status;
            });
          });
      }
    };

    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      if (($scope.session && $scope.session.jobOrderId) || passed_job_order_id) {
        $scope.assignJobOrder();
      } else {
        $scope.assignSession();
      }

      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'submit');
    };

    $scope.getProfileUrl = function (picture) {
      if (picture) {
        return DOMAIN + picture;
      }
      return `${enums.AssetsFolder.images}avatar.png`;
    };

    $scope.showProvider = function (provider) {
      providerManagerService.getProvider(provider.user_id).then(function (provider) {
        const modalInstance = $modal.open({
          animation: true,
          templateUrl: 'app/views/job/providerModal.html',
          controller: 'ProviderModalController',
          size: 'lg',
          backdrop: 'static',
          resolve: {
            provider: function () {
              provider.hidePermissions = true;
              return provider;
            },
          },
        });
        modalInstance.result.then(function () {});
      });
    };

    $scope.assignSession = function () {
      sessionManagerService.assignSessionProvider($scope.formData).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
            $modalInstance.close();
          } else {
            AlertService.errorAlert(response.message);
            $modalInstance.dismiss('cancel');
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.dismiss('cancel');
        }
      );
    };

    $scope.assignJobOrder = function () {
      if (!$scope.providers) {
        return;
      }

      const selectedProviders = $scope.providers.filter((p) => p.selected);
      const totalWorkers =
        $scope.session && $scope.session.total_workers ? $scope.session.total_workers : passed_total_worker_count;
      if (!selectedProviders.length) {
        AlertService.warningAlert(`Please select at least one ${$scope.keyProfession}.`);
        $scope.submitBtnDisbaled = false;
      } else if (selectedProviders.length > totalWorkers) {
        AlertService.warningAlert(
          `You are trying to schedule too many ${$scope.keyProfession}s. You need ${totalWorkers} but attempted to schedule ${selectedProviders.length}.`
        );
        $scope.submitBtnDisbaled = false;
      } else {
        selectedProviders.forEach((provider) => {
          const jobOrder = {
            jobOrderId: $scope.session ? $scope.session.jobOrderId : passed_job_order_id,
            userId: provider.user_id,
            oldUserId: $scope.oldUserId,
          };
          sessionManagerService.assignJobOrder(jobOrder).then(
            function (response) {
              if (response.success) {
                AlertService.successAlert(response.message);
                $modalInstance.close();
              } else {
                AlertService.errorAlert(response.message);
                $modalInstance.dismiss('cancel');
              }
            },
            function (reason) {
              AlertService.serverRequestErrorAlert(reason);
              $modalInstance.dismiss('cancel');
            }
          );
        });
      }
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'cancel');
    };

    $scope.initialize();
  },
]);
