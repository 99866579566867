import module from '../module';
export default module.controller('BirdsEyeController', [
  '$scope',
  '$rootScope',
  '$modalInstance',
  'passedData',
  function ($scope, $rootScope, $modalInstance, passedData) {
    if (passedData) {
      $scope.jobId = passedData.jobId;
      $scope.jobInstance = passedData.jobInstance;
    }

    $scope.closeCallback = {
      doClose: function () {
        $modalInstance.close();
      },
    };
  },
]);
