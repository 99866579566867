import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  Inject,
} from '@angular/core';
import { BirdsEyeModel, BirdsEyeProvider } from '../../../shared/models/birds-eye';

@Component({
  templateUrl: './birds-eye-workers.component.html',
  styleUrls: ['./birds-eye-workers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-birds-eye-workers',
})
export class BirdsEyeWorkersComponent implements OnChanges {
  @Input() model: BirdsEyeModel;
  @Input() mode: 'schedule' | 'offer';
  @Input() editable: boolean = true;
  @Input() sessions: { [key: number]: number[] } = {};

  @Output() allDaysClicked = new EventEmitter<{ provider_id: number; remove: boolean }>();
  @Output() modeChange = new EventEmitter<'schedule' | 'offer'>();

  private workerSessions: any = {};

  constructor(@Inject('ModalService') private modalService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sessions && changes.sessions.currentValue) {
      this.workerSessions = {};
      Object.keys(changes.sessions.currentValue).forEach((key) => {
        const items = changes.sessions.currentValue[key];
        items.forEach((item) => {
          if (this.workerSessions[item]) {
            this.workerSessions[item]++;
          } else {
            this.workerSessions[item] = 1;
          }
        });
      });
    }
  }

  public getSessionCounter(worker: BirdsEyeProvider) {
    const workerNumber = this.getNumberOfSessions(worker);
    return `${workerNumber} / ${this.model.session_list.length}`;
  }

  public getSessionPercentage(worker: BirdsEyeProvider) {
    return `${((this.getNumberOfSessions(worker) / this.model.session_list.length) * 100).toFixed()}%`;
  }

  private getNumberOfSessions(worker: BirdsEyeProvider) {
    return this.workerSessions[worker.provider_id] || 0;
  }

  public getTotalPercentage() {
    return `${(
      (this.model.session_list.filter((x) => this.sessions[x.session_id] && this.sessions[x.session_id].length).length /
        this.model.session_list.length) *
      100
    ).toFixed()}%`;
  }

  public allSessionsFilled(provider_id) {
    return (
      Object.keys(this.sessions).filter((key) => this.sessions[key].length).length === this.model.session_list.length
    );
  }

  public onAllDays(provider_id) {
    const remove = this.allSessionsFilled(provider_id);
    this.allDaysClicked.emit({ provider_id, remove });
  }

  public openWorkerProfile(worker) {
    this.modalService.open({
      animation: true,
      templateUrl: 'app/views/workers/index.html',
      controller: 'WorkersController',
      keyboard: false,
      size: 'xlg',
      backdrop: 'static',
      resolve: {
        passedData: function () {
          return {
            workerId: worker.user_id,
            providerId: worker.provider_id,
          };
        },
      },
    });
  }
}
