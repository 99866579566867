import module from './module';
export default module.directive('dirSwitchSelect', function () {
  return {
    restrict: 'E',
    scope: {
      selectValue: '=?',
      selectOptions: '=',
      sortChanged: '&',
    },
    link: function (scope) {
      scope.setValue = (event, item) => {
        scope.sortChanged({ $event: item.sortKey });
        if (scope.selectValue !== item.value) {
          scope.selectValue = item.value;
          event.stopImmediatePropagation();
          event.preventDefault();
        }
      };
    },
    templateUrl: 'app/views/templates/switch_select.html',
  };
});
