import module from '../module';
import Swal from 'sweetalert2';
import * as enums from '../../enums';

export default module.controller('UpdateRequestAttribsModalController', [
  'sessionManagerService',
  '$scope',
  '$modalInstance',
  'AlertService',
  'passedSession',
  async function (sessionManagerService, $scope, $modalInstance, AlertService, passedSession) {
    sessionManagerService.setSession(passedSession.session_id).then(
      async function (session) {
        $scope.attrib = session.attrib_list.find((x) => x.name === 'notes_internal');
        if (!$scope.attrib) {
          const result = await sessionManagerService.getRealmAttribs(enums.Realms.Service);
          $scope.attrib = result.attrib_list.find((x) => x.name === 'notes_internal');
        }
        $scope.notes = $scope.attrib?.value_text;
      },
      function (reason) {
        AlertService.serverRequestErrorAlert(reason);
        $modalInstance.dismiss('cancel');
      }
    );

    $scope.updateNotes = function () {
      sessionManagerService
        .updateRequestAttribs(passedSession.session_id, [
          {
            attrib_id: $scope.attrib.attrib_id,
            name: $scope.attrib.name,
            value_text: $scope.notes,
          },
        ])
        .then(async function (result) {
          if (result.success) {
            const configPositive = {
              title: 'Updated!',
              text: 'Internal notes updated',
              icon: 'success',
              timer: 2000,
              showConfirmButton: false,
            };
            await Swal.fire(configPositive);
            $modalInstance.dismiss('cancel');
          } else {
            AlertService.errorAlert(result.message);
            $modalInstance.dismiss('cancel');
          }
        });
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  },
]);
