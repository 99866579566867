import { CalendarView } from 'angular-calendar';
import { WorkerEvent } from '../../shared/models/worker-event';
import { Worker } from '../../shared/models/worker';

export interface WorkersState {
  list: { [key: number]: Worker | any };
  count: number;
  rawResult?: any;

  selectedTab: number;
  selectedCalendarView: CalendarView;
  events?: WorkerEvent[];

  blacklistedEnterprises: { [userId: string]: { id: string; name: string }[] };
  blacklistedWorkers: { [enterpriseId: number]: { id: number; first_name: string; last_name: string }[] };
}

export const initialWorkersState: WorkersState = {
  list: [],
  count: 0,
  selectedTab: 0,
  selectedCalendarView: CalendarView.Month,
  blacklistedEnterprises: {},
  blacklistedWorkers: {},
};
