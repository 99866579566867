import * as moment from 'moment';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  templateUrl: './calendar-event.component.html',
  styleUrls: ['./calendar-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-calendar-event',
})
export class CalendarEventComponent {
  @Input() event: any;
  @Input() isDay: boolean = false;

  public getEventTime() {
    return `${moment(this.event.start).format('H:mm A')} - ${moment(
      this.event.end
    ).format('H:mm A')}`;
  }
}
