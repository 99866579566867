export default {
  GENERAL_NOTIF_ADMIN_CONS: 1, // General Notification Area - Admin Console
  WFA_NOTIF_ADMIN_CONS: 2, // Waiting for Approval Notification Area - Admin Console
  SESSION_LIST_ADMIN_CONS: 3, // Session List - Admin Console
  SCHEDULE_SESSION: 4,
  NOW_SESSION: 5,
  SESSION_TAB: 6,
  REPORT_TAB: 7,
  ADMIN_TAB: 8,
  SUPPORT_TAB: 9,
  PRICE_TAB: 10,
  REQUESTER_TAB: 11,
  PROVIDER_TAB: 12,
  POOL_TAB: 13,
  ESCALATION_TAB: 14,
  ADD_LOCATION: 15,
  MEETING_PLACE_TAB: 16,
  ROLE_TAB: 17,
  ADD_PRICE: 18,
  EDIT_PRICE: 19,
  DELETE_PRICE: 20,
  ADD_REQUESTER: 21,
  ADD_PROVIDER: 22,
  EDIT_PROVIDER: 23,
  DELETE_PROVIDER: 24,
  ADD_POOL: 25,
  EDIT_POOL: 26,
  DELETE_POOL: 27,
  ADD_ESCALATION: 28,
  EDIT_ESCALATION: 29,
  DELETE_ESCALATION: 30,
  ADD_MEETING_PLACE: 31,
  EDIT_MEETING_PLACE: 32,
  DELETE_MEETING_PLACE: 33,
  ADD_ROLE: 34,
  EDIT_ROLE: 35,
  DELETE_ROLE: 36,
  CALENDAR_SCHEDULE_TAB: 37,
  JOBS: 38,
  SCHEDULE_JOB: 39,
  CALENDAR_SHOW_HOURS: 40,
  ASSIGN: 41,
  ESCALATE: 42,
  CHECKIN: 43,
  CHECKOUT: 44,
  REVIEW: 45,
  CHANGE: 46,
  CANCEL: 47,
  CHANGE_VERIFY: 48,
  ANALYTICS_TAB: 49,
  USER_TAB: 50,
  ADD_USER: 51,
  EDIT_USER: 52,
  DELETE_USER: 53,
  APPROVE: 54,
  REQUEUE: 55,
  ENTERPRISE_TAB: 56,
  ADD_ENTERPRISE: 57,
  EDIT_ENTERPRISE: 58,
  DELETE_ENTERPRISE: 59,
  REMOVE: 60,
  CANCEL_REMOVE: 61,
  ASSIGN_QUALIFIED_WORKER: 62,
  NEW_ANALYTICS_TAB: 63,
  DASHBOARD_FINANCIAL: 64,
  SCHEDULE_JOB_ORDER: 65,
  APPLY_SHOW_DIALOG: 66,
  APPLY_ASSIGN_DIALOG: 67,
  APPLY_OFFER_DIALOG: 68,
  BRANCH_TAB: 69,
  ADD_BRANCH: 70,
  EDIT_BRANCH: 71,
  DELETE_BRANCH: 72,
  WORKN_CHECKIN: 73,
  PEOPLENET_CHECKIN: 74, // not used anymore
  CHANGE_ASSIGNMENT: 75,
  TIME_SHEET_VERIFICATION: 76,
  TIME_SHEET_REPORTS: 77,
  REPEAT_JOB_ORDER_QUESTION: 78,
  UNBUNDLED_JOB_ORDER: 79,
  ANALYTICS_DASHBOARD: 79,
  DASHBOARD_TAB: 79,
  ANALYTICS_REPORTING: 80,
  JOB_ORDERS_WITHOUT_TEMPLATE: 81,
  ALLOW_UNVERIFY: 82,
  SHOW_AMOUNT_VERIFY: 83,
  ADHOC_EDIT_ATTRIBS: 84,
  SHOW_ADHOC: 85,
  BIRDS_EYE: 86,
  HIDE_VERIFY_BUTTON: 87,
  BLACKLIST: 88,
  MULTI_BRANCH_ADMIN: 89,
  PRICE_ATTRIBS: 90,
  AUTOMATED_EXTRACT_CALL: 91,
  UPDATE_JOB_ORDER: 92,
  UPDATE_ORDER: 93,
  FORCE_KNOWN_LOCATION: 94,
  TOTAL_TIME_REPORT: 95,
  CUSTOM_COMM_LIST: 96,
  CUSTOM_COMM_EMAIL: 97,
  CUSTOM_COMM_TEXT: 98,
  CUSTOM_COMM_PUSH: 99,
  LOGOUT_USER: 100,
  MASS_CANCEL: 101,
  TIME_KEEPING_EDIT_TIME: 102,
  CONFIDENTIAL_FIELDS: 103,
};
