import angular from 'angular';
import module from '../module';
import Swal from 'sweetalert2';
import * as enums from '../../enums';

export default module.controller('BaseJobModalController', [
  '$scope',
  '$modalInstance',
  'FormatHelperService',
  'AlertService',
  'enterprise_selection',
  'sessionManagerService',
  'trackingService',
  'generalDataService',
  'AuthenticationService',
  'branchFilterService',
  function (
    $scope,
    $modalInstance,
    FormatHelperService,
    AlertService,
    enterprise_selection,
    sessionManagerService,
    trackingService,
    generalDataService,
    AuthenticationService,
    branchFilterService
  ) {
    $scope.entFilter = enterprise_selection;
    $scope.submitBtnDisbaled = false;
    $scope.jobType = { selection: '', pickListRequested: false }; // for job type checkboxes
    $scope.keySession = generalDataService.getKeySession();
    $scope.title = 'Schedule ' + $scope.keySession;
    $scope.requestType = 1;
    $scope.type = 'new';

    // Grabbing list of Job attribs from API
    const getRequestAttribs = function () {
      sessionManagerService.getRealmAttribs(enums.Realms.Service).then(
        function (response) {
          if (response.success) {
            const scheduledService = response.attrib_list.filter(function (obj) {
              return obj.type_flags.type == 0 || obj.type_flags.type == $scope.requestType;
            });

            const formData = scheduledService.sort(function (a, b) {
              return a.order - b.order;
            });

            $scope.originalFormData = angular.copy(formData);
            $scope.formData = angular.copy(formData);
            reorderAttribs($scope.formData);

            // Default to apply if escalation is not present
            if (!$scope.escalationAttrib && $scope.applyAttrib) {
              $scope.applyAttrib.value = true;
            }

            if ($scope.attribsLoadedCallback) {
              $scope.attribsLoadedCallback($scope.formData);
            }
          } else {
            AlertService.errorAlert(response.message);
            $modalInstance.close();
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    };

    // modal controls
    $scope.validate = async function () {
      if ($scope.jobType.pickListRequested) {
        const result = $scope.validatePickList();
        if (result) {
          $scope.showSpinner = false;

          const config = {
            title: 'Are you sure?',
            text: result.message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#cc514c',
            confirmButtonText: 'Continue',
          };

          const returnHandler = function (isConfirm) {
            if (isConfirm) {
              $scope.showSpinner = true;
              formatRequest();
            } else {
              $scope.submitBtnDisbaled = false;
              $scope.$apply();
            }
          };

          const { value: returnValue } = await Swal.fire(config);
          returnHandler(returnValue);

          return;
        }
      }
      formatRequest();
    };

    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      $scope.showSpinner = true;
      // Do Error Checking for missing fields
      let _errorMessages = [];
      // Multi select erorr Checking
      angular.forEach($scope.formData, function (attrib) {
        if (attrib.type === 'multi_list' && attrib.type_flags.required == true) {
          if (attrib.value.length == 0) {
            _errorMessages.push('Missing Required ' + attrib.display_name + ' Selection');
          }
        } else if (attrib.name === 'location') {
          if (attrib.value.location_id == 0) {
            if (attrib.value.location.length == 0) {
              _errorMessages.push('Missing Required ' + attrib.display_name + ' Selection');
            }
          }
        }
      });

      const customErrors = $scope.checkForErrors($scope.formData);
      if (customErrors.length) {
        _errorMessages = _errorMessages.concat(customErrors);
      }

      if (_errorMessages.length > 0) {
        AlertService.errorAlert(_errorMessages.join('\n'));
        $scope.submitBtnDisbaled = false;
        $scope.showSpinner = false;
      } else {
        // no missing fields
        if ($scope.jobType.selection === 'assign') {
          $scope.formData.push($scope.staffProviderAttrib);
          if ($scope.qualifiedWorkers) {
            const providers = $scope.qualifiedWorkers
              .filter(function (item) {
                return item.selected;
              })
              .map(function (item) {
                return { choice_id: item.provider_id };
              });
            const orig = $scope.staffProviderAttrib.value;
            if (orig && orig.length) {
              providers.push(orig);
            }
            $scope.staffProviderAttrib.value = providers;
          }
        } else if ($scope.jobType.selection === 'escalation' && !$scope.jobType.pickListRequested) {
          $scope.formData.push($scope.escalationAttrib);
        }

        if ($scope.proservAttrib) {
          $scope.formData.push($scope.proservAttrib);
        }

        if ($scope.priceAttrib) {
          $scope.formData.push($scope.priceAttrib);
        }

        if ($scope.applyAttrib) {
          $scope.formData.push($scope.applyAttrib);
        }
        // format form data
        $scope.validate();
      }
      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'submit');
    };
    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'cancel');
    };

    // format request
    function formatRequest() {
      const attrib_list = FormatHelperService.format_data($scope.formData);
      let provider_list = [];
      // Only send the provider list parameter on escalations i.e. pick list usage
      if (
        $scope.pickListValid &&
        ($scope.jobType.selection === 'escalation' || ($scope.applyAttrib && $scope.applyAttrib.value))
      ) {
        provider_list = $scope.pickList
          .filter(function (item) {
            return item.selected;
          })
          .map(function (item) {
            return item.provider_id;
          });
      }
      const service_request = {
        type: $scope.requestType,
        attrib_list: attrib_list,
      };
      if (provider_list.length) {
        service_request.provider_list = provider_list;
      }
      const CURRENT_USER = AuthenticationService.getCurrentUser();
      if (CURRENT_USER.user_type === 2) {
        service_request.enterprise_id = $scope.entFilter.enterprise_id;
      }
      submitToServer(service_request);
    }

    // submit request to server
    function submitToServer(service_request) {
      sessionManagerService.submitSessionRequest(service_request).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
          } else {
            AlertService.errorAlert(response.message);
          }
          $scope.formData = {};
          $modalInstance.close();
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $scope.formData = {};
          $modalInstance.close();
        }
      );
    }

    // format attrib array
    function reorderAttribs(attrib_list) {
      const _removedAttribsArray = [];
      angular.forEach(attrib_list, function (attrib, index) {
        switch (attrib.name) {
          case 'escalation_list':
            $scope.escalationAttrib = attrib;
            $scope.escalationAttrib.value = { choice_id: 0 };
            $scope.jobType = { assign: false, escalation: true, selection: 'escalation' };
            $scope.pickListAvailable = true;
            _removedAttribsArray.push(attrib);
            break;
          case 'staff_provider_list':
            const choice_list = attrib.choice_list.filter((choice) => {
              return choice.choice_id != 0;
            });
            attrib.choice_list = choice_list;
            $scope.staffProviderAttrib = attrib;
            $scope.staffProviderAttrib.value = { choice_id: 0 };
            _removedAttribsArray.push(attrib);
            break;
          case 'proserv_list':
            $scope.proservAttrib = attrib;
            $scope.proservAttrib.value = { choice_id: -1 };
            _removedAttribsArray.push(attrib);
            break;
          case 'price_list':
            $scope.priceAttrib = attrib;
            $scope.priceAttrib.value = { choice_id: 0 };
            _removedAttribsArray.push(attrib);
            break;
          case 'interest_flag':
            $scope.applyAttrib = attrib;
            $scope.applyAttrib.value = false;
            _removedAttribsArray.push(attrib);
            break;
        }
      });
      removeAttribFromArray(_removedAttribsArray);
    }
    function removeAttribFromArray(attribsArray) {
      for (let i = 0; i < attribsArray.length; i++) {
        const index = $scope.formData.indexOf(attribsArray[i]);
        $scope.formData.splice(index, 1);
      }
      $scope.originalSortedAttribList = angular.copy($scope.formData);
    }

    // listen for instance_count attrib clicks
    $scope.$on('addAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      const _count = field.instanceCountIndex || 1;
      const _field = angular.copy(
        $scope.originalFormData.filter(function (obj) {
          return obj.attrib_id == field.attrib_id;
        })[0]
      );
      _field.instanceCountIndex = _count + 1;
      _field.showRemoveInstance = true;
      $scope.formData[index].doNotShowAddInstance = true;
      $scope.formData[index].showRemoveInstance = false;
      $scope.formData.splice(index + 1, 0, _field); // add after current field
    });

    $scope.$on('removeAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      $scope.formData[index - 1].doNotShowAddInstance = false;
      $scope.formData[index - 1].showRemoveInstance = true;
      $scope.formData.splice(index, 1);
    });

    $scope.preparePickListPayload = function () {
      $scope.branch = branchFilterService.getSelectedBranch();

      const payload = {
        type: $scope.requestType,
        attrib_list: FormatHelperService.format_data($scope.formData),
      };

      if ($scope.branch && $scope.branch.id !== -1) {
        payload.branch_id = $scope.branch.id;
      }

      return payload;
    };

    getRequestAttribs();
  },
]);
