import $ from 'jquery';
import angular from 'angular';
import module from '../module';
import moment from 'moment';
export default module.controller('AnalyticsController', [
  '$scope',
  '$sce',
  '$localStorage',
  'AlertService',
  'ReportingSectionService',
  'FormatHelperService',
  'EnterpriseFilterService',
  'PENTAHO_URL',
  'TimeFormatService',
  function (
    $scope,
    $sce,
    $localStorage,
    AlertService,
    ReportingSectionService,
    FormatHelperService,
    EnterpriseFilterService,
    PENTAHO_URL,
    TimeFormatService
  ) {
    const URL = PENTAHO_URL + '/pentaho/api/repos/%3Ahome%3Aserveture%3A';

    if ($localStorage.keyAttribute && $localStorage.generalData) {
      const keyAttribName = ($localStorage.keyAttribute || {}).display_name;
      const keyProfessionObj = $localStorage.generalData.filter(function (obj) {
        return obj.data_name === 'key_profession';
      })[0];
      $scope.reportsArray = ReportingSectionService.fetchReportChoices(keyAttribName, keyProfessionObj.data_content);
    } else {
      $scope.reportsArray = ReportingSectionService.fetchReportChoices();
    }

    $scope.pickerOptions = {
      format: TimeFormatService.format('dateSlashTemplate'),
    };

    $scope.customDateRange = false;
    $scope.showSpinner = false;
    $scope.formData = {
      selectedReport: null,
      reportFormat: null,
      dateRangeObj: {},
    };
    $scope.dateRangeChoices = ReportingSectionService.fetchDateRangeChoices();
    $scope.formData.timePeriod = $scope.dateRangeChoices[0];
    $scope.formData.dateRangeObj = ReportingSectionService.fetchDateRange($scope.formData.timePeriod);

    $scope.reportFormatsArray = [
      { type: 'HTML', name: 'table/html;page-mode=stream' },
      { type: 'PDF', name: 'pageable/pdf' },
      { type: 'CSV', name: 'table/csv;page-mode=stream' },
    ];

    // Return Date display for user
    $scope.dateRangeDisplay = function () {
      const tempStart = moment($scope.formData.dateRangeObj.start).clone();
      const tempEnd = moment($scope.formData.dateRangeObj.end).clone();
      const start = FormatHelperService.returnLocalTimeString(tempStart, 'MMM Do, YYYY');
      const end = FormatHelperService.returnLocalTimeString(tempEnd, 'MMM Do, YYYY');
      return start + ' - ' + end;
    };

    // If date drop down slection is changed
    $scope.getDateRangeDisplay = function (timePeriod) {
      if (timePeriod.value === 'custom') {
        $scope.customDateRange = true;
      } else {
        $scope.customDateRange = false;
        $scope.formData.dateRangeObj = ReportingSectionService.fetchDateRange($scope.formData.timePeriod);
      }
    };

    // Calendar date time selection options and controls
    $scope.startDate = { opened: false };
    $scope.openStartDate = function () {
      $scope.startDate.opened = true;
    };

    $scope.endDate = { opened: false };
    $scope.openEndDate = function () {
      $scope.endDate.opened = true;
    };

    $scope.setEndDateMin = function (startDate) {
      $scope.minEndDate = moment(startDate);
      // ensure user does not set end date before start
      if (moment($scope.formData.endDate).isBefore(moment(startDate))) {
        $scope.formData.endDate = startDate;
      }
    };

    // Server Call for report information
    $scope.buildRequestURL = function () {
      if (!$scope.formData || !$scope.formData.selectedReport || !$scope.formData.reportFormat) {
        return;
      }

      $scope.showSpinner = true;
      const constructedURL = URL + $scope.formData.selectedReport.value + '.prpt' + '/generatedContent';

      const dataObj = {
        'output-target': $scope.formData.reportFormat.name,
        fromdate_param: moment
          .utc($scope.formData.dateRangeObj.start)
          .format(TimeFormatService.format('fullDateTimeSecs')),
        todate_param: moment.utc($scope.formData.dateRangeObj.end).format(TimeFormatService.format('fullDateTimeSecs')),
        enterprise_param: [],
      };

      if ($scope.entFilter.enterprise_id == -1) {
        const list = EnterpriseFilterService.fetchEnterpriseList();
        angular.forEach(list, function (ent) {
          if (ent.enterprise_id !== -1) {
            dataObj.enterprise_param.push(ent.enterprise_id);
          }
        });
      } else {
        dataObj.enterprise_param.push($scope.entFilter.enterprise_id);
      }

      if ($scope.formData.reportFormat.type === 'HTML') {
        serverHTMLCall(constructedURL, dataObj);
      } else {
        serverBinaryCall(constructedURL, dataObj, $scope.formData.reportFormat.type);
      }
    };

    function serverHTMLCall(url, dataObj) {
      $.ajax({
        type: 'POST',
        url: url,
        data: dataObj,
        traditional: true,
        error: function (xhr, ajaxOptions, thrownError) {
          AlertService.errorAlert(thrownError);
          $scope.showSpinner = false;
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', 'Basic ' + btoa('serveture-user:guitar'));
        },
        success: function (data, textStatus, jqXHR) {
          const tempData = data.replace(/\/pentaho\/getImage/g, PENTAHO_URL + '/pentaho/getImage');
          $scope.currentReport = $sce.trustAsHtml(tempData);
          $scope.showSpinner = false;
          $scope.$apply();
        },
      });
    }

    function serverBinaryCall(url, dataObj, formatTypeParam) {
      const formatType = formatTypeParam;
      $.ajax({
        type: 'POST',
        url: url,
        data: dataObj,
        dataType: 'binary',
        traditional: true,
        error: function (xhr, ajaxOptions, thrownError) {
          AlertService.errorAlert(thrownError);
          $scope.showSpinner = false;
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', 'Basic ' + btoa('serveture-user:guitar'));
        },
        success: function (data, textStatus, jqXHR) {
          if (formatType === 'CSV') {
            csvResponse(data);
          } else {
            pdfResponse(data);
          }
          $scope.showSpinner = false;
          $scope.$apply();
        },
      });
    }

    function pdfResponse(data) {
      if (window.navigator.msSaveOrOpenBlob) {
        const blob = new Blob([data], { type: 'text/pdf' });
        window.navigator.msSaveBlob(blob);
      } else {
        const reader = new FileReader();
        if (reader) {
          reader.onload = function () {
            const link = $('#reportDwnLink');
            const fixedPdf = reader.result.replace('application/pdf', 'application/octet-stream');
            link.attr({
              download: getFileName(),
              href: $sce.trustAsResourceUrl(fixedPdf),
            });
            link[0].click();
          };
          reader.readAsDataURL(data);
        } else {
          AlertService.errorAlert('Your browser does not support File Reader');
        }
      }
    }

    function csvResponse(data) {
      if (window.navigator.msSaveOrOpenBlob) {
        const blob = new Blob([data], { type: 'text/csv' });
        window.navigator.msSaveBlob(blob);
      } else {
        const reader = new FileReader();
        if (reader) {
          reader.onload = function () {
            const link = $('#reportDwnLink');
            link.attr({
              download: getFileName(),
              href: $sce.trustAsResourceUrl(reader.result),
            });
            link[0].click();
          };
          reader.readAsDataURL(data);
        } else {
          AlertService.errorAlert('Your browser does not support File Reader');
        }
      }
    }

    // Need to get binary response blob for PDFs
    $.ajaxTransport('+binary', function (options, originalOptions, jqXHR) {
      // check for conditions and support for blob / arraybuffer response type
      if (
        window.FormData &&
        ((options.dataType && options.dataType === 'binary') ||
          (options.data &&
            ((window.ArrayBuffer && options.data instanceof ArrayBuffer) ||
              (window.Blob && options.data instanceof Blob))))
      ) {
        return {
          // create new XMLHttpRequest
          send: function (headers, callback) {
            // setup all variables
            // eslint-disable-next-line one-var
            const xhr = new XMLHttpRequest(),
              url = options.url,
              type = options.type,
              async = options.async || true,
              // blob or arraybuffer. Default is blob
              dataType = options.responseType || 'blob',
              data = options.data || null,
              username = options.username || null,
              password = options.password || null;

            xhr.addEventListener('load', function () {
              const data = {};
              data[options.dataType] = xhr.response;
              // make callback and send data
              callback(xhr.status, xhr.statusText, data, xhr.getAllResponseHeaders());
            });

            xhr.open(type, url, async, username, password);

            // setup custom headers
            for (const i in headers) {
              xhr.setRequestHeader(i, headers[i]);
            }

            xhr.responseType = dataType;
            xhr.send(data);
          },
          abort: function () {
            jqXHR.abort();
          },
        };
      }
    });

    function getFileName() {
      const name = `${
        $scope.formData.selectedReport.display_name
      }.${$scope.formData.reportFormat.type.toLocaleLowerCase()}`;
      return name.replace(/ /g, '_');
    }
  },
]);
