import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';

import { AuthenticationService } from '../../services/authentication.service';
import { PageHeaderService } from './page-header.service';
import { branchSelectorAnimation } from './page-header.animation';

import { IUser } from '../../interfaces/IUser';

@Component({
  selector: 'page-header',
  templateUrl: 'page-header.component.html',
  animations: branchSelectorAnimation,
})
export class PageHeaderComponent implements OnInit, OnChanges {

  public currentUser: IUser
  public isBanchSelectorVisible: boolean;
  public isEnterpriseSelectorVisible: boolean;

  @Input() selectedTab: string;
  @Input() moduleName: string;

  constructor(
    private authenticationService: AuthenticationService,
    private pageHeaderService: PageHeaderService
  ) { }

  public ngOnInit() {
    this.currentUser = this.authenticationService.getCurrentUser();
    this.isBanchSelectorVisible = this.pageHeaderService.getBranchSelectorVisibility(this.selectedTab);
    this.isEnterpriseSelectorVisible = this.pageHeaderService.getEnterpriseSelectorVisibility();
  }

  public ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      if (propName === 'selectedTab') {
        this.isBanchSelectorVisible = this.pageHeaderService.getBranchSelectorVisibility(this.selectedTab);
        this.isEnterpriseSelectorVisible = this.pageHeaderService.getEnterpriseSelectorVisibility();
      }
    }
  }
}
