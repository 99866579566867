import { NgModule } from '@angular/core';

import {
  sessionServiceProvider,
  providerServiceProvider,
  enterpriseFilterServiceProvider,
  locationServiceProvider,
  providerManagerServiceProvider,
  modalServiceProvider,
  alertServiceProvider,
} from '../upgraded.providers';
import { WorkerPage } from './pages/worker-page/worker.page';
import { SharedModule } from '../shared/shared.module';
import { WorkerSkillsComponent } from './components/worker-skills/worker-skills.component';
import { WorkerProfilePage } from './pages/profile-page/worker-profile.page';
import { WorkerProfileItemComponent } from './components/worker-profile-item/worker-profile-item.component';
import { WorkerBackProfileComponent } from './components/worker-back-profile/worker-back-profile.component';
import { CurrentWorkPage } from './pages/current-work-page/current-work.page';
import { CalendarEventComponent } from './components/calendar-event/calendar-event.component';
import { WorkerStatsComponent } from './components/worker-stats/worker-stats.component';
import { WorkerStatsPage } from './pages/worker-stats-page/worker-stats.page';
import { EffectsModule } from '@ngrx/effects';
import { WorkersEffect } from './store/effects';
import { VettingStatusComponent } from './components/vetting-status/vetting-status.component';
import { BlacklistPage } from './pages/blacklist-page/blacklist.page';
import { BlacklistedWorkersComponent } from './components/blacklisted-workers/blacklisted-workers.component';
import { WorkersUpgradeDirective } from './components/workers-upgrade/workers-upgrade.directive';
import { WorkersPageComponent } from './pages/workers-page/workers.page';

const PAGES = [WorkerPage, WorkerProfilePage, CurrentWorkPage, WorkerStatsPage, BlacklistPage];
const COMPONENTS = [
  CalendarEventComponent,
  VettingStatusComponent,
  WorkerSkillsComponent,
  WorkerProfileItemComponent,
  WorkerBackProfileComponent,
  WorkerStatsComponent,
  BlacklistedWorkersComponent,
  WorkersUpgradeDirective,
  WorkersPageComponent,
];

@NgModule({
  imports: [SharedModule, EffectsModule.forFeature([WorkersEffect])],
  declarations: [...PAGES, ...COMPONENTS],
  exports: [...PAGES],
  entryComponents: [...PAGES, BlacklistedWorkersComponent],
  providers: [
    sessionServiceProvider,
    providerServiceProvider,
    enterpriseFilterServiceProvider,
    locationServiceProvider,
    providerManagerServiceProvider,
    modalServiceProvider,
    alertServiceProvider,
  ],
})
export class WorkersModule {
  constructor() {}
}
