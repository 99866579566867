import module from '../module';
import * as enums from '../../enums';

export default module.controller('SaveJobLocationModal', [
  '$scope',
  '$modalInstance',
  'passedData',
  'LocationTabService',
  'locationManagerService',
  'enterprise_selection',
  'AlertService',
  function (
    $scope,
    $modalInstance,
    passedData,
    LocationTabService,
    locationManagerService,
    enterprise_selection,
    AlertService
  ) {
    $scope.initialize = function () {
      $scope.location = passedData.location;
      $scope.icons = {
        warning: `${enums.AssetsFolder.svg}invitations/warning.svg`,
      };
    };

    $scope.cancel = function () {
      $modalInstance.close();
    };

    $scope.proceed = function () {
      const stateList = LocationTabService.getStateList();
      const address = LocationTabService.parseLocation(passedData.location.details, stateList);
      address.enterprise_id = enterprise_selection.enterprise_id;
      address.name = address.address_line_1;
      const formatedSubmission = LocationTabService.formatSubmission(address);
      locationManagerService.addNewLocation(formatedSubmission).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
          } else {
            AlertService.errorAlert(response.message);
          }
          console.log(response);
          $modalInstance.close(response);
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    };

    $scope.initialize();
  },
]);
