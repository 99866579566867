import module from '../module';
import Swal from 'sweetalert2';

export default module.controller('RoleTabController', [
  '$scope',
  '$modal',
  'roleManagerService',
  'AuthenticationService',
  'enterpriseListService',
  'PERMISSIONS',
  'EnterpriseFilterService',
  'trackingService',
  function (
    $scope,
    $modal,
    roleManagerService,
    AuthenticationService,
    enterpriseListService,
    PERMISSIONS,
    EnterpriseFilterService,
    trackingService
  ) {
    const CURRENT_USER = AuthenticationService.getCurrentUser();
    $scope.requesterBtnStatus = true;
    $scope.showSpinner = true;
    $scope.reverse = true;

    $scope.addRolePermission = CURRENT_USER.permissionCheck(PERMISSIONS.ADD_ROLE);
    $scope.editRolePermission = CURRENT_USER.permissionCheck(PERMISSIONS.EDIT_ROLE);
    $scope.deleteRolePermission = CURRENT_USER.permissionCheck(PERMISSIONS.DELETE_ROLE);

    // Grabbing from API
    $scope.getRoles = function (roleType) {
      roleManagerService.getAllRoles(roleType).then(function (rolesArray) {
        $scope.roles = rolesArray;
        $scope.entDisplayFilter($scope.roles);
        $scope.showSpinner = false;
      });
    };

    // market_place enterprise filter
    if (CURRENT_USER.user_type == 2) {
      $scope.$watch('entFilter', function () {
        $scope.getRoles();
      });
    } else {
      $scope.getRoles();
    }
    // filter provider view based on the selected enterprise
    $scope.entDisplayFilter = function (rolesArray, enterpriseId) {
      $scope.roles = EnterpriseFilterService.filterAdminApiItems(rolesArray, enterpriseId);
      $scope.showSpinner = false;
    };

    $scope.openRoleModal = function (type, role) {
      let ctrl;
      if (type === 'new') {
        ctrl = 'NewRoleModalController';
      } else if (type === 'edit') {
        ctrl = 'EditRoleModalController';
      }

      const modalInstance = $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/views/admin/role/roleModal.html',
        controller: ctrl,
        size: 'lg',
        backdrop: 'static',
        resolve: {
          enterprise_selection: function () {
            return $scope.entFilter;
          },
          passedRole: function () {
            return role;
          },
        },
      });

      modalInstance.result.then(function () {
        $scope.getRoles();
      });

      trackingService.trackEvent(trackingService.ADMIN, 'roles', type);
    };

    $scope.removeRole = async function (role) {
      const config = {
        title: 'Are you sure?',
        text: 'You are about to delete Role ' + role.name,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Yes, delete Role',
      };

      const returnHandler = function (isConfirm) {
        if (isConfirm) {
          roleManagerService.removeRole(role.id).then(
            async function (response) {
              const configPositive = {
                title: 'Deleted!',
                text: 'Role was deleted',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configPositive);

              $scope.getRoles();
            },
            async function () {
              const configError = {
                title: 'Error',
                text: 'There was a problem deleting the role.',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configError);
            }
          );
        }
      };

      const { value: returnValue } = await Swal.fire(config);
      returnHandler(returnValue);

      trackingService.trackEvent(trackingService.ADMIN, 'roles', 'delete');
    };

    // Table Sorting
    $scope.sort = function (keyname) {
      $scope.sortKey = keyname; // set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; // if true make it false and vice versa
    };
    $scope.sort('name');

    enterpriseListService.getAllEnterprisesSimple().then(function (list) {
      $scope.enterprises = list;
    });
  },
]);
