import module from './module';
import * as enums from '../enums';

export default module.directive('dirPickWorker', [
  function () {
    return {
      restrict: 'E',
      controller: [
        '$scope',
        '$localStorage',
        'providerManagerService',
        'AuthenticationService',
        'branchFilterService',
        function (
          $scope,
          $localStorage,
          providerManagerService,
          AuthenticationService,
          branchFilterService
        ) {
          var CURRENT_USER = AuthenticationService.getCurrentUser();
          $scope.keyProfession = $localStorage.generalData.filter(function (obj) {
            return obj.data_name == 'key_profession';
          })[0];

          $scope.gotoPage = function (page) {
            $scope.selectallSelected = false;

            $scope.currentPage = page;
            $scope.getProviders();
          };

          $scope.updateFilters = function (systemFilters, attribFilters, filterBranch) {
            $scope.filterBranch = filterBranch;
            var STAFF_TYPE = 3,
              STAFF_ONLY = 1,
              CONTRACTORS_ONLY = 2;
            //If the user is marketplace admin, and not selected an enterprise
            //then filter automatically to contractors only
            if (CURRENT_USER.user_type == enums.UserType.MarketPlaceAdmin && !$scope.enterpriseId) {
              systemFilters.push({
                type: STAFF_TYPE,
                options: [CONTRACTORS_ONLY]
              });
              //Else filter to staff only
            } else if ($scope.filterStaff) {
              systemFilters.push({
                type: STAFF_TYPE,
                options: [STAFF_ONLY]
              });
            }
            $scope.currentPage = 1;
            $scope.systemFilters = systemFilters;
            $scope.attribFilters = attribFilters;
            $scope.getProviders();
          };

          $scope.resetState = function () {
            $scope.pageSize = 50;
            $scope.currentPage = 1;
            $scope.sortProperty = {
              field_id: 1,
              descending: false
            };
            $scope.filters = [];
            $scope.systemFilters = [];
            $scope.attribFilters = [];
            $scope.selectedProviders = [];
          };
          $scope.resetState();

          $scope.getProviders = function (requestType) {
            if ($scope.providerList) {
              $scope.providers = $scope.providerList;
            } else {
              const payload = {
                enterprise_id: $scope.enterpriseId,
                page_number: $scope.currentPage,
                page_size: $scope.pageSize,
                order_by: $scope.sortProperty,
                attrib_filters: $scope.attribFilters,
                system_filters: $scope.systemFilters
              };

              if ($scope.filterBranch) {
                let branch_id = -1;

                if (CURRENT_USER.user_type == enums.UserType.MarketPlaceAdmin) {
                  const branch = branchFilterService.getSelectedBranch();
                  branch_id = branch ? branch.id : branch_id;
                } else {
                  branch_id = (CURRENT_USER.branch_info && CURRENT_USER.branch_info.id) || branch_id;
                }

                if (branch_id !== -1) {
                  payload.branch_id = branch_id;
                  delete payload.enterprise_id
                }
              }

              providerManagerService
                .getFilteredProviders(payload)
                .then(function (response) {
                  if ($scope.providers) {
                    const localIds = $scope.providers.map(x => x.provider_id);
                    const newProviders = response.providers.filter(p => localIds.indexOf(p.provider_id) === -1);

                    $scope.providers = $scope.providers.concat(newProviders);
                  } else {
                    $scope.providers = response.providers;
                  }
                  $scope.visibleProviders = response.providers;
                  if (response.totalCount) {
                    $scope.numberOfProviders = response.totalCount;
                  }
                  $scope.showSpinner = false;
                  $scope.setSelectedProviders();
                });
            }
          };

          $scope.setSelectedProviders = function () {
            var selectedIds = $scope.selectedProviders.map(function (item) {
              return item.provider_id;
            });

            $scope.providers.forEach(function (item) {
              if (selectedIds.indexOf(item.provider_id) > -1) {
                item.poolSelected = true;
              }
            });

            if ($scope.visibleProviders) {
              $scope.visibleProviders.forEach(function (item) {
                if (selectedIds.indexOf(item.provider_id) > -1) {
                  item.poolSelected = true;
                }
              });
            }
          };

          function selectedProviders() {
            if ($scope.provider_list) {
              $scope.selectedProviders = $scope.provider_list.map(function (obj) {
                var result = {
                  provider_id: obj.provider_id,
                  full_name: obj.first_name + ' ' + obj.last_name,
                  user_id: obj.user_id
                };
                result.poolSelected = true;
                return result;
              });

              for (var i = 0; i < $scope.selectedProviders.length; i++) {
                var obj = {
                  provider_id: $scope.selectedProviders[i]
                };
                $scope.formData.provider_list.push(obj.provider_id);
              }
              $scope.oldProviderPoolList = $scope.formData.provider_list;
            }
          }

          selectedProviders();
          $scope.$on('Provider_List.Loaded', selectedProviders);

          $scope.sort = function (keyname) {
            $scope.sortProperty.field_id = keyname; //set the sortKey to the param passed
            $scope.sortProperty.descending = !$scope.sortProperty.descending; //if true make it false and vice versa
            $scope.getProviders();
          };

          $scope.poolSelectedUpdate = function (provider) {
            setMoveAllVariables();

            if (provider.poolSelected) {
              $scope.selectedProviders.push(provider);
              return;
            }

            const index = $scope.selectedProviders.findIndex(function (item) {
              return item.provider_id === provider.provider_id;
            });
            $scope.selectedProviders.splice(index, 1);

            const availableProvider = $scope.providers.find(function (item) {
              return item.provider_id === provider.provider_id;
            });
            if (availableProvider) {
              availableProvider.poolSelected = false;
            }
          };

          $scope.onSelectAllChange = function () {
            $scope.selectallSelected = !$scope.selectallSelected;
          };

          $scope.onRemoveAllChange = function () {
            $scope.removeallSelected = !$scope.removeallSelected;
          };

          $scope.onSelectAllAvailable = function () {
            console.log('dirPickWorker onSelectAllAvailable');
            $scope.removeallSelected = false;

            if (!$scope.selectallSelected) {
              $scope.selectallSelected = true;

              if (!$scope.selectedProvidersCache.length) {
                $scope.selectedProvidersCache = angular.copy($scope.selectedProviders);
              }

              $scope.visibleProviders.forEach(
                function (provider) {
                  if (!provider.poolSelected) {

                    provider.poolSelected = true;
                    $scope.selectedProviders.push(provider);
                    $scope.visibleProvidersCache.push(provider);
                  }
                }
              )

              return;
            }

            $scope.selectedProviders = angular.copy($scope.selectedProvidersCache);
            $scope.visibleProviders.forEach(
              function (provider) {
                if ($scope.selectedProviders && $scope.selectedProviders.length) {
                  provider.poolSelected = $scope.selectedProviders.find(prov => provider.provider_id === prov.provider_id) ? true : false;
                } else {
                  provider.poolSelected = false;
                }
              }
            )

            $scope.selectallSelected = false;
          };

          $scope.onRemoveAllSelected = function () {
            console.log('dirPickWorker onRemoveAllSelected');
            if (
              !$scope.removeallSelected &&
              (!$scope.selectedProviders || !$scope.selectedProviders.length) &&
              (!$scope.selectedProvidersCache || !$scope.selectedProvidersCache.length)
            ) {
              return;
            }
            $scope.selectallSelected = false;

            // select remove all
            if (!$scope.removeallSelected) {
              $scope.removeallSelected = true;

              // restore previous selectedProviders state
              const tempCache = angular.copy($scope.selectedProviders);
              $scope.selectedProviders = angular.copy($scope.selectedProvidersCache);
              $scope.selectedProvidersCache = angular.copy(tempCache);

              // match previous selections between selectedProviders and visibleProviders
              $scope.visibleProviders.forEach(
                function (item) {
                  const find = $scope.selectedProviders.find((provider) => item.provider_id === provider.provider_id);
                  if (find) {
                    item.poolSelected = true;
                  } else {
                    item.poolSelected = false;
                  }
                }
              );

              return;
            }

            // deselect remove all
            const tempCache = angular.copy($scope.selectedProviders);
            $scope.selectedProviders = angular.copy($scope.selectedProvidersCache);
            $scope.selectedProvidersCache = angular.copy(tempCache);

            // match selections between selectedProviders and visibleProviders
            $scope.visibleProviders.forEach(
              function (item) {
                $scope.selectedProviders.forEach((provider) => {
                  if (item.provider_id === provider.provider_id) {
                    item.poolSelected = true;
                  }
                });
              }
            );
            $scope.removeallSelected = false;
          };

          function setMoveAllVariables() {
            $scope.selectedProvidersCache = [];
            $scope.visibleProvidersCache = [];
            $scope.selectallSelected = false;
            $scope.removeallSelected = false;
          }

          setMoveAllVariables();
        }
      ],
      templateUrl: 'app/views/templates/pick_worker.html'
    };
  }
]);
