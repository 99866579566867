import { Pipe, PipeTransform } from '@angular/core';
import { Worker, ProfileFieldType } from '../models/worker';

@Pipe({
  name: 'profileField',
})
export class ProfileFieldPipe implements PipeTransform {
  transform(value: Worker, name: string, showConfidential?: boolean) {
    const profileField = value.profile_info.find((x) => x.name === name);
    if (!profileField) {
      return '';
    }

    let result = null;

    switch (profileField.type) {
      case ProfileFieldType.Text:
        result = profileField.display_value;
      case ProfileFieldType.SingleList:
        result = profileField.choice_list?.find((x) => x.selected)?.name;
      default:
        result = profileField.display_value;
    }

    if (profileField.confidential && !showConfidential) {
      if (result && result.replace) {
        return result.replace(/./g, '*');
      }
      return '';
    }

    return result;
  }
}
