import module from './module';

export default module.factory('SplitPriceService', [
  function () {
    const _priceStructures = [
      {
        name: 'Platform',
        value: 'Serveture',
        portion: 'serveture_portion',
        portionType: 'serveture_portion_type',
        rateUnit: 'serveture_rate_unit',
        percentOf: 'serveture_pct_of',
      },
      {
        name: 'MarketPlace',
        value: 'MarketPlace',
        portion: 'marketplace_portion',
        portionType: 'marketplace_portion_type',
        rateUnit: 'marketplace_rate_unit',
        percentOf: 'marketplace_pct_of',
      },
      {
        name: 'Enterprise',
        value: 'Enterprise',
        portion: 'enterprise_portion',
        portionType: 'enterprise_portion_type',
        rateUnit: 'enterprise_rate_unit',
        percentOf: 'enterprise_pct_of',
      },
      {
        name: 'Worker',
        value: 'Service Provider',
        portion: 'service_portion',
        portionType: 'service_portion_type',
        rateUnit: 'service_rate_unit',
        percentOf: 'service_pct_of',
      },
    ];

    function _validateTotalPercentages(formData) {
      const totalItems = _priceStructures
        .filter(function (item) {
          return formData[item.percentOf] === 'Total' && formData[item.portionType] === 'Percent';
        })
        .map(function (item) {
          return formData[item.portion];
        });
      if (totalItems.length > 1) {
        const totalPercent = totalItems.reduce(function (item1, item2) {
          return item1 + item2;
        });

        if (totalPercent !== 100) {
          return false;
        }
      } else if (totalItems.length === 1) {
        return totalItems[0] === 100;
      }
      return true;
    }

    function _validateMinPrice(formData) {
      let success = true;

      function findLowestByUnit(rates, unitId) {
        const filtered = rates.filter(function (rate) {
          return rate.unit === unitId;
        });
        if (!filtered.length) {
          return;
        }
        return filtered.reduce(function (a, b) {
          return a.rate < b.rate ? a : b;
        });
      }

      const dollarItems = _priceStructures.filter(function (item) {
        return formData[item.portionType] === 'Dollar';
      });

      if (dollarItems.length) {
        const rates = formData.trigger_list
          .filter(function (trigger) {
            return trigger.split_flag;
          })
          .map(function (trigger) {
            return {
              unit: trigger.billing_rate_unit_id,
              rate: trigger.billing_rate,
            };
          });

        const mins = {
          1: findLowestByUnit(rates, 1),
          2: findLowestByUnit(rates, 2),
          3: findLowestByUnit(rates, 3),
          4: findLowestByUnit(rates, 4),
          5: findLowestByUnit(rates, 5),
        };

        const currents = {};
        dollarItems.forEach(function (structure) {
          const rate = formData[structure.rateUnit];
          const value = formData[structure.portion];
          if (!currents[rate]) {
            currents[rate] = value;
          } else {
            currents[rate] += value;
          }
        });

        Object.keys(currents).forEach(function (rate) {
          if (mins[rate] && currents[rate] > mins[rate].rate) {
            success = false;
          }
        });
      }
      return success;
    }

    function _removeOrphanedData(formData) {
      _priceStructures.forEach(function (struct) {
        if (!formData[struct.portionType]) {
          delete formData[struct.percentOf];
          delete formData[struct.rateUnit];
          delete formData[struct.portion];
          delete formData[struct.portionType];
        }
      });
    }

    function _validateZeroPrice(formData) {
      function validateNumber(property) {
        return (!property && property !== 0) || (typeof property === 'number' && property > 0);
      }

      const structureValid = !_priceStructures.some(function (struct) {
        return formData[struct.portionType] === 'Dollar' && formData[struct.portion] === 0;
      });
      const triggerValid =
        validateNumber(formData.trigger_list[0].billing_rate) &&
        validateNumber(formData.trigger_list[0].minimum_rate) &&
        validateNumber(formData.trigger_list[0].travel_rate);

      return structureValid;
    }

    return {
      priceStructures: _priceStructures,
      validateTotalPercentages: _validateTotalPercentages,
      validateMinPrice: _validateMinPrice,
      validateZeroPrice: _validateZeroPrice,
      removeOrphanedData: _removeOrphanedData,
    };
  },
]);
