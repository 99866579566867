import angular from 'angular';
import module from '../module';
import moment from 'moment';
import * as enums from '../../enums';

export default module.controller('CheckOutModalController', [
  '$scope',
  '$modalInstance',
  'AlertService',
  'passedJob',
  'passedSession',
  'passedEnt',
  'sessionManagerService',
  'trackingService',
  'timeRuleService',
  'generalDataService',
  'TimeFormatService',
  function (
    $scope,
    $modalInstance,
    AlertService,
    passedJob,
    passedSession,
    passedEnt,
    sessionManagerService,
    trackingService,
    timeRuleService,
    generalDataService,
    TimeFormatService
  ) {
    let _totalServiceTimeAttrib;
    $scope.submitBtnDisbaled = false;
    $scope.title = 'Check Out / Review';
    $scope.keySession = generalDataService.getKeySession();
    const _passedSession = angular.copy(passedSession);
    const _passedEnterprise = angular.copy(passedEnt);
    $scope.checkout = {
      dateTime: moment(),
    };

    sessionManagerService
      .getRealmAttribs(enums.Realms.WorkerReview)
      .then(
        function (response) {
          _totalServiceTimeAttrib = response.attrib_list.filter(function (obj) {
            return obj.name === 'service_total_time';
          })[0];
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.dismiss('cancel');
        }
      )
      .then(function () {
        sessionManagerService.getRealmAttribs(enums.Realms.RequesterReview).then(
          function (response) {
            $scope.reviewAttribs = response.attrib_list;
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
            $modalInstance.dismiss('cancel');
          }
        );
      })
      .then(function () {
        sessionManagerService.setSession(_passedSession.session_id || _passedSession.request_id).then(
          async function (session) {
            $scope.session = session;
            console.log($scope.session);
            $scope.sessionLocation = $scope.session.attrib_list?.find((x) => x.type === 'location')?.value_location;

            $scope.checkout.dateTime = await TimeFormatService.formatTimezone(
              'fullDateTimeSecs',
              moment().format(),
              moment().format(),
              $scope.sessionLocation
            );

            await buildCheckin(session);
            $scope.rules = timeRuleService.getCheckOutRules(session.time_rule_info);
            $scope.rulesViewText = timeRuleService.generateCheckOutViewRules(session.time_rule_info);
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
            $modalInstance.dismiss('cancel');
          }
        );
      });

    async function getDurationDisplay() {
      const sessionNowTime = await TimeFormatService.formatTimezone(
        'fullDateTimeSecs',
        moment().format(),
        moment().format(),
        $scope.sessionLocation
      );

      const totalMinuteDuration = sessionNowTime.diff($scope.checkinMoment, 'm');
      const hourMinuteCount = $scope.duration.hours * 60;
      if (hourMinuteCount < totalMinuteDuration) {
        $scope.duration.minutes = totalMinuteDuration - hourMinuteCount;
      } else {
        $scope.duration.minutes = hourMinuteCount - totalMinuteDuration;
      }
    }
    async function buildCheckin(session) {
      const builtCheckInTimeUtc = moment.utc(session.checkin_date + ' ' + session.checkin_time);

      $scope.checkinMoment = await TimeFormatService.formatTimezone(
        'fullDateTimeSecs',
        builtCheckInTimeUtc,
        builtCheckInTimeUtc,
        $scope.sessionLocation
      );

      const sessionNowTime = await TimeFormatService.formatTimezone(
        'fullDateTimeSecs',
        moment().format(),
        moment().format(),
        $scope.sessionLocation
      );

      $scope.checkInTime = moment($scope.checkinMoment).format('MM/DD/YY HH:mm');
      $scope.duration = {
        minutes: '',
        hours: sessionNowTime.diff($scope.checkinMoment, 'h'),
      };
      await getDurationDisplay();
    }

    $scope.ok = async function () {
      $scope.submitBtnDisbaled = true;
      if (await durationErrorCheck()) {
        const reviewData = sessionManagerService.formatSessionReviewData($scope.session.review);
        const reivewObj = {
          end_type: 1,
          request_id: $scope.session.request_id,
          attrib_list: reviewData,
        };
        sendToServer(reivewObj);
      } else {
        $scope.submitBtnDisbaled = false;
      }
      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'submit');
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'cancel');
    };

    function sendToServer(reivewObj) {
      sessionManagerService.endSessionRequest(reivewObj).then(
        function (response) {
          if (response.success) {
            sessionManagerService.endSessionRequest($scope.checkoutFormData).then(
              function (response) {
                if (response.success) {
                  AlertService.successAlert(response.message);
                  $modalInstance.close();
                } else {
                  AlertService.errorAlert(response.message);
                  $modalInstance.close();
                }
              },
              function (reason) {
                AlertService.serverRequestErrorAlert(reason);
                $modalInstance.dismiss('cancel');
              }
            );
          } else {
            AlertService.errorAlert(response.message);
            $modalInstance.close();
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.dismiss('cancel');
        }
      );
    }

    async function durationErrorCheck() {
      const durationMinutes = $scope.duration.hours * 60 + $scope.duration.minutes;
      if (durationMinutes > _totalServiceTimeAttrib.max_amount) {
        AlertService.errorAlert('Duration cannot exceed ' + _totalServiceTimeAttrib.max_amount / 60 + ' hours');
        return false;
      } else {
        let checkout_datetime = await TimeFormatService.formatTimezone(
          'fullDateTimeSecs',
          moment($scope.checkout.dateTime),
          moment($scope.checkout.dateTime),
          $scope.sessionLocation,
          true
        );

        checkout_datetime = checkout_datetime.utc().format(TimeFormatService.format('fullDateTimeNoSeparator'));

        $scope.checkoutFormData = {
          request_id: $scope.session.request_id,
          end_type: 1,
          for_provider: true,
          attrib_list: [
            {
              attrib_id: _totalServiceTimeAttrib.attrib_id,
              value_count: durationMinutes,
            },
          ],
          checkout_datetime,
        };
        const disabledRules = timeRuleService.getDisabledRules($scope.rules);
        if (disabledRules && disabledRules.length) {
          $scope.checkoutFormData.disabled_time_rules = disabledRules;
        }
        return true;
      }
    }

    $scope.setCheckoutDateTime = async function () {
      const checkoutTime = await TimeFormatService.formatTimezone(
        'fullDateTimeSecs',
        moment($scope.checkout.dateTime),
        moment($scope.checkout.dateTime),
        $scope.sessionLocation,
        true
      );

      if (moment(checkoutTime).isBefore($scope.checkinMoment)) {
        AlertService.warningAlert('Check out date cannot be before check in.');
      } else {
        $scope.duration.hours = moment(checkoutTime).diff($scope.checkinMoment, 'h');
        const totalMinuteDuration = moment(checkoutTime).diff($scope.checkinMoment, 'm');
        const hourMinuteCount = $scope.duration.hours * 60;
        if (hourMinuteCount < totalMinuteDuration) {
          $scope.duration.minutes = totalMinuteDuration - hourMinuteCount;
        } else {
          $scope.duration.minutes = hourMinuteCount - totalMinuteDuration;
        }
      }
    };

    $scope.calculateCheckoutDate = function () {
      const newDate = $scope.checkinMoment.clone();
      newDate.add($scope.duration.minutes, 'm');
      newDate.add($scope.duration.hours, 'h');
      $scope.checkout.dateTime = newDate;
    };
  },
]);
