import module from '../module';

export default module.controller('providerImageModalController', [
  '$scope',
  '$modalInstance',
  function ($scope, $modalInstance) {
    $scope.cancelSmallModal = function () {
      $modalInstance.dismiss('cancel');
    };
  },
]);
