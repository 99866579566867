// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[0]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[1]!../../../node_modules/@fontsource/nunito-sans/index.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".info-page-container {\n  display: flex;\n  gap: 34px;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  margin-top: 30px;\n  color: #404D67;\n  font-family: \"Nunito Sans\";\n  font-size: 20px;\n}\n.info-page-container .logo-section {\n  width: 350px;\n}\n.info-page-container .text-section {\n  margin: 14px 24px;\n  text-align: center;\n}\n.info-page-container .smart-link-button {\n  padding: 24px 14px;\n  background-color: #ffffff;\n  max-width: 325px;\n  display: flex;\n  white-space: pre-wrap;\n  overflow: hidden;\n  border-radius: 16px;\n  color: #2d3941;\n  box-shadow: 3px 8px 6px -6px #b6b6b6;\n}\n.info-page-container .icon {\n  margin-right: 12px;\n  height: 52px;\n  border-radius: 12px;\n}\n.info-page-container .button-text {\n  text-align: center;\n  font-size: 18px;\n}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
