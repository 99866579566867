import module from './module';
import moment from 'moment';
export default module.directive('dirTrackingTimeBar', [
  '$document',
  '$timeout',
  '$interval',
  'TimeFormatService',
  function ($document, $timeout, $interval, TimeFormatService) {
    return {
      restrict: 'E',
      scope: {
        display: '=',
        startTime: '=',
        endTime: '=',
      },
      link: function (scope, element, attr) {
        var tableBodyHeight, topOffset, offSetDisplay, offsetStartTime, hashMinuteCount;

        function setElementAttribs() {
          if (offSetDisplay >= 0) {
            element.css({
              height: tableBodyHeight + 'px',
              left: offSetDisplay + 'px',
              position: 'absolute',
              borderRight: '1px solid #E51937',
              zIndex: '100',
              top: topOffset + 'px',
              display: 'initial',
            });
          }
        }

        function setAttribs() {
          var _tableBody = $('#time-table-body');
          tableBodyHeight = _tableBody.height();
          topOffset = $('#schedule-table-head').height();
          var _tableBodyWidth = _tableBody.width();
          var _columnLabelWidth = $('#time-table-label-column').width();
          var _rowBodyWidth = _tableBodyWidth - _columnLabelWidth;
          var _hashMarks = _rowBodyWidth / hashMinuteCount;
          var _timeBarStart = moment();
          var _timeDiff = _timeBarStart.diff(offsetStartTime, 'm');
          offSetDisplay = _timeDiff * _hashMarks + _columnLabelWidth + 10;
          setElementAttribs();
        }

        //need to get starting range either day or week
        function setMinuteHashMark() {
          if (scope.display == 'week') {
            offsetStartTime = moment().startOf('week');
            hashMinuteCount = 10080;
          } else {
            offsetStartTime = moment().startOf('day');
            hashMinuteCount = 1440;
          }
          $timeout(function () {
            setAttribs();
          }, 300);
        }

        window.addEventListener('resize', setAttribs);

        //update dotted time bar line ever 10 minutes
        $interval(function () {
          currentDayOrWeekInView() ? setMinuteHashMark() : '';
        }, 600000);

        scope.$watch(
          function () {
            return $('#time-table-body').height();
          },
          function (newVal, oldVal) {
            if (newVal != oldVal) {
              $timeout(function () {
                currentDayOrWeekInView() ? setMinuteHashMark() : element.css({ display: 'none' });
              }, 300);
            }
          }
        );

        scope.$watch('startTime', function (newVal, oldVal) {
          if (newVal != oldVal) {
            currentDayOrWeekInView() ? setMinuteHashMark() : element.css({ display: 'none' });
          }
        });

        scope.$watch('endTime', function (newVal, oldVal) {
          if (newVal != oldVal) {
            currentDayOrWeekInView() ? setMinuteHashMark() : element.css({ display: 'none' });
          }
        });

        function currentDayOrWeekInView() {
          var currentDay = moment();
          var displayValue;
          if (scope.display == 'day') {
            displayValue = currentDay.isSame(moment(scope.startTime, TimeFormatService.format('api')), 'd');
          } else {
            displayValue = currentDay.isBetween(
              moment(scope.startTime, TimeFormatService.format('api')),
              moment(scope.endTime, TimeFormatService.format('api'))
            );
          }
          return displayValue;
        }
      }, //link
    };
  },
]);
