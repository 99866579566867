import module from './module';
import moment from 'moment';

export default module.factory('OverlapService', [
  'FormatHelperService',
  'TimeFormatService',
  function (FormatHelperService, TimeFormatService) {
    let overlapId = 0;

    function sortByStartTime(a, b) {
      return FormatHelperService.returnSortedValue(moment(b.start_time), moment(a.start_time));
    }

    function buildOverlapSesisons(sessionList) {
      sessionList.sort(sortByStartTime);

      for (let i = 0; i < sessionList.length - 1; i++) {
        for (let k = i + 1; k < sessionList.length; k++) {
          const firstSession = sessionList[i];
          const secondSession = sessionList[k];
          if (firstSession.overlap_id || secondSession.overlap_id) {
            continue;
          }
          if (firstSession.start.isBefore(secondSession.end) && secondSession.start.isBefore(firstSession.end)) {
            const width = moment(firstSession.end).diff(secondSession.start, 'm');
            if (sessionList.find((x) => x.overlap_id && x.start === firstSession.start && x.duration === width)) {
              continue;
            }

            const overlapObj = {
              displayValue: true,
              attrib_list: [],
              child: firstSession.child,
              duration: width,
              start: firstSession.start,
              state: { name: 'overlap' },
              firstSessionState: firstSession.state,
              secondSessionState: secondSession.state,
              overlap_id: 'overlap' + overlapId++,
              session_ids: [firstSession.session_id, secondSession.session_id],
            };
            sessionList.push(overlapObj);
          }
        }
      }
    }

    function addToNextGroup(groups, target) {
      for (var i = 0; i < groups.length; i++) {
        let hasOverlap = false;
        for (let k = 0; k < groups[i].length; k++) {
          const current = groups[i][k];
          if (current.start.isBefore(target.end) && target.start.isBefore(current.end)) {
            hasOverlap = true;
            break;
          }
        }

        if (!hasOverlap) {
          groups[i].push(target);
          return;
        }
      }

      if (!groups[i]) {
        groups[i] = [];
      }

      groups[i].push(target);
    }

    function cloneJobsForOverlappingSessions(job_list, currentProvider) {
      const jobsToRemove = [];
      job_list.forEach((job, index) => {
        job.session_list.sort(sortByStartTime);
        const groups = [];
        for (let i = 0; i < job.session_list.length; i++) {
          if (job.session_list[i].overlap_id) {
            continue;
          }
          addToNextGroup(groups, job.session_list[i]);
        }

        if (groups.length) {
          jobsToRemove.push(index);

          groups.forEach((group) => {
            const newJob = { ...job };
            newJob.session_list = group;
            if (!newJob.bundled) {
              newJob.total_workers = newJob.session_list.filter((x) => x.displayValue).length;
              newJob.number_candidates = newJob.session_list
                .map((x) => x.number_candidates)
                .filter((x) => !isNaN(x))
                .reduce((a, b) => a + b, 0);
            }
            job_list.push(newJob);
          });
        }
      });

      if (jobsToRemove.length) {
        for (let i = job_list.length - 1; i >= 0; i--) {
          if (jobsToRemove.indexOf(i) > -1) {
            job_list.splice(i, 1);
          }
        }
      }
    }

    function hasOverlappingSessions(session_list) {
      const buildSession = (session) => {
        const start = moment.utc(`${session.request_date} ${session.request_time}`);
        const end = start.clone().add(session.duration, 'minutes');

        return {
          start,
          end,
        };
      };

      for (let i = 0; i < session_list.length - 1; i++) {
        for (let k = i + 1; k < session_list.length; k++) {
          const firstSession = buildSession(session_list[i]);
          const secondSession = buildSession(session_list[k]);

          if (firstSession.start.isBefore(secondSession.end) && secondSession.start.isBefore(firstSession.end)) {
            return true;
          }
        }
      }
      return false;
    }

    return {
      buildOverlapSesisons,
      cloneJobsForOverlappingSessions,
      hasOverlappingSessions,
    };
  },
]);
