import { Action } from '@ngrx/store';
import { IBranchList, IEnterpriseList, IEnterpriseSelection, IUser } from '../interfaces';

export const FETCH_BRANCH_LIST = '[Common] Fetch branch list';
export const BRANCH_LIST_FETCHED = '[Common] Branch list fetched';

export const ENTERPRISE_LIST_FETCHED = '[Common] Enterprise list fetched';

export const FETCH_ENTERPRISE_LIST = '[Common] Fetch enterprise list';

export const SET_SELECTED_ENTERPRISE = '[Common] Set selected enterprise';
export const SET_SELECTED_BRANCH = '[Common] Set selected branch';

export const FETCH_REALM_ATTRIBS = '[Common] Fetch realm attribs';
export const REALM_ATTRIBS_FETCHED = '[Common] Realm attribs fetched';
export const REALM_ATTRIBS_ERROR = '[Common] Realm attribs error';
export const CLEAR_REALM_ATTRIB = '[Common] Clear realm attrib';

export const ADD_NEW_ENTERPRISE = '[Common] Add new enterprise';
export const UPDATE_ENTERPRISE_NAME = '[Common] Update enterprise name';
export const DELETE_ENTERPRISE = '[Common] Delete enterprise';

export const INIT_WEBSOCKET_MESSAGES = '[Common] Init Websocket Messages';
export const SET_WEBSOCKET_MESSAGE_FOR_ENTERPRISE = '[Common] Set Websocket Message For Enterprise';

export class FetchBranchList implements Action {
  public readonly type = FETCH_BRANCH_LIST;
}

export class BranchListFetched implements Action {
  public readonly type = BRANCH_LIST_FETCHED;

  constructor(public result: IBranchList[]) {}
}

export class FetchEnterpriseList implements Action {
  public readonly type = FETCH_ENTERPRISE_LIST;
}

export class EnterpriseListFetched implements Action {
  public readonly type = ENTERPRISE_LIST_FETCHED;

  constructor(public result: IEnterpriseList[]) {}
}

export class SetSelectedEnterprise implements Action {
  public readonly type = SET_SELECTED_ENTERPRISE;

  constructor(public selection: IEnterpriseSelection, public updateBranch?: boolean) {}
}

export class SetSelectedBranch implements Action {
  public readonly type = SET_SELECTED_BRANCH;

  constructor(public selection: any, public updateEnterprise?: boolean) {}
}

export class UpdateEnterpriseName implements Action {
  public readonly type = UPDATE_ENTERPRISE_NAME;

  constructor(public id: number, public newName: string) {}
}

export class AddNewEnterprise implements Action {
  public readonly type = ADD_NEW_ENTERPRISE;

  constructor(public enterprise: IEnterpriseList) {}
}

export class DeleteEnterprise implements Action {
  public readonly type = DELETE_ENTERPRISE;

  constructor(public id: number) {}
}

export class FetchRealmAttribs implements Action {
  public readonly type = FETCH_REALM_ATTRIBS;

  constructor(public realmId: number, public enterpriseId: number) {}
}

export class RealmAttribsFetched implements Action {
  public readonly type = REALM_ATTRIBS_FETCHED;

  constructor(public realmId: number, public enterpriseId: number, public attribs: any[]) {}
}

export class RealmAttribsError implements Action {
  public readonly type = REALM_ATTRIBS_ERROR;

  constructor(public realmId: number, public enterpriseId: number, public error: any) {}
}

export class ClearRealmAttrib implements Action {
  public readonly type = CLEAR_REALM_ATTRIB;

  constructor(public realmId: number) {}
}

export class InitWebsocketMessages implements Action {
  public readonly type = INIT_WEBSOCKET_MESSAGES;

  constructor(public enterprises: number[]) {}
}

export class SetWebsocketMessageForEnterprise implements Action {
  public readonly type = SET_WEBSOCKET_MESSAGE_FOR_ENTERPRISE;

  constructor(public enterpriseId: number, public message: unknown) {}
}

export type Actions =
  | BranchListFetched
  | EnterpriseListFetched
  | SetSelectedEnterprise
  | FetchBranchList
  | SetSelectedBranch
  | FetchRealmAttribs
  | RealmAttribsFetched
  | RealmAttribsError
  | ClearRealmAttrib
  | UpdateEnterpriseName
  | AddNewEnterprise
  | DeleteEnterprise
  | InitWebsocketMessages
  | SetWebsocketMessageForEnterprise;
