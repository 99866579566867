import module from './module';
export default module
  .directive('dirMyCurrentTime', ['$interval', 'dateFilter', function ($interval, dateFilter) {

    function link(scope, element, attrs) {
      var format, timeoutId;

      function updateTime() {
        element.text(dateFilter(new Date(), 'MMMM dd, yyyy HH:mm:ss'));
      }
      if (attrs.myCurrentTime) {
        scope.$watch(attrs.myCurrentTime, function (value) {
          format = value;
          updateTime();
        });
      } else if (attrs.dirMyCurrentTime) {
        scope.$watch(attrs.dirMyCurrentTime, function (value) {
          format = value;
          updateTime();
        });
      }

      element.on('$destroy', function () {
        clearInterval(timeoutId);
      });

      // start the UI update process; save the timeoutId for canceling
      timeoutId = setInterval(function () {
        updateTime(); // update DOM
      }, 1000);
    }

    return {
      link: link
    };
  }]);
