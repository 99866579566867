import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { FinancialBaseWidget } from './financial.base.widget';
import * as fromRoot from '../../store';
import { FilterReportList } from '../../store/actions';

@Component({
  selector: 'financial-location-component',
  templateUrl: './financial.widget.template.html'
})
export class FinancialLocationComponent extends FinancialBaseWidget {
  public titleValue: string;
  constructor(store: Store<fromRoot.State>) {
    super(store);
  }

  loadDataTemplate(list) {
    if (!list) {
      return;
    }
    let hash = {};
    let mapped = list.map(item => {
      return {
        location: item.to_location.name,
        billable: item.total_cost
      };
    });

    mapped.forEach(element => {
      if (hash[element.location]) {
        hash[element.location] += element.billable;
      } else {
        hash[element.location] = element.billable;
      }
    });

    this.data = Object.keys(hash).map(key => {
      return {
        name: key,
        value: parseInt(hash[key])
      };
    });

    this.data.sort((a, b) => b.value - a.value);

    this.titleValue = this.data.length && `$${this.data.map(x => x.value).reduce((a, b) => a + b)}`;
  }

  filterAll() {
    let action = new FilterReportList({
      showAll: true
    });
    this.store.dispatch(action);
  }

  filterData(data) {
    let action = new FilterReportList({
      location: data.name
    });
    this.store.dispatch(action);
  }
}
