import { Injectable, Injector } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as LocalActions from './actions';
import { mergeMap, catchError, switchMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { EventService } from '../../shared/services/event.service';
import { BlacklistService } from '../../shared/services/blacklist.service';
import Swal from 'sweetalert2';

@Injectable()
export class WorkersEffect {
  constructor(
    private actions$: Actions,
    private injector: Injector,
    private eventService: EventService,
    private blacklistService: BlacklistService
  ) {}

  private _svc;

  get svc() {
    if (!this._svc) {
      this._svc = this.injector.get('ProviderManagerService');
    }
    return this._svc;
  }

  fetchWorkers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocalActions.FETCH_WORKERS),
      mergeMap(async (action: LocalActions.FetchWorkers) => {
        const result = await this.svc.getFilteredProviders(action.payload);
        return new LocalActions.WorkersFetched(result.providers, result);
      }),
      catchError(() => of({ type: 'none' }))
    )
  );

  fetchWorkerDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocalActions.FETCH_WORKER_DETAILS),
      mergeMap(async (action: LocalActions.FetchWorkerDetails) => {
        const result = await this.svc.getProvider(action.userId);
        return new LocalActions.WorkerDetailsFetched(result, action.providerId || result.providerId);
      })
    )
  );

  fetchEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocalActions.FETCH_EVENTS),
      mergeMap((action: LocalActions.FetchEvents) => {
        return this.eventService
          .fetchEvents(action.payload.date, action.payload.view, action.payload.providerId)
          .pipe(switchMap((result) => of(new LocalActions.EventsFetched(result))));
      })
    )
  );

  fetchWorkerBlacklist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocalActions.FETCH_WORKER_BLACKLIST),
      mergeMap((action: LocalActions.FetchWorkerBlacklist) => {
        return this.blacklistService
          .getWorkerBlacklist(action.payload.userId)
          .pipe(switchMap((result) => of(new LocalActions.WorkerBlacklistFetched(action.payload.userId, result))));
      })
    )
  );

  updateWorkerBlacklist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocalActions.UPDATE_WORKER_BLACKLIST),
      mergeMap((action: LocalActions.UpdateWorkerBlacklist) => {
        return this.blacklistService.updateWorkerBlacklist(action.userId, action.enterprises).pipe(
          switchMap((result) => {
            Swal.fire(
              result.success ? 'Blocklist updated' : 'Error updating blocklist',
              '',
              result.success ? 'success' : 'error'
            );

            if (result.success) {
              return [new LocalActions.FetchWorkerBlacklist({ userId: action.userId })];
            } else {
              return [];
            }
          })
        );
      })
    )
  );

  fetchEnterpriseBlacklist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocalActions.FETCH_ENTERPRISE_BLACKLIST),
      mergeMap((action: LocalActions.FetchEnterpriseBlacklist) => {
        return this.blacklistService
          .getEnterpriseBlacklist(action.enterpriseId)
          .pipe(switchMap((result) => of(new LocalActions.EnterpriseBlacklistFetched(action.enterpriseId, result))));
      })
    )
  );

  logConfidentialView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocalActions.LOG_Confidential_VIEW),
        map((action: LocalActions.LogConfidentialView) => {
          this.svc.logConfidentialView(action.attrib);
        })
      ),
    { dispatch: false }
  );

  updateEnterpriseBlacklist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocalActions.UPDATE_ENTERPRISE_BLACKLIST),
      mergeMap((action: LocalActions.UpdateEnterpriseBlacklist) => {
        return this.blacklistService.updateEnterpriseBlacklist(action.enterpriseId, action.workers).pipe(
          switchMap((result) => {
            Swal.fire(
              result.success ? 'Blocklist updated' : 'Error updating blocklist',
              '',
              result.success ? 'success' : 'error'
            );

            if (result.success) {
              return [new LocalActions.FetchEnterpriseBlacklist(action.enterpriseId)];
            } else {
              return [];
            }
          })
        );
      })
    )
  );
}
