import {
  Component,
  ComponentRef,
  OnInit,
  OnDestroy,
  ComponentFactoryResolver,
  Input,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromRoot from '../store';

import { BaseWidget } from './base.widget';

import { WidgetListService } from '../services/';

@Component({
  selector: 'dynamic-widget-component',
  templateUrl: './dynamic.widget.component.html'
})
export class DynamicWidgetComponent implements OnInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef, static: true })
  container: ViewContainerRef;
  public dragMode: boolean;
  private componentRef: ComponentRef<{}>;
  @Input() widgetData;
  @Input() isDetail;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private widgetListService: WidgetListService,
    private store: Store<fromRoot.State>
  ) { }

  ngOnInit() {
    let componentType = <any>this.widgetListService.getWidgetType(this.widgetData.name);
    let factory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    this.componentRef = this.container.createComponent(factory);
    (<BaseWidget>this.componentRef.instance).widgetData = this.widgetData;

    this.store.select(fromRoot.getDragMode).subscribe(dragMode => (this.dragMode = dragMode));
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
