import module from '../../module';
import * as enums from '../../../enums';
import Swal from 'sweetalert2';

export default module.controller('CommunicationsTabController', [
  '$scope',
  '$modal',
  'communicationsService',
  'AuthenticationService',
  'PERMISSIONS',
  function ($scope, $window, communicationsService, AuthenticationService, PERMISSIONS) {
    $scope.initialize = function () {
      const CURRENT_USER = AuthenticationService.getCurrentUser();

      $scope.listPermission = CURRENT_USER.permissionCheck(PERMISSIONS.CUSTOM_COMM_LIST);
      $scope.emailPermission = CURRENT_USER.permissionCheck(PERMISSIONS.CUSTOM_COMM_EMAIL);
      $scope.textPermission = CURRENT_USER.permissionCheck(PERMISSIONS.CUSTOM_COMM_TEXT);
      $scope.pushPermission = CURRENT_USER.permissionCheck(PERMISSIONS.CUSTOM_COMM_PUSH);

      $scope.icons = {
        default: `${enums.AssetsFolder.svg}emails/default.svg`,
        custom: `${enums.AssetsFolder.svg}emails/custom.svg`,
        template: `${enums.AssetsFolder.svg}emails/template.svg`,
        notification: `${enums.AssetsFolder.svg}emails/notification.svg`,
        textMessage: `${enums.AssetsFolder.svg}emails/sms.svg`,
      };

      if (CURRENT_USER.user_type !== 2 || !$scope.listPermission) {
        $window.location.reload();
      }

      $scope.getTemplates();
    };

    $scope.selectTemplate = function (type, id) {
      if (type === 'email' && !$scope.emailPermission) {
        return;
      }
      if (type === 'textMessage' && !$scope.textPermission) {
        return;
      }
      if (type === 'notification' && !$scope.pushPermission) {
        return;
      }
      $scope.selectedType = type;
      $scope.selectedId = id;

      if (type === null && id === null) {
        $scope.getTemplates();
      }
    };

    $scope.toggleClicked = async function (e, template, type) {
      e.preventDefault();
      e.stopImmediatePropagation();
      if (type === 'email' && !$scope.emailPermission) {
        template.enabled = !template.enabled;
        return;
      }
      if (type === 'text message' && !$scope.textPermission) {
        template.enabled = !template.enabled;
        return;
      }
      if (type === 'notification' && !$scope.pushPermission) {
        template.enabled = !template.enabled;
        return;
      }

      template.enabled = !template.enabled;
      const isEnabled = template.enabled;

      const configPositive = {
        title: 'Warning',
        text: isEnabled
          ? `You are about to stop this ${type} from being sent. Are you sure?`
          : `You are about to start sending this ${type} when the conditions are met. Are you sure?`,
        confirmButtonText: isEnabled ? 'Yes, stop sending' : 'Yes, start sending',
        cancelButtonText: 'Cancel',
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
      };
      const { isConfirmed } = await Swal.fire(configPositive);
      if (isConfirmed) {
        if (type === 'email') {
          await communicationsService.toggleEnabled(template, !isEnabled);
        } else if (type === 'text message') {
          await communicationsService.toggleTextMessageEnabled(template.type, !isEnabled);
        } else if (type === 'notification') {
          await communicationsService.toggleNotificationEnabled(template, !isEnabled);
        }

        $scope.getTemplates();
      }
    };

    $scope.getTemplates = function () {
      communicationsService.getTemplates().then(function (templates) {
        $scope.templates = templates;
      });

      communicationsService.getTextMessages().then((textMessages) => {
        $scope.textMessages = textMessages;
      });

      communicationsService.getNotifications().then((notifications) => {
        $scope.notifications = notifications;
      });
    };

    $scope.initialize();
  },
]);
