import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
} from '@angular/core';
import { Worker } from '../../../shared/models/worker';
import { EventService } from '../../../shared/services/event.service';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './worker-stats.component.html',
  styleUrls: ['./worker-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-worker-stats',
})
export class WorkerStatsComponent implements OnInit {
  @Input() worker: Worker;
  @Input() providerId: number;

  public stats$: Observable<any>;

  constructor(private eventService: EventService) {}

  ngOnInit() {
    this.stats$ = this.eventService.getRequests(this.providerId);
  }
}
