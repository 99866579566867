import module from '../module';
import Swal from 'sweetalert2';

export default module.controller('UserTabController', [
  '$scope',
  '$modal',
  'adminUserService',
  'AuthenticationService',
  'PERMISSIONS',
  'EnterpriseFilterService',
  'branchFilterService',
  'trackingService',
  function (
    $scope,
    $modal,
    adminUserService,
    AuthenticationService,
    PERMISSIONS,
    EnterpriseFilterService,
    branchFilterService,
    trackingService
  ) {
    'use strict';
    $scope.initialize = function () {
      const CURRENT_USER = AuthenticationService.getCurrentUser();
      $scope.isMarketplace = false;
      $scope.showSpinner = true;
      $scope.reverse = false;
      $scope.order_by = 1;
      $scope.currentPage = 1;
      $scope.pageSize = 25;

      $scope.addUserPermission = CURRENT_USER.permissionCheck(PERMISSIONS.ADD_USER);
      $scope.editUserPermission = CURRENT_USER.permissionCheck(PERMISSIONS.EDIT_USER);
      $scope.deleteUserPermission = CURRENT_USER.permissionCheck(PERMISSIONS.DELETE_USER);
      $scope.logoutUserPermission = CURRENT_USER.permissionCheck(PERMISSIONS.LOGOUT_USER);

      $scope.isLinkedToBold = CURRENT_USER.is_linked_to_bold;

      if (CURRENT_USER.user_type == 2 || CURRENT_USER.user_type == 6) {
        $scope.$watch('entFilter', function (newVal) {
          $scope.isMarketplace = newVal.enterprise_id === -1;
          $scope.resetPageInfo();
          $scope.getUsers();
        });
      } else {
        $scope.getUsers();
      }
    };

    $scope.openUserModal = function (type, user) {
      let ctrl;
      if (type === 'new') ctrl = 'NewUserModalController';
      else if (type === 'edit') {
        ctrl = 'EditUserModalController';
      }

      const modalInstance = $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl:
          type === 'new' ? 'app/views/admin/user/inviteUserModal.html' : 'app/views/admin/user/userModal.html',
        controller: ctrl,
        backdrop: 'static',
        size: 'lg',
        resolve: {
          enterprise_selection: () => $scope.entFilter,
          branch_selection: () => branchFilterService.getSelectedBranch(),
          passedUser: () => user,
        },
      });
      modalInstance.result.then(function () {
        $scope.getUsers();
      });
      trackingService.trackEvent(trackingService.ADMIN, 'users', type);
    };

    $scope.openInvitationsModal = function () {
      $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/views/admin/user/invitationsModal.html',
        controller: 'InvitationsModalController',
        backdrop: 'static',
        size: 'lg',
      });
    };

    $scope.removeUser = async function (user) {
      const config = {
        title: 'Are you sure?',
        text: 'You are about to deactivate User ' + user.full_name,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Yes, deactivate User',
      };

      const returnHandler = function (isConfirm) {
        if (isConfirm) {
          adminUserService.deactivateUser(user.id).then(
            async function () {
              const configPositive = {
                title: 'Deactivated!',
                text: 'User was deactivated',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configPositive);

              $scope.getUsers();
            },
            async function () {
              const configError = {
                title: 'Error',
                text: 'There was a problem deactivating the user.',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configError);
            }
          );
        }
      };

      const { value: returnValue } = await Swal.fire(config);
      returnHandler(returnValue);

      trackingService.trackEvent(trackingService.ADMIN, 'users', 'delete');
    };

    $scope.logoutUser = async function (user) {
      const config = {
        title: 'Are you sure?',
        text: 'You are about to log out User ' + user.full_name,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Yes, log out User',
      };

      const returnHandler = function (isConfirm) {
        if (isConfirm) {
          adminUserService.logoutUser(user.id).then(
            async function () {
              const configPositive = {
                title: 'Logged out!',
                text: 'User was logged out',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configPositive);

              $scope.getUsers();
            },
            async function () {
              const configError = {
                title: 'Error',
                text: 'There was a problem logging out the user.',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configError);
            }
          );
        }
      };

      const { value: returnValue } = await Swal.fire(config);
      returnHandler(returnValue);
    };

    $scope.$on('BranchSelectionChanged', () => {
      $scope.resetPageInfo();
      $scope.getUsers();
    });

    $scope.getUsers = function () {
      const currentBranch = branchFilterService.getSelectedBranch();
      const currentBranchId = currentBranch && currentBranch.id;
      $scope.showSpinner = true;
      const payload = {
        page: $scope.currentPage,
        size: $scope.pageSize,
        order_by: $scope.order_by,
        descending: $scope.reverse,
        ent_id: $scope.entFilter.enterprise_id,
        branch_id: currentBranchId,
      };
      adminUserService.getAllAdminUsers(payload).then(function (response) {
        if (response) {
          $scope.users = response.results;
          $scope.numberOfUsers = response.count;
        }
        $scope.showSpinner = false;
      });
    };

    $scope.sort = function (orderKey) {
      $scope.order_by = orderKey; // set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; // if true make it false and vice versa
      $scope.getUsers();
    };
    $scope.gotoPage = function (page) {
      $scope.currentPage = page;
      $scope.getUsers();
    };
    $scope.resetPageInfo = function () {
      $scope.currentPage = 1;
      $scope.pageSize = 25;
    };
    $scope.initialize();
  },
]);
