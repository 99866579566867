import module from './module';
import moment from 'moment';

export default module.factory('WebsocketService', [
  '$localStorage',
  '$rootScope',
  '$timeout',
  'AlertService',
  'WS_URL',
  'NgrxStore',
  function ($localStorage, $rootScope, $timeout, AlertService, WS_URL, NgrxStore) {
    let errorCounter = 0;

    const notificationMessages = [
      'NEW_REQUEST',
      'REQ_CANCEL_REQUEST',
      'PRO_CANCEL_REQUEST',
      'REQ_COMPLETE_REQUEST',
      'ADMIN_CANCEL_REQUEST',
      'EXPIRED_REQUEST',
      'COMMITTED_REQUEST',
      'APPROVED_REQUEST',
      'REJECTED_REQUEST',
      'PROVIDER_CHECKIN',
      'PRO_COMPLETE_REQUEST',
      'ADMIN_PROVIDER_CHECKIN',
      'ADMIN_PROVIDER_CHECKOUT',
      'CHANGE_ASSIGNED_REQUEST',
      'NEW_ASSIGNED_SCHEDULED_REQUEST',
      'NEW_ASSIGNED_NOW_REQUEST',
      'NEW_ASSIGNED_JOB',
      'NEW_OPEN_JOB',
      'NEW_SCHEDULED_JOB',
      'CHANGE_VERIFY_REQUEST',
      'REQUEUE_COMMITTED_REQUEST',
      'REQUEUE_CANCELLED_REQUEST',
      'JOBLEVEL_CANCEL',
      'ADMIN_REMOVE_REQUEST',
      'ADMIN_REMOVE_JOB',
      'ESCALATE_COMMITTED_REQUEST',
      'COMMITTED_JOBORDER',
      'REQUEUE_CANCELLED_JOBORDER',
      'REQUEUE_COMMITTED_JOBORDER',
      'TRIGGER_CALENDAR_REFRESH',
      'JOBLEVEL_WORKERCOUNT_UPDATE',
      'JOBLEVEL_WORKERCOUNT_COMPLETE',
      'INTEREST_OFFER_REQUEST',
      'NEW_INTEREST_JOBORDER',
      'INTEREST_PERIOD_OVER_JOBORDER',
      'NO_INTEREST_REQUEST',
      'NO_INTEREST_JOBORDER',
      'NEW_INTEREST_REQUEST',
      'REMOVE_INTEREST_REQUEST',
      'REMOVE_INTEREST_JOBORDER',
      'INTEREST_OFFER_JOBORDER',
    ];

    const checkInMessages = ['ADMIN_PROVIDER_CHECKIN', 'PROVIDER_CHECKIN'];
    let ws;

    // do not need to keep provider location update messages
    function notificationDisplayCheck(messageAction) {
      if (notificationMessages.indexOf(messageAction) !== -1) {
        return true;
      } else {
        return false;
      }
    }

    function providerCheckInCheck(messageAction) {
      if (checkInMessages.indexOf(messageAction) !== -1) {
        return true;
      } else {
        return false;
      }
    }

    const current_user = $localStorage.current_user;
    const collection = [];

    const openSocket = function (socket_ent_id) {
      const socket_url = WS_URL + 'ent' + socket_ent_id + '?subscribe-broadcast';
      ws = new WebSocket(socket_url);
      ws.onopen = function () {
        errorCounter = 0;
      };
      ws.onclose = function () {
        ws.onerror = null;
      };
      ws.onerror = function () {
        if (errorCounter < 10) {
          $timeout(function () {
            console.log('retrying connection');
            errorCounter++;
            openSocket(socket_ent_id);
          }, 10000);
        } else {
          AlertService.errorAlert('Currently unable to receive push updates from the server.');
        }
      };
      ws.onmessage = function (e) {
        const message = JSON.parse(e.data);
        // override message for testing only
        // message = {
        //   action: 'NEW_VERSION_RELEASED',
        //   title: 'Would you like to reload the page?',
        //   text: 'A new version is released, you should reload the page to use it.',
        //   confirmButtonText: 'Yes, reload',
        //   cancelButtonText: 'No',
        //   headerMessage: 'Reload to use the new version!',
        // }

        if (notificationDisplayCheck(message.action)) {
          $rootScope.$broadcast('NOTIFICATION_UPDATE', message);
          localStorageMessageTimeCheck(message, socket_ent_id);
        } else {
          $rootScope.$broadcast(message.action, message);
        }

        if (providerCheckInCheck(message.action)) {
          $rootScope.$broadcast('STATUS_CHANGED_TO_ACTIVE', message);
        }
      };
    };
    // Set Websocket URL
    if (current_user) {
      if (current_user.user_type == 3 || current_user.user_type == 5) {
        openSocket(current_user.enterprise_info.id.toString());
        collection.push({ enterprise_id: current_user.enterprise_info.id, messages: [] });
      }
    }

    // only keep last 24 hours of messages in localStorage
    function localStorageMessageTimeCheck(message, socketEntId) {
      message.timeStamp = moment();

      const hourDiff = moment().diff(moment(message.timeStamp), 'hours');
      if (hourDiff <= 2) {
        NgrxStore.dispatch({
          type: '[Common] Set Websocket Message For Enterprise',
          enterpriseId: socketEntId,
          message,
        });
      }
    }
    // return socket;
    return {
      connectToEnterprise: function (entId) {
        if (ws) {
          ws.onerror = null;
          ws.close();
        }
        openSocket(entId);
      },
      fetchNotifications: function () {
        return collection;
      },
      closeSocket: function () {
        if (ws) {
          ws.onerror = null;
          ws.close();
        }
      },
    }; // end of return
  },
]);
