import { Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { ScopeProvider } from '../../../app/upgraded.providers';

@Directive({
  selector: 'app-session',
  providers: [ScopeProvider],
})
export class SessionUpgradeDirective extends UpgradeComponent {
  constructor(elementRef: ElementRef, injector: Injector) {
    super('sessionDirective', elementRef, injector);
  }
}
