import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../store';
import * as moment from 'moment';
import { RequestListFilter } from '../store/';
import { combineLatest } from 'rxjs';
import { FetchRequestDetails, ExportToCsvDetails, ExportToPdfDetails, FetchProviderDetails } from '../store/actions';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SessionDetailsComponent } from './session.details.component';
import { ProviderDetailsComponent } from './provider.details.component';
import { SessionFilterService } from '../services/session.filter.service';
import { TimeFormatService } from '../../../shared/services/time-format.service';
import { EnumsService } from '../../../shared/services/enums.service';

@Component({
  selector: 'request-list-component',
  templateUrl: './request.list.component.html',
})
export class RequestListComponent implements OnInit {
  private allRequests: any[];

  @ViewChild('theList', { static: false }) theList;
  @Input() widgetData;
  public currentFilter: RequestListFilter;
  public filterText: string;
  public requests: any[];
  public showRequests: any[];
  public isLoading: boolean;
  public count: number;
  public currentPage = 1;
  public pageSize = 50;
  public jobs: any[];
  public keyAttribute: any;
  public searchQuery: string;
  public keyProfession: string;
  private subscriptions = [];
  public customTitle;
  public jobName: string;
  public enterpriseName: string;
  public hasInternalNotes = false;
  public showCancelReasonColumn = false;

  constructor(
    private store: Store<fromRoot.State>,
    private modalService: BsModalService,
    private filterService: SessionFilterService,
    private timeFormatService: TimeFormatService,
    private enumsService: EnumsService
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      combineLatest(
        this.store.select(fromRoot.getRequests),
        this.store.select(fromRoot.getJobs),
        this.store.select(fromRoot.getKeyAttribute),
        this.store.select(fromRoot.getKeyProfession)
      ).subscribe((result) => {
        this.allRequests = result[0];
        this.jobs = result[1];
        this.keyAttribute = result[2];
        this.keyProfession = result[3];
        this.loadData(this.allRequests);
      })
    );

    this.subscriptions.push(
      this.store.select(fromRoot.getIsLoading).subscribe((isLoading) => (this.isLoading = isLoading))
    );

    this.subscriptions.push(
      combineLatest(
        this.store.select(fromRoot.getRequestListFilter),
        this.store.select(fromRoot.getTimeRange)
      ).subscribe((results) => {
        this.updateFilters(results[0], results[1]);
      })
    );

    this.jobName = this.widgetData.jobName || 'Job Template Name';
    this.enterpriseName = this.widgetData.enterpriseName || 'Enterprise Name';
  }

  loadData(list: any[]) {
    this.currentPage = 0;
    this.count = list.length;
    list.sort((a, b) => {
      const aTime = a.request_date + a.request_time;
      const bTime = b.request_date + b.request_time;
      return aTime.localeCompare(bTime);
    });
    this.requests = list.map((r) => this.buildRequest(r));
    this.showCancelReasonColumn = this.requests.some(r => r.statusType === 7);
    this.showRequests = this.requests.slice(0, this.pageSize);
  }

  updateFilters(requestFilter: RequestListFilter, timeFilter: any) {
    this.filterText = '';
    this.count = null;
    if (this.allRequests) {
      let list = this.allRequests;

      if (requestFilter) {
        this.currentFilter = requestFilter;
        if (requestFilter.status) {
          list = list.filter((r) => r.status.type === requestFilter.status);
          let statuses = {
            1: 'Active',
            2: 'Committed',
            3: 'Pending',
            4: 'Open',
            5: 'Completed',
            6: 'Denied',
            7: 'Cancelled',
          };
          this.filterText = statuses[requestFilter.status];
        }

        if (requestFilter.cancelCode) {
          list = list.filter((r) => r.cancelled_reason === requestFilter.cancelCode);
          let statuses = {
            0: 'Not Canceled',
            1: 'Canceled by Admin',
            2: 'Request Timed Out',
            3: 'All Available Providers Declined Request',
            4: 'Canceled By Requesters',
            5: 'Canceled By Worker',
          };
          this.filterText = statuses[requestFilter.cancelCode];
        }

        if (requestFilter.showAccepted) {
          list = list.filter((r) => r.status.type === 1 || r.status.type === 2);
          this.filterText = 'Accepted';
        }

        if (requestFilter.showLate) {
          list = this.filterService.filterLateSessions(list);
          this.filterText = 'Late';
        }

        if (requestFilter.showLong) {
          list = this.filterService.filterGoingLongSessions(list);
          this.filterText = 'Going long';
        }
        if (requestFilter.keyAttribute) {
          list = this.filterService.filterKeyAttribute(list, this.keyAttribute.value, requestFilter.keyAttribute);
          this.filterText = requestFilter.keyAttribute;
        }
        if (requestFilter.showAllAccepted) {
          list = this.filterService.filterAllAccepted(list);
          this.filterText = 'Accepted';
        }
        if (requestFilter.showAllOffered) {
          list = this.filterService.filterAllOffered(list);
          this.filterText = 'Offered';
        }
        if (requestFilter.showAll) {
          this.filterText = 'Total';
        }
        if (requestFilter.showAllLate) {
          list = this.filterService.filterLateSessions(list).concat(this.filterService.filterGoingLongSessions(list));
        }
      }
      this.loadData(list);
    }
  }

  buildRequest(r) {
    let type;
    switch (r.type) {
      case 1:
        type = 'Scheduled';
        break;
      case 2:
        type = 'Now';
        break;
      default:
        type = 'N/A';
        break;
    }
    if (this.keyAttribute && this.keyAttribute.name === 'Key Attribute') {
      this.customTitle = `Top ${this.keyAttribute.name}`;
    } else if (this.keyAttribute) {
      this.customTitle = `Top ${this.keyAttribute.name}`;
    }

    let job = this.jobs.find((j) => j.job_id === r.job_id);
    let keyAttribute = r.attrib_list.find((a) => a.name === this.keyAttribute.value);
    let keyAttributeValue;
    if (keyAttribute) {
      if (keyAttribute.type === 'single_list' || keyAttribute.type === 'multi_list') {
        keyAttributeValue = keyAttribute.value_list && keyAttribute.value_list[0] && keyAttribute.value_list[0].name;
      } else if (keyAttribute.type === 'text') {
        keyAttributeValue = keyAttribute.value_text;
      }
    }

    let duration = (<any>moment.duration(r.duration, 'minutes')).format('HH : mm', { trim: false });
    let internalNotesAttribute = r.attrib_list.find((a) => a.name === 'notes_internal');
    let internalNotes;
    if (internalNotesAttribute) {
      internalNotes = internalNotesAttribute.value_text;
      this.hasInternalNotes = true;
    }
    return {
      id: r.request_id,
      startTime: moment
        .utc(`${r.request_date} ${r.request_time}`)
        .local()
        .format(this.timeFormatService.format('dateTimeLong')),
      checkInTime:
        r.checkin_date && r.checkin_time
          ? moment
            .utc(`${r.checkin_date} ${r.checkin_time}`)
            .local()
            .format(this.timeFormatService.format('dateTimeLong'))
          : 'n/a',
      checkOutTime:
        r.checkout_date && r.checkout_time
          ? moment
            .utc(`${r.checkout_date} ${r.checkout_time}`)
            .local()
            .format(this.timeFormatService.format('dateTimeLong'))
          : 'n/a',
      duration: duration,
      jobName: r.job_name,
      enterpriseName: r.enterprise_name,
      requester: `${r.req_first_name} ${r.req_last_name}`,
      keyAttributeValue: keyAttributeValue,
      provider: `${r.serv_first_name} ${r.serv_last_name}`,
      type: type,
      statusType: r.status.type,
      status: this.getStatus(r),
      cancelReason: r.cancel_reason_text ?? '',
      workNId: r.serv_id ?? '',
      atsId: r.serv_ext_id ?? '',
      internalNotes: this.hasInternalNotes ? internalNotes : '',
    };
  }

  getStatus(request) {
    if (request.status.name === 'Canceled' && request.cancelled_reason) {
      return this.enumsService.CancelStatuses[request.cancelled_reason];
    }
    return request.status.name;
  }

  pageChanged(event) {
    let index = event.page - 1;
    let start = index * event.itemsPerPage;
    let end = (index + 1) * event.itemsPerPage;
    this.showRequests = this.requests.slice(start, end);
  }

  sessionSelected(id) {
    this.modalService.show(SessionDetailsComponent, { animated: false });
    let action = new FetchRequestDetails(id);
    this.store.dispatch(action);
  }

  providerSelected(event, id) {
    event.stopPropagation();
    this.modalService.show(ProviderDetailsComponent, { animated: false });
    let action = new FetchProviderDetails(id);
    this.store.dispatch(action);
  }

  doSearch() {
    this.currentPage = 0;
    if (this.searchQuery) {
      this.requests = this.requests.filter((r) => {
        let match = false;
        for (var key in r) {
          if (typeof r[key] === 'string') {
            if (r[key].toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1) {
              match = true;
              break;
            }
          }
        }
        return match;
      });
      this.count = this.requests.length;
      this.showRequests = this.requests.slice(0, this.pageSize);
    } else {
      this.ngOnInit();
    }
  }

  exportToCSV() {
    let labels = [
      'Session ID',
      'Start Time',
      'Check in Time',
      'Check out Time',
      'Reserved',
      'Job Template Name',
      'Enterprise',
      'Requester',
      this.keyAttribute && this.keyAttribute.name,
      'Worker',
      'Type',
      'Status',
      'Cancel Reason',
      'WorkN ID',
      'ATS ID'
    ];

    if (this.hasInternalNotes) {
      labels.push('Internal Notes');
    }

    const csvFields = this.requests.map(({ statusType, ...item }) => item);
    this.store.dispatch(new ExportToCsvDetails('requestList', labels, csvFields));
  }

  exportToPDF() {
    let labels = [
      { header: 'Session ID' },
      { header: 'Start Time' },
      { header: 'Check in Time' },
      { header: 'Check out Time' },
      { header: 'Reserved' },
      { header: 'Job Template Name' },
      { header: 'Enterprise' },
      { header: 'Requester' },
      { header: this.keyAttribute && this.keyAttribute.name },
      { header: 'Worker' },
      { header: 'Type' },
      { header: 'Status' },
      { header: 'Cancel Reason' },
      { header: 'WorkN ID' },
      { header: 'ATS ID' },
    ];
    if (this.hasInternalNotes) {
      labels.push({ header: 'Internal Notes' });
    }

    const pdfFields = this.requests.map(({ statusType, ...item }) => item);
    this.store.dispatch(new ExportToPdfDetails('requestList', labels, pdfFields));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
