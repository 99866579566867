import module from './module';

export default module.service('providerExportService', [
  'FormatHelperService',
  function (FormatHelperService) {
    const separator = ',';

    const loggedInStatus = (status) => {
      switch (status) {
        case -1:
          return 'Never logged in';
        case 0:
          return 'Not current logged in';
        case 1:
          return 'Currently logged in';
      }
    };

    const vettingStatus = (provider) => {
      if (provider.profile_info) {
        const item = provider.profile_info.find((x) => x.name === 'provider_vetting_status');
        if (item) {
          if (item.display_value) {
            return item.display_value;
          } else {
            const selected = item.choice_list.find((x) => x.selected);
            if (selected) {
              return selected.name;
            }
          }
        }
      }
      return '';
    };

    const getCsvHeader = (data) => {
      const headers = [
        'First Name',
        'Last Name',
        'Username',
        'Login status',
        'Vetting status',
        'External candidate ID',
      ];

      return headers.join(separator);
    };

    const getCsvBody = (providers) => {
      return providers
        .map(function (provider) {
          const firstName = provider.profile_info
            ? provider.profile_info.find((info) => info.name === 'first_name')
            : null;
          const lastName = provider.profile_info
            ? provider.profile_info.find((info) => info.name === 'last_name')
            : null;
          const userName = provider.profile_info
            ? provider.profile_info.find((info) => info.name === 'username')
            : null;
          const externalCandidateId = provider.profile_info
            ? provider.profile_info.find((info) => info.name === 'ext_employee_id')
            : null;

          const csv = {
            firstName: firstName ? firstName.display_value : provider.first_name,
            lastName: lastName ? lastName.display_value : provider.last_name,
            userName: userName ? userName.display_value : '',
            loginStatus: typeof provider.login_status === 'number' ? loggedInStatus(provider.login_status) : '',
            vettingStatus: vettingStatus(provider),
            externalCandidateId: externalCandidateId ? externalCandidateId.display_value : '',
          };

          return csv;
        })
        .map(function (provider) {
          return Object.keys(provider)
            .map(function (key) {
              return provider[key];
            })
            .join(separator);
        });
    };

    const getCsv = (providers) => {
      let result = [];
      result.push('data:text/csv;charset=utf8,');
      result.push(getCsvHeader(providers));

      const csvBody = getCsvBody(providers);

      result = result.concat(csvBody);

      return result.join('\n');
    };

    const generateDownload = (data) => {
      const dataUri = getCsv(data);

      const hiddenElement = document.createElement('a');
      hiddenElement.href = encodeURI(dataUri);

      const fileNameExtra = `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate()}-${new Date().getHours()}-${new Date().getMinutes()}-${new Date().getSeconds()}`;

      hiddenElement.download = `Providers-${fileNameExtra}.csv`;
      document.body.appendChild(hiddenElement); // Required for FF
      hiddenElement.click();
      document.body.removeChild(hiddenElement);
    };

    return {
      generateDownload,
    };
  },
]);
