import { Injectable } from '@angular/core';
import { SecureHttpService } from '../secure.http.service';
import Env from '../../../scripts/env.js';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CommunicationsService {
  private endpointRoot = `${Env.BASE_URL}`;
  constructor(private http: SecureHttpService) { }
  public getTemplates() {
    return this.http
      .get(`${this.endpointRoot}/marketplace/list_email_templates/`)
      .pipe(
        map((res) =>
          res.map((item) => ({
            name: item.display_title || item.template_name,
            custom: !item.is_base_template,
            enabled: item.is_enabled,
            text: item.description,
            id: item.id,
            editable: item.is_editable,
          }))
        )
      )
      .toPromise();
  }

  public getNotifications() {
    return this.http
      .get(`${this.endpointRoot}/marketplace/list_push_templates/`)
      .pipe(
        map((res) =>
          res.map((item) => ({
            name: item.title,
            custom: !item.is_base_template,
            enabled: item.is_enabled,
            text: item.description,
            id: item.id,
          }))
        )
      )
      .toPromise();
  }

  public getTextMessages() {
    return this.http
      .get(`${this.endpointRoot}/get_text_message_types`)
      .pipe(map((result) => result.data))
      .toPromise();
  }

  toggleTextMessageEnabled(type, newStatus) {
    return this.http
      .post(`${this.endpointRoot}/change_text_enabled`, {
        type,
        enabled: newStatus,
      })
      .toPromise();
  }

  getEmailTemplate(id: number) {
    return this.http
      .get(`${this.endpointRoot}/marketplace/email_template_details/?id=${id}`)
      .pipe(
        map((res) => ({
          variables: Object.keys(res.editable_vars)
            ?.map((v) => ({
              name: v,
              description: res.editable_vars[v],
            }))
            .filter((v, i, a) => a.indexOf(v) === i),
          enabled: res.is_enabled,
          custom: !res.is_base_template,
          name: res.display_title || res.template_name,
          body: res.html_body,
          customTemplateId: res.custom_template_id,
          image: res.mandrill_image_url,
          ...res,
        }))
      )
      .toPromise();
  }
  saveEmailTemplate(template) {
    if (template.is_base_template) {
      return this.http
        .post(`${this.endpointRoot}/marketplace/email_template_details/`, {
          base_template_id: template.id,
        })
        .pipe(
          switchMap((newTemplate) => {
            return this.http.patch(`${this.endpointRoot}/marketplace/email_template_details/`, {
              email_template_id: newTemplate.id,
              html_body: template.body,
              subject: template.subject,
            });
          })
        )
        .toPromise();
    } else {
      return this.http
        .patch(`${this.endpointRoot}/marketplace/email_template_details/`, {
          email_template_id: template.id,
          html_body: template.body,
          subject: template.subject,
        })
        .toPromise();
    }
  }
  revertEmailTemplate(template) {
    return this.http
      .delete(`${this.endpointRoot}/marketplace/email_template_details/`, {
        email_template_id: template.id,
      })
      .toPromise();
  }
  revertEmailTemplateToCustom(template) {
    return this.http
      .post(`${this.endpointRoot}/marketplace/toggle_use_custom_template/`, {
        email_template_id: template.customTemplateId,
      })
      .toPromise();
  }
  revertNotificationTemplateToDefault(template) {
    return this.http
      .delete(`${this.endpointRoot}/marketplace/push_notification_details/`, {
        push_template_id: template.id,
      })
      .toPromise();
  }
  revertNotificationTemplateToCustom(template) {
    return this.http
      .post(`${this.endpointRoot}/marketplace/toggle_use_custom_push_template/`, {
        push_template_id: template.customTemplateId,
      })
      .toPromise();
  }
  revertTextMessageTemplateToDefault(template) {
    return this.http
      .post(`${this.endpointRoot}/revert_to_default_text_message_template/`, {
        type: template.type,
      })
      .toPromise();
  }
  revertTextMessageTemplateToCustom(template) {
    return this.http
      .post(`${this.endpointRoot}/revert_to_custom_text_message_template/`, {
        type: template.type,
      })
      .toPromise();
  }
  toggleEnabled(template, newStatus) {
    const toggleHrefPart = newStatus ? 'enable_email_template' : 'disable_email_template';

    return this.http
      .get(`${this.endpointRoot}/marketplace/${toggleHrefPart}/?id=${template.id}`)
      .toPromise();
  }

  toggleNotificationEnabled(template) {
    if (!template.custom) {
      return this.http
        .post(`${this.endpointRoot}/marketplace/push_notification_details/`, {
          base_template_id: template.id,
        })
        .pipe(
          switchMap((newTemplate) => {
            return this.http.get(
              `${this.endpointRoot}/marketplace/toggle_push_notification_active/?id=${newTemplate.id}`
            );
          })
        )
        .toPromise();
    } else {
      return this.http
        .get(`${this.endpointRoot}/marketplace/toggle_push_notification_active/?id=${template.id}`)
        .toPromise();
    }
  }

  getNotificationTemplate(id: number) {
    return this.http
      .get(`${this.endpointRoot}/marketplace/push_notification_details/?id=${id}`)
      .pipe(
        map((res) => ({
          variables: Object.keys(res.editable_vars)
            ?.map((v) => ({
              name: v,
              description: res.editable_vars[v],
            }))
            .filter((v, i, a) => a.indexOf(v) === i),
          enabled: res.is_enabled,
          custom: !res.is_base_template,
          name: res.display_title,
          subject: res.title,
          body: res.text,
          customTemplateId: res.custom_template_id,
          description: res.type_description,
          ...res,
        }))
      )
      .toPromise();
  }

  getTextMessageTemplate(id) {
    return this.http.post(`${this.endpointRoot}/get_text_message_template/`, { type: id }).toPromise();
  }

  saveNotificationTemplate(template) {
    if (template.is_base_template) {
      return this.http
        .post(`${this.endpointRoot}/marketplace/push_notification_details/`, {
          base_template_id: template.id,
        })
        .pipe(
          switchMap((newTemplate) => {
            return this.http.patch(`${this.endpointRoot}/marketplace/push_notification_details/`, {
              push_template_id: newTemplate.id,
              text: template.body,
              title: template.subject,
            });
          })
        )
        .toPromise();
    } else {
      return this.http
        .patch(`${this.endpointRoot}/marketplace/push_notification_details/`, {
          push_template_id: template.id,
          text: template.body,
          title: template.subject,
        })
        .toPromise();
    }
  }

  saveTextMessage(template) {
    return this.http
      .post(`${this.endpointRoot}/save_text_message_template`, {
        type: template.type,
        body: template.body,
      })
      .toPromise();
  }

  uploadEmailFile(id, file) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('email_template_id', id);
    let headers = new Headers();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.postFile(`${this.endpointRoot}/marketplace/upload_email_image/`, formData).toPromise();
  }
}
