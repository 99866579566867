import { AppConfigService } from './app-config.service';
import { AppStateService } from './app-state.service';
import { AuthenticationService } from './authentication.service';
import { BranchFilterWrapperService } from './branch-filer-wrapper.service';
import { BrowserNotificationService } from './browser-notifications.service';
import { EnterpriseFilterWrapperService } from './enterprise-filer-wrapper.service';
import { EnumsService } from './enums.service';
import { ExportService } from './export.service';
import { UserAgentService } from './user-agent.service';
import { TimeFormatService } from './time-format.service';
import { GeneralDataService } from './general.data.service';
import { PermissionsService } from './permissions.service';
import { SecureHttpService } from './secure.http.service';
import { TimeService } from './time.service';
import { ForceFilterService } from './force-filter.service';
import { LocationProvider } from './location.provider';
import { BranchService } from './api/branch-service';
import { MarketplaceService } from './api/marketplace-service';
import { TimezoneService } from './api/timezone-service';
import { PermissionService } from './api/permission-service';
import { EnterpriseListService } from './api/enterprise-list.service';
import { CommunicationsService } from './api/communications.service';

export const SERVICES = [
  AppConfigService,
  AppStateService,
  AuthenticationService,
  BranchFilterWrapperService,
  BrowserNotificationService,
  EnterpriseFilterWrapperService,
  EnumsService,
  UserAgentService,
  TimeFormatService,
  ExportService,
  GeneralDataService,
  PermissionsService,
  SecureHttpService,
  TimeService,
  LocationProvider,
  BranchService,
  MarketplaceService,
  TimezoneService,
  PermissionService,
  EnterpriseListService,
  CommunicationsService,
];

export * from './app-config.service';
export * from './app-state.service';
export * from './authentication.service';
export * from './branch-filer-wrapper.service';
export * from './browser-notifications.service';
export * from './enterprise-filer-wrapper.service';
export * from './enums.service';
export * from './user-agent.service';
export * from './export.service';
export * from './general.data.service';
export * from './permissions.service';
export * from './secure.http.service';
export * from './time.service';

// ---------------------------------
// Angular downgrade in one place
// ---------------------------------

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Store } from '@ngrx/store';
import { CommonSelectors } from '../store/selectors';

angular
  .module('ServetureWebApp')
  .factory('AppStateService', downgradeInjectable(AppStateService))
  .factory('BrowserNotificationService', downgradeInjectable(BrowserNotificationService))
  .factory('EnumsService', downgradeInjectable(EnumsService))
  .factory('UserAgentService', downgradeInjectable(UserAgentService))
  .factory('TimeFormatService', downgradeInjectable(TimeFormatService))
  .factory('TimeService', downgradeInjectable(TimeService))
  .factory('NgrxStore', downgradeInjectable(Store))
  .factory('PermissionsService', downgradeInjectable(PermissionsService))
  .factory('ForceFilterService', downgradeInjectable(ForceFilterService))
  .factory('LocationProvider', downgradeInjectable(LocationProvider))
  .factory('branchService', downgradeInjectable(BranchService))
  .factory('marketplaceService', downgradeInjectable(MarketplaceService))
  .factory('timezoneService', downgradeInjectable(TimezoneService))
  .factory('permissionService', downgradeInjectable(PermissionService))
  .factory('enterpriseListService', downgradeInjectable(EnterpriseListService))
  .factory('communicationsService', downgradeInjectable(CommunicationsService))
  .factory('commonSelectors', downgradeInjectable(CommonSelectors));
