import $ from 'jquery';
import angular from 'angular';
import module from '../module';
import * as enums from '../../enums';
import Swal from 'sweetalert2';
import moment from 'moment';

export default module.controller('JobController', [
  'sessionManagerService',
  '$scope',
  '$timeout',
  '$interval',
  '$modal',
  '$location',
  'AlertService',
  'providerManagerService',
  'DOMAIN',
  'EnterpriseFilterService',
  'AuthenticationService',
  'generalDataService',
  'TimeFormatService',
  function (
    sessionManagerService,
    $scope,
    $timeout,
    $interval,
    $modal,
    $location,
    AlertService,
    providerManagerService,
    DOMAIN,
    EnterpriseFilterService,
    AuthenticationService,
    generalDataService,
    TimeFormatService
  ) {
    const CURRENT_USER = AuthenticationService.getCurrentUser();
    $scope.pageTitle = generalDataService.getKeySession() + 's';
    let locationInterval;
    $scope.keyProfession = generalDataService.getKeyProfession();
    $scope.keySession = generalDataService.getKeySession();
    $scope.entFilter = EnterpriseFilterService.fetchSelectedEnterprise();
    $scope.submitBtnDisbaled = false;
    $scope.job = {};
    $scope.map = {};
    $scope.provider = {};
    $scope.domain = DOMAIN;
    $scope.showMap = true;
    $scope.refreshMapIcon = `${enums.AssetsFolder.svg}refreshmap.svg`;
    $scope.rippleIcon = `${enums.AssetsFolder.svg}ripple.svg`;

    // logged in status check for class association
    $scope.loggedInStatus = function (status_code) {
      if (status_code == 1) {
        return 'logged-in-state';
      } else if (status_code == 0) {
        return 'logged-out-state';
      } else {
        return 'no-logged-in-state';
      }
    };
    $scope.popover = {
      loggedInStatus: 'app/views/templates/avatar_loggedin_status_legend.html',
    };

    function getProvider(userId) {
      providerManagerService.getProvider(userId).then(function (provider) {
        $scope.provider = angular.copy(provider);
      });
    }
    $scope.$watch('request', function (newVal, oldVal) {
      if (newVal != oldVal) {
        getProvider($scope.request.serv_user_id);
      }
    });

    function displayProvider(providerId) {
      const marketplaceId = CURRENT_USER.market_place_info.id;
      providerManagerService.getProviderLocation(providerId, marketplaceId).then(function (location) {
        providerLocObjBuilder(location, 2, $scope.keyProfession.data_content, false);
      });
    }

    // grab passed Job from session controller from session service
    // grab list of review attribs realm 7
    function getJob() {
      sessionManagerService
        .getSession()
        .then(
          function (session) {
            $scope.request = session;
            $scope.setupMap($scope.request);
            if ($scope.request.serv_user_id) {
              getProvider($scope.request.serv_user_id);
            }
            if (session.cancelled_date && session.cancelled_time) {
              $scope.cancelDateTime = moment
                .utc(`${session.cancelled_date} ${session.cancelled_time}`)
                .local()
                .format(TimeFormatService.format('dateTimeLong'));
            }
            const cancelReasonAtrib = session?.attrib_list?.find((x) => x.name === 'admin_cancel_comments');
            if (cancelReasonAtrib && cancelReasonAtrib.value_text) {
              $scope.cancelReason = cancelReasonAtrib.value_text;
            } else {
              $scope.cancelReason = session.cancelled_reason ? enums.CancelStatuses[session.cancelled_reason] : null;
            }
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
          }
        )
        .then(function () {
          sessionManagerService.getRealmAttribs(enums.Realms.RequesterReview).then(
            function (response) {
              if (response.success) {
                $scope.reviewAttribs = response.attrib_list;
              } else {
                AlertService.errorAlert(response.message);
              }
            },
            function (reason) {
              AlertService.serverRequestErrorAlert(reason);
            }
          );
        });
    }
    getJob();

    $scope.reloadSession = function (request) {
      $scope.showMap = false;
      $timeout(function () {
        sessionManagerService.setSession(request.request_id).then(
          function (session) {
            $scope.request = session;
            mapMarkerUpdate($scope.request);
            $scope.showMap = true;
            if ($scope.request.provider_list && $scope.request.provider_list.length > 0) {
              availableProviders($scope.request.provider_list);
            }
          },
          function (reason) {
            AlertService.serverRequestErrorAlert('Could not contact server');
            mapMarkerUpdate($scope.request);
            $scope.showMap = true;
          }
        );
      }, 300);
    };

    // WebSocket
    $scope.$on('PRO_LOCATION_UPDATE', updateProviderLocation);

    function updateProviderLocation(event, args) {
      const newMessage = args;
      if (newMessage.request_id == $scope.request.request_id) {
        event.stopPropagation();
        const providerUpdatedLocation = $scope.markers.filter(function (obj) {
          return obj.id == 2;
        })[0];
        if (providerUpdatedLocation) {
          providerUpdatedLocation.latitude = parseFloat(newMessage.lat);
          providerUpdatedLocation.longitude = parseFloat(newMessage.lon);
        } else {
          mapMarkerUpdate($scope.request);
          const providerLocObj = {
            latitude: parseFloat(newMessage.lat),
            longitude: parseFloat(newMessage.lon),
            id: 2,
            options: {
              labelClass: 'provider_marker_labels',
              labelAnchor: '0 0',
              labelContent: $scope.keyProfession.data_content,
              icon: `${enums.AssetsFolder.images}map_worker.png`,
              labelVisible: false,
            },
          };
          $scope.markers.push(providerLocObj);
        }
      }
    }

    $scope.$on('NOTIFICATION_UPDATE', function (event, message) {
      event.stopPropagation();
      if (message.request_id == $scope.request.request_id) {
        mapMarkerUpdate($scope.request);
        if ($scope.request.serv_user_id) {
          getProvider($scope.request.serv_user_id);
        }
        if ($scope.request.provider_list) {
          availableProviders($scope.request.provider_list);
        }
        if (message.status.type !== 1 && locationInterval) {
          stopLiveTracking();
        }
      }
    });

    // clear the marker obj of all provider locations when the Request status
    // changes from pending. could be cancelled or changed to committed.

    function mapMarkerUpdate(request) {
      if (request.status.type == 7) {
        // cancelled
        const jobLocationMarker = $scope.markers.filter(function (obj) {
          return obj.id == 1;
        });
        $scope.markers = jobLocationMarker;
      } else {
        const jobLocationMarker = $scope.markers.filter(function (obj) {
          return obj.id == 1;
        });
        $scope.markers = jobLocationMarker;
      }
    }

    $scope.setupMap = function (request) {
      $scope.markers = [
        {
          position: {
            lat: parseFloat(request.location.value_location.latitude),
            lng: parseFloat(request.location.value_location.longitude),
          },
          clickable: true,
          label: {
            className: 'marker_labels',
            text: request.location.value_location.name,
          },

          icon: {
            url: `${enums.AssetsFolder.images}job_location.png`,
            size: { width: 19, height: 19 },
            labelOrigin: { x: 0, y: -20 },
          },
        },
      ];

      $scope.map = {
        control: {},
        center: {
          lat: parseFloat(request.location.value_location.latitude),
          lng: parseFloat(request.location.value_location.longitude),
        },
        zoom: 20,
        fit: true,
        clusterOptions: { minimumClusterSize: 2 },
        gestureHandling: 'greedy',
      };
      $scope.options = {
        scrollwheel: false,
      };
      if (request.provider_list) {
        availableProviders(request.provider_list);
      }
      startLiveTracking(request);
    };

    function startLiveTracking(request) {
      if (request.serv_provider_id) {
        // Committed session, show provider on map from prior to 30 mins on start
        if (request.status && (request.status.type === 2 || request.status.type === 1)) {
          const startTime = $scope.buildStartDate();
          const endTime = moment($scope.buildEndTime(), TimeFormatService.format('dateTimeLong'));

          // Committed sessions should not be tracked after their end
          if (request.status.type === 2 && moment().isAfter(endTime)) {
            return;
          } else if (request.status.type === 1) {
            $scope.isLiveTracking = true;
            displayProvider(request.serv_provider_id);
            $timeout(() => $scope.forceFit(), 500);
            locationInterval = $interval(liveTrackStep, 10000);
            // Request is active
          } else if (moment.duration(startTime.diff(moment())).asMinutes() < 30) {
            // Committed request is to start in 30 minutes
            $scope.isLiveTracking = true;
            displayProvider(request.serv_provider_id);
            $timeout(() => $scope.forceFit(), 500);
            locationInterval = $interval(liveTrackStep, 10000);
          }
        }
      }
    }

    function liveTrackStep() {
      displayProvider($scope.request.serv_provider_id);
      // Committed session is over, stop live tracking, active sessions tracks further
      const endTime = moment($scope.buildEndTime(), TimeFormatService.format('dateTimeLong'));
      if (moment().isAfter(endTime) && $scope.request.status.type === 2) {
        stopLiveTracking();
      }
    }

    function stopLiveTracking() {
      $interval.cancel(locationInterval);
      $scope.isLiveTracking = false;
    }

    // Show available providers
    function availableProviders(provider_list) {
      if ($scope.request.status.type == 3) {
        // 3 is status pending

        const availableProviderLabel = 'Available ' + $scope.keyProfession.data_content;
        const unavailableProviderLabel = $scope.keyProfession.data_content + ' Declined';

        for (let i = 0; i < provider_list.length; i++) {
          if (provider_list[i].response == 0) {
            // no response yet
            providerLocObjBuilder(provider_list[i], provider_list[i].provider_id + 100, availableProviderLabel, true);
          } else if (provider_list[i].response == 2) {
            // declined
            providerLocObjBuilder(provider_list[i], provider_list[i].provider_id + 100, unavailableProviderLabel, true);
          }
        }
      }
    }

    function providerLocObjBuilder(locObj, locID, labelName, showLabel) {
      if (locObj.latitude == 0) {
        $scope.showLocUnavailableBanner = true;
      } else {
        $scope.showLocUnavailableBanner = false;
        const providerIcon = `${enums.AssetsFolder.images}map_worker.png`;

        const providerLocObj = {
          position: {
            lat: parseFloat(locObj.latitude),
            lng: parseFloat(locObj.longitude),
          },
          clickable: true,
          id: locID,
          provider_id: locObj.provider_id,
          label: {
            className: 'provider_marker_labels',
            text: labelName,
          },

          icon: {
            url: providerIcon,
            size: { width: 19, height: 19 },
            labelOrigin: { x: 0, y: -20 },
          },
        };

        const existingIndex = $scope.markers.findIndex(function (item) {
          return item.id === locID;
        });
        if (existingIndex !== -1) {
          $scope.markers.splice(existingIndex, 1);
        }
        $scope.markers.push(providerLocObj);
      }
    }

    $scope.openMapProviderModal = function (marker) {
      marker.set('labelVisible', !marker.labelVisible);
    };
    // show hide customer review section
    $scope.completeRequest = function () {
      $scope.requesterCompleted = true;
      const modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/schedule/actionsModal.html',
        controller: 'CheckOutModalController',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          passedJob: function () {
            return '';
          },
          passedSession: function () {
            return $scope.request;
          },
          passedEnt: function () {
            return $scope.entFilter;
          },
          passedJobOrderDetails: function () {
            return null;
          },
          passed_current_worker_count: function () {
            return null;
          },
          passed_total_worker_count: function () {
            return null;
          },
        },
      });
    };

    // cancel btn display
    $scope.cancelBtn = function (status) {
      if (status === 'Canceled' || status === 'Active' || status === 'Denied' || status === 'Completed') {
        return false;
      } else if (status === 'Committed') {
        $scope.cancelBtnDisplay = 'Cancel ' + $scope.keySession;
        return true;
      } else {
        $scope.cancelBtnDisplay = 'Cancel Request';
        return true;
      }
    };

    // cancel job button
    $scope.cancelJob = async function () {
      const config = {
        title: 'Are you sure?',
        text: 'You are about to cancel Job #' + $scope.request.request_id,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Yes, cancel job!',
      };

      const returnHandler = function (isConfirm) {
        if (!isConfirm) {
          return;
        }

        const obj = {
          end_type: 3,
          request_id: $scope.request.request_id,
        };

        sessionManagerService.endSessionRequest(obj).then(
          async function (response) {
            if (response.success) {
              const configPositive = {
                title: 'Canceled!',
                text: 'This job has been canceled.',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configPositive);
            } else {
              const configError = {
                title: 'Error',
                text: response.message,
                icon: 'error',
                showConfirmButton: true,
              };
              await Swal.fire(configError);
            } // end if response
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
          }
        ); // end server cancel call
      };

      const { value: returnValue } = await Swal.fire(config);
      returnHandler(returnValue);
    }; // end $scope.cancel job method

    // clear out review and job
    $scope.goBack = function () {
      $scope.review = {
        rate: 0,
        max: 5,
        isReadonly: false,
        customerComments: '',
        minutesLate: '',
      };
      $scope.issueModel = {
        late: false,
        noShow: false,
        poorPerformance: false,
        notProfessional: false,
      };
      $scope.openIssueResults = [];
      location.href = '/#/session';
    };

    // Job Receipt Modal
    $scope.showJobReceiptModal = function (review) {
      $scope.requestCompletion = {
        review: review,
        requestDetails: $scope.request,
      };
      // check if late is selected then minutes is required to continue
      if (review.late == true && (review.minutesLate == 0 || review.minutesLate == null)) {
        AlertService.warningAlert('Please enter how many minutes late');
      } else {
        const modalInstance = $modal.open({
          animation: true,
          templateUrl: 'app/views/job/jobReceiptModal.html',
          controller: 'JobReceiptModalController',
          size: 'lg',
          backdrop: 'static',
          resolve: {
            completionDetails: function () {
              return $scope.requestCompletion;
            },
          },
        });
        modalInstance.result.then(function () {
          $scope.goBack();
        });
      }
    };

    // Load Supervisor Details
    $scope.loadSupervisorDetails = function () {
      let supervisorSigImage = '';
      sessionManagerService
        .getSupervisorSignOffDetails({ svc_req_id: $scope.request.request_id })
        .then(function (response) {
          if (response?.success) {
            supervisorSigImage = response.image_attribute?.data ? response.image_attribute.data : '';
            $scope.showImageModal(null, supervisorSigImage);
          }
        });
    };

    // Image Viewer Modal
    $scope.showImageModal = function (imageUrl, base64Image) {
      const modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/job/imageViewerModal.html',
        controller: 'imageViewerModalController',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          passedImage: function () {
            return imageUrl;
          },
          base64Image: function () {
            return base64Image;
          },
        },
      });
      modalInstance.result.then(function () {});
    };

    $scope.forceFit = function () {
      $scope.fit = !$scope.fit;
      const map = document.querySelector('#googleMap');
      map?.forceFit();
    };

    $scope.forceFit();

    $scope.buildSubmitDate = function () {
      return moment
        .utc($scope.request.submit_date + ' ' + $scope.request.submit_time)
        .local()
        .format(TimeFormatService.format('dateTimeLong'));
    };

    $scope.buildStartDate = function () {
      return moment.utc($scope.request.request_date + ' ' + $scope.request.request_time);
    };

    $scope.buildEndTime = function () {
      if ($scope.request && $scope.request.attrib_list) {
        const durationAttrib = $scope.request.attrib_list.find(function (item) {
          return item.name === 'duration';
        });
        if (durationAttrib) {
          const duration = durationAttrib.value_count;
          const startTime = $scope.buildStartDate().local();
          return startTime.add(duration, 'm').format(TimeFormatService.format('dateTimeLong'));
        }
      }
    };

    $scope.sessionTracking = function () {
      console.log($scope.request);
      const modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/session/trackSessionModal.html',
        controller: 'TrackSessionModalController',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          passedSession: function () {
            return $scope.request;
          },
          passed_job_id: function () {
            return null;
          },
          passedJobOrderDetails: function () {
            return null;
          },
          passed_current_worker_count: function () {
            return null;
          },
          passed_total_worker_count: function () {
            return null;
          },
        },
      });
      modalInstance.result.then(function () {});
    };

    $scope.$on('$destroy', function () {
      if (locationInterval) $interval.cancel(locationInterval);
    });
  },
]);
