import { Component, Inject, OnInit } from '@angular/core';
import { AdminUserService } from '../../shared/services/api/admin-user.service';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { filter, take } from 'rxjs';
import { AppStateService } from '../../shared/services';

@Component({
  templateUrl: './reset-password.page.component.html',
})
export class ResetPasswordPageComponent implements OnInit {
  public showMismatchError = false;
  public showRegexError = false;
  public showSuccess = false;
  public password: string;
  public confirmPassword: string;
  public token: string;
  public username: string;
  public marketplaceId: number;
  public isPasswordExpired = false;
  public currentPassword: string;

  constructor(
    private adminUserService: AdminUserService,
    private route: ActivatedRoute,
    private appState: AppStateService,
    @Inject('localStorage') private localStorage
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.pipe(take(1)).subscribe((params) => {
      this.token = params.get('token');
      this.username = params.get('username');
      const passwordValue = params.get('passwordExpired');
      this.isPasswordExpired = passwordValue?.toLocaleLowerCase() === 'true' ?? false;
    });
    this.appState.initData$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((initData) => {
        const list: any[] = initData.market_place_list;
        if (list?.length) {
          this.marketplaceId = list[0].market_place_id;
        }
      });
  }

  public async resetPassword() {
    this.showMismatchError = false;
    this.showRegexError = false;

    if (this.isPasswordExpired && !this.currentPassword) {
      Swal.fire({
        title: 'Error',
        text: 'Current Password is required.',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }
    if (!this.password || !this.confirmPassword) {
      Swal.fire({
        title: 'Error',
        text: 'Password and Confirm Password are required.',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }
    if (this.currentPassword === this.password) {
      Swal.fire({
        title: 'Error',
        text: 'New password cannot be same as the old password.',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }
    if (this.password !== this.confirmPassword) {
      this.showMismatchError = true;
      return;
    } else {
      const passwordRegexp = new RegExp(
        '^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()-__+.]){1,}).{7,}$'
      );
      if (!this.password.match(passwordRegexp)) {
        this.showRegexError = true;
        return;
      }
    }


    if (this.isPasswordExpired) {
      const resetExpiredPayload = {
        old_password: this.currentPassword,
        new_password: this.password,
      };
      try {
        const result = await this.adminUserService.resetExpiredPassword(resetExpiredPayload);
        if (result.success) {
          this.localStorage.token = result?.user_token ?? ''
          this.showSuccess = true;
        }
      } catch (e) {
        Swal.fire({
          title: 'Error',
          text: 'There was an error resetting the password.',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } else {
      const resetPayload = {
        password: this.password,
        token: this.token,
        username: this.username,
        marketplaceId: this.marketplaceId,
      };
      try {
        const result = await this.adminUserService.resetPassword(resetPayload);
        if (result.success) {
          this.showSuccess = true;
        }
      } catch (e) {
        Swal.fire({
          title: 'Error',
          text: 'There was an error submitting.',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
        });
      }
    }
  }
}
