import { BranchSelectorComponent } from './branch-selector/branch-selector.component';
import { EnterpriseSelectorComponent } from './enterprise-selector/enterprise-selector.component';
import { FilterComponent } from './filter/filter.component';
import { HeaderTitleComponent } from './header-title/header-title.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TabHeaderComponent } from './tab-header/tab-header.component';
import { TabSelectorComponent } from './tab-selector/tab-selector.component';
import { KeyAttributeEditorComponent } from './key-attribute-editor/key-attribute-editor.component';

export const COMPONENTS = [
  BranchSelectorComponent,
  EnterpriseSelectorComponent,
  FilterComponent,
  HeaderTitleComponent,
  PageHeaderComponent,
  SpinnerComponent,
  TabHeaderComponent,
  TabSelectorComponent,
  KeyAttributeEditorComponent,
];

export * from './branch-selector/branch-selector.component';
export * from './enterprise-selector/enterprise-selector.component';
export * from './filter/filter.component';
export * from './header-title/header-title.component';
export * from './page-header/page-header.component';
export * from './spinner/spinner.component';
export * from './tab-header/tab-header.component';
export * from './tab-selector/tab-selector.component';

// COMPONENT SERVICES
import { HeaderTitleService } from './header-title/header-title.service';
import { PageHeaderService } from './page-header/page-header.service';
import { TabHeaderService } from './tab-header/tab-header.service';

export const COMPONENT_SERVICES = [HeaderTitleService, PageHeaderService, TabHeaderService];
