import { Component, OnDestroy, OnInit } from '@angular/core';

import { State } from '../../../store';
import { Store } from '@ngrx/store';
import { Subject, takeUntil, tap } from 'rxjs';
import { CommonSelectors } from 'app/shared/store/selectors';
import { SetSelectedBranch } from 'app/shared/store/actions';

@Component({
  selector: 'branch-selector',
  templateUrl: 'branch-selector.component.html',
})
export class BranchSelectorComponent implements OnInit, OnDestroy {
  public selectedBranch: number;
  public branchList: any[] = [];

  private readonly destroy$ = new Subject<boolean>();

  constructor(private store: Store<State>, private selectors: CommonSelectors) {}

  public ngOnInit(): void {
    this.selectors
      .getBranchList()
      .pipe(
        takeUntil(this.destroy$),
        tap((list) => (this.branchList = list))
      )
      .subscribe();

    this.selectors
      .getBranchSelection()
      .pipe(
        takeUntil(this.destroy$),
        tap((item) => (this.selectedBranch = item))
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public onBranchChanged(value: any): void {
    this.store.dispatch(new SetSelectedBranch(value, true));
  }
}
