'use strict';
import * as _ from 'lodash';
import * as enums from '../enums';
import Swal from 'sweetalert2';
import moment from 'moment';
import angular from 'angular';
import $ from 'jquery';

angular
  .module('calendar-schedule-timeline', [])
  .controller('dirCalendarScheduleTimelineController', [
    '$scope',
    '$localStorage',
    'AlertService',
    '$timeout',
    'sessionManagerService',
    '$location',
    '$modal',
    'FormatHelperService',
    'AuthenticationService',
    'DOMAIN',
    'removeSessionService',
    'PERMISSIONS',
    'trackingService',
    'generalDataService',
    'jobManagerService',
    'TimeFormatService',
    function (
      $scope,
      $localStorage,
      AlertService,
      $timeout,
      sessionManagerService,
      $location,
      $modal,
      FormatHelperService,
      AuthenticationService,
      DOMAIN,
      removeSessionService,
      PERMISSIONS,
      trackingService,
      generalDataService,
      jobManagerService,
      TimeFormatService
    ) {
      $scope.durationIcon = `${enums.AssetsFolder.svg}duration.svg`;
      $scope.startIcon = `${enums.AssetsFolder.svg}start.svg`;
      $scope.endIcon = `${enums.AssetsFolder.svg}end.svg`;
      const queryParams = $location.search();
      $scope.fromBold = queryParams && queryParams.ext_order_id;
      // Permission Check
      $scope.daySteps = {
        first: [0, 1, 2, 3, 4, 5],
        second: [6, 7, 8, 9, 10, 11],
        third: [12, 13, 14, 15, 16, 17],
        fourth: [18, 19, 20, 21, 22, 23],
      };

      $scope.weekSteps = {
        mon: [
          {
            day: 1,
            hour: 0,
          },
          {
            day: 1,
            hour: 6,
          },
          {
            day: 1,
            hour: 12,
          },
          {
            day: 1,
            hour: 18,
          },
        ],
        tue: [
          {
            day: 2,
            hour: 0,
          },
          {
            day: 2,
            hour: 6,
          },
          {
            day: 2,
            hour: 12,
          },
          {
            day: 2,
            hour: 18,
          },
        ],
        wed: [
          {
            day: 3,
            hour: 0,
          },
          {
            day: 3,
            hour: 6,
          },
          {
            day: 3,
            hour: 12,
          },
          {
            day: 3,
            hour: 18,
          },
        ],
        thu: [
          {
            day: 4,
            hour: 0,
          },
          {
            day: 4,
            hour: 6,
          },
          {
            day: 4,
            hour: 12,
          },
          {
            day: 4,
            hour: 18,
          },
        ],
        fri: [
          {
            day: 5,
            hour: 0,
          },
          {
            day: 5,
            hour: 6,
          },
          {
            day: 5,
            hour: 12,
          },
          {
            day: 5,
            hour: 18,
          },
        ],
        sat: [
          {
            day: 6,
            hour: 0,
          },
          {
            day: 6,
            hour: 6,
          },
          {
            day: 6,
            hour: 12,
          },
          {
            day: 6,
            hour: 18,
          },
        ],
        sun: [
          {
            day: 7,
            hour: 0,
          },
          {
            day: 7,
            hour: 6,
          },
          {
            day: 7,
            hour: 12,
          },
          {
            day: 7,
            hour: 18,
          },
        ],
      };

      const CURRENT_USER = AuthenticationService.getCurrentUser();
      const assignStates = ['pending', 'open', 'committed'];
      const changeStates = ['pending', 'open', 'committed'];
      const cancelStates = ['pending', 'open', 'committed', 'interest', 'waiting decision'];
      const escalationStates = ['open', 'committed'];
      const checkinStates = ['committed'];
      const checkoutStates = ['active'];
      const completedStates = ['completed'];
      const canceledStates = ['canceled'];
      const approvedStates = ['open'];
      const scheduleStates = ['pending', 'open', 'committed', 'active', 'canceled', 'completed', 'interest'];

      $scope.keySession = generalDataService.getKeySession();
      const profession = generalDataService.getKeyProfession();
      $scope.keyProfession = (profession && profession.data_content) || 'Worker';

      $scope.calViewAction = function (passedSession, action) {
        const state = passedSession.state.name.toLowerCase();
        const staff = passedSession.staff;
        const isScheduled = passedSession.request_type === 1;

        switch (action) {
          case 'assign':
            if (passedSession.bundled && state === 'committed') {
              return false;
            }
            if (
              isScheduled &&
              staff &&
              CURRENT_USER.permissionCheck(PERMISSIONS.ASSIGN) &&
              assignStates.indexOf(state) !== -1
            ) {
              return true;
            }
            break;

          case 'escalate':
            if (passedSession.bundled && state === 'committed') {
              return false;
            }
            if (
              isScheduled &&
              staff &&
              CURRENT_USER.permissionCheck(PERMISSIONS.ESCALATE) &&
              escalationStates.indexOf(state) !== -1 &&
              moment(passedSession.end).isAfter(moment())
            ) {
              return true;
            }
            break;

          case 'change':
            if (
              CURRENT_USER.permissionCheck(PERMISSIONS.CHANGE) &&
              ((passedSession.bundled && changeStates.indexOf(state) !== -1 && state !== 'pending') ||
                (!passedSession.bundled && changeStates.indexOf(state) !== -1 && passedSession.request_type == 1))
            ) {
              return true;
            }
            break;

          case 'cancel':
            if (CURRENT_USER.permissionCheck(PERMISSIONS.CANCEL) && cancelStates.indexOf(state) !== -1) {
              return true;
            }
            break;

          case 'checkin':
            if (CURRENT_USER.permissionCheck(PERMISSIONS.CHECKIN) && checkinStates.indexOf(state) !== -1) {
              return true;
            }
            break;

          case 'checkout':
            if (CURRENT_USER.permissionCheck(PERMISSIONS.CHECKOUT) && checkoutStates.indexOf(state) !== -1) {
              return true;
            }
            break;

          case 'transferSplit':
            if (staff && CURRENT_USER.permissionCheck(PERMISSIONS.CHECKOUT) && checkoutStates.indexOf(state) !== -1) {
              return true;
            }
            break;

          case 'changeVerify':
            if (CURRENT_USER.permissionCheck(PERMISSIONS.CHANGE_VERIFY) && completedStates.indexOf(state) !== -1) {
              return true;
            }
            break;

          case 'completed':
            if (completedStates.indexOf(state) !== -1) {
              return true;
            }
            break;

          case 'nonCompleted':
            if (completedStates.indexOf(state) === -1) {
              return true;
            }
            break;

          case 'resubmit':
          case 'changeResubmit':
            if (
              CURRENT_USER.permissionCheck(PERMISSIONS.REQUEUE) &&
              ((!passedSession.bundled && canceledStates.indexOf(state) !== -1) ||
                (passedSession.bundled && (state === 'committed' || state === 'canceled')))
            ) {
              return true;
            }
            break;

          case 'approve':
            if (staff && CURRENT_USER.permissionCheck(PERMISSIONS.APPROVE) && approvedStates.indexOf(state) !== -1) {
              return true;
            }
            break;

          case 'remove':
            if (CURRENT_USER.permissionCheck(PERMISSIONS.REMOVE) && canceledStates.indexOf(state) !== -1) {
              return true;
            }
            break;

          case 'cancelRemove':
            if (CURRENT_USER.permissionCheck(PERMISSIONS.CANCEL_REMOVE) && cancelStates.indexOf(state) !== -1) {
              return true;
            }
            break;

          case 'endAssignment':
            if (
              passedSession.jobOrderId &&
              CURRENT_USER.permissionCheck(PERMISSIONS.CANCEL) &&
              checkinStates.indexOf(state) !== -1
            ) {
              return true;
            }
            break;

          case 'scheduleSession':
            if (
              !passedSession.isJob &&
              CURRENT_USER.permissionCheck(PERMISSIONS.SCHEDULE_SESSION) &&
              scheduleStates.indexOf(state) !== -1
            ) {
              return true;
            }
            break;

          case 'scheduleJob':
            // console.log(passedSession);
            if (
              passedSession.isJob &&
              CURRENT_USER.permissionCheck(PERMISSIONS.SCHEDULE_JOB) &&
              scheduleStates.indexOf(state) !== -1
            ) {
              return true;
            }
            break;

          case 'editJobOrder':
            return (
              CURRENT_USER.permissionCheck(PERMISSIONS.UPDATE_ORDER) &&
              passedSession.jobOrderId &&
              (passedSession.user_id === 'Pending' || passedSession.user_id === 'Interest')
            );

          case 'changeJobOrder':
            return (
              CURRENT_USER.permissionCheck(PERMISSIONS.UPDATE_JOB_ORDER) &&
              passedSession.jobOrderId &&
              (passedSession.user_id === 'Pending' || passedSession.user_id === 'Interest')
            );

          case 'changeJobAssignment':
            return (
              CURRENT_USER.permissionCheck(PERMISSIONS.CHANGE_ASSIGNMENT) &&
              // CURRENT_USER.permissionCheck(PERMISSIONS.SCHEDULE_JOB_ORDER) &&
              (passedSession.state.type === 1 || passedSession.state.type === 2) &&
              passedSession.jobOrderId &&
              passedSession.user_id !== 'Pending'
            );

          case 'changeSessionAssignment':
            return (
              CURRENT_USER.permissionCheck(PERMISSIONS.CHANGE_ASSIGNMENT) &&
              // CURRENT_USER.permissionCheck(PERMISSIONS.SCHEDULE_JOB_ORDER) &&
              (passedSession.state.type === 1 || passedSession.state.type === 2) &&
              // passedSession.jobOrderId &&
              passedSession.user_id !== 'Pending'
            );

          case 'showInterested':
            return (
              (state === 'interest' || state === 'waiting decision') &&
              CURRENT_USER.permissionCheck(PERMISSIONS.APPLY_SHOW_DIALOG)
            );

          case 'assignInterested':
            return (
              (state === 'interest' || state === 'waiting decision') &&
              CURRENT_USER.permissionCheck(PERMISSIONS.APPLY_ASSIGN_DIALOG)
            );

          case 'offerInterested':
            return (
              (state === 'interest' || state === 'waiting decision') &&
              CURRENT_USER.permissionCheck(PERMISSIONS.APPLY_OFFER_DIALOG)
            );

          case 'viewTracking':
            return (
              (state === 'interest' || state === 'pending') &&
              CURRENT_USER.permissionCheck(PERMISSIONS.JOBS) &&
              passedSession.bundled &&
              (passedSession.user_id === 'Interest' || passedSession.user_id === 'Pending')
            );

          case 'birdsEye':
            return (
              state === 'interest' && !passedSession.jobOrderId && CURRENT_USER.permissionCheck(PERMISSIONS.BIRDS_EYE)
            );

          case 'updateRequestAttribs':
            return $scope.internalNotesPresent;
        }
      };

      $scope.calScheduleAction = function (action) {
        switch (action) {
          case 'scheduleSession':
            if (CURRENT_USER.permissionCheck(PERMISSIONS.SCHEDULE_SESSION)) {
              return true;
            }
            break;
          case 'scheduleJob':
            if (CURRENT_USER.permissionCheck(PERMISSIONS.SCHEDULE_JOB)) {
              return true;
            }
            break;
        }
      };
      $scope.sessionTracking = function (job, session) {
        $('div.popover').remove();
        // console.log('session');
        // console.log(session);

        jobManagerService.getJobOrderTracking(session.jobOrderId).then(
          (response) => {
            // console.log('response');
            // console.log(response);

            $modal.open({
              animation: true,
              templateUrl: 'app/views/session/trackSessionModal.html',
              controller: 'TrackSessionModalController',
              size: 'lg',
              backdrop: 'static',
              resolve: {
                passedSession: function () {
                  return response;
                },
                passed_job_id: function () {
                  return session.job_id; // job.job_id;
                },
                passedJobOrderDetails: function () {
                  return null;
                },
                passed_current_worker_count: function () {
                  return session.current_workers; // job.current_worker_count;
                },
                passed_total_worker_count: function () {
                  return session.total_workers; // job.total_worker_count;
                },
              },
            });
          },
          async (error) => {
            const configError = {
              title: 'Error',
              text: typeof error === 'string' ? error : 'Error contacting the server',
              icon: 'error',
              timer: 2000,
              showConfirmButton: false,
            };
            await Swal.fire(configError);
          }
        );
      };

      $scope.goToBirdsEye = (job, session) => {
        const jobId = job ? job.job_id : session.bundled_job_id;
        const jobInstance = job ? job.job_instance : session.job_instance;

        $modal.open({
          animation: true,
          templateUrl: 'app/views/job/birdsEye.html',
          controller: 'BirdsEyeController',
          size: 'xlg',
          backdrop: 'static',
          resolve: {
            passedData: function () {
              return {
                jobId,
                jobInstance,
              };
            },
          },
        });
      };

      $scope.scheduleByTime = function (action, timeOfDay) {
        let _controller;
        let _templateUrl;
        const start = moment($scope.startTimeRange, TimeFormatService.format('api'));
        if (typeof timeOfDay === 'number') {
          start.hours(timeOfDay);
        } else {
          start.day(timeOfDay.day);
          start.hours(timeOfDay.hour);
        }

        if (action === 'scheduleJob') {
          _controller = 'NewScheduleJobModalController';
          _templateUrl = 'app/views/schedule/scheduleJobModal.html';
        } else if (action === 'scheduleSession') {
          _controller = 'RequestScheduleJobModalController';
          _templateUrl = 'app/views/session/requestSessionModal.html';
        }

        $modal.open({
          animation: true,
          templateUrl: _templateUrl,
          controller: _controller,
          size: 'lg',
          backdrop: 'static',
          resolve: {
            passedEnt: function () {
              return $scope.entFilter;
            },
            enterprise_selection: function () {
              return $scope.entFilter;
            },
            passed_job_id: function () {
              return null;
            },
            passedSession: function () {
              return null;
            },
            start_time: function () {
              return start;
            },
          },
        });
      };

      // logged in status check for class association
      $scope.loggedInStatus = function (status_code) {
        if (status_code == 1) {
          return 'logged-in-state';
        } else if (status_code == 0) {
          return 'logged-out-state';
        } else {
          return 'no-logged-in-state';
        }
      };

      $scope.monthDisplay = function () {
        const month = moment($scope.startTimeRange, TimeFormatService.format('api')).clone().format('MMMM');
        return month;
      };

      function isString(obj) {
        if (typeof obj === 'string') {
          return true;
        } else {
          return false;
        }
      }

      $scope.$watch('typeFilter', function (newVal, oldVal) {
        if (newVal === 'Providers') {
          $scope.typeFilterLabel = $localStorage.generalData.filter(function (obj) {
            return obj.data_name === 'key_profession';
          })[0].data_content;
        } else {
          $scope.typeFilterLabel = newVal;
        }
      });

      $scope.$watch('search', function () {
        $scope.onSearchChanged({
          search: $scope.search,
        });
      });

      $scope.formatTimeDisplay = function (time) {
        return moment(time).format('ddd h:mm a');
      };

      $scope.formatDurationDisplay = function (duration) {
        const _duration = duration;
        let _durationDisplay;
        let hours;
        let minutes;
        if (_duration >= 1400) {
          hours = parseInt(_duration / 60);
          minutes = parseInt(_duration - hours * 60);

          if (minutes === 0) {
            _durationDisplay = hours + ' hrs';
          } else {
            _durationDisplay = hours + ' hrs : ' + minutes + ' mins';
          }
        } else if (_duration < 1400 && _duration >= 120) {
          hours = moment.duration(_duration, 'minutes').hours();
          minutes = moment.duration(_duration, 'minutes').minutes();

          if (minutes === 0) {
            _durationDisplay = hours + ' hrs';
          } else {
            _durationDisplay = hours + ' hrs : ' + minutes + ' mins';
          }
        } else if (_duration < 120 && _duration >= 60) {
          hours = 1;
          minutes = moment.duration(_duration, 'minutes').minutes();

          if (minutes === 0) {
            _durationDisplay = '1 hr';
          } else {
            _durationDisplay = '1 hr : ' + minutes + ' mins';
          }
        } else {
          minutes = moment.duration(_duration, 'minutes').minutes();
          _durationDisplay = minutes + ' mins';
        }
        return _durationDisplay;
      };

      function buildDateHeader(startTimeRange) {
        $scope.dateHeaderDisplay = [];
        let _startTimeRange;
        if (isString(startTimeRange)) {
          _startTimeRange = startTimeRange;
        } else {
          _startTimeRange = startTimeRange.format(TimeFormatService.format('api'));
        }

        if ($scope.dateRangeDisplay === 'week') {
          for (let i = 0; i < 7; i++) {
            const dayLabel = moment(_startTimeRange, TimeFormatService.format('api')).startOf('week').add(i, 'd');
            $scope.dateHeaderDisplay.push(dayLabel);
          }
        } else {
          const dayLabel = moment(_startTimeRange, TimeFormatService.format('api')).format('ddd Do');
          $scope.dateHeaderDisplay.push(dayLabel);
        }
      }

      $scope.$watch('dateRangeDisplay', function () {
        buildDateHeader($scope.startTimeRange);
      });

      $scope.$watch('startTimeRange', function () {
        buildDateHeader($scope.startTimeRange);
      });

      // Duration calculation and display
      function sessionDurationAddable(session) {
        if (session.state.type === 7) {
          // ignore canceled sessions
          return false;
        }

        if (!session.child || (session.child && session.association)) {
          return true;
        }
      }

      $scope.returnTotalDuration = function (parentObj) {
        let duration = 0;
        if ($scope.selectedFilterList && $scope.selectedFilterList.length > 0) {
          angular.forEach(parentObj.session_list, function (session) {
            if ($scope.dynamicFilters(session) && sessionDurationAddable(session)) {
              duration += FormatHelperService.getDurationVal(
                session,
                {
                  start: $scope.startTimeRange,
                  end: $scope.endTimeRange,
                },
                TimeFormatService.format('api')
              );
            }
          });
        } else {
          duration = parentObj.durationTotal;
        }
        return $scope.formatDurationDisplay(duration);
      };

      // filter to only show those sessions with displayValue true
      $scope.displayValue = function (obj) {
        return obj.displayValue;
      };

      // Dynamic filter selection
      $scope.$watch('filterList', function (newVal, oldVal) {
        if (newVal != oldVal) {
          $scope.selectedFilterList = newVal;
        }
      });

      // Filter on parent obj if no sessions or no sessions matching filter then false
      $scope.dynamicFiltersParentObj = function (parentObj) {
        const sessionList = parentObj.session_list;
        if ($scope.selectedFilterList && $scope.selectedFilterList.length > 0) {
          const values = [];

          if (sessionList.length > 0) {
            angular.forEach(sessionList, function (session) {
              values.push($scope.dynamicFilters(session));
            });

            // If 1 true is found then return true to display row
            if (values.indexOf(true) !== -1) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          // no dynamic filters selected
          return true;
        }
      };

      // Filter on individual sessions
      $scope.dynamicFilters = function (session) {
        if ($scope.selectedFilterList && $scope.selectedFilterList.length > 0) {
          let val = true;
          angular.forEach($scope.selectedFilterList, function (filter) {
            const sessionAttrib = session.attrib_list.filter(function (obj) {
              return obj.attrib_id == filter.attrib_id;
            })[0];

            if (!sessionAttrib || !FormatHelperService.returnAttribComparisionValue(sessionAttrib, filter)) {
              val = false;
              return;
            }
          });

          return val;
        } else {
          // no dynamic filters selected
          return true;
        }
      };

      // For if client clicks header while in week view to view a day
      $scope.dayView = function (label) {
        const dayClicked = angular.copy(label);
        $scope.dateRangeDisplay = 'day';
        $scope.startTimeRange = moment(dayClicked).clone().startOf('d').format(TimeFormatService.format('api'));
        $scope.endTimeRange = moment(dayClicked).clone().endOf('d').format(TimeFormatService.format('api'));
        buildDateHeader($scope.startTimeRange);

        const startTime = moment($scope.startTimeRange).clone().format(TimeFormatService.format('api'));
        $scope.$emit('dayViewClicked', startTime, $scope.endTimeRange);
      };

      // Schedule actions popover
      let timelineContainerWidth;
      $scope.popover = {
        scheduleActions: 'app/views/templates/calendar_schedule_actions.html',
        providerScheduleActions: 'app/views/templates/calendar_provider_schedule_actions.html',
        loggedInStatus: 'app/views/templates/avatar_loggedin_status_legend.html',
        jobOrder: 'app/views/templates/schedule_popover.html',
        calculatePlacement: function (id) {
          if (!timelineContainerWidth) {
            timelineContainerWidth = document.getElementsByClassName('timeline-container')[0].offsetWidth;
          }
          const elem = document.getElementById(id);

          if (elem) {
            if (parseInt(elem.style.left) > timelineContainerWidth * 0.5) {
              return 'left';
            }
          }
          return 'right';
        },
      };

      sessionManagerService
        .getRealmAttribs(enums.Realms.Service /*, $scope.entFilter.enterprise_id*/)
        .then((result) => {
          $scope.internalNotesPresent = result.attrib_list.find((x) => x.name === 'notes_internal');
        });

      const unwatch = $scope.$watch(
        function () {
          return $('div.popover').length;
        },
        function (length) {
          if (length === 1) {
            $timeout(function () {
              $('body').addClass('popover-open');
              const popover = $('div.popover');
              const calendarAction = popover.find('.calendar-action');
              const calendarHeight = calendarAction.outerHeight();

              if (calendarHeight + parseInt(popover.offset().top) > $(window).innerHeight()) {
                calendarAction.css('margin-top', -calendarHeight + 50 + 'px');
              }

              popover.css('visibility', 'visible');
            });
          } else {
            $('body').removeClass('popover-open');
          }
        }
      );

      $scope.$on('$destroy', unwatch);

      // Schedule actions dropdown
      $scope.dropdownActions = function (action, session) {
        if (action === 'cancel') {
          removeSessionService.doCancel(session.session_id, session);
          trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'cancel');
        } else if (action === 'remove') {
          removeSessionService.doRemove(session.session_id, session);
          trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'remove');
        } else if (action === 'cancelRemove') {
          removeSessionService.doCancelAndRemove(session.session_id, session);
          trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'cancel remove');
        }
      };

      // Modal trigger based on drop down
      $scope.openModal = function (job, session, action) {
        let _controller;
        let _templateUrl = 'app/views/schedule/actionsModal.html';
        if (action === 'assign') {
          _controller = 'AssignmentModalController';
          trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'assign');
        } else if (action === 'escalate') {
          _controller = 'EscalationModalController';
          trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'escalation');
        } else if (action === 'change') {
          _controller = 'ChangeTimeModalController';
          trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'change time');
        } else if (action === 'checkout') {
          _controller = 'CheckOutModalController';
          trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'check out');
        } else if (action === 'viewDetails') {
          _controller = 'SessionDetailsModalController';
          trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'session details');
        } else if (action === 'checkin') {
          _controller = 'CheckInModalController';
          trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'check in');
        } else if (action === 'scheduleJob') {
          _controller = 'NewScheduleJobModalController';
          _templateUrl = 'app/views/schedule/scheduleJobModal.html';
        } else if (action === 'changeJobOrder' || action === 'changeJobAssignment') {
          _controller = 'ChangeJobOrderModalController';
          _templateUrl = 'app/views/schedule/changeJobOrderModal.html';
        } else if (action === 'changeSessionAssignment') {
          _controller = 'ChangeSessionAssignmentModalController';
          _templateUrl = 'app/views/session/changeAssignmentModal.html';
        } else if (action === 'editJobOrder') {
          _controller = 'EditJobOrderModalController';
          _templateUrl = 'app/views/schedule/editJobOrderModal.html';
        } else if (action === 'showInterested') {
          _controller = 'ShowInterestedProvidersModalController';
          _templateUrl = 'app/views/session/showInterestedModal.html';
        } else if (action === 'offerInterested') {
          _controller = 'OfferInterestedProvidersModalController';
          _templateUrl = 'app/views/session/interestActionModal.html';
        } else if (action === 'assignInterested') {
          _controller = 'AssignInterestedProvidersModalController';
          _templateUrl = 'app/views/session/interestActionModal.html';
        } else if (action === 'updateRequestAttribs') {
          _controller = 'UpdateRequestAttribsModalController';
          _templateUrl = 'app/views/session/updateRequestAttribsModal.html';
        } else if (action === 'endAssignment') {
          _controller = 'EndAssignmentModalController';
        }

        $modal.open({
          animation: true,
          templateUrl: _templateUrl,
          controller: _controller,
          size: 'lg',
          backdrop: 'static',
          resolve: {
            passedJob: function () {
              return job;
            },
            passedSession: function () {
              return session;
            },
            passedEnt: function () {
              return $scope.entFilter;
            },
            enterprise_selection: function () {
              return $scope.entFilter;
            },
            passed_job_id: function () {
              return (job && job.job_id) || (session && session.jobId);
            },
            start_time: function () {
              return null;
            },
            passedOperation: function () {
              return action;
            },
            passed_job_order_id: function () {
              return null;
            },
            passed_current_worker_count: function () {
              return null;
            },
            passed_total_worker_count: function () {
              return null;
            },
          },
        });
      };

      $scope.openChangeTimeModal = function (session) {
        trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'change time');
        sessionManagerService
          .setSession(session.session_id, $scope.entFilter.enterprise_id)
          .then(function (serverSession) {
            serverSession.start = session.start;
            serverSession.end = session.end;
            $modal.open({
              animation: true,
              templateUrl: 'app/views/schedule/actionsModal.html',
              controller: 'ChangeTimeModalController',
              size: 'lg',
              backdrop: 'static',
              resolve: {
                passedSession: function () {
                  return serverSession;
                },
              },
            });
          });
      };

      // Provider view actions
      $scope.openProviderActionsModal = function (provider, session, action) {
        let _controller;
        const _templateUrl = 'app/views/schedule/actionsModal.html';
        if (action === 'assign') {
          _controller = 'AssignmentModalController';
          trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'assign');
        } else if (action === 'escalate') {
          _controller = 'EscalationModalController';
          trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'escalation');
        } else if (action === 'change') {
          _controller = 'ChangeTimeModalController';
          trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'change time');
        } else if (action === 'checkout') {
          _controller = 'CheckOutModalController';
          trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'check out');
        } else if (action === 'viewDetails') {
          _controller = 'SessionDetailsModalController';
          trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'session details');
        } else if (action === 'checkin') {
          _controller = 'CheckInModalController';
          trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'check in');
        } else if (action === 'endAssignment') {
          _controller = 'EndAssignmentModalController';
        }

        $modal.open({
          animation: true,
          templateUrl: _templateUrl,
          controller: _controller,
          size: 'lg',
          backdrop: 'static',
          resolve: {
            passedJob: function () {
              return provider;
            },
            passedSession: function () {
              return session;
            },
            passedEnt: function () {
              return $scope.entFilter;
            },
            passed_job_order_id: function () {
              return null;
            },
            passed_current_worker_count: function () {
              return null;
            },
            passed_total_worker_count: function () {
              return null;
            },
          },
        });
      };

      $scope.replicateSessionModal = function (origSession) {
        trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'replicate');
        sessionManagerService.setSession(origSession.session_id).then(function (session) {
          $modal.open({
            animation: true,
            templateUrl: 'app/views/session/requestSessionModal.html',
            controller: 'ReplicateSessionModalController',
            size: 'lg',
            backdrop: 'static',
            resolve: {
              enterprise_selection: function () {
                return $scope.entFilter;
              },
              passedSession: function () {
                return origSession;
              },
            },
          });
        });
      };

      // Modal trigger for transfer Split
      $scope.transferSplitModal = function (session) {
        trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'transfer split');
        const modalInstance = $modal.open({
          animation: true,
          templateUrl: 'app/views/schedule/transferSplitModal.html',
          controller: 'TransferSplitModalController',
          size: 'lg',
          backdrop: 'static',
          resolve: {
            passedSession: function () {
              return session;
            },
            passedEnt: function () {
              return $scope.entFilter;
            },
            passed_job_order_id: function () {
              return null;
            },
          },
        });
        modalInstance.result.then(function (response) {
          if (response.status && response.status.type == 2) {
            // committed
            sessionManagerService.setSession(response.request_id).then(function (session) {
              $scope.openModal({}, session, 'checkin');
            });
          }
        });
      };

      // Change Verify  Modal
      $scope.changeVerifyModal = function (session) {
        trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'change verify');

        $modal.open({
          animation: true,
          templateUrl: 'app/views/reporting/verify/changeVerifyModal.html',
          controller: 'ChangeVerifyModalController',
          size: 'lg',
          backdrop: 'static',
          resolve: {
            selectedSession: function () {
              return session;
            },
          },
        });
      };

      // Job review was clicked
      $scope.reviewSession = function (session) {
        trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'view session');
        // Toggle Job Div Show
        sessionManagerService.setSession(session.session_id, $scope.entFilter.enterprise_id).then(
          function () {
            $timeout(function () {
              location.href = '/#/job';
            }, 80);
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
          }
        );
      };

      $scope.approveSession = function (session) {
        trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'assign');
        sessionManagerService.setSession(session.session_id).then(function (session) {
          $modal.open({
            animation: true,
            templateUrl: 'app/views/session/requestSessionModal.html',
            controller: 'WaitingApprovalModalController',
            size: 'lg',
            backdrop: 'static',
            resolve: {
              enterprise_selection: function () {
                return $scope.entFilter;
              },
            },
          });
        });
      };

      $scope.resendSession = async function (session) {
        trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'resend');
        if (session.bundled) {
          const config = {
            title: 'Resend',
            text: `This ${$scope.keySession} is part of a job order. What would you like to resend?`,
            showCancelButton: true,
            confirmButtonColor: '#cc514c',
            cancelButtonText: `Just this ${$scope.keySession}`,
            confirmButtonText: `All ${$scope.keySession}s`,
            icon: 'warning',
          };

          const returnHandler = function (answer) {
            if (answer && !isNaN(session.user_id)) {
              // Committed sessions should be automatically resent as 0/1
              sessionManagerService
                .resendJobOrder({
                  job_order_id: session.jobOrderId,
                  user_id: session.user_id,
                })
                .then(
                  function (response) {
                    if (response.success) {
                      AlertService.successAlert(response.message);
                    } else {
                      AlertService.errorAlert(response.message);
                    }
                  },
                  function (error) {
                    AlertService.serverRequestErrorAlert(error);
                  }
                );
            } else {
              $scope.openResendModal(session, answer);
            }
          };

          const { value: returnValue } = await Swal.fire(config);
          returnHandler(returnValue);
        } else {
          $scope.openResendModal(session);
        }
      };

      $scope.openResendModal = function (calendarSession, resendJobOrder) {
        sessionManagerService.setSession(calendarSession.session_id).then(function (session) {
          let controller, templateUrl;
          if (resendJobOrder) {
            controller = 'ResendJobOrderModalController';
            templateUrl = 'app/views/session/resendJobOrderModal.html';
          } else {
            controller = 'ResendModalController';
            templateUrl = 'app/views/session/requestSessionModal.html';
          }

          $modal.open({
            animation: true,
            templateUrl,
            controller,
            size: 'lg',
            backdrop: 'static',
            resolve: {
              enterprise_selection: function () {
                return $scope.entFilter;
              },
              passedSession: function () {
                // console.log(calendarSession);
                return resendJobOrder ? calendarSession : session;
              },
            },
          });
        });
      };

      $scope.getProfileUrl = function (picture) {
        return DOMAIN + picture;
      };

      $scope.assignText = function (session) {
        if ((session.bundled && isNaN(session.user_id)) || session.state.name === 'Open') {
          return 'Schedule';
        } else if (!isNaN(session.user_id)) {
          return 'Cancel and Schedule';
        } else {
          return 'Cancel and Schedule';
        }
      };

      $scope.escalateText = function (session) {
        return session.state.name === 'Open' ? 'Offer' : 'Cancel and Offer';
      };

      let tableBodyWidth;
      let columnLabelWidth;

      $scope.sessionsLoaded = _.debounce(() => {
        tableBodyWidth = tableBodyWidth || $('#time-table-body').width();
        columnLabelWidth = columnLabelWidth || $('#time-table-label-column:visible').width();
        const rowBodyWidth = tableBodyWidth - columnLabelWidth;
        $('.timeline-container').width(rowBodyWidth - 22);
      }, 0);

      window.addEventListener('resize', function () {
        tableBodyWidth = null;
        columnLabelWidth = null;
        $scope.sessionsLoaded();
      });
    },
  ]) // end of controller)
  .directive('dirCalendarScheduleTimeline', [
    function () {
      return {
        restrict: 'E',
        transclude: true,
        scope: {
          jobsArray: '=',
          providersArray: '=',
          dateRangeDisplay: '=',
          typeFilter: '=',
          entFilter: '=',
          startTimeRange: '=',
          endTimeRange: '=',
          filterList: '=',
          search: '=',
          totalWorkersHours: '=',
          showTotalWorkers: '=',
          onSearchChanged: '&',
        },
        controller: 'dirCalendarScheduleTimelineController',
        templateUrl: 'app/views/templates/calendar.html',
      };
    },
  ]);
