import angular from 'angular';
import module from '../module';
export default module.controller('EditMeetingPlaceModalController', [
  '$scope',
  '$modalInstance',
  'passedLocation',
  'passedPlace',
  'AlertService',
  function ($scope, $modalInstance, passedLocation, passedPlace, AlertService) {
    $scope.submitBtnDisbaled = false;
    $scope.title = 'Edit Meeting Place';
    $scope.submitBtn = 'Update Meeting Place';
    $scope.locationTitle = 'Selected Location';

    $scope.location = passedLocation;
    $scope.passedPlace = angular.copy(passedPlace);
    $scope.formData = {
      name: $scope.passedPlace.name,
      place_id: $scope.passedPlace.place_id,
    };

    // Modal controls
    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      $scope.location.updatePlace($scope.formData).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
          } else {
            AlertService.errorAlert(response.message);
          }
          $modalInstance.close($scope.location);
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    };
    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  },
]);
