import angular from 'angular';
import module from '../module';
import * as enums from '../../enums';

export default module.controller('timeReportsChangeMobileModalController', [
  '$scope',
  '$modalInstance',
  '$rootScope',
  'session',
  'sendDrafts',
  function ($scope, $modalInstance, $rootScope, session, sendDrafts) {
    $scope.durationIcon = `${enums.AssetsFolder.svg}duration.svg`;

    const originalSession = angular.copy(session);
    $scope.session = session;

    $scope.cancel = function () {
      if (!sendDrafts) {
        Object.keys(originalSession).forEach((value) => {
          session[value] = originalSession[value];
        });
      }
      $modalInstance.close('cancel');
    };

    const unbindHandler = $rootScope.$on('CloseMobileChangeModal', function () {
      $modalInstance.close();
    });

    $scope.$on('$destroy', function () {
      unbindHandler();
    });
  },
]);
