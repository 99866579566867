import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { CalendarView } from 'angular-calendar';
import { SecureHttpService } from '../../shared/services/secure.http.service';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { WorkerEvent } from '../models/worker-event';
import { GeneralDataService } from '../../shared/services/general.data.service';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(private httpService: SecureHttpService, private generalDataService: GeneralDataService) {}

  public fetchEvents(currentDate: Date, period: CalendarView, providerId: number): Observable<WorkerEvent[]> {
    const dates = this.calculateDates(currentDate, period);
    return this.generalDataService.getKeyAttribute().pipe(
      switchMap((attribute) => {
        return this.httpService
          .post('get_my_requests', {
            ...dates,
            system_filters: [{ type: 1, options: [providerId] }],
          })
          .pipe(
            map((response) => {
              return response.request_list.map((req) => ({
                id: req.request_id,
                session_id: req.request_id,
                start: moment.utc(`${req.request_date} ${req.request_time}`).local().toDate(),
                end: moment
                  .utc(`${req.request_date} ${req.request_time}`)
                  .add(req.attrib_list.find((x) => x.name == 'duration').value_count, 'm')
                  .local()
                  .toDate(),
                title: this.getRequestTitle(req, attribute),
                status: req.status,
                duration: req.attrib_list.find((x) => x.name == 'duration').value_count,
                enterprise: req.enterprise_name,
              }));
            })
          );
      })
    );
  }

  private getRequestTitle(request, attribute) {
    const attrib = request.attrib_list.find((x) => x.name == attribute.value);
    if (attrib) {
      return attrib.value_text || attrib.value_list[0]?.name;
    }
    return '';
  }

  public getRequests(providerId: number) {
    return this.httpService
      .post('get_my_requests', {
        system_filters: [{ type: 1, options: [providerId] }],
      })
      .pipe(
        map((req) => req.request_list),
        map((list) => {
          const numberOfJobs = list.filter((x) => x.job_order_id).length;
          const numberOfShifts = list.filter((x) => !x.job_order_id).length;
          const futureJobs = list.filter(
            (x) => x.job_order_id && moment(`${x.request_date} ${x.request_time}`).isAfter(moment())
          ).length;
          const late = list.filter(
            (x) =>
              x.checkin_date &&
              moment(`${x.checkin_date} ${x.checkin_time}`).isAfter(moment(`${x.request_date} ${x.request_time}`))
          ).length;

          const latePercentage = Math.ceil((late / list.length) * 100) || 0;

          const cancelled = list.filter((req) => req.cancelled_reason === 5).length;
          const cancelledPercentage = Math.ceil((cancelled / list.length) * 100) || 0;

          return {
            numberOfJobs,
            numberOfShifts,
            futureJobs,
            latePercentage,
            cancelledPercentage,
          };
        })
      );
  }

  private calculateDates(currentDate: Date, period: CalendarView) {
    const date = moment(currentDate);

    return {
      start_date: date.clone().startOf(period).subtract(1, 'day').format('YYYYMMDD HHmm'),
      end_date: date.clone().endOf(period).add(1, 'day').format('YYYYMMDD HHmm'),
    };
  }
}
