import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseWidget } from './base.widget';
import * as fromRoot from '../store';
import * as _ from 'lodash';

@Component({
  selector: 'shift-worker-location-component',
  templateUrl: './shift.worker.location.component.html',
})
export class ShiftWorkerLocationComponent extends BaseWidget {
  public customTitle: string;
  public xAxisLabel = '# Workers';
  public yAxisLabel = '# Sessions';
  public chartSize = [400, 270];

  constructor(store: Store<fromRoot.State>) {
    super(store);
  }

  loadDataTemplate(list) {
    if (!list) {
      return;
    }
    this.store
      .select(fromRoot.getKeySession)
      .subscribe((keySession) => {
        this.customTitle = `${keySession} Worker Location`;
        this.yAxisLabel = `# ${keySession}s`;
      })
      .unsubscribe();
    let locations = list.map((x) => x.loc_name || 'Unknown');

    let map = list.map((x) => {
      return {
        location: x.loc_name || 'Unknown',
        worker: `${x.serv_first_name} ${x.serv_last_name}`,
      };
    });

    this.data = _.uniq(locations).map((locationName) => {
      let dataPoint = map.filter((x) => x.location === locationName);
      let workers = _.uniq(dataPoint.map((x) => x.worker));
      return {
        name: locationName,
        series: [
          {
            name: '',
            y: dataPoint.length,
            x: workers.length,
            r: 30,
          },
        ],
      };
    });
  }
}
