import module from './module';
export default module
  .filter('providerPoolFilter', [function () {
    return function (items, poolProviders) {
      if (!poolProviders || !items) {
        return items;
      }
      return items.filter(function (provider) {
        return poolProviders.indexOf(provider.provider_id) !== -1;
      });
    };
  }]);