import angular from 'angular';
import module from '../module';
import * as enums from '../../enums';
export default module.controller('NewPriceModalController', [
  '$scope',
  '$modalInstance',
  'priceManagerService',
  'entFilter',
  'FormatHelperService',
  'providerManagerService',
  'AlertService',
  '$localStorage',
  'AuthenticationService',
  'SplitPriceService',
  'generalDataService',
  'PERMISSIONS',
  'sessionManagerService',
  function (
    $scope,
    $modalInstance,
    priceManagerService,
    entFilter,
    FormatHelperService,
    providerManagerService,
    AlertService,
    $localStorage,
    AuthenticationService,
    SplitPriceService,
    generalDataService,
    PERMISSIONS,
    sessionManagerService
  ) {
    $scope.keyProfession = generalDataService.getKeyProfession();
    $scope.keySession = generalDataService.getKeySession();
    const CURRENT_USER = AuthenticationService.getCurrentUser();
    $scope.hasAttribPermission = CURRENT_USER.permissionCheck(PERMISSIONS.PRICE_ATTRIBS);
    $scope.currencySymbol = AuthenticationService.getCurrencySymbol();
    $scope.priceStructures = SplitPriceService.priceStructures;
    $scope.submitBtnDisbaled = false;
    $scope.submitBtnText = 'Create Price';
    $scope.entFilter = entFilter;
    $scope.filterStaff = true;
    $scope.formData = {
      cancel_policy: false,
      provider_only: false,
      order: 10,
      trigger_list: [
        {
          trigger_event_id: 11,
          minimum: false,
          travel: false,
          billing_rate_unit_id: 2,
          split_flag: true,
          price_structure_split: false,
        },
      ],
      price_structure_split: true,
      marketplace_portion_type: 'Percent',
      marketplace_portion: 100,
      marketplace_pct_of: 'Total',
      service_portion_type: 'Dollar',
      service_rate_unit: 2,
    };
    $scope.title = 'Add a New Price';

    $scope.getRealmObject = function () {
      const result = {
        realm_list: [
          {
            realm: 9,
          },
        ],
      };
      if ($scope.entFilter && $scope.entFilter.enterprise_id !== -1) {
        result.enterprise_id = $scope.entFilter.enterprise_id;
      }

      return result;
    };

    priceManagerService.getPriceAttribs($scope.getRealmObject()).then(
      function (response) {
        if (response.success) {
          const formData = response.attrib_list.sort(function (a, b) {
            return a.order - b.order;
          });
          $scope.originalFormData = angular.copy(formData);
          $scope.attrib_list = angular.copy(formData);
          $scope.rate_unit_list = response.rate_unit_list;
          $scope.currency_list = response.currency_list;
          $scope.trigger_list = response.trigger_list;
          $scope.billing_rate_attrib_id_choices = response.billing_rate_attrib_id_choices;
        }
      },
      function () {
        AlertService.serverRequestErrorAlert('Unable to load pricing attribute list');
        $modalInstance.close();
      }
    );

    $scope.addTrigger = function () {
      const triggerIndex = $scope.formData.trigger_list.length - 1;
      if ($scope.formData.trigger_list[triggerIndex]) {
        $scope.formData.trigger_list[triggerIndex] = {
          trigger_event_id: $scope.formData.trigger_list[triggerIndex].trigger_event_id,
          billing_rate: $scope.formData.trigger_list[triggerIndex].billing_rate,
          billing_rate_unit_id: $scope.formData.trigger_list[triggerIndex].billing_rate_unit_id,
          minimum: $scope.formData.trigger_list[triggerIndex].minimum || false,
          minimum_rate: $scope.formData.trigger_list[triggerIndex].minimum_rate,
          travel: $scope.formData.trigger_list[triggerIndex].travel || false,
          travel_rate: $scope.formData.trigger_list[triggerIndex].travel_rate,
          travel_rate_unit_id: $scope.formData.trigger_list[triggerIndex].travel_rate_unit_id,
          split_flag: $scope.formData.trigger_list[triggerIndex].split_flag || false,
          billing_rate_attrib_id: $scope.formData.trigger_list[triggerIndex].billing_rate_attrib_id,
        };
      }
      $scope.formData.trigger_list.push({ minimum: false, travel: false, billing_rate_unit_id: 2, split_flag: true });
    };
    $scope.removeTrigger = function () {
      if ($scope.formData.trigger_list.length < 2) {
        AlertService.warningAlert('At least one trigger is required');
      } else {
        $scope.formData.trigger_list.pop();
      }
    };
    $scope.setTravelDefaults = function (trigger) {
      trigger.travel_rate_unit_id = 2;
      trigger.travel_rate = '';
    };

    // Modal controls
    $scope.ok = function () {
      if (!$scope.validatePriceStructure()) {
        return;
      }
      $scope.submitBtnDisbaled = true;
      // ensure last trigger in triggers away
      const last_item = $scope.formData.trigger_list.length - 1;
      $scope.formData.trigger_list[last_item] = {
        trigger_event_id: $scope.formData.trigger_list[last_item].trigger_event_id,
        billing_rate: $scope.formData.trigger_list[last_item].billing_rate,
        billing_rate_unit_id: $scope.formData.trigger_list[last_item].billing_rate_unit_id,
        minimum: $scope.formData.trigger_list[last_item].minimum || false,
        minimum_rate: $scope.formData.trigger_list[last_item].minimum_rate,
        travel: $scope.formData.trigger_list[last_item].travel || false,
        travel_rate: $scope.formData.trigger_list[last_item].travel_rate,
        travel_rate_unit_id: $scope.formData.trigger_list[last_item].travel_rate_unit_id,
        split_flag: $scope.formData.trigger_list[last_item].split_flag || false,
        billing_rate_attrib_id: $scope.formData.trigger_list[last_item].billing_rate_attrib_id || null,
      };
      formatData();
    };

    const formatData = function () {
      if (CURRENT_USER.user_type == 2) {
        $scope.formData.market_place_id = CURRENT_USER.market_place_info.id;
        if ($scope.entFilter.enterprise_id != -1) {
          $scope.formData.enterprise_id = $scope.entFilter.enterprise_id;
        }
      }

      const formatedAttribList = FormatHelperService.format_data($scope.attrib_list);
      if (formatedAttribList.length > 0) {
        $scope.formData.attrib_list = formatedAttribList;
      }

      if ($scope.selectedProviders) {
        $scope.formData.provider_list = $scope.selectedProviders.map(function (provider) {
          return {
            provider_id: provider.provider_id,
          };
        });
      }

      angular.forEach($scope.formData.trigger_list, function (trigger) {
        if (trigger.travel == false) {
          delete trigger.travel_rate_unit_id;
          delete trigger.travel_rate;
        }
        if (trigger.minimum == false) {
          delete trigger.minimum_rate;
        }
      });

      sendToServer($scope.formData);
    };

    const sendToServer = function (priceObj) {
      priceManagerService.addNewPrice(priceObj).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
          } else {
            AlertService.errorAlert(response.message);
          }
          $modalInstance.close();
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    // listen for instance_count attrib clicks
    $scope.$on('addAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      const _count = field.instanceCountIndex || 1;
      const _field = angular.copy(
        $scope.originalFormData.filter(function (obj) {
          return obj.attrib_id == field.attrib_id;
        })[0]
      );
      _field.instanceCountIndex = _count + 1;
      _field.showRemoveInstance = true;
      $scope.attrib_list[index].doNotShowAddInstance = true;
      $scope.attrib_list[index].showRemoveInstance = false;
      $scope.attrib_list.splice(index + 1, 0, _field); // add after current field
    });

    $scope.$on('removeAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      $scope.attrib_list[index - 1].doNotShowAddInstance = false;
      $scope.attrib_list[index - 1].showRemoveInstance = true;
      $scope.attrib_list.splice(index, 1);
    });

    // Override multiselect label
    $scope.translation = {
      nothingSelected: 'All',
      selectAll: 'Select All',
      selectNone: 'Select None',
      reset: 'Done',
    };

    $scope.portionTypeChanged = function (value) {
      const currentValue = $scope.formData[value.portionType];
      if (currentValue === 'Dollar') {
        delete $scope.formData[value.percentOf];
        $scope.formData[value.rateUnit] = 2; // 2 is Hour
      } else if (currentValue === 'Percent') {
        delete $scope.formData[value.rateUnit];
        $scope.formData[value.percentOf] = 'Total';
      } else {
        delete $scope.formData[value.rateUnit];
        delete $scope.formData[value.percentOf];
      }
    };

    $scope.validatePriceStructure = function () {
      if ($scope.formData.price_structure_split) {
        if (SplitPriceService.validateMinPrice($scope.formData) === false) {
          AlertService.warningAlert('Amount is higher then bill rate.');
          return false;
        }
        if (SplitPriceService.validateTotalPercentages($scope.formData) === false) {
          AlertService.warningAlert('Your split percentage do not add to 100.');
          return false;
        }
        if (SplitPriceService.validateZeroPrice($scope.formData) === false) {
          AlertService.warningAlert('0 is not allowed for prices.');
          return false;
        }
        SplitPriceService.removeOrphanedData($scope.formData);
      }
      return true;
    };

    $scope.billingRateChange = function (trigger) {
      if (trigger.billing_rate <= 0) {
        trigger.billing_rate = 1;
      }
    };
  },
]);
