import module from '../module';

export default module.directive('sessionDirective', [
  function () {
    return {
      restrict: 'E',
      templateUrl: 'app/views/session/index.html',
      controller: 'SessionController',
    };
  },
]);
