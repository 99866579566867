import { createFeatureSelector, createSelector, Store, select } from '@ngrx/store';
import { State } from 'app/store';
import { WorkersState } from './state';
import { Injectable } from '@angular/core';
import { map, filter } from 'rxjs/operators';
import { FetchWorkerDetails } from './actions';
import { Observable } from 'rxjs';
import { CalendarView } from 'angular-calendar';
import { WorkerEvent } from '../../shared/models/worker-event';

const selectRoot = createFeatureSelector<State>('workers');
const selectFeature = createSelector(selectRoot, (state: any) => state);

const selectWorkers = createSelector(selectFeature, (state: WorkersState) => state.list);

const selectTab = createSelector(selectFeature, (state: WorkersState) => state.selectedTab);

const selectCalendarView = createSelector(selectFeature, (state: WorkersState) => state.selectedCalendarView);

const selectEvents = createSelector(selectFeature, (state: WorkersState) => state.events);

const selectBlacklistedEnterprises = createSelector(
  selectFeature,
  (state: WorkersState) => state.blacklistedEnterprises
);

const selectBlacklistedWorkers = createSelector(selectFeature, (state: WorkersState) => state.blacklistedWorkers);

@Injectable({ providedIn: 'root' })
export class WorkersSelectors {
  constructor(private _store: Store<State>) {}

  getWorkerListItem(userId: number, providerId: number) {
    return this._store.pipe(
      select(selectWorkers),
      filter((x) => !!x),
      map((list) => list[providerId]),
      map((worker) => {
        if (!worker || !worker.detailsLoaded) {
          worker = {
            detailsLoaded: false,
          };
          this._store.dispatch(new FetchWorkerDetails(userId, providerId));
        }
        return worker;
      }),
      filter((worker) => worker.detailsLoaded)
    );
  }

  getSelectedTab(): Observable<number> {
    return this._store.pipe(select(selectTab));
  }

  getSelectedCalendarView(): Observable<CalendarView> {
    return this._store.pipe(select(selectCalendarView));
  }

  getEvents(): Observable<WorkerEvent[]> {
    return this._store.pipe(select(selectEvents));
  }

  getEnterpriseBlacklistForWorker(userId: number): Observable<{ id: string; name: string }[]> {
    return this._store.pipe(
      select(selectBlacklistedEnterprises),
      map((x) => x[userId])
    );
  }

  getWorkerBlacklistForEnterprise(
    enterpriseId: number
  ): Observable<{ id: number; first_name: string; last_name: string }[]> {
    return this._store.pipe(
      select(selectBlacklistedWorkers),
      map((x) => x[enterpriseId])
    );
  }
}
