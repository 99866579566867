import module from './module';
import moment from 'moment';

export default module.factory('FormatHelperService', [
  'timeZoneStore',
  'TimeFormatService',
  function (timeZoneStore, TimeFormatService) {
    return {
      // /////////////////////////DYNAMIC FORM FIELD FORMATTER FOR SERVER OBJ/////////////////////////
      format_data: function (formData) {
        const attribs = []; // to hold attrib values

        function attribFormater(attrib) {
          switch (attrib.type) {
            case 'single_list':
              if (attrib.name === 'from_language') {
                let selected_obj;
                if (attrib.value_list) {
                  selected_obj = attrib.value_list.filter(function (obj) {
                    return obj.selected == true;
                  })[0];
                } else {
                  selected_obj = attrib.choice_list.filter(function (obj) {
                    return obj.selected == true;
                  })[0];
                }
                return {
                  attrib_id: attrib.attrib_id,
                  value_single_list: selected_obj.choice_id,
                  name: attrib.name,
                };
              } else {
                if (attrib.value && attrib.value.length) {
                  return {
                    attrib_id: attrib.attrib_id,
                    value_single_list: attrib.value[0] && attrib.value[0].choice_id,
                    name: attrib.name,
                  };
                } else if (attrib.value) {
                  return {
                    attrib_id: attrib.attrib_id,
                    value_single_list: attrib.value.choice_id,
                    name: attrib.name,
                  };
                  // For the worker realm, add the delete flag so that attribs can be removed
                }
              }
              break;
            case 'multi_list':
              let obj;
              if (attrib.value && attrib.value.length > 0) {
                // return new array of just choice_id objs
                const selected_list = attrib.value.map(function (obj) {
                  return {
                    choice_id: obj.choice_id,
                  };
                });
                obj = {
                  attrib_id: attrib.attrib_id,
                  value_multi_list: selected_list,
                  name: attrib.name,
                };
              } else if (
                attrib.value &&
                attrib.name === 'staff_provider_list' &&
                typeof attrib.value.choice_id !== 'undefined'
              ) {
                obj = {
                  attrib_id: attrib.attrib_id,
                  value_multi_list: [
                    {
                      choice_id: attrib.value.choice_id,
                    },
                  ],
                  name: attrib.name,
                };
                // For the worker realm, add the delete flag so that attribs can be removed
              } else {
                obj = {
                  attrib_id: attrib.attrib_id,
                  value_multi_list: [],
                  name: attrib.name,
                };
              }
              return obj;
            case 'location':
              if (attrib.value) {
                if (attrib.value.location_id > 0) {
                  return {
                    attrib_id: attrib.attrib_id,
                    value_location: {
                      location_id: attrib.value.location_id,
                    },
                    name: attrib.name,
                  };
                } else if (attrib.value.details) {
                  // if first submission and manually entered address
                  const addressArray = attrib.value.details.address_components;
                  const zip = addressArray.filter(function (obj) {
                    return obj.types[0] === 'postal_code';
                  })[0];
                  const state = addressArray.filter(function (obj) {
                    return obj.types[0] === 'administrative_area_level_1';
                  })[0];
                  const city = addressArray.filter(function (obj) {
                    return obj.types[0] === 'locality' || obj.types[0] === 'sublocality_level_1';
                  })[0];
                  const country = addressArray.filter(function (obj) {
                    return obj.types[0] === 'country';
                  })[0];
                  let fullCity;

                  if (country && country.short_name !== 'US' && city) {
                    fullCity = city.long_name + ',' + country.long_name;
                  }

                  return {
                    attrib_id: attrib.attrib_id,
                    value_location: {
                      city: fullCity || (city || {}).long_name,
                      state: (state || {}).long_name,
                      zip_code: (zip || {}).long_name,
                      longitude: attrib.value.details.geometry.location.lng(),
                      latitude: attrib.value.details.geometry.location.lat(),
                      address_line_1: attrib.value.details.name,
                      name: attrib.value.details.name,
                    },
                    name: attrib.name,
                  };
                } else {
                  // if approving request
                  return {
                    attrib_id: attrib.attrib_id,
                    value_location: {
                      city: attrib.value.city,
                      state: attrib.value.state,
                      zip_code: attrib.value.zip_code,
                      longitude: attrib.value.longitude,
                      latitude: attrib.value.latitude,
                      address_line_1: attrib.value.address_line_1,
                      address_line_2: attrib.value.address_line_2,
                      name: attrib.value.name,
                    },
                    name: attrib.name,
                  };
                }
              }
              break;
            case 'place':
              if (attrib.value) {
                return {
                  attrib_id: attrib.attrib_id,
                  value_place: {
                    place_id: attrib.value.place_id,
                    name: attrib.value.name,
                  },
                  name: attrib.name,
                };
              }
              break;
            case 'text':
              if (attrib.value) {
                return {
                  attrib_id: attrib.attrib_id,
                  value_text: attrib.value,
                  name: attrib.name,
                };
              }
              break;
            case 'count':
              if (attrib.value || attrib.value_count) {
                return {
                  attrib_id: attrib.attrib_id,
                  value_count: attrib.value || attrib.value_count,
                  name: attrib.name,
                };
              }
              break;
            case 'decimal':
              if (attrib.value || attrib.value_decimal) {
                return {
                  attrib_id: attrib.attrib_id,
                  value_decimal: attrib.value || attrib.value_decimal,
                  name: attrib.name,
                };
              }
              break;
            case 'date_time':
              if (!attrib.value) {
                return null;
              }
              let date_time = attrib.value_text || attrib.value;

              if (!attrib.value._isUTC) {
                date_time = moment
                  .utc(date_time.skipTimezoneAlignment ? attrib.value : timeZoneStore.calcTime(attrib.value))
                  .format(TimeFormatService.format('fullDateTimeNoSeparator')); // format to UTC and then string text
              } else {
                date_time = moment.utc(date_time).format(TimeFormatService.format('fullDateTimeNoSeparator'));
              }

              return {
                attrib_id: attrib.attrib_id,
                value_text: date_time,
                name: attrib.name,
              };

            case 'date_range':
              if (!attrib.value) {
                return null;
              }
              const start = moment(attrib.value.start).format(TimeFormatService.format('fullDate'));
              const end = moment(attrib.value.end).format(TimeFormatService.format('fullDate'));
              return {
                attrib_id: attrib.attrib_id,
                value: {
                  start,
                  end,
                },
                name: attrib.name,
              };
            case 'date':
              if (!attrib.value) {
                return null;
              }
              const date = moment(timeZoneStore.calcTime(attrib.value)).format(
                TimeFormatService.format('fullDateNoSeparator')
              );

              return {
                attrib_id: attrib.attrib_id,
                value_text: date,
                name: attrib.name,
              };
            case 'flag':
              return {
                attrib_id: attrib.attrib_id,
                value_flag: attrib.value,
                name: attrib.name,
              };
            case 'password':
              if (attrib.value) {
                return {
                  attrib_id: attrib.attrib_id,
                  value_text: attrib.value,
                  name: attrib.name,
                };
              }
              break;
            case 'image':
              if (attrib.jpeg) {
                return {
                  attrib_id: attrib.attrib_id,
                  value_image: attrib.jpeg,
                  name: attrib.name,
                  record_id: attrib.record_id,
                };
              } else if (!attrib.jpeg && attrib.value) {
                return {
                  attrib_id: attrib.attrib_id,
                  value_image: attrib.value.base64,
                  name: attrib.name,
                  record_id: attrib.record_id,
                };
              } else if (attrib.delete_flag) {
                return {
                  attrib_id: attrib.attrib_id,
                  name: attrib.name,
                  record_id: attrib.record_id,
                  delete_flag: attrib.delete_flag,
                };
              }
              break;
          } // switch end
        } // end of attrib formatter

        // Child Attribs loop
        function childAttribs(child_attribs) {
          const childAttribs = [];
          for (let i = 0; i < child_attribs.length; i++) {
            const childAttrib = attribFormater(child_attribs[i]);
            childAttrib && childAttribs.push(childAttrib);
          }
          return childAttribs;
        }

        // Main attrib loop
        for (let i = 0; i < formData.length; i++) {
          const formatedObj = attribFormater(formData[i]);
          if (formatedObj && formData[i].child_attribs) {
            const childAttribsArray = childAttribs(formData[i].child_attribs);
            childAttribsArray.length > 0 && (formatedObj.child_attribs = childAttribsArray);
          }
          formatedObj && attribs.push(formatedObj);
        }

        return attribs;
      },

      format_data_back: function (formData) {
        const attribs = []; // to hold attrib values

        function attribFormater(attrib) {
          switch (attrib.type) {
            case 'single_list':
              let selected_obj;
              if (attrib.value_list) {
                selected_obj = attrib.value_list.filter(function (obj) {
                  return obj.selected == true;
                })[0];
              } else {
                selected_obj = attrib.choice_list.filter(function (obj) {
                  return obj.selected == true;
                })[0];
              }
              return {
                attrib_id: attrib.attrib_id,
                value_single_list: selected_obj ? selected_obj.choice_id : null,
                name: attrib.name,
              };
            case 'multi_list':
              let selected_list;
              if (attrib.value_list && attrib.value_list.length > 0) {
                // return new array of just choice_id objs
                selected_list = attrib.value_list
                  .filter(function (obj) {
                    return obj.selected;
                  })
                  .map(function (obj) {
                    return {
                      choice_id: obj.choice_id,
                    };
                  });
              } else if (attrib.choice_list) {
                selected_list = attrib.choice_list
                  .filter(function (obj) {
                    return obj.selected;
                  })
                  .map(function (obj) {
                    return {
                      choice_id: obj.choice_id,
                    };
                  });
              }
              return {
                attrib_id: attrib.attrib_id,
                value_multi_list: selected_list,
                name: attrib.name,
              };
            case 'location':
              if (attrib.value_location && attrib.value_location.length) {
                const loc = attrib.value_location.find(function (vals) {
                  return vals.selected;
                });
                if (loc) {
                  return {
                    attrib_id: attrib.attrib_id,
                    value_location: {
                      location_id: loc.location_id,
                    },
                    name: attrib.name,
                  };
                }
              }
              break;
            case 'place':
              if (attrib.value_place && attrib.value_place.length) {
                const loc = attrib.value_place.find(function (vals) {
                  return vals.selected;
                });
                if (loc) {
                  return {
                    attrib_id: attrib.attrib_id,
                    value_place: {
                      place_id: loc.place_id,
                      name: loc.name,
                    },
                    name: attrib.name,
                  };
                }
              }
              break;
            case 'text':
              if (attrib.value_text) {
                return {
                  attrib_id: attrib.attrib_id,
                  value: attrib.value_text,
                  name: attrib.name,
                };
              }
              break;
            case 'count':
              if (attrib.value_count) {
                return {
                  attrib_id: attrib.attrib_id,
                  value_count: attrib.value_count,
                  name: attrib.name,
                };
              }
              break;
            case 'decimal':
              if (attrib.value_decimal) {
                return {
                  attrib_id: attrib.attrib_id,
                  value_decimal: attrib.value_decimal,
                  name: attrib.name,
                };
              }
              break;
            case 'date_time':
              const date_time = moment(attrib.value_text, TimeFormatService.format('fullDateTimeNoSeparator')).format(
                TimeFormatService.format('fullDateTimeNoSeparator')
              ); // format to UTC and then string text
              return {
                attrib_id: attrib.attrib_id,
                value_text: date_time,
                name: attrib.name,
              };
            case 'date':
              return {
                attrib_id: attrib.attrib_id,
                value_text: attrib.value_text,
                name: attrib.name,
              };
            case 'flag':
              return {
                attrib_id: attrib.attrib_id,
                value_flag: attrib.value_flag,
                name: attrib.name,
              };
            case 'password':
              if (attrib.value) {
                return {
                  attrib_id: attrib.attrib_id,
                  value_text: attrib.value,
                  name: attrib.name,
                };
              }
              break;
            case 'image':
              if (attrib.jpeg) {
                return {
                  attrib_id: attrib.attrib_id,
                  value_image: attrib.jpeg,
                  name: attrib.name,
                  record_id: attrib.record_id,
                };
              } else if (!attrib.jpeg && attrib.value) {
                return {
                  attrib_id: attrib.attrib_id,
                  value_image: attrib.value.base64,
                  name: attrib.name,
                  record_id: attrib.record_id,
                };
              } else if (attrib.delete_flag) {
                return {
                  attrib_id: attrib.attrib_id,
                  name: attrib.name,
                  record_id: attrib.record_id,
                  delete_flag: attrib.delete_flag,
                };
              }
              break;
          } // switch end
        } // end of attrib formatter

        // Child Attribs loop
        function childAttribs(child_attribs) {
          const childAttribs = [];
          for (let i = 0; i < child_attribs.length; i++) {
            const childAttrib = attribFormater(child_attribs[i]);
            childAttrib && childAttribs.push(childAttrib);
          }
          return childAttribs;
        }

        // Main attrib loop
        for (let i = 0; i < formData.length; i++) {
          const formatedObj = attribFormater(formData[i]);
          if (formatedObj && formData[i].child_attribs) {
            const childAttribsArray = childAttribs(formData[i].child_attribs);
            childAttribsArray.length > 0 && (formatedObj.child_attribs = childAttribsArray);
          }
          formatedObj && attribs.push(formatedObj);
        }

        return attribs;
      },
      // /////////////////////////DYNAMIC FORM FIELD FORMATTER/////////////////////////
      // ////////////////////////////////SORT FUNCTIONS////////////////////////////////
      returnSortedValue: function (A, B) {
        if (A < B) {
          return 1;
        } else if (A > B) {
          return -1;
        } else if (A == B) {
          return 0;
        }
      },
      // /////////////////////////////////TIME MOMENT//////////////////////////////////
      returnLocalTimeString: function (value, toFormat) {
        // takes moment obj
        const localTime = moment.utc(value).local().clone();
        if (localTime.isValid()) {
          return localTime.format(toFormat);
        } else {
          return 'N/A';
        }
      },
      returnUtcString: function (value, toFormat) {
        // takes moment obj
        const _utc = value.utc().clone();
        if (_utc.isValid()) {
          return _utc.format(toFormat);
        } else {
          return null;
        }
      },
      // /////////////////////////////////ATTRIBUTE DISPLAY////////////////////////////
      // DURATION FORMATTER
      returnDurationDisplayVal: function (value, showEmptyWhenNull) {
        let days, hours, minutes;
        const _value = value;
        if (_value && _value > 0) {
          if (_value >= 1400) {
            days = moment.duration(_value, 'minutes').days();
            hours = moment.duration(_value, 'minutes').hours();
            minutes = moment.duration(_value, 'minutes').minutes();
            return days + ' days : ' + hours + ' hrs : ' + minutes + ' mins';
          } else if (_value < 1400 && _value >= 120) {
            hours = moment.duration(_value, 'minutes').hours();
            minutes = moment.duration(_value, 'minutes').minutes();
            return hours + ' hrs : ' + minutes + ' mins';
          } else if (_value < 120 && _value >= 60) {
            hours = 1;
            minutes = moment.duration(_value, 'minutes').minutes();
            return '1 hr : ' + minutes + ' mins';
          } else {
            minutes = moment.duration(_value, 'minutes').minutes();
            return minutes + ' mins';
          }
        } else if (value === 0) {
          return '0 mins';
        } else if (showEmptyWhenNull) {
          return '';
        } else {
          return 'N/A';
        }
      },
      // For getting vals when calendar filters engaged
      getDurationVal: function (session, dateRange, format) {
        return session.duration;
      },
      // Name
      returnFullName: function (firstName, lastName) {
        if (firstName && lastName) {
          return firstName + ' ' + lastName;
        } else if (firstName) {
          return firstName;
        } else if (lastName) {
          return lastName;
        } else {
          return 'N/A';
        }
      },
      // /////////////////////////ATTRIBUTE VALUE RETREIVAL////////////////////////////
      returnAttribComparisionValue: function (attribObj, filterObj) {
        return nonWidgetType(attribObj, filterObj);

        function nonWidgetType(attrib, filterObj) {
          let values;
          switch (attrib.type) {
            case 'text':
              if (!filterObj.value_text) {
                return true;
              } else {
                return attrib.value_text.indexOf(filterObj.value_text) > -1;
              }
            case 'location':
              if (!filterObj.value_location) {
                return true;
              } else {
                if (!attrib.value_location) {
                  return true;
                } else {
                  return attrib.value_location[0].location_id === filterObj.value_location.location_id;
                }
              }
            case 'single_list':
              if (attrib.choice_list) {
                values = attrib.choice_list.filter(function (obj) {
                  return obj.selected == true;
                })[0];
              } else if (attrib.value_list) {
                values = attrib.value_list.filter(function (obj) {
                  return obj.selected == true;
                })[0];
              } else {
                return false;
              }
              if ((values || {}).choice_id === filterObj.value_single_list) {
                return true;
              } else {
                return false;
              }
            case 'multi_list': // multi list
              if (attrib.choice_list) {
                values = attrib.choice_list.filter(function (obj) {
                  return obj.selected == true;
                });
              } else if (attrib.value_list) {
                values = attrib.value_list.filter(function (obj) {
                  return obj.selected == true;
                });
              } else {
                return false;
              }

              const choiceIds = values.map(function (obj) {
                return obj.choice_id;
              });

              // If not the same number of ids then it does NOT match filter
              if (filterObj.value_multi_list.length !== choiceIds.length) {
                return false;
              } else {
                let val = true;
                for (let i = 0; i < filterObj.value_multi_list.length; i++) {
                  if (choiceIds.indexOf(filterObj.value_multi_list[i]?.choice_id) === -1) {
                    val = false;
                    return;
                  }
                }
                return val;
              }
          } // end switch`
        } // end nonWidgetFunction
      },
      // //////////////////////////ATTRIBUTE VALUE RETREIVAL///////////////////////////
      // Internet Explorer 6-11
      isIE: function () {
        if (/* @cc_on!@ */ false || !!document.documentMode) {
          return true;
        }
      },
      // Opera 8.0 +
      isOpera: function () {
        if ((!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0) {
          return true;
        }
      },
      // Firefox 1.0+
      isFireFox: function () {
        if (typeof InstallTrigger !== 'undefined') {
          return true;
        }
      },
      // At least Safari 3+: "[object HTMLElementConstructor]"
      isSafari: function () {
        if (Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0) {
          return true;
        }
      },
      // Edge 20+
      isEdge: function () {
        if (!this.isIE && !!window.StyleMedia) {
          return true;
        }
      },
      // Chrome 1+
      isChrome: function () {
        if (!!window.chrome && !!window.chrome.webstore) {
          return true;
        }
      },
      generateDurationLabel: function (input) {
        const months = parseInt(input / (60 * 24 * 30));
        input -= months * 60 * 24 * 30;
        const days = parseInt(input / (60 * 24));
        input -= days * 60 * 24;
        const hours = parseInt(input / 60);
        input -= hours * 60;
        const minutes = input;

        if (months > 0) {
          return `${months} mnths ${days} days ${hours} hrs ${minutes} mins`;
        } else if (days > 0) {
          return `${days} days ${hours} hrs ${minutes} mins`;
        } else if (hours > 0) {
          return `${hours} hrs ${minutes} mins`;
        } else {
          return `${input} mins`;
        }
      },
    };
  },
]);
