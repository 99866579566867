import module from '../module';
export default module.controller('imageViewerModalController', [
  '$scope',
  '$modalInstance',
  'passedImage',
  'base64Image',
  function ($scope, $modalInstance, passedImage, base64Image) {
    $scope.imageUrl = passedImage;
    $scope.base64Image = base64Image;

    $scope.ok = function () { };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  },
]);
