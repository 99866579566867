import { Action } from '@ngrx/store';

export const WORKER_FILTER_CHANGE = '[Dashboard] Worker filter change';
export const BRANCH_FILTER_CHANGE = '[Dashboard] Branch filter change';
export const ENTERPRISE_FILTER_CHANGE = '[Dashboard] Enterprise filter change';
export const TIME_FILTER_CHANGE = '[Dashboard] Time filter change';
export const CATEGORY_FILTER_CHANGE = '[Dashboard] Category filter change';
export const WIDGETS_CHANGED = '[Dashboard] Widgets changed';
export const FETCH_MY_REQUESTS = '[Dashboard] Fetch my requests';
export const LOADED_MY_REQUESTS = '[Dashboard] Loaded my requests';
export const TIME_RANGE_CHANGED = '[Dashboard] Time range changed';
export const SELECTED_WIDGET_CHANGED = '[Dashboard] Selected widget changed';
export const FETCH_ENTERPRISES = '[Dashboard] Fetch enterprises';
export const LOADED_ENTERPRISES = '[Dashboard] Loaded enterprises';
export const FETCH_KEY_ATTRIBUTE = '[Dashboard] Fetch key attribute';
export const LOADED_KEY_ATTRIBUTE = '[Dashboard] Loaded key attribute';
export const FETCH_WORKERS = '[Dashboard] Fetch workers';
export const LOADED_WORKERS = '[Dashboard] Loaded workers';
export const FILTER_REQUEST_LIST = '[Dashboard] Filter request list';
export const FILTER_REPORT_LIST = '[Dashboard] Filter report list';
export const TOGGLE_WIDGET = '[Dashboard] Toggle widget';
export const WIDGET_SORT_CHANGED = '[Dashboard] Widget order changed';
export const DRAG_MODE_CHANGED = '[Dashboard] Drag mode changed';
export const LOADED_JOBS = '[Dashboard] Loaded jobs';
export const HAS_VALID_FILTER = '[Dashboard] Request list has valid filter';
export const FETCH_REQUEST_DETAILS = '[Dashboard] Fetch request details';
export const LOADED_REQUEST_DETAILS = '[Dashboard] Request details loaded';
export const LOADED_VERIFY_REPORT = '[Dashboard] Loaded verify report';
export const STAFF_FILTER_CHANGE = '[Dashboard] Staff filter changed';
export const EXPORT_TO_CSV = '[Dashboard] Export to CSV Widget';
export const EXPORT_TO_CSV_DETAILS = '[Dashboard] Export to CSV Details';
export const EXPORT_TO_PDF_DETAILS = '[Dashboard] Export to PDF Details';
export const CHECK_PERMISSION = '[Dashboard] Check permission';
export const PERMISSIONS_CHANGED = '[Dashboard] Permissions changed';
export const FETCH_PROVIDER_DETAILS = '[Dashboard] Fetch provider details';
export const LOADED_PROVIDER_DETAILS = '[Dashboard] Provider details loaded';
export const LOADED_KEY_SESSION = '[Dashboard] Loaded key session';
export const FETCH_KEY_SESSION = '[Dashboard] Fetch key session';
export const LOADED_KEY_PROFESSION = '[Dashboard] Loaded key profession';
export const FETCH_KEY_PROFESSION = '[Dashboard] Fetch key profession';
export const HOURLY_FILTER_CHANGE = '[Dashboard] Hourly Filter Change';

import { RequestListFilter } from '../';

export class WorkerFilterChange implements Action {
  readonly type = WORKER_FILTER_CHANGE;
  readonly value: string;

  constructor(value) {
    this.value = value;
  }
}

export class BranchFilterChange implements Action {
  readonly type = BRANCH_FILTER_CHANGE;
  readonly branchId: number;

  constructor(value) {
    this.branchId = value;
  }
}
export class EnterpriseFilterChange implements Action {
  readonly type = ENTERPRISE_FILTER_CHANGE;
  readonly enterpriseId: number;
  readonly branchOrigin: boolean;

  constructor(value, branchOrigin = false) {
    this.enterpriseId = value;
    this.branchOrigin = branchOrigin;
  }
}

export class TimeFilterChange implements Action {
  readonly type = TIME_FILTER_CHANGE;
  readonly timeFilterId: number;

  constructor(value) {
    this.timeFilterId = value;
  }
}

export class CategoryFilterChange implements Action {
  readonly type = CATEGORY_FILTER_CHANGE;
  readonly categoryFilterId: number;

  constructor(value) {
    this.categoryFilterId = value;
  }
}

export class WidgetsChanged implements Action {
  readonly type = WIDGETS_CHANGED;
  readonly selectedWidgets: any[];
  readonly allWidgets: any[];

  constructor(selected, all) {
    this.selectedWidgets = selected;
    this.allWidgets = all;
  }
}

export class FetchMyRequests implements Action {
  readonly type = FETCH_MY_REQUESTS;
}

export class LoadedMyRequests implements Action {
  readonly type = LOADED_MY_REQUESTS;
  readonly requests: any[];

  constructor(value) {
    this.requests = value;
  }
}

export class TimeRangeChanged implements Action {
  readonly type = TIME_RANGE_CHANGED;
  readonly timeRange: any;

  constructor(value) {
    this.timeRange = value;
  }
}

export class SelectedWidgetChanged implements Action {
  readonly type = SELECTED_WIDGET_CHANGED;
  readonly widget: any;

  constructor(value) {
    this.widget = value;
  }
}

export class FetchEnterprises implements Action {
  readonly type = FETCH_ENTERPRISES;
}

export class LoadedEnterprises implements Action {
  readonly type = LOADED_ENTERPRISES;
  readonly enterprises: any[];

  constructor(value) {
    this.enterprises = value;
  }
}

export class FetchKeyAttribute implements Action {
  readonly type = FETCH_KEY_ATTRIBUTE;
}

export class LoadedKeyAttribute implements Action {
  readonly type = LOADED_KEY_ATTRIBUTE;
  readonly keyAttribute: any;

  constructor(value) {
    this.keyAttribute = value;
  }
}

export class FetchWorkers implements Action {
  readonly type = FETCH_WORKERS;
}

export class LoadedWorkers implements Action {
  readonly type = LOADED_WORKERS;
  readonly workerInfo: any;

  constructor(value) {
    this.workerInfo = value;
  }
}

export class FilterRequestList implements Action {
  readonly type = FILTER_REQUEST_LIST;
  readonly filter: RequestListFilter;

  constructor(value) {
    this.filter = value;
  }
}

export class FilterReportList implements Action {
  readonly type = FILTER_REPORT_LIST;
  readonly filter: RequestListFilter;

  constructor(filter) {
    this.filter = filter;
  }
}

export class ToggleWidget implements Action {
  readonly type = TOGGLE_WIDGET;
  readonly widget: any;
  readonly status: boolean;

  constructor(widget, status) {
    this.widget = widget;
    this.status = status;
  }
}

export class WidgetOrderChanged implements Action {
  readonly type = WIDGET_SORT_CHANGED;
  readonly widgets: any[];
  readonly category: number;

  constructor(widgets, category) {
    this.widgets = widgets;
    this.category = category;
  }
}

export class DragModeChanged implements Action {
  readonly type = DRAG_MODE_CHANGED;
  readonly dragMode: boolean;

  constructor(value) {
    this.dragMode = value;
  }
}

export class LoadedJobs implements Action {
  readonly type = LOADED_JOBS;
  readonly jobs: any[];

  constructor(jobs) {
    this.jobs = jobs;
  }
}

export class HasValidFilter implements Action {
  readonly type = HAS_VALID_FILTER;
  readonly value: boolean;

  constructor(value) {
    this.value = value;
  }
}

export class FetchRequestDetails implements Action {
  readonly type = FETCH_REQUEST_DETAILS;
  readonly id: number;

  constructor(id) {
    this.id = id;
  }
}

export class LoadedRequestDetails implements Action {
  readonly type = LOADED_REQUEST_DETAILS;
  readonly request: any;

  constructor(request) {
    this.request = request;
  }
}

export class LoadedVerifyReport implements Action {
  readonly type = LOADED_VERIFY_REPORT;
  readonly report: any;

  constructor(report) {
    this.report = report;
  }
}

export class StaffFilterChange implements Action {
  readonly type = STAFF_FILTER_CHANGE;
  readonly code: number;

  constructor(code) {
    this.code = code;
  }
}

export class ExportToCsv implements Action {
  readonly type = EXPORT_TO_CSV;
  readonly data;
  readonly widget;

  constructor(data, widget) {
    this.data = data;
    this.widget = widget;
  }
}

export class ExportToCsvDetails implements Action {
  readonly type = EXPORT_TO_CSV_DETAILS;
  readonly labels: string[];
  readonly data: any[];
  readonly name: string;

  constructor(name, labels, data) {
    this.name = name;
    this.labels = labels;
    this.data = data;
  }
}

export class ExportToPdfDetails implements Action {
  readonly type = EXPORT_TO_PDF_DETAILS;
  readonly labels: string[];
  readonly data: any[];
  readonly name: string;

  constructor(name, labels, data) {
    this.name = name;
    this.labels = labels;
    this.data = data;
  }
}

export class CheckPermission implements Action {
  readonly type = CHECK_PERMISSION;

  constructor(public permissionId: number) {}
}

export class PermissionsChanged implements Action {
  readonly type = PERMISSIONS_CHANGED;

  constructor(public permissionId: number, public value: any) {}
}

export class FetchProviderDetails implements Action {
  readonly type = FETCH_PROVIDER_DETAILS;

  constructor(public userId: number) {}
}

export class LoadedProviderDetails implements Action {
  readonly type = LOADED_PROVIDER_DETAILS;

  constructor(public details: any) {}
}

export class FetchKeySession implements Action {
  readonly type = FETCH_KEY_SESSION;
}

export class LoadedKeySession implements Action {
  readonly type = LOADED_KEY_SESSION;

  constructor(public keySession: string) {}
}

export class FetchKeyProfession implements Action {
  readonly type = FETCH_KEY_PROFESSION;
}

export class LoadedKeyProfession implements Action {
  readonly type = LOADED_KEY_PROFESSION;

  constructor(public keyProfession: string) {}
}

export class HourlyFilterChange implements Action {
  readonly type = HOURLY_FILTER_CHANGE;

  constructor(public hourlyId: number) {}
}

export type Actions =
  | WorkerFilterChange
  | BranchFilterChange
  | EnterpriseFilterChange
  | TimeFilterChange
  | CategoryFilterChange
  | HourlyFilterChange
  | WidgetsChanged
  | LoadedMyRequests
  | TimeRangeChanged
  | SelectedWidgetChanged
  | FetchEnterprises
  | LoadedEnterprises
  | FetchKeyAttribute
  | LoadedKeyAttribute
  | FetchWorkers
  | LoadedWorkers
  | FilterRequestList
  | ToggleWidget
  | WidgetOrderChanged
  | DragModeChanged
  | LoadedJobs
  | HasValidFilter
  | FetchRequestDetails
  | LoadedRequestDetails
  | LoadedVerifyReport
  | FilterReportList
  | ExportToCsv
  | ExportToCsvDetails
  | ExportToPdfDetails
  | CheckPermission
  | PermissionsChanged
  | FetchProviderDetails
  | LoadedProviderDetails
  | FetchKeySession
  | LoadedKeySession
  | FetchKeyProfession
  | LoadedKeyProfession
  | StaffFilterChange;
