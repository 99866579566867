import { FooterComponent } from './components/footer/footer.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { COMPONENTS as SHARED_COMPONENTS, COMPONENT_SERVICES } from './components/index';
import { SERVICES } from './services/index';
import { TabComponent } from './components/tab-component/tab.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ProfileFieldPipe } from './pipes/profile-field.pipe';
import { CalendarModule } from 'angular-calendar';
import { ProfilePicturePipe } from './pipes/profile-picture.pipe';
import { ProfileAttribComponent } from './components/profile-attrib-component/profile-attrib.component';
import { DurationInputComponent } from './components/duration-input/duration-input.component';
import { TimeInputComponent } from './components/time-input/time-input.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgxMaskModule } from 'ngx-mask';
import { AutofocusDirective } from './directives/auto-focus.directive';
import { formatHelperServiceProvider, locationManagerServiceProvider, LocalStorageProvider } from '../upgraded.providers';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { MatOption } from '@angular/material/core';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MenuComponent } from './components/menu/menu.component';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { CommonEffect } from './store/effects';
import { GoogleMapsModule } from '@angular/google-maps';

const PIPES = [ProfileFieldPipe, ProfilePicturePipe];
const COMPONENTS = [
  TabComponent,
  ProfileAttribComponent,
  DurationInputComponent,
  TimeInputComponent,
  CustomSelectComponent,
  MenuComponent,
  FooterComponent,
  GoogleMapComponent,
];
const MODULES = [
  BrowserModule,
  BrowserAnimationsModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  CalendarModule,
  TimepickerModule,
  NgxMaskModule,
  MatLegacySelectModule,
  ScrollingModule,
  NgxMatSelectSearchModule,
  RouterModule,
  GoogleMapsModule,
];
const DIRECTIVES = [AutofocusDirective];

@NgModule({
  imports: [CommonModule, EffectsModule.forFeature([CommonEffect]), ...MODULES],
  declarations: [COMPONENTS, ...SHARED_COMPONENTS, ...PIPES, ...DIRECTIVES],
  exports: [FormsModule, COMPONENTS, ...SHARED_COMPONENTS, ...MODULES, ...PIPES, ...DIRECTIVES],
  providers: [SERVICES, COMPONENT_SERVICES, locationManagerServiceProvider, formatHelperServiceProvider, LocalStorageProvider],
  entryComponents: [CustomSelectComponent],
})
export class SharedModule { }

angular.module('ServetureWebApp').directive('appSelect', downgradeComponent({ component: CustomSelectComponent }));
angular.module('ServetureWebApp').directive('appGoogleMap', downgradeComponent({ component: GoogleMapComponent }));
