import moment from 'moment';
import module from './module';
import * as enums from '../enums';

export default module.directive('dirBreakPopup', [
  'timeReportCsvService',
  function (timeReportCsvService) {
    return {
      restrict: 'E',
      scope: {
        type: '@',
        session: '=',
        isReadOnly: '=',
        close: '&',
        recalculateBreakHeight: '&',
      },
      templateUrl: 'app/views/templates/break_popup.html',
      link: function (scope, element, attrs) {
        scope.types = {
          break: 'break',
          total: 'total',
        };

        scope.openTab = 1;
        scope.icons = {
          edit: `${enums.AssetsFolder.svg}edit-lines.svg`,
          delete: `${enums.AssetsFolder.svg}invitations/delete.svg`,
        };

        scope.timePickerConfig = {
          hoursStep: 1,
          minuteStep: 1,
          mouseWheel: true,
        };

        scope.calculateDuration = function (section) {
          if (section.breakIn && section.breakOut) {
            if (moment(section.breakIn).isBefore(moment(section.breakOut))) {
              section.breakDuration = moment(section.breakOut)
                .startOf('minute')
                .diff(moment(section.breakIn).startOf('minute'), 'm');
            } else {
              section.breakDuration = moment(section.breakOut)
                .startOf('minute')
                .add(1, 'd')
                .diff(moment(section.breakIn).startOf('minute'), 'm');
            }
          }
          section.invalid = section.breakDuration <= 0;
          if (!section.invalid && scope.breakSections.length > 1) {
            section.invalid = scope.overlapCheck(section) && 'overlap';
          }

          section.breakInOpen = false;
          section.breakOutOpen = false;

          section.displayDuration = timeReportCsvService.getDurationDisplayVal(section.breakDuration);

          scope.refreshTotaltime();
        };

        const hasOverlap = (start1, end1, start2, end2) => {
          const toDate = (input) => (typeof input === 'string' ? new Date(input) : input);

          return toDate(start1) <= toDate(end2) && toDate(start2) <= toDate(end1);
        };

        scope.overlapCheck = function (currentSection) {
          for (let i = 0; i < scope.breakSections.length; i++) {
            if (currentSection === scope.breakSections[i]) {
              continue;
            }
            if (
              hasOverlap(
                currentSection.breakIn,
                currentSection.breakOut,
                scope.breakSections[i].breakIn,
                scope.breakSections[i].breakOut
              )
            ) {
              return true;
            }
          }

          return false;
        };

        scope.calculateTotalTime = function () {
          if (scope.breakSections.length === 0) {
            return 0;
          }

          const result = scope.breakSections
            .filter((x) => !x.delete)
            .filter((x) => !isNaN(x.breakDuration))
            .filter((x) => !x.invalid)
            .map((x) => x.breakDuration)
            .reduce((a, b) => a + b, 0);

          scope.totalTimeInvalid = result > scope.session.request_total_time;
          return result;
        };

        scope.saveChanges = function () {
          const totalTime = scope.calculateTotalTime();
          scope.session.break_time_hours = Math.floor(totalTime / 60);
          scope.session.break_time_minutes = totalTime % 60;
          scope.session.breakSections = scope.breakSections;
        };

        scope.discardDuration = function (section) {
          section.breakInOpen = false;
          section.breakOutOpen = false;

          scope.refreshTotaltime();
        };

        scope.addSection = function () {
          scope.breakSections.push({
            breakIn: null,
            breakOut: null,
            breakInOpen: false,
            breakOutOpen: false,
            breakDuration: 0,
            title: `${moment.localeData().ordinal(scope.breakSections.length + 1)}. Break`,
            isAdding: true,
            invalid: true,
          });
          scope.recalculateBreakHeight();
        };

        scope.openBreakIn = function (section) {
          const index = scope.breakSections.indexOf(section);

          scope.breakSections.forEach((section) => {
            section.breakInOpen = false;
            section.breakOutOpen = false;
          });
          scope.breakSections[index].breakInOpen = true;
          if (!scope.breakSections[index].breakIn) {
            scope.breakSections[index].breakIn = Date.now();
          }
        };

        scope.openBreakOut = function (section) {
          const index = scope.breakSections.indexOf(section);

          scope.breakSections.forEach((section) => {
            section.breakInOpen = false;
            section.breakOutOpen = false;
          });
          scope.breakSections[index].breakOutOpen = true;
          if (!scope.breakSections[index].breakOut) {
            scope.breakSections[index].breakOut = Date.now();
          }
        };

        scope.removeSection = function (section) {
          const index = scope.breakSections.indexOf(section);
          if (section.break_id) {
            section.delete = true;
          } else {
            scope.breakSections.splice(index, 1);
          }
          scope.refreshTotaltime();
          scope.recalculateBreakHeight();

          scope.breakSections
            .filter((s) => s.invalid === 'overlap')
            .forEach((section) => (section.invalid = scope.overlapCheck(section)));
        };

        scope.closePopup = function (event, session, isOk) {
          if (isOk) {
            scope.saveChanges();
          }
          if (event) {
            event.stopImmediatePropagation();
            event.preventDefault();
          }
          scope.close({ session, isOk });
        };

        scope.checkKey = function (event) {
          if (event.keyCode === 13 || event.which === 13 || event.key === 'Enter') {
            scope.close({ session: scope.session, isOk: true });
          }
        };

        scope.refreshTotaltime = function () {
          scope.totalTime = timeReportCsvService.getDurationDisplayVal(
            scope.breakSections.length ? scope.calculateTotalTime() : scope.session.break_total_time
          );

          if (!scope.totalTime) {
            scope.totalTime = timeReportCsvService.getDurationDisplayVal(0);
          }
        };

        if (scope.session.breakSections) {
          scope.breakSections = JSON.parse(JSON.stringify(scope.session.breakSections)).filter(
            (b) => b.breakDuration > 0
          );

          scope.breakSections.forEach((b, i) => {
            scope.calculateDuration(b);
            b.title = `${moment.localeData().ordinal(i + 1)}. Break`;
          });
        } else {
          scope.breakSections = [];
        }
        scope.refreshTotaltime();

        scope.saveSection = function (section) {
          section.isAdding = false;
          section.isEditing = false;
          scope.recalculateBreakHeight();
        };

        scope.editSection = function (section) {
          section.isEditing = true;
        };

        scope.isAdding = function () {
          return scope.breakSections.some((s) => s.isAdding || s.isEditing);
        };
      },
    };
  },
]);
