import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpgradeModule } from '@angular/upgrade/static';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { WorkersModule } from './workers/workers.module';
import { sessionServiceProvider } from './upgraded.providers';
import { AnalyticsModule } from './analytics/analytics.module';
import { DashboardModule } from './analytics/dashboard/dashboard.module';
import { NewReportingModule } from './analytics/new-reporting/new-reporting.module';
import { SharedModule } from './shared/shared.module';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { reducers, metaReducers } from './store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { WidgetEffects } from './analytics/dashboard/store/effects/widget.effects';
import { JobsModule } from './jobs/jobs.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { app } from '../app/scripts/app';
import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { ScheduleModule } from './schedule/schedule.module';
import { LoginModule } from './login/login.module';
import { ReportingModule } from './reporting/reporting.module';
import { AdminModule } from './admin/admin.module';
import { SupportingModule } from './support/support.module';
import { SmartLinkModule } from './smart-link/smart-link.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    UpgradeModule,
    FormsModule,
    ReactiveFormsModule,
    AnalyticsModule,
    DashboardModule,
    WorkersModule,
    JobsModule,
    ReportingModule,
    NewReportingModule,
    SharedModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    StoreDevtoolsModule.instrument(),
    EffectsModule.forRoot([WidgetEffects]),
    NgxMaskModule.forRoot(),
    AppRoutingModule,
    ScheduleModule,
    LoginModule,
    AdminModule,
    SupportingModule,
    SmartLinkModule,
  ],
  declarations: [AppComponent],
  entryComponents: [],
  providers: [sessionServiceProvider],
  bootstrap: [AppComponent],
})
export class AppModule {}
