export const generateTimeFromShorthand = (value: string): string => {
  let result: string;
  if (value.length < 4) {
    // 1,2,...9 should be 1:00, 2:00, 9:00
    if (value.length === 1) {
      result = `${value}:00`;
      // 00, 11, 22: 0:00, 1:10, 2:20
    } else if (value.length === 2) {
      const [firstNum, secondNum] = value;
      if (+secondNum < 6) {
        result = `0${firstNum}:${secondNum}0`;
      } else {
        result = `0${firstNum}:0${secondNum}`;
      }
      // 3:4, 5:6, 9:8: 3:40, 5:60, 9:08
    } else if (value.length === 3) {
      const [firstNum, secondNum] = value.split(':');
      if (+secondNum < 6) {
        result = `0${firstNum}:${secondNum}0`;
      } else {
        result = `0${firstNum}:0${secondNum}`;
      }
    }
    return result;
  } else {
    return value;
  }
};
