import module from './module';

export default module.factory('providerManagerService', [
  '$http',
  'BASE_URL',
  'ADMIN_API',
  '$q',
  'Provider',
  function ($http, BASE_URL, ADMIN_API, $q, Provider) {
    const providersManager = {
      _pool: {},
      _allRequestsCalled: false,
      _providersArray: [],
      _selectedProvider: {},
      _retrieveInstance: function (userId, providerData, dataType) {
        let instance = this._pool[userId];
        if (instance) {
          instance.setData(providerData, dataType);
        } else {
          instance = new Provider(providerData, dataType);
          instance.user_id = userId;
          this._pool[userId] = instance;
          this._providersArray.push(instance);
        }
        return instance;
      },
      _load: function (userId, deferred) {
        const scope = this;
        $http
          .post(BASE_URL + '/get_profile_info', { user_id: userId })
          .then(function (res) {
            const providerData = res.data;
            const provider = scope._retrieveInstance(userId, providerData, 'DETAIL_DISPLAY');

            const email = provider?.profile_info?.find((x) => x.name === 'email_address')?.data;
            if (email) {
              $http
                .get(BASE_URL + `/resubscribe_user_email?email=${email}`)
                .then((res) => {
                  const response = res.data;
                  if (response.success) {
                    deferred.resolve({
                      ...provider,
                      subscribedToEmail: response.reject.every((x) => x === '[]'),
                    });
                  } else {
                    deferred.resolve(provider);
                  }
                })
                .catch(() => deferred.resolve(provider));
            } else {
              deferred.resolve(provider);
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _loadAllProviders: function (deferred) {
        const scope = this;
        scope._allRequestsCalled = true;
        $http
          .post(BASE_URL + '/get_service_providers', {})
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              response.provider_list.forEach(function (providerData) {
                scope._retrieveInstance(providerData.user_id, providerData, 'TABLE_DISPLAY');
              });
              deferred.resolve(scope._providersArray);
            } else {
              deferred.reject();
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _loadFilteredProviders: function (filterObj, deferred) {
        $http
          .post(BASE_URL + '/get_service_providers', filterObj)
          .then(function (res) {
            const response = res.data;
            const result = [];
            if (response.success) {
              response.provider_list.forEach(function (providerData) {
                result.push(new Provider(providerData, 'DETAIL_DISPLAY'));
              });
              deferred.resolve({
                totalCount: response.total_count,
                providers: result,
              });
            } else {
              deferred.reject();
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _adminWorkerExtract: function (filterObj, deferred) {
        $http
          .post(BASE_URL + '/admin_worker_extract', filterObj)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _regNewProvider: function (providerDetails, deferred) {
        $http
          .post(BASE_URL + '/create_new_user', providerDetails)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _updateProvider: function (updateObj, deferred) {
        $http
          .post(BASE_URL + '/set_profile_info', updateObj)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function (response) {
            deferred.reject();
          });
      },
      _getQualifiedProviders: function (payload, deferred) {
        $http.post(BASE_URL + '/get_qualified_workers', payload).then(
          function (response) {
            deferred.resolve(response.data && response.data.provider_list);
          },
          function () {
            deferred.reject();
          }
        );
      },
      _removeInstance: function (provider, deferred) {
        $http.post(BASE_URL + '/delete_provider', { provider_id: provider.provider_id }).then(
          function (response) {
            deferred.resolve(response.data);
          },
          function () {
            deferred.reject();
          }
        );
      },
      _getProviderLocation: function (providerId, marketplaceId, deferred) {
        $http
          .post(BASE_URL + '/get_provider_locations', { provider_list: [providerId], market_place_id: marketplaceId })
          .then(
            function (response) {
              if (response.data && response.data.provider_list && response.data.provider_list.length) {
                deferred.resolve(response.data.provider_list[0]);
              } else {
                deferred.reject();
              }
            },
            function () {
              deferred.reject();
            }
          );
      },
      /* Public Methods */
      getProvider: function (userId) {
        const deferred = $q.defer();
        this._load(userId, deferred);
        return deferred.promise;
      },
      getAllProviders: function (requestType) {
        const deferred = $q.defer();
        const scope = this;
        //  scope._loadAllProviders(deferred);
        if (requestType === 'newProviderPresent' || scope._allRequestsCalled == false) {
          scope._loadAllProviders(deferred);
        } else {
          //  scope._loadAllProviders(deferred);
          const providers = scope._providersArray;
          if (providers.length > 0) {
            deferred.resolve(providers);
          } else {
            scope._loadAllProviders(deferred);
          }
        }
        return deferred.promise;
      },
      registerProvider: function (providerData) {
        const deferred = $q.defer();
        this._regNewProvider(providerData, deferred);
        return deferred.promise;
      },
      updateProviderAttribs: function (updateObj) {
        const deferred = $q.defer();
        this._updateProvider(updateObj, deferred);
        return deferred.promise;
      },
      getQualifiedProviders: function (payload) {
        const deferred = $q.defer();
        this._getQualifiedProviders(payload, deferred);
        return deferred.promise;
      },
      getFilteredProviders: function (filterObj) {
        const deferred = $q.defer();
        this._loadFilteredProviders(filterObj, deferred);
        return deferred.promise;
      },
      adminWorkerExtract: function (filterObj) {
        const deferred = $q.defer();
        this._adminWorkerExtract(filterObj, deferred);
        return deferred.promise;
      },
      getProviderLocation: function (providerId, marketplaceId) {
        const deferred = $q.defer();
        this._getProviderLocation(providerId, marketplaceId, deferred);
        return deferred.promise;
      },
      removeProvider: function (provider) {
        const deferred = $q.defer();
        this._removeInstance(provider, deferred);
        return deferred.promise;
      },
      resubscribeToEmails: function (email) {
        const deferred = $q.defer();
        $http
          .post(BASE_URL + '/resubscribe_user_email', { email })
          .then((res) => {
            const response = res.data;
            if (response?.success) {
              deferred.resolve(response);
            } else {
              deferred.reject();
            }
          })
          .catch(() => deferred.reject());
        return deferred.promise;
      },
      logConfidentialView: function (attrib) {
        const deferred = $q.defer();
        const payload = {
          conf_attr: attrib.name,
        };
        $http.post(ADMIN_API + '/events/create_confidential_log/', payload);

        return deferred.promise;
      },
    };
    return providersManager;
  },
]);
