import { IBranchList, IEnterpriseList } from '../interfaces';

export const createRealmKey = (enterpriseId: number, realmId: number): string =>
  `${enterpriseId === -1 ? undefined : enterpriseId}:${realmId}`;

export interface CommonState {
  branchList: IBranchList[];
  enterpriseList: IEnterpriseList[];
  selection: any;
  selectedBranch: any;
  realmAttribs: Record<number, { attrib_list?: any[]; success: boolean; message?: string }>;
  websocketMessages: Record<number, unknown[]>;
}

export const initialCommonState: CommonState = {
  branchList: null,
  enterpriseList: null,
  selection: {},
  selectedBranch: {},
  realmAttribs: {},
  websocketMessages: {},
};
