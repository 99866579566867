import module from '../../module';
import * as enums from '../../../enums';
import Swal from 'sweetalert2';

export default module.directive('dirEditTextMessage', function () {
  return {
    restrict: 'E',
    scope: {
      selectTemplate: '&',
      id: '=',
    },
    controller: [
      '$scope',
      '$timeout',
      'communicationsService',
      '$modal',
      'AlertService',
      async function ($scope, $timeout, communicationsService, $modal, AlertService) {
        $scope.cancel = async function () {
          if ($scope.emailForm.$dirty) {
            const config = {
              title: 'Discard changes?',
              text: 'You have made changes to the template, do you want to discard them?',
              confirmButtonText: 'Yes, discard the changes',
              cancelButtonText: 'No, go back',
              icon: 'warning',
              showConfirmButton: true,
              showCancelButton: true,
            };
            const { isConfirmed } = await Swal.fire(config);
            if (isConfirmed) {
              $scope.selectTemplate()(null, null);
            }
          } else {
            $scope.selectTemplate()(null, null);
          }
        };

        $scope.revertToDefault = async function () {
          const config = {
            title: 'Revert to the default template?',
            text: 'Are you sure you want to revert to the default text message template?',
            confirmButtonText: 'Yes, revert to default',
            cancelButtonText: 'No, go back',
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
          };
          const { isConfirmed } = await Swal.fire(config);
          if (isConfirmed) {
            const response = await communicationsService.revertTextMessageTemplateToDefault($scope.template);
            if (response) {
              AlertService.successAlert('Text message template reverted to the default');
              $scope.selectTemplate()(null, null);
            } else {
              AlertService.errorAlert('There was an error reverting the text message template');
            }
          }
        };

        $scope.revertToCustom = async function () {
          const config = {
            title: 'Revert to the last used custom template?',
            text: 'Are you sure you want to revert to the last used custom text message template?',
            confirmButtonText: 'Yes, revert to last used custom',
            cancelButtonText: 'No, go back',
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
          };
          const { isConfirmed } = await Swal.fire(config);
          if (isConfirmed) {
            const response = await communicationsService.revertTextMessageTemplateToCustom($scope.template);
            if (response) {
              AlertService.successAlert('Text message template reverted to the last used custom');
              $scope.selectTemplate()(null, null);
            } else {
              AlertService.errorAlert('There was an error reverting the text message template');
            }
          }
        };

        $scope.save = async function () {
          const shortLinkText = '*|short_link|*';
          if ($scope.template.variables.find((x) => x.name === shortLinkText)) {
            if ($scope.template.body.indexOf(shortLinkText) === -1) {
              AlertService.errorAlert('The short_link variable is required for this template.');
              return;
            }
          }

          const modalInstance = $modal.open({
            templateUrl: 'app/views/admin/communications/previewTextMessage.html',
            size: 'xlg',
            backdrop: 'static',
            controller: 'PreviewTextMessageController',
            resolve: {
              passedData() {
                return {
                  template: $scope.template,
                  image: $scope.currentImage,
                  mode: 'save',
                };
              },
            },
          });
          const result = await modalInstance.result;
          if (result) {
            const response = await communicationsService.saveTextMessage($scope.template);
            if (response.success) {
              AlertService.successAlert('Text message template updated successfully');
              $scope.selectTemplate()(null, null);
            } else {
              AlertService.errorAlert('There was an error updating the text message template');
            }
          }
        };

        $scope.preview = function () {
          $modal.open({
            templateUrl: 'app/views/admin/communications/previewTextMessage.html',
            size: 'lg',
            backdrop: 'static',
            controller: 'PreviewTextMessageController',
            resolve: {
              passedData() {
                return {
                  template: $scope.template,
                };
              },
            },
          });
        };

        $scope.icons = {
          ok: `${enums.AssetsFolder.svg}emails/smsOk.svg`,
          warning: `${enums.AssetsFolder.svg}emails/smsWarning.svg`,
        };

        $scope.template = await communicationsService.getTextMessageTemplate($scope.id);
      },
    ],
    templateUrl: 'app/views/admin/communications/editTextMessage.html',
  };
});
