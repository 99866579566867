import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../store';
import * as moment from 'moment';
import { RequestListFilter } from '../store/';
import { combineLatest } from 'rxjs';
import { FetchRequestDetails, ExportToCsvDetails } from '../store/actions';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SessionDetailsComponent } from './session.details.component';
import { TimeFormatService } from '../../../shared/services/time-format.service';

@Component({
  selector: 'request-list-component',
  templateUrl: './verify.report.component.html',
})
export class VerifyReportComponent implements OnInit {
  private allRequests: any[];
  @ViewChild('theList', { static: false }) theList;
  @Input() widgetData;
  public currentFilter: RequestListFilter;
  public filterText: string;
  public requests: any[];
  public showRequests: any[];
  public isLoading: boolean;
  public count: number;
  public currentPage = 1;
  public pageSize = 50;
  public keyAttribute: any;
  public searchQuery: string;
  private subscriptions = [];

  constructor(
    private store: Store<fromRoot.State>,
    private modalService: BsModalService,
    private timeFormatService: TimeFormatService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      combineLatest(this.store.select(fromRoot.getVerifyReport), this.store.select(fromRoot.getKeyAttribute)).subscribe(
        (result) => {
          this.allRequests = result[0];
          this.keyAttribute = result[1];
          this.loadData(this.allRequests);
        }
      )
    );

    this.subscriptions.push(
      this.store.select(fromRoot.getIsLoading).subscribe((isLoading) => (this.isLoading = isLoading))
    );

    this.subscriptions.push(
      this.store.select(fromRoot.getVerifyListFilter).subscribe((filter) => {
        this.currentFilter = filter;
        this.updateFilters(filter);
      })
    );
  }

  loadData(list: any[]) {
    this.currentPage = 0;
    this.count = list.length;
    list.sort((a, b) => {
      const aTime = a.request_date + a.request_time;
      const bTime = b.request_date + b.request_time;
      return aTime.localeCompare(bTime);
    });
    this.requests = list.map((r) => this.buildRequest(r));
    this.showRequests = this.requests.slice(0, this.pageSize);
  }

  updateFilters(filter) {
    this.filterText = null;
    if (this.allRequests) {
      let list = this.allRequests;
      if (filter) {
        if (filter.keyAttribute) {
          list = list.filter((r) => {
            if (r && r.key_attrib_info) {
              if (r.key_attrib_info.type === 'single_list' || r.key_attrib_info.type === 'multi_list') {
                return (
                  r.key_attrib_info.value_list &&
                  r.key_attrib_info.value_list[0] &&
                  r.key_attrib_info.value_list[0].name === filter.keyAttribute
                );
              } else if (r.key_attrib_info.type === 'text') {
                return r.key_attrib_info.value_text === filter.keyAttribute;
              }
            }
            return;
          });
          this.filterText = filter.keyAttribute;
        }
        if (filter.location) {
          list = list.filter((r) => r.to_location.name === filter.location);
          this.filterText = filter.location;
        }
      }
      this.loadData(list);
    }
  }

  buildRequest(r) {
    return {
      id: r.request_id,
      provider: `${r.serv_first_name} ${r.serv_last_name}`,
      status: r.status.name,
      keyAttributeValue: r.key_attrib_info.value_list[0].name,
      duration: (<any>moment.duration(r.estimated_duration, 'minutes')).format('HH : mm'),
      date: moment(r.request_date).format(this.timeFormatService.format('shortMonth')),
      checkIn: r.checkin_time,
      checkout: r.checkout_time,
      timeWorked: (<any>moment.duration(r.request_total_time, 'minutes')).format('HH : mm'),
      location: r.to_location && r.to_location.name,
      amount: r.total_cost,
    };
  }

  pageChanged(event) {
    let index = event.page - 1;
    let start = index * event.itemsPerPage;
    let end = (index + 1) * event.itemsPerPage;
    this.showRequests = this.requests.slice(start, end);
  }

  sessionSelected(id) {
    this.modalService.show(SessionDetailsComponent, { animated: false });
    let action = new FetchRequestDetails(id);
    this.store.dispatch(action);
  }

  doSearch() {
    this.currentPage = 0;
    if (this.searchQuery) {
      this.requests = this.requests.filter((r) => {
        let match = false;
        for (var key in r) {
          if (typeof r[key] === 'string') {
            if (r[key].toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1) {
              match = true;
              break;
            }
          }
        }
        return match;
      });
      this.count = this.requests.length;
      this.showRequests = this.requests.slice(0, this.pageSize);
    } else {
      this.ngOnInit();
    }
  }

  exportToCSV() {
    let labels = [
      'Session ID',
      'Interpreter',
      'Status',
      this.keyAttribute && this.keyAttribute.name,
      'Duration',
      'Date',
      'Check In',
      'Check Out',
      'Time Worked',
      'Location',
      'Amount',
    ];

    this.store.dispatch(new ExportToCsvDetails('verifyReport', labels, this.requests));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
