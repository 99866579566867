import module from './module';
import * as enums from "../enums";

export default module.directive('dirPleaseReviewWidget', function () {
  return {
    restrict: 'E',
    controller: [
      '$scope',
      '$rootScope',
      'pleaseReviewService',
      function ($scope, $rootScope, pleaseReviewService) {
        $scope.unratedList = [];
        $scope.closeIcon = `${enums.AssetsFolder.svg}close.svg`;

        pleaseReviewService.getUnratedServices()
          .then(function (services) {
            return pleaseReviewService.filterOutCanceled(services);
          })
          .then(function (services) {
            if (services) {
              $scope.unratedList = services.slice(0, 20);
            }
          });

        $scope.closeReviews = function () {
          $scope.toggleReview();
        };

        $scope.formatReview = function (request) {
          return pleaseReviewService.formatReview(request);
        };

        $scope.$on('PleaseReview.Reviewed.Admin', function (e, id) {
          $scope.removeItem(id);
        });

        $scope.$on('NOTIFICATION_UPDATE', (e, msg) => {
          if (msg.action === 'PRO_COMPLETE_REQUEST') {
            pleaseReviewService.getUnratedServices().then(function (result) {
              $scope.unratedList = result;
            });
          }
        });

        $scope.submitReview = function (request) {
          pleaseReviewService.review(request.request_id, request.review_rate).then(function () {
            $scope.removeItem(request.request_id);
            $rootScope.$broadcast('PleaseReview.Reviewed.Widget', request.request_id);
            $rootScope.$broadcast('PleaseReview.Fetched', $scope.unratedList.length);
          });
        };

        $scope.removeItem = function (id) {
          var justRatedIndex = $scope.unratedList.findIndex(function (unrated) {
            return unrated.request_id === id;
          });
          $scope.unratedList.splice(justRatedIndex, 1);
        };
      }
    ],
    templateUrl: 'app/views/templates/pleaseReviewWidget.html'
  };
});
