import module from './module';

export default module.factory('locationManagerService', [
  '$http',
  'BASE_URL',
  '$q',
  'Location',
  'sessionManagerService',
  function ($http, BASE_URL, $q, Location, sessionManagerService) {
    const locationsManager = {
      _selectedLocation: {},
      _pool: {},
      _locationsObject: {},
      _unknownPool: {},
      _unknownLocationsObject: {},
      _locationChangedCallbacks: [],
      _retrieveInstance: function (locationId, locationData, entId) {
        let instance = this._pool[locationId];
        if (instance) {
          instance.setData(locationData);
        } else {
          instance = new Location(locationData);
          entId = entId || -1;
          this._pool[locationId] = instance;
          if (this._locationsObject[entId]) {
            this._locationsObject[entId].push(instance);
          } else {
            this._locationsObject[entId] = [];
            this._locationsObject[entId].push(instance);
          }
        }
        return instance;
      },
      _retrieveUnknownInstance: function (locationName, locationData, entId) {
        if (!this._unknownPool[entId]) {
          this._unknownPool[entId] = {};
        }
        let instance = this._unknownPool[entId][locationName];
        if (!instance) {
          instance = new Location(locationData, entId);
          this._unknownPool[entId][locationName] = instance;
          if (this._unknownLocationsObject[entId]) {
            this._unknownLocationsObject[entId].push(instance);
          } else {
            this._unknownLocationsObject[entId] = [];
            this._unknownLocationsObject[entId].push(instance);
          }
        }
        return instance;
      },
      _removeInstance: function (location, deferred) {
        const instance = this._pool[location.location_id];
        if (instance) {
          delete this._pool[location.location_id];
          const index = this._locationsObject[location.enterprise_id].indexOf(instance);
          if (index !== -1) {
            this._locationsObject[location.enterprise_id].splice(index, 1);
          }
        }
        deferred.resolve(this._locationsObject);
      },
      // TODO update
      _load: function (locationId, deferred) {
        const scope = this;
        $http
          .post(BASE_URL + '/get_places', { location_id: locationId })
          .then(function (res) {
            const response = res.data;
            const location = scope._retrieveInstance(
              locationId,
              response.location_list[0],
              response.location_list[0].enterprise_id
            );
            deferred.resolve(location);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _loadAllLocations: function (deferred) {
        const scope = this;
        $http
          .post(BASE_URL + '/get_places', {})
          .then(function (res) {
            const response = res.data;
            response.location_list.forEach(function (locationData) {
              scope._retrieveInstance(locationData.location_id, locationData, locationData.enterprise_id);
            });
            deferred.resolve(scope._locationsObject);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _createNewLocation: function (locationData, deferred) {
        $http
          .post(BASE_URL + '/add_location', locationData)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _updateLocation: function (locationData, deferred) {
        $http
          .post(BASE_URL + '/update_location', locationData)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      /* Public Methods */
      getLocation: function (locId) {
        const deferred = $q.defer();
        this._load(locId, deferred);
        return deferred.promise;
      },
      getAllLocations: function () {
        const deferred = $q.defer();
        this._loadAllLocations(deferred);
        return deferred.promise;
      },
      getUnknownLocations: function (ent_id) {
        const scope = this;
        const deferred = $q.defer();
        sessionManagerService.getAllSessions(ent_id).then(function (sessions) {
          if (Array.isArray(sessions)) {
            sessions.forEach(function (sessionData) {
              if (sessionData.location_id == null && sessionData.location) {
                // Build unknown location obj
                scope._retrieveUnknownInstance(
                  sessionData.location.value_location.name,
                  sessionData.location.value_location,
                  ent_id
                );
              }
            });
          }
          deferred.resolve(scope._unknownLocationsObject);
        });
        return deferred.promise;
      },
      grabUnknownLocList: function () {
        const scope = this;
        const deferred = $q.defer();
        deferred.resolve(scope._unknownLocationsObject);
        return deferred.promise;
      },
      addNewLocation: function (locationData) {
        const deferred = $q.defer();
        this._createNewLocation(locationData, deferred);
        return deferred.promise;
      },
      updateLocation: function (locationData) {
        const deferred = $q.defer();
        this._updateLocation(locationData, deferred);
        return deferred.promise;
      },
      removeDeletedLocation: function (location) {
        const deferred = $q.defer();
        this._removeInstance(location, deferred);
        return deferred.promise;
      },
      updatePlaceList: function (locId) {
        const deferred = $q.defer();
        this._load(locId, deferred);
        return deferred.promise;
      },
      setLocation: function (selected_location) {
        this._selectedLocation = selected_location;
        this.callLocationChangeCallbacks();
      },
      getSelectedLocation: function () {
        return this._selectedLocation;
      },
      clearSelectedLocation: function () {
        this._selectedLocation = {};
      },
      addLocationChangeCallback: function (callback) {
        if (!Array.isArray(this._locationChangedCallbacks)) {
          this._locationChangedCallbacks = [];
        }

        if (typeof callback === 'function') {
          this._locationChangedCallbacks.push(callback);
        }
      },
      callLocationChangeCallbacks: function () {
        if (Array.isArray(this._locationChangedCallbacks)) {
          for (const cb of this._locationChangedCallbacks) {
            if (typeof cb === 'function') {
              cb();
            }
          }
        }
      },
      deleteCallbacks: function () {
        this._locationChangedCallbacks = [];
      },
    };
    return locationsManager;
  },
]);
