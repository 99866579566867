import angular from 'angular';
import module from '../module';
import * as enums from '../../enums';

export default module.controller('EditJobModalController', [
  '$scope',
  '$modalInstance',
  'AlertService',
  'jobManagerService',
  'enterprise_selection',
  'FormatHelperService',
  'job_selection',
  'sessionManagerService',
  function (
    $scope,
    $modalInstance,
    AlertService,
    jobManagerService,
    enterprise_selection,
    FormatHelperService,
    job_selection,
    sessionManagerService
  ) {
    $scope.entFilter = angular.copy(enterprise_selection);
    $scope.job = angular.copy(job_selection);
    $scope.submitBtnDisbaled = false;
    $scope.title = 'Edit Job Template';
    $scope.type = 'edit';
    $scope.formData = {
      job_id: $scope.job.job_id,
      name: $scope.job.name,
      desc: $scope.job.desc,
      attrib_list: $scope.job.attrib_list,
    };

    // Get Dynamic Job Attribs Realm 16
    sessionManagerService.getRealmAttribs(enums.Realms.JobCreation).then(
      function (response) {
        if (response.success) {
          $scope.attrib_list = response.attrib_list.sort(function (a, b) {
            return a.order - b.order;
          });
        } else {
          AlertService.errorAlert(response);
          $modalInstance.close();
        }
      },
      function (reason) {
        AlertService.serverRequestErrorAlert(reason);
        $modalInstance.close();
      }
    );
    // modal controls
    $scope.ok = function (action) {
      $scope.submitBtnDisbaled = true;
      // format dynamic attribs
      const formatedAttribList = FormatHelperService.format_data($scope.attrib_list);
      if (formatedAttribList.length > 0) {
        $scope.formData.attrib_list = formatedAttribList;
      }
      sendToServer($scope.formData);
    };

    const sendToServer = function (jobObj) {
      jobManagerService.submitEditJob(jobObj).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
            $modalInstance.close();
          } else {
            AlertService.errorAlert(response.message);
            $modalInstance.dismiss('cancel');
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.dismiss('cancel');
        }
      );
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  },
]);
