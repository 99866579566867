import module from '../module';
export default module.controller('WaitingApprovalModalController', [
  '$scope',
  '$controller',
  '$modalInstance',
  'FormatHelperService',
  'AlertService',
  'sessionManagerService',
  'enterprise_selection',
  'generalDataService',
  function(
    $scope,
    $controller,
    $modalInstance,
    FormatHelperService,
    AlertService,
    sessionManagerService,
    enterprise_selection,
    generalDataService
  ) {
    $controller('BaseEditController', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      FormatHelperService: FormatHelperService,
      AlertService: AlertService,
      sessionManagerService: sessionManagerService,
      enterprise_selection: enterprise_selection
    });

    $scope.keySession = generalDataService.getKeySession();
    $scope.type = 'approval';
    $scope.title = 'Approve ' + $scope.keySession;

    $scope.decline = function() {
      $scope.submitBtnDisbaled = true;
      var service_request_response = {
        request_id: $scope.request.request_id,
        approved: false
      };
      $scope.submitToServer(service_request_response);
    };

    // submit for approval response to server
    $scope.formatRequest = function() {
      var provider_list = [];
      if ($scope.pickListValid) {
        provider_list = $scope.pickList
          .filter(function(item) {
            return item.selected;
          })
          .map(function(item) {
            return item.provider_id;
          });
      }
      //format form data for server
      var attrib_list = FormatHelperService.format_data($scope.formData);
      var service_request_response = {
        request_id: $scope.request.request_id,
        approved: true,
        attrib_list: attrib_list,
        provider_list: provider_list
      };
      $scope.submitToServer(service_request_response);
    };

    $scope.preparePickListPayload = function() {
      return {
        attrib_list: FormatHelperService.format_data($scope.formData),
        type: $scope.request.type
      };
    };

    // server call
    $scope.submitToServer = function(service_request_response) {
      sessionManagerService.submitOpenResponse(service_request_response).then(
        function(response) {
          if (response.success) {
            AlertService.successAlert(response.message);
          } else {
            AlertService.errorAlert(response.message);
          }
          $modalInstance.close();
        },
        function(reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    };

    $scope.loadRequest();
    $scope.$on('BaseEditController.LoadRequest', function() {
      // Open sessions should default to dynamic escalation on approval screen
      if ($scope.request && $scope.request.status && $scope.request.status.type === 4) {
        $scope.jobType.selection = 'escalation';
        $scope.jobType.assign = false;
        $scope.jobType.escalation = true;
        $scope.escalationAttrib.value = { choice_id: 0 };
      }
    });
  }
]);
