import angular from 'angular';
import module from '../module';
import moment from 'moment';

export default module.controller('CheckInModalController', [
  '$scope',
  '$modalInstance',
  'AlertService',
  'passedSession',
  'passedEnt',
  'sessionManagerService',
  'trackingService',
  'timeRuleService',
  'generalDataService',
  'TimeFormatService',
  function (
    $scope,
    $modalInstance,
    AlertService,
    passedSession,
    passedEnt,
    sessionManagerService,
    trackingService,
    timeRuleService,
    generalDataService,
    TimeFormatService
  ) {
    $scope.submitBtnDisbaled = false;
    $scope.title = 'Check-In';
    $scope.keySession = generalDataService.getKeySession();
    const _passedSession = angular.copy(passedSession);
    const _passedEnterprise = angular.copy(passedEnt);
    $scope.formData = {
      request_id: _passedSession.session_id || _passedSession.request_id,
    };

    var scheduledStartUTC, scheduledStartLocal;
    if (_passedSession.start_time) {
      scheduledStartUTC = moment
        .utc(_passedSession.start_time)
        .format(TimeFormatService.format('fullDateTimeNoSeparator'));
      scheduledStartLocal = moment.utc(_passedSession.start_time).local().format('MM/DD/YY | h:mm a');
    } else {
      scheduledStartUTC = moment
        .utc(_passedSession.start_date_time, TimeFormatService.format('fullDateTime'))
        .clone()
        .format(TimeFormatService.format('fullDateTimeNoSeparator'));
      scheduledStartLocal = moment
        .utc(_passedSession.start_date_time, TimeFormatService.format('fullDateTime'))
        .local()
        .clone()
        .format('MM/DD/YY | h:mm a');
    }

    const scheduleTimeSelectionDisplay = 'Scheduled Time - '.concat(scheduledStartLocal);
    $scope.checkInSelections = [
      { name: 'Current Time', time: '', id: 1 },
      { name: scheduleTimeSelectionDisplay, time: '', id: 2 },
      { name: 'Custom Time', time: '', id: 3 },
    ];

    if (_passedSession.session_id) {
      $scope.selectedCheckIn = { id: 1 };
    } else {
      $scope.selectedCheckIn = { id: 2 };
    }

    sessionManagerService.setSession(_passedSession.session_id || _passedSession.request_id).then(
      function (session) {
        $scope.rules = timeRuleService.getCheckInRules(session.time_rule_info);
        $scope.rulesViewText = timeRuleService.generateCheckInViewRules(session.time_rule_info);
      },
      function (reason) {
        AlertService.serverRequestErrorAlert(reason);
        $modalInstance.dismiss('cancel');
      }
    );

    const newStartTimeUntouched = new Date().setHours(moment().format('HH'), moment().format('mm'), 0);
    $scope.newDateObj = {
      startDate: moment().format(TimeFormatService.format('dateSlash')),
      startTime: newStartTimeUntouched,
    };

    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      if ($scope.selectedCheckIn.id == 2) {
        $scope.formData.checkin_datetime = scheduledStartUTC;
      } else if ($scope.selectedCheckIn.id == 3) {
        $scope.formData.checkin_datetime = formatDateTimes();
      }
      const disabledRules = timeRuleService.getDisabledRules($scope.rules);
      if (disabledRules && disabledRules.length) {
        $scope.formData.disabled_time_rules = disabledRules;
      }
      sendToServer($scope.formData);
      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'submit');
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'cancel');
    };

    function sendToServer(reivewObj) {
      sessionManagerService.checkinSessionProvider(reivewObj).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
            $modalInstance.close();
          } else {
            AlertService.errorAlert(response.message);
            $modalInstance.dismiss('cancel');
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.dismiss('cancel');
        }
      );
    }

    // Calendar date time selection options and controls
    $scope.startDate = { opened: false };
    $scope.openStartDate = function () {
      $scope.startDate.opened = true;
    };
    // Start time selection
    $scope.hstep = 1;
    $scope.mstep = 5;

    function formatDateTimes() {
      var _startTime, _startDate, _startDateTime, _builtDateTime;
      _startTime = moment($scope.newDateObj.startTime).format('HHmm');
      if (typeof $scope.newDateObj.startDate === 'string') {
        _startDate = moment($scope.newDateObj.startDate, TimeFormatService.format('dateSlash')).format(
          TimeFormatService.format('fullDateNoSeparator')
        );
      } else {
        _startDate = moment($scope.newDateObj.startDate).format(TimeFormatService.format('fullDateNoSeparator'));
      }
      _startDateTime = _startDate + ' ' + _startTime;
      _builtDateTime = moment(_startDateTime, TimeFormatService.format('fullDateTimeNoSeparator'))
        .utc()
        .format(TimeFormatService.format('fullDateTimeNoSeparator'));
      return _builtDateTime;
    }
  },
]);
