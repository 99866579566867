import module from './module';
import moment from 'moment-timezone';

export default module.directive('dirTimeZoneDisplay', [
  '$rootScope',
  'googleLocationService',
  'timeZoneStore',
  'FormatHelperService',
  'TimeFormatService',
  function ($rootScope, googleLocationService, timeZoneStore, FormatHelperService, TimeFormatService) {
    return {
      restrict: 'E',
      scope: {
        date: '=?',
        time: '=?',
        resetDate: '=?',
        waitForDate: '=?',
      },
      link: function (scope) {
        scope.abbr = '';
        scope.longName = 'Time Zone of Location';
        scope.location = null;
        scope.previousDate = null;
        scope.previousLocation = null;

        scope.calculateClientTimeZone = function () {
          var result = 'unknown';
          try {
            // Chrome, Firefox
            result = /.*\s(.+)/.exec(
              new Date().toLocaleDateString(navigator.language, {
                timeZoneName: 'short',
              })
            )[1];
          } catch (e) {
            // IE, some loss in accuracy due to guessing at the abbreviation
            // Note: This regex adds a grouping around the open paren as a
            //       workaround for an IE regex parser bug
            result = new Date().toTimeString().match(new RegExp('[A-Z](?!.*[(])', 'g')).join('');
          }

          scope.abbr = result;
          scope.longName = String(String(new Date()).split('(')[1]).split(')')[0];
        };

        scope.calculateLocationTimeZone = function () {
          if (
            scope.previousDate &&
            scope.previousDate.format(TimeFormatService.format('fullDateTimeNoSeparator')) ===
              moment(scope.date).format(TimeFormatService.format('fullDateTimeNoSeparator')) &&
            scope.previousLocation &&
            scope.previousLocation.location_id === scope.location.location_id
          ) {
            return;
          }

          if (scope.location !== null && typeof scope.date !== 'undefined') {
            // if no separated time available we should use the time from the date
            const time = typeof scope.time !== 'undefined' ? scope.time : scope.date;
            const startTime = moment(time).format('HHmm');
            const startDate = moment(scope.date).format(TimeFormatService.format('fullDateNoSeparator'));
            const startDateTime = startDate + ' ' + startTime;
            const convertedTime = moment(startDateTime, TimeFormatService.format('fullDateTimeNoSeparator')).format(
              'YYYY-MM-DDTHH:mm:ss'
            );

            const timestamp = new Date(convertedTime).getTime() / 1000;
            if (!isNaN(timestamp)) {
              googleLocationService.getTimezone(scope.location, timestamp).then((data) => {
                if (data && data !== null) {
                  timeZoneStore.load(data);
                  scope.abbr = moment.tz(new Date(convertedTime).getTime(), data.timeZoneId).format('z');
                  scope.longName = data.timeZoneId; // timeZoneName???
                  if (scope.resetDate) {
                    scope.date = moment.utc(scope.date).tz(data.timeZoneId);
                    scope.previousDate = angular.copy(scope.date);
                    scope.previousLocation = angular.copy(scope.location);
                    scope.resetDate = false;
                  }
                }
              });
            }
          }
        };

        if (typeof scope.date === 'undefined' && !scope.waitForDate) {
          scope.calculateClientTimeZone();
        } else {
          scope.unwatchLocationChanged = $rootScope.$on('locationChanged', function (evt, data) {
            if (typeof data === 'string' && data.length) {
              googleLocationService.geocodeAddress(data).then((loc) => {
                if (loc) {
                  scope.location = {
                    latitude: loc.lat,
                    longitude: loc.lng,
                  };

                  scope.calculateLocationTimeZone();
                }
              });
            } else {
              scope.location = data;
              scope.calculateLocationTimeZone();
            }
          });

          scope.unwatchDate = scope.$watch('date', function () {
            scope.calculateLocationTimeZone();
          });

          scope.unwatchTime = scope.$watch('time', function () {
            scope.calculateLocationTimeZone();
          });
        }

        scope.$on('$destroy', function () {
          if (scope.unwatchLocationChanged) {
            scope.unwatchLocationChanged();
          }
          if (scope.unwatchDate) {
            scope.unwatchDate();
          }
          if (scope.unwatchTime) {
            scope.unwatchTime();
          }
        });
      },
      templateUrl: 'app/views/templates/timezone_display.html',
    };
  },
]);
