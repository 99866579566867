import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { SecureHttpService } from '../../../shared/services/secure.http.service';

@Injectable()
export class JobService {
  private data: any[];
  constructor(private http: SecureHttpService) { }

  loadFromCache(enterpriseId) {
    let cached = localStorage.getItem(`get_jobs/${enterpriseId}`);
    if (cached) {
      this.data = JSON.parse(cached);
    }
  }

  fetch(enterpriseId = -1) {
    this.data = null;
    this.loadFromCache(enterpriseId);

    if (this.data) {
      return of(this.data);
    }
    let passedId = enterpriseId === -1 ? 1 : enterpriseId;
    let payload = {};
    if (passedId !== -1) {
      (<any>payload).enterprise_id = passedId;
    }

    return this.http.post('get_jobs', payload).pipe(
      switchMap(response => {
        let result = response.job_list;
        // localStorage.setItem(`get_jobs/${enterpriseId}`, JSON.stringify(result));
        return of(result);
      })
    );
  }
}
