import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BirdsEyeModel } from '../../shared/models/birds-eye';
import { SecureHttpService } from '../../shared/services';
import * as moment from 'moment';

@Injectable()
export class BirdsEyeService {
  constructor(private secureHttpService: SecureHttpService) {}

  fetchOrder(jobId: string, jobInstance: string): Observable<BirdsEyeModel> {
    return this.secureHttpService
      .post('get_birdseye_worker_shifts', {
        job_id: jobId,
        job_instance: jobInstance,
      })
      .pipe(
        map((res) => {
          if (res && res.session_list) {
            res.session_list.sort((a, b) => {
              return `${a.start_date} ${a.start_time}`.localeCompare(`${b.start_date} ${b.start_time}`);
            });
          }
          return res;
        }),
        map((res) => {
          if (res && res.session_list) {
            res.session_list = res.session_list.map((session) => {
              const startString = `${session.start_date} ${session.start_time}`;
              const endString = `${session.start_date} ${session.end_time}`;

              return {
                ...session,
                start_time: moment.utc(startString).local().format('HH:mm'),
                start_date: moment.utc(startString).local().format('yyyy-MM-DD'),
                end_time: moment.utc(endString).local().format('HH:mm'),
              };
            });
          }
          return res;
        })
      );
  }

  schedule(assignments: { [key: string]: number[] }) {
    return this.secureHttpService.post('schedule_shifts', { assignments: this.preparePayload(assignments) });
  }

  offer(offers: { [key: string]: number[] }) {
    return this.secureHttpService.post('offer_shifts', { offers: this.preparePayload(offers) });
  }

  preparePayload(payload: { [key: string]: number[] }) {
    const copy = {};
    Object.keys(payload).forEach((key) => {
      copy[`${key}`] = payload[key].map((item) => +item);
    });
    return copy;
  }
}
