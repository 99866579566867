import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';

import { DashboardComponent } from './components/dashboard.component';
import { DashboardWidgetsComponent } from './components/dashboard.widgets.component';
import { DateRangeComponent } from './components/date.range.component';

import { DynamicWidgetComponent } from './widgets/dynamic.widget.component';
import { FillRateComponent } from './widgets/fill.rate.component';
import { CancelBreakDownComponent } from './widgets/cancel.break.down.component';
import { ShiftCountComponent } from './widgets/shift.count.component';
import { LateComponent } from './widgets/late.component';
import { ShiftsOfferedComponent } from './widgets/shifts.offered.component';
import { ShiftsAcceptedComponent } from './widgets/shifts.accepted.component';
import { ShiftQualityComponent } from './widgets/shift.quality.component';
import { TopKeyAttributeComponent } from './widgets/top.key.attribute.component';
import { ShiftWorkerLocationComponent } from './widgets/shift.worker.location.component';
import { TotalTimeComponent } from './widgets/total.time.component';

import { UniqueWorkersComponent } from './widgets/workers/unique.workers.component';
import { WorkerVettingComponent } from './widgets/workers/worker.vetting.component';

import { FinancialKeyAttributeComponent } from './widgets/financial/financial.key.attribute.component';
import { FinancialLocationComponent } from './widgets/financial/financial.location.component';

import { DETAILS_COMPONENT } from './details';

import { SERVICES } from './services';

import { GlobalErrorHandler } from './error.handler';
import { DragDropModule } from '@angular/cdk/drag-drop';

import {
  sessionServiceProvider,
  providerServiceProvider,
  enterpriseFilterServiceProvider,
} from '../../upgraded.providers';

import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    HttpClientModule,

    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    SharedModule,
    DragDropModule,
  ],
  declarations: [
    DETAILS_COMPONENT,
    DashboardComponent,
    FillRateComponent,
    CancelBreakDownComponent,
    ShiftCountComponent,
    TotalTimeComponent,
    LateComponent,
    ShiftsOfferedComponent,
    ShiftsAcceptedComponent,
    ShiftQualityComponent,
    DynamicWidgetComponent,
    TopKeyAttributeComponent,
    ShiftWorkerLocationComponent,
    UniqueWorkersComponent,
    WorkerVettingComponent,
    DashboardWidgetsComponent,
    DateRangeComponent,
    FinancialKeyAttributeComponent,
    FinancialLocationComponent,
  ],
  entryComponents: [
    DETAILS_COMPONENT,
    DashboardComponent,
    FillRateComponent,
    CancelBreakDownComponent,
    ShiftCountComponent,
    TotalTimeComponent,
    LateComponent,
    ShiftsOfferedComponent,
    ShiftsAcceptedComponent,
    ShiftQualityComponent,
    DynamicWidgetComponent,
    TopKeyAttributeComponent,
    ShiftWorkerLocationComponent,
    UniqueWorkersComponent,
    WorkerVettingComponent,
    DashboardWidgetsComponent,
    DateRangeComponent,
    FinancialKeyAttributeComponent,
    FinancialLocationComponent,
  ],
  exports: [DashboardComponent],
  providers: [
    SERVICES,
    sessionServiceProvider,
    providerServiceProvider,
    enterpriseFilterServiceProvider,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
})
export class DashboardModule {
  constructor() {}
}
