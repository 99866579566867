import module from '../module';
import * as enums from '../../enums';

export default module.controller('SessionQueueController', [
  'sessionManagerService',
  '$scope',
  '$modal',
  'AlertService',
  '$location',
  'WebsocketService',
  '$timeout',
  '$localStorage',
  'FormatHelperService',
  'AuthenticationService',
  'generalDataService',
  'TimeFormatService',
  function (
    sessionManagerService,
    $scope,
    $modal,
    AlertService,
    $location,
    WebsocketService,
    $timeout,
    $localStorage,
    FormatHelperService,
    AuthenticationService,
    generalDataService,
    TimeFormatService
  ) {
    var CURRENT_USER = AuthenticationService.getCurrentUser();
    $scope.keySession = generalDataService.getKeySession();
    $scope.tableDisplayCount = 25;
    $scope.jobs = [];
    $scope.animationsEnabled = true;
    $scope.keyAttrib = $localStorage.keyAttribute;

    function getKeyAttribDisplayName() {
      sessionManagerService.getRealmAttribs(enums.Realms.Service).then(
        function (response) {
          const keyAttribute = response.attrib_list.find(function (obj) {
            return obj.name == $localStorage.keyAttribute.data_content;
          });
          $localStorage.keyAttribute.display_name = keyAttribute ? keyAttribute.display_name : '';
          $scope.keyAttrib = $localStorage.keyAttribute;
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
        }
      );
    }
    if (!$scope.keyAttrib.display_name) {
      getKeyAttribDisplayName();
    }
    ////////////////////////////////////////////////////////////////////////////
    //Permission Check
    $scope.schedulePermission = CURRENT_USER.permissionCheck(4);
    $scope.nowPermission = CURRENT_USER.permissionCheck(5);
    $scope.scheduleJobPermission = CURRENT_USER.permissionCheck(39);
    ////////////////////////////////////////////////////////////////////////////
    $scope.keyProfession = $localStorage.generalData.filter(function (obj) {
      return obj.data_name == 'key_profession';
    })[0];
    //////////////////////////////////////////////////////////////////////////////
    //Toggle Job Div Show
    $scope.viewRequest = function (sessionId) {
      sessionManagerService.setSession(sessionId, $scope.entFilter.id).then(function (response) {
        if (response.success) {
          location.href = '/#/job';
        } else {
          AlertService.errorAlert(response);
        }
      });
    };
    //////////////////////////////////////////////////////////////////////////////
    //Table Sorting
    $scope.sort = function (keyname) {
      $scope.sortKey = keyname; //set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    };
    $scope.sort('start_date_time');
    //////////////////////////////////////////////////////////////////////////////
    //ATTRIBUTE DISPLAY
    $scope.getRequestTimeDisplayVal = function (utcString) {
      var _momentObj = moment.utc(utcString, TimeFormatService.format('fullDateTime')).clone();
      var _val = FormatHelperService.returnLocalTimeString(_momentObj, TimeFormatService.format('dateTimeLong'));
      return _val;
    };
    //////////////////////////////////////////////////////////////////////////////
    // Schedule Session Modal
    $scope.showScheduleJobModal = function () {
      var modalInstance = $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/views/session/requestSessionModal.html',
        controller: 'RequestScheduleJobModalController',
        keyboard: false,
        size: 'lg',
        backdrop: 'static',
        resolve: {
          enterprise_selection: function () {
            return $scope.entFilter;
          },
          passedSession: function () {
            return null;
          },
          start_time: function () {
            return null;
          },
          passedJobOrderDetails: function () {
            return null;
          },
          passed_current_worker_count: function () {
            return null;
          },
          passed_total_worker_count: function () {
            return null;
          },
        },
      });
      modalInstance.result.then(function () { });
    };
    //////////////////////////////////////////////////////////////////////////////
    // Now Sesisons Modal
    $scope.showNowJobModal = function () {
      var modalInstance = $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/views/session/requestSessionModal.html',
        controller: 'NowJobModalController',
        keyboard: false,
        size: 'lg',
        backdrop: 'static',
        resolve: {
          enterprise_selection: function () {
            return $scope.entFilter;
          },
        },
      });
      modalInstance.result.then(function () { });
    };

    //////////////////////////////////////////////////////////////////////////////
    // Schedule Sessions on Job Modal
    $scope.openScheduleJobModal = function () {
      var modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/schedule/scheduleJobModal.html',
        controller: 'NewScheduleJobModalController',
        keyboard: false,
        size: 'lg',
        backdrop: 'static',
        resolve: {
          enterprise_selection: function () {
            return $scope.entFilter;
          },
          passed_job_id: function () {
            return null;
          },
          passed_job_order_id: function () {
            return null;
          },
          passedSession: function () {
            return null;
          },
          start_time: function () {
            return null;
          },
          passedJobOrderDetails: function () {
            return null;
          },
          passed_current_worker_count: function () {
            return null;
          },
          passed_total_worker_count: function () {
            return null;
          },
        },
      });

      modalInstance.result.then(function () { });
    };
  },
]);
