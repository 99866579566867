import module from '../module';
import * as enums from '../../enums';
import moment from 'moment';

export default module.service('apiLogsService', [
  '$http',
  'BASE_URL',
  '$q',
  'TimeFormatService',
  function ($http, BASE_URL, $q, TimeFormatService) {
    const inboundEndpoint = `${BASE_URL}/get_marketplace_api_logs`;
    const outboundEndpoint = `${BASE_URL}/get_outbound_api_logs`;

    const checkUserName = (log, filter) => {
      if (!log.username) {
        return false;
      }
      return log.username.toLowerCase().indexOf(filter.value_text.toLowerCase()) > -1;
    };

    const checkDateRange = (log, filter) => {
      if (!log.call_date) {
        return false;
      }
      return moment(moment(log.call_date).format(TimeFormatService.format('fullDate'))).isBetween(
        filter.value.start,
        filter.value.end,
        null,
        '[]'
      );
    };

    const checkEndpoint = (log, filter) => {
      if (!log.endpoint_desc) {
        return false;
      }
      return log.endpoint_desc.toLowerCase().indexOf(filter.value_text.toLowerCase()) > -1;
    };

    const checkAtsId = (log, filter) => {
      if (!log.ext_id) {
        return false;
      }
      return log.ext_id.toLowerCase().indexOf(filter.value_text.toLowerCase()) > -1;
    };

    const addLocalCallDate = (log_list) => {
      const list = log_list;
      for (const log in list) {
        list[log].call_date_local = moment
          .utc(list[log].call_date || list[log].call_date_time)
          .local()
          .format(TimeFormatService.format('fullDateTimeSecs'));
      }
      return list;
    };

    const checkStatus = (log, filter) => {
      return log.status === filter.value_single_list;
    };

    return {
      getInboundApiLogs: (data) => {
        const deferred = $q.defer();

        $http
          .post(inboundEndpoint, data)
          .then((response) => {
            if (response.data.success) {
              deferred.resolve(addLocalCallDate(response.data.log_list));
            } else {
              deferred.reject({ error: true });
            }
          })
          .catch((error) => {
            return deferred.reject(error);
          });

        return deferred.promise;
      },
      getOutboundApiLogs: (data) => {
        const deferred = $q.defer();

        $http
          .post(outboundEndpoint, data)
          .then((response) => {
            if (response.data.success) {
              const result = response.data.log_list.map((item) => {
                return {
                  ...item,
                  endpoint_desc: item.action_desc,
                  status: item.status ? enums.ApiLogsState.Success : enums.ApiLogsState.Failed,
                  success: item.status,
                  json_data: item.call_data,
                  workn_id: item.workn_internal_id,
                };
              });
              deferred.resolve(addLocalCallDate(result));
            } else {
              deferred.reject({ error: true });
            }
          })
          .catch((error) => {
            return deferred.reject(error);
          });

        return deferred.promise;
      },

      filterApiLogs: (logs, filters) => {
        const filtered = logs.filter((log) => {
          let retVal = true;
          filters.forEach((filter) => {
            switch (filter.attrib_id) {
              case 1:
                return (retVal = retVal && checkUserName(log, filter));
              case 2:
                return (retVal = retVal && checkDateRange(log, filter));
              case 3:
                return (retVal = retVal && checkEndpoint(log, filter));
              case 4:
                return (retVal = retVal && checkStatus(log, filter));
              case 5:
                return (retVal = retVal && checkAtsId(log, filter));
            }
          });
          return retVal;
        });
        return filtered;
      },
    };
  },
]);
