import module from './module';
import * as enums from '../enums';
import * as rxjs from 'rxjs';

export default module.factory('EnterpriseFilterService', [
  '$rootScope',
  'AuthenticationService',
  'trackingService',
  'NgrxStore',
  'commonSelectors',
  function ($rootScope, AuthenticationService, trackingService, NgrxStore, commonSelectors) {
    const CURRENT_USER = AuthenticationService.getCurrentUser();
    const currentUserType = AuthenticationService.getCurrentUserType();

    let enterprise_list = [];
    let enterpriseIds = [];

    if (
      CURRENT_USER &&
      (currentUserType === enums.UserType.MarketPlaceAdmin ||
        currentUserType === enums.UserType.BranchAdmin ||
        currentUserType === enums.UserType.MultiBranchAdmin) &&
      enterprise_list.length === 0
    ) {
      enterprise_list = [];
    } else if (CURRENT_USER && CURRENT_USER.user_type != 2 && CURRENT_USER.enterprise_info) {
      NgrxStore.dispatch({
        type: '[Common] Set selected enterprise',
        selection: CURRENT_USER.enterprise_info,
      });
    }

    return {
      fetchSelectedEnterprise: function () {
        let result = {};
        commonSelectors.getEnterpriseSelection().subscribe((x) => (result = x));
        return result;
      },
      fetchEnterpriseList: async function (ent) {
        enterprise_list = await rxjs.firstValueFrom(commonSelectors.getVisibleEnterprises());
        return Promise.resolve(enterprise_list);
      },
      setSelectedEnterprise: function (ent) {
        NgrxStore.dispatch({
          type: '[Common] Set selected enterprise',
          selection: ent,
          updateBranch: true,
        });
        trackingService.trackEvent('ENTERPRISE FILTER', 'changed', '');
        $rootScope.$broadcast('EnterpriseSelectionChanged', ent);
      },
      fetchEnterpriseIds: function () {
        return enterpriseIds;
      },
      addEnterprise: function (ent) {
        const { id, branch, name, marketplace } = ent;

        const enterprise = {
          id,
          enterprise_id: id,
          branch,
          branch_id: branch,
          name,
          market_place_id: marketplace,
        };
        NgrxStore.dispatch({
          type: '[Common] Add new enterprise',
          enterprise,
        });
      },
      deleteEnterprise: function (id) {
        NgrxStore.dispatch({
          type: '[Common] Delete enterprise',
          id,
        });
      },
      updateEnterpriseName: function (ent) {
        const { id, name } = ent;
        NgrxStore.dispatch({
          type: '[Common] Update enterprise name',
          id,
          newName: name,
        });
      },

      filterAdminApiItems: function (items, enterpriseId) {
        const selectedEnterprise = this.fetchSelectedEnterprise();
        const selectedEnterpriseId = selectedEnterprise.enterprise_id || enterpriseId;
        const allItems = items;
        let result = [];
        const enterpriseItems = allItems.filter(function (obj) {
          return obj.enterprise != null;
        });
        const marketplaceItems = allItems.filter(function (obj) {
          return obj.enterprise == null;
        });
        if (selectedEnterpriseId == -1) {
          result = marketplaceItems;
        } else {
          result = enterpriseItems.filter(function (obj) {
            return obj.enterprise == selectedEnterpriseId;
          });
        }
        return result;
      },

      filterManageableItems: function (items, enterpriseId) {
        const selectedEnterprise = this.fetchSelectedEnterprise();
        const selectedEnterpriseId = selectedEnterprise.enterprise_id || enterpriseId;
        if (selectedEnterpriseId === -1) {
          return items.filter(function (item) {
            return item.enterprise === null;
          });
        } else {
          return items.filter(function (item) {
            return item.enterprise === selectedEnterpriseId || item.enterprise === null;
          });
        }
      },
    }; // end of return
  },
]);
