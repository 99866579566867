import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../store';
import * as moment from 'moment';
import { TimeFormatService } from '../../../shared/services/time-format.service';
import { Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { EnumsService } from '../../../shared/services/enums.service';

@Component({
  selector: 'session-details-component',
  templateUrl: './session.details.component.html',
})
export class SessionDetailsComponent implements OnInit, OnDestroy {
  private readonly stop$ = new Subject<boolean>();

  private sessionService;
  public details: any;
  public cancelReason: string;
  constructor(
    private store: Store<fromRoot.State>,
    public bsModalRef: BsModalRef,
    @Inject('Session') sessionService,
    private timeFormatService: TimeFormatService,
    private enumsService: EnumsService
  ) {
    this.sessionService = sessionService;
  }

  ngOnInit() {
    this.store
      .pipe(
        takeUntil(this.stop$),
        select(fromRoot.getRequestDetails),
        tap((details) => {
          this.details = new this.sessionService(details);
          this.cancelReason = this.details.cancelled_reason
            ? this.enumsService.CancelStatuses[this.details.cancelled_reason]
            : null;
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.stop$.next(true);
    this.stop$.complete();
  }

  close() {
    this.bsModalRef.hide();
  }

  buildSubmitDate() {
    if (this.details) {
      return moment(this.details.submit_date + ' ' + this.details.submit_time).format(
        this.timeFormatService.format('timeDateLong')
      );
    }
  }
}
