import module from './module';

export default module.factory('priceManagerService', [
  '$http',
  'BASE_URL',
  '$q',
  'Price',
  function ($http, BASE_URL, $q, Price) {
    const pricesManager = {
      _pool: {},
      _pricesObj: {},
      _selectedPrice: {},
      _retrieveInstance: function (priceId, priceData) {
        let instance = this._pool[priceId];
        if (instance) {
          instance.setData(priceData);
        } else {
          instance = new Price(priceData);
          this._pool[priceId] = instance;
          if (priceData.enterprise_id) {
            if (!this._pricesObj[priceData.enterprise_id]) {
              this._pricesObj[priceData.enterprise_id] = [];
            }
            this._pricesObj[priceData.enterprise_id].push(instance);
          } else {
            if (!this._pricesObj[-1]) {
              this._pricesObj[-1] = [];
            }
            this._pricesObj[-1].push(instance);
          }
        }
        return instance;
      },
      _removeInstance: function (price, deferred) {
        const instance = this._pool[price.price_structure_id];
        if (instance) {
          delete this._pool[price.price_structure_id];
          if (price.enterprise_id) {
            const index = this._pricesObj[price.enterprise_id].indexOf(instance);
            if (index !== -1) {
              this._pricesObj[price.enterprise_id].splice(index, 1);
            }
          } else {
            const index = this._pricesObj[-1].indexOf(instance);
            if (index !== -1) {
              this._pricesObj[-1].splice(index, 1);
            }
          }
        }
        deferred.resolve(this._pricesObj);
      },
      _load: function (priceId, deferred) {
        const scope = this;
        $http
          .post(BASE_URL + '/get_price_structure_details', { price_structure_id: priceId })
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              const price = scope._retrieveInstance(response.price_structure_id, response);
              deferred.resolve(price);
            } else {
              deferred.reject(response.message);
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _loadAllPrices: function (deferred, priceParams) {
        const scope = this;
        $http
          .post(BASE_URL + '/get_price_structures', priceParams)
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              response.price_structure_list.forEach(function (priceData) {
                scope._retrieveInstance(priceData.price_structure_id, priceData);
              });
              deferred.resolve(scope._pricesObj);
            } else {
              deferred.reject();
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _loadAttribs: function (realmObj, deferred) {
        $http
          .post(BASE_URL + '/get_realm_attribs', realmObj)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _newPrice: function (priceData, deferred) {
        $http
          .post(BASE_URL + '/add_price_structure', priceData)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _updatePrice: function (priceData, deferred) {
        $http
          .post(BASE_URL + '/update_price_structure', priceData)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      /* Public Methods */
      getPriceAttribs: function (realmObj) {
        const deferred = $q.defer();
        this._loadAttribs(realmObj, deferred);
        return deferred.promise;
      },
      getPrice: function (priceId) {
        const deferred = $q.defer();
        this._load(priceId, deferred);
        return deferred.promise;
      },
      getAllPrices: function (priceParams) {
        const deferred = $q.defer();
        this._loadAllPrices(deferred, priceParams);
        return deferred.promise;
      },
      addNewPrice: function (priceData) {
        const deferred = $q.defer();
        this._newPrice(priceData, deferred);
        return deferred.promise;
      },
      updatePrice: function (priceData) {
        const deferred = $q.defer();
        this._updatePrice(priceData, deferred);
        return deferred.promise;
      },
      removeDeletedPrice: function (price) {
        const deferred = $q.defer();
        this._removeInstance(price, deferred);
        return deferred.promise;
      },
    };
    return pricesManager;
  },
]);
