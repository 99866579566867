import module from './module';

export default module.filter('durationDisplayValue', [
  'FormatHelperService',
  function (FormatHelperService) {
    return function (value, showEmptyWhenNull) {
      if (value === -1) {
        return '';
      }
      const _displayVal = FormatHelperService.returnDurationDisplayVal(value, showEmptyWhenNull);
      return _displayVal;
    };
  },
]);
