import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { IChartDataSource } from '../../../../shared/interfaces/IChartDataSource';

@Component({
  selector: 'line-chart',
  templateUrl: 'line-chart.component.html',
})
export class LineChartComponent implements OnChanges {

  @Input() xAxisLabel: string;
  @Input() yAxisLabel: string;
  @Input() data: IChartDataSource;

  public dataList: any[];
  public view: any[] = [700, 300];

  // options
  public legend: boolean = true;
  public showLabels: boolean = true;
  public animations: boolean = true;
  public xAxis: boolean = true;
  public yAxis: boolean = true;
  public showYAxisLabel: boolean = true;
  public showXAxisLabel: boolean = true;
  public timeline: boolean = true;
  public legendPosition: string = 'below'

  public colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  constructor() {
    this.dataList = this.data?.list;

    this.view = this.getChartWidth();

    window.onresize = (): void => {
      this.view = this.getChartWidth();
    };
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
    this.dataList = this.data?.list;
  }

  public onSelect(data): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  public onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  public onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  private getChartWidth(): number[] {
    if (window.outerWidth > 700) {
      return [700, 300];
    }
    else {
      const widthWithoutPadding: number = window.outerWidth - 20;
      return [widthWithoutPadding, 300];
    }
  }
}
