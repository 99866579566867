import { Injectable } from '@angular/core';
import { SecureHttpService } from '../secure.http.service';
import Env from '../../../scripts/env.js';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TimezoneService {
  constructor(private http: SecureHttpService) {}

  public getTimezones() {
    return this.http
      .get(`${Env.ADMIN_API}/info/timezones/`)
      .pipe(map((response) => _.values(response)))
      .toPromise();
  }
}
