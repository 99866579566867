import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
} from '@angular/core';
import { Worker } from '../../../shared/models/worker';

@Component({
  templateUrl: './worker-profile-item.component.html',
  selector: 'app-worker-profile-item',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkerProfileItemComponent implements OnInit {
  @Input() worker: Worker;
  @Input() fieldName: string;

  public name: string;
  public value: string;

  ngOnInit() {
    const property = this.worker.profile_info.find(
      (x) => x.name === this.fieldName
    );
    if (property) {
      this.name = property.display_name;
      this.value = property.display_value;
    }
  }
}
