import angular from 'angular';
import module from '../module';
import * as enums from '../../enums';
export default module.controller('NewProviderModalController', [
  '$scope',
  '$timeout',
  '$modalInstance',
  'AlertService',
  'FormatHelperService',
  'enterprise_selection',
  'providerManagerService',
  'generalDataService',
  'AuthenticationService',
  'LocationTabService',
  'googleLocationService',
  'PERMISSIONS',
  'branchFilterService',
  'branch_selection',
  'sessionManagerService',
  function (
    $scope,
    $timeout,
    $modalInstance,
    AlertService,
    FormatHelperService,
    enterprise_selection,
    providerManagerService,
    generalDataService,
    AuthenticationService,
    LocationTabService,
    googleLocationService,
    PERMISSIONS,
    branchFilterService,
    branch_selection,
    sessionManagerService
  ) {
    const CURRENT_USER = AuthenticationService.getCurrentUser();
    $scope.keyProfession = generalDataService.getKeyProfession();
    $scope.submitBtnDisbaled = false;
    $scope.formType = 'new';
    $scope.state_list = LocationTabService.getStateList();
    $scope.branchData = { branch_id: null };
    $scope.locationData = {
      value: {
        location: '',
        details: {},
      },
    };

    $scope.entFilter = enterprise_selection;
    $scope.submitBtnLabel = `Save ${$scope.keyProfession.data_content || 'Worker'}`;
    if ($scope.entFilter.enterprise_id == -1) {
      $scope.title = 'Add Contract';
    } else {
      $scope.title = 'Add Staff';
    }

    $scope.toggleAttributes = function (show) {
      const attrib_names = ['address_line_1', 'address_line_2', 'city', 'zip_code', 'state', 'country'];
      if ($scope.formData) {
        const addressAttribs = $scope.formData.filter(function (item) {
          return attrib_names.indexOf(item.name) !== -1;
        });

        addressAttribs.forEach(function (attrib) {
          if (attrib.type_flags) {
            attrib.type_flags.display = show;
          }
        });
      }
    };

    $scope.hideState = function () {
      console.log('hide the state');
      const state = $scope.formData.find(function (item) {
        return item.name === 'state';
      });
      if (state) {
        state.type_flags.display = false;
      }
    };

    $scope.handleAddress = function (attribs) {
      const location = attribs.find(function (item) {
        return item.name === 'location';
      });
      if (location) {
        return;
      }
      const address = attribs.find(function (item) {
        return item.name === 'address_line_1';
      });
      if (address) {
        $scope.locationRequired = address.type_flags.required;
        $scope.showLocationInput = true;
        $scope.toggleAttributes(false);
        $scope.$watch('locationData.value.location', $scope.updateLocationAttributes);
      }
    };

    $scope.setAttributeValue = function (name, value) {
      const attrib = $scope.formData.find(function (attrib) {
        return attrib.name === name;
      });
      if (attrib && attrib.type === 'text') {
        attrib.value = value;
      } else if (attrib && attrib.type === 'single_list') {
        attrib.value = { choice_id: value };
      }
    };

    $scope.updateLocationAttributes = function (value) {
      if (value) {
        $timeout(function () {
          try {
            const address = googleLocationService.parseAddress($scope.locationData.value.details, $scope.state_list);
            $scope.setAttributeValue('state', address.state && address.state.id);
            if (address.zip_code) {
              $scope.setAttributeValue('zip_code', address.zip_code.toString());
            }
            $scope.setAttributeValue('address_line_1', address.address_line_1);
            $scope.setAttributeValue('city', address.city);
            $scope.setAttributeValue('country', address.countryCode);
            $scope.isForeign = address.isForeign;

            if (address.isForeign) {
              $scope.hideState();
            }
          } catch (e) {
            if ($scope.formData.longitude == 0 || $scope.formData.latitude == 0) {
              AlertService.errorAlert('Cannot find address, please try a different address');
            }
          }
        }, 200);
        $scope.toggleAttributes(true);
      }
    };

    $scope.handleBranch = function () {
      const CURRENT_USER = AuthenticationService.getCurrentUser();
      if (CURRENT_USER.user_type === 2 && CURRENT_USER.permissionCheck(PERMISSIONS.BRANCH_TAB)) {
        branchFilterService.fetchBranchesForList().then((branches) => {
          if (branches && branches.length) {
            $scope.branches = branches;
          }
        });
      }
    };

    // Grabbing list of Job attribs from API
    const getProviderRegAttribs = function () {
      sessionManagerService.getRealmAttribs(enums.Realms.Worker).then(function (result) {
        const formData = result.attrib_list.sort(function (a, b) {
          return a.order - b.order;
        });
        $scope.originalFormData = angular.copy(formData);
        $scope.formData = angular.copy(formData);
        $scope.handleAddress(formData);
        $scope.handleBranch(formData);
      });
    };
    getProviderRegAttribs();

    // listen for instance_count attrib clicks
    $scope.$on('addAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      const _count = field.instanceCountIndex || 1;
      const _field = angular.copy(
        $scope.originalFormData.filter(function (obj) {
          return obj.attrib_id == field.attrib_id;
        })[0]
      );
      _field.instanceCountIndex = _count + 1;
      _field.showRemoveInstance = true;
      $scope.formData[index].doNotShowAddInstance = true;
      $scope.formData[index].showRemoveInstance = false;
      $scope.formData.splice(index + 1, 0, _field); // add after current field
    });

    $scope.$on('removeAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      $scope.formData[index - 1].doNotShowAddInstance = false;
      $scope.formData[index - 1].showRemoveInstance = true;
      $scope.formData.splice(index, 1);
    });

    // Modal controls
    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      const formData = $scope.formData;
      let errorPresent;
      for (let i = 0; i < formData.length; i++) {
        if (formData[i].type === 'multi_list' && formData[i].type_flags.required == true) {
          if (formData[i].value.length < 1) {
            errorPresent = true;
          }
        } else if (formData[i].type === 'location' && formData[i].type_flags.required == true) {
          if (formData[i].value.location_id == 0 && !formData[i].value.details) {
            errorPresent = true;
          }
        } else if (formData[i].name === 'radius') {
          // send back the default radius at least
          if (!formData[i].value) {
            formData[i].value = formData[i].default_count;
          }
        }
      }
      if (errorPresent) {
        AlertService.warningAlert('Please fill in all required fields');
        $scope.submitBtnDisbaled = false;
      } else {
        sendToServer();
      }
    };

    function sendToServer() {
      const obj = FormatHelperService.format_data($scope.formData);
      const providerDetails = {
        username: obj.filter(function (obj) {
          return obj.name === 'username';
        })[0].value_text,
        password: obj.filter(function (obj) {
          return obj.name === 'password';
        })[0].value_text,
        user_type: 4,
        market_place_id: CURRENT_USER.market_place_info.id,
        service_type_id: 1,
        profile_info: obj,
      };

      if (CURRENT_USER.user_type == 3) {
        providerDetails.enterprise_id = CURRENT_USER.enterprise_info.id;
      } else if (CURRENT_USER.user_type == 2 && $scope.entFilter.enterprise_id != -1) {
        providerDetails.enterprise_id = $scope.entFilter.enterprise_id;
      }

      providerDetails.branch_id = $scope.branchData.branch_id;
      providerManagerService.registerProvider(providerDetails).then(
        function (response) {
          if (response.success) {
            $modalInstance.close(response);
          } else {
            AlertService.errorAlert(response.message);
            $scope.submitBtnDisbaled = false;
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    }

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  },
]);
