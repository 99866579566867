import module from '../module';

export default module.directive('scheduleDirective', [
  function () {
    return {
      restrict: 'E',
      templateUrl: 'app/views/schedule/index.html',
      controller: 'ScheduleController',
    };
  },
]);
