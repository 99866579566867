import angular from 'angular';
import module from '../module';
import * as enums from '../../enums';
export default module.controller('EditRequesterModalController', [
  '$scope',
  '$modalInstance',
  'passedRequester',
  'requesterManagerService',
  'FormatHelperService',
  'AlertService',
  'sessionManagerService',
  function (
    $scope,
    $modalInstance,
    passedRequester,
    requesterManagerService,
    FormatHelperService,
    AlertService,
    sessionManagerService
  ) {
    $scope.title = 'Edit Requester';
    $scope.submitBtnLabel = 'Update Requester';
    $scope.formType = 'edit';
    $scope.originalRequester = angular.copy(passedRequester);
    $scope.submitBtnDisbaled = false;

    const loadProvider = function () {
      requesterManagerService.getRequester(passedRequester.user_id).then(function (response) {
        $scope.requester = response;
        getRequesterRegAttribs();
      });
    };

    // Grabbing list of requester attribs from API
    const getRequesterRegAttribs = function () {
      sessionManagerService.getRealmAttribs(enums.Realms.Requester).then(function (result) {
        $scope.formData = result.attrib_list.sort(function (a, b) {
          return a.order - b.order;
        });
        // setAndCopyFormData();
        $scope.originalFormData = angular.copy($scope.formData);
      });
    };

    // Modal controls
    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      const requesterDetails = {};
      const obj = FormatHelperService.format_data($scope.formData);
      const originalUsername = $scope.requester.profile_info.filter(function (obj) {
        return obj.name === 'username';
      })[0];

      for (let i = 0; i < obj.length; i++) {
        if (obj[i].name === 'username') {
          const editUsername = obj.splice([i], 1)[0];
          if (editUsername.value_text != originalUsername.data) {
            requesterDetails.new_username = editUsername.value_text;
          }
        }
        if (obj[i].name === 'password') {
          const newPassword = obj.splice([i], 1)[0];
          requesterDetails.new_password = newPassword.value_text;
        }
      }

      requesterDetails.user_id = passedRequester.user_id;
      requesterDetails.profile_info = obj;

      sendToServer(requesterDetails);
    };

    const sendToServer = function (requesterDetails) {
      requesterManagerService.updateRequesterAttribs(requesterDetails).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
            $modalInstance.close();
          } else {
            $scope.submitBtnDisbaled = false;
            AlertService.errorAlert(response.message);
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    };

    $scope.cancel = function () {
      angular.copy($scope.original, $scope.data);
      $modalInstance.dismiss('cancel');
    };

    loadProvider();
  },
]);
