import module from './module';
export default module.filter('providerType', [
  function () {
    return function (items, providerType) {
      if (!providerType || !items) {
        return items;
      }
      return items.filter(function (provider) {
        if (providerType == true) {
          return provider.enterprise_info != null;
        } else if (providerType == false) {
          return provider.enterprise_info;
        }
        return false;
      });
    };
  },
]);
