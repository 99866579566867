import { Inject, Injectable, Injector } from '@angular/core';
import * as moment from 'moment';

const hash = {
  shortMonth: 'DD/MMM/YYYY', // 23/Aug/2020
  dateTimeLong: 'MMM DD YY / h:mm A', // Aug 23 20 / 4:22 PM
  timeDateLong: 'HH:mm / D-MMM-YY', // 16:22 / 23-Aug-20
  api: 'YYYYMMDD HHmm', // 20200823 1622
  apiDate: 'YYYYMMDD', // 20200823
  shortDate: 'M/D/YYYY', // 8/23/2020
  shortDateTime: 'M/D/YYYY hh:mm A', // 8/23/2020 04:23 PM
  fullDateTimeSecs: 'YYYY-MM-DD HH:mm:ss', // 2020-08-23 16:23:44
  fullDateTime: 'YYYY-MM-DD HH:mm', // 2020-08-23 16:23
  middleDate: 'MM/DD/YYYY', // 08/23/2020
  shortMonthYear: 'M/DD/YY', // 8/23/20
  fullDate: 'YYYY-MM-DD', // 2020-08-23
  fullDateNoSeparator: 'MMDDYYYY', // 08232020
  fullDateTimeNoSeparator: 'MMDDYYYY HHmm', // 08232020 1625
  fullShortMonthYear: 'M/DD/YY h:mm A', // 8/23/20 4:25 PM
  dateSlash: 'MM/DD/YY', // 08/23/20
  dateSlashTemplate: 'MM/dd/yy', // 08/23/20
  interestDate: 'DD MMM YY', // 23 Aug 20
  fullDateLongMonth: 'YYYY-MMM-DD', // 2020-Aug-23
  fullDateHour: 'YYYY-MM-DD H', // 2020-08-23 16
  fullDateHourMinute: 'YYYY-MM-DD H:mm', // 2020-08-23 16:26
  jobDate: 'ddd M/D/YY',
};

const uk = {
  shortDate: 'D/M/YYYY',
  shortDateTime: 'D/M/YYYY hh:mm A',
  middleDate: 'DD/MM/YYYY',
  shortMonthYear: 'DD/M/YY',
  fullShortMonthYear: 'DD/M/YY h:mm A',
  dateSlash: 'DD/MM/YY',
  dateSlashTemplate: 'dd/MM/yy',
  jobDate: 'ddd D/M/YY',
};

@Injectable()
export class TimeFormatService {
  private cache = {};
  constructor(private injector: Injector) {}

  public format(key: string) {
    let isGB = false;
    if (window.navigator.languages) {
      isGB = window.navigator.languages.some((x) => x.indexOf('en-GB') > -1);
    }

    if (isGB) {
      return uk[key] || hash[key];
    }
    return hash[key];
  }

  public async formatTimezone(key: string, date: string, time: string, location: any, changeTimeZone: boolean = false) {
    const googleLocationService = this.injector.get('googleLocationService');

    const startTime = moment(time).format('HHmm');
    const startDate = moment(date).format(this.format('fullDateNoSeparator'));
    const startDateTime = startDate + ' ' + startTime;
    const convertedTime = moment(startDateTime, this.format('fullDateTimeNoSeparator')).format('YYYY-MM-DDTHH:mm:ss');

    const timestamp = new Date(convertedTime).getTime() / 1000;
    let data;
    try {
      const key = `${JSON.stringify(location)}--${timestamp}`;
      data = this.cache[key];
    } catch (e) {}

    if (!data) {
      data = await googleLocationService.getTimezone(location, timestamp);
      const key = `${JSON.stringify(location)}--${timestamp}`;
      this.cache[key] = data;
    }

    const timezoneOffset = data ? (parseInt(data.rawOffset) + parseInt(data.dstOffset)) / 60 : 0;

    return moment(date).utcOffset(timezoneOffset, changeTimeZone);
  }
}
