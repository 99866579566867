import module from './module';
import * as enums from '../enums';
import * as rxjs from 'rxjs';
import moment from 'moment';

export default module.directive('dirPageHeader', function () {
  const BRANCH_ID = -2;
  return {
    restrict: 'E',
    controller: [
      '$scope',
      '$rootScope',
      'AuthenticationService',
      'EnterpriseFilterService',
      'branchFilterService',
      '$location',
      'WebsocketService',
      'PERMISSIONS',
      'ForceFilterService',
      'NgrxStore',
      'commonSelectors',
      async function (
        $scope,
        $rootScope,
        AuthenticationService,
        EnterpriseFilterService,
        branchFilterService,
        $location,
        WebsocketService,
        PERMISSIONS,
        ForceFilterService,
        NgrxStore,
        commonSelectors
        //$route,
      ) {
        $scope.showAdminFilters = $location.path()?.includes('schedule');
        $scope.data = { filters: {} };
        $scope.entFilter = {};

        const queryParams = $location.search();
        $scope.fromBold = queryParams && queryParams.ext_order_id;

        if ($scope.showAdminFilters) {
          $scope.adminFilters = await ForceFilterService.fetchRequiredAttribs();
          // Set up $scope.data.filters to each admin filter
          // It's used for ng-model binding
          const filters = ForceFilterService.getFilter();
          filters.forEach((filter) => {
            // Each stored admin filter is a tuple: [attrib_id, choice_id]
            // Set up the $scope.data.filters to the stored values
            const adminFilter = $scope.adminFilters.find((x) => x.attrib_id === filter[0]);
            if (adminFilter) {
              const choice = adminFilter?.choice_list.find((x) => x.choice_id === filter[1]);
              $scope.data.filters[adminFilter.attrib_id] = choice;
            }
          });

          const locationFilter = $scope.adminFilters.find((x) => x.type === 'location');
          if (locationFilter) {
            $scope.data.filters[locationFilter.attrib_id] = { choice_id: +ForceFilterService.getLocationId() };
          }
        }

        $scope.updateAdminFilters = () => {
          $scope.adminFilters.forEach((filter) => {
            filter.value = [$scope.data.filters[filter.attrib_id]];
          });

          // Update the filter's value based on the ng-model binding
          // Store the filters
          ForceFilterService.setFilter($scope.adminFilters);
          // Notify other parts of the system
          $rootScope.$broadcast('adminFilter');
        };

        const enterpriseSelectionSubscription = commonSelectors.getEnterpriseSelection().subscribe((selection) => {
          $scope.data.enterprise = selection;
          $scope.entFilter = $scope.data.enterprise;
          $rootScope.$broadcast('EnterpriseSelectionChanged', $scope.data.enterprise);
        });

        const branchSelectionSubscription = commonSelectors.getBranchSelection().subscribe((selection) => {
          $scope.data.branch = selection;
        });

        const enterpriseListSubscription = commonSelectors.getVisibleEnterprises().subscribe((enterprises) => {
          $scope.enterprise_list = enterprises;
        });

        $scope.branchFiterChanged = async function (enterpriseOrigin, changeEnterprise) {
          if ($scope.showFilter) {
            NgrxStore.dispatch({
              type: '[Common] Set selected branch',
              selection: $scope.data.branch,
              updateEnterprise: true,
            });

            $rootScope.$emit('selectedBranchChanged');
          }
        };

        //  current_user and entFilter accessible for session queue/notification, reporting and admin tabs
        $scope.current_user = AuthenticationService.getCurrentUser();
        $scope.showFilter =
          $scope.current_user.user_type == enums.UserType.MarketPlaceAdmin ||
          $scope.current_user.user_type == enums.UserType.BranchAdmin;
        $scope.isMarketplaceUser = $scope.current_user.user_type === enums.UserType.MarketPlaceAdmin;
        $scope.hasBranchPermission = $scope.current_user.permissionCheck(PERMISSIONS.BRANCH_TAB);

        let branchListSubscription;
        if ($scope.showFilter) {
          branchListSubscription = commonSelectors.getBranchList().subscribe((branches) => {
            $scope.branches = branches;
          });
        }

        $scope.determineState = function (branch, enterprise) {
          if (branch) {
            if (branch.id === -1) {
              $scope.selectionType = `Marketplace`;
              $scope.selectionData = '';
            } else {
              $scope.selectionType = 'Branch';
              $scope.selectionData = ` ${branch.name}`;
            }
          }

          if (enterprise) {
            if (enterprise.id !== -1 && enterprise.id !== -2) {
              $scope.selectionType = `Enterprise `;
              $scope.selectionData = ` ${$scope.getEnterpriseName(enterprise)}`;
            } else if ($scope.current_user.user_type === enums.UserType.BranchAdmin || enterprise.id === -2) {
              $scope.selectionType = 'Branch';
            }
          }
        };

        $scope.getEnterpriseName = function (enterprise) {
          if (!enterprise) {
            return '';
          }

          if (typeof enterprise.name === 'string' && enterprise.name.length) {
            return enterprise.name;
          }

          if ($scope.current_user?.enterprise_info?.name) {
            return $scope.current_user.enterprise_info.name;
          }

          return '';
        };

        const branchesUpdated = $rootScope.$on('Branches.Updated', () => {
          branchFilterService.fetchBranches(true).then((branches) => {
            $scope.branches = branches;
            $scope.data.branch = branches[0];
          });
        });

        if ($scope.current_user.user_type == enums.UserType.MarketPlaceAdmin) {
        } else if ($scope.current_user.user_type == enums.UserType.BranchAdmin) {
        } else {
          $scope.entFilter.enterprise_id = $scope.current_user.enterprise_info.id;
        }

        if ($location.$$path === '/support') {
          // if ($location.$$path == '/job' || $location.$$path == '/support') {
          $scope.showFilterBtn = false;
        } else {
          $scope.showFilterBtn = true;
        }

        if ($scope.current_user.enterprise_info) {
          $scope.title = $scope.current_user.enterprise_info.name;
        } else {
          $scope.title = $scope.current_user.market_place_info.name;
        }

        // grabbing and setting selected enterprise
        if (
          ($scope.current_user.user_type == enums.UserType.MarketPlaceAdmin ||
            $scope.current_user.user_type == enums.UserType.BranchAdmin) &&
          !$scope.enterprise_list &&
          !$scope.data.enterprise
        ) {
          $scope.data.enterprise = EnterpriseFilterService.fetchSelectedEnterprise();
        }

        $scope.entFilterChanged = async function () {
          NgrxStore.dispatch({
            type: '[Common] Set selected enterprise',
            selection: $scope.data.enterprise,
            updateBranch: true,
          });

          const workWeekStart = $scope.data.enterprise.workweek_start;
          const currentWorkWeekStart = moment.localeData().firstDayOfWeek();

          if (workWeekStart !== currentWorkWeekStart) {
            if (workWeekStart || workWeekStart === 0) {
              moment.updateLocale('en', {
                week: {
                  dow: workWeekStart,
                },
              });
            }
            $rootScope.$broadcast('WorkWeekStart.Change');
          }

          if ($scope.entFilter) {
            try {
              if ($scope.entFilter.enterprise_id !== -1) {
                WebsocketService.connectToEnterprise($scope.entFilter.enterprise_id);
              } else {
                WebsocketService.closeSocket();
              }
            } catch (e) { }
          }
        };

        const workingAsSubscription = rxjs
          .combineLatest([commonSelectors.getBranchSelection(), commonSelectors.getEnterpriseSelection()])
          .subscribe(([branch, enterprise]) => {
            $scope.determineState(branch, enterprise);
          });

        $scope.$on('$destroy', function () {
          branchesUpdated();

          enterpriseListSubscription?.unsubscribe();
          branchSelectionSubscription?.unsubscribe();
          enterpriseSelectionSubscription?.unsubscribe();
          branchListSubscription?.unsubscribe();
          workingAsSubscription?.unsubscribe();
        });
      },
    ],
    templateUrl: 'app/views/templates/page_header.html',
  };
});
