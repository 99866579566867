import { Injectable } from '@angular/core';
import { EnterpriseListService } from '../services/api/enterprise-list.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, catchError, exhaustMap, filter, from, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import {
  BranchListFetched,
  EnterpriseListFetched,
  FETCH_BRANCH_LIST,
  FETCH_ENTERPRISE_LIST,
  FETCH_REALM_ATTRIBS,
  FetchRealmAttribs,
  RealmAttribsError,
  RealmAttribsFetched,
  SET_SELECTED_BRANCH,
  SET_SELECTED_ENTERPRISE,
  SetSelectedBranch,
  SetSelectedEnterprise,
} from './actions';
import { AuthenticationService, BranchFilterWrapperService, GeneralDataService } from '../services';
import { UserType } from 'app/scripts/enums';
import { Action, Store } from '@ngrx/store';
import { State } from 'app/store';
import { IEnterpriseSelection } from '../interfaces';

@Injectable()
export class CommonEffect {
  fetchEnterprises$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FETCH_ENTERPRISE_LIST),
      mergeMap(() =>
        this.enterpriseListService.getAllEnterprisesSimple$().pipe(
          switchMap((apiResult) => {
            const marketplaceId = this.authenticationService.getCurrentUser()?.market_place_info?.id;
            const list = apiResult ?? [];
            const result = [
              ...list.map((item) => ({
                ...item,
                enterprise_id: item.id,
                branch_id: item.branch,
                market_place_id: marketplaceId,
              })),
            ];

            result.sort((a, b) => a.name.localeCompare(b.name));

            const currentUserType = this.authenticationService.getCurrentUserType();
            if (
              [UserType.BranchAdmin, UserType.MultiBranchAdmin, UserType.MarketPlaceAdmin].includes(currentUserType)
            ) {
              let allEntObj: any = { id: -1, name: 'Marketplace', enterprise_id: -1 };
              if (currentUserType === UserType.BranchAdmin || currentUserType === UserType.MultiBranchAdmin) {
                allEntObj = { id: -1, name: 'Branch', branch_id: -1 };
              }

              if (result.indexOf(allEntObj) == -1) {
                result.unshift(allEntObj);
              }
            }

            return [new EnterpriseListFetched(result), new SetSelectedEnterprise(result[0])];
          })
        )
      )
    )
  );

  fetchBranches$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(FETCH_BRANCH_LIST),
        mergeMap(() =>
          from(this.branchFilterService.fetchBranches()).pipe(
            switchMap((result: any) => {
              const currentUserType = this.authenticationService.getCurrentUserType();
              if (currentUserType === UserType.MultiBranchAdmin) {
                const branchIds = result.map((b) => b.branch_id).sort((a, b) => a.name.localeCompare(b.name));
                result = result.filter((e) => branchIds.indexOf(e.branch_id) > -1);
              }

              const mappedResult =
                result.map((item) => ({ ...item, branch_id: item.id, market_place_id: item.marketplace })) || [];

              return [new BranchListFetched(mappedResult), new SetSelectedBranch(result[0])];
            })
          )
        )
      )
  );

  setSelectedEnteprise$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SET_SELECTED_ENTERPRISE),
        filter((action: SetSelectedEnterprise) => !!action.selection.branch),
        withLatestFrom(this.store),
        map(([action, state]: [SetSelectedEnterprise, State]) => {
          if (action.updateBranch) {
            const targetBranch = state.commonData.branchList?.find(
              (x) => x.id === action.selection.branch || x.branch_id === action.selection.branch
            );
            return new SetSelectedBranch(targetBranch, false);
          }
          return { type: 'ignore' };
        })
      )
  );

  setSelectedBranch$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(SET_SELECTED_BRANCH),
        withLatestFrom(this.store),
        map(([action, state]: [SetSelectedBranch, State]) => {
          if (action.updateEnterprise) {
            if (action.selection && action.selection.id === -1 && state.commonData.enterpriseList) {
              return new SetSelectedEnterprise(
                state.commonData.enterpriseList[0] as unknown as IEnterpriseSelection,
                false
              );
            } else {
              return new SetSelectedEnterprise(
                { id: -1, name: 'Branch', enterprise_id: -1 } as unknown as IEnterpriseSelection,
                false
              );
            }
          } else {
            return { type: 'ignore' };
          }
        })
      )
  );

  fetchRealmAttribs$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(FETCH_REALM_ATTRIBS),
        exhaustMap((action: FetchRealmAttribs) => {
          return this.generalDataService
            .getRealmAttribs(action.realmId, action.enterpriseId > 0 ? action.enterpriseId : undefined)
            .pipe(
              switchMap((result) => of(new RealmAttribsFetched(action.realmId, action.enterpriseId, result))),
              catchError((error) => of(new RealmAttribsError(action.realmId, action.enterpriseId, error)))
            );
        })
      )
  );

  constructor(
    private actions$: Actions,
    private enterpriseListService: EnterpriseListService,
    private authenticationService: AuthenticationService,
    private branchFilterService: BranchFilterWrapperService,
    private store: Store<State>,
    private generalDataService: GeneralDataService
  ) {}
}
