import module from './module';

export default module.service('timeRuleService', [
  '$sce',
  function ($sce) {
    function getCheckinRules(ruleInfo) {
      if (ruleInfo) {
        const checkInIds = [3];
        return ruleInfo.filter(function (item) {
          return checkInIds.indexOf(item.rule_type) !== -1;
        });
      }
    }

    function getCheckoutRules(ruleInfo) {
      if (ruleInfo) {
        const checkOutIds = [1, 2, 4];
        return ruleInfo.filter(function (item) {
          return checkOutIds.indexOf(item.rule_type) !== -1;
        });
      }
    }

    function getDisabledRules(rules) {
      if (rules) {
        return rules
          .filter(function (rule) {
            return rule.setDisabled;
          })
          .map(function (rule) {
            return rule.rule_id;
          });
      }
    }

    function generateCheckOutViewRules(ruleInfo) {
      const rules = getCheckoutRules(ruleInfo);
      if (rules && rules.length) {
        const filteredNames = rules
          .filter(function (rule) {
            return rule.viewable && !rule.can_disable;
          })
          .map(function (rule) {
            return rule.name;
          });
        if (filteredNames) {
          return $sce.trustAsHtml(filteredNames.join('<br>'));
        }
      }
    }

    function generateCheckInViewRules(ruleInfo) {
      const rules = getCheckinRules(ruleInfo);
      if (rules && rules.length) {
        const filteredNames = rules
          .filter(function (rule) {
            return rule.viewable && !rule.can_disable;
          })
          .map(function (rule) {
            return '<span>' + rule.name + '</span>';
          });
        if (filteredNames) {
          return $sce.trustAsHtml(filteredNames.join('<br>'));
        }
      }
    }

    return {
      getCheckInRules: getCheckinRules,
      getCheckOutRules: getCheckoutRules,
      getDisabledRules,
      generateCheckOutViewRules,
      generateCheckInViewRules,
    };
  },
]);
