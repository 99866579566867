import module from '../module';
export default module.controller('MassCancelModalController', [
  '$scope',
  '$rootScope',
  '$modalInstance',
  'passedData',
  function ($scope, $rootScope, $modalInstance, passedData) {
    if (passedData) {
      $scope.date = passedData.date;
      $scope.sessionData = passedData.sessionData;
      $scope.filterType = passedData.filterType;
    }

    $scope.closeCallback = {
      doClose: function () {
        $modalInstance.close();
      },
    };
  },
]);
