import * as actions from './actions/index';

export interface RequestListFilter {
  showLate: boolean;
  showLong: boolean;
  status: number;
  cancelCode: number;
  showAccepted: boolean;
  keyAttribute: string;
  location: string;
  showAllAccepted: boolean;
  showAllOffered: boolean;
  showAll: boolean;
  showAllLate: boolean;
}

export interface State {
  workerFilter: string;
  enterpriseFilter: number;
  timeFilter: number;
  categoryFilter: number;
  widgets: any[];
  allWidgets: any[];
  requests: any[];
  timeRange: any;
  isLoading: boolean;
  selectedWidget: any;
  enterprises: any[];
  keyAttribute: any;
  keySession: string;
  keyProfession: string;
  workerInfo: any;
  dragMode: boolean;
  jobs: any[];
  hasValidFilter: boolean;
  requestDetails: any;
  providerDetails: any;
  requestListFilter: RequestListFilter;
  verifyListFilter: RequestListFilter;
  verifyReport: any[];
  staffFilter: number;
  permissions: any;
  hourlyFilter: number;
}

const initialState: State = {
  workerFilter: 'All',
  enterpriseFilter: -1,
  staffFilter: 0,
  timeFilter: 1,
  categoryFilter: 1,
  widgets: [],
  allWidgets: [],
  requests: [],
  timeRange: null,
  isLoading: false,
  selectedWidget: null,
  enterprises: [],
  keyAttribute: null,
  keySession: null,
  keyProfession: null,
  workerInfo: null,
  dragMode: false,
  jobs: [],
  hasValidFilter: false,
  requestDetails: null,
  providerDetails: null,
  verifyReport: [],
  permissions: {},
  hourlyFilter: 0,
  requestListFilter: {
    showLate: false,
    showLong: false,
    status: null,
    cancelCode: null,
    showAccepted: false,
    keyAttribute: null,
    location: null,
    showAllLate: false,
    showAllAccepted: false,
    showAllOffered: false,
    showAll: false,
  },
  verifyListFilter: {
    showLate: false,
    showLong: false,
    status: null,
    cancelCode: null,
    showAccepted: false,
    keyAttribute: null,
    location: null,
    showAll: false,
    showAllAccepted: false,
    showAllOffered: false,
    showAllLate: false,
  },
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.WORKER_FILTER_CHANGE:
      return Object.assign({}, state, { workerFilter: action.value });
    case actions.ENTERPRISE_FILTER_CHANGE:
      return Object.assign({}, state, { enterpriseFilter: action.enterpriseId, isLoading: true });
    case actions.TIME_FILTER_CHANGE:
      return Object.assign({}, state, { timeFilter: action.timeFilterId });
    case actions.CATEGORY_FILTER_CHANGE:
      return Object.assign({}, state, { categoryFilter: action.categoryFilterId });
    case actions.WIDGETS_CHANGED:
      return Object.assign({}, state, { widgets: action.selectedWidgets, allWidgets: action.allWidgets });
    case actions.LOADED_MY_REQUESTS:
      return Object.assign({}, state, { requests: action.requests, isLoading: false });
    case actions.TIME_RANGE_CHANGED:
      return Object.assign({}, state, { timeRange: action.timeRange, isLoading: true });
    case actions.SELECTED_WIDGET_CHANGED:
      return Object.assign({}, state, { selectedWidget: action.widget });
    case actions.LOADED_ENTERPRISES:
      return Object.assign({}, state, { enterprises: action.enterprises });
    case actions.LOADED_KEY_ATTRIBUTE:
      return Object.assign({}, state, { keyAttribute: action.keyAttribute });
    case actions.LOADED_WORKERS:
      return Object.assign({}, state, { workerInfo: action.workerInfo });
    case actions.FILTER_REQUEST_LIST:
      return Object.assign({}, state, { requestListFilter: action.filter });
    case actions.DRAG_MODE_CHANGED:
      return Object.assign({}, state, { dragMode: action.dragMode });
    case actions.LOADED_JOBS:
      return Object.assign({}, state, { jobs: action.jobs });
    case actions.HAS_VALID_FILTER:
      return Object.assign({}, state, { hasValidFilter: action.value });
    case actions.LOADED_REQUEST_DETAILS:
      return Object.assign({}, state, { requestDetails: action.request });
    case actions.LOADED_VERIFY_REPORT:
      return Object.assign({}, state, { verifyReport: action.report, isLoading: false });
    case actions.FILTER_REPORT_LIST:
      return Object.assign({}, state, { verifyListFilter: action.filter });
    case actions.STAFF_FILTER_CHANGE:
      return Object.assign({}, state, { staffFilter: action.code, isLoading: true });
    case actions.PERMISSIONS_CHANGED:
      state.permissions[action.permissionId] = action.value;
      return state;
    case actions.LOADED_PROVIDER_DETAILS:
      return Object.assign({}, state, { providerDetails: action.details });
    case actions.LOADED_KEY_SESSION:
      return Object.assign({}, state, { keySession: action.keySession });
    case actions.LOADED_KEY_PROFESSION:
      return Object.assign({}, state, { keyProfession: action.keyProfession });
    case actions.HOURLY_FILTER_CHANGE:
      return Object.assign({}, state, { hourlyFilter: action.hourlyId });
    default:
      return state;
  }
}

export const getWorkerFilter = (state: State) => state.workerFilter;
export const getEnterpriseFilter = (state: State) => state.enterpriseFilter;
export const getTimeFilter = (state: State) => state.timeFilter;
export const getCategoryFilter = (state: State) => state.categoryFilter;
export const getWidgets = (state: State) => state.widgets;
export const getAllWidgets = (state: State) => state.allWidgets;
export const getRequests = (state: State) => state.requests;
export const getTimeRange = (state: State) => state.timeRange;
export const getIsLoading = (state: State) => state.isLoading;
export const getSelectedWidget = (state: State) => state.selectedWidget;
export const getEnterprises = (state: State) => state.enterprises;
export const getKeyAttribute = (state: State) => state.keyAttribute;
export const getWorkerInfo = (state: State) => state.workerInfo;
export const getRequestListFilter = (state: State) => state.requestListFilter;
export const getDragMode = (state: State) => state.dragMode;
export const getJobs = (state: State) => state.jobs;
export const getHasValidFilter = (state: State) => state.hasValidFilter;
export const getRequestDetails = (state: State) => state.requestDetails;
export const getVerifyReport = (state: State) => state.verifyReport;
export const getVerifyListFilter = (state: State) => state.verifyListFilter;
export const getStaffFilter = (state: State) => state.staffFilter;
export const getPermissions = (state: State) => state.permissions;
export const getProviderDetails = (state: State) => state.providerDetails;
export const getKeySession = (state: State) => state.keySession;
export const getKeyProfession = (state: State) => state.keyProfession;
export const getHourlyFilter = (state: State) => state.hourlyFilter;
