import module from './module';

export default module.factory('RequesterTabService', [
  '$http',
  '$localStorage',
  'BASE_URL',
  function ($http, $localStorage, BASE_URL) {
    let tableDetails = []; // table

    // filter Array of Providers for dispaly in Table view
    const filterTableView = function (requesters) {
      for (let i = 0; i < requesters.length; i++) {
        const first_name = requesters[i].profile_info.filter(function (obj) {
          return obj.name === 'first_name';
        })[0];
        const last_name = requesters[i].profile_info.filter(function (obj) {
          return obj.name === 'last_name';
        })[0];
        const status_obj = requesters[i].profile_info.filter(function (obj) {
          return obj.name === 'login_status';
        })[0];
        const permission_obj = requesters[i].profile_info.filter(function (obj) {
          return obj.name === 'requester_vetting_status';
        })[0];
        const requester_obj = {
          user_id: requesters[i].user_id,
          requester_id: requesters[i].requester_id,
          market_place_info: requesters[i].market_place_info,
          enterprise_info: requesters[i].enterprise_info,
          full_name: first_name.data + ' ' + last_name.data,
          first_name: first_name.data,
          last_name: last_name.data,
          permission: permission_obj.choice_list.filter(function (obj) {
            return obj.selected == true;
          })[0].name,
          login_status: status_obj.choice_list.filter(function (obj) {
            return obj.selected == true;
          })[0].name,
          status_code: requesters[i].status,
        };
        tableDetails.push(requester_obj);
      } // end of for loop
      return tableDetails;
    };

    return {
      formatRequesterList: function (data, callback) {
        if (tableDetails.length > 0) {
          callback(tableDetails);
        } else {
          const filteredResponse = filterTableView(data);
          callback(filteredResponse);
        }
      },
      clearTableDetails: function () {
        tableDetails = [];
      },
    };
  },
]);
