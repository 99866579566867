import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseWidget } from '../base.widget';
import * as fromRoot from '../../store';
import { ToggleWidget } from '../../store/actions';

@Component({
  selector: 'unique-workers-component',
  templateUrl: './unique.workers.component.html',
})
export class UniqueWorkersComponent implements OnInit {
  public isLoading: boolean;
  public count: number;
  public widgetData: any;
  private subscriptions = [];
  private dragMode: boolean;
  constructor(private store: Store<fromRoot.State>) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(fromRoot.getWorkerInfo).subscribe((info) => {
        this.count = info.total_count;
      })
    );

    this.subscriptions.push(
      this.store.select(fromRoot.getDragMode).subscribe((dragMode) => (this.dragMode = dragMode))
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  closeWidget() {
    this.store.dispatch(new ToggleWidget(this.widgetData, true));
  }
}
