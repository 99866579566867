import module from './module';
import * as enums from '../enums';
import moment from 'moment';

export default module.directive('dirNotificationWidget', function () {
  return {
    restrict: 'E',
    controller: [
      '$scope',
      '$location',
      'AuthenticationService',
      'sessionManagerService',
      'AlertService',
      'BrowserNotificationService',
      'commonSelectors',
      function (
        $scope,
        $location,
        AuthenticationService,
        sessionManagerService,
        AlertService,
        BrowserNotificationService,
        commonSelectors
      ) {
        var CURRENT_USER = AuthenticationService.getCurrentUser();
        let websocketMessagesSubscription;

        $scope.closeIcon = `${enums.AssetsFolder.svg}close.svg`;

        $scope.buildNotification = function (rawValue) {
          var result = {
            message: rawValue.message,
            sessionId: rawValue.request_id,
          };
          if (rawValue.submit_time) {
            result.time = moment(rawValue.submit_time, 'hh:mm:ss').format('h:mmA');
          }
          if (rawValue.submit_date) {
            result.date = moment(rawValue.submit_date).format('Do MMM YYYY');
          }
          if (rawValue.status && rawValue.status.name) {
            result.status = rawValue.status.name.toLowerCase();
          }
          return result;
        };

        if (websocketMessagesSubscription) {
          websocketMessagesSubscription.unsubscribe();
        }
        websocketMessagesSubscription = commonSelectors
          .getWebsocketMessagesForEnterprise($scope.entFilter.enterprise_id)
          .subscribe((messages) => {
            $scope.notifications = messages;
          });

        if (CURRENT_USER.user_type == 2 || CURRENT_USER.user_type == 6) {
          $scope.$watch('entFilter', function (newVal, oldVal) {
            if (newVal.enterprise_id > -1) {
              if (websocketMessagesSubscription) {
                websocketMessagesSubscription.unsubscribe();
              }
              websocketMessagesSubscription = commonSelectors
                .getWebsocketMessagesForEnterprise($scope.entFilter.enterprise_id)
                .subscribe((messages) => {
                  $scope.notifications = messages;
                  if ($scope.notifications) {
                    $scope.notifications = $scope.notifications.map(function (item) {
                      return $scope.buildNotification(item);
                    });
                  }
                });
            }
          });
          if (websocketMessagesSubscription) {
            websocketMessagesSubscription.unsubscribe();
          }
          websocketMessagesSubscription = commonSelectors
            .getWebsocketMessagesForEnterprise($scope.entFilter.enterprise_id)
            .subscribe((messages) => {
              $scope.notifications = messages;
              if ($scope.notifications) {
                $scope.notifications = $scope.notifications.map(function (item) {
                  return $scope.buildNotification(item);
                });
              }
            });
        }

        $scope.$on('NOTIFICATION_UPDATE', function (event, args) {
          var newMessage = $scope.buildNotification(args);
          if (!$scope.notifications) {
            $scope.notifications = [];
          }
          $scope.notifications.push(newMessage);
          BrowserNotificationService.createNotification(newMessage?.message);
        });

        $scope.displayDateTime = function (date, time) {
          if (date && time) {
            return time + ', ' + date;
          } else if (date) {
            return date;
          } else if (time) {
            return time;
          } else {
            return '';
          }
        };

        $scope.viewRequest = function (sessionId) {
          if (sessionId) {
            sessionManagerService.setSession(sessionId).then(
              function (response) {
                if (response.success) {
                  $scope.closeNotifications();
                  location.href = '/#/job';
                } else {
                  AlertService.errorAlert(response);
                }
              },
              function (reason) {
                AlertService.serverRequestErrorAlert(reason);
              }
            );
          }
        };

        $scope.closeNotifications = function () {
          $scope.toggleNotification();
        };

        $scope.$on('$destroy', function () {
          if (websocketMessagesSubscription) {
            websocketMessagesSubscription.unsubscribe();
          }
        });
      },
    ],
    templateUrl: 'app/views/templates/notificationWidget.html',
  };
});
