export const FilterableTypes = {
  text: 'text',
  single_list: 'single_list',
  multi_list: 'multi_list',
  flag: 'flag',
  count: 'count',
  decimal: 'decimal',
  location: 'location',
  place: 'place',
  date_time: 'date_time',
  date: 'date',
  password: 'password',
  image: 'image',
  date_range: 'date_range',
};

export const ApiLogsState = {
  Success: 1,
  Failed: 2,
  Ignored: 3,
  Filtered: 4,
};

export const RequestStatus = {
  Active: 1,
  Committed: 2,
  Pending: 3,
  Open: 4,
  Completed: 5,
  Denied: 6,
  Canceled: 7,
  Interest: 8,
  WaitingDecision: 9,
};

export const RequestStatusString = {
  Active: 'Active',
  Committed: 'Committed',
  Pending: 'Pending',
  Open: 'Open',
  Completed: 'Completed',
  Denied: 'Denied',
  Canceled: 'Canceled',
  Interest: 'Interest',
  WaitingDecision: 'Waiting Decision',
};

export const RequestType = {
  AllTypes: 0,
  Scheduled: 1,
  NowOrOnDemand: 2,
};

export const SystemFiltersType = {
  Worker: 1,
  Requester: 2,
  RequestStatus: 3,
  RequestType: 4,
};

export const UserType = {
  SystemAdmin: 1,
  MarketPlaceAdmin: 2,
  EnterpriseAdmin: 3,
  ServiceProvider: 4,
  Requester: 5,
  BranchAdmin: 6,
  MasterUser: 7,
  MultiBranchAdmin: 99,
};

export const SelectedTimeReportFilterType = {
  JobOrder: 0,
  Extract: 1,
};

export const AssetsFolder = {
  images: 'assets/images/',
  svg: 'assets/svg/',
};

export const NotificationPermission = {
  default: 'default',
  granted: 'granted',
  denied: 'denied',
};

export const CancelStatuses = {
  0: 'Not Canceled',
  1: 'Canceled By Admin',
  2: 'Request Timed Out',
  3: 'All Available Providers Declined Request',
  4: 'Canceled By Requesters',
  5: 'Canceled By Worker',
  6: 'Cancelled By System',
  7: 'No Interested Workers',
  8: 'No Decision By Employer',
  9: 'Interest Cancelled By Admin',
  10: 'Interest Cancelled By Requester',
  11: 'Cancelled due to assignment change',
  12: 'No show',
};

export const Realms = {
  Worker: 1,
  Requester: 2,
  Service: 3,
  AcceptService: 4,
  DeclineService: 5,
  RejectService: 6,
  RequesterReview: 7,
  WorkerReview: 8,
  Price: 9,
  Escalation: 10,
  ApproveRequest: 11,
  RequesterCancel: 12,
  WorkerCancel: 13,
  AdminCancel: 14,
  JobFilter: 15,
  JobCreation: 16,
};
