import module from '../../module';
import * as enums from '../../../enums';
import Swal from 'sweetalert2';

export default module.directive('dirEditEmail', function () {
  return {
    restrict: 'E',
    scope: {
      selectTemplate: '&',
      id: '=',
    },
    controller: [
      '$scope',
      'communicationsService',
      '$modal',
      'AlertService',
      async function ($scope, communicationsService, $modal, AlertService) {
        $scope.cancel = async function () {
          if ($scope.emailForm.$dirty) {
            const config = {
              title: 'Discard changes?',
              text: 'You have made changes to the template, do you want to discard them?',
              confirmButtonText: 'Yes, discard the changes',
              cancelButtonText: 'No, go back',
              icon: 'warning',
              showConfirmButton: true,
              showCancelButton: true,
            };
            const { isConfirmed } = await Swal.fire(config);
            if (isConfirmed) {
              $scope.selectTemplate()(null, null);
            }
          } else {
            $scope.selectTemplate()(null, null);
          }
        };

        $scope.revertToDefault = async function () {
          const config = {
            title: 'Revert to the default template?',
            text: 'Are you sure you want to revert to the default email template?',
            confirmButtonText: 'Yes, revert to default',
            cancelButtonText: 'No, go back',
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
          };
          const { isConfirmed } = await Swal.fire(config);
          if (isConfirmed) {
            const response = await communicationsService.revertEmailTemplate($scope.template);
            if (response) {
              AlertService.successAlert('Email template reverted to the default');
              $scope.selectTemplate()(null, null);
            } else {
              AlertService.errorAlert('There was an error reverting the email template');
            }
          }
        };

        $scope.revertToCustom = async function () {
          const config = {
            title: 'Revert to the previous custom template?',
            text: 'Are you sure you want to revert to the previous custom email template?',
            confirmButtonText: 'Yes, revert to previous custom',
            cancelButtonText: 'No, go back',
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
          };
          const { isConfirmed } = await Swal.fire(config);
          if (isConfirmed) {
            const response = await communicationsService.revertEmailTemplateToCustom($scope.template);
            if (response) {
              AlertService.successAlert('Email template reverted to the previous custom one.');
              $scope.selectTemplate()(null, null);
            } else {
              AlertService.errorAlert('There was an error reverting the email template');
            }
          }
        };

        $scope.save = async function () {
          const modalInstance = $modal.open({
            templateUrl: 'app/views/admin/communications/previewEmail.html',
            size: 'xlg',
            backdrop: 'static',
            controller: 'PreviewEmailController',
            resolve: {
              passedData() {
                return {
                  template: $scope.template,
                  image: $scope.currentImage,
                  mode: 'save',
                };
              },
            },
          });
          const result = await modalInstance.result;
          if (result) {
            const response = await communicationsService.saveEmailTemplate($scope.template);
            if (response) {
              AlertService.successAlert('Email template updated successfully');
              if ($scope.rawImage) {
                await communicationsService.uploadEmailFile($scope.id, $scope.rawImage);
              }
              $scope.selectTemplate()(null, null);
            } else {
              AlertService.errorAlert('There was an error updating the email template');
            }
          }
        };

        $scope.preview = function () {
          $modal.open({
            templateUrl: 'app/views/admin/communications/previewEmail.html',
            size: 'xlg',
            backdrop: 'static',
            controller: 'PreviewEmailController',
            resolve: {
              passedData() {
                return {
                  template: $scope.template,
                  image: $scope.currentImage,
                };
              },
            },
          });
        };

        $scope.template = await communicationsService.getEmailTemplate($scope.id);
        $scope.icons = {
          upload: `${enums.AssetsFolder.svg}emails/upload.svg`,
        };

        $scope.deleteImage = function () {
          $scope.currentImage = null;
          $('center img').attr('src', $scope.originalImage);
        };

        $scope.openFileUpload = function () {
          document.getElementById('fileUpload').click();
        };

        $scope.fileUploaded = async function (e) {
          if (e.files && e.files[0]) {
            $scope.rawImage = e.files[0];
            const reader = new FileReader();
            reader.addEventListener(
              'load',
              () => {
                $scope.currentImage = reader.result;

                $scope.originalImage = $('center img').attr('src');
                $('center img').attr('src', reader.result);
              },
              false
            );
            reader.readAsDataURL(e.files[0]);
          }
        };
      },
    ],
    templateUrl: 'app/views/admin/communications/editEmail.html',
  };
});
