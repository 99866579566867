import module from '../module';
import moment from 'moment';

export default module.service('timeReportCalculationService', [
  'timeReportCsvService',
  'timeReportDataService',
  'TimeFormatService',
  function (timeReportCsvService, timeReportDataService, TimeFormatService) {
    const updateTotalTimeModel = (session) => {
      session.request_total_time_model = timeReportCsvService.getDurationDisplayVal(session.request_total_time);
    };

    const setSessionTime = (session) => {
      session.checkin_time_obj = moment(`${session.checkin_date} ${session.checkin_time}`, 'YYYY-MM-DD LT');
      session.checkout_time_obj = moment(`${session.checkout_date} ${session.checkout_time}`, 'YYYY-MM-DD LT');
    };

    const updateTotalTimes = (session) => {
      const break_total_time = session.break_total_time === -1 ? 0 : session.break_total_time;
      session.request_total_time -= break_total_time;
      session.total_time_hours = timeReportDataService.getHoursFromTime(session.request_total_time);
      session.total_time_minutes = timeReportDataService.getMinutesFromTime(session.request_total_time);
    };

    const changeCheckin = (session) => {
      const _newDate = moment(session.checkinDateTime).format(TimeFormatService.format('fullDate'));
      const _newTime = moment(session.checkinDateTime).format('LT');
      session.checkin_date = _newDate;
      session.checkin_time = _newTime;
      session.request_total_time = moment(session.checkoutDateTime).diff(moment(session.checkinDateTime), 'minutes');

      _updateSessionTimes(session);
    };

    const changeCheckout = (session) => {
      const _newDate = moment(session.checkoutDateTime).format(TimeFormatService.format('fullDate'));
      const _newTime = moment(session.checkoutDateTime).format('LT');
      session.checkout_date = _newDate;
      session.checkout_time = _newTime;
      session.request_total_time = moment(session.checkoutDateTime).diff(moment(session.checkinDateTime), 'minutes');

      _updateSessionTimes(session);
    };

    const _updateSessionTimes = (session) => {
      updateTotalTimes(session);
      updateTotalTimeModel(session);
      setSessionTime(session);
    };

    const changeTimeWorked = (session) => {
      const request_total_time = timeReportDataService.getMinutesFromDuration(session.request_total_time_model);
      if (request_total_time !== 'invalid') {
        session.request_total_time = request_total_time;
      }
    };

    const closeSessionTime = (type, session) => {
      if (type === 'checkin') {
        const checkin_time = moment(session.checkin_time_obj).format('LT');
        if (checkin_time === 'Invalid date') {
          setSessionTime(session);
          return;
        }
        session.checkin_time = checkin_time;
        session.checkinDateTime = moment(`${session.checkin_date} ${checkin_time}`, 'YYYY-MM-DD LT').format(
          TimeFormatService.format('api')
        );
      } else {
        const checkout_time = moment(session.checkout_time_obj).format('LT');
        if (checkout_time === 'Invalid date') {
          setSessionTime(session);
          return;
        }
        session.checkout_time = checkout_time;
        session.checkoutDateTime = moment(`${session.checkout_date} ${checkout_time}`, 'YYYY-MM-DD LT').format(
          TimeFormatService.format('api')
        );
      }

      const newTotalTime = moment(session.checkoutDateTime).diff(moment(session.checkinDateTime), 'minutes');
      if (newTotalTime < 0) {
        if (type === 'checkin') {
          session.checkoutDateTime = moment(session.checkinDateTime)
            .clone()
            .add(session.request_total_time, 'm')
            .format(TimeFormatService.format('api'));
        } else if (type === 'checkout') {
          session.checkinDateTime = moment(session.checkoutDateTime)
            .clone()
            .subtract(session.request_total_time, 'm')
            .format(TimeFormatService.format('api'));
        }
      } else {
        session.request_total_time = newTotalTime;
        updateTotalTimes(session);
        updateTotalTimeModel(session);
      }
    };

    const closeTotalTime = (session, total_time_hours, total_time_minutes) => {
      session.request_total_time = total_time_hours * 60 + total_time_minutes;
      if (session.request_total_time) {
        session.request_total_time_model = timeReportCsvService.getDurationDisplayVal(session.request_total_time);
      } else {
        session.request_total_time_model = '0 min';
      }

      session.total_time_hours = timeReportDataService.getHoursFromTime(session.request_total_time);
      session.total_time_minutes = timeReportDataService.getMinutesFromTime(session.request_total_time);

      const checkin_time = moment(session.checkin_time_obj).format('LT');
      if (checkin_time === 'Invalid date') {
        return;
      }
      const checkin_datetime = moment(`${session.checkin_date} ${checkin_time}`, 'YYYY-MM-DD LT');

      checkin_datetime.add(session.request_total_time, 'm');
      if (session.break_total_time > 0) {
        checkin_datetime.add(session.break_total_time, 'm');
      }

      session.checkout_time = checkin_datetime.format('LT');
      session.checkoutDateTime = moment(checkin_datetime, 'YYYY-MM-DD LT').format(TimeFormatService.format('api'));
      setSessionTime(session);
    };

    const closeBreakTime = (session, break_total_time, breakDiff) => {
      if (!session.request_total_time && session.request_total_time !== 0) {
        session.request_total_time = moment(session.checkoutDateTime).diff(moment(session.checkinDateTime), 'minutes');
      }
      session.break_total_time = break_total_time - breakDiff;

      if (session.break_total_time) {
        session.break_total_time_model = timeReportCsvService.getDurationDisplayVal(session.break_total_time);
      } else {
        session.break_total_time_model = '';
      }

      // update time worked based on break time
      session.request_total_time += breakDiff;
      if (session.request_total_time > 0) {
        session.request_total_time_model = timeReportCsvService.getDurationDisplayVal(session.request_total_time);
      } else {
        session.request_total_time_model = '0 min';
      }

      session.total_time_hours = timeReportDataService.getHoursFromTime(session.request_total_time);
      session.total_time_minutes = timeReportDataService.getMinutesFromTime(session.request_total_time);
    };

    return {
      updateTotalTimeModel,
      setSessionTime,
      updateTotalTimes,
      changeCheckin,
      changeCheckout,
      changeTimeWorked,
      closeSessionTime,
      closeTotalTime,
      closeBreakTime,
    };
  },
]);
