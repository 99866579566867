import module from './module';
import moment from 'moment';
import angular from 'angular';
import * as _ from 'lodash';
import * as operators from 'rxjs/operators';

export default module.factory('sessionManagerService', [
  '$http',
  'BASE_URL',
  '$q',
  'Session',
  'Provider',
  'FormatHelperService',
  'commonSelectors',
  function ($http, BASE_URL, $q, Session, Provider, FormatHelperService, commonSelectors) {
    const sessionsManager = {
      _pool: {},
      _verifyPool: {},
      _sessionsArray: {},
      _selectedSession: '',
      _retrieveInstance: function (sessionId, sessionData, ent_id) {
        let instance = this._pool[sessionId];
        if (instance) {
          instance.setData(sessionData);
        } else {
          instance = new Session(sessionData, ent_id);
          this._pool[sessionId] = instance;
          if (!this._sessionsArray[ent_id]) {
            this._sessionsArray[ent_id] = [];
            this._sessionsArray[ent_id].push(instance);
          } else {
            this._sessionsArray[ent_id].push(instance);
          }
        }
        return instance;
      },
      _retrieveVerifyInstance: function (sessionId, sessionData) {
        let instance = this._verifyPool[sessionId];
        if (instance) {
          instance.setData(sessionData);
        } else {
          instance = new Session(sessionData);
          this._verifyPool[sessionId] = instance;
        }
        instance.serverVerified = instance.verified;
        return instance;
      },
      _search: function (sessionId) {
        return this._pool[sessionId];
      },
      _getAttribs: function (deferred, realm_id) {
        commonSelectors
          .getAttribsForRealm(realm_id)
          .pipe(operators.take(1))
          .subscribe((res) => {
            if (res.success) {
              deferred.resolve(angular.copy(res));
            } else {
              deferred.reject(res.message);
            }
          });
      },
      _submitRequest: function (deferred, requestObj) {
        $http
          .post(BASE_URL + '/send_svc_request', requestObj)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      // Approve or Decline Servie Request
      _submitOpenRequestResponse: function (deferred, requestObj) {
        $http
          .post(BASE_URL + '/approve_svc_request', requestObj)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _load: function (sessionId, deferred, ent_id) {
        const scope = this;
        $http
          .post(BASE_URL + '/get_request_detail', { request_id: sessionId })
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              const attribs = response.attrib_list;
              if (attribs) {
                attribs.sort((a, b) => a.order - b.order);
                response.attrib_list = _.uniqBy(attribs, 'attrib_id');
              }
              scope._selectedSession = scope._retrieveInstance(response.request_id, response, ent_id);
              deferred.resolve(scope._selectedSession);
            } else {
              deferred.reject(response.message);
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _fetchSession: function (sessionId, deferred) {
        $http
          .post(BASE_URL + '/get_request_detail', { request_id: sessionId })
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              const attribs = response.attrib_list;
              if (attribs) {
                attribs.sort((a, b) => a.order - b.order);
                response.attrib_list = _.uniqBy(attribs, 'attrib_id');
              }
              deferred.resolve(response);
            } else {
              deferred.reject(response.message);
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _loadAllSessions: function (ent_id, start_date, job_id, job_instance, deferred) {
        const scope = this;
        $http
          .post(BASE_URL + '/get_my_requests', { enterprise_id: ent_id, start_date, job_id, job_instance })
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              response.request_list.forEach(function (sessionData) {
                if (scope._selectedSession.request_id !== sessionData.request_id) {
                  scope._retrieveInstance(sessionData.request_id, sessionData, ent_id);
                }
              });
              deferred.resolve(scope._sessionsArray[ent_id]);
            } else {
              deferred.reject(response.message);
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _getAllIncompleteRequests: function (ent_id, deferred) {
        const scope = this;
        const data = ent_id ? { enterprise_id: ent_id } : null;
        $http
          .post(BASE_URL + '/get_incomplete_requests', data)
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              response.request_list.forEach(function (sessionData) {
                if (scope._selectedSession.request_id !== sessionData.request_id) {
                  scope._retrieveInstance(sessionData.request_id, sessionData, ent_id);
                }
              });
              deferred.resolve(scope._sessionsArray[ent_id]);
            } else {
              deferred.reject(response.message);
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _getSingleJobOrderById: function (ext_id, deferred) {
        $http
          .post(BASE_URL + '/api_get_single_job_order', { ext_order_id: ext_id })
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              deferred.resolve(response);
            } else {
              deferred.reject(response.message);
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },
      // /////////////////Change Verify Actions////////////////////////////////////////
      _loadAllVerifySessions: function (startDate, endDate, deferred, ent_id) {
        const scope = this;
        const verifyList = [];
        let serverObj = { enterprise_id: ent_id };
        if (startDate && endDate) {
          serverObj = {
            start_date: startDate,
            end_date: endDate,
            enterprise_id: ent_id,
          };
        }
        $http
          .post(BASE_URL + '/verify_report', serverObj)
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              response.request_list.forEach(function (sessionData) {
                const session = scope._retrieveVerifyInstance(sessionData.request_id, sessionData);
                verifyList.push(session);
              });
              response.request_list = verifyList;
            }
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _loadVerifySession: function (sessionId, deferred) {
        const scope = this;
        $http
          .post(BASE_URL + '/get_verify_details', { request_id: sessionId })
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              const session = scope._retrieveVerifyInstance(response.request_id, response);
              deferred.resolve(session);
            } else {
              deferred.reject(response.message);
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _verifyServiceRequests: function (verifyList, deferred) {
        $http
          .post(BASE_URL + '/order_timesheet_verify_shifts', { request_list: verifyList })
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      // /////////////////Change Verify Actions////////////////////////////////////////
      // ////////////////Assignment Actions////////////////////////////////////////////
      _assignJobSessionProvider: function (assignmentObj, deferred) {
        $http
          .post(BASE_URL + '/assign_session', assignmentObj)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _escalateJobSession: function (escalateObj, deferred) {
        $http
          .post(BASE_URL + '/escalate_session', escalateObj)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _updateSessionTime: function (requestObj, deferred) {
        $http
          .post(BASE_URL + '/change_session_timing', requestObj)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _checkinProvider: function (requestObj, deferred) {
        $http
          .post(BASE_URL + '/submit_checkin', requestObj)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      // Complete or Cancel Servie Request
      _completeServiceRequest: function (requestObj, deferred) {
        $http
          .post(BASE_URL + '/end_svc_request', requestObj)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _requeueRequest: function (requestId, deferred) {
        $http
          .post(BASE_URL + '/requeue_svc_request', { request_id: requestId })
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _changeAndRequeueRequest: function (requestObject, deferred) {
        $http
          .post(BASE_URL + '/requeue_svc_request', requestObject)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _prelimRequest: function (requestObject, deferred) {
        $http
          .post(BASE_URL + '/prelim_svc_request', requestObject)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(
              response.provider_list.map(function (item) {
                return new Provider(item, 'TABLE_DISPLAY');
              })
            );
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _prelimJob: function (requestObject, deferred) {
        $http
          .post(BASE_URL + '/prelim_job_qualified_workers', requestObject)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(
              response.provider_list.map(function (item) {
                return new Provider(item, 'TABLE_DISPLAY');
              })
            );
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _prelimAdhocJob: function (requestObject, deferred) {
        $http
          .post(BASE_URL + '/prelim_adhoc_qualified_workers', requestObject)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(
              response.provider_list.map(function (item) {
                return new Provider(item, 'TABLE_DISPLAY');
              })
            );
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _endJobRequest: function (requestObj, deferred) {
        $http
          .post(BASE_URL + '/cancel_scheduled_job', requestObj)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _getTrackingInfo: function (requestId, deferred) {
        $http
          .post(BASE_URL + '/get_session_tracking', {
            request_id: requestId,
          })
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _assignJobOrder: function (assignObj, deferred) {
        $http
          .post(BASE_URL + '/assign_job_order', {
            job_order_id: assignObj.jobOrderId,
            user_id: assignObj.userId,
            old_user_id: assignObj.oldUserId,
          })
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _changeJobOrder: function (payload, deferred) {
        $http
          .post(BASE_URL + '/edit_job_order', payload)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _changeJobOrder2: function (payload, deferred) {
        $http
          .post(BASE_URL + '/change_job_order', payload)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _changeJobAssignment: function (payload, deferred) {
        $http
          .post(BASE_URL + '/change_job_assignment', payload)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _changeSessionAssignment: function (payload, deferred) {
        $http
          .post(BASE_URL + '/change_session_assignment', payload)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _getSupervisorSignOffDetails: function (payload, deferred) {
        $http
          .post(BASE_URL + '/get_supervisor_signoff_image', payload)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _resendJobOrder: function (payload, deferred) {
        $http
          .post(BASE_URL + '/requeue_job_order', payload)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      // ////////////////Assignment Actions////////////////////////////////////////////
      // ////////////////////* Public Methods *////////////////////////////////////////
      getRealmAttribs: function (realm_id) {
        const deferred = $q.defer();
        this._getAttribs(deferred, realm_id);
        return deferred.promise;
      },
      submitSessionRequest: function (requestObj) {
        const deferred = $q.defer();
        this._submitRequest(deferred, requestObj);
        return deferred.promise;
      },
      submitOpenResponse: function (requestObj) {
        const deferred = $q.defer();
        this._submitOpenRequestResponse(deferred, requestObj);
        return deferred.promise;
      },
      getAllSessions: function (ent_id, start_date, job_id, job_instance) {
        const deferred = $q.defer();
        const scope = this;
        const sessions = scope._sessionsArray;
        if (ent_id == -1) {
          deferred.resolve(sessions);
        } else {
          scope._loadAllSessions(ent_id, start_date, job_id, job_instance, deferred);
        }
        return deferred.promise;
      },
      getAllIncompleteRequests: function (ent_id) {
        const deferred = $q.defer();
        const scope = this;
        if (ent_id == -1) {
          deferred.resolve({});
        } else {
          scope._getAllIncompleteRequests(ent_id, deferred);
        }
        return deferred.promise;
      },
      setSession: function (sessionId, entId) {
        const deferred = $q.defer();
        this._load(sessionId, deferred, entId);
        return deferred.promise;
      },
      getSession: function () {
        const deferred = $q.defer();
        const session = this._selectedSession;
        deferred.resolve(session);
        return deferred.promise;
      },
      addNewSession: function (ent_id, sessionData) {
        this._load(sessionData.request_id, $q.defer(), ent_id);
      },
      updateSession: function (sessionData) {
        const deferred = $q.defer();
        this._load(sessionData.request_id, deferred);
        return deferred.promise;
      },
      getSingleJobOrderById: function (ext_id) {
        const deferred = $q.defer();
        this._getSingleJobOrderById(ext_id, deferred);
        return deferred.promise;
      },
      // ///////////////////* Public Methods */////////////////////////////////////////
      // ///////////////////* Session Actions *////////////////////////////////////////
      assignSessionProvider: function (assignmentObj) {
        const deferred = $q.defer();
        this._assignJobSessionProvider(assignmentObj, deferred);
        return deferred.promise;
      },
      escalateSession: function (escalateObj) {
        const deferred = $q.defer();
        this._escalateJobSession(escalateObj, deferred);
        return deferred.promise;
      },
      changeSessionTime: function (requestObj) {
        const deferred = $q.defer();
        this._updateSessionTime(requestObj, deferred);
        return deferred.promise;
      },
      checkinSessionProvider: function (requestObj) {
        const deferred = $q.defer();
        this._checkinProvider(requestObj, deferred);
        return deferred.promise;
      },
      endSessionRequest: function (requestObj) {
        const deferred = $q.defer();
        this._completeServiceRequest(requestObj, deferred);
        return deferred.promise;
      },
      endJobRequest: function (requestObj) {
        const deferred = $q.defer();
        this._endJobRequest(requestObj, deferred);
        return deferred.promise;
      },
      // //////////////////* Session Actions */////////////////////////////////////////
      // //////////////////* Change Verify Calls */////////////////////////////////////
      getAllVerifySessions: function (startDate, endDate, ent_id) {
        const deferred = $q.defer();
        this._loadAllVerifySessions(startDate, endDate, deferred, ent_id);
        return deferred.promise;
      },
      getVerifySession: function (request_id) {
        const deferred = $q.defer();
        this._loadVerifySession(request_id, deferred);
        return deferred.promise;
      },
      verifyRequests: function (requestList) {
        const deferred = $q.defer();
        this._verifyServiceRequests(requestList, deferred);
        return deferred.promise;
      },
      requeueRequest: function (requestId) {
        const deferred = $q.defer();
        this._requeueRequest(requestId, deferred);
        return deferred.promise;
      },
      changeAndRequeueRequest: function (requestObject) {
        const deferred = $q.defer();
        this._changeAndRequeueRequest(requestObject, deferred);
        return deferred.promise;
      },
      prelimRequest: function (requestObject) {
        const deferred = $q.defer();
        this._prelimRequest(requestObject, deferred);
        return deferred.promise;
      },
      prelimJob: function (requestObject) {
        const deferred = $q.defer();
        this._prelimJob(requestObject, deferred);
        return deferred.promise;
      },
      prelimAdhocJob: function (requestObject) {
        const deferred = $q.defer();
        this._prelimAdhocJob(requestObject, deferred);
        return deferred.promise;
      },
      fetchSession: function (sessionId) {
        const deferred = $q.defer();
        this._fetchSession(sessionId, deferred);
        return deferred.promise;
      },
      getTrackingInfo: function (sessionId) {
        const deferred = $q.defer();
        this._getTrackingInfo(sessionId, deferred);
        return deferred.promise;
      },
      assignJobOrder: function (assignObj) {
        const deferred = $q.defer();
        this._assignJobOrder(assignObj, deferred);
        return deferred.promise;
      },
      changeJobOrder: function (payload) {
        const deferred = $q.defer();
        this._changeJobOrder(payload, deferred);
        return deferred.promise;
      },
      resendJobOrder: function (payload) {
        const deferred = $q.defer();
        this._resendJobOrder(payload, deferred);
        return deferred.promise;
      },
      changeJobOrder2: function (payload) {
        const deferred = $q.defer();
        this._changeJobOrder2(payload, deferred);
        return deferred.promise;
      },
      changeJobAssignment: function (payload) {
        const deferred = $q.defer();
        this._changeJobAssignment(payload, deferred);
        return deferred.promise;
      },
      changeSessionAssignment: function (payload) {
        const deferred = $q.defer();
        this._changeSessionAssignment(payload, deferred);
        return deferred.promise;
      },
      getSupervisorSignOffDetails: function (payload) {
        const deferred = $q.defer();
        this._getSupervisorSignOffDetails(payload, deferred);
        return deferred.promise;
      },
      updateRequestAttribs: function (requestId, attribs) {
        const deferred = $q.defer();
        $http
          .post(BASE_URL + '/update_pending_committed_cancelled_svc_req_attribs', {
            request_id: requestId,
            attribs,
          })
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
        return deferred.promise;
      },
      getMyRequests: function (ent_id, start_date, job_id, job_instance) {
        const deferred = $q.defer();
        $http
          .post(BASE_URL + '/get_my_requests', { enterprise_id: ent_id, start_date, job_id, job_instance })
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              const result = response.request_list.map(function (sessionData) {
                return new Session(sessionData, ent_id);
              });
              deferred.resolve(result);
            } else {
              deferred.reject(response.message);
            }
          })
          .catch(function () {
            deferred.reject();
          });
        return deferred.promise;
      },
      endAssignment: function (job_id, job_instance, provider_id, cancel_date) {
        const deferred = $q.defer();
        $http
          .post(BASE_URL + '/cancel_job_assignment ', { job_id, job_instance, provider_id, cancel_date })
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              deferred.resolve(response);
            } else {
              deferred.reject(response.message);
            }
          })
          .catch(function () {
            deferred.reject();
          });
        return deferred.promise;
      },
      // ////////////////////* Change Verify Calls *///////////////////////////////////
      // ////////////////////* Formating Methods */////////////////////////////////////
      filterSessionByLocation: function (ent_id, location_id) {
        if (this._sessionsArray[ent_id]) {
          const sessions = this._sessionsArray[ent_id].filter(function (obj) {
            return obj.location_id == location_id;
          });
          return sessions;
        } else {
          return false;
        }
      },
      // TODO make method return non associated location sessions and the build something
      filterSessionByUnknownLocations: function (ent_id, location_name) {
        const sessions = this._sessionsArray[ent_id].filter(function (obj) {
          if (obj.location) {
            return obj.location.value_location.name == location_name;
          }
          return false;
        });
        return sessions;
      },

      formatSessionReviewData: function (reviewDetails) {
        const attribs = [];
        const _reviewDetails = reviewDetails;
        angular.forEach(reviewDetails.attrib_list, function (obj) {
          switch (obj.type) {
            case 'count':
              if (obj.name === 'requester_issue_late_arrival' && _reviewDetails.late == true) {
                const attrib = {
                  attrib_id: obj.attrib_id,
                  value_count: _reviewDetails.minutesLate,
                };
                attribs.push(attrib);
              }
              if (obj.name === 'service_review' && _reviewDetails.rate > 0) {
                const attrib = {
                  attrib_id: obj.attrib_id,
                  value_count: _reviewDetails.rate,
                };
                attribs.push(attrib);
              }
              break;
            case 'text':
              if (obj.name === 'requester_comments' && _reviewDetails.customerComments.length > 0) {
                const attrib = {
                  attrib_id: obj.attrib_id,
                  value_text: _reviewDetails.customerComments,
                };
                attribs.push(attrib);
              }
              break;
            case 'multi_list':
              var selected = obj.choice_list.filter(function (obj) {
                return obj.selected == true;
              });
              if (selected.length > 0) {
                selected = selected.map(function (obj) {
                  return { choice_id: obj.choice_id };
                });
                const attrib = {
                  attrib_id: obj.attrib_id,
                  value_multi_list: selected,
                };
                attribs.push(attrib);
              }
              break;
          } // end of switch
        }); // end of forEach
        const extraAttribs = FormatHelperService.format_data(reviewDetails.extraAttribs);

        return [...attribs, ...extraAttribs];
      }, // end of formatData method
      returnDurationDisplayValue: function (value) {
        let days;
        let hours;
        let minutes;
        const _value = value;
        if (_value && _value > 0) {
          if (_value >= 1400) {
            days = moment.duration(_value, 'minutes').days();
            hours = moment.duration(_value, 'minutes').hours();
            minutes = moment.duration(_value, 'minutes').minutes();
            return days + ' days : ' + hours + ' hrs : ' + minutes + ' mins';
          } else if (_value < 1400 && _value >= 120) {
            hours = moment.duration(_value, 'minutes').hours();
            minutes = moment.duration(_value, 'minutes').minutes();
            return hours + ' hrs : ' + minutes + ' mins';
          } else if (_value < 120 && _value >= 60) {
            hours = 1;
            minutes = moment.duration(_value, 'minutes').minutes();
            return '1 hr : ' + minutes + ' mins';
          } else {
            minutes = moment.duration(_value, 'minutes').minutes();
            return minutes + ' mins';
          }
        } else {
          return 'N/A';
        }
      },
    };
    return sessionsManager;
  },
]);
