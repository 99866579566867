import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseWidget } from './base.widget';
import * as fromRoot from '../store';
import { FilterRequestList } from '../store/actions';

@Component({
  selector: 'top-key-attribute-component',
  templateUrl: './common.widget.template.html'
})
export class TopKeyAttributeComponent extends BaseWidget {
  public keyAttributeName: string;
  public customTitle;
  public titleValue: string;
  public titleText = 'Requested';
  constructor(store: Store<fromRoot.State>) {
    super(store);
  }

  loadDataTemplate(list) {
    if (!list) {
      return;
    }
    this.store.select(fromRoot.getKeyAttribute).subscribe(keyAttribute => {
      if (keyAttribute && keyAttribute.name === 'Key Attribute') {
        this.customTitle = `Top ${keyAttribute.name}`;
      } else if (keyAttribute) {
        this.keyAttributeName = keyAttribute.name;
        this.customTitle = `Top ${this.keyAttributeName}`;
        let attribMap = {};

        this.titleValue = list.length;
        let items = list.map(item => {
          let theAttrib = item.attrib_list && item.attrib_list.find(attrib => attrib.name === keyAttribute.value);
          if (!theAttrib) {
            return 'No title';
          }
          if (theAttrib.type === 'single_list' || theAttrib.type === 'multi_list') {
            return theAttrib && theAttrib.value_list && theAttrib.value_list.length && theAttrib.value_list[0].name;
          }
          else if (theAttrib.type === 'text') {
            return theAttrib && theAttrib.value_text;
          }
        });

        items.forEach(element => {
          if (attribMap[element]) {
            attribMap[element]++;
          } else {
            attribMap[element] = 1;
          }
        });

        this.data = Object.keys(attribMap).map(key => {
          let value = attribMap[key];
          return { name: key, value: value };
        });
        this.data.sort((a, b) => {
          return b.value - a.value;
        });

        if (!this.data.some(x => x.value)) {
          this.data = [];
        }
      }
    });
  }

  filterAll() {
    let action = new FilterRequestList({
      showAll: true
    });
    this.store.dispatch(action);
  }

  filterData(data) {
    let action = new FilterRequestList({
      keyAttribute: data.name
    });
    this.store.dispatch(action);
  }
}
