import module from '../module';
import moment from 'moment';
export default module.controller('ReportsDashboardController', [
  '$scope',
  '$sce',
  'AuthenticationService',
  'ReportingSectionService',
  'FormatHelperService',
  'EnterpriseFilterService',
  'PENTAHO_URL',
  'TimeFormatService',
  function (
    $scope,
    $sce,
    AuthenticationService,
    ReportingSectionService,
    FormatHelperService,
    EnterpriseFilterService,
    PENTAHO_URL,
    TimeFormatService
  ) {
    const CURRENT_USER = AuthenticationService.getCurrentUser();
    const URL =
      PENTAHO_URL +
      '/pentaho/api/repos/%3Ahome%3Aserveture%3ADashboards%3A1 V1.wcdf/generatedContent?userid=serveture-user&password=guitar';

    const dataObj = {
      marketplace_param: CURRENT_USER.market_place_info.id,
    };

    $scope.pickerOptions = {
      format: TimeFormatService.format('dateSlashTemplate'),
    };

    $scope.customDateRange = false;
    $scope.showSpinner = false;

    $scope.formData = {
      timePeriod: null,
      dateRangeObj: {},
    };

    $scope.dateRangeChoices = ReportingSectionService.fetchDateRangeChoices();
    $scope.formData.timePeriod = $scope.dateRangeChoices[2];
    $scope.formData.dateRangeObj = ReportingSectionService.fetchDateRange($scope.formData.timePeriod);

    // Return Date display for user
    $scope.dateRangeDisplay = function () {
      const tempStart = moment($scope.formData.dateRangeObj.start).clone();
      const tempEnd = moment($scope.formData.dateRangeObj.end).clone();
      const start = FormatHelperService.returnLocalTimeString(tempStart, 'MMM Do, YYYY');
      const end = FormatHelperService.returnLocalTimeString(tempEnd, 'MMM Do, YYYY');
      return start + ' - ' + end;
    };

    // If date drop down slection is changed
    $scope.getDateRangeDisplay = function (timePeriod) {
      if (timePeriod.value === 'custom') {
        $scope.customDateRange = true;
      } else {
        $scope.customDateRange = false;
        $scope.formData.dateRangeObj = ReportingSectionService.fetchDateRange($scope.formData.timePeriod);

        $scope.browserCheck();
      }
    };

    // Build server call
    $scope.browserCheck = function () {
      buildRequestURL();
    };

    function buildRequestURL() {
      $scope.showSpinner = true;

      dataObj.from_date = moment
        .utc($scope.formData.dateRangeObj.start)
        .format(TimeFormatService.format('fullDateTimeSecs'));
      dataObj.to_date = moment
        .utc($scope.formData.dateRangeObj.end)
        .format(TimeFormatService.format('fullDateTimeSecs'));

      let constructedURL =
        URL +
        '&from_date=' +
        dataObj.from_date +
        '&to_date=' +
        dataObj.to_date +
        '&marketplace_param=' +
        dataObj.marketplace_param.toString();

      if ($scope.entFilter.enterprise_id == -1) {
        const list = EnterpriseFilterService.fetchEnterpriseIds();
        const entString = '&enterprise_param=' + list.toString();
        constructedURL = constructedURL.concat(entString);
      } else if ($scope.entFilter && $scope.entFilter.enterprise_id) {
        const entString = '&enterprise_param=' + $scope.entFilter.enterprise_id.toString();
        constructedURL = constructedURL.concat(entString);
      }

      $scope.currentReportURL = $sce.trustAsResourceUrl(constructedURL);
      $scope.showSpinner = false;
    }

    // Calendar date time selection options and controls
    $scope.startDate = { opened: false };
    $scope.openStartDate = function () {
      $scope.startDate.opened = true;
    };

    $scope.endDate = { opened: false };
    $scope.openEndDate = function () {
      $scope.endDate.opened = true;
    };

    $scope.setEndDateMin = function (startDate) {
      $scope.minEndDate = moment(startDate);
      // ensure user does not set end date before start
      if (moment($scope.formData.dateRangeObj.end).isBefore(moment(startDate))) {
        $scope.formData.dateRangeObj.end = startDate;
      }
      $scope.browserCheck();
    };

    $scope.endDateChanged = function () {
      $scope.browserCheck();
    };

    if (CURRENT_USER.user_type == 2) {
      $scope.browserCheck();
      $scope.$watch('entFilter', function (newVal, oldVal) {
        if (newVal != oldVal) {
          $scope.browserCheck();
        }
      });
    } else {
      $scope.browserCheck();
    }
  },
]);
