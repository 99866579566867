import angular from 'angular';
import module from '../module';
import * as enums from '../../enums';
export default module.controller('NewRequesterModalController', [
  '$scope',
  '$modalInstance',
  'AlertService',
  'FormatHelperService',
  'enterpriseSelection',
  'requesterManagerService',
  'AuthenticationService',
  'sessionManagerService',
  function (
    $scope,
    $modalInstance,
    AlertService,
    FormatHelperService,
    enterpriseSelection,
    requesterManagerService,
    AuthenticationService,
    sessionManagerService
  ) {
    $scope.title = 'Add Requester';
    $scope.formType = 'new';
    $scope.submitBtnLabel = 'Add Requester';
    const CURRENT_USER = AuthenticationService.getCurrentUser();
    const passedEnt = enterpriseSelection;
    $scope.submitBtnDisbaled = false;

    // Grabbing list of requester attribs from API
    const getRequesterRegAttribs = function () {
      sessionManagerService.getRealmAttribs(enums.Realms.Requester).then(function (result) {
        const formData = result.attrib_list.sort(function (a, b) {
          return a.order - b.order;
        });
        $scope.originalFormData = angular.copy(formData);
        $scope.formData = angular.copy(formData);
      });
    };
    getRequesterRegAttribs();

    // listen for instance_count attrib clicks
    $scope.$on('addAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      const _count = field.instanceCountIndex || 1;
      const _field = angular.copy(
        $scope.originalFormData.filter(function (obj) {
          return obj.attrib_id == field.attrib_id;
        })[0]
      );
      _field.instanceCountIndex = _count + 1;
      _field.showRemoveInstance = true;
      $scope.formData[index].doNotShowAddInstance = true;
      $scope.formData[index].showRemoveInstance = false;
      $scope.formData.splice(index + 1, 0, _field); // add after current field
    });

    $scope.$on('removeAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      $scope.formData[index - 1].doNotShowAddInstance = false;
      $scope.formData[index - 1].showRemoveInstance = true;
      $scope.formData.splice(index, 1);
    });

    // Modal controls
    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      const obj = FormatHelperService.format_data($scope.formData);
      const requesterDetails = {
        username: obj.filter(function (obj) {
          return obj.name === 'username';
        })[0].value_text,
        password: obj.filter(function (obj) {
          return obj.name === 'password';
        })[0].value_text,
        user_type: 5,
        market_place_id: CURRENT_USER.market_place_info.id,
        profile_info: obj,
      };
      if (CURRENT_USER.user_type == 3) {
        requesterDetails.enterprise_id = CURRENT_USER.enterprise_info.id;
      } else if (CURRENT_USER.user_type == 2) {
        requesterDetails.enterprise_id = passedEnt.enterprise_id;
      }
      sendToServer(requesterDetails);
    };

    const sendToServer = function (requesterDetails) {
      requesterManagerService.registerRequester(requesterDetails).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
            $modalInstance.close();
          } else {
            $scope.submitBtnDisbaled = false;
            AlertService.errorAlert(response.message);
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  },
]);
