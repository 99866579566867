import { Component, Output, EventEmitter } from '@angular/core';

import { EnumsService } from '../../../../shared/services/enums.service';
import { PermissionsService } from '../../../../shared/services/permissions.service';
import { tabSelectorAnimation } from './tab-selector-container.animations';
import { AppConfigService } from '../../../../shared/services/app-config.service';
import { AuthenticationService } from '../../../../shared/services/authentication.service';

import { IAnalytics } from '../../../../shared/interfaces/IAppConfig';
import { ITabSelectorConfig } from '../../../../shared/interfaces/ITabSelectorConfig';
import { AppStateService } from '../../../../shared/services/app-state.service';
import { IUser } from '../../../../shared/interfaces/IUser';

@Component({
  selector: 'tab-selector-container',
  templateUrl: 'tab-selector-container.component.html',
  animations: tabSelectorAnimation,
})
export class TabSelectorContainerComponent {
  private _selectedTab: string;
  public get selectedTab(): string {
    return this._selectedTab;
  }
  public set selectedTab(v: string) {
    this._selectedTab = v;
    this.appStateService.activeScreen = v;
  }

  public tabSelectorConfig: ITabSelectorConfig[];
  public analytics: IAnalytics;
  public currentUser: IUser;

  @Output() selectingTab: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private appConfigService: AppConfigService,
    private appStateService: AppStateService,
    private authenticationService: AuthenticationService,
    private permissionsService: PermissionsService,
    private enumsService: EnumsService
  ) {
    this.currentUser = this.authenticationService.getCurrentUser();
    // to have a local copy of the analytics config
    this.analytics = this.appConfigService.siteMapConfig.analytics;

    // set the initial tab selection
    this.selectedTab = this.analytics.dashboard.selector;

    // create the current tab config
    this.tabSelectorConfig = [
      {
        tabName: this.analytics.dashboard.selector,
        displayName: this.analytics.dashboard.displayName,
        enabled: this.getPermissionForSubTab(this.permissionsService.PERMISSIONS.NEW_ANALYTICS_TAB),
      },
      //Hiding Reports tab for now until AZDO 79802 is done
      // {
      //   tabName: this.analytics.reports.selector,
      //   displayName: this.analytics.reports.displayName,
      //   enabled: this.getPermissionForSubTab(this.permissionsService.PERMISSIONS.ANALYTICS_REPORTING),
      // },
    ];
  }

  public onSelectTab(selectedTab): void {
    this.selectedTab = selectedTab;
    this.selectingTab.emit(selectedTab);
  }

  private getPermissionForSubTab(permission: number): boolean {
    return !!this.authenticationService.getPermission(permission);
  }
}
