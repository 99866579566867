import module from '../module';
export default module.controller('NewMeetingPlaceModalController', [
  '$scope',
  '$modalInstance',
  'locationsList',
  'AlertService',
  'locationManagerService',
  function ($scope, $modalInstance, locationsList, AlertService, locationManagerService) {
    $scope.submitBtnDisbaled = false;
    $scope.title = 'Add New Meeting Place';
    $scope.submitBtn = 'Add Meeting Place';
    $scope.locationTitle = 'Select a Location';
    $scope.locations = locationsList;
    $scope.formData = {
      name: '',
    };

    $scope.selectedLocation = {};
    $scope.$watch('selectedLocation', function () {
      $scope.formData.location_id = $scope.selectedLocation.location_id;
    });

    // Modal controls
    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      if (!$scope.formData.location_id) {
        AlertService.warningAlert('Please select a loction in order to submit a new place');
        $scope.submitBtnDisbaled = false;
      } else {
        addPlace($scope.formData);
      }
    };
    const addPlace = function (formData) {
      $scope.selectedLocation.addNewPlace(formData).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
          } else {
            AlertService.errorAlert(response.message);
          }
          $modalInstance.close($scope.selectedLocation);
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
        }
      );
    };
    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  },
]);
