import { Injectable } from '@angular/core';
import { SecureHttpService } from '../secure.http.service';
import Env from '../../../scripts/env.js';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  constructor(private http: SecureHttpService) {}

  public getAllPermissions() {
    return this.http.get(`${Env.ADMIN_API}/permissions/`).toPromise();
  }
}
