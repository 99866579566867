import module from './module';
export default module
  .directive('dirScheduleByTime', ['$document', function ($document) {
    return {
      restrict: 'E',
      scope: {
          timetype: '=',
          timesegment: '='
      },
      link: function (scope, element, attr) {
      
      } //link
    };
  }]);
