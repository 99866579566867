import module from './module';
export default module.directive('dirJobIndicator', function () {
  return {
    restrict: 'E',
    scope: {
      job: '='
    },
    controller: [
      '$scope',
      function ($scope) {
        $scope.getFillValue = function () {
          var firstSession = $scope.job.session_list && $scope.job.session_list[0];
          if (firstSession) {
            if (firstSession.state.type === 8 || firstSession.state.type === 9) {
              return $scope.job.number_candidates;
            }
          }

          return $scope.job.current_workers;
        };

        $scope.calculatePercent = function () {
          $scope.fillPercent = $scope.getFillValue() / $scope.job.total_workers * -100 + 0.1 + 's';
        };

        $scope.calculatePercent();
        $scope.$watch('job.current_workers', $scope.calculatePercent);
        $scope.$watch('job.total_workers', $scope.calculatePercent);
        $scope.$watch('job.number_candidates', $scope.calculatePercent);
      }
    ],
    templateUrl: 'app/views/templates/job_indicator.html'
  };
});
