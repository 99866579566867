import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { ActionReducer } from 'ngx-bootstrap/mini-ngrx';
import * as fromDashboard from './analytics/dashboard/store/index';
import { WorkersState } from './workers/store/state';
import { reducer as workersReducer } from './workers/store/reducer';
import { CommonState } from './shared/store/state';
import { reducer as commonReducer } from './shared/store/reducer';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  dashboard: fromDashboard.State;
  workers: WorkersState;
  commonData: CommonState
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<State> = {
  dashboard: fromDashboard.reducer,
  workers: workersReducer,
  commonData: commonReducer
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return function (state: State, action: any): State {
    // console.log('state', state);
    // console.log('action', action);

    return reducer(state, action);
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = [logger];
