import module from '../module';
export default module.controller('RequesterTabController', [
  '$scope',
  '$modal',
  'AlertService',
  'requesterManagerService',
  'AuthenticationService',
  'trackingService',
  function ($scope, $modal, AlertService, requesterManagerService, AuthenticationService, trackingService) {
    const CURRENT_USER = AuthenticationService.getCurrentUser();
    $scope.requesterBtnStatus = true;
    $scope.showSpinner = true;
    $scope.reverse = true;

    // Permission Check
    $scope.addRequesterPermission = CURRENT_USER.permissionCheck(21);

    // set the state of the requester btn based on selected enterprise
    $scope.requesterBtnStatusCheck = function (ent_id) {
      if (ent_id == -1) {
        $scope.requesterBtnStatus = false;
      } else {
        $scope.requesterBtnStatus = true;
      }
    };

    // filter requester view based on the selected enterprise
    $scope.entDisplayFilter = function (allRequesters) {
      if ($scope.entFilter.enterprise_id != -1) {
        $scope.requesters = allRequesters.filter(function (obj) {
          return obj.enterprise_info?.id == $scope.entFilter.enterprise_id;
        });
      } else {
        $scope.requesters = '';
      }
    };

    // Grabbing from API
    const getRequesters = function (requestType) {
      requesterManagerService.getAllRequesters(requestType).then(function (requestersArray) {
        $scope.requesters = requestersArray;
        if (CURRENT_USER.user_type == 2) {
          $scope.entDisplayFilter($scope.requesters);
        }
        $scope.showSpinner = false;
      });
    };

    // market_place enterprise filter
    if (CURRENT_USER.user_type == 2) {
      $scope.requesterBtnStatusCheck($scope.entFilter.enterprise_id);
      $scope.$watch('entFilter', function () {
        console.log('entfilter', arguments);
        getRequesters();
        $scope.requesterBtnStatusCheck($scope.entFilter.enterprise_id);
      });
    } else {
      getRequesters();
    }

    // Table Sorting
    $scope.sort = function (keyname) {
      $scope.sortKey = keyname; // set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; // if true make it false and vice versa
    };
    $scope.sort('last_name');

    // Add New User Modal
    $scope.openRequesterModal = function (action, requester) {
      let ctrlType;
      if (action === 'new') {
        ctrlType = 'NewRequesterModalController';
      } else if (action === 'edit') {
        ctrlType = 'EditRequesterModalController';
      }
      const modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/admin/requester/requesterRegistrationModal.html',
        controller: ctrlType,
        size: 'lg',
        backdrop: 'static',
        resolve: {
          enterpriseSelection: function () {
            return $scope.entFilter;
          },
          passedRequester: function () {
            return requester;
          },
        },
      });
      modalInstance.result.then(function () {
        getRequesters('newRequesterPresent');
      });
      trackingService.trackEvent(trackingService.ADMIN, 'requester', action);
    };
  },
]);
