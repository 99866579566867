export class ChartModel {

  public name: string;
  public xAxisLabel: string;
  public yAxisLabel: string;

  constructor(name: string, xAxisLabel: string, yAxisLabel: string) {
    this.name = name;
    this.xAxisLabel = xAxisLabel;
    this.yAxisLabel = yAxisLabel;
  }
}
