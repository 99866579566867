import module from './module';

export default module.directive('dirJsonText', () => {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, element, attr, ngModel) {
      function into(input) {
        return JSON.parse(input);
      }
      function out(data) {
        if (!data) {
          return 'no data';
        }
        return JSON.stringify(data, undefined, 4);
      }
      ngModel.$parsers.push(into);
      ngModel.$formatters.push(out);
    }
  };
});
