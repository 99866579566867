import module from './module';

export default module.factory('timeConversionService', [
  function () {
    const units = {
      year: 24 * 60 * 365,
      month: 24 * 60 * 30,
      week: 24 * 60 * 7,
      day: 24 * 60,
      hour: 60,
      minute: 1,
    };

    return {
      convertMinutes: function (minuteValue) {
        const result = [];
        for (const name in units) {
          const p = Math.floor(minuteValue / units[name]);
          if (p === 1) result.push(p + ' ' + name);
          if (p >= 2) result.push(p + ' ' + name + 's');
          minuteValue %= units[name];
        }
        return result.join(' ');
      },
    };
  },
]);
