import module from './module';
import * as enums from '../enums';
import Swal from 'sweetalert2';
import * as enums from '../../enums';

export default module.directive('dirWorkerFilter', [
  function () {
    return {
      restrict: 'E',
      controller: [
        '$scope',
        '$localStorage',
        'FormatHelperService',
        'sessionManagerService',
        'AuthenticationService',
        'branchFilterService',
        'PERMISSIONS',
        function (
          $scope,
          $localStorage,
          FormatHelperService,
          sessionManagerService,
          AuthenticationService,
          branchFilterService,
          PERMISSIONS,
        ) {
          const CURRENT_USER = AuthenticationService.getCurrentUser();
          const branch = branchFilterService.getSelectedBranch();

          $scope.showBranchFilter =
            CURRENT_USER.user_type === enums.UserType.MarketPlaceAdmin &&
            !$scope.hideBranchFilter &&
            branch &&
            branch.id !== -1;

          $scope.pickFilterName = 'Pick Filter';
          $scope.data2 = {
            filterBranch: false,
          };
          $scope.hasConfidentialPermission = CURRENT_USER?.permissionCheck(PERMISSIONS.CONFIDENTIAL_FIELDS) ?? false;

          $scope.prepareFilters = function () {
            sessionManagerService.getRealmAttribs(enums.Realms.Worker).then(function (result) {
              const filterableTypes = [
                'text',
                'single_list',
                'multi_list',
                'flag',
                'count',
                'decimal',
                'location',
                'place',
              ];
              $scope.filterList = result.attrib_list
                .filter(function (attrib) {
                  return filterableTypes.includes(attrib.type) && ($scope.hasConfidentialPermission || !attrib.confidential);
                })
                .map((f) => ({
                  ...f,
                  type_flags: {
                    ...f.type_flags,
                    read_only: false,
                  },
                }));
              $scope.filterList.push({
                attrib_id: -1,
                isSystem: true,
                systemType: 1,
                display_name: 'Login Status',
                type: 'multi_list',
                widget_type: 3,
                type_flags: {
                  display: true,
                  type: 0,
                },
                choice_list: [
                  { choice_id: -1, selected: false, name: 'Never Logged In' },
                  { choice_id: 0, selected: false, name: 'Currently Logged Out' },
                  { choice_id: 1, selected: false, name: 'Currently Logged In' },
                ],
              });
              $scope.filterList.push({
                attrib_id: -2,
                isSystem: true,
                systemType: 2,
                display_name: 'Average Rating',
                type: 'single_list',
                widget_type: 3,
                type_flags: {
                  display: true,
                  type: 0,
                },
                choice_list: [
                  { choice_id: 0, selected: false, name: '> 0' },
                  { choice_id: 1, selected: false, name: '> 1' },
                  { choice_id: 2, selected: false, name: '> 2' },
                  { choice_id: 3, selected: false, name: '> 3' },
                  { choice_id: 4, selected: false, name: '> 4' },
                  { choice_id: 5, selected: false, name: '> 5' },
                ],
              });
              $scope.filterList.push({
                attrib_id: -3,
                isSystem: true,
                systemType: 3,
                display_name: 'Worker Type',
                type: 'multi_list',
                widget_type: 3,
                type_flags: {
                  display: true,
                  type: 0,
                },
                choice_list: [
                  { choice_id: 1, selected: false, name: 'Staff' },
                  { choice_id: 2, selected: false, name: 'Contractor' },
                ],
              });
              $scope.filterList.push({
                attrib_id: -4,
                isSystem: true,
                systemType: 4,
                display_name: 'WorkN ID',
                type: 'count',
                widget_type: 10,
                type_flags: {
                  display: true,
                  type: 0,
                },
              });
            });
            if ($localStorage.workerFilter) {
              let localFilters = $localStorage.workerFilter ? JSON.parse($localStorage.workerFilter) : [];

              if (!$scope.hasConfidentialPermission) {
                localFilters = localFilters.filter((f) => !f.confidential);
              }
              $scope.filters = localFilters;
            } else {
              $scope.filters = [];
            }
            if ($scope.filters.length) {
              $scope.pickFilterName = 'Pick Another Filter';
            }
            $scope.setFilters();
          };

          $scope.addFilter = function (attrib_id) {
            const MAX_FILTER = 5;
            if ($scope.filters.length === MAX_FILTER) {
              $scope.showFilterWarning();
              $scope.showFilterLimitReachedTooltip = true;
              return;
            }
            if (attrib_id && attrib_id !== 'none') {
              var filter = $scope.filterList.find(function (item) {
                return item.attrib_id == attrib_id;
              });
              if (
                !$scope.filters.find(function (item) {
                  return item.attrib_id == attrib_id;
                })
              ) {
                $scope.filters.push(filter);
              }
              $scope.selectedFilter = 'none';
              $scope.pickFilterName = 'Pick Another Filter';
            }
          };

          $scope.showFilterWarning = () => {
            const configError = {
              title: 'You reached the maximum number of filters',
              text: 'A maximum of 5 filters can be used at one time, remove one existing filter to add a new one.',
              icon: 'warning',
              showConfirmButton: true,
              confirmButtonText: 'Ok',
              confirmButtonColor: '#EE8357',
            };
            Swal.fire(configError);
          };

          $scope.removeFilter = function (index) {
            $scope.filters.splice(index, 1);
            $scope.showFilterLimitReachedTooltip = false;

            if (!$scope.filters.length) {
              $scope.pickFilterName = 'Pick Filter';
            }
            $scope.setFilters();
          };

          $scope.setFilters = function () {
            $scope.filters.forEach(function (filter) {
              if (filter.type === 'single_list') {
                if (filter.value && filter.value.choice_id) {
                  filter.choice_list.forEach(function (choice) {
                    if (choice.choice_id === filter.value.choice_id) {
                      choice.selected = true;
                    } else {
                      choice.selected = false;
                    }
                  });
                }
              }
            });
            $localStorage.workerFilter = JSON.stringify($scope.filters);
            var systemFilters = [],
              attribFilters;
            attribFilters = FormatHelperService.format_data($scope.filters);
            attribFilters.forEach(function (item) {
              if (typeof item.value_single_list !== 'undefined') {
                item.value_list = [item.value_single_list];
                delete item.value_single_list;
              }
              if (typeof item.value_multi_list !== 'undefined') {
                item.value_list = item.value_multi_list.map(function (item) {
                  return item.choice_id;
                });
                delete item.value_multi_list;
              }
              if (typeof item.value_location !== 'undefined') {
                item.value_location = item.value_location.location_id;
              }

              if (item.attrib_id < 0) {
                systemFilters.push(item);
              }
            });
            attribFilters = attribFilters.filter(function (item) {
              return item.attrib_id > 0;
            });

            systemFilters = systemFilters.map(function (item) {
              if (item.value_count) {
                return {
                  type: Math.abs(item.attrib_id),
                  options: [item.value_count],
                };
              }
              return {
                type: Math.abs(item.attrib_id),
                options: item.value_list,
              };
            });

            let filterBranch =
              CURRENT_USER.user_type === enums.UserType.MarketPlaceAdmin ? $scope.data2.filterBranch : true;

            // if $scope.hideBranchFilter === true it is called from the providertab, so it should be true
            filterBranch = $scope.hideBranchFilter ? true : filterBranch;

            $scope.updateFilters(systemFilters, attribFilters, filterBranch);
          };

          $scope.keyPressed = function (e) {
            //User pressed enter, submit the filters
            if (e.keyCode === 13) {
              e.preventDefault();
              $scope.setFilters();
            }
          };

          $scope.prepareFilters();
        },
      ],
      templateUrl: 'app/views/templates/worker_filter.html',
    };
  },
]);
