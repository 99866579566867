import module from '../module';

export default module.controller('MapViewsController', [
  '$scope',
  '$timeout',
  '$modal',
  'sessionManagerService',
  'locationManagerService',
  'AuthenticationService',
  function ($scope, $timeout, $modal, sessionManagerService, locationManagerService, AuthenticationService) {
    const CURRENT_USER = AuthenticationService.getCurrentUser();

    $scope.markers = [];
    $scope.savedLocations = [];
    $scope.showSpinner = true;
    $scope.selectedStatus = { name: 'All Statuses', id: 0 };

    $scope.statusesFilter = [
      { name: 'All Statuses', id: 0 },
      { name: 'Active', id: 1 },
      { name: 'Committed', id: 2 },
      { name: 'Pending', id: 3 },
      { name: 'Open', id: 4 },
      { name: 'Completed', id: 5 },
      { name: 'Denied', id: 6 },
      { name: 'Canceled', id: 7 },
    ];

    // Map Detail Modal
    $scope.openMapDetailModal = function (marker) {
      if (marker.sessionLength < 0) {
        return;
      }

      marker.location = $scope.savedLocations.find((location) => {
        return location.location_id === marker.locationId;
      });

      $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/views/reporting/mapViews/mapDetailViewModal.html',
        controller: 'MapDetailViewModalController',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          selectedLocation: function () {
            return marker;
          },
          selectedFilter: function () {
            return $scope.selectedStatus;
          },
        },
      });
    };

    // grab known and unknown locations and then create the map markers
    function createMarkers(passedLoc, counter) {
      $scope.savedLocations.push(passedLoc);
      $scope.markers.push({
        position: {
          lat: parseFloat(passedLoc.latitude),
          lng: parseFloat(passedLoc.longitude),
        },
        id: counter,
        locationId: passedLoc.location_id,
        sessionLength: (passedLoc.sessions || []).length,
        clickable: true,
        label: {
          className: 'marker_labels',
          text: passedLoc.name + ': ' + (passedLoc.sessions || []).length,
        },
      });
    }

    function setKnownMarkers(location_list) {
      if (location_list) {
        for (let i = 0; i < location_list.length; i++) {
          const sessions = sessionManagerService.filterSessionByLocation(
            location_list[i].enterprise_id,
            location_list[i].location_id
          );
          location_list[i].addSessions(sessions);
          createMarkers(location_list[i], i);
        }
      }
    }

    function setUnknownLocMarkers() {
      if (CURRENT_USER.user_type == 2 && $scope.entFilter.enterprise_id == -1) {
        let unknownLocsArray = [];
        let counter = 9999;
        locationManagerService.grabUnknownLocList().then(function (unknownLocsObj) {
          for (const entLoc in unknownLocsObj) {
            unknownLocsArray = unknownLocsArray.concat(unknownLocsObj[entLoc]);
          }
          unknownLocsArray.forEach(function (location) {
            counter++;
            const sessions = sessionManagerService.filterSessionByUnknownLocations(
              location.enterprise_id,
              location.name
            );
            location.addSessions(sessions);
            createMarkers(location, counter);
          });
          $scope.showSpinner = false;
        });
      } else if (CURRENT_USER.user_type == 2 && $scope.entFilter.enterprise_id != -1) {
        getUnknownLocsEntPresent();
      } else {
        getUnknownLocsEntPresent();
      }
    }

    function getUnknownLocsEntPresent() {
      locationManagerService.getUnknownLocations($scope.entFilter.enterprise_id).then(function (unknownLocationsArray) {
        const unknownLocs = unknownLocationsArray;
        let counter = 9999;
        if (unknownLocs[$scope.entFilter.enterprise_id]) {
          unknownLocs[$scope.entFilter.enterprise_id].forEach(function (location) {
            counter++;
            const sessions = sessionManagerService.filterSessionByUnknownLocations(
              location.enterprise_id,
              location.name
            );
            location.addSessions(sessions);
            createMarkers(location, counter);
          });
        }
        $scope.showSpinner = false;
      });
    }

    function getLocationsForDisplay() {
      $scope.markers = [];
      locationManagerService.getAllLocations().then(function (locationsArray) {
        const knownLocs = locationsArray;
        if (CURRENT_USER.user_type == 3) {
          setKnownMarkers(knownLocs[$scope.entFilter.enterprise_id]);
        } else if (CURRENT_USER.user_type == 2) {
          if ($scope.entFilter.enterprise_id != -1) {
            const filteredLocations = knownLocs[$scope.entFilter.enterprise_id];
            setKnownMarkers(filteredLocations);
          } else {
            // marketplace selected loading all locations
            let locArray = [];
            for (const entLoc in knownLocs) {
              locArray = locArray.concat(knownLocs[entLoc]);
            }
            setKnownMarkers(locArray);
          }
        }
        setUnknownLocMarkers(); // grab unknown locations next
      });
    }

    $scope.updateMarkerStats = function (filterVal) {
      $scope.selectedStatus = filterVal;
      $scope.markers.forEach(function (currentVal) {
        let _requestCount;
        if (filterVal.id == 0) {
          _requestCount = currentVal.location.sessions.length;
          currentVal.options.visible = true;
        } else {
          if (currentVal.location.sessions) {
            _requestCount = currentVal.location.sessions.filter(function (obj) {
              return obj.status.type == filterVal.id;
            }).length;
          } else {
            _requestCount = 0;
          }

          if (_requestCount == 0) {
            currentVal.options.visible = false;
          } else {
            currentVal.options.visible = true;
          }
        }
        currentVal.options.labelContent = currentVal.location.name + ': ' + _requestCount;
      });
    };

    // GOOGLE MAPS
    $scope.map = {
      control: {},
      center: {
        lat: 41,
        lng: -99,
      },
      refresh: true,
      zoom: 4,
      fit: true,
      options: { scrollwheel: false },
      gestureHandling: 'greedy',
    };

    $scope.showSpinner = false;

    function initializeData() {
      $scope.showSpinner = true;
      $scope.selectedStatus.id = 0;

      setTimeout(() => {
        sessionManagerService.getAllSessions($scope.entFilter.enterprise_id).then(function (sessions) {
          $scope.showSpinner = true;
          getLocationsForDisplay();
        });
      }, 1000);
    }

    // market_place enterprise filter
    if (CURRENT_USER.user_type == 2) {
      $scope.$watch('entFilter', function (newVal, oldVal) {
        if (newVal !== oldVal) {
          initializeData();
        }
      });
    }

    function loadSessions() {
      // if ($scope.entFilter.enterprise_id == -1) {
      //   return;
      // }
      initializeData();
    }

    loadSessions();
  },
]);
