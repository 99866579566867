import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  templateUrl: './workn-error-page.component.html',
  styleUrls: ['./workn-error-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'app-error-workn',
})
export class WorknErrorPageComponent {}
