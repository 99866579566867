import { Component, OnDestroy, OnInit } from '@angular/core';
import { IPermissionInfo, IUser } from 'app/shared/interfaces';
import { AuthenticationService, PermissionsService } from 'app/shared/services';
import { EnumsService } from './../../services/enums.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, filter, map, switchMap, takeUntil } from 'rxjs';
import { NavigationEnd } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CommonSelectors } from 'app/shared/store/selectors';
import { UserType } from 'app/scripts/enums';

@Component({
  templateUrl: './menu.component.html',
  selector: 'app-menu',
})
export class MenuComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<boolean>();

  public isCollapsed = false;
  public token: string;
  public activetab: string;
  public titleLabel: string;
  public currentUser: IUser;
  public displayName: string;
  public displayEmail: string;
  public displayMarketplace: string;
  public isMarketplace = false;
  public bannerImg: SafeUrl;

  public sessionTabPermission: IPermissionInfo | boolean;
  public reportTabPermission: IPermissionInfo | boolean;
  public adminTabPermission: IPermissionInfo | boolean;
  public workersTabPermission: IPermissionInfo | boolean;
  public supportTabPermission: IPermissionInfo | boolean;
  public scheduleTabPermission: IPermissionInfo | boolean;
  public jobsTabPermission: IPermissionInfo | boolean;
  public dashboardTabPermission: IPermissionInfo | boolean;
  public analyticsTabPermission: IPermissionInfo | boolean;
  public fromBold: boolean;

  constructor(
    private permissions: PermissionsService,
    private authenticationService: AuthenticationService,
    private enumService: EnumsService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private commonSelectors: CommonSelectors
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        map(() => this.route),
        map((route) => route.firstChild),
        switchMap((route) => route.data)
      )
      .subscribe((data) => {
        this.activetab = data.activetab;
      });
    
    this.route.queryParams.pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.fromBold = !!params['ext_order_id'];
        if (this.fromBold) {
          this.scheduleTabPermission = this.authenticationService.getPermission(
            this.permissions.PERMISSIONS.CALENDAR_SCHEDULE_TAB
          );
          this.adminTabPermission = false;
          this.reportTabPermission = false;
          this.sessionTabPermission = false;
          this.jobsTabPermission = false;
          this.analyticsTabPermission = false;
          this.supportTabPermission = false;
          this.workersTabPermission = false;
          this.dashboardTabPermission = false;
        } 
      });

    try {
      this.currentUser = JSON.parse(localStorage.getItem('ngStorage-current_user'));
    } catch (e) {}

    if (this.currentUser?.user_type === UserType.EnterpriseAdmin) {
      this.commonSelectors
        .getEnterpriseSelection()
        .pipe(takeUntil(this.destroy$))
        .subscribe((selection) => {
          this.titleLabel = selection.name;
        });
    } else if (this.currentUser?.user_type === UserType.BranchAdmin) {
      this.titleLabel = this.currentUser?.market_place_info?.name;
    }

    this.token = localStorage.getItem('ngStorage-token');
    let bannerImg = localStorage.getItem('ngStorage-bannerImg');
    try {
      if (bannerImg) {
        bannerImg = JSON.parse(bannerImg);
        this.bannerImg = this.sanitizer.bypassSecurityTrustUrl(bannerImg);
      }
    } catch (e) {}

    if (this.currentUser && this.currentUser.permission_info) {
      this.sessionTabPermission = this.authenticationService.getPermission(this.permissions.PERMISSIONS.SESSION_TAB);
      this.reportTabPermission = this.authenticationService.getPermission(this.permissions.PERMISSIONS.REPORT_TAB);
      this.adminTabPermission = this.authenticationService.getPermission(this.permissions.PERMISSIONS.ADMIN_TAB);
      this.workersTabPermission = this.authenticationService.getPermission(this.permissions.PERMISSIONS.PROVIDER_TAB);
      this.supportTabPermission = this.authenticationService.getPermission(this.permissions.PERMISSIONS.SUPPORT_TAB);
      this.scheduleTabPermission = this.authenticationService.getPermission(
        this.permissions.PERMISSIONS.CALENDAR_SCHEDULE_TAB
      );
      this.jobsTabPermission = this.authenticationService.getPermission(this.permissions.PERMISSIONS.JOBS);
      this.dashboardTabPermission = this.authenticationService.getPermission(
        this.permissions.PERMISSIONS.NEW_ANALYTICS_TAB
      );
      if (this.currentUser && this.currentUser.user_type === this.enumService.UserType.MasterUser) {
        this.sessionTabPermission = false;
        this.reportTabPermission = false;
        this.adminTabPermission = false;
        this.supportTabPermission = false;
        this.scheduleTabPermission = false;
        this.jobsTabPermission = false;
        this.analyticsTabPermission = this.authenticationService.getPermission(
          this.permissions.PERMISSIONS.NEW_ANALYTICS_TAB
        );
      } else if (this.currentUser && this.currentUser.permission_info) {
        this.sessionTabPermission = this.authenticationService.getPermission(this.permissions.PERMISSIONS.SESSION_TAB);
        this.reportTabPermission = this.authenticationService.getPermission(this.permissions.PERMISSIONS.REPORT_TAB);
        this.adminTabPermission = this.authenticationService.getPermission(this.permissions.PERMISSIONS.ADMIN_TAB);
        this.supportTabPermission = this.authenticationService.getPermission(this.permissions.PERMISSIONS.SUPPORT_TAB);
        this.scheduleTabPermission = this.authenticationService.getPermission(
          this.permissions.PERMISSIONS.CALENDAR_SCHEDULE_TAB
        );
        this.jobsTabPermission = this.authenticationService.getPermission(this.permissions.PERMISSIONS.JOBS);
        this.analyticsTabPermission = this.authenticationService.getPermission(
          this.permissions.PERMISSIONS.NEW_ANALYTICS_TAB
        );
      }
    }

    if (this.currentUser) {
      const firstName = this.currentUser.profile_info?.find((x) => x.name === 'first_name')?.data;
      const lastName = this.currentUser.profile_info?.find((x) => x.name === 'last_name')?.data;
      const userName = this.currentUser.profile_info?.find((x) => x.name === 'username')?.data;

      if (firstName && lastName) {
        this.displayName = `${firstName} ${lastName}`;
      } else {
        this.displayName = userName;
      }

      this.displayEmail = this.currentUser.profile_info?.find((x) => x.name === 'email_address')?.data;
      this.displayMarketplace = this.currentUser?.market_place_info?.name;

      this.isMarketplace = [
        this.enumService.UserType.MarketPlaceAdmin,
        this.enumService.UserType.MultiBranchAdmin,
      ].includes(this.currentUser.user_type);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
