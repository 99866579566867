import { createSelector, createFeatureSelector } from '@ngrx/store';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

import * as fromDashboard from './store/index';

export interface State {
  dashboard: fromDashboard.State;
}

export const getDashboardState = createFeatureSelector<fromDashboard.State>('dashboard');

export const getWorkerFilter = createSelector(getDashboardState, fromDashboard.getWorkerFilter);

export const getEnterpriseFilter = createSelector(getDashboardState, fromDashboard.getEnterpriseFilter);

export const getTimeFilter = createSelector(getDashboardState, fromDashboard.getTimeFilter);

export const getCategoryFilter = createSelector(getDashboardState, fromDashboard.getCategoryFilter);

export const getWidgets = createSelector(getDashboardState, fromDashboard.getWidgets);

export const getRequests = createSelector(getDashboardState, fromDashboard.getRequests);

export const getTimeRange = createSelector(getDashboardState, fromDashboard.getTimeRange);

export const getIsLoading = createSelector(getDashboardState, fromDashboard.getIsLoading);

export const getSelectedWidget = createSelector(getDashboardState, fromDashboard.getSelectedWidget);

export const getEnterprises = createSelector(getDashboardState, fromDashboard.getEnterprises);

export const getKeyAttribute = createSelector(getDashboardState, fromDashboard.getKeyAttribute);

export const getWorkerInfo = createSelector(getDashboardState, fromDashboard.getWorkerInfo);

export const getRequestListFilter = createSelector(getDashboardState, fromDashboard.getRequestListFilter);

export const getAllWidgets = createSelector(getDashboardState, fromDashboard.getAllWidgets);

export const getDragMode = createSelector(getDashboardState, fromDashboard.getDragMode);

export const getJobs = createSelector(getDashboardState, fromDashboard.getJobs);

export const getHasValidFilter = createSelector(getDashboardState, fromDashboard.getHasValidFilter);

export const getRequestDetails = createSelector(getDashboardState, fromDashboard.getRequestDetails);

export const getVerifyReport = createSelector(getDashboardState, fromDashboard.getVerifyReport);

export const getVerifyListFilter = createSelector(getDashboardState, fromDashboard.getVerifyListFilter);

export const getStaffFilter = createSelector(getDashboardState, fromDashboard.getStaffFilter);

export const getPermissions = createSelector(getDashboardState, fromDashboard.getPermissions);

export const getProviderDetails = createSelector(getDashboardState, fromDashboard.getProviderDetails);

export const getKeySession = createSelector(getDashboardState, fromDashboard.getKeySession);

export const getKeyProfession = createSelector(getDashboardState, fromDashboard.getKeyProfession);

export const getHourlyFilter = createSelector(getDashboardState, fromDashboard.getHourlyFilter);
