import module from './module';
import moment from 'moment';

export default module.factory('timeService', [
  'TimeFormatService',
  function (TimeFormatService) {
    function _combineDateTime(date, time) {
      // in UTC...this converst to and returns local time
      const builtDateTimeUtc = date + ' ' + time;
      return moment.utc(builtDateTimeUtc, TimeFormatService.format('fullDateTime')).local().clone();
    }

    function _buildLocalTimeObj(localDateTimeMoment) {
      const _localDateTimeMoment = moment(localDateTimeMoment).clone();
      return {
        momentObj: moment(_localDateTimeMoment).clone(),
        localDateTime: moment(_localDateTimeMoment).clone().format(TimeFormatService.format('fullDateTime')),
        displayDate: moment(_localDateTimeMoment).clone().format(TimeFormatService.format('shortMonthYear')),
        displayTime: moment(_localDateTimeMoment).clone().format('h:mma'),
      };
    }

    function _getDurationDiff(start, end) {
      return end.diff(start, 'm');
    }

    function _buildCheckInOutObjs(session) {
      let checkInObj;
      let checkOutObj;
      let checkInOutWarning;
      let checkInOutDiff;
      if (session.checkin_date) {
        const _checkinDateTimeLocal = _combineDateTime(session.checkin_date, session.checkin_time);
        checkInObj = _buildLocalTimeObj(_checkinDateTimeLocal);
      }
      if (session.checkout_date) {
        const _checkoutDateTimeLocal = _combineDateTime(session.checkout_date, session.checkout_time);
        checkOutObj = _buildLocalTimeObj(_checkoutDateTimeLocal);
      }
      if (checkInObj && checkOutObj) {
        checkInOutDiff = _getDurationDiff(checkInObj.momentObj, checkOutObj.momentObj);
        if (session.status.type !== 7 && checkInOutDiff < 0) {
          checkInOutWarning = 'Check-In time cannot be after Check-Out time.';
        }
      }

      return {
        checkInObj,
        checkOutObj,
        checkInOutWarning,
        checkInOutDiff,
      };
    }

    return {
      combineDateTime: _combineDateTime,
      buildLocalTimeObj: _buildLocalTimeObj,
      getDurationDiff: _getDurationDiff,
      buildCheckInOutObjs: _buildCheckInOutObjs,
    };
  },
]);
