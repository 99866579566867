import $ from 'jquery';
import * as _ from 'lodash';
import module from './module';
export default module.directive('dirTabControl', [
  '$timeout',
  'trackingService',
  '$location',
  function ($timeout, trackingService, $location) {
    return {
      restrict: 'A',
      link: function ($scope, element, attrs) {
        $scope.$on('UpdateAdminTabs', function () {
          if (!$scope.tabPermissions[$scope.currentTab]) {
            selectFirstTab();
          }
        });
        ////////////////////////////////////////////////////////////////////////////////
        // Show tab...needed to reload DOM element for table pagination
        $scope.showTab = function (tab) {
          $scope.currentTab = tab;
          _.each($scope.tabs, function (value, key) {
            if (key == tab) {
              $scope.tabs[key] = true;
            } else {
              $scope.tabs[key] = false;
              var divId = '#' + key;
              $(divId).removeClass('active');
            }
          });
          if (!$scope.$$phase) {
            $scope.$apply();
          }
          trackingService.trackEvent(trackingService.ADMIN, 'tabChange', tab);
        };

        angular.element(document).ready(function () {
          const tabPaths = $location
            .path()
            .split('/')
            .filter((value) => value.length > 0);
          if (tabPaths && tabPaths.length && tabPaths[0] === 'reporting') {
            $timeout(function () {
              switch (tabPaths[1]) {
                case 'time-verification':
                  $scope.showTab('timeReportsTab');
                  break;
                case 'analytics':
                  $scope.showTab('analyticsTab');
                  break;
                case 'overview':
                  $scope.showTab('dashboardTab');
                  break;
                case 'map-views':
                  $scope.showTab('mapTab');
                  break;
                case 'time-verification-original':
                  $scope.showTab('changeVerifyTab');
                  break;
                default:
                  selectFirstTab();
                  break;
              }
            }, 600);
          } else {
            selectFirstTab();
          }
        });

        function selectFirstTab() {
          $timeout(function () {
            var id = element.attr('id');
            var firstActiveTab = $('#' + id + ' > ul > li:not(".ng-hide"):first');
            if (!firstActiveTab.attr('id')) {
              return;
            }
            firstActiveTab.addClass('active');
            var tempDiv = firstActiveTab.attr('id').replace(/Tab/i, 'Div');
            var divId = '#' + tempDiv;
            // set scope.tabs boolean
            $scope.showTab(firstActiveTab.attr('id'));

            // timeout to change parent div visiblity and recognize scope.tabs boolean
            $(divId).parent().addClass('active');
          }, 300);
        }
      },
    };
  },
]);
