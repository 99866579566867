import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject, takeUntil, tap } from 'rxjs';
import { CommonSelectors } from '../../../shared/store/selectors';
import { State } from 'app/store';
import { Store } from '@ngrx/store';
import { SetSelectedEnterprise } from 'app/shared/store/actions';

@Component({
  selector: 'enterprise-selector',
  templateUrl: 'enterprise-selector.component.html',
})
export class EnterpriseSelectorComponent implements OnInit, OnDestroy {
  public enterpriseList: any[] = [];
  public selectedEnterprise: any;

  private readonly destroy$ = new Subject<boolean>();

  constructor(private store: Store<State>, private commonSelectors: CommonSelectors) {}

  public ngOnInit(): void {
    this.commonSelectors
      .getVisibleEnterprises()
      .pipe(
        takeUntil(this.destroy$),
        tap((list) => (this.enterpriseList = list))
      )
      .subscribe();

    this.commonSelectors
      .getEnterpriseSelection()
      .pipe(
        takeUntil(this.destroy$),
        tap((item) => (this.selectedEnterprise = item))
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public onEnterpriseChange(value: any) {
    this.store.dispatch(new SetSelectedEnterprise(value, true));
  }
}
