import module from './module';
import angular from 'angular';

export default module.factory('CurrentUser', [
  function () {
    function CurrentUser(userData) {
      if (userData) {
        this.setData(userData);
      }
    }

    CurrentUser.prototype = {
      setData: function (userData) {
        angular.extend(this, userData);
      },
      permissionCheck: function (permId) {
        return this.permission_info.filter(function (obj) {
          return obj.priv_id == permId;
        })[0];
      },
    };

    return CurrentUser;
  },
]);
