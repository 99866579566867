import module from './module';
export default module.directive('dirFormLabel', function() {
  return {
    restrict: 'E',
    scope: {
      field: '='
    },
    templateUrl: 'app/views/templates/form_label.html'
  };
});
