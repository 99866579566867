import { Injectable } from '@angular/core';
import { SecureHttpService } from '../secure.http.service';
import Env from '../../../scripts/env.js';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class EnterpriseListService {
  private endpointRoot = `${Env.ADMIN_API}/enterprises/`;

  constructor(private http: SecureHttpService) { }

  public getAllEnterprises() {
    return this.http.get(this.endpointRoot).toPromise();
  }

  public getAllEnterprisesSimple() {
    return this.http.get(`${this.endpointRoot}simple`).toPromise();
  }

  public getAllEnterprises$() {
    return this.http.get(this.endpointRoot);
  }

  public getAllEnterprisesSimple$() {
    return this.http.get(`${this.endpointRoot}simple`);
  }

  public getFilteredEnterprises(payload) {
    const removeUndefined = (item) => _.pickBy(item, (p) => p !== undefined);
    return this.http.get(this.endpointRoot, removeUndefined(payload)).toPromise();
  }

  public getEnterprise(id) {
    return this.http.get(`${this.endpointRoot}${id}`).toPromise();
  }

  public editEnterprise(enterprise) {
    return this.http.patch(`${this.endpointRoot}${enterprise.id}`, enterprise).toPromise();
  }

  public addEnterprise(enterprise) {
    return this.http.post(`${this.endpointRoot}`, enterprise).toPromise();
  }

  public removeEnterprise(id) {
    return this.http.delete(`${this.endpointRoot}${id}/`).toPromise();
  }

  public extractEnterprises() {
    return this.http.post(`${this.endpointRoot}admin_enterprise_extract`).toPromise();
  }
}
