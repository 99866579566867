import { NewReportsComponent } from './new-reports/new-reports.component';
import { NewReportsService } from './new-reports/new-reports.service';

export const PAGES = [
  NewReportsComponent,
];

export const PAGES_SERVICES = [
  NewReportsService,
]

export * from './new-reports/new-reports.component';
export * from './new-reports/new-reports.service';
