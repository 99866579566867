import module from './module';
import Swal from 'sweetalert2';

export default module.factory('AlertService', [
  '$http',
  '$location',
  'enterpriseUtilityService',
  function ($http, $location, enterpriseUtilityService) {
    return {

      errorAlert: async function (message, timer) {
        const configError = {
          title: 'Error',
          text: message,
          icon: 'error',
          timer: timer || 2000,
          showConfirmButton: false,
        };
        await Swal.fire(configError);
      },
      serverRequestErrorAlert: async function (reason) {
        const search = $location.search();
        if (search && search.showModal === 'true') {
          return;
        }
        if (typeof reason === 'string') {
          const configError = {
            title: 'Error',
            text: reason,
            icon: 'error',
            timer: 2000,
            showConfirmButton: false,
          };
          await Swal.fire(configError);
        } else {
          const configError = {
            title: 'Error',
            text: 'Error contacting the server',
            icon: 'error',
            timer: 2000,
            showConfirmButton: false,
          };
          await Swal.fire(configError);
        }
      },
      successAlert: async function (message) {
        const configPositive = {
          title: 'Success',
          text: message,
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        };
        await Swal.fire(configPositive);
      },
      warningAlert: async function (message) {
        const configError = {
          title: 'Oops!',
          text: message,
          icon: 'warning',
          showConfirmButton: true,
        };
        await Swal.fire(configError);
      },
      detailedErrorAlert: async function (message) {
        if (!message?.error?.details) {
          return;
        }

        const errors = Object.keys(message.error.details).map(function (key) {
          return `${enterpriseUtilityService.getEnterpriseKeyLabel(key)}: ${message.error.details[key]}`;
        });

        const configError = {
          title: 'Error',
          html: errors.join('<br/>'),
          icon: 'error',
          timer: 4000,
          showConfirmButton: false,
        };
        await Swal.fire(configError);
      },
      errorListAlert: async function (messages, timer) {
        if (!messages || messages.length === 0) {
          return;
        }

        const configError = {
          title: 'Error',
          html: messages.join('<br/>'),
          icon: 'error',
          timer: timer || 2000,
          showConfirmButton: false,
        };
        await Swal.fire(configError);
      },
    };
  },
]);
