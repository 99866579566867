import module from './module';
import './angular-mighty-datepicker';
import './dirCalendarFilter';
import './dirCustomerReview';
import './dirDynamicAttribFormField';
import './dirDynamicChildAttribFormField';
import './dirDynamicStyle';
import './dirJobViewHeader';
import './dirNotificationWidget';
import './dirPageHeader';
import './dirPaging';
import './dirPickList';
import './dirPickWorker';
import './dirRegistrationForm';
import './dirScheduleJobForm';
import './dirServiceRequestForm';
import './dirTabControl';
import './dirTimeBarDisplay';
import './dirTrackingTimeBar';
import './dirWorkerFilter';
import './myCurrentTime';
import './pleaseReviewWidget';
import './dirScheduleByTime';
import './dirJobIndicator';
import './dirApplyIndicator';
import './dirProfilePicture';
import './dirFormLabel';
import './dirTimeZoneDisplay';
import './dirOnLastRepeat';
import './dirSwitchSelect';
import './dirApiLogFilter';
import './dirJsonText';
import './dirDatetimepicker';
import './dirTooltip';
import './dirSimplePieChart';
import './dirTimePickerPopupSimple';
import './dirTimePickerPopupWithArrows';
import './dirMouseWheelDown';
import './dirMouseWheelUp';
import './dirRemoveSelectAll';
import './dirCalendarScheduleTimeline';
import './dirPaginate';
import './multiSelectDir';
import './ngAutocomplete';
import './dirBreakPopup';
import './dirBreakPenalty';
import './dirEnterpriseFilter';
import './dirJobFilter';
import './dirContentEditable';
import './dirFileDrop';
import './dirToggleButton';
import './upgrade/scheduleDirective';
import './upgrade/loginDirective';
import './upgrade/jobOrderReportDirective';
import './upgrade/workersDirective';
import './upgrade/reportingDirective';
import './upgrade/adminDirective';
import './upgrade/supportDirective';
import './upgrade/jobDirective';
import './upgrade/sessionDirective';
import './dirSupervisorSignOff';

export default module;
