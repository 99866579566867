import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SecureHttpService } from '../../../shared/services/secure.http.service';
import { share, switchMap } from 'rxjs/operators';
import { TimeFormatService } from '../../../shared/services/time-format.service';
import { AuthenticationService } from '../../../shared/services';

@Injectable()
export class VerifyReportService {
  private data: any[];
  private fetch$: Observable<any>;

  constructor(
    private http: SecureHttpService,
    private timeFormatService: TimeFormatService,
    private authService: AuthenticationService
  ) {}

  loadFromCache(enterpriseId) {
    let cached = localStorage.getItem(`verify_report/${enterpriseId}`);
    if (cached) {
      this.data = JSON.parse(cached);
    }
  }

  fetch(enterpriseId = -1, range, staff, branchId?) {
    this.data = null;
    //this.loadFromCache(enterpriseId);

    if (this.data) {
      return of(this.data);
    }
    let startDate = moment.utc(range.start).format(this.timeFormatService.format('api'));
    let endDate = moment.utc(range.end).format(this.timeFormatService.format('api'));
    let payload = {
      start_date: startDate,
      end_date: endDate,
    };
    if (enterpriseId > -1) {
      (<any>payload).enterprise_id = enterpriseId;
    }

    const currentUser = this.authService.getCurrentUser();
    // BRANCH admins
    let branch_id;
    if (currentUser.user_type == 6) {
      branch_id = currentUser?.branch_info?.id;
    }
    if (enterpriseId === -2) {
      branch_id = branchId;
    }

    if (branch_id) {
      (<any>payload).branch_id = branch_id;
    }

    if (this.fetch$) {
      return this.fetch$;
    } else {
      this.fetch$ = this.http.post('verify_report', payload).pipe(
        switchMap((response) => {
          let result = response.request_list;
          switch (staff) {
            case 1:
              result = result.filter((x) => x.worker_staff);
              break;
            case 2:
              result = result.filter((x) => !x.worker_staff);
              break;
          }
          //localStorage.setItem(`verify_report/${enterpriseId}`, JSON.stringify(result));
          return of(result);
        }),
        share()
      );

      return this.fetch$;
    }
  }
}
