import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { FinancialBaseWidget } from './financial.base.widget';
import * as fromRoot from '../../store';
import { FilterReportList } from '../../store/actions';

@Component({
  selector: 'financial-key-attribute-component',
  templateUrl: './financial.widget.template.html'
})
export class FinancialKeyAttributeComponent extends FinancialBaseWidget {
  public customTitle: string;
  public titleValue: string;
  constructor(store: Store<fromRoot.State>) {
    super(store);
  }

  loadDataTemplate(list) {
    if (!list) {
      return;
    }

    this.store.select(fromRoot.getKeyAttribute).subscribe(attrib => {
      if (attrib) {
        this.customTitle = `$ By ${attrib.name}`;
      }
    });

    let hash = {};
    let mapped = list.map(item => {
      return {
        attrib: this.getAttrib(item.key_attrib_info),
        billable: item.total_cost
      };
    });

    mapped.forEach(element => {
      if (hash[element.attrib]) {
        hash[element.attrib] += element.billable;
      } else {
        hash[element.attrib] = element.billable;
      }
    });

    this.data = Object.keys(hash).map(key => {
      return {
        name: key,
        value: parseInt(hash[key])
      };
    });

    this.data.sort((a, b) => b.value - a.value);

    this.titleValue = this.data.length && `$${this.data.map(x => x.value).reduce((a, b) => a + b)}`;
  }

  getAttrib(key_attrib_info) {
    let theAttribValue;
    if (key_attrib_info) {
      if (key_attrib_info.type === 'single_list' || key_attrib_info.type === 'multi_list') {
        theAttribValue = key_attrib_info.value_list && key_attrib_info.value_list[0] && key_attrib_info.value_list[0].name;
      }
      else if (key_attrib_info.type === 'text') {
        theAttribValue = key_attrib_info.value_text;
      }
    }
    return theAttribValue;
  }

  filterAll() {
    let action = new FilterReportList({
      showAll: true
    });
    this.store.dispatch(action);
  }

  filterData(data) {
    let action = new FilterReportList({
      keyAttribute: data.name
    });
    this.store.dispatch(action);
  }
}
