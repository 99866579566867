import angular from 'angular';
import module from '../module';
import Swal from 'sweetalert2';

export default module.controller('PoolTabController', [
  '$scope',
  '$rootScope',
  '$modal',
  'poolManagerService',
  'AuthenticationService',
  'trackingService',
  'EnterpriseFilterService',
  'escalationManagerService',
  function (
    $scope,
    $rootScope,
    $modal,
    poolManagerService,
    AuthenticationService,
    trackingService,
    EnterpriseFilterService,
    escalationManagerService
  ) {
    const CURRENT_USER = AuthenticationService.getCurrentUser();
    let unbindHandler = null;
    $scope.showSpinner = true;
    $scope.poolBtnStatus = true;
    $scope.reverse = true;
    $scope.entFilter = EnterpriseFilterService.fetchSelectedEnterprise();

    // Permission Check
    $scope.addPoolPermission = CURRENT_USER.permissionCheck(25);
    $scope.editPoolPermission = CURRENT_USER.permissionCheck(26);
    $scope.deletePoolPermission = CURRENT_USER.permissionCheck(27);

    $scope.allProviderPool = function (pool) {
      return pool.pool_id != -1;
    };
    // Grabbing Pools from API
    const getPools = function () {
      const marketPlaceId = CURRENT_USER.market_place_info.id;
      let params = {};
      if (parseInt($scope.entFilter.enterprise_id) > 0) {
        params = { ...params, enterprise_id: $scope.entFilter.enterprise_id };
      } else if (marketPlaceId) {
        params = { ...params, market_place_id: marketPlaceId };
      }

      poolManagerService.getAllPools(params).then(function (poolsArray) {
        $scope.pools = angular.copy(poolsArray);
        if (CURRENT_USER.user_type == 2) {
          $scope.entDisplayFilter($scope.pools);
        }
        $scope.showSpinner = false;
      });

      escalationManagerService.getAllEscalations(params).then(function (escalationsObj) {
        $scope.escalations =
          parseInt($scope.entFilter.enterprise_id) > 0
            ? escalationsObj[$scope.entFilter.enterprise_id]
            : escalationsObj[-1];
      });
    };

    // market_place enterprise filter
    if (CURRENT_USER.user_type == 2) {
      $scope.$watch('entFilter', function () {
        getPools(parseInt($scope.entFilter.enterprise_id) > 0 ? $scope.entFilter.enterprise_id : null);
      });
    } else {
      getPools(parseInt($scope.entFilter.enterprise_id) > 0 ? $scope.entFilter.enterprise_id : null);
    }
    // filter pools view based on the selected enterprise
    $scope.entDisplayFilter = function (pools) {
      // if ($scope.entFilter.enterprise_id == -1) {
      //   $scope.pools = null;
      //   $scope.poolBtnStatus = false;
      // } else {
      if (parseInt($scope.entFilter.enterprise_id) > 0) {
        $scope.pools = pools.filter(function (obj) {
          return obj.enterprise_id == $scope.entFilter.enterprise_id;
        });
      } else {
        $scope.pools = pools.filter((pool) => {
          return pool.enterprise_id === undefined;
        });
      }
      $scope.poolBtnStatus = true;
      // }
    };

    // Table Sorting
    $scope.sort = function (keyname) {
      $scope.sortKey = keyname; // set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; // if true make it false and vice versa
    };
    $scope.sort('name');

    $scope.poolUsedInEscalations = async (pool) => {
      if ($scope.escalations) {
        const usedEscalations = $scope.escalations.filter((esc) =>
          esc.pool_list.some((escPool) => escPool.pool_id === pool.pool_id)
        );
        if (usedEscalations.length) {
          return usedEscalations;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    // Delete requester button
    $scope.deletePool = async function (pool) {
      const escalations = await $scope.poolUsedInEscalations(pool);
      if (escalations) {
        const escConfig = {
          title: 'Pool in use!',
          html: `You must remove this pool from the following escalation(s) before submitting:<br>
          ${escalations.map((esc) => `<b>${esc.name}</b>`).join(',<br>')}
          `,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#aaa',
          confirmButtonText: 'Ok',
        };
        return await Swal.fire(escConfig);
      }

      const config = {
        title: 'Are you sure?',
        text: 'You are about to delete Pool ' + pool.name,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Yes, delete Pool',
      };

      const returnHandler = function (isConfirm) {
        if (isConfirm) {
          pool.delete().then(
            async function (response) {
              if (response.success) {
                poolManagerService.removeDeletedPool(pool.pool_id).then(function (poolsArray) {
                  $scope.pools = angular.copy(poolsArray);
                  if (CURRENT_USER.user_type == 2) {
                    $scope.entDisplayFilter($scope.pools);
                  }
                });

                const configPositive = {
                  title: 'Deleted!',
                  text: response.message,
                  icon: 'success',
                  timer: 2000,
                  showConfirmButton: false,
                };
                await Swal.fire(configPositive);
              } else {
                const configError = {
                  title: 'Error',
                  text: response.message,
                  icon: 'error',
                  timer: 2000,
                  showConfirmButton: false,
                };
                await Swal.fire(configError);
              }
            },
            async function (reason) {
              const configError = {
                title: 'Error',
                text: 'Error contacting the server',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configError);
            }
          );
        }
      };

      const { value: returnValue } = await Swal.fire(config);
      returnHandler(returnValue);

      trackingService.trackEvent(trackingService.ADMIN, 'pools', 'delete');
    };

    // Add New Pool Modal
    $scope.openNewPoolModal = function () {
      const modalInstance = $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/views/admin/pools/poolModal.html',
        controller: 'NewPoolModalController',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          enterprise_selection: function () {
            return $scope.entFilter;
          },
        },
      });

      modalInstance.result.then(function () {
        getPools(parseInt($scope.entFilter.enterprise_id) > 0 ? $scope.entFilter.enterprise_id : null);
      });
      trackingService.trackEvent(trackingService.ADMIN, 'pools', 'new');
    }; // end of scope modal

    // EditPool Modal
    $scope.editPoolModal = function (pool_id) {
      poolManagerService.getPool(pool_id).then(function (pool) {
        openEditModal(pool);
      });
      trackingService.trackEvent(trackingService.ADMIN, 'pools', 'edit');
    };

    const openEditModal = function (pool) {
      const modalInstance = $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/views/admin/pools/poolModal.html',
        controller: 'EditPoolModalController',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          passedPool: function () {
            return pool;
          },
          enterprise_selection: function () {
            return $scope.entFilter;
          },
        },
      });

      modalInstance.result.then(function () {
        getPools(parseInt($scope.entFilter.enterprise_id) > 0 ? $scope.entFilter.enterprise_id : null);
      });
    };

    unbindHandler = $rootScope.$on('EnterpriseSelectionChanged', function () {
      $scope.entFilter = EnterpriseFilterService.fetchSelectedEnterprise();
      getPools(parseInt($scope.entFilter.enterprise_id) > 0 ? $scope.entFilter.enterprise_id : null);
    });

    $scope.$on('$destroy', function () {
      unbindHandler();
    });
  },
]);
