
import module from './module';

export default module.factory('enterpriseUtilityService', [

    function () {
        return {
            getEnterpriseKeyLabel(key) {
                switch (key) {
                    case 'return_email':
                        return 'Return Email';
                    case 'web_url':
                        return 'Custom URL';
                }
                return key;
            },
            validateEnterpriseFormData(enterpriseFormData) {
                const formErrors = [];

                const emailRegex =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if (!enterpriseFormData.name) {
                    formErrors.push('Enterprise Name is required.');
                }
                if (!enterpriseFormData.details.billing_email) {
                    formErrors.push('Contact Email is required.');
                }
                if (enterpriseFormData.details.billing_email && !emailRegex.test(enterpriseFormData.details.billing_email)) {
                    formErrors.push('Contact Email is invalid.');
                }
                if (enterpriseFormData.details.bcc_email && !emailRegex.test(enterpriseFormData.details.bcc_email)) {
                    formErrors.push('BCC Email is invalid.');
                }
                if (enterpriseFormData.details.support_email && !emailRegex.test(enterpriseFormData.details.support_email)) {
                    formErrors.push('Support Email is invalid.');
                }
                if (enterpriseFormData.details.footer_email && !emailRegex.test(enterpriseFormData.details.footer_email)) {
                    formErrors.push('Footer Email is invalid.');
                }
                if (!enterpriseFormData.details.time_zone) {
                    formErrors.push('Time Zone is required.');
                }
                return formErrors;
            },
        };
    },
]);
