import module from './module';

export default module.factory('poolManagerService', [
  '$http',
  'BASE_URL',
  '$q',
  'Pool',
  function ($http, BASE_URL, $q, Pool) {
    const poolsManager = {
      _pool: {},
      _poolsArray: [],
      _selectedPool: {},
      _retrieveInstance: function (poolId, poolData) {
        let instance = this._pool[poolId];
        if (instance) {
          instance.setData(poolData);
        } else {
          instance = new Pool(poolData);
          this._pool[poolId] = instance;
          this._poolsArray.push(instance);
        }
        return instance;
      },
      _removeInstance: function (poolId, deferred) {
        const instance = this._pool[poolId];
        if (instance) {
          delete this._pool[poolId];
          const index = this._poolsArray.indexOf(instance);
          if (index !== -1) {
            this._poolsArray.splice(index, 1);
          }
        }
        deferred.resolve(this._poolsArray);
      },
      _search: function (poolId) {
        return this._pool[poolId];
      },
      _load: function (poolId, deferred) {
        const scope = this;
        $http
          .post(BASE_URL + '/get_pool_details', { pool_id: poolId })
          .then(function (res) {
            const response = res.data;
            const pool = scope._retrieveInstance(response.pool_id, response);
            deferred.resolve(pool);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _loadAllPools: function (deferred, payload) {
        const scope = this;
        $http
          .post(BASE_URL + '/get_service_pools', payload)
          .then(function (res) {
            const response = res.data;
            const result = response.pool_list.map((pool) => scope._retrieveInstance(pool.pool_id, pool));
            deferred.resolve(result);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _newPool: function (poolData, deferred) {
        $http
          .post(BASE_URL + '/add_service_pool', poolData)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      /* Public Methods */
      getPool: function (poolId) {
        const deferred = $q.defer();
        this._load(poolId, deferred);
        return deferred.promise;
      },
      getAllPools: function (params) {
        const deferred = $q.defer();
        this._loadAllPools(deferred, params);
        return deferred.promise;
      },
      addNewPool: function (poolData) {
        const deferred = $q.defer();
        this._newPool(poolData, deferred);
        return deferred.promise;
      },
      removeDeletedPool: function (poolId) {
        const deferred = $q.defer();
        this._removeInstance(poolId, deferred);
        return deferred.promise;
      },
    };
    return poolsManager;
  },
]);
