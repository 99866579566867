import module from '../module';

export default module.directive('loginDirective', [
  function () {
    return {
      restrict: 'E',
      templateUrl: 'app/views/login/index.html',
      controller: 'MainController',
    };
  },
]);
