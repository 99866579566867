import moment from 'moment';
import angular from 'angular';
import module from '../module';
import * as enums from '../../enums';

export default module.controller('EditProviderModalController', [
  '$scope',
  '$modalInstance',
  'AlertService',
  'FormatHelperService',
  'selectedProvider',
  'enterprise_selection',
  'providerManagerService',
  'locationManagerService',
  '$timeout',
  'generalDataService',
  'AuthenticationService',
  'branchFilterService',
  'PERMISSIONS',
  'sessionManagerService',
  function (
    $scope,
    $modalInstance,
    AlertService,
    FormatHelperService,
    selectedProvider,
    enterprise_selection,
    providerManagerService,
    locationManagerService,
    $timeout,
    generalDataService,
    AuthenticationService,
    branchFilterService,
    PERMISSIONS,
    sessionManagerService
  ) {
    $scope.submitBtnDisbaled = false;
    $scope.formType = 'edit';
    $scope.provider = angular.copy(selectedProvider);
    $scope.entFilter = enterprise_selection;
    $scope.branchData = { branch_id: selectedProvider.branch_info ? selectedProvider.branch_info.id : null };
    const profession = generalDataService.getKeyProfession();
    $scope.keyProfession = (profession && profession.data_content) || 'Worker';
    $scope.submitBtnLabel = `Update ${$scope.keyProfession}`;
    if ($scope.provider.enterprise_info) {
      $scope.title = `Edit Staff ${$scope.keyProfession}`;
    } else {
      $scope.title = `Edit Contract ${$scope.keyProfession}`;
    }

    // Grabbing list of Job attribs from API
    const getProviderRegAttribs = function () {
      sessionManagerService.getRealmAttribs(enums.Realms.Worker).then(function (result) {
        $scope.formData = result.attrib_list.sort(function (a, b) {
          return a.order - b.order;
        });
        $scope.originalFormData = angular.copy($scope.formData);
      });
    };

    $scope.imagesToRemove = [];

    // Modal controls
    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      const location = $scope.formData.filter(function (obj) {
        return obj.name === 'location';
      })[0];
      if (location && (location.value || {}).name === 'Other' && !location.value.details) {
        AlertService.errorAlert('Missing Location Information');
        $scope.submitBtnDisbaled = false;
      } else {
        updateProvider();
      }
    };

    const updateProvider = function () {
      const providerDetails = {};
      const imagesToRemove = $scope.imagesToRemove.map(function (attrib) {
        attrib.delete_flag = true;
        return attrib;
      });

      $scope.formData = $scope.formData.concat(imagesToRemove);
      const obj = FormatHelperService.format_data($scope.formData);
      const originalUsername = $scope.provider.profile_info.filter(function (obj) {
        return obj.name === 'username';
      })[0];

      for (let i = 0; i < obj.length; i++) {
        if (obj[i].name === 'username') {
          const editUsername = obj.splice([i], 1)[0];
          if (editUsername.value_text != originalUsername.data) {
            providerDetails.new_username = editUsername.value_text;
          }
        }
        if (obj[i].name === 'password') {
          const newPassword = obj.splice([i], 1)[0];
          providerDetails.new_password = newPassword.value_text;
        }
      }
      providerDetails.user_id = selectedProvider.user_id;
      providerDetails.profile_info = obj;
      providerDetails.branch_id = $scope.branchData.branch_id;
      sendToServer(providerDetails);
    };

    const sendToServer = function (providerDetails) {
      providerManagerService.updateProviderAttribs(providerDetails).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
            $modalInstance.close();
          } else {
            AlertService.errorAlert(response.message);
            $scope.submitBtnDisbaled = false;
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    };

    $scope.$on('addAttribInstanceClicked', function (e, field, index, newField) {
      e.stopPropagation();
      const _count = field.instanceCountIndex || 1;
      const _field = angular.copy(
        $scope.originalFormData.filter(function (obj) {
          return obj.attrib_id == field.attrib_id;
        })[0]
      );
      if (newField) {
        _field.record_id = newField.record_id;
      } else {
        _field.record_id = -1;
        _field.addedByUser = true;
      }
      _field.instanceCountIndex = _count + 1;
      _field.showRemoveInstance = true;
      $scope.formData[index].doNotShowAddInstance = true;
      $scope.formData.splice(index + 1, 0, _field); // add after current field
    });

    $scope.$on('removeAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      $scope.formData[index - 1].doNotShowAddInstance = false;
      $scope.formData[index - 1].showRemoveInstance = true;
      const removedElements = $scope.formData.splice(index, 1)[0];
      if (removedElements && !removedElements.addedByUser) {
        $scope.imagesToRemove.push(removedElements);
      }
    });

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.handleBranch = function () {
      const CURRENT_USER = AuthenticationService.getCurrentUser();
      if (CURRENT_USER.user_type === 2 && CURRENT_USER.permissionCheck(PERMISSIONS.BRANCH_TAB)) {
        branchFilterService.fetchBranchesForList().then((branches) => {
          if (branches && branches.length) {
            $scope.branches = branches;
          }
        });
      }
    };
    getProviderRegAttribs();
    $scope.handleBranch();

    $scope.toggleEmailNotifications = function () {
      const email = $scope.provider.profile_info?.find((x) => x.name === 'email_address')?.data;
      if (email) {
        providerManagerService
          .resubscribeToEmails(email)
          .then(() => {})
          .catch(() => {
            AlertService.errorAlert('Could not resubscribe user to emails!');
            $scope.provider.subscribedToEmail = false;
          });
      }
    };

    $scope.profileCreatedDate = function () {
      if ($scope.provider.created_at) {
        return moment($scope.provider.created_at).format('MM-DD-YYYY HH:mm zz');
      }
    };

    $scope.profileCreatedUser = function () {
      return $scope.provider.created_by;
    };

    $scope.profileModifiedDate = function () {
      if ($scope.provider.updated_at) {
        return moment($scope.provider.updated_at).format('MM-DD-YYYY HH:mm zz');
      }
    };

    $scope.profileModifiedUser = function () {
      return $scope.provider.updated_by;
    };
  },
]);
