import { Component, ChangeDetectionStrategy, Input, Inject, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../../../shared/services';
import { Worker } from '../../../shared/models/worker';
import { PermissionsService } from '../../../shared/services/permissions.service';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  templateUrl: './worker-profile.page.html',
  styleUrls: ['./worker-profile.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-worker-profile-page',
})
export class WorkerProfilePage {
  @Input() worker: Worker;
  @Output() workerUpdated = new EventEmitter<any>();

  public keyAttribute = JSON.parse(localStorage.getItem('ngStorage-keyAttribute') || '{}');

  public fields = [
    'address_line_1',
    'address_line_2',
    'city',
    'state',
    'country',
    'zip_code',
    'username',
    'first_name',
    'last_name',
    'biography',
    'mobile_phone',
    'email_address',
    'ext_employee_id',
    'branch_name',
    this.keyAttribute.data_content,
    'provider_vetting_status',
    'radius',
    'login_status',
    'password',
    'pref_contact',
  ];

  public baseFields = ['username', 'mobile_phone', 'email_address', 'ext_employee_id', 'branch_name'];

  public rightFields = ['radius', 'login_status', 'pref_contact'];

  public canEdit = this.authenticationService.getPermission(this.permissionService.PERMISSIONS.EDIT_PROVIDER);

  constructor(
    @Inject('ModalService') private modalService,
    @Inject('ProviderManagerService') private providerManagerService,
    @Inject('AlertService') private alertService,
    private permissionService: PermissionsService,
    private authenticationService: AuthenticationService
  ) {}

  public getBaseFieldList() {
    return this.worker.profile_info.filter((x) => this.baseFields.indexOf(x.name) > -1);
  }

  public getRightFieldList() {
    return this.worker.profile_info.filter((x) => this.rightFields.indexOf(x.name) > -1);
  }

  public getFieldList() {
    const result = this.worker.profile_info.filter(
      (x) => x.display && (x.display_value || x.data) && this.fields.indexOf(x.name) === -1
    );
    result.sort((a, b) => a.order - b.order);
    return _.uniqBy(result, 'attrib_id');
  }

  public editWorker() {
    const modalInstance = this.modalService.open({
      animation: true,
      templateUrl: 'app/views/admin/provider/providerRegistrationModal.html',
      controller: 'EditProviderModalController',
      keyboard: false,
      size: 'lg',
      backdrop: 'static',
      resolve: {
        selectedProvider: () => {
          return this.worker;
        },
        enterprise_selection: () => {
          return null;
        },
      },
    });

    modalInstance.result.then(() => this.workerUpdated.emit());
  }

  public updateStatus(payload) {
    this.providerManagerService.updateProviderAttribs(payload).then(
      (response) => {
        if (response.success) {
          this.alertService.successAlert(response.message);
          this.workerUpdated.emit();
        } else {
          this.alertService.errorAlert(response.message);
        }
      },
      (reason) => {
        this.alertService.serverRequestErrorAlert(reason);
      }
    );
  }

  public loggedInStatus = function (status_code, vetting_code) {
    let loggedIn, vetting;
    switch (status_code) {
      case 1:
        loggedIn = 'logged-in-state';
        break;
      case 0:
        loggedIn = 'logged-out-state';
        break;
      default:
        loggedIn = 'no-logged-in-state';
        break;
    }

    switch (vetting_code) {
      case 0:
      case 1:
      case 2:
        vetting = 'vetting-approved-state';
        break;
      case 100:
        vetting = 'vetting-waiting-state';
        break;
      case 101:
        vetting = 'vetting-denied-state';
        break;
      default:
        vetting = 'vetting-no-state';
        break;
    }

    return `${loggedIn} ${vetting}`;
  };

  public imageClick(src) {
    this.modalService.open({
      animation: true,
      template: `
    <div style="text-align: center; padding: 20px;">
      <div>
        <button class="close" ng-click="cancelSmallModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <img ng-src="${src}" style="max-width: 100%; max-height: 100%;" />
    </div> `,
      keyboard: false,
      size: 'lg',
      controller: 'providerImageModalController',
    });
  }

  public profileCreatedDate() {
    if (this.worker.created_at) {
      return moment(this.worker.created_at).format('MM-DD-YYYY HH:mm zz');
    }
  }

  public profileCreatedUser() {
    return this.worker.created_by;
  }

  public profileModifiedDate() {
    if (this.worker.updated_at) {
      return moment(this.worker.updated_at).format('MM-DD-YYYY HH:mm zz');
    }
  }

  public profileModifiedUser() {
    return this.worker.updated_by;
  }
}
