import { Injectable, Injector } from '@angular/core';

@Injectable()
export class BranchFilterWrapperService {

  constructor(
    private injector: Injector
  ) { }

  public setSelectedBranch(branchId, enterpriseOrigin?: boolean) {
    let svc = this.injector.get('branchFilterService');
    svc.setSelectedBranch(branchId, enterpriseOrigin);
  }

  public getSelectedBranch() {
    let svc = this.injector.get('branchFilterService');
    return svc.getSelectedBranch();
  }

  public fetchBranches() {
    let svc = this.injector.get('branchFilterService');
    return svc.fetchBranches();
  }
}
