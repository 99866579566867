import module from './module';
import moment from 'moment-timezone';

export default module.service('timeZoneStore', function () {
  let timeZone;

  return {
    isValidDate,
    calcTime,
    getTimeZoneOffset,
    getLongName,
    getAbbr,
    load,
  };

  function getAbbr(timestamp) {
    return moment.tz(timestamp, timeZone.timeZoneId).format('z');
  }

  function getLongName() {
    return timeZone.timeZoneId;
  }

  function getTimeZoneOffset() {
    return timeZone ? parseInt(timeZone.rawOffset) + parseInt(timeZone.dstOffset) : 0;
  }

  function isValidDate(date) {
    return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
  }

  function calcTime(dateParam) {
    // convert to msec
    // add local time zone offset
    // get UTC time in msec
    let date;

    if (typeof dateParam === 'string') {
      date = new Date(dateParam);
    }

    if (this.isValidDate(dateParam)) {
      date = dateParam;
    } else if (dateParam._d && this.isValidDate(dateParam._d)) {
      date = dateParam._d;
    }

    if (!date) {
      return;
    }

    // removing local time zone offset and adding the selected location's timezone offset
    // utc = (date.getTime() / 1000 - (date.getTimezoneOffset() * 60)) - this.getTimeZoneOffset();
    const utc = date.getTime() - date.getTimezoneOffset() * 60 * 1000 - this.getTimeZoneOffset() * 1000;

    // create new Date object for different city
    // using supplied offset
    const nd = new Date(utc);

    // converting local time to timeZone's timezone
    // var nd = new Date((utc - this.getTimeZoneOffset()) * 1000);

    // return time as a string;
    return nd;
  }

  function load(data) {
    timeZone = data;
  }
});
