import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { BirdsEyeModel, BirdsEyeSession } from '../../../shared/models/birds-eye';
import { DAYS_OF_WEEK } from 'angular-calendar';
import * as moment from 'moment';

@Component({
  templateUrl: './birds-eye-monthly.component.html',
  styleUrls: ['./birds-eye-monthly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-birds-eye-monthly',
  encapsulation: ViewEncapsulation.None,
})
export class BirdsEyeMonthlyComponent implements OnInit {
  @Input() model: BirdsEyeModel;
  @Input() mode: 'schedule' | 'offer';
  @Input() sessions: { [key: number]: number[] } = {};
  @Output() modeChange = new EventEmitter<'schedule' | 'offer'>();

  public viewDate: Date = new Date();
  public weekStartsOn = DAYS_OF_WEEK.MONDAY;

  public events = [];

  ngOnInit() {
    this.events = this.model.session_list.map((s) => ({
      ...s,
      unfilled: !s.provider_ids.length,
      start: new Date(s.start_date),
      end: new Date(`${s.start_date} ${s.start_time}`),
      title: s.key_attribute,
    }));

    this.viewDate = this.events.length ? new Date(this.events[0].start) : new Date();
  }

  public getWorkers(session_id: number) {
    const sessions = this.sessions[session_id];
    if (sessions && sessions.length) {
      return this.model.provider_list.filter((x) => sessions.indexOf(x.provider_id) > -1);
    }
  }

  public getSessionInfo(session: BirdsEyeSession) {
    const startTime = moment(`${session.start_date} ${session.start_time}`, 'YYYY-MM-DD hh:mm').format('h');
    const endTime = moment(`${session.start_date} ${session.end_time}`, 'YYYY-MM-DD hh:mm').format('hA');
    const timeFrame = `${startTime}-${endTime}`;

    return `${timeFrame} ${session.key_attribute}`;
  }
}
