import module from './module';

export default module.factory('trackingService', [
  'Analytics',
  function (Analytics) {
    function _trackEvent(category, event, label) {
      Analytics.trackEvent(category, event, label);
    }

    return {
      trackEvent: _trackEvent,
      CALENDAR: 'CALENDAR',
      ADMIN: 'ADMIN',
      MODALS: 'MODALS',
    };
  },
]);
