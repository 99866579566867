import module from '../module';
export default module.controller('ModalPickListControler', [
  '$scope',
  '$modalInstance',
  'pickList',
  'qualifiedWorkers',
  'offerNumber',
  function ($scope, $modalInstance, pickList, qualifiedWorkers, offerNumber) {
    setTimeout(() => {
      $scope.jobType.pickListRequested = true;
      if (!qualifiedWorkers && !pickList) {
        $scope.showPickList();
      } else {
        $scope.qualifiedWorkers = qualifiedWorkers;
        $scope.pickList = pickList;
      }
    }, 0);

    $scope.offerNumber = offerNumber;
    $scope.offerWarning = `You may not submit a list less than ${offerNumber} since that is remaining in your Order.`;
    $scope.ok = function () {
      $modalInstance.close({
        pickList: $scope.pickList,
        qualifiedWorkers: $scope.qualifiedWorkers,
      });
    };

    $scope.hasEnoughOffers = function () {
      if ($scope.jobType.selection === 'escalation') {
        if (!$scope.pickList) {
          return false;
        }
      }
      return true;
    };

    $scope.hasSchedules = function () {
      return (
        $scope.jobType.selection === 'assign' &&
        $scope.qualifiedWorkers &&
        $scope.qualifiedWorkers.filter((x) => x.selected).length
      );
    };

    $scope.canSubmit = function () {
      if ($scope.jobType.selection === 'escalation' || ($scope.applyAttrib && $scope.applyAttrib.value)) {
        return $scope.hasEnoughOffers();
      } else {
        return $scope.hasSchedules();
      }
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  },
]);
