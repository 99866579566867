import module from './module';
import angular from 'angular';

export default module.directive('dirCustomerReview', function () {
  return {
    restrict: 'E',
    scope: {
      request: '=',
      reviewAttribs: '=',
      modal: '=',
    },
    controller: [
      '$scope',
      '$timeout',
      function ($scope, $timeout) {
        $scope.issueModel = $scope.reviewAttribs.filter(function (obj) {
          return obj.name === 'requester_issues';
        })[0];
        $scope.late_arrival = $scope.reviewAttribs.filter(function (obj) {
          return obj.name === 'requester_issue_late_arrival';
        })[0];
        $scope.comments = $scope.reviewAttribs.filter(function (obj) {
          return obj.name === 'requester_comments';
        })[0];
        $scope.service_review = $scope.reviewAttribs.filter(function (obj) {
          return obj.name === 'service_review';
        })[0];

        const extraAttribs = $scope.reviewAttribs.filter((obj) => {
          return (
            obj !== $scope.issueModel &&
            obj !== $scope.late_arrival &&
            obj !== $scope.comments &&
            obj !== $scope.service_review
          );
        });

        // Rating System for completed jobs
        $scope.request.review = {
          rate: 0,
          max: $scope.service_review.max_amount,
          isReadonly: false,
          late: false,
          customerComments: '',
          minutesLate: '',
          issues: $scope.issueModel,
          late_arrival: $scope.late_arrival,
          comments: $scope.comments,
          service_review: $scope.service_review,
          attrib_list: $scope.reviewAttribs,
          extraAttribs,
        };

        $scope.ratingStates = [{ stateOn: 'glyphicon-star', stateOff: 'glyphicon-star-empty' }];

        // open issue
        $scope.status = {
          open: false,
        };

        $scope.request.openIssueResults = [];

        $scope.$watch(
          'issueModel.choice_list',
          function (newValue, oldValue, $scope) {
            angular.forEach(newValue, function (obj) {
              if (obj.name === 'Late' && obj.selected == true) {
                $scope.request.review.late = true;
              } else if (obj.name === 'Late' && obj.selected == false) {
                $scope.request.review.late = false;
                $scope.request.review.minutesLate = null;
              }
            });
          },
          true
        );
      },
    ],
    templateUrl: 'app/views/templates/customer_review.html',
  };
});
