import module from './module';
import moment from 'moment';

export default module.factory('calendarProviderManagerService', [
  '$http',
  'BASE_URL',
  '$q',
  'CalendarProvider',
  'TimeFormatService',
  'ForceFilterService',
  function ($http, BASE_URL, $q, CalendarProvider, TimeFormatService, ForceFilterService) {
    const calendarProvidersManager = {
      _setSessionRange(session_list) {
        return session_list.map((session) => {
          const tempStart = moment.utc(session.start_time).local();
          session.start = tempStart.format(TimeFormatService.format('fullDateTimeSecs'));
          session.end = tempStart.add(session.duration, 'm').format(TimeFormatService.format('fullDateTimeSecs'));
          return session;
        });
      },

      _loadAllCalendarProviders: function (start, end, deferred, entId, range) {
        const _startDateTime = moment(start, TimeFormatService.format('api'))
          .subtract(range === 'day' ? 1 : 0, 'd')
          .utc()
          .format(TimeFormatService.format('api'));
        const _endDateTime = moment(end, TimeFormatService.format('api')).utc().format(TimeFormatService.format('api'));
        const _requestObj = {
          enterprise_id: entId,
          start_date: _startDateTime,
          end_date: _endDateTime,
          user_filter_attribute_id_choice_id_tuples: ForceFilterService.getFilter(),
          user_filter_location_id: ForceFilterService.getLocationId(),
        };

        $http
          .post(BASE_URL + '/get_cal_providers_jobs', _requestObj)
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              const sessionList = calendarProvidersManager._setSessionRange(response.session_list);
              const result = response.provider_list.map(
                (data) => new CalendarProvider(data, start, end, response.job_list, sessionList)
              );
              deferred.resolve(result);
            } else {
              deferred.reject();
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },
      /* Public Methods */
      getAllCalProviders: function (start, end, entId, range) {
        const deferred = $q.defer();
        this._loadAllCalendarProviders(start, end, deferred, entId, range);
        return deferred.promise;
      },
    };
    return calendarProvidersManager;
  },
]);
