import module from './module';

export default module
  .directive('dirTimePickerPopupWithArrows', [
    function () {
      return {
        restrict: 'E',
        scope: {
          type: '@',
          session: '=',
          close: '&',
          timePickerConfig: '='
        },
        templateUrl: 'app/views/templates/time_picker_popup_with_arrows.html',
        link: function (scope, element, attrs) {
          scope.closePopup = function (isOk) {
            scope.close({ type: scope.type, session: scope.session, isOk: isOk });
          };
        }
      };
    }
  ]);
