import { Component, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store';
import { ToggleWidget, ExportToCsv } from '../../store/actions';
import { Spinner } from 'spin.js';

@Component({
  template: '',
})
export abstract class FinancialBaseWidget {
  public chartType = '';
  public requests: any[];
  public data: any[] = [];
  public count: number;
  public isLoading: boolean;
  public timeRange: any;
  public showChart = false;
  public chartSize = [400, 200];
  public widgetData: any;
  public customTitle: string;
  public titleValue: string;
  public titleText: string;
  private subscriptions: any[] = [];
  private dragMode: boolean;
  @ViewChild('spinHolder', { static: false }) spinHolder;
  constructor(protected store: Store<fromRoot.State>) {}

  startSpinner() {
    if (
      this.spinHolder &&
      this.spinHolder.nativeElement &&
      this.spinHolder.nativeElement.children &&
      this.spinHolder.nativeElement.children.length === 0
    ) {
      let spinner = new Spinner().spin();
      this.spinHolder.nativeElement.appendChild(spinner.el);
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(fromRoot.getVerifyReport).subscribe((req) => {
        this.requests = req;
        this.loadData(this.requests);
      })
    );

    this.subscriptions.push(
      this.store.select(fromRoot.getIsLoading).subscribe((isLoading) => (this.isLoading = isLoading))
    );

    this.subscriptions.push(
      this.store.select(fromRoot.getDragMode).subscribe((dragMode) => (this.dragMode = dragMode))
    );
  }

  loadData(list: any) {
    this.startSpinner();
    this.loadDataTemplate(list);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  toggleChart() {
    this.showChart = !this.showChart;
  }

  closeWidget() {
    this.store.dispatch(new ToggleWidget(this.widgetData, true));
  }

  exportToCSV() {
    this.store.dispatch(
      new ExportToCsv(this.data, {
        title: this.widgetData.title,
        name: this.widgetData.name,
        mainData: this.titleValue,
        mainText: this.titleText,
      })
    );
  }

  formatNumber(number) {
    let parsedValue = number;
    if (typeof number === 'string') {
      parsedValue = parseFloat(number.replace(/[^\d\.]/, ''));
    }

    return `$${Math.floor(parsedValue)}`;
  }

  abstract loadDataTemplate(list: any);
}
