import * as moment from 'moment';
import * as angular from 'angular';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import Swal from 'sweetalert2';
import { EnumsService, GeneralDataService } from '../../../shared/services';
import { map } from 'rxjs/operators';

@Component({
  templateUrl: './mass-cancel.component.html',
  styleUrls: ['./mass-cancel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-mass-cancel',
})
export class MassCancelComponent implements OnInit {
  @Input() date: Date;
  @Input() sessionData: any[];
  @Input() closeCallback: any;
  @Input() filterType: 'Providers' | 'Sessions';

  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;
  @ViewChildren('workerboxes') workerboxes: QueryList<ElementRef>;

  public displayData = [];
  public selection = {};
  public keyAttribute = JSON.parse(localStorage.getItem('ngStorage-keyAttribute') || '{}');
  public jobMode = false;
  public cancelReason = '';
  public allSessions = [];
  public commentAttrib$ = new BehaviorSubject<any>(null);

  private cancelStates = ['pending', 'committed'];

  constructor(
    @Inject('sessionManagerService') private sessionManagerService,
    private generalDataService: GeneralDataService,
    private enumsService: EnumsService
  ) {}

  ngOnInit() {
    this.generalDataService
      .getRealmAttribs(this.enumsService.Realms.AdminCancel)
      .pipe(map((attribs?: any[]) => (attribs && attribs.length ? attribs[0] : null)))
      .subscribe((res) => this.commentAttrib$.next(res));

    if (this.filterType === 'Providers') {
      this.displayData = this.sessionData
        .filter((p) => p.session_list.length && p.session_list.some((session) => this.isCancelableSession(session)))
        .map((p) => ({
          name: p.name,
          profile_picture: p.profile_picture,
          sessionList: this.mapSessionList(p.session_list),
        }));
    } else {
      const providers = {};
      this.sessionData.forEach((job) => {
        job.provider_list.forEach((prov) => {
          const sessions = prov.session_ids
            .map((id) => job.session_list.find((s) => s.session_id == id))
            .filter((x) => !!x);
          if (providers[prov.name]) {
            providers[prov.name] = [...providers[prov.name], ...sessions];
          } else {
            providers[prov.name] = [...sessions];
          }
        });
      });

      this.displayData = Object.keys(providers).map((key) => ({
        name: key,
        sessionList: this.mapSessionList(providers[key]),
      }));
    }
    this.displayData.sort((a, b) => a.name.localeCompare(b.name));
  }

  selectChanged(event, id) {
    this.selection = { ...this.selection, [id]: event.target.checked };
  }

  close() {
    this.closeCallback && this.closeCallback.doClose();
  }

  mapSessionList(list: any[]) {
    return list
      .filter((session) => this.isCancelableSession(session))
      .map((session) => {
        session.job_id = session.bundled_job_id ? session.bundled_job_id : session.job_id;
        this.allSessions.push(session);
        return {
          ...session,
          location: session.attrib_list.find((x) => x.type === 'location')?.value_location[0]?.name,
          keyAttribute: this.generalDataService.readKeyAttributeValue(
            session.attrib_list,
            this.keyAttribute.data_content
          ),
          id: session.session_id,
          start: moment.utc(session.start_time).local().format('L hh:mm A'),
        };
      });
  }

  isCancelableSession(session) {
    return this.cancelStates.indexOf(session.state.name.toLowerCase()) > -1;
  }

  toggleAll(event) {
    this.checkboxes.forEach((cb) => {
      cb.nativeElement.checked = event.target.checked;
      this.selection[cb.nativeElement.id.replace('cb', '')] = event.target.checked;
    });

    this.workerboxes.forEach((cb) => {
      cb.nativeElement.checked = event.target.checked;
    });
  }

  toggleWorker(event, worker) {
    const sessionIds = worker.sessionList.map((session) => session.id);
    this.checkboxes
      .filter((cb) => {
        return sessionIds.indexOf(parseInt(cb.nativeElement.id.replace('cb', ''))) > -1;
      })
      .forEach((cb) => {
        cb.nativeElement.checked = event.target.checked;
        this.selection[cb.nativeElement.id.replace('cb', '')] = event.target.checked;
      });
  }

  doCancel() {
    if (
      this.commentAttrib$.value &&
      this.commentAttrib$.value.type_flags &&
      this.commentAttrib$.value.type_flags.required &&
      !this.cancelReason
    ) {
      return Swal.fire({
        title: 'Error',
        text: 'Cancel reason is required!',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
      });
    }

    const sessionsToRemove = Object.keys(this.selection)
      .filter((x) => !!this.selection[x])
      .filter((sessionId) => {
        if (this.jobMode) {
          const storedSession = this.allSessions.find((x) => x.session_id == sessionId);
          return storedSession && !(storedSession.job_id && storedSession.job_instance);
        } else {
          return true;
        }
      })
      .filter((x) => !!x)
      .map((x) => x)
      .map((sessionId) =>
        of(
          this.sessionManagerService.endSessionRequest({
            request_id: sessionId,
            end_type: 3,
            remove: false,
            attrib_list: [
              {
                attrib_id: this.commentAttrib$.value.attrib_id, //id for admin_cancel_comments attrib
                name: this.commentAttrib$.value.name,
                [this.commentAttrib$.value?.choice_list?.length ? 'value_single_list' : 'value_text']:
                  this.cancelReason,
              },
            ],
          })
        )
      );

    let jobsToRemove = [];

    if (this.jobMode) {
      jobsToRemove = Object.keys(this.selection)
        .filter((x) => !!this.selection[x])
        .map((sessionId) => {
          return this.allSessions.find((x) => x.session_id == sessionId);
        })
        .filter((session) => session && session.job_id && session.job_instance)
        .filter(
          (job, index, self) =>
            index === self.findIndex((other) => other.job_id === job.job_id && other.job_instance === job.job_instance)
        )
        .map((session) =>
          of(
            this.sessionManagerService.endJobRequest({
              job_id: session.job_id,
              job_instance: session.job_instance,
              remove: false,
              remove_only: false,
              user_id: undefined,
              attrib_list: [
                {
                  attrib_id: 155, //id for admin_cancel_comments attrib
                  name: 'admin_cancel_comments',
                  value_text: this.cancelReason,
                },
              ],
            })
          )
        );
    }

    combineLatest([...sessionsToRemove, ...jobsToRemove]).subscribe(() => {
      return Swal.fire({
        title: 'Success',
        text: 'Submit succesful.',
        icon: 'success',
        timer: 2000,
        showConfirmButton: false,
      }).then(() => {
        this.closeCallback && this.closeCallback.doClose();
      });
    });
  }
}

angular.module('ServetureWebApp').directive('massCancel', downgradeComponent({ component: MassCancelComponent }));
