import angular from 'angular';
import module from '../module';
import * as enums from '../../enums';

export default module.controller('EditPriceModalController', [
  '$scope',
  '$modalInstance',
  'priceManagerService',
  'entFilter',
  'passedPrice',
  'FormatHelperService',
  'providerManagerService',
  'AlertService',
  '$localStorage',
  'SplitPriceService',
  'generalDataService',
  'AuthenticationService',
  'PERMISSIONS',
  'sessionManagerService',
  function (
    $scope,
    $modalInstance,
    priceManagerService,
    entFilter,
    passedPrice,
    FormatHelperService,
    providerManagerService,
    AlertService,
    $localStorage,
    SplitPriceService,
    generalDataService,
    AuthenticationService,
    PERMISSIONS,
    sessionManagerService
  ) {
    $scope.keyProfession = generalDataService.getKeyProfession();
    $scope.keySession = generalDataService.getKeySession();
    const localPrice = angular.copy(passedPrice);
    $scope.title = 'Edit Price';
    $scope.currencySymbol = AuthenticationService.getCurrencySymbol();
    $scope.entFilter = entFilter;
    $scope.providers = [];
    $scope.submitBtnDisbaled = false;
    $scope.filterStaff = true;
    $scope.submitBtnText = 'Update  Price';
    $scope.priceStructures = SplitPriceService.priceStructures;
    $scope.hasAttribPermission = AuthenticationService.getCurrentUser().permissionCheck(PERMISSIONS.PRICE_ATTRIBS);

    $scope.getRealmObject = function () {
      const result = {
        realm_list: [
          {
            realm: 9,
          },
        ],
      };
      if ($scope.entFilter && $scope.entFilter.enterprise_id !== -1) {
        result.enterprise_id = $scope.entFilter.enterprise_id;
      }

      return result;
    };

    const getPricingAttribs = function () {
      priceManagerService.getPriceAttribs($scope.getRealmObject()).then(
        function (response) {
          if (response.success) {
            $scope.attrib_list = response.attrib_list.sort(function (a, b) {
              return a.order - b.order;
            });
            $scope.rate_unit_list = response.rate_unit_list;
            $scope.currency_list = response.currency_list;
            $scope.trigger_list = response.trigger_list;
            $scope.billing_rate_attrib_id_choices = response.billing_rate_attrib_id_choices;
          }
        },
        function () {
          AlertService.serverRequestErrorAlert('Unable to load pricing attribute list');
          $modalInstance.close();
        }
      );
    };

    priceManagerService.getPrice(localPrice.price_structure_id).then(
      function (priceToEdit) {
        $scope.price = priceToEdit;
        $scope.formData = angular.copy($scope.price);
        $scope.provider_list = $scope.price.provider_list;
        $scope.$broadcast('Provider_List.Loaded');
        getPricingAttribs();
      },
      function (reason) {
        AlertService.serverRequestErrorAlert(reason);
        $modalInstance.close();
      }
    );

    $scope.addTrigger = function () {
      $scope.formData.trigger_list.push({ minimum: false, travel: false, billing_rate_unit_id: 2, split_flag: true });
    };
    $scope.removeTrigger = function () {
      if ($scope.formData.trigger_list.length < 2) {
        AlertService.warningAlert('At least one trigger is required');
      } else {
        $scope.formData.trigger_list.pop();
      }
    };

    $scope.setTravelDefaults = function (trigger) {
      trigger.travel_rate_unit_id = 2;
      trigger.travel_rate = '';
    };

    // Modal controls
    $scope.ok = function () {
      if (!$scope.validatePriceStructure()) {
        return;
      }
      $scope.submitBtnDisbaled = true;
      formatData();
    };

    const formatData = function () {
      const formatedAttribList = FormatHelperService.format_data($scope.attrib_list);
      const originalAttribList = FormatHelperService.format_data($scope.formData.attrib_list);
      if (formatedAttribList.length > 0) {
        $scope.formData.attrib_list = formatedAttribList;
      }
      if (($scope.selectedProviders || {}).length > 0) {
        // return new array of just choice_id objs
        $scope.formData.provider_list = $scope.selectedProviders.map(function (obj) {
          return { provider_id: obj.provider_id };
        });
      }

      angular.forEach($scope.formData.trigger_list, function (trigger) {
        if (trigger.travel == false) {
          delete trigger.travel_rate_unit_id;
          delete trigger.travel_rate;
        }
        if (trigger.minimum == false) {
          delete trigger.minimum_rate;
        }
      });

      sendToServer($scope.formData);
    };

    const sendToServer = function (priceObj) {
      priceManagerService.updatePrice(priceObj).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
          } else {
            AlertService.errorAlert(response.message);
          }
          $modalInstance.close();
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    // Override multiselect label
    $scope.translation = {
      nothingSelected: 'All',
      selectAll: 'Select All',
      selectNone: 'Select None',
      reset: 'Done',
    };

    $scope.portionTypeChanged = function (value) {
      const currentValue = $scope.formData[value.portionType];
      if (currentValue === 'Dollar') {
        delete $scope.formData[value.percentOf];
        $scope.formData[value.rateUnit] = 2; // 2 is Hour
      } else if (currentValue === 'Percent') {
        delete $scope.formData[value.rateUnit];
        $scope.formData[value.percentOf] = 'Total';
      } else {
        delete $scope.formData[value.rateUnit];
        delete $scope.formData[value.percentOf];
      }
    };

    $scope.validatePriceStructure = function () {
      if ($scope.formData.price_structure_split) {
        if (SplitPriceService.validateMinPrice($scope.formData) === false) {
          AlertService.warningAlert('Amount is higher then bill rate.');
          return false;
        }
        if (SplitPriceService.validateTotalPercentages($scope.formData) === false) {
          AlertService.warningAlert('Your split percentage does not add up to 100%.');
          return false;
        }
        if (SplitPriceService.validateZeroPrice($scope.formData) === false) {
          AlertService.warningAlert('0 is not allowed for prices.');
          return false;
        }
        SplitPriceService.removeOrphanedData($scope.formData);
      }
      return true;
    };

    $scope.billingRateChange = function (trigger) {
      if (trigger.billing_rate <= 0) {
        trigger.billing_rate = 1;
      }
    };
  },
]);
