import module from './module';

export default module
  .directive('dirTooltip', ['$interval', 'dateFilter', function ($interval, dateFilter) {

    function link(scope, element, attrs) {

      console.log(scope);
      let errorMessage = 'Error';
      if (scope.session && scope.session.error && scope.session.error.errors && scope.session.error.errors.length) {
        errorMessage = scope.session.error.errors[0].error;
      }
      attrs.tooltip = errorMessage;

      // scope.session.error.errors.forEach((value) => {
      //   if (errorMessage.length) {
      //     errorMessage += `${value.field}: ${value.error}<br/>`;
      //   } else {
      //     errorMessage = `${value.field}: ${value.error}`;
      //   }
      // })
      // attrs.tooltipHtmlUnsafe = errorMessage;

      // HTML attr
      //  tooltip-html-unsafe="Line 1<br />Line 2<br />Line 3"
    }

    return {
      link
    };
  }]);
