import module from '../module';
import moment from 'moment';
export default module.controller('ResendModalController', [
  '$scope',
  '$controller',
  '$modalInstance',
  'FormatHelperService',
  'AlertService',
  'sessionManagerService',
  'enterprise_selection',
  'TimeFormatService',
  'trackingService',
  function (
    $scope,
    $controller,
    $modalInstance,
    FormatHelperService,
    AlertService,
    sessionManagerService,
    enterprise_selection,
    TimeFormatService,
    trackingService
  ) {
    $controller('BaseEditController', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      FormatHelperService: FormatHelperService,
      AlertService: AlertService,
      sessionManagerService: sessionManagerService,
      enterprise_selection: enterprise_selection,
      trackingService: trackingService,
    });

    $scope.title = 'Resend';
    $scope.type = 'resend';

    $scope.formatRequest = function () {
      // format form data for server
      let provider_list = [];
      if ($scope.pickListValid) {
        provider_list = $scope.pickList
          .filter(function (item) {
            return item.selected;
          })
          .map(function (item) {
            return item.provider_id;
          });
      }
      const attrib_list = FormatHelperService.format_data($scope.formData);
      const service_request_response = {
        request_id: $scope.request.request_id,
        attrib_list: attrib_list,
        provider_list: provider_list,
      };
      $scope.submitToServer(service_request_response);
    };

    $scope.preparePickListPayload = function () {
      return {
        request_id: $scope.request.request_id,
        type: $scope.request.type,
        attrib_list: FormatHelperService.format_data($scope.formData),
      };
    };

    $scope.isDateInFuture = function (service_request_response) {
      if ($scope.request.type === 2) {
        return true;
      }
      if (service_request_response && service_request_response.attrib_list) {
        const item = service_request_response.attrib_list.find(function (item) {
          return item.name === 'date_time';
        });
        if (item) {
          return moment(item.value_text, TimeFormatService.format('fullDateTimeNoSeparator')).isAfter(moment());
        }
      }
      return false;
    };

    // server call
    $scope.submitToServer = function (service_request_response) {
      if ($scope.isDateInFuture(service_request_response) === false) {
        AlertService.errorAlert(
          'You are attempting to add this session for a past starting date/time. Please adjust it to a future date/time to proceed.',
          5000
        );
        $scope.submitBtnDisbaled = false;
        return;
      }
      sessionManagerService.changeAndRequeueRequest(service_request_response).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
          } else {
            AlertService.errorAlert(response.message);
          }
          $modalInstance.close();
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    };

    function populateAttribs(source, target) {
      switch (source.type) {
        case 'single_list':
          target.value = source.value_list.filter(function (obj) {
            return obj.selected == true;
          })[0];
          break;
        case 'multi_list':
          target.value = source.value_list;
          target.choice_list = source.value_list;
          break;
        case 'text':
          target.value = source.value_text;
          break;
        case 'count':
          target.value = source.value_count;
          break;
        case 'location':
          if (source.value_location) {
            locationManagerService
              .getAllLocations(enterprise_selection && enterprise_selection.enterprise_id)
              .then(function (locationsObject) {
                var locations = locationsObject[enterprise_selection.enterprise_id];
                var loc = locations.find(function (location) {
                  return location.location_id === source.value_location.location_id;
                });
                target.value = loc;
                $scope.selectedLocation = loc;
              });
          }
          break;
        case 'place':
          $scope.$watch('selectedLocation', function (val) {
            if (val) {
              var selected = source.value_place.filter(function (obj) {
                return obj.selected == true;
              })[0];
              var selectedPlace = $scope.selectedLocation.place_list.find(function (place) {
                return place.place_id === selected.place_id;
              });
              target.value = selectedPlace;
            }
          });
          break;
      }
    }

    $scope.attribsLoadedCallback = function (attribs) {
      if (passedSession && passedSession.start) {
        sessionManagerService.setSession(passedSession.session_id).then(function (session) {
          attribs.forEach(function (origAttrib) {
            var loadedAttrib = session.attrib_list.find(function (attrib) {
              return attrib.attrib_id === origAttrib.attrib_id;
            });
            if (loadedAttrib) {
              populateAttribs(loadedAttrib, origAttrib);
            }
          });
        });

        var dateAttrib = attribs.find(function (attrib) {
          return attrib.attrib_id === 4; // 4 is date and time
        });
        if (dateAttrib) {
          dateAttrib.value = moment(passedSession.start);
        }
      }
    };

    $scope.loadRequest();
  },
]);
