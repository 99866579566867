import angular from 'angular';
import module from '../module';
import moment from 'moment';

export default module.controller('EndAssignmentModalController', [
  '$scope',
  '$modalInstance',
  'AlertService',
  'passedSession',
  'sessionManagerService',
  'generalDataService',
  'removeSessionService',
  function (
    $scope,
    $modalInstance,
    AlertService,
    passedSession,
    sessionManagerService,
    generalDataService,
    removeSessionService
  ) {
    $scope.submitBtnDisbaled = false;
    $scope.title = 'End Assignment';
    $scope.keySession = generalDataService.getKeySession();
    const _passedSession = angular.copy(passedSession);
    $scope.newDateObj = {
      startDate: moment(),
    };

    sessionManagerService.setSession(_passedSession.session_id || _passedSession.request_id).then(
      function (session) {
        $scope.session = session;
        $scope.newDateObj.startDate = moment(session.request_date);
      },
      function (reason) {
        AlertService.serverRequestErrorAlert(reason);
        $modalInstance.dismiss('cancel');
      }
    );

    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;

      removeSessionService
        .endAssignment(
          $scope.session.job_instance,
          $scope.session.job_id,
          moment($scope.newDateObj.startDate),
          $scope.session.serv_provider_id
        )
        .then(
          (success) => {
            console.log(success);
            if (success) {
              $modalInstance.dismiss();
            }
            $scope.submitBtnDisbaled = false;
          },
          (error) => {
            AlertService.errorAlert(error);
          }
        );
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    // Calendar date time selection options and controls
    $scope.startDate = { opened: false };
    $scope.openStartDate = function () {
      $scope.startDate.opened = true;
    };
    // Start time selection
    $scope.hstep = 1;
    $scope.mstep = 5;
    $scope.dateAttrib = {
      type: 'date',
      editType: 'edit',
      name: 'loginBackground',
      display_name: 'Effective Date',
      type_flags: {
        required: true,
      },
      value: '',
      attrib_list: [
        {
          attrib_id: 1,
          type: 'date',
          data: '',
        },
      ],
    };
  },
]);
