import module from './module';
import moment from 'moment';

export default module.factory('SessionsInJobService', [
  'sessionManagerService',
  '$q',
  'EnterpriseFilterService',
  'TimeFormatService',
  function (sessionManagerService, $q, EnterpriseFilterService, TimeFormatService) {
    function _isApplicable(sessionId) {
      const deferred = $q.defer();
      sessionManagerService.fetchSession(sessionId).then(function (request) {
        deferred.resolve({
          jobInstance: request.job_instance,
          jobId: request.job_id,
          providerId: request.serv_provider_id,
        });
      });
      return deferred.promise;
    }

    function _fetchSessionsForJob(jobInstance, jobId, providerId, effectiveDate) {
      const enterprise = EnterpriseFilterService.fetchSelectedEnterprise();
      const entId = enterprise ? enterprise.enterprise_id : -1;
      const deferred = $q.defer();
      const currentTime = effectiveDate || moment();

      sessionManagerService
        .getMyRequests(entId, currentTime.format(TimeFormatService.format('api')), jobId, jobInstance)
        .then(function (sessions) {
          // Only apply to Pending, Committed, Interest or Open state sessions
          const states = [1, 2, 3, 4, 7, 8];
          let filtered = sessions.filter(function (session) {
            return (
              session.job_instance === jobInstance &&
              session.job_id === jobId &&
              states.indexOf(session.status.type) !== -1 &&
              moment(session.start_date_time).isAfter(currentTime)
            );
          });
          if (providerId) {
            filtered = filtered.filter(function (session) {
              return session.serv_provider_id === providerId;
            });
          }
          deferred.resolve(filtered);
        });
      return deferred.promise;
    }

    return {
      isApplicable: _isApplicable,
      fetchSessionsForJob: _fetchSessionsForJob,
    };
  },
]);
