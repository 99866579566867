import angular from 'angular';
import module from './module';
import moment from 'moment';

export default module.factory('CalendarProvider', [
  '$http',
  'BASE_URL',
  'FormatHelperService',
  'OverlapService',
  'TimeFormatService',
  function ($http, BASE_URL, FormatHelperService, OverlapService, TimeFormatService) {
    // remove displayValue false sessions
    function sessionDisplayFilter(session) {
      return session.displayValue;
    }
    // remove non associated sessions
    function sessionAssociationFilter(session) {
      return session.association;
    }
    // Sort sesison list by time...needed in order to build overlap blocks
    function sortByStartTime(a, b) {
      return FormatHelperService.returnSortedValue(moment(b.start_time), moment(a.start_time));
    }
    // Duration Sort
    function sortByDuration(a, b) {
      return FormatHelperService.returnSortedValue(parseFloat(a.durationTotal), parseFloat(b.durationTotal));
    }

    // 3. Set duration total
    function setDurationTotal(list, parentObj, dateRange, provider) {
      const _viewStart = dateRange.start;
      const _viewEnd = dateRange.end;
      list.forEach((session) => {
        const _start = moment(session.start, TimeFormatService.format('fullDateTimeSecs')).clone();
        const _end = moment(session.end, TimeFormatService.format('fullDateTimeSecs')).clone();

        if (session.state.type === 7) {
          // ignore canceled sessions
          return;
        }

        if (session.child || session.state.name.toLowerCase() !== 'open') {
          // entire session within view
          if (
            (_start.isSameOrAfter(_viewStart) && _end.isSameOrBefore(_viewEnd)) ||
            (_start.isSameOrBefore(_viewEnd) && _end.isAfter(_viewEnd))
          ) {
            if (parentObj.bundled && session.state.type === 3) {
              const pending = parentObj.total_workers - parentObj.current_workers;
              parentObj.durationTotal += session.duration * pending;
            } else {
              parentObj.durationTotal += session.duration;
            }
          }
        } else {
          parentObj.potentialDurationTotal += session.duration;
        }
      });

      // const isPending = provider.name === 'Pending';
      // if (parentObj.bundled && parentObj.session_list.length) {
      //   parentObj.durationTotal =
      //     // parentObj.job_dates.length *
      //     parentObj.session_list[0].duration *
      //     (parentObj.total_workers - parentObj.current_workers);
      // }
    }
    // Initial Set data Call
    function CalendarProvider(calendarProviderData, startTime, endTime, job_list, session_list) {
      if (calendarProviderData) {
        this.setData(calendarProviderData, startTime, endTime, job_list, session_list);
      }
    }

    function buildSession(id, session_list, job_list, _currentProvider) {
      const job = job_list.find((job) => job.session_ids.indexOf(id) !== -1);
      const session = angular.copy(session_list.find((session) => session.session_id === id));
      session.isJob = job && job.job_map.indexOf('svcreq') === -1;
      session.job_id = session.isJob && job.job_map;
      session.bundled_job_id = job.job_id;
      session.jobOrderId = job.job_order_id;
      session.child = false;
      session.user_id = _currentProvider.user_id;
      session.displayValue = session.committed === 0 || session.committed === session.user_id;
      session.bundled = job.bundled;
      session.apply = session.state.type === 8 || session.state.type === 9;
      job.apply = session.apply;
      if (job.bundled) {
        job.number_candidates = session.number_candidates;
      }
      session.job_instance = job.job_instance;
      session.current_workers = job.current_workers;
      session.total_workers = job.total_workers;
      session.start = moment(session.start, TimeFormatService.format('fullDateTimeSecs'));
      session.end = moment(session.end, TimeFormatService.format('fullDateTimeSecs'));
      const tempStart = moment.utc(session.start_time).local();
      if (
        tempStart.isBefore(_currentProvider.dateRange.start) &&
        moment(session.end).isSameOrBefore(_currentProvider.dateRange.start)
      ) {
        session.displayValue = false;
      }

      return session;
    }

    function buildJobSession(session, job, _currentProvider) {
      session.isJob = job.job_map.indexOf('svcreq') === -1;
      session.job_id = session.isJob && job.job_map;
      session.jobOrderId = job.job_order_id;
      session.bundled_job_id = job.job_id;
      session.child = true;
      session.user_id = _currentProvider.user_id;
      session.displayValue = session.committed === 0 || session.committed === session.user_id;
      session.bundled = job.bundled;
      session.apply = session.state.type === 8 || session.state.type === 9;
      job.apply = session.apply;
      session.job_instance = job.job_instance;
      session.current_workers = job.current_workers;
      session.total_workers = job.total_workers;
      session.start = moment(session.start, TimeFormatService.format('fullDateTimeSecs'));
      session.end = moment(session.end, TimeFormatService.format('fullDateTimeSecs'));
      const tempStart = moment.utc(session.start_time).local();
      if (
        tempStart.isBefore(_currentProvider.dateRange.start) &&
        moment(session.end).isSameOrBefore(_currentProvider.dateRange.start)
      ) {
        session.displayValue = false;
      } else {
        session.displayValue = true;
      }
      session.association = _currentProvider.session_ids.indexOf(session.session_id) !== -1;

      if (!job.bundled) {
        if (session.displayValue) {
          if (session.number_candidates) {
            job.number_candidates += session.number_candidates;
          }
          if (!job.total_workers) {
            job.total_workers = 1;
          } else {
            job.total_workers++;
          }
        }
      } else {
        job.number_candidates = session.number_candidates;
      }

      return session;
    }

    function buildSearchableTextList(_currentProvider) {
      const searchables = [];
      searchables.push(_currentProvider.name);

      _currentProvider.job_list.forEach((job) => {
        job.session_list.forEach((sessionListItem) => {
          sessionListItem.attrib_list
            .filter((att) => att.value_list)
            .forEach((valueListItem) => {
              valueListItem.value_list.forEach((listItem) => {
                if (searchables.indexOf(listItem.name) === -1) {
                  searchables.push(listItem.name);
                }
              });
            });
        });
      });

      return searchables;
    }

    CalendarProvider.prototype = {
      setData: function (calendarProviderData, startTime, endTime, job_list, session_list) {
        const fixedProviders = ['Canceled', 'Pending', 'Open', 'Interest', 'Waiting Decision'];
        const fixedPosition = {
          Open: 5,
          Interest: 4,
          'Waiting Decision': 3,
          Pending: 2,
          Canceled: 1,
        };
        angular.extend(this, calendarProviderData);
        const _currentProvider = this;
        _currentProvider.displayValue = true;
        _currentProvider.durationTotal = 0;
        _currentProvider.potentialDurationTotal = 0;
        _currentProvider.dateRange = {
          start: moment(startTime, TimeFormatService.format('api')),
          end: moment(endTime, TimeFormatService.format('api')),
        };
        _currentProvider.vetting_status_code = _currentProvider.vetting_status;
        _currentProvider.login_status_code = _currentProvider.login_status;
        _currentProvider.isFixed =
          fixedProviders.indexOf(_currentProvider.name) !== -1 ? fixedPosition[_currentProvider.name] : false;

        _currentProvider.session_list = _currentProvider.session_ids
          .map((id) => {
            return buildSession(id, session_list, job_list, _currentProvider);
          })
          .filter((session) => session.displayValue);

        _currentProvider.job_list = _currentProvider.job_list
          .map((id) => job_list.find((job) => job.job_map == id))
          .map((job) => {
            const copiedJob = angular.copy(job);
            copiedJob.jobOrderId = copiedJob.job_order_id;
            copiedJob.session_list = session_list
              .filter(
                (session) =>
                  job.session_ids.indexOf(session.session_id) !== -1 &&
                  _currentProvider.session_ids.indexOf(session.session_id) !== -1
              )
              .map((session) => {
                return buildJobSession(session, copiedJob, _currentProvider);
              });

            return copiedJob;
          });

        // collect the searchable texts
        _currentProvider.searchables = buildSearchableTextList(_currentProvider);

        // 4. Sort session list in order to build overlap blocks
        _currentProvider.session_list.sort(sortByStartTime);
        // 3. Build duration total off of displayable sessions
        setDurationTotal(_currentProvider.session_list, _currentProvider, _currentProvider.dateRange, _currentProvider);

        OverlapService.buildOverlapSesisons(_currentProvider.session_list);
        // 6. Set session details on child job lists sessions
        OverlapService.cloneJobsForOverlappingSessions(_currentProvider.job_list, _currentProvider);

        _currentProvider.job_list.forEach((job) => {
          job.durationTotal = 0;
          job.session_list = job.session_list.filter(sessionAssociationFilter).filter(sessionDisplayFilter);
          job.displayValue = job.session_list.length;
          setDurationTotal(job.session_list, job, _currentProvider.dateRange, _currentProvider);
        });

        _currentProvider.job_list.sort(sortByDuration);
      },
    };

    return CalendarProvider;
  },
]);
