import module from './module';

export default module.directive('dirTimePickerPopupSimple', [
  function () {
    return {
      restrict: 'E',
      scope: {
        type: '@',
        session: '=',
        close: '&',
      },
      templateUrl: 'app/views/templates/time_picker_popup_simple.html',
      link: function (scope, element, attrs) {
        scope.types = {
          break: 'break',
          total: 'total',
        };

        scope.closePopup = function (event, session, isOk) {
          if (event) {
            event.stopImmediatePropagation();
            event.preventDefault();
          }
          scope.close({ session: session, isOk: isOk });
        };

        scope.checkKey = function (event) {
          if (event.keyCode === 13 || event.which === 13 || event.key === 'Enter') {
            scope.close({ session: scope.session, isOk: true });
          }
        };

        scope.incrementHours = function (event, session) {
          if (event) {
            event.stopImmediatePropagation();
            event.preventDefault();
          }
          if (scope.type === scope.types.break) {
            session.break_time_hours = incDecValue(session.break_time_hours, 1);
          } else if (scope.type === scope.types.total) {
            session.total_time_hours = incDecValue(session.total_time_hours, 1);
          }
        };

        scope.incrementMinutes = function (event, session) {
          if (event) {
            event.stopImmediatePropagation();
            event.preventDefault();
          }
          if (scope.type === scope.types.break) {
            session.break_time_minutes = incDecValue(session.break_time_minutes, 1);
          } else if (scope.type === scope.types.total) {
            session.total_time_minutes = incDecValue(session.total_time_minutes, 1);
          }
        };

        scope.decrementHours = function (event, session) {
          if (event) {
            event.stopImmediatePropagation();
            event.preventDefault();
          }
          if (scope.type === scope.types.break) {
            session.break_time_hours = incDecValue(session.break_time_hours, -1);
          } else if (scope.type === scope.types.total) {
            session.total_time_hours = incDecValue(session.total_time_hours, -1);
          }
        };

        scope.decrementMinutes = function (event, session) {
          if (event) {
            event.stopImmediatePropagation();
            event.preventDefault();
          }
          if (scope.type === scope.types.break) {
            session.break_time_minutes = incDecValue(session.break_time_minutes, -1);
          } else if (scope.type === scope.types.total) {
            session.total_time_minutes = incDecValue(session.total_time_minutes, -1);
          }
        };

        const watchBreakTimeHours = scope.$watch('session.break_time_hours', function (val) {
          const totalTimeHours = Math.floor(scope.session.request_total_time / 60);
          const totalTimeMinutes = scope.session.request_total_time - scope.session.break_time_hours * 60;
          if (scope.session.break_time_minutes > totalTimeMinutes) {
            scope.session.break_time_minutes = totalTimeMinutes;
          }
          if (val < 0) {
            scope.session.break_time_hours = 0;
          }
          if (val > totalTimeHours) {
            scope.session.break_time_hours = totalTimeHours;
          }
        });

        const watchBreakTimeMinutes = scope.$watch('session.break_time_minutes', function (val) {
          const breakTimeHours = scope.session.break_time_hours;
          const totalTimeMinutes = scope.session.request_total_time - breakTimeHours * 60;
          if (val > 59) {
            scope.session.break_time_minutes = 59;
          }
          if (val > totalTimeMinutes) {
            scope.session.break_time_minutes = totalTimeMinutes;
          }
          if (val < 0) {
            scope.session.break_time_minutes = 0;
          }
        });

        const watchTotalTimeHours = scope.$watch('session.total_time_hours', function (val) {
          if (val < 0) {
            scope.session.total_time_hours = 0;
          }
        });

        const watchTotalTimeMinutes = scope.$watch('session.total_time_minutes', function (val) {
          if (val > 59) {
            scope.session.total_time_minutes = 59;
          }
          if (val < 0) {
            scope.session.total_time_minutes = 0;
          }
        });

        scope.$on('$destroy', function () {
          watchBreakTimeHours();
          watchBreakTimeMinutes();
          watchTotalTimeHours();
          watchTotalTimeMinutes();
        });

        function incDecValue(value, operand) {
          if (!value && value != 0) {
            return;
          }
          if (!operand) {
            return value;
          }

          let origValue = parseInt(value);

          if (!isNaN(origValue)) {
            if (origValue + operand > -1) {
              return origValue + operand;
            }
          }

          return 0;
        }

        setTimeout(() => {
          const minutes_element = document.querySelector('.time-picker-popup-minutes');
          if (minutes_element) {
            minutes_element.select();
          }
        }, 500);
      },
    };
  },
]);
