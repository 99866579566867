import module from './module';
export default module.filter('enterpriseName', [
  function () {
    return function (input, list) {
      if (!list || !input) {
        return input;
      }
      const result = list.find(function (item) {
        return item.id === input;
      });

      return result.name;
    };
  },
]);
