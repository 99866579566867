import * as angular from 'angular';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Store } from '@ngrx/store';
import { FetchEnterpriseBlacklist } from '../../store/actions';
import { Observable } from 'rxjs';
import { WorkersSelectors } from '../../store/selectors';
import { filter, take } from 'rxjs/operators';

@Component({
  templateUrl: './blacklisted-workers.component.html',
  styleUrls: ['./blacklisted-workers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlacklistedWorkersComponent implements OnInit {
  @Input() enterpriseId: number;
  @Input() providers: any[];
  @Input() blacklistLoadedCallback: any;
  blacklistedWorkers$: Observable<{ id: number; first_name: string; last_name: string }[]>;

  constructor(private store: Store, private selectors: WorkersSelectors) {}

  ngOnInit() {
    this.store.dispatch(new FetchEnterpriseBlacklist(this.enterpriseId));
    this.selectors
      .getWorkerBlacklistForEnterprise(this.enterpriseId)
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((blacklist) => this?.blacklistLoadedCallback(blacklist));
  }
}

angular
  .module('ServetureWebApp')
  .directive('blacklistedWorkers', downgradeComponent({ component: BlacklistedWorkersComponent }));
