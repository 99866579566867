import module from './module';
import Swal from 'sweetalert2';
import moment from 'moment';
import * as enums from '../enums';

export default module.factory('removeSessionService', [
  '$rootScope',
  'sessionManagerService',
  'AlertService',
  'SessionsInJobService',
  'generalDataService',
  '$q',
  'TimeFormatService',
  'AuthenticationService',
  'PERMISSIONS',
  function (
    $rootScope,
    sessionManagerService,
    AlertService,
    SessionsInJobService,
    generalDataService,
    $q,
    TimeFormatService,
    AuthenticationService,
    PERMISSIONS
  ) {
    const keySession = generalDataService.getKeySession();
    let cachedCommentAttrib = null;
    return {
      hasMassCancelPermission: function () {
        const CURRENT_USER = AuthenticationService.getCurrentUser();
        return CURRENT_USER.permissionCheck(PERMISSIONS.MASS_CANCEL);
      },
      getCommentAttrib: async function () {
        if (cachedCommentAttrib) {
          return cachedCommentAttrib;
        }
        const attribs = await sessionManagerService.getRealmAttribs(enums.Realms.AdminCancel);
        cachedCommentAttrib = attribs?.attrib_list[0];
        return cachedCommentAttrib;
      },

      handleJob: function (requestId, options, cancelComment, request) {
        const scope = this;
        if (request.bundled && (request.user_id === 'Pending' || request.user_id === 'Canceled')) {
          this.cancelBundledJobProvider(request, options, cancelComment);
        } else if (request.job_id && request.job_order_id) {
          scope.sendJobToServer(requestId, options, cancelComment, request);
        } else {
          SessionsInJobService.isApplicable(requestId).then(function (response) {
            if (response.jobId && response.jobInstance) {
              SessionsInJobService.fetchSessionsForJob(response.jobInstance, response.jobId, response.providerId).then(
                async function (sessionsInJob) {
                  const hasPermission = scope.hasMassCancelPermission();
                  if (!hasPermission) {
                    scope.sendToServer(requestId, options, cancelComment);
                  } else {
                    const config = {
                      title: 'Warning',
                      text: `This ${keySession} is part of a Job with ${sessionsInJob.length} ${keySession}s.
                      Do you wish to apply this change to all remaining ${sessionsInJob.length} ${keySession}s or just this one?`,
                      showCancelButton: true,
                      confirmButtonColor: '#cc514c',
                      confirmButtonText: 'All Remaining',
                      cancelButtonText: 'Just This One',
                      icon: 'warning',
                    };

                    const returnHandler = function (applyToAll) {
                      if (applyToAll) {
                        scope.cancelJob(response, options, cancelComment, request);
                      } else {
                        scope.sendToServer(requestId, options, cancelComment);
                      }
                    };

                    const { value: returnValue } = await Swal.fire(config);
                    returnHandler(returnValue);
                  }
                }
              );
            } else {
              scope.sendToServer(requestId, options, cancelComment);
            }
          });
        }
      },

      cancelJob: async function (requestData, options, cancelComment, request) {
        const that = this;
        const commentAttrib = await this.getCommentAttrib();
        sessionManagerService
          .endJobRequest({
            job_id: requestData.jobId,
            job_instance: requestData.jobInstance,
            remove: options.doRemove,
            remove_only: !options.doCancel && options.doRemove,
            unassigned_only: true,
            user_id: !isNaN(request.user_id) ? request.user_id : undefined,
            attrib_list: [
              {
                attrib_id: commentAttrib?.attrib_id, //id for admin_cancel_comments attrib
                name: commentAttrib?.name,
                [commentAttrib?.choice_list?.length ? 'value_single_list' : 'value_text']: cancelComment,
              },
            ],
          })
          .then(function (response) {
            that.showAnswer(response);
            if (options.doRemove && !options.doCancel) {
              $rootScope.$broadcast('NOTIFICATION_UPDATE', { action: 'TRIGGER_CALENDAR_REFRESH', forceUpdate: true });
            }
          }, this.showError);
      },

      cancelBundledJobProvider: async function (requestData, options, cancelComment) {
        const that = this;
        if (requestData.user_id === 'Canceled' && options.doRemove) {
          that.endBundledJob(requestData, options, false, cancelComment);
        } else {
          const config = {
            title: 'Warning',
            text: `This ${keySession} is part of a job order. What would you like to do?`,
            showCancelButton: true,
            confirmButtonColor: '#cc514c',
            confirmButtonText: `Cancel Unfilled ${keySession}s`,
            cancelButtonText: 'Close',
            icon: 'warning',
          };

          const returnHandler = async function (inputValue) {
            if (inputValue) {
              that.endBundledJob(requestData, options, false, cancelComment);
            }
          };

          const { value: returnValue } = await Swal.fire(config);
          returnHandler(returnValue);
        }
      },

      confirmCancelJob: async function (requestData, options, inputValue, cancelComment) {
        const that = this;

        const returnHandler = function (returnValue) {
          if (returnValue) {
            that.endBundledJob(requestData, options, inputValue, cancelComment);
          }
        };

        const config = {
          title: 'Warning',
          text: `Please confirm you wish to cancel the order including ${requestData.current_workers} workers whom have already been committed to work.`,
          showCancelButton: true,
          confirmButtonColor: '#cc514c',
          cancelButtonText: 'No',
          confirmButtonText: 'Confirm',
          icon: 'warning',
        };

        const { value: returnValue } = await Swal.fire(config);
        returnHandler(returnValue);
      },

      endBundledJob: async function (requestData, options, inputValue, cancelComment) {
        const that = this;
        const commentAttrib = await this.getCommentAttrib();
        sessionManagerService
          .endJobRequest({
            job_id: requestData.bundled_job_id,
            job_instance: requestData.job_instance,
            remove: options.doRemove,
            remove_only: !options.doCancel && options.doRemove,
            unassigned_only: !inputValue,
            attrib_list: [
              {
                attrib_id: commentAttrib?.attrib_id, //id for admin_cancel_comments attrib
                name: commentAttrib?.name,
                [commentAttrib?.choice_list?.length ? 'value_single_list' : 'value_text']: cancelComment,
              },
            ],
          })
          .then(function (response) {
            that.showAnswer(response);
            if (options.doRemove && !options.doCancel) {
              $rootScope.$broadcast('NOTIFICATION_UPDATE', { action: 'TRIGGER_CALENDAR_REFRESH', forceUpdate: true });
            }
          }, that.showError);
      },

      sendToServer: async function (requestId, options, cancelComment) {
        const commentAttrib = await this.getCommentAttrib();
        sessionManagerService
          .endSessionRequest({
            request_id: requestId,
            end_type: options.doCancel ? 3 : 0,
            remove: options.doRemove,
            attrib_list: [
              {
                attrib_id: commentAttrib?.attrib_id, //id for admin_cancel_comments attrib
                name: commentAttrib?.name,
                [commentAttrib?.choice_list?.length ? 'value_single_list' : 'value_text']: cancelComment,
              },
            ],
          })
          .then(this.showAnswer, this.showError);
      },

      sendJobToServer: function (requestId, options, cancelComment, request) {
        sessionManagerService
          .endJobRequest({
            job_id: request.job_id,
            job_instance: request.job_instance,
            remove: options.doRemove,
            remove_only: !options.doCancel && options.doRemove,
          })
          .then((response) => {
            $rootScope.$emit('UpdateJobsTab');
            this.showAnswer(response);
          }, this.showError);
      },

      showAnswer: async function (response) {
        if (response.success) {
          const config = {
            title: 'Canceled!',
            text: response.message,
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
          };
          await Swal.fire(config);
        } else {
          const config = {
            title: 'Error',
            text: response.message,
            icon: 'error',
            timer: 2000,
            showConfirmButton: false,
          };
          await Swal.fire(config);
        }
      },

      showError: async function () {
        const config = {
          title: 'Error',
          text: 'error contacting the server',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
        };
        await Swal.fire(config);
      },

      showCustomError: async function (config) {
        await Swal.fire(config);
      },

      showSwal: async function (requestId, options, commentAttrib, request) {
        const scope = this;
        const isRequired = commentAttrib && commentAttrib.type_flags && commentAttrib.type_flags.required;

        const config = {
          title: options.title,
          showCancelButton: true,
          confirmButtonColor: '#cc514c',
          confirmButtonText: options.button,
          inputPlaceholder: 'Write a comment!',
          inputValidator: (value) => {
            if (isRequired && !value) {
              return 'Comments are required!';
            }
          },
        };

        if (commentAttrib) {
          config.input = 'text';
          config.icon = 'question';
        } else {
          config.icon = 'warning';
        }

        const returnHandler = function (returnValue) {
          if (returnValue === undefined) {
            return;
          }

          if (isRequired && typeof returnValue !== 'string') {
            return;
          }

          if ((isRequired && typeof returnValue === 'string') || !isRequired) {
            scope.handleJob(requestId, options, returnValue, request);
          }
        };

        const { value: returnValue } = await Swal.fire(config);
        Swal.close();
        returnHandler(returnValue);
      },

      showSwalWithList: async function (requestId, options, commentAttrib, request) {
        const scope = this;
        const isList = !!commentAttrib?.choice_list?.length;

        const config = {
          title: options.title,
          showCancelButton: true,
          confirmButtonColor: '#cc514c',
          confirmButtonText: options.button,
          icon: 'question',
          preConfirm: () => {
            if (isList) {
              return document.getElementById('cancel-input').value;
            }
          },
        };

        if (isList) {
          const mapper = (list) => {
            let retval = '';
            for (var item of list) {
              retval += `<option value="${item.choice_id}">${item.name}</option>`;
            }
            return retval;
          };

          config.html = `
            <div style="text-align:left">Select a reason!</div>
            <select class="cancel-options" id="cancel-input">
              ${mapper(commentAttrib.choice_list)}
            </select>
          `;
        } else if (commentAttrib) {
          config.input = 'text';
          config.inputPlaceholder = 'Write a comment!';
        }

        const returnHandler = function (value) {
          if (value === undefined) {
            return;
          }

          const isRequired = commentAttrib && commentAttrib.type_flags && commentAttrib.type_flags.required;
          let retVal = value;

          if (isList && typeof value === 'string') {
            retVal = value.trim();
          }

          Swal.close();
          if (isRequired && retVal) {
            scope.handleJob(requestId, options, retVal, request);
          } else if (!isRequired) {
            scope.handleJob(requestId, options, retVal, request);
          } else {
            const error = {
              title: 'Error',
              text: 'You have to provide a comment',
              icon: 'error',
              timer: 2000,
              showConfirmButton: false,
            };
            scope.showCustomError(error);
          }
        };

        const { value: returnValue } = await Swal.fire(config);
        returnHandler(returnValue);
      },

      doRemove: function (requestId, request) {
        this.showSwal(
          requestId,
          {
            button: 'Confirm, Remove',
            title: 'Do you want to Remove this ' + keySession + '?',
            doCancel: false,
            doRemove: true,
          },
          null,
          request
        );
      },

      doCancel: async function (requestId, request) {
        const commentAttrib = await this.getCommentAttrib();
        this.showSwalWithList(
          requestId,
          {
            button: 'Confirm, Cancel',
            title: 'Do you want to Cancel this ' + keySession + '?',
            doCancel: true,
            doRemove: false,
          },
          commentAttrib,
          request
        );
      },

      doCancelJob: async function (requestId, request) {
        const commentAttrib = await this.getCommentAttrib();
        this.showSwal(
          requestId,
          {
            button: 'Confirm, Cancel',
            title: 'Do you want to Cancel this Job?',
            doCancel: true,
            doRemove: false,
          },
          commentAttrib,
          request
        );
      },

      doCancelAndRemove: async function (requestId, request) {
        const commentAttrib = await this.getCommentAttrib();
        this.showSwal(
          requestId,
          {
            button: 'Confirm, Cancel and Remove',
            title: 'Do you want to Cancel and Remove this ' + keySession + '?',
            doCancel: true,
            doRemove: true,
          },
          commentAttrib,
          request
        );
      },
      endAssignment: async function (jobInstance, jobId, effectiveDate, providerId) {
        const deferred = $q.defer();
        SessionsInJobService.fetchSessionsForJob(jobInstance, jobId, null, effectiveDate).then(async function (
          sessionsInJob
        ) {
          const affected = sessionsInJob.filter((x) => x.status.type !== 7);
          if (!affected.length) {
            const config = {
              title: 'Warning',
              text: `No ${keySession}s to cancel!`,
              showCancelButton: false,
              icon: 'warning',
            };

            await Swal.fire(config);
            deferred.resolve(false);
          } else {
            const endAssignmentDate = moment(effectiveDate).format(TimeFormatService.format('apiDate'));
            const response = await sessionManagerService.endAssignment(
              jobId,
              jobInstance,
              providerId,
              endAssignmentDate
            );

            const config = {
              title: 'Success',
              text: response.message,
              showCancelButton: false,
              icon: 'success',
            };

            await Swal.fire(config);
            deferred.resolve(true);
          }
        });
        return deferred.promise;
      },
    };
  },
]);
