export class ChartFilterOption {

  public filterType: number;
  public displayValue: string;
  public value: any;
  public showItem: boolean;

  constructor(displayValue: string, value: any, showItem?: boolean) {
    this.displayValue = displayValue;
    this.value = value;
    this.filterType = 0;
    this.showItem = typeof showItem === 'boolean' ? showItem : true;
  }
}
