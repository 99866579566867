import { trigger, state, style, transition, animate } from '@angular/animations';

export const tabSelectorAnimation = [
  trigger('activeInactive', [
    state('active', style({
      borderBottom: '8px solid var(--main-color)',
    })),
    state('inactive', style({
      borderBottom: 'none',
    })),
    transition('active => inactive', [
      animate('300ms'),
    ]),
    transition('inactive => active', [
      animate('300ms'),
    ]),
  ]),
];
