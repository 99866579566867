export enum ProfileFieldType {
  SingleList = 'single_list',
  Flag = 'flag',
  Text = 'text',
  Image = 'image',
  MultiList = 'multi_list',
}

export interface ChoiceItem {
  name: string;
  selected: boolean;
  choice_id: string;
}

export interface ProfileField {
  attrib_id: number;
  name: string;
  type: ProfileFieldType;
  display_value?: string;
  choice_list?: ChoiceItem[];
  display: boolean;
  order: number;
  display_name: string;
  data?: string;
  read_only: boolean;
  // TODO: verify if this would be the property name
  confidential?: boolean;
}

export interface FormField extends ProfileField {
  value?: {
    choice_id?: number;
    location_id?: number;
    details?: any;
  };
  locations?: any[];
}

export interface Worker {
  profile_info: ProfileField[];
  profile_picture?: string;

  detailsLoaded: boolean;
  user_id: number;

  created_at: string;
  created_by: string;

  updated_at: string;
  updated_by: string;
}
