import module from '../module';

export default module.controller('ReportingController', [
  '$scope',
  'AuthenticationService',
  'PERMISSIONS',
  '$location',
  function ($scope, AuthenticationService, PERMISSIONS, $location) {
    $scope.data = {};
    $scope.pageTitle = 'Time Keeping';

    const CURRENT_USER = AuthenticationService.getCurrentUser();

    $scope.tabPermissions = {
      dashboardTab: CURRENT_USER.permissionCheck(PERMISSIONS.TIME_SHEET_REPORTS),
      analyticsTab: CURRENT_USER.permissionCheck(PERMISSIONS.TIME_SHEET_REPORTS),
      timeReportsTab: CURRENT_USER.permissionCheck(PERMISSIONS.TIME_SHEET_VERIFICATION),
      changeVerifyTab: CURRENT_USER.permissionCheck(PERMISSIONS.TIME_SHEET_REPORTS),
      mapTab: CURRENT_USER.permissionCheck(PERMISSIONS.TIME_SHEET_REPORTS),
    };

    $scope.tabs = {
      dashboardTab: false,
      analyticsTab: false,
      timeReportsTab: false,
      changeVerifyTab: false,
      mapTab: false,
    };

    $scope.tabNames = [
      {
        id: 0,
        name: 'Time Verification',
        tabsProperty: 'timeReportsTab',
      },
      {
        id: 1,
        name: 'Analytics',
        tabsProperty: 'analyticsTab',
      },
      {
        id: 2,
        name: 'Overview',
        tabsProperty: 'dashboardTab',
      },
      {
        id: 3,
        name: 'Map Views',
        tabsProperty: 'mapTab',
      },
      {
        id: 4,
        name: 'Time Verification (Original)',
        tabsProperty: 'changeVerifyTab',
      },
    ];

    setTimeout(() => {
      $scope.data.selectedTab = $scope.tabNames[0];
      $scope.tabSelectionChanged($scope.data.selectedTab);
    }, 0);

    $scope.tabSelectionChanged = function (tabName) {
      if (tabName && tabName.tabsProperty) {
        clearAllTabsSelection();
        $scope.tabs[tabName.tabsProperty] = true;
      }
    };

    function clearAllTabsSelection() {
      Object.keys($scope.tabs).forEach((value) => {
        return ($scope.tabs[value] = false);
      });
    }

    $scope.reportTabPermission = CURRENT_USER.permissionCheck(PERMISSIONS.REPORT_TAB);

    // Permission Check
    function permissionCheck(tabPermission) {
      if (!tabPermission) {
        location.href = '/#/';
      }
    }

    permissionCheck($scope.reportTabPermission);
  },
]);
