import module from '../../module';

export default module.controller('PreviewEmailController', [
  '$scope',
  '$modalInstance',
  'communicationsService',
  'passedData',
  '$sce',
  function ($scope, $modalInstance, communicationsService, passedData, $sce) {
    $scope.initialize = function () {
      $scope.template = passedData.template;
      $scope.body = $sce.trustAsHtml($scope.template.body);
      if (passedData.image) {
        setTimeout(() => {
          const imgPlaceholder = $('center img');
          if (!imgPlaceholder.length) {
            $scope.newImage = passedData.image;
          }

          imgPlaceholder.attr('src', passedData.image);
        }, 200);
      }
      $scope.isSaving = passedData.mode === 'save';
    };

    $scope.cancel = function () {
      $modalInstance.close();
    };

    $scope.save = function () {
      $modalInstance.close(true);
    };

    $scope.initialize();
  },
]);
