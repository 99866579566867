import module from './module';
import * as enums from '../enums';

export default module.directive('dirProfilePicture', function () {
  return {
    restrict: 'E',
    scope: {
      provider: '=',
      customClickHandler: '&?',
    },
    templateUrl: 'app/views/templates/profile_picture.html',
    controller: [
      '$scope',
      '$modal',
      '$location',
      'providerManagerService',
      'DOMAIN',
      function ($scope, $modal, $location, providerManagerService, DOMAIN) {
        $scope.getProfileUrl = function (picture) {
          if (!picture) {
            return `${enums.AssetsFolder.images}avatar.png`;
          }
          if (typeof picture === 'string') {
            return DOMAIN + picture;
          } else if (picture.data) {
            return DOMAIN + picture.data;
          } else {
            return `${enums.AssetsFolder.images}avatar.png`;
          }
        };

        $scope.popover = {
          loggedInStatus:
            'app/views/templates/avatar_loggedin_status_legend.html',
        };

        $scope.loggedInStatus = function (status_code, vetting_code) {
          var loggedIn, vetting;
          switch (status_code) {
            case 1:
              loggedIn = 'logged-in-state';
              break;
            case 0:
              loggedIn = 'logged-out-state';
              break;
            default:
              loggedIn = 'no-logged-in-state';
              break;
          }

          switch (vetting_code) {
            case 0:
            case 1:
            case 2:
              vetting = 'vetting-approved-state';
              break;
            case 100:
              vetting = 'vetting-waiting-state';
              break;
            case 101:
              vetting = 'vetting-denied-state';
              break;
            default:
              vetting = 'vetting-no-state';
              break;
          }

          return `${loggedIn} ${vetting}`;
        };

        ////////////////////////////////////////////////////////////////////////////////
        //Grab Provider
        $scope.showProviderDetails = function (userId) {
          providerManagerService.getProvider(userId).then(function (provider) {
            // $location.path(
            //   `/workers/${provider.user_id}/${provider.provider_id}`
            // );

            $modal.open({
              animation: true,
              templateUrl: 'app/views/workers/index.html',
              controller: 'WorkersController',
              size: 'xlg',
              backdrop: 'static',
              resolve: {
                passedData: function () {
                  return {
                    workerId: provider.user_id,
                    providerId: provider.provider_id,
                  };
                },
              },
            });
          });
        };

        //Provider Modal
        $scope.showProviderModal = function (provider) {
          $modal.open({
            animation: true,
            templateUrl: 'app/views/job/providerModal.html',
            controller: 'ProviderModalController',
            size: 'lg',
            backdrop: 'static',
            resolve: {
              provider: function () {
                provider.hidePermissions = true;
                return provider;
              },
            },
          });
        };
      },
    ],
  };
});
