import angular from 'angular';
import module from '../module';
import Swal from 'sweetalert2';

export default module.controller('EditBranchModalController', [
  '$rootScope',
  '$scope',
  '$modalInstance',
  '$modal',
  'AlertService',
  'AuthenticationService',
  'enterpriseListService',
  'branchService',
  'passedBranchId',
  'EnterpriseFilterService',
  function (
    $rootScope,
    $scope,
    $modalInstance,
    $modal,
    AlertService,
    AuthenticationService,
    enterpriseListService,
    branchService,
    passedBranchId,
    EnterpriseFilterService
  ) {
    $scope.initialize = function () {
      $scope.CURRENT_USER = AuthenticationService.getCurrentUser();

      $scope.submitBtnDisbaled = false;
      $scope.showSpinner = true;
      $scope.type = 'Edit Branch'; // Modal Title
      $scope.btnLabel = 'Edit Branch';
      $scope.sortKey = 'name';
      $scope.reverse = false;

      $scope.formData = {
        name: '',
        marketplace: $scope.CURRENT_USER.market_place_info.id,
        force_update: true,
      };

      $scope.getEnterprises().then(() => $scope.getBranch());
    };

    $scope.getEnterprises = function () {
      return enterpriseListService.getAllEnterprisesSimple().then(function (enterprises) {
        $scope.enterprises = angular.copy(enterprises);
        $scope.showSpinner = false;
      });
    };

    $scope.getBranch = function () {
      return branchService.getById(passedBranchId).then((branch) => {
        $scope.enterprises.forEach((e) => {
          if (branch.enterprises.indexOf(e.id) > -1) {
            e.selected = true;
          }
          $scope.formData = angular.copy(branch);
          $scope.formData.force_update = true;
        });
      });
    };

    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      $scope.updateBranch();
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      $scope.formData = {};
    };

    $scope.updateBranch = async function () {
      const enterpriseList = $scope.enterprises
        .filter((x) => x.selected)
        .filter((x) => !!x.branch && x.branch !== passedBranchId)
        .map((x) => x.name)
        .join(', ');
      if (enterpriseList) {
        const config = {
          title: 'Are you sure?',
          text: `Enterprise(s) ${enterpriseList} are currently under different branch(es), do you want to move them?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#cc514c',
          confirmButtonText: 'Yes, move Enterprises',
        };

        const returnHandler = function (isConfirm) {
          if (isConfirm) {
            $scope.formData.enterprises = $scope.enterprises.filter((x) => x.selected).map((x) => x.id);
            branchService.update($scope.formData).then(
              function () {
                $scope.enterprises
                  .filter((x) => x.selected)
                  .forEach((ent) => {
                    ent.branch_id = passedBranchId;
                    EnterpriseFilterService.updateEnterpriseName(ent);
                  });
                $rootScope.$broadcast('Branches.Updated');
                AlertService.successAlert('Branch was updated.');
                $modalInstance.close();
              },
              function (reason) {
                AlertService.serverRequestErrorAlert(reason);
                $modalInstance.close();
              }
            );
          }
        };

        const { value: returnValue } = await Swal.fire(config);
        returnHandler(returnValue);
      } else {
        $scope.formData.enterprises = $scope.enterprises.filter((x) => x.selected).map((x) => x.id);
        branchService.update($scope.formData).then(
          function () {
            $scope.$emit('Branches.Updated');
            AlertService.successAlert('Branch was updated.');
            $modalInstance.close();
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
            $modalInstance.close();
          }
        );
      }
    };

    $scope.sort = function (keyname) {
      $scope.sortKey = keyname; // set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; // if true make it false and vice versa
    };

    $scope.initialize();
  },
]);
