import module from './module';
import moment from 'moment';

export default module.factory('calendarJobManagerService', [
  '$http',
  'BASE_URL',
  '$q',
  'CalendarJob',
  'TimeFormatService',
  'ForceFilterService',
  function ($http, BASE_URL, $q, CalendarJob, TimeFormatService, ForceFilterService) {
    const calendarJobsManager = {
      _pool: {},
      _calendarJobsObj: {},
      _retrieveInstance: function (calJobId, calJobData, startTime, endTime, entId) {
        let instance = this._pool[calJobId];
        if (instance) {
          instance.setData(calJobData, startTime, endTime);
        } else {
          instance = new CalendarJob(calJobData, startTime, endTime);
          this._pool[calJobId] = instance;
        }
        return instance;
      },
      _loadAllCalendarJobs: function (start, end, deferred, entId, range) {
        const _startDateTime = moment(start, TimeFormatService.format('api'))
          .subtract(range === 'day' ? 1 : 0, 'd')
          .utc()
          .format(TimeFormatService.format('api'));

        const _endDateTime = moment(end, TimeFormatService.format('api')).utc().format(TimeFormatService.format('api'));
        const _requestObj = {
          enterprise_id: entId,
          start_date: _startDateTime,
          end_date: _endDateTime,
          user_filter_attribute_id_choice_id_tuples: ForceFilterService.getFilter(),
          user_filter_location_id: ForceFilterService.getLocationId(),
        };

        $http
          .post(BASE_URL + '/get_cal_providers_jobs', _requestObj)
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              const tempArray = [];
              response.job_list.forEach(function (calendarJobData) {
                tempArray.push(
                  new CalendarJob(calendarJobData, start, end, response.provider_list, response.session_list)
                );
              });
              deferred.resolve(tempArray);
            } else {
              deferred.reject(response.message);
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },
      /* Public Methods */
      getAllCalJobs: function (start, end, entId, range) {
        const deferred = $q.defer();
        this._loadAllCalendarJobs(start, end, deferred, entId, range);
        return deferred.promise;
      },
    };
    return calendarJobsManager;
  },
]);
