import module from '../module';
export default module.controller('ResendJobOrderModalController', [
  '$scope',
  '$modalInstance',
  'passedSession',
  'sessionManagerService',
  'AlertService',
  function($scope, $modalInstance, passedSession, sessionManagerService, AlertService) {
    $scope.session = passedSession;
    $scope.totalWorkers = $scope.session.total_workers || $scope.session.total_worker_count;
    $scope.formData = {
      job_order_id: $scope.session.jobOrderId,
      worker_count: $scope.totalWorkers - $scope.session.current_workers
    };

    $scope.cancel = function() {
      $modalInstance.dismiss('cancel');
    };

    $scope.ok = function() {
      $scope.formData.worker_count = $scope.formData.worker_count + $scope.session.current_workers;
      sessionManagerService.resendJobOrder($scope.formData).then(
        function(response) {
          if (response.success) {
            AlertService.successAlert(response.message);
            $modalInstance.close();
          } else {
            AlertService.errorAlert(response.message);
            $modalInstance.dismiss('cancel');
          }
        },
        function(error) {
          AlertService.serverRequestErrorAlert(error);
          $modalInstance.dismiss('cancel');
        }
      );
    };
  }
]);
