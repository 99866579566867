import angular from 'angular';
import module from '../module';
import * as enums from '../../enums';
import Swal from 'sweetalert2';
import moment from 'moment';
import * as rxjs from 'rxjs';
import * as operators from 'rxjs/operators';

export default module.controller('TimeReportsController', [
  '$scope',
  '$rootScope',
  '$localStorage',
  '$location',
  '$modal',
  '$window',
  'ReportingSectionService',
  'sessionManagerService',
  'AuthenticationService',
  'generalDataService',
  'AlertService',
  'branchFilterService',
  'EnterpriseFilterService',
  'PERMISSIONS',
  'timeReportDataService',
  'timeReportNetworkService',
  'timeReportUIService',
  'timeReportCsvService',
  'timeReportCalculationService',
  'TimeFormatService',
  'ALLOW_JOB_ORDER_REPEAT',
  'NgrxStore',
  'TimeService',
  function (
    $scope,
    $rootScope,
    $localStorage,
    $location,
    $modal,
    $window,
    ReportingSectionService,
    sessionManagerService,
    AuthenticationService,
    generalDataService,
    AlertService,
    branchFilterService,
    EnterpriseFilterService,
    PERMISSIONS,
    timeReportDataService,
    timeReportNetworkService,
    timeReportUIService,
    timeReportCsvService,
    timeReportCalculationService,
    TimeFormatService,
    ALLOW_JOB_ORDER_REPEAT,
    NgrxStore,
    TimeService
  ) {
    $scope.verifyAttribute = null;
    $scope.allowJobOrderRepeat = null;
    $scope.isSupervisorSignOffEnabled = false;
    $scope.startDate = null;
    $scope.endDate = null;
    $scope.dateFilterValue = null;

    AuthenticationService.getInitDataCache().then((response) => {
      $scope.initialData = response;
      $scope.verifyAttribute = response.general_data.find((data) => {
        return data.data_name === 'verify_attribute';
      });
      const repeatJobOrderObj = response.general_data.find((data) => {
        return data.data_name === 'repeat_job_order';
      });
      $scope.allowJobOrderRepeat = repeatJobOrderObj?.data_content?.repeat_job_order ?? false;
      initialize();
    });

    $scope.icons = {
      penalty: `${enums.AssetsFolder.svg}penalty-indicator.svg`,
    };

    // When the app is already loaded we can read this synchronously
    $scope.isBreakPenaltyEnabled = AuthenticationService.isBreakPenaltyEnabled();
    $scope.isMPSupervisorSignOffEnabled = AuthenticationService.isMPSupervisorSignOffEnabled();
    calculateSupervisorSignOffEnable();

    // When refreshing/opening the webapp from timekeeping we need to watch for the initial data loaded event
    $rootScope.$on('loadedInitialData', () => {
      $scope.isBreakPenaltyEnabled = AuthenticationService.isBreakPenaltyEnabled();
      $scope.isMPSupervisorSignOffEnabled = AuthenticationService.isMPSupervisorSignOffEnabled();
      calculateSupervisorSignOffEnable();
    });

    $scope.translations = timeReportUIService.getTranslations();
    $scope.params = $location.search();
    $scope.getVerified = !!$scope.params.attribValue;
    $scope.showCancelled = !!$scope.params.attribValue;
    $scope.CURRENT_USER = AuthenticationService.getCurrentUser();
    $scope.keySession = generalDataService.getKeySession();
    $scope.currencySymbol = AuthenticationService.getCurrencySymbol();
    $scope.showSpinner = false;
    $scope.showVerifyAll = false;
    $scope.wasChangeOnPage = false;
    $scope.hasUnverifyPermission = $scope.CURRENT_USER.permissionCheck(PERMISSIONS.ALLOW_UNVERIFY);
    $scope.hideVerifyButton = $scope.CURRENT_USER.permissionCheck(PERMISSIONS.HIDE_VERIFY_BUTTON);
    $scope.hasExtractPermission = $scope.CURRENT_USER.permissionCheck(PERMISSIONS.AUTOMATED_EXTRACT_CALL);
    $scope.repeatPermission = $scope.CURRENT_USER.permissionCheck(PERMISSIONS.REPEAT_JOB_ORDER_QUESTION);
    $scope.hasEditTimePermission = $scope.CURRENT_USER.permissionCheck(PERMISSIONS.TIME_KEEPING_EDIT_TIME);
    // model object for the
    $scope.data = {};

    // the data object for the table data
    $scope.sessions = [];
    // check if download is in progress
    $scope.getTimesheetChoicesInProgress = false;
    $scope.getVerifyJobSessionsInProgress = false;

    $scope.getTimesheetChoices = (wasVerification) => {
      if ($scope.getTimesheetChoicesInProgress) {
        return;
      }

      $scope.getTimesheetChoicesInProgress = true;

      const choicesPayloadData = {
        getVerified: $scope.getVerified,
        branchFilter: $scope.branchFilter,
        entFilter: $scope.entFilter,
        verifyAttribute: $scope.verifyAttribute,
        startDate: $scope.selectedFilterType === 0 ? $scope.startDate : undefined,
        endDate: $scope.selectedFilterType === 0 ? $scope.endDate : undefined
      };
      const data = timeReportDataService.getTimesheetChoicesPayload(choicesPayloadData);

      $scope.showSpinner = true;

      timeReportNetworkService
        .getOrderTimesheetChoices(data)
        .then(async (response) => {
          $scope.getTimesheetChoicesInProgress = false;

          $scope.billing_info = response.billing_info;
          $scope.verifyJobOrders = response.order_info?.length
            ? response.order_info.map(timeReportDataService.addGuidToOrder)
            : [];
          $scope.data.selectedJobOrder = $scope.verifyJobOrders[0];

          if (!$scope.data.selectedJobOrder) {
            $scope.sessions = [];
          }
          const range = ReportingSectionService.buildVerifyChangeDateRange($scope.billing_info);
          $scope.verifyDateRange = range;
          $scope.data.selectedRange = $scope.verifyDateRange[0];

          $scope.selectFirstItemInDropdown();

          $scope.showSpinner = false;

          if ($scope.selectedSort) {
            $scope.sortShiftValue = $scope.selectedSort[$scope.selectedFilterType];
            console.log($scope.selectedSort, $scope.selectedFilterType);
            if ($scope.sortShiftValue) {
              $scope.sortShifts($scope.sortShiftValue);
            }
          }

          if (
            wasVerification &&
            $scope.selectedFilterType === enums.SelectedTimeReportFilterType.JobOrder &&
            $scope.verifyJobOrders &&
            !$scope.verifyJobOrders.length
          ) {
            const configPositive = timeReportUIService.getSwalCongrats();
            await Swal.fire(configPositive);
          }
        })
        .catch(() => {
          $scope.showSpinner = false;
          $scope.getTimesheetChoicesInProgress = false;
        });
    };

    const unwatchEntFilter = $scope.$watch('entFilter', function () {
      $scope.branchFilter = branchFilterService.getSelectedBranch();
      if (!$scope.branchFilter && $scope.entFilter.branch_id) {
        branchFilterService.setSelectedBranch(parseInt($scope.entFilter.branch_id));
      } else {
        $scope.sendDrafts();
        $scope.resetTable();

        if ($scope.initialData) {
          $scope.getTimesheetChoices();
        }
      }
    });

    const branchChangeHandler = () => {
      const branchFilter = branchFilterService.getSelectedBranch();
      if (!$scope.branchFilter || ($scope.branchFilter && $scope.branchFilter.id !== branchFilter.id)) {
        $scope.branchFilter = branchFilter;
        $scope.resetTable();

        if ($scope.initialData) {
          $scope.getTimesheetChoices();
        }
      }
    };

    const unwatchSelectedBranchChanged = $rootScope.$on('selectedBranchChanged', branchChangeHandler);

    const unwatchBranchSelectionChanged = $rootScope.$on('BranchSelectionChanged', branchChangeHandler);

    const enterpriseChangeHandler = () => {
      const enterprise = EnterpriseFilterService.fetchSelectedEnterprise();

      if (enterprise.enterprise_id === -1) {
        $scope.isSupervisorSignOffEnabled = $scope.isMPSupervisorSignOffEnabled;
      } else {
        const payload = { enterprise_id: enterprise.enterprise_id, page_number: 1, page_size: 1 };

        generalDataService.getMyRequest(payload).then((request_list) => {
          if (request_list?.length) {
            $scope.isEnterpriseSupervisorSignOffEnabled = request_list[0]?.supervisor_signoff_enterprises;
            calculateSupervisorSignOffEnable();
          }
        });
      }
    };

    function calculateSupervisorSignOffEnable() {
      switch ($scope.isEnterpriseSupervisorSignOffEnabled) {
        case -1:
          $scope.isSupervisorSignOffEnabled = $scope.isMPSupervisorSignOffEnabled;
          break;
        case 0: // disabled
          $scope.isSupervisorSignOffEnabled = false;
          break;
        case 1: // enabled
          $scope.isSupervisorSignOffEnabled = true;
          break;
      }
    }
    const unbindSelectedEnterpriseChanged = $rootScope.$on('selectedEnterpriseChanged', enterpriseChangeHandler);

    const unbindEnterpriseSelectionChanged = $rootScope.$on('EnterpriseSelectionChanged', enterpriseChangeHandler);

    $scope.resetTable = function () {
      $scope.verifyJobOrders = [];
      $scope.verifyDateRange = [];
      $scope.billing_info = [];
      $scope.orignalSessions = [];
      $scope.sessions = [];
      $scope.shift_errors = [];
      $scope.data.selectedRange = null;
      $scope.data.selectedJobOrder = {};
      $scope.showVerifyAll = false;
      $scope.wasChangeOnPage = false;
      $scope.allVerifiedRequestsWithoutCanceled = false;
    };

    $scope.getVerifyJobSessions = (selectedItem, verifiedSession) => {
      if (!selectedItem) {
        return;
      }

      if ($scope.getVerifyJobSessionsInProgress) {
        return;
      }
      $scope.sendDrafts();
      $scope.getVerifyJobSessionsInProgress = true;
      $scope.showSpinner = true;

      if ($scope.selectedFilterType === enums.SelectedTimeReportFilterType.JobOrder) {
        if ($scope.data.selectedJobOrder != selectedItem) {
          $scope.data.selectedJobOrder = selectedItem;
        }
        $scope.prevSelectedJobOrder = selectedItem;
      } else {
        $scope.data.selectedRange = selectedItem;
        $scope.prevSelectedRange = selectedItem;
      }

      $scope.wasChangeOnPage = false;
      const verifJobSessPaylData = {
        selectedItem,
        selectedFilterType: $scope.selectedFilterType,
        branchFilter: $scope.branchFilter,
        entFilter: $scope.entFilter,
        verifyAttribute: $scope.verifyAttribute,
      };
      const verifyJobSessionsPayload = timeReportDataService.getVerifyJobSessionsPayload(verifJobSessPaylData);

      timeReportNetworkService.getOrderTimesheetShifts(verifyJobSessionsPayload).then(
        function (response) {
          $scope.getVerifyJobSessionsInProgress = false;
          $scope.showSpinner = false;
          $scope.wasVerifyCall = false;
          $scope.showProjectedTime = response.show_projected_time;
          $scope.isRepeatJobOrder = response.repeat_data?.repeat_order;
          $scope.repeatData = response.repeat_data;

          if (!response.success) {
            $scope.orignalSessions = [];
            $scope.sessions = [];
            $scope.shift_errors = [];
            AlertService.errorAlert(response.message);

            return;
          }

          $scope.orignalSessions = angular.copy(response.request_list);

          $scope.verifiedRequests = $scope.orignalSessions.filter((session) => session.verified).length;
          $scope.unVerifiedRequests = $scope.orignalSessions.filter((session) => !session.verified).length;

          if (
            $scope.selectedFilterType === enums.SelectedTimeReportFilterType.JobOrder &&
            response.request_list.length &&
            $scope.verifiedRequests === response.request_list.length &&
            $scope.wasVerifyCall &&
            !$scope.getVerified
          ) {
            $scope.wasVerifyCall = false;

            if ($scope.initialData) {
              $scope.getTimesheetChoices();
            }
            return;
          }

          if (!verifiedSession) {
            const orignalSessions = angular.copy(response.request_list);

            $scope.sessions = orignalSessions.map((session) => {
              if (session.draft_changes) {
                session = {
                  ...session,
                  ...session.draft_changes,
                  retainTotalTime: session.draft_changes.request_total_time,
                };
                if (session.draft_changes?.checkin_datetime) {
                  const checkinDateTime = moment(session.draft_changes?.checkin_datetime).format('YYYY-MM-DD HH:mm');
                  session.checkin_date = checkinDateTime.split(' ')[0];
                  session.checkin_time = checkinDateTime.split(' ')[1];
                }
                if (session.draft_changes?.checkout_datetime) {
                  const checkoutDateTime = moment(session.draft_changes?.checkout_datetime).format('YYYY-MM-DD HH:mm');
                  session.checkout_date = checkoutDateTime.split(' ')[0];
                  session.checkout_time = checkoutDateTime.split(' ')[1];
                }
              }
              return timeReportDataService.getInitializedSession(session, $scope.showProjectedTime);
            });
          } else {
            const affectedSession = response.request_list.find((x) => x.request_id === verifiedSession.request_id);
            const localIndex = $scope.sessions.findIndex((x) => x.request_id === verifiedSession.request_id);
            if (localIndex > -1 && affectedSession) {
              $scope.sessions[localIndex] = timeReportDataService.getInitializedSession(
                affectedSession,
                $scope.showProjectedTime
              );
            }
          }

          // set the exclamation mark if there were errors
          $scope.sessions = $scope.sessions.map((session) => {
            if ($scope.shift_errors) {
              session.error = $scope.shift_errors.find((err) => {
                return err.request_id === session.request_id;
              });
            }

            return session;
          });

          $scope.hasAmount = !!$scope.sessions.find((session) => session.total_amt > 0);

          $scope.verifiedRequests = $scope.sessions.filter((session) => session.verified).length;

          if ($scope.verifiedRequests === $scope.sessions.length && selectedItem?.attrib_value) {
            const config = timeReportUIService.getSwalWrongJobOrderId(selectedItem.attrib_value);
            Swal.fire(config);
          }

          $scope.setPieChartData();

          setVerifyAllButton();
        },
        function (reason) {
          $scope.getVerifyJobSessionsInProgress = false;
          AlertService.serverRequestErrorAlert(reason);
          $scope.showSpinner = false;
          $scope.wasVerifyCall = false;
        }
      );
    };

    // Key profession and attribute variables
    $scope.keyProfession = $localStorage.generalData.filter(function (obj) {
      return obj.data_name === 'key_profession';
    })[0];
    $scope.keyAttrib = $localStorage.keyAttribute;
    function getKeyAttribDisplayName() {
      sessionManagerService.getRealmAttribs(enums.Realms.Service).then(
        function (response) {
          // get skill name from realm attribs
          const keyAttribName = response.attrib_list.filter(function (obj) {
            return obj.name === $localStorage.keyAttribute.data_content;
          });

          if (keyAttribName && keyAttribName[0]) {
            $localStorage.keyAttribute.display_name = keyAttribName[0].display_name;
          } else {
            $localStorage.keyAttribute.display_name = 'N/A';
          }

          $scope.keyAttrib = $localStorage.keyAttribute;
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
        }
      );
    }
    function getFirstFilterName() {
      sessionManagerService.getRealmAttribs(enums.Realms.Service).then(
        function (response) {
          // get first filter name from realm attribs
          let filterName;
          if ($scope.verifyAttribute) {
            filterName = response.attrib_list.filter(function (obj) {
              return obj.name === $scope.verifyAttribute.data_content;
            });
          }
          if (filterName && filterName[0]) {
            $scope.translations.firstFilterName = filterName[0].display_name;
          } else {
            $scope.translations.firstFilterName = 'Job Order';
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
        }
      );
    }

    $scope.changeCheckin = function (session) {
      $scope.addToDraft(session);
      $scope.wasChangeOnPage = true;
      timeReportCalculationService.changeCheckin(session);
    };

    $scope.changeCheckout = function (session) {
      $scope.addToDraft(session);
      $scope.wasChangeOnPage = true;
      timeReportCalculationService.changeCheckout(session);
    };

    $scope.initTimeWorked = function (session) {
      if ($scope.showProjectedTime || session.request_total_time) {
        session.request_total_time_model = timeReportCsvService.getDurationDisplayVal(session.request_total_time);
      }
    };

    $scope.changeTimeWorked = function (session) {
      $scope.addToDraft(session);
      $scope.wasChangeOnPage = true;
      timeReportCalculationService.changeTimeWorked(session);
    };

    $scope.initBreak = function (session) {
      session.break_total_time_model = timeReportCsvService.getDurationDisplayVal(session.break_total_time);
    };

    $scope.changeBreak = function (session) {
      $scope.addToDraft(session);
      $scope.wasChangeOnPage = true;
    };

    $scope.wasBreakAlreadyUpdated = false;
    $scope.keypress = function (event, session) {
      if (event.charCode === 13 || event.keyCode === 13 || event.which === 13) {
        $scope.blurBreak(session);
      }
    };

    $scope.changeAmount = function (session) {
      $scope.addToDraft(session);
      session.total_amt = parseInt(session.total_amt);
    };

    $scope.changeRating = function () {
      $scope.wasChangeOnPage = true;
    };

    $scope.setSelectedFilterType = (selection) => {
      $scope.sendDrafts();
      if ($scope.selectedFilterType != selection) {
        $scope.selectedFilterType = selection;
        localStorage.setItem('selectedFilterType', selection);
        $scope.prevSelectedRange = null;
        $scope.prevSelectedJobOrder = null;
        $scope.sessions = [];
        $scope.shift_errors = [];
        $scope.wasChangeOnPage = false;
        if ($scope.selectedFilterType === 0 && !$scope.dateFilterValue) {
          $scope.filterByDate(1);
        } else {
          $scope.selectFirstItemInDropdown();
        }

        if ($scope.selectedSort) {
          $scope.sortShiftValue = $scope.selectedSort[selection];
          if ($scope.sortShiftValue) {
            $scope.sortShifts($scope.sortShiftValue);
          }
        }
      }
    };

    $scope.selectFirstItemInDropdown = () => {
      // check job and jobinstance ids in the url to preselection
      const jobId = parseInt($scope.params.jobId);
      const jobInstanceId = parseInt($scope.params.jobInstanceId);
      const attribValue = $scope.params.attribValue;

      if (jobId && !isNaN(jobId) && jobInstanceId && !isNaN(jobInstanceId)) {
        if ($scope.selectedFilterType == enums.SelectedTimeReportFilterType.Extract) {
          $scope.setSelectedFilterType(enums.SelectedTimeReportFilterType.JobOrder);
        } else if ($scope.verifyJobOrders && $scope.verifyJobOrders.length) {
          const jobOrder = $scope.verifyJobOrders.find((order) => {
            return order.job_id === jobId && order.job_instance === jobInstanceId;
          });
          if (jobOrder) {
            $scope.data.selectedJobOrder = jobOrder;
          }

          // clear the route params
          $location.search({});
          $scope.getVerifyJobSessions($scope.data.selectedJobOrder);
        }
      } else if (attribValue) {
        if ($scope.selectedFilterType == enums.SelectedTimeReportFilterType.Extract) {
          $scope.setSelectedFilterType(enums.SelectedTimeReportFilterType.JobOrder);
        } else if ($scope.verifyJobOrders && $scope.verifyJobOrders.length) {
          const jobOrder = $scope.verifyJobOrders.find((order) => {
            return order.attrib_value === attribValue;
          });
          if (jobOrder) {
            $scope.data.selectedJobOrder = jobOrder;
          } else {
            const config = timeReportUIService.getSwalWrongJobOrderId(attribValue);
            Swal.fire(config);
          }
          $scope.params = {};
          $location.search({});

          $scope.getVerifyJobSessions($scope.data.selectedJobOrder);
        }
      } else {
        if ($scope.selectedFilterType === enums.SelectedTimeReportFilterType.JobOrder) {
          if ($scope.verifyJobOrders && $scope.verifyJobOrders.length) {
            if ($scope.prevSelectedJobOrder) {
              $scope.data.selectedJobOrder = $scope.verifyJobOrders.find(
                (order) => order.id === $scope.prevSelectedJobOrder.id
              );
              if (!$scope.data.selectedJobOrder) {
                $scope.data.selectedJobOrder = $scope.verifyJobOrders[0];
              }
            } else {
              $scope.data.selectedJobOrder = $scope.verifyJobOrders[0];
            }
            $scope.getVerifyJobSessions($scope.data.selectedJobOrder);
          }
        } else {
          if ($scope.verifyDateRange && $scope.verifyDateRange.length) {
            // search the stored
            if ($scope.prevSelectedRange) {
              $scope.data.selectedRange = $scope.verifyDateRange.find(
                (range) => range.id === $scope.prevSelectedRange.id
              );
              if (!$scope.data.selectedRange) {
                $scope.data.selectedRange = $scope.verifyDateRange[0];
              }
            } else {
              $scope.data.selectedRange = $scope.verifyDateRange[0];
            }

            $scope.getVerifyJobSessions($scope.data.selectedRange);
          }
        }
      }
    };

    $scope.export = () => {
      const mappedSessions = $scope.sessions.map((s) => ({
        ...s,
        status: $scope.getStatus(s),
      }));
      const data = {
        sessions: mappedSessions,
        keySession: $scope.keySession,
        keyAttrib: $scope.keyAttrib,
        keyProfession: $scope.keyProfession,
        showCancelled: $scope.showCancelled,
        selectedFilterType: $scope.selectedFilterType,
        selectedRange: $scope.data.selectedRange,
        selectedJobOrder: $scope.data.selectedJobOrder,
      };
      timeReportCsvService.generateDownload(data);
    };

    $scope.extract = () => {
      const extractPayload = timeReportDataService.getVerifyJobSessionsPayload({
        selectedItem: $scope.data.selectedRange,
        branchFilter: $scope.branchFilter,
        entFilter: $scope.entFilter,
      });

      timeReportNetworkService
        .adminExtract(extractPayload)
        .then((response) => {
          if (!response.success) {
            AlertService.errorAlert(response.message);
          } else {
            AlertService.successAlert(response.message);
          }
        })
        .catch((err) => AlertService.serverRequestErrorAlert(err));
    };

    function setVerifyAllButton() {
      let sessions = $scope.sessions;
      if (!$scope.showCancelled) {
        sessions = sessions.filter((session) => session.status != enums.RequestStatusString.Canceled);
      }

      if (!sessions.length || (sessions.length && $scope.verifiedRequests === sessions.length)) {
        $scope.showVerifyAll = false;
      } else if (sessions.length && sessions.length >= $scope.verifiedRequests) {
        $scope.showVerifyAll = !$scope.hideVerifyButton;
      }
    }

    // SHOW CANCELLED OR NOT
    $scope.toggleCancelled = () => {
      $scope.showCancelled = !$scope.showCancelled;

      setVerifyAllButton();
    };

    $scope.cancelledFilter = function (item) {
      return $scope.showCancelled || item.status !== enums.RequestStatusString.Canceled;
    };
    // SHOW CANCELLED OR NOT

    // SHOW FUTURE OR NOT
    $scope.showFuture = $localStorage.verificationFuture || false;
    $scope.toggleFuture = () => {
      $scope.showFuture = !$scope.showFuture;
      $localStorage.verificationFuture = $scope.showFuture;

      setVerifyAllButton();
    };

    $scope.futureFilter = function (item) {
      return (
        $scope.selectedFilterType === 0 ||
        $scope.showFuture ||
        moment.utc(`${item.request_date} ${item.request_time}`).isBefore(moment())
      );
    };

    $scope.toggleAllActions = () => ($scope.showAllActions = !$scope.showAllActions);
    // SHOW FUTURE OR NOT

    // GET VERIFIED OR NOT

    $scope.toggleVerified = () => {
      $scope.prevSelectedRange = null;
      $scope.prevSelectedJobOrder = null;
      $scope.getVerified = !$scope.getVerified;
      $scope.resetTable();
      if ($scope.initialData) {
        $scope.getTimesheetChoices();
      }
    };
    // GET VERIFIED OR NOT

    $scope.openDetail = function (session) {
      $modal.open({
        animation: true,
        templateUrl: 'app/views/reporting/timeReports/shiftDetails.html',
        controller: 'shiftDetailsModalController',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          passedSession: function () {
            return session;
          },
        },
      });
    };

    $scope.sortReverse = false;
    $scope.sort = (key) => {
      if ($scope.sortKey === key) {
        $scope.sortReverse = !$scope.sortReverse;
      }
      $scope.sortKey = key;
    };

    $scope.closeAllPicker = function () {
      const popupOverlay = document.querySelector('.popup-overlay');
      if (popupOverlay) {
        popupOverlay.classList.remove('visible-overlay');
      }

      for (const sess in $scope.sessionDate) {
        $scope.sessionDate[sess] = false;
      }
      for (const sess in $scope.sessionTime) {
        $scope.sessionTime[sess] = false;
      }
      for (const sess in $scope.sessionBreakTime) {
        $scope.sessionBreakTime[sess] = false;
      }
      for (const sess in $scope.sessionBreakTotalTime) {
        $scope.sessionBreakTotalTime[sess] = false;
      }
      for (const sess in $scope.sessionTotalTime) {
        $scope.sessionTotalTime[sess] = false;
      }
      for (const sess in $scope.sessionBreakPenalty) {
        $scope.sessionBreakPenalty[sess] = false;
      }
      for (const sess in $scope.sessionSupervisorSignOff) {
        $scope.sessionSupervisorSignOff[sess] = false;
      }
    };
    // Open close DateTime picker
    $scope.sessionDate = {};
    $scope.openSessionDate = function (request_id) {
      if ($scope.sessionDate[request_id]) {
        $scope.sessionDate[request_id] = false;
        return;
      }

      $scope.closeAllPicker();
      $scope.sessionDate[request_id] = true;

      setTimeout(() => {
        timeReportUIService.checkIfElementInsideViewport('.datetime-picker-container div', $scope.closeAllPicker);
      }, 0);
    };

    $scope.closeSessionDate = function (newDate, oldDate, request_id, session) {
      $scope.addToDraft(session);
      $scope.sendDrafts();
      const popupOverlay = document.querySelector('.popup-overlay');
      if (popupOverlay) {
        popupOverlay.classList.remove('visible-overlay');
      }

      $scope.sessionDate[request_id] = false;
    };

    $scope.datetimePickerConfig = {
      startViewTime: 'minute',
      minuteStep: 1,
    };
    // Open close DateTime picker

    // Open close Time picker
    $scope.sessionTime = {};

    $scope.openSessionTime = function (request_id) {
      if ($scope.sessionTime[request_id]) {
        $scope.sessionTime[request_id] = false;
        return;
      }

      $scope.closeAllPicker();
      $scope.sessionTime[request_id] = true;

      setTimeout(() => {
        timeReportUIService.selectInput('#timereports-tab-table .time-picker-container td>input');
      }, 500);

      setTimeout(() => {
        timeReportUIService.checkIfElementInsideViewport('.time-picker-container', $scope.closeAllPicker);
      }, 0);
    };

    $scope.closeSessionTime = function (type, session, isOk) {
      const popupOverlay = document.querySelector('.popup-overlay');
      if (popupOverlay) {
        popupOverlay.classList.remove('visible-overlay');
      }

      const request_id = `${session.request_id}_${type}`;
      $scope.sessionTime[request_id] = false;

      if (!isOk) {
        timeReportCalculationService.setSessionTime(session);
        return;
      }

      timeReportCalculationService.closeSessionTime(type, session);

      if (isOk) {
        $scope.addToDraft(session);
        $scope.sendDrafts();
      }
    };

    $scope.timePickerConfig = timeReportUIService.getTimePickerConfig();
    // Open close Time picker

    // Open close Session Break time picker
    $scope.sessionBreakTime = {};
    $scope.sessionBreakPenalty = {};
    $scope.sessionBreakTotalTime = {};
    $scope.openBreakTime = function (request_id, session) {
      if ($scope.sessionBreakTime[request_id]) {
        $scope.sessionBreakTime[request_id] = false;
        return;
      }

      $scope.closeAllPicker();
      $scope.sessionBreakTime[request_id] = true;

      setTimeout(() => {
        timeReportUIService.checkIfElementInsideViewport(
          '.datetime-picker-container div',
          () => { }
          //   $scope.closeBreakTime(session, false)
        );
      }, 0);
    };

    $scope.openBreakPenalty = function (request_id, session) {
      if ($scope.sessionBreakPenalty[request_id]) {
        $scope.sessionBreakPenalty[request_id] = false;
        return;
      }

      $scope.closeAllPicker();
      $scope.sessionBreakPenalty[request_id] = true;

      setTimeout(() => {
        timeReportUIService.checkIfElementInsideViewport(
          '.datetime-picker-container div',
          () => { }
          //   $scope.closeBreakTime(session, false)
        );
      }, 0);
    };

    $scope.openBreakTotalTime = function (request_id) {
      if ($scope.sessionBreakTotalTime[request_id]) {
        $scope.sessionBreakTotalTime[request_id] = false;
        return;
      }

      $scope.closeAllPicker();
      $scope.sessionBreakTotalTime[request_id] = true;

      setTimeout(() => {
        timeReportUIService.checkIfElementInsideViewport('.time-interval-container', $scope.closeAllPicker);
      }, 0);
    };

    // Open close Supervisor sign off
    $scope.sessionSupervisorSignOff = {};

    $scope.openSupervisorSignOff = function (request_id) {
      if ($scope.sessionSupervisorSignOff[request_id]) {
        $scope.sessionSupervisorSignOff[request_id] = false;
        return;
      }

      $scope.closeAllPicker();
      $scope.sessionSupervisorSignOff[request_id] = true;

      setTimeout(() => {
        timeReportUIService.checkIfElementInsideViewport('.datetime-picker-container div', () => { });
      }, 0);
    };

    $scope.closeSupervisorSignOff = function (session, isOk) {
      $scope.addToDraft(session);
      const popupOverlay = document.querySelector('.popup-overlay');
      if (popupOverlay) {
        popupOverlay.classList.remove('visible-overlay');
      }

      const request_id = session.request_id;
      if (!isOk) {
        $scope.sessionSupervisorSignOff[request_id] = false;
        return;
      }

      $scope.sessionSupervisorSignOff[request_id] = false;
    };

    $scope.recalculateBreakHeight = function () {
      setTimeout(() => {
        timeReportUIService.checkIfElementInsideViewport('.datetime-picker-container div', $scope.closeAllPicker);
      }, 0);
    };

    $scope.closeBreakTime = function (session, isOk) {
      const popupOverlay = document.querySelector('.popup-overlay');
      if (popupOverlay) {
        popupOverlay.classList.remove('visible-overlay');
      }

      const request_id = session.request_id;
      if (!isOk) {
        $scope.sessionBreakTime[request_id] = false;
        return;
      }

      const break_time_hours = parseInt(session.break_time_hours == '' ? 0 : session.break_time_hours);
      const break_time_minutes = parseInt(session.break_time_minutes == '' ? 0 : session.break_time_minutes);

      if (isNaN(break_time_hours) || isNaN(break_time_minutes) || break_time_hours < 0 || break_time_minutes < 0) {
        $scope.sessionBreakTime[request_id] = false;
        return;
      }

      const break_total_time = session.break_total_time === -1 ? 0 : session.break_total_time;
      const breakDiff = break_total_time - (break_time_hours * 60 + break_time_minutes);

      if (breakDiff === 0) {
        $scope.sessionBreakTime[request_id] = false;
        return;
      }
      timeReportCalculationService.closeBreakTime(session, break_total_time, breakDiff);

      $scope.sessionBreakTime[request_id] = false;

      if (isOk) {
        $scope.addToDraft(session);
        $scope.sendDrafts();
      }
    };

    $scope.closeBreakPenalty = function (session, isOk) {
      $scope.addToDraft(session);
      const popupOverlay = document.querySelector('.popup-overlay');
      if (popupOverlay) {
        popupOverlay.classList.remove('visible-overlay');
      }

      const request_id = session.request_id;
      if (!isOk) {
        $scope.sessionBreakPenalty[request_id] = false;
        return;
      }

      $scope.sessionBreakPenalty[request_id] = false;
    };

    $scope.closeBreakTotalTime = function (session, isOk) {
      session.breakSections = [];
      const popupOverlay = document.querySelector('.popup-overlay');
      if (popupOverlay) {
        popupOverlay.classList.remove('visible-overlay');
      }

      const request_id = session.request_id;
      if (!isOk) {
        $scope.sessionBreakTotalTime[request_id] = false;
        return;
      }

      const break_time_hours = parseInt(session.break_time_hours == '' ? 0 : session.break_time_hours);
      const break_time_minutes = parseInt(session.break_time_minutes == '' ? 0 : session.break_time_minutes);

      if (isNaN(break_time_hours) || isNaN(break_time_minutes) || break_time_hours < 0 || break_time_minutes < 0) {
        $scope.sessionBreakTotalTime[request_id] = false;
        return;
      }

      const break_total_time = session.break_total_time === -1 ? 0 : session.break_total_time;
      const breakDiff = break_total_time - (break_time_hours * 60 + break_time_minutes);

      if (breakDiff === 0) {
        $scope.sessionBreakTotalTime[request_id] = false;
        return;
      }
      timeReportCalculationService.closeBreakTime(session, break_total_time, breakDiff);

      $scope.sessionBreakTotalTime[request_id] = false;

      if (isOk) {
        $scope.addToDraft(session);
        $scope.sendDrafts();
      }
    };
    // Open close Session Break time picker

    // Open close Session Total time picker
    $scope.sessionTotalTime = {};
    $scope.openTotalTime = function (request_id) {
      if ($scope.sessionTotalTime[request_id]) {
        $scope.sessionTotalTime[request_id] = false;
        return;
      }
      $scope.closeAllPicker();
      $scope.sessionTotalTime[request_id] = true;

      setTimeout(() => {
        timeReportUIService.checkIfElementInsideViewport('.time-interval-container', $scope.closeAllPicker);
      }, 0);
    };

    $scope.closeTotalTime = function (session, isOk) {
      const popupOverlay = document.querySelector('.popup-overlay');
      if (popupOverlay) {
        popupOverlay.classList.remove('visible-overlay');
      }

      const request_id = session.request_id;

      if (!isOk) {
        $scope.sessionTotalTime[request_id] = false;
        return;
      }

      const total_time_hours = parseInt(session.total_time_hours == '' ? 0 : session.total_time_hours);
      const total_time_minutes = parseInt(session.total_time_minutes == '' ? 0 : session.total_time_minutes);

      if (isNaN(total_time_hours) || isNaN(total_time_minutes) || total_time_hours < 0 || total_time_minutes < 0) {
        $scope.sessionTotalTime[request_id] = false;
        return;
      }
      timeReportCalculationService.closeTotalTime(session, total_time_hours, total_time_minutes);
      $scope.sessionTotalTime[request_id] = false;

      if (isOk) {
        $scope.addToDraft(session);
        $scope.sendDrafts();
      }
    };
    // Open close Session Total time picker

    $scope.beforePageChange = async function (callback) {
      const config = timeReportUIService.getSwalSaveDataBeforeLeave();

      const returnHandler = function (isConfirm) {
        if (!isConfirm) {
          return;
        }

        $scope.wasChangeOnPage = false;
        if (callback) {
          callback();
        }
      };

      const { value: returnValue } = await Swal.fire(config);
      returnHandler(returnValue);
    };

    $scope.verifyCall = (data, reloadChoices) => {
      const singleVerification = data.request_list.length === 1;
      $scope.showSpinner = true;
      timeReportNetworkService.postOrderTimesheetVerifyShifts(data).then(
        async function (response) {
          $scope.showSpinner = false;
          if (!response.success) {
            AlertService.errorAlert(response.message);
            return;
          }

          $scope.wasVerifyCall = true;
          $scope.shift_errors = response.shift_errors;
          $scope.verified_orders = response.verified_orders;

          if (!response.verified_orders.length) {
            $scope.refresh(reloadChoices, singleVerification ? data.request_list[0] : null);
            // close change modal
            $rootScope.$broadcast('CloseMobileChangeModal');

            return;
          }

          const config = timeReportUIService.getSwalJobOrderCompleted();

          const returnHandler = function () {
            $scope.prevSelectedJobOrder = null;
            // close change modal
            $rootScope.$broadcast('CloseMobileChangeModal');

            // check repeat privilege
            if ($scope.CURRENT_USER.permissionCheck(PERMISSIONS.REPEAT_JOB_ORDER_QUESTION) && !$scope.repeatData) {
              $scope.showRepeatModal();
            } else {
              $scope.refresh(reloadChoices, singleVerification ? data.request_list[0] : null);
            }
          };

          await Swal.fire(config);
          returnHandler();
        },
        function (reason) {
          // handle network error
          $scope.showSpinner = false;
          AlertService.serverRequestErrorAlert(reason);
        }
      );
    };

    $scope.saveDraftCall = (data) => {
      if (data.request_list.length) {
        timeReportNetworkService
          .postOrderTimesheetVerifyShifts({
            draft: true,
            ...data,
          })
          .then(async function (response) {
            $scope.sessions.forEach((s) => (s.draft = false));
          });
      }
    };

    $scope.verifyAllQuestion = async () => {
      if (!$scope.isVerifyAllDisabled()) {
        const numberOfActiveShifts = $scope.sessions
          .filter($scope.futureFilter)
          .map((x) => (x.status === 'Active' ? 1 : 0))
          .reduce((a, b) => a + b, 0);
        const config = timeReportUIService.getSwalVerifyAllQuestion(numberOfActiveShifts);

        const returnHandler = function (modalResult) {
          if (modalResult === true) {
            $scope.verifyAll(false);
          } else if (modalResult === false) {
            $scope.verifyAll(true);
          } else if (modalResult === undefined) {

          }
        };

        const { value: returnValue } = await Swal.fire(config);
        returnHandler(returnValue);
      }
    };

    $scope.isVerifyAllDisabled = () => {
      return !$scope.sessions
        .filter($scope.futureFilter)
        .filter((x) => !x.verified)
        .filter((x) => x.status !== enums.RequestStatusString.Canceled)
        .every((x) => x.request_total_time > 0);
    };

    $scope.verifyAll = (excludeActive) => {
      $scope.sessions.forEach((x) => (x.draft = false));
      const data = timeReportDataService.buildRequestList(
        $scope.sessions
          .filter($scope.futureFilter)
          .filter((item) => item.status !== enums.RequestStatusString.Canceled)
          .filter((item) => (excludeActive ? item.status !== enums.RequestStatusString.Active : true)),
        $scope.orignalSessions
          .filter($scope.futureFilter)
          .filter((item) => item.status !== enums.RequestStatusString.Canceled)
          .filter((item) => (excludeActive ? item.status !== enums.RequestStatusString.Active : true)),
        $scope.verifyAttribute
      );
      $scope.verifyCall(data, true);
    };

    $scope.verifySession = (session) => {
      session.draft = false;
      $scope.sendDrafts();
      if (session.request_total_time_model && session.request_total_time > 0) {
        const sessions = [];
        sessions.push(session);
        const data = timeReportDataService.buildRequestList(sessions, $scope.orignalSessions, $scope.verifyAttribute);
        $scope.verifyCall(data);
      }
    };

    $scope.addToDraft = (session) => {
      session.draft = true;
    };

    $scope.sendDrafts = () => {
      if ($scope.CURRENT_USER.allow_service_request_drafts) {
        const data = timeReportDataService.buildRequestList(
          $scope.sessions.filter((s) => s.draft),
          $scope.orignalSessions,
          $scope.verifyAttribute
        );
        $scope.saveDraftCall(data);
      }
    };

    $scope.noShowQuestion = async (session) => {
      const config = timeReportUIService.getSwalNoShowQuestion();

      const returnHandler = function (isConfirm) {
        if (!isConfirm) {
          return;
        }
        $scope.sendDrafts();
        const payload = timeReportDataService.buildNoShowPayload(session);
        sessionManagerService.endSessionRequest(payload).then(() => {
          $scope.refresh(false);
          $rootScope.$broadcast('CloseMobileChangeModal');
        });
      };
      const { value: returnValue } = await Swal.fire(config);
      returnHandler(returnValue);
    };

    $scope.unverifySessionQuestion = async function (session) {
      const config = timeReportUIService.getSwalUnverifySessionQuestion();

      const returnHandler = function (isConfirm) {
        if (!isConfirm) {
          return;
        }
        $scope.sendDrafts();
        $scope.unverifySession(session);
      };

      const { value: returnValue } = await Swal.fire(config);
      returnHandler(returnValue);
    };

    $scope.unverifySession = (session) => {
      const data = {
        request_id: session.request_id,
      };

      if ($scope.verifyAttribute) {
        data.verify_attribute = $scope.verifyAttribute.data_content;
      }

      $scope.showSpinner = true;
      timeReportNetworkService.postOrderTimesheetUnverifyShifts(data).then(
        function (response) {
          if (response.success) {
            $scope.sessions = $scope.sessions.map((_session) => {
              if (_session.request_id === session.request_id) {
                _session.verified = false;
              }
              return _session;
            });
            $scope.unVerifiedRequests = $scope.sessions.filter((session) => !session.verified).length;
            $scope.setPieChartData();
            $scope.showVerifyAll = !$scope.hideVerifyButton;
          } else {
            // handle error from server
            AlertService.errorAlert(response.message);
          }
          $scope.showSpinner = false;
        },
        function (reason) {
          // handle network error
          AlertService.serverRequestErrorAlert(reason);
          $scope.showSpinner = false;
        }
      );
    };

    $scope.setPieChartData = () => {
      $scope.verifiedRequestsWithoutCanceled = $scope.sessions.filter((session) => {
        return session.verified && session.status !== enums.RequestStatusString.Canceled;
      }).length;

      $scope.allVerifiedRequestsWithoutCanceled = $scope.sessions.filter((session) => {
        return session.status !== enums.RequestStatusString.Canceled;
      }).length;
    };

    $scope.$on('$destroy', function () {
      unwatchEntFilter();
      unwatchSelectedBranchChanged();
      unwatchBranchSelectionChanged();
      unbindEnterpriseSelectionChanged();
      unbindSelectedEnterpriseChanged();
      $window.removeEventListener('scroll', onScrollHandlers);
      clearInterval($scope.draftInterval);
      $scope.sendDrafts();
    });

    $scope.doNotRepeat = async function () {
      const alertConfig = {
        title: 'Are you sure?',
        text: 'You do not want to repeat this order',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Confirm',
      };
      const returnHandler = function (isConfirm) {
        if (isConfirm) {
          const payload = {
            custom_data: {
              repeat_data: {
                repeat_order: false,
              },
            },
          };

          if ($scope.verifyAttribute) {
            payload.verify_attribute = $scope.verifyAttribute.data_content;
            payload.attrib_value = $scope.data.selectedJobOrder.attrib_value;
          } else {
            payload.job_id = $scope.data.selectedJobOrder.job_id;
            payload.job_instance = $scope.data.selectedJobOrder.job_instance;
          }
          timeReportNetworkService
            .postOrderTimesheetRepeatOrders(payload)
            .then((response) => {
              if (response.success) {
                $scope.showSwal('Success', response.message, 'success', true);
              } else {
                $scope.showSwal('Error', response.message, 'error', true);
              }
            })
            .catch((error) => {
              $scope.showSwal('Error', error.message, 'error', true);
            });
        }
      };
      const { value: returnValue } = await Swal.fire(alertConfig);
      returnHandler(returnValue);
    };

    $scope.showSwal = async function (title, text, icon, supressRefresh) {
      const config = {
        title,
        text,
        icon,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Ok',
      };

      const returnHandler = function () {
        $scope.refresh(!supressRefresh);
      };
      await Swal.fire(config);
      returnHandler();
    };

    $scope.showRepeatModal = function (uiTrigger) {
      const modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/reporting/timeReports/repeatJobOrderModal.html',
        controller: 'repeatJobOrderModalController',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          selectedJobOrder: function () {
            return $scope.data.selectedJobOrder;
          },
          verifyAttribute: function () {
            return $scope.verifyAttribute;
          },
          userTriggered: function () {
            return uiTrigger;
          },
        },
      });
      modalInstance.result.then(function (response) {
        $scope.refresh(!uiTrigger);
      });
    };

    $scope.showRepeatData = function () {
      $modal.open({
        animation: true,
        templateUrl: 'app/views/reporting/timeReports/repeatDataShow.html',
        controller: 'repeatDataShowController',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          repeatData: function () {
            return $scope.repeatData;
          },
        },
      });
    };

    $scope.refresh = function (reloadChoices, verifiedSession) {
      if ($scope.selectedFilterType === enums.SelectedTimeReportFilterType.JobOrder) {
        if (reloadChoices) {
          $scope.resetTable();
          $scope.getTimesheetChoices(true);
        } else {
          $scope.selectFirstItemInDropdown();
        }
      } else {
        $scope.getVerifyJobSessions($scope.data.selectedRange, verifiedSession);
      }
    };

    $scope.openMobileChangeModal = function (session) {
      const modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/reporting/timeReports/timeReportsChangeMobileModal.html',
        controller: 'timeReportsChangeMobileModalController',
        size: 'lg',
        backdrop: 'static',
        scope: $scope,
        resolve: {
          session: function () {
            return session;
          },
          sendDrafts: function () {
            return $scope.CURRENT_USER.allow_service_request_drafts;
          },
        },
      });
      modalInstance.result.then(function () {
        $scope.refresh(true);
      });
    };

    $scope.getStatus = function (session) {
      return session.cancelled_reason === 12 ? 'No show' : session.status;
    };

    $scope.sortShifts = (value) => {
      const sort = (value) => {
        if ($scope.selectedFilterType === 0) {
          switch (value) {
            // Count ASC
            case 1:
              $scope.verifyJobOrders.sort((a, b) => a.total_cnt - b.total_cnt);
              break;
            // Count DESC
            case 2:
              $scope.verifyJobOrders.sort((a, b) => b.total_cnt - a.total_cnt);
              break;
            // Start ASC
            case 3:
              $scope.verifyJobOrders.sort((a, b) =>
                moment(a.start_date, TimeFormatService.format('middleDate'))
                  .format(TimeFormatService.format('fullDate'))
                  .localeCompare(
                    moment(b.start_date, TimeFormatService.format('middleDate')).format(
                      TimeFormatService.format('fullDate')
                    )
                  )
              );
              break;
            // Start DESC
            case 4:
              $scope.verifyJobOrders.sort((a, b) =>
                moment(b.start_date, TimeFormatService.format('middleDate'))
                  .format(TimeFormatService.format('fullDate'))
                  .localeCompare(
                    moment(a.start_date, TimeFormatService.format('middleDate')).format(
                      TimeFormatService.format('fullDate')
                    )
                  )
              );
              break;
            // Alphabetical ASC
            case 5:
              $scope.verifyJobOrders.sort((a, b) => a.desc.localeCompare(b.desc));
              break;
            // Alphabetical DESC
            case 6:
              $scope.verifyJobOrders.sort((a, b) => b.desc.localeCompare(a.desc));
              break;
          }
        } else if ($scope.selectedFilterType === 1) {
          switch (value) {
            // Start ASC
            case 3:
              $scope.verifyDateRange.sort((a, b) => a.start.localeCompare(b.start));
              break;
            // Start DESC
            case 4:
              $scope.verifyDateRange.sort((a, b) => b.start.localeCompare(a.start));
              break;
          }
        }
      };

      const save = (value) => {
        let selectedSort = localStorage.getItem('selectedSort') || {};
        if (selectedSort) {
          try {
            selectedSort = JSON.parse(selectedSort);
          } catch (e) {
            selectedSort = {};
          }
        }
        if (!selectedSort) {
          selectedSort = {};
        }

        selectedSort[$scope.selectedFilterType] = value;
        localStorage.setItem('selectedSort', JSON.stringify(selectedSort));
        $scope.selectedSort = selectedSort;
      };

      sort(value);
      save(value);
    };

    $scope.filterByDate = (value, skipChoicesLoading = false) => {
      if ($scope.selectedFilterType === 0 && $scope.dateFilterValue !== value) {
        $scope.dateFilterValue = value;
        if ($scope.dateFilterValue !== 8) {
          const { start, end } = TimeService.getRange(value);
          $scope.startDate = start;
          $scope.endDate = end;
          if (!skipChoicesLoading) {
            $scope.getTimesheetChoices();
          }
        }
      }
    };

    $scope.customDateSelectionChanged = (value) => {
      if (value?.timeRange) {
        $scope.startDate = value.timeRange.start;
        $scope.endDate = value.timeRange.end;
        $scope.getTimesheetChoices();
      }
    };

    // $scope.shouldFixHeader = 0;
    let tabMenuItems;
    let sticky;

    function initStickyHeader() {
      tabMenuItems = document.getElementById('mobile-time-reports-tab-menu-items');
      if (!tabMenuItems) {
        return;
      }

      sticky = tabMenuItems.offsetTop;
    }

    function onScrollHandlers() {
      if (document.getElementsByClassName('time-picker-container').length) {
        timeReportUIService.checkIfElementInsideViewport('.time-picker-container', $scope.closeAllPicker);
      } else if (document.getElementsByClassName('time-interval-container').length) {
        timeReportUIService.checkIfElementInsideViewport('.time-interval-container', $scope.closeAllPicker);
      } else if (document.getElementsByClassName('datetime-picker-container').length) {
        timeReportUIService.checkIfElementInsideViewport('.datetime-picker-container div', $scope.closeAllPicker);
      }
    }

    $window.addEventListener('scroll', onScrollHandlers);
    $window.addEventListener('beforeunload', $scope.sendDrafts);

    async function initialize() {
      $scope.draftInterval = setInterval(() => {
        $scope.sendDrafts();
      }, 60000);

      initStickyHeader();
      getFirstFilterName();

      // set filter button
      const selectedFilterType = localStorage.getItem('selectedFilterType');
      if (selectedFilterType !== undefined && selectedFilterType !== null) {
        // 0 -> Job Order 1 -> Extract
        $scope.selectedFilterType = parseInt(selectedFilterType, 10);
      } else {
        $scope.selectedFilterType = enums.SelectedTimeReportFilterType.Extract;
      }

      if (!$scope.keyAttrib.display_name) {
        getKeyAttribDisplayName();
      }

      // check enterprise id in the url to preselection
      if ($scope.params.entId) {
        if ($scope.CURRENT_USER.user_type === 3) {
          if ($scope.CURRENT_USER.enterprise_info?.id != $scope.params.entId) {
            const config = timeReportUIService.getSwalWrongEnterpriseId();

            const returnHandler = function () { };

            await Swal.fire(config);
            returnHandler();
          }
        } else {
          const result = await rxjs.firstValueFrom(
            NgrxStore.select((x) => x.commonData.enterpriseList).pipe(operators.filter((x) => !!x))
          );
          const selectedEnt = result.filter((ent) => ent.id === parseInt($scope.params.entId));

          if (selectedEnt && selectedEnt.length) {
            branchFilterService.setSelectedBranch(parseInt(selectedEnt[0].branch_id));
            $scope.branchFilter = branchFilterService.getSelectedBranch();
            EnterpriseFilterService.setSelectedEnterprise(selectedEnt[0]);
            $scope.entFilter = EnterpriseFilterService.fetchSelectedEnterprise();
          } else {
            const config = timeReportUIService.getSwalWrongEnterpriseId();

            const returnHandler = function () { };

            await Swal.fire(config);
            returnHandler();
          }
        }
      }
      let selectedSort = localStorage.getItem('selectedSort');
      if (selectedSort) {
        try {
          selectedSort = JSON.parse(selectedSort);
        } catch (e) {
          selectedSort = {};
        }
      }

      if (!selectedSort) {
        selectedSort = {};
      }

      $scope.selectedSort = selectedSort;
      $scope.sortShiftValue = selectedSort[$scope.selectedFilterType];
      $scope.filterByDate(1, true);
      $scope.getTimesheetChoices();

      $scope.popover = {
        verifierStatus: 'app/views/templates/verifier_info.html',
      };
    }
  },
]);
