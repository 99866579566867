import module from '../module';
export default module.controller('EditJobOrderModalController', [
  '$scope',
  '$modalInstance',
  'passedSession',
  'sessionManagerService',
  'AlertService',
  'passed_current_worker_count',
  'passed_total_worker_count',
  'passed_job_order_id',
  function (
    $scope,
    $modalInstance,
    passedSession,
    sessionManagerService,
    AlertService,
    passed_current_worker_count,
    passed_total_worker_count,
    passed_job_order_id
  ) {
    $scope.minWorkers = passedSession ? passedSession.current_workers : passed_current_worker_count;
    if (!$scope.session) {
      $scope.session = passedSession;
    }
    $scope.formData = {
      job_order_id: $scope.session ? $scope.session.jobOrderId : passed_job_order_id,
      worker_count: $scope.session ? $scope.session.total_workers : passed_total_worker_count,
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.ok = function () {
      sessionManagerService.changeJobOrder($scope.formData).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
            $modalInstance.close();
          } else {
            AlertService.errorAlert(response.message);
            $modalInstance.dismiss('cancel');
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.dismiss('cancel');
        }
      );
    };
  },
]);
