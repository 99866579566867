import { NgModule } from '@angular/core';
import { WorknErrorPageComponent } from './workn/workn-error-page.component';
import { AvionteErrorPageComponent } from './avionte/avionte-error-page.component';
import { SmartlinkErrorComponent } from './error/smartlink-error.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { SmartLinkPage } from './smart-link-page/smart-link-page.component';
import { InfoPageComponent } from './info-page/info-page.component';

@NgModule({
  imports: [CommonModule, MatLegacyButtonModule, MatTooltipModule, MatIconModule, RouterModule],
  exports: [SmartlinkErrorComponent],
  declarations: [
    AvionteErrorPageComponent,
    InfoPageComponent,
    SmartlinkErrorComponent,
    SmartLinkPage,
    WorknErrorPageComponent,
  ],
})
export class SmartLinkModule {
  constructor() {}
}
