import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './login/pages/login-page.component';
import { SchedulePageComponent } from './schedule/pages/schedule-page.component';
import { JobOrderReportPageComponent } from './jobs/pages/job-order-report/job-order-report.page';
import { WorkersPageComponent } from './workers/pages/workers-page/workers.page';
import { WorkerPage } from './workers/pages/worker-page/worker.page';
import { AnalyticsComponent } from './analytics/analytics-container';
import { ReportingPageComponent } from './reporting/pages/reporting.page';
import { AdminPageComponent } from './admin/pages/admin.page';
import { SupportPageComponent } from './support/pages/support.page';
import { TokenGuard } from './shared/guards/token.guard';
import { JobPageComponent } from './schedule/pages/job-page.component';
import { SessionPageComponent } from './schedule/pages/session-page.component';
import { SmartLinkPage } from './smart-link/smart-link-page/smart-link-page.component';
import { ForgotPasswordPageComponent } from './login/pages/forgot-password.page.component';
import { ResetPasswordPageComponent } from './login/pages/reset-password.page.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginPageComponent, data: { showBg: true } },
  { path: 'ssoLogin', component: LoginPageComponent, data: { showBg: true } },
  { path: 'forgot-password', component: ForgotPasswordPageComponent, data: { showBg: true } },
  { path: 'reset-password', component: ResetPasswordPageComponent, data: { showBg: true } },
  {
    path: 'schedule/:jobOrderId',
    component: SchedulePageComponent,
    data: {
      title: 'Schedule',
      activetab: 'schedule',
    },
    canActivate: [TokenGuard],
  },
  {
    path: 'schedule',
    component: SchedulePageComponent,
    data: {
      title: 'Schedule',
      activetab: 'schedule',
    },
    canActivate: [TokenGuard],
  },
  {
    path: 'job-order-report',
    component: JobOrderReportPageComponent,
    data: {
      title: 'Jobs',
      activetab: 'job',
    },
    canActivate: [TokenGuard],
  },
  {
    path: 'workers/:userId/:providerId',
    component: WorkerPage,
    data: {
      title: 'Workers',
      activetab: 'workers',
    },
    canActivate: [TokenGuard],
  },
  {
    path: 'workers',
    component: WorkersPageComponent,
    data: {
      title: 'Workers',
      activetab: 'workers',
    },
    canActivate: [TokenGuard],
  },
  {
    path: 'analytics',
    component: AnalyticsComponent,
    data: {
      title: 'Dashboard',
      activetab: 'analytics',
    },
    canActivate: [TokenGuard],
  },
  {
    path: 'reporting/time-verification',
    component: ReportingPageComponent,
    data: {
      title: 'Time Keeping',
      activetab: 'reporting',
    },
    canActivate: [TokenGuard],
  },
  {
    path: 'reporting/analytics',
    component: ReportingPageComponent,
    data: {
      title: 'Time Keeping',
      activetab: 'reporting',
    },
    canActivate: [TokenGuard],
  },
  {
    path: 'reporting/overview',
    component: ReportingPageComponent,
    data: {
      title: 'Time Keeping',
      activetab: 'reporting',
    },
    canActivate: [TokenGuard],
  },
  {
    path: 'reporting/map-views',
    component: ReportingPageComponent,
    data: {
      title: 'Time Keeping',
      activetab: 'reporting',
    },
    canActivate: [TokenGuard],
  },
  {
    path: 'reporting/time-verification-original',
    component: ReportingPageComponent,
    data: {
      title: 'Time Keeping',
      activetab: 'reporting',
    },
    canActivate: [TokenGuard],
  },
  {
    path: 'reporting',
    component: ReportingPageComponent,
    data: {
      title: 'Time Keeping',
      activetab: 'reporting',
    },
    canActivate: [TokenGuard],
  },
  {
    path: 'admin',
    component: AdminPageComponent,
    data: {
      title: 'Admin',
      activetab: 'admin',
    },
    canActivate: [TokenGuard],
  },
  {
    path: 'support',
    component: SupportPageComponent,
    data: {
      title: 'Support',
      activetab: 'support',
    },
    canActivate: [TokenGuard],
  },
  {
    path: 'job',
    component: JobPageComponent,
    data: {
      title: 'Job',
      activetab: 'schedule',
    },
    canActivate: [TokenGuard],
  },
  {
    path: 'session',
    component: SessionPageComponent,
    data: {
      title: 'Job',
      activetab: 'schedule',
    },
    canActivate: [TokenGuard],
  },
  {
    path: 'session/:fromJobs',
    component: SessionPageComponent,
    data: {
      title: 'Job',
      activetab: 'schedule',
    },
    canActivate: [TokenGuard],
  },
   {
    path: 'info',
    component: SmartLinkPage,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
