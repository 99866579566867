import module from '../module';
import moment from 'moment';

export default module.controller('repeatDataShowController', [
  '$scope',
  '$modalInstance',
  'repeatData',
  'TimeFormatService',
  function ($scope, $modalInstance, repeatData, TimeFormatService) {
    const utcToLocal = (date, time) => moment.utc(`${date} ${time}`).local().format();

    if (repeatData) {
      const localDateTime = moment(utcToLocal(repeatData.date, repeatData.time));
      $scope.repeatData = { ...repeatData, date: localDateTime.format(TimeFormatService.format('fullDate')), time: localDateTime.format('LT') };
    } else {
      $scope.repeatData = {};
    }

    $scope.errorMessage = 'Please fill out the form before sending the order';
    $scope.showError = false;
    $scope.comments = '';
    $scope.optionalWorkerNumbers = [];
    $scope.selectedWorkerNumber = '';
    $scope.showTimepicker = false;
    $scope.showDateTimepicker = false;
    $scope.timepicker = {
      modelObj: moment(new Date()),
    };
    $scope.timepickerInputModel = '';
    $scope.dateTimepickerModel = '';

    $scope.datetimePickerConfig = {
      startViewTime: 'minute',
      minuteStep: 1,
    };

    $scope.timePickerConfig = {
      hoursStep: 1,
      minuteStep: 1,
      mouseWheel: true,
    };

    const maxWorkerNumer = 100;
    for (let i = 1; i <= maxWorkerNumer; i++) {
      $scope.optionalWorkerNumbers.push(i);
    }

    // modal close, no
    $scope.cancel = function () {
      $modalInstance.close('cancel');
    };
  },
]);
