import { Component, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../store';
import { ToggleWidget, ExportToCsv } from '../store/actions';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { Spinner } from 'spin.js';

@Component({
  template: '',
})
export abstract class BaseWidget {
  public requests: any[];
  public data: any[] = [];
  public count: number;
  public isLoading: boolean;
  public enterpriseId: number;
  public timeRange: any;
  public showChart = false;
  public chartSize = [400, 200];
  public widgetData: any;
  public titleText = 'Cancelled';
  public titleValue;
  public dragMode: boolean;
  public chartType = '';
  @ViewChild('spinHolder', { static: false }) spinHolder;
  private subscriptions: any[] = [];
  constructor(protected store: Store<fromRoot.State>) {}

  startSpinner() {
    if (
      this.spinHolder &&
      this.spinHolder.nativeElement &&
      this.spinHolder.nativeElement.children &&
      this.spinHolder.nativeElement.children.length === 0
    ) {
      let spinner = new Spinner().spin();
      this.spinHolder.nativeElement.appendChild(spinner.el);
    }
  }

  ngOnInit() {
    this.showChart = this.widgetData.chartMode;
    this.subscriptions.push(
      this.store.select(fromRoot.getIsLoading).subscribe((isLoading) => (this.isLoading = isLoading))
    );

    this.subscriptions.push(
      combineLatest(this.store.select(fromRoot.getRequests), this.store.select(fromRoot.getTimeRange)).subscribe(
        (res) => {
          this.requests = res[0];
          let range = res[1];
          if (!this.requests) {
            return;
          }
          let list = this.requests.map((i) => ({
            ...i,
            duration: i?.duration,
          }));
          this.loadData(list);
        }
      )
    );

    this.subscriptions.push(
      this.store.select(fromRoot.getDragMode).subscribe((dragMode) => (this.dragMode = dragMode))
    );
  }

  loadData(list: any) {
    this.startSpinner();
    this.loadDataTemplate(list);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  toggleChart() {
    this.showChart = !this.showChart;
  }

  closeWidget() {
    this.store.dispatch(new ToggleWidget(this.widgetData, true));
  }

  exportToCSV() {
    this.store.dispatch(
      new ExportToCsv(this.data, {
        title: this.widgetData.title,
        name: this.widgetData.name,
        mainData: this.titleValue,
        mainText: this.titleText,
      })
    );
  }

  abstract loadDataTemplate(list: any);
}
