import { createFeatureSelector, createSelector, Store, select } from '@ngrx/store';
import { State } from '../../store';
import { CommonState, createRealmKey } from './state';
import { Injectable } from '@angular/core';

import { Observable, combineLatest, filter, map } from 'rxjs';
import { IBranchList, IEnterpriseList, IEnterpriseSelection } from '../interfaces';
import { FetchBranchList, FetchEnterpriseList, FetchRealmAttribs } from './actions';

const MARKETPLACE_ID = -1;

const selectRoot = createFeatureSelector<State>('commonData');
const selectFeature = createSelector(selectRoot, (state: any) => state);

const selectBranchList = createSelector(selectFeature, (state: CommonState) => state.branchList);

const selectEnterpriseList = createSelector(selectFeature, (state: CommonState) => state.enterpriseList);
const selectEnterpriseSelection = createSelector(selectFeature, (state: CommonState) => state.selection);
const selectBranchSelection = createSelector(selectFeature, (state: CommonState) => state.selectedBranch);

const selectRealmAttribs = createSelector(selectFeature, (state: CommonState) => state.realmAttribs);

const selectWebsocketMessages = createSelector(selectFeature, (state: CommonState) => state.websocketMessages);

@Injectable({ providedIn: 'root' })
export class CommonSelectors {
  constructor(private _store: Store<State>) {}

  getBranchList(): Observable<IBranchList[]> {
    return this._store.pipe(
      select(selectBranchList),
      map((list) => {
        if (!list || !list.length) {
          this._store.dispatch(new FetchBranchList());
        }
        return list;
      }),
      filter((x) => x && !!x.length)
    );
  }

  getEnterpriseList(): Observable<IEnterpriseList[]> {
    return this._store.pipe(
      select(selectEnterpriseList),
      map((list) => {
        if (!list || !list.length) {
          this._store.dispatch(new FetchEnterpriseList());
        }
        return list;
      }),
      filter((x) => x && !!x.length)
    );
  }

  getEnterpriseSelection(): Observable<IEnterpriseSelection> {
    return this._store.pipe(select(selectEnterpriseSelection));
  }

  getBranchSelection(): Observable<any> {
    return this._store.pipe(select(selectBranchSelection));
  }

  getVisibleEnterprises(): Observable<IEnterpriseList[]> {
    return combineLatest([this.getEnterpriseList(), this.getBranchSelection()]).pipe(
      map(([enterpiseList, branch]) => {
        if (branch.id && branch.id !== MARKETPLACE_ID) {
          const result = enterpiseList?.filter((x) => x.branch === branch.id);
          result.unshift({
            id: MARKETPLACE_ID,
            name: 'Branch',
            enterprise_id: MARKETPLACE_ID,
          } as unknown as IEnterpriseList);
          return result;
        } else {
          return [...enterpiseList];
        }
      })
    );
  }

  getAttribsForRealm(realmId: number): Observable<{ success: boolean; attrib_list?: any[]; message?: string }> {
    return combineLatest([this.getEnterpriseSelection(), this._store.pipe(select(selectRealmAttribs))]).pipe(
      map(([enterpsise, attribs]) => {
        const key = createRealmKey(enterpsise.id, realmId);
        const realm = attribs[key];
        if (realm) {
          return realm;
        } else {
          this._store.dispatch(new FetchRealmAttribs(realmId, enterpsise.id));
        }
      }),
      filter((x) => !!x)
    );
  }

  getWebsocketMessagesForEnterprise(enterpriseId: number): Observable<unknown[]> {
    return this._store.pipe(
      select(selectWebsocketMessages),
      map((x) => x[enterpriseId])
    );
  }
}
