import module from '../module';

export default module.controller('NewPoolModalController', [
  '$scope',
  '$modalInstance',
  '$modal',
  'providerManagerService',
  'AlertService',
  'enterprise_selection',
  '$localStorage',
  'AuthenticationService',
  'poolManagerService',
  function (
    $scope,
    $modalInstance,
    $modal,
    providerManagerService,
    AlertService,
    enterprise_selection,
    $localStorage,
    AuthenticationService,
    poolManagerService
  ) {
    $scope.keyProfession = $localStorage.generalData.filter(function (obj) {
      return obj.data_name === 'key_profession';
    })[0];
    const CURRENT_USER = AuthenticationService.getCurrentUser();

    $scope.submitBtnDisbaled = false;
    $scope.showSpinner = true;
    let passedEnt = enterprise_selection;
    if (!passedEnt) {
      passedEnt = {
        enterprise_id: CURRENT_USER.enterprise_info.id,
      };
    }
    $scope.enterpriseId = passedEnt.enterprise_id;

    $scope.provider_list = [];

    $scope.formData = {
      enterprise_id: $scope.enterpriseId,
      ent_only: false,
      provider_list: [],
    };

    // Modal controls
    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      if ($scope.selectedProviders) {
        if (!$scope.selectedProviders.length) {
          $scope.submitBtnDisbaled = false;
          AlertService.warningAlert(
            'Sorry, you cannot have 0 workers in a pool. Please Delete the pool or leave at least one worker in it.'
          );
          return;
        }

        $scope.formData.provider_list = $scope.selectedProviders.map((p) => ({
          provider_id: p.provider_id,
        }));
      }
      addNewPool();
    };

    function addNewPool() {
      poolManagerService.addNewPool($scope.formData).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
          } else {
            AlertService.errorAlert(response.message);
          }
          $modalInstance.close();
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    }

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.loadProvider = function (userId) {
      providerManagerService.getProvider(userId).then(function (provider) {
        showProviderModal(provider);
      });
    };

    function showProviderModal(provider) {
      const modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/job/providerModal.html',
        controller: 'ProviderModalController',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          provider: function () {
            provider.hidePermissions = true;
            return provider;
          },
        },
      });
      modalInstance.result.then(function () {});
    }

    $scope.type = 'Add New Pool'; // Modal Title
    $scope.btnLabel = 'Add Pool';
  },
]);
