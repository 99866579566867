import { NgModule, forwardRef, ErrorHandler } from '@angular/core';
import { UpgradeAdapter } from '@angular/upgrade';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReportingUpgradeDirective } from './directives/reporting-upgrade.directive';
import { ReportingPageComponent } from './pages/reporting.page';

import {
  sessionServiceProvider,
  providerServiceProvider,
  enterpriseFilterServiceProvider,
} from '../upgraded.providers';

import { AnalyticsPageComponent } from './analytics/pages/analytics.page.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [BrowserModule, BrowserAnimationsModule, FormsModule, ReactiveFormsModule, HttpClientModule, SharedModule],
  declarations: [AnalyticsPageComponent, ReportingUpgradeDirective, ReportingPageComponent],
  entryComponents: [AnalyticsPageComponent],
  providers: [sessionServiceProvider, providerServiceProvider, enterpriseFilterServiceProvider],
})
export class ReportingModule {
  constructor() {}
}
