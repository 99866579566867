import module from './module';
import moment from 'moment';
export default module.directive('dirDynamicAttribFormField', function () {
  function alphabetical(a, b) {
    const A = a.name.toLowerCase();
    const B = b.name.toLowerCase();
    if (A < B) {
      return -1;
    } else if (A > B) {
      return 1;
    } else {
      return 0;
    }
  }
  return {
    restrict: 'E',
    scope: {
      field: '=',
      type: '=',
      form: '=',
      entFilter: '=',
      index: '=',
      translation: '=',
      isdisabled: '=',
      tooltip: '=',
      hideOtherLocation: '=',
      showConfidential: '=',
    },
    controller: [
      '$scope',
      'locationManagerService',
      'DOMAIN',
      'TimeFormatService',
      'LocationProvider',
      function ($scope, locationManagerService, DOMAIN, TimeFormatService, LocationProvider) {
        $scope.onImageChangeHandler = function (event, file) {
          if (file[0].type.indexOf('image') != -1) {
            if (FileReader) {
              const _canvasId = $scope.field.name + '_' + $scope.index;
              const canvas = document.getElementById(_canvasId);
              const context = canvas.getContext('2d');

              // Draw imag to canvas inroder to convert to jpeg and resize
              const imageObj = new Image();
              imageObj.onload = function () {
                canvas.width = imageObj.width;
                canvas.height = imageObj.height;
                context.drawImage(imageObj, 0, 0);
                $scope.field.jpeg = canvas.toDataURL().replace(/^data:image\/(png);base64,/, '');
                $scope.imageUrl = canvas.toDataURL();
              };

              // File Reader to set image obj
              const fr = new FileReader();
              fr.onload = function () {
                imageObj.src = fr.result;
              };
              fr.readAsDataURL(file[0]);
            } // end if FileReader Present

            $scope.showRemoveBtn = true;
            delete $scope.imageError;
            delete $scope.imageUrl;
          } else {
            $scope.removeFile();
            $scope.imageError = 'Only images are allowed';
          }
        };

        $scope.removeFile = function (e) {
          e ? e.preventDefault() : null;
          const _name = $scope.field.name + '_' + $scope.index.toString();
          $('input[name=' + _name + ']').val(null);
          delete $scope.field.value;
          $scope.field.jpeg ? delete $scope.field.jpeg : null;
          $scope.imageUrl = null;
          $scope.showRemoveBtn = false;
        };

        $scope.addAdditionalAttrib = function (e) {
          e ? e.preventDefault() : null;
          $scope.$emit('addAttribInstanceClicked', $scope.field, $scope.index);
        };

        $scope.removeAdditionalAttrib = function (e) {
          e ? e.preventDefault() : null;
          $scope.removeFile();
          $scope.$emit('removeAttribInstanceClicked', $scope.field, $scope.index);
        };

        $scope.addInstanceBtnDisplay = function () {
          const _count = $scope.field.instanceCountIndex || 1;
          if (_count == $scope.field.instance_count || $scope.field.doNotShowAddInstance) {
            return false;
          } else {
            return true;
          }
        };
        $scope.removeInstanceBtnDisplay = function () {
          if ($scope.field.showRemoveInstance && $scope.field.instanceCountIndex > 1) {
            return true;
          }
        };

        $scope.multipleInstances = function () {
          if ($scope.field.instance_count && $scope.field.instance_count != 1) {
            return true;
          }
        };
        /// ///////////////////////////////////////////////////////////////////////////
        const editSwitch = function (usedAttrib) {
          switch (usedAttrib.type) {
            case 'password':
              break;
            case 'single_list':
              $scope.field.value = usedAttrib.value_list.filter(function (obj) {
                return obj.selected == true;
              })[0];
              $scope.field.choice_list = usedAttrib.value_list;
              break;
            case 'multi_list':
              $scope.field.value = usedAttrib.value_list;
              $scope.field.choice_list = usedAttrib.value_list;
              break;
            case 'text':
              if ($scope.field.name == 'biography') {
                $scope.rows = 4;
              } else {
                $scope.rows = 1;
              }
              $scope.field.value = usedAttrib.value_text;
              break;
            case 'image':
              if (usedAttrib.value_text) {
                $scope.imageUrl = DOMAIN + usedAttrib.value_text;
              }
              break;
            case 'count':
              $scope.field.min_amount = 0;
              $scope.field.value = usedAttrib.value_count;
              break;
            case 'decimal':
              $scope.field.min_amount = 0;
              $scope.field.value = usedAttrib.value_decimal;
              break;
            case 'location':
              locationManagerService.getAllLocations($scope.entFilter.enterprise_id).then(function (locationsObject) {
                if (locationsObject && $scope.entFilter.enterprise_id != -1) {
                  const locations = locationsObject[$scope.entFilter.enterprise_id];
                  $scope.locations = usedAttrib.value_location.map((location) => {
                    const loc = locations.find((_loc) => _loc.location_id === location.location_id);
                    location.place_list = loc?.place_list;
                    return location;
                  });
                  $scope.locations = LocationProvider.handleOtherLocation($scope.locations, $scope.hideOtherLocation);
                  $scope.locations.sort(alphabetical);
                  $scope.field.value = usedAttrib.value_location.filter(function (obj) {
                    return obj.selected == true;
                  })[0]; // assign selected location for display
                } else {
                  $scope.locations = LocationProvider.handleOtherLocation(
                    usedAttrib.value_location,
                    $scope.hideOtherLocation
                  );
                  $scope.locations.sort(alphabetical);
                  $scope.field.value = usedAttrib.value_location.filter(function (obj) {
                    return obj.selected == true;
                  })[0]; // assign selected location for display
                }
                locationManagerService.setLocation($scope.field.value);
              });

              $scope.changeLocation = (location) => {
                locationManagerService.setLocation(location);
              };
              break;
            case 'place':
              locationManagerService.addLocationChangeCallback(function () {
                const location = locationManagerService.getSelectedLocation();
                if (location) {
                  $scope.place_list = location.place_list;
                  const places = $scope.form.attrib_list.find((attr) => attr.name === 'place');
                  const selected = places.value_place.find((place) => place.selected);
                  if ($scope.place_list.find((place) => place.place_id === selected.place_id)) {
                    $scope.field.value = selected;
                  }
                }
              });
              break;
            case 'flag':
              $scope.field.value = usedAttrib.data || usedAttrib.value_flag;
              break;
            case 'date':
              $scope.dateField = { opened: false };
              $scope.openDateField = function () {
                $scope.dateField.opened = !$scope.dateField.opened;
              };
              break;
            case 'date_time':
              if (field.value_text) {
                const date = moment(field.value_text, TimeFormatService.format('fullDateNoSeparator')).clone();
                $scope.field.value = date;
              }
              // For contorlling opening and closing of date picker
              $scope.status = { opened: false };
              $scope.open = function () {
                $scope.status.opened = !$scope.status.opened;
              };
              break;
          } // end switch
        }; // end edit switch function

        const newSwitch = function (field) {
          switch (field.type) {
            case 'password':
              break;
            case 'single_list':
              if (field.choice_list) {
                const selected = field.choice_list.filter(function (item) {
                  return item.selected;
                });
                if (selected) {
                  field.value = selected[0];
                }
              }
              break;
            case 'multi_list':
              break;
            case 'text':
              if (field.name == 'biography') {
                $scope.rows = 4;
              } else {
                $scope.rows = 1;
              }
              if (field.default_text) {
                field.value = field.default_text;
              }
              break;
            case 'image':
              break;
            case 'count':
            case 'decimal':
              field.min_amount = 0;
              break;
            case 'location':
              locationManagerService.getAllLocations($scope.entFilter.enterprise_id).then(function (locationsObject) {
                if (locationsObject) {
                  const locations = locationsObject[$scope.entFilter.enterprise_id];
                  $scope.locations = LocationProvider.handleOtherLocation(locations, $scope.hideOtherLocation);
                  $scope.locations.sort(alphabetical);
                } else {
                  $scope.locations = LocationProvider.handleOtherLocation([], $scope.hideOtherLocation);
                  field.value = $scope.locations[0];
                }
              });

              $scope.changeLocation = (location) => {
                locationManagerService.setLocation(location);
              };
              break;
            case 'place':
              locationManagerService.addLocationChangeCallback(function () {
                const location = locationManagerService.getSelectedLocation();
                if (location) {
                  $scope.place_list = location.place_list;
                }
              });
              break;
            case 'flag':
              field.value = field.value || field.default_flag;
              break;
            case 'date':
              $scope.dateField = { opened: false };
              $scope.openDateField = function () {
                $scope.dateField.opened = !$scope.dateField.opened;
              };
              break;
            case 'date_time':
              if (field.value_text) {
                const date = moment(field.value_text, TimeFormatService.format('fullDateNoSeparator')).clone();
                $scope.field.value = date;
              }
              // For contorlling opening and closing of date picker
              $scope.status = { opened: false };
              $scope.open = function () {
                $scope.status.opened = !$scope.status.opened;
              };
              break;
            case 'date_range':
              if (!$scope.formData) {
                $scope.formData = {
                  timePeriod: null,
                  dateRangeObj: {},
                };
              }

              $scope.startDate = { opened: false };
              $scope.openStartDate = function () {
                $scope.startDate.opened = true;
              };

              $scope.endDate = { opened: false };
              $scope.openEndDate = function () {
                $scope.endDate.opened = true;
              };

              if (field.value && field.value.start && field.value.end) {
                $scope.formData.dateRangeObj.start = field.value.start;
                $scope.formData.dateRangeObj.end = field.value.end;
              }

              $scope.setEndDateMin = function (startDate) {
                $scope.minEndDate = moment(startDate);
                // ensure user does not set end date before start
                if (moment($scope.formData.dateRangeObj.end).isBefore(moment(startDate))) {
                  $scope.formData.dateRangeObj.end = startDate;
                }
                $scope.setEndDate();
              };

              $scope.setEndDate = function () {
                $scope.field.value = {
                  start: $scope.formData.dateRangeObj.start,
                  end: $scope.formData.dateRangeObj.end,
                };
              };
              break;
          } // end switch
        }; // end new switch function

        // switch case based on field type
        if ($scope.type && $scope.type.toLowerCase().indexOf('edit') != -1) {
          const usedAttrib = $scope.form.attrib_list.filter(function (obj) {
            return obj.attrib_id == $scope.field.attrib_id;
          })[0];
          if (usedAttrib) {
            editSwitch(usedAttrib);
          } else {
            // meaning attrib wasn't previously used but could still be in the list i.e. turned on after creation so treat like new
            newSwitch($scope.field);
          }
        } else {
          newSwitch($scope.field);
        }

        $scope.$on('$destroy', function () {
          locationManagerService.deleteCallbacks();
        });
      },
    ], // end controller
    templateUrl: 'app/views/templates/dynamic_attrib_fields.html',
  };
});
