import {
  Component,
  ComponentRef,
  OnInit,
  OnDestroy,
  ComponentFactoryResolver,
  Input,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromRoot from '../store';

import { WidgetListService } from '../services/';

@Component({
  selector: 'dynamic-detail-component',
  templateUrl: './dynamic.detail.component.html'
})
export class DynamicDetailComponent implements OnInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef, static: true })
  container: ViewContainerRef;
  private componentRef: ComponentRef<{}>;
  @Input() widgetData;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private widgetListService: WidgetListService,
    private store: Store<fromRoot.State>
  ) { }

  ngOnInit() {
    this.store.select(fromRoot.getSelectedWidget).subscribe(widget => {
      if (this.componentRef) {
        this.componentRef.destroy();
        this.componentRef = null;
      }

      if (widget) {
        let componentType = <any>this.widgetListService.getDetailType(widget.name);
        let factory = this.componentFactoryResolver.resolveComponentFactory(componentType);
        this.componentRef = this.container.createComponent(factory);
        (<any>this.componentRef.instance).widgetData = widget;
      }
    });
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
