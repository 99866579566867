import angular from 'angular';
import module from './module';

export default module.factory('Pool', [
  '$http',
  'BASE_URL',
  '$q',
  function ($http, BASE_URL, $q) {
    function Pool(poolData) {
      if (poolData) {
        this.setData(poolData);
      }
    }

    Pool.prototype = {
      setData: function (poolData) {
        angular.extend(this, poolData);
      },
      delete: function () {
        const deferred = $q.defer();
        $http
          .post(BASE_URL + '/delete_service_pool', { pool_id: this.pool_id })
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function (response) {
            deferred.resolve(response);
          });
        return deferred.promise;
      },
      update: function (poolData) {
        const deferred = $q.defer();
        $http
          .post(BASE_URL + '/update_service_pool', poolData)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function (response) {
            deferred.resolve(response);
          });
        return deferred.promise;
      },
    };

    return Pool;
  },
]);
