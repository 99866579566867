import angular from 'angular';
import module from '../module';
import Swal from 'sweetalert2';
import moment from 'moment';

export default module.controller('ChangeTimeModalController', [
  '$scope',
  '$rootScope',
  '$modalInstance',
  'AlertService',
  'passedSession',
  'sessionManagerService',
  'FormatHelperService',
  'SessionsInJobService',
  'trackingService',
  'generalDataService',
  'TimeFormatService',
  async function (
    $scope,
    $rootScope,
    $modalInstance,
    AlertService,
    passedSession,
    sessionManagerService,
    FormatHelperService,
    SessionsInJobService,
    trackingService,
    generalDataService,
    TimeFormatService
  ) {
    $scope.submitBtnDisbaled = false;
    $scope.keySession = generalDataService.getKeySession();
    $scope.title = 'Change Time';
    $scope.session = angular.copy(passedSession);
    $scope.isRequeue = $scope.session.status.type === 7; // running this controller for a canceled session
    $scope.isNow = $scope.session.request_type === 2;
    $scope.maxEtaVisible = $scope.isRequeue && $scope.isNow;

    $scope.pickerOptions = {
      format: TimeFormatService.format('dateSlashTemplate'),
    };

    if ($scope.session.start) {
      $scope.sessionLocation = $scope.session.attrib_list?.find((x) => x.type === 'location')?.value_location;

      const adjustedTime = await TimeFormatService.formatTimezone(
        'fullDateTimeSecs',
        $scope.session.start,
        $scope.session.start,
        $scope.sessionLocation
      );

      $scope.timeZoneDate = adjustedTime;

      const adjustedEnd = await TimeFormatService.formatTimezone(
        'fullDateTimeSecs',
        $scope.session.end,
        $scope.session.end,
        $scope.sessionLocation
      );

      setTimeout(() => {
        $rootScope.$broadcast('locationChanged', $scope.sessionLocation);
      });

      $scope.originalStartDate = moment(adjustedTime).format(TimeFormatService.format('dateSlash'));
      $scope.originalStartTime = moment(adjustedTime).format('h:mm A');
      $scope.originalEndDate = moment(adjustedEnd).format(TimeFormatService.format('dateSlash'));
      $scope.originalEndTime = moment(adjustedEnd).format('h:mm A');
    } else {
      const startMomObj = moment.utc($scope.session.start_date_time, TimeFormatService.format('fullDateTime')).clone();
      $scope.originalStartDate = FormatHelperService.returnLocalTimeString(
        startMomObj,
        TimeFormatService.format('dateSlash')
      );
      $scope.originalStartTime = FormatHelperService.returnLocalTimeString(startMomObj, 'h:mm A');
      const endMomObj = moment
        .utc($scope.session.start_date_time, TimeFormatService.format('fullDateTime'))
        .add($scope.session.duration, 'm')
        .clone();
      $scope.originalEndDate = FormatHelperService.returnLocalTimeString(
        endMomObj,
        TimeFormatService.format('dateSlash')
      );
      $scope.originalEndTime = FormatHelperService.returnLocalTimeString(endMomObj, 'h:mm A');
    }

    $scope.formData = {};

    const newStartTimeUntouched = new Date().setHours(
      moment($scope.originalStartTime, 'h:mm A').format('HH'),
      moment($scope.originalStartTime, 'h:mm A').format('mm'),
      0
    );
    $scope.newDateObj = {
      startDate: $scope.originalStartDate,
      startTime: newStartTimeUntouched,
      duration: $scope.session.duration,
      maxEta: 20,
    };

    $scope.sliderModelStart = {
      options: {
        floor: 0,
        ceil: 180,
        step: 5,
        showTicks: false,
        translate: function (value) {
          return FormatHelperService.generateDurationLabel(value);
        },
      },
    };

    $scope.sliderModelDuration = {
      options: {
        floor: 0,
        ceil: 720,
        step: 15,
        showTicks: false,
        translate: function (value) {
          return FormatHelperService.generateDurationLabel(value);
        },
      },
    };

    // end date and time display
    $scope.newEndPeriod = {
      endTime: $scope.originalEndTime,
      endDate: $scope.originalEndDate,
    };

    const formatEndDate = function (startTime, startDate) {
      const newStartTime = moment(startTime).format('HHmm');
      const newStartDate = moment(startDate).format(TimeFormatService.format('fullDateNoSeparator'));
      const newEndDateTime = newStartDate + ' ' + newStartTime;
      $scope.newEndPeriod.endDate = moment(newEndDateTime, TimeFormatService.format('fullDateTimeNoSeparator'))
        .add($scope.newDateObj.duration, 'm')
        .format(TimeFormatService.format('dateSlash'));
    };

    $scope.updateEndDateDisplay = function (format) {
      if (format === 'date') {
        if (newStartTimeUntouched != $scope.newDateObj.startTime) {
          formatEndDate($scope.newDateObj.startTime, $scope.newDateObj.startDate);
        } else {
          formatEndDate($scope.session.start, $scope.newDateObj.startDate);
        }
      } else if (format === 'time') {
        $scope.newEndPeriod.endTime = moment($scope.newDateObj.startTime)
          .add($scope.newDateObj.duration, 'm')
          .format('h:mm A');
        if ($scope.originalStartDate == $scope.newDateObj.startDate) {
          formatEndDate(
            $scope.newDateObj.startTime,
            moment($scope.newDateObj.startDate, TimeFormatService.format('dateSlash'))
          );
        } else {
          formatEndDate($scope.newDateObj.startTime, $scope.newDateObj.startDate);
        }
      }
    };

    $scope.$watch('newDateObj.duration', function () {
      $scope.updateEndDateDisplay('time');
    });

    $scope.ok = async function () {
      $scope.submitBtnDisbaled = true;
      $scope.formData.maxEta = $scope.newDateObj.maxEta;
      if ($scope.newDateObj.duration != $scope.session.duration) {
        $scope.formData.duration = $scope.newDateObj.duration;
      }

      if (
        newStartTimeUntouched != $scope.newDateObj.startTime ||
        $scope.originalStartDate != $scope.newDateObj.startDate ||
        $scope.newDateObj.duration != $scope.session.duration
      ) {
        await formatDateTimes($scope.newDateObj);
      } else {
        await sendToServer($scope.formData);
      }
      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'submit');
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'cancel');
    };

    function isEmpty(object) {
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    }

    const sendToServer = async function (formData) {
      if ($scope.isRequeue) {
        // for canceled sessions, use the requeue api
        sendRequeue(formData);
        return;
      }

      if (isEmpty(formData)) {
        AlertService.errorAlert('Nothing has been changed');
        $scope.submitBtnDisbaled = false;
      } else {
        formData.request_id = $scope.session.session_id || $scope.session.request_id;
        await SessionsInJobService.isApplicable(formData.request_id).then(async function (response) {
          if (response.jobId && response.jobInstance) {
            await SessionsInJobService.fetchSessionsForJob(
              response.jobInstance,
              response.jobId,
              response.providerId
            ).then(async function (sessionsInJob) {
              const config = {
                title: 'Warning',
                text:
                  'This ' +
                  $scope.keySession +
                  ' is part of a Job with ' +
                  sessionsInJob.length +
                  ' shifts. Do you wish to apply this change to all remaining ' +
                  sessionsInJob.length +
                  ' ' +
                  $scope.keySession +
                  's or just this one?',
                showCancelButton: true,
                confirmButtonColor: '#cc514c',
                confirmButtonText: 'All Remaining',
                cancelButtonText: 'Just This One',
                icon: 'warning',
              };

              const returnHandler = async function (applyToAll) {
                if (applyToAll) {
                  const dayDiff = await calculateDayDiff();
                  sessionsInJob.sort((a, b) => {
                    const startA = `${a.request_date} ${a.request_time}`;
                    const startB = `${b.request_date} ${b.request_time}`;

                    return dayDiff > 0 ? startB.localeCompare(startA) : startA.localeCompare(startB);
                  });
                  for (const session of sessionsInJob) {
                    const copiedData = { ...formData };
                    copiedData.request_id = session.request_id;
                    copiedData.start_date_time = await formatSessionTime(session, dayDiff);
                    await sendRequest(copiedData);
                  }
                } else {
                  sendRequest(formData);
                }
              };

              const { value: returnValue, dismiss } = await Swal.fire(config);
              if (dismiss !== Swal.DismissReason.backdrop) {
                await returnHandler(returnValue);
              } else {
                $scope.submitBtnDisbaled = false;
              }
            });
          } else {
            sendRequest(formData);
          }
        });
      }
    };

    const sendRequest = function (formData) {
      return sessionManagerService.changeSessionTime(formData).then(
        async function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
            $modalInstance.close();
          } else if (response.shifts_overlap) {
            const returnHandler = async function (force) {
              if (force) {
                const copiedData = { ...formData };
                copiedData.allow_shift_overlap = true;
                sendRequest(copiedData);
              } else {
                $modalInstance.dismiss('cancel');
              }
            };

            const config = {
              title: 'Warning',
              text: response.message,
              showCancelButton: true,
              confirmButtonColor: '#cc514c',
              confirmButtonText: 'Allow overlap',
              cancelButtonText: 'Cancel',
              icon: 'warning',
            };

            const { value: returnValue } = await Swal.fire(config);
            await returnHandler(returnValue);
          } else {
            AlertService.errorAlert(response.message);
            $modalInstance.dismiss('cancel');
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.dismiss('cancel');
        }
      );
    };

    const buildForRequeue = function (formData) {
      const result = {
        attrib_list: [],
      };
      result.request_id = $scope.session.session_id || $scope.session.request_id;

      if (formData.start_date_time) {
        result.attrib_list.push({
          name: 'date_time',
          attrib_id: 4,
          value_text: formData.start_date_time,
        });
      }

      if (formData.duration) {
        result.attrib_list.push({
          name: 'duration',
          attrib_id: 7,
          value_count: formData.duration,
        });
      }

      if ($scope.isNow && formData.maxEta) {
        result.attrib_list.push({
          name: 'max_eta',
          attrib_id: 3,
          value_count: formData.maxEta,
        });
      }

      return result;
    };

    const sendRequeue = function (formData) {
      formData = buildForRequeue(formData);
      sessionManagerService.changeAndRequeueRequest(formData).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
            $modalInstance.close();
          } else {
            AlertService.errorAlert(response.message);
            $modalInstance.dismiss('cancel');
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.dismiss('cancel');
        }
      );
    };

    // Calendar date time selection options and controls
    $scope.startDate = {
      opened: false,
    };
    $scope.openStartDate = function () {
      $scope.startDate.opened = true;
    };
    // Options
    // Start time selection
    $scope.hstep = 1;
    $scope.mstep = 5;

    const formatDateTimes = async function (formData) {
      var _startTime, _startDate, _startDateTime, _builtDateTime;
      if (newStartTimeUntouched == $scope.newDateObj.startTime) {
        _startTime = moment($scope.session.start).format('HH:mm');
      } else {
        _startTime = moment(formData.startTime).format('HH:mm');
      }

      if ($scope.originalStartDate == $scope.newDateObj.startDate) {
        _startDate = moment($scope.session.start).format(TimeFormatService.format('fullDate'));
      } else {
        _startDate = moment(formData.startDate).format(TimeFormatService.format('fullDate'));
      }

      const adjustedStart = await TimeFormatService.formatTimezone(
        'fullDateTimeNoSeparator',
        _startDate,
        _startTime,
        $scope.sessionLocation
      );

      _startDateTime = await TimeFormatService.formatTimezone(
        'fullDateTimeNoSeparator',
        adjustedStart.format('YYYY-MM-DD') + ' ' + _startTime,
        adjustedStart.format('YYYY-MM-DD') + ' ' + _startTime,
        $scope.sessionLocation,
        true
      );

      _builtDateTime = moment
        .utc(_startDateTime.clone().format())
        .format(TimeFormatService.format('fullDateTimeNoSeparator'));

      $scope.formData.start_date_time = _builtDateTime;
      await sendToServer($scope.formData);
    };

    const calculateDayDiff = async () => {
      const startTime = moment($scope.newDateObj.startTime).format('HH:mm');
      const startDate = moment($scope.newDateObj.startDate).format(TimeFormatService.format('dateSlash'));

      const targetStartTemp = moment.utc(`${startDate} ${startTime}`, `${TimeFormatService.format('dateSlash')} HH:mm`);
      const targetStart = await TimeFormatService.formatTimezone(
        'fullDateTimeNoSeparator',
        targetStartTemp,
        targetStartTemp,
        $scope.sessionLocation,
        true
      );

      // Get original start date in session location timezone
      const originalStartTemp = moment.utc(`${$scope.session.request_date} ${$scope.session.request_time}`);
      const originalStart = await TimeFormatService.formatTimezone(
        'fullDateTimeNoSeparator',
        originalStartTemp,
        originalStartTemp,
        $scope.sessionLocation
      );

      return targetStart.diff(originalStart);
    };

    const formatSessionTime = async function (session, dayDiff) {
      const originalStartTemp = moment.utc(`${session.request_date} ${session.request_time}`);
      const originalStart = await TimeFormatService.formatTimezone(
        'fullDateTimeNoSeparator',
        originalStartTemp,
        originalStartTemp,
        $scope.sessionLocation
      );

      originalStart.add(dayDiff);

      return originalStart.utc().format(TimeFormatService.format('fullDateTimeNoSeparator'));
    };

    // For slider time display
    $scope.translateHours = function (value) {
      const hours = value / 60;
      return hours + ' hrs';
    };

    $scope.translate = function (value) {
      return value + ' mins';
    };
  },
]);
