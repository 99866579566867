import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JobUpgradeDirective } from './components/job-upgrade.directive';
import { JobPageComponent } from './pages/job-page.component';
import { SessionUpgradeDirective } from './components/session-upgrade.directive';
import { SessionPageComponent } from './pages/session-page.component';

import {
  sessionServiceProvider,
  providerServiceProvider,
  enterpriseFilterServiceProvider,
} from '../upgraded.providers';
import { SchedulePageComponent } from './pages/schedule-page.component';
import { ScheduleUpgradeDirective } from './components/schedule-upgrade.directive';

@NgModule({
  imports: [BrowserModule, BrowserAnimationsModule, FormsModule, ReactiveFormsModule, HttpClientModule],
  declarations: [
    SchedulePageComponent,
    ScheduleUpgradeDirective,
    JobUpgradeDirective,
    JobPageComponent,
    SessionUpgradeDirective,
    SessionPageComponent,
  ],
  entryComponents: [ScheduleUpgradeDirective],
  providers: [sessionServiceProvider, providerServiceProvider, enterpriseFilterServiceProvider],
})
export class ScheduleModule {
  constructor() {}
}
