import angular from 'angular';
import module from '../module';
import Swal from 'sweetalert2';
import * as enums from '../../enums';

export default module.controller('BaseEditController', [
  '$scope',
  '$modalInstance',
  'AlertService',
  'sessionManagerService',
  'enterprise_selection',
  'trackingService',
  function ($scope, $modalInstance, AlertService, sessionManagerService, enterprise_selection, trackingService) {
    $scope.submitBtnDisbaled = false;
    $scope.entFilter = enterprise_selection;
    $scope.jobType = { assign: false, escalation: false, selection: '' };

    // building instance indexes for multiple instances
    function addMultiInstanceAttribs(instanceIdsArray) {
      for (let i = 0; i < instanceIdsArray.length; i++) {
        const multiInstancesArray = $scope.formData.filter(function (obj) {
          return obj.attrib_id == instanceIdsArray[i];
        }); // return array of instances

        const _length = multiInstancesArray.length;

        for (let x = 0; x < _length; x++) {
          multiInstancesArray[x].instanceCountIndex = x + 1;
          multiInstancesArray[x].doNotShowAddInstance = true;
          multiInstancesArray[x].showRemoveInstance = false;
        }

        multiInstancesArray[_length - 1].showRemoveInstance = true;

        if (multiInstancesArray[_length - 1].instance_count !== multiInstancesArray[_length - 1].instanceCountIndex) {
          multiInstancesArray[_length - 1].doNotShowAddInstance = false;
        }
      }
    }

    // finding multiple instances used
    function getUsedMultiInstances() {
      const dupAttribIds = [];

      const attribIds = $scope.formData.map(function (obj) {
        return obj.attrib_id;
      });

      attribIds.sort();

      for (let i = 0; i < attribIds.length; i++) {
        if (attribIds[i] === attribIds[i + 1]) {
          dupAttribIds.push(attribIds[i]);
        }
      }

      const sortedArray = dupAttribIds.sort();

      sortedArray.filter(function (v, i, o) {
        if (i >= 0 && v !== o[i - 1]) return v;
      });

      if (sortedArray.length > 0) {
        addMultiInstanceAttribs(sortedArray);
      }
    }

    // Filling in values
    function buildCombinedForm() {
      const _usedAttribIds = $scope.formData.map(function (obj) {
        return obj.attrib_id;
      });

      angular.forEach($scope.originalFormData, function (attrib) {
        if (_usedAttribIds.indexOf(attrib.attrib_id) == -1) {
          // means not currently used
          $scope.formData.push(attrib);
        }
      });

      $scope.formData.sort(function (a, b) {
        return a.order - b.order;
      });

      getUsedMultiInstances();
      reorderAttribs($scope.formData);

      if ($scope.attribsLoadedCallback) {
        $scope.attribsLoadedCallback($scope.formData);
      }
    }

    // Grabbing list of Job attribs from API
    function getRequestAttribs(type) {
      sessionManagerService.getRealmAttribs(enums.Realms.Service).then(
        function (response) {
          if (response.success) {
            const scheduledService = response.attrib_list.filter(function (obj) {
              return obj.type_flags.type == 0 || obj.type_flags.type == type;
            });

            const formData = scheduledService.sort(function (a, b) {
              return a.order - b.order;
            });

            $scope.originalFormData = angular.copy(formData);
            buildCombinedForm();
            $scope.$broadcast('BaseEditController.LoadRequest');
          } else {
            AlertService.errorAlert(response.message);
            $modalInstance.dismiss('cancel');
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.dismiss('cancel');
        }
      );
    }

    $scope.loadRequest = function () {
      sessionManagerService.getSession().then(function (session) {
        $scope.request = angular.copy(session);
        $scope.formData = angular.copy(session.attrib_list);
        getRequestAttribs($scope.request.type);
      });
    };

    // Modal Control Buttons
    $scope.validate = async function () {
      if ($scope.jobType.pickListRequested) {
        const result = $scope.validatePickList();
        if (result) {
          const config = {
            title: 'Are you sure?',
            text: result.message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#cc514c',
            confirmButtonText: 'Continue',
          };

          const returnHandler = function (isConfirm) {
            if (isConfirm) {
              $scope.formatRequest();
            } else {
              $scope.submitBtnDisbaled = false;
              $scope.$apply();
            }
          };

          const { value: returnValue } = await Swal.fire(config);
          returnHandler(returnValue);

          return;
        }
      }
      $scope.formatRequest();
    };

    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      // checks for jobType attrib and adds to formData
      if ($scope.jobType.selection === 'assign') {
        $scope.formData.push($scope.staffProviderAttrib);

        if ($scope.qualifiedWorkers) {
          const providers = $scope.qualifiedWorkers
            .filter(function (item) {
              return item.selected;
            })
            .map(function (item) {
              return { choice_id: item.provider_id };
            });

          const orig = $scope.staffProviderAttrib.value;
          providers.push(orig);
          $scope.staffProviderAttrib.value = providers;
        }
      } else if ($scope.jobType.selection === 'escalation' && !$scope.jobType.pickListRequested) {
        $scope.formData.push($scope.escalationAttrib);
      }

      if ($scope.applyAttrib.value) {
        $scope.formData.push($scope.applyAttrib);
      }

      // check to see that location entered if other selected
      const address = $scope.formData.filter(function (obj) {
        return obj.name === 'location';
      })[0];

      if (address.value.location_id == 0 && address.value.location.length == 0) {
        AlertService.warningAlert('Please fill in an address.');
        $scope.submitBtnDisbaled = false;
      } else {
        $scope.validate();
      }

      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'submit');
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'cancel');
    };

    // find selected attrib
    function selectedAttrib(attribName) {
      let attrib;
      if ($scope.request) {
        attrib = $scope.request.attrib_list.filter(function (obj) {
          return obj.name == attribName;
        })[0];
      }

      return attrib;
    }
    // format attrib array
    function reorderAttribs(attrib_list) {
      const _removedAttribsArray = [];
      angular.forEach(attrib_list, function (attrib, index) {
        switch (attrib.name) {
          case 'escalation_list':
            var filledInAttrib = selectedAttrib(attrib.name);
            $scope.escalationAttrib = attrib;
            if (filledInAttrib) {
              $scope.escalationAttrib.value = filledInAttrib.value_list.filter(function (obj) {
                return obj.selected == true;
              })[0];
              $scope.jobType = { assign: false, escalation: true, selection: 'escalation' };
              $scope.showJob();
            }
            _removedAttribsArray.push(attrib);
            break;

          case 'proserv_list':
            var filledInAttrib = selectedAttrib(attrib.name);
            $scope.proservAttrib = attrib;
            if (filledInAttrib) {
              $scope.proservAttrib.value = filledInAttrib.value_list.filter(function (obj) {
                return obj.selected == true;
              })[0];
              $scope.showJob();
            }
            _removedAttribsArray.push(attrib);
            break;

          case 'staff_provider_list':
            var filledInAttrib = selectedAttrib(attrib.name);
            $scope.staffProviderAttrib = attrib;
            if (filledInAttrib) {
              $scope.staffProviderAttrib.value = filledInAttrib.value_list.filter(function (obj) {
                return obj.selected == true;
              })[0];
              $scope.jobType = { assign: true, escalation: false, selection: 'assign' };
              $scope.showJob();
            }
            _removedAttribsArray.push(attrib);
            break;

          case 'requested_for':
            var filledInAttrib = selectedAttrib(attrib.name);
            // requested_for is not present in the request, need to assign by hand
            if (!filledInAttrib) {
              const result =
                attrib.choice_list &&
                attrib.choice_list.filter(function (obj) {
                  return obj.choice_id === $scope.request.req_requester_id;
                });
              if (result && result.length > 0 && result[0] != undefined) {
                result[0].selected = true;
                attrib.value_list = attrib.choice_list;
              }
            }
            break;
          case 'price_list':
            $scope.priceAttrib = attrib;
            $scope.priceAttrib.value = { choice_id: 0 };
            _removedAttribsArray.push(attrib);
            break;
          case 'interest_flag':
            $scope.applyAttrib = attrib;
            $scope.applyAttrib.value = false;
            _removedAttribsArray.push(attrib);
            break;
        }
      });
      removeAttribFromArray(_removedAttribsArray);
    }
    function removeAttribFromArray(attribsArray) {
      for (let i = 0; i < attribsArray.length; i++) {
        const index = $scope.formData.indexOf(attribsArray[i]);
        $scope.formData.splice(index, 1);
      }
      $scope.originalSortedAttribList = angular.copy($scope.formData);
    }

    // listen for instance_count attrib clicks
    $scope.$on('addAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      const _count = field.instanceCountIndex || 1;

      const _field = angular.copy(
        $scope.originalFormData.filter(function (obj) {
          return obj.attrib_id == field.attrib_id;
        })[0]
      );

      _field.instanceCountIndex = _count + 1;
      _field.showRemoveInstance = true;
      $scope.formData[index].doNotShowAddInstance = true;
      $scope.formData[index].showRemoveInstance = false;
      $scope.formData.splice(index + 1, 0, _field); // add after current field
    });

    $scope.$on('removeAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      $scope.formData[index - 1].doNotShowAddInstance = false;
      $scope.formData[index - 1].showRemoveInstance = true;
      $scope.formData.splice(index, 1);
    });
  },
]);
