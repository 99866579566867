import angular from 'angular';
import module from './module';

export default module.factory('EscalationTabService', [
  '$http',
  '$localStorage',
  'BASE_URL',
  function ($http, $localStorage, BASE_URL) {
    return {
      // Format Data for submission
      formatUpdateEscalation: function (formData, poolsArraySubmission) {
        const originalFormData = formData;
        const originalPools = formData.pool_list;
        const submissionPools = poolsArraySubmission;

        const originalPoolIds = originalPools.map(function (obj) {
          return obj.pool_id;
        });
        const newPoolIds = submissionPools.map(function (obj) {
          return obj.pool_id;
        });

        const currentlyAssignedPools = [];
        const finalPoolList = [];

        for (let i = 0; i < newPoolIds.length; i++) {
          if (originalPoolIds.indexOf(newPoolIds[i]) > -1) {
            // create array of selected pool_ids already assigned to escaltion
            currentlyAssignedPools.push(newPoolIds[i]);
          } else {
            // format and add new pools to finale list
            const newPoolObj = submissionPools.filter(function (obj) {
              return obj.pool_id == newPoolIds[i];
            })[0];
            const obj = {
              pool_id: newPoolObj.pool_id,
              action_flag: 1,
              order: newPoolObj.order,
              wait_time: newPoolObj.wait_time,
            };
            finalPoolList.push(obj);
          }
        }
        // find updates to already assigned pools
        for (let i = 0; i < originalPoolIds.length; i++) {
          const assignedPoolObj = poolsArraySubmission.filter(function (obj) {
            return obj.pool_id == originalPoolIds[i];
          })[0];
          // check for differences between assignedPoolObj and originalFormData
          if (assignedPoolObj) {
            const originalPoolObj = originalFormData.pool_list.filter(function (obj) {
              return obj.pool_id == assignedPoolObj.pool_id;
            })[0];
            // if difference add update action flag and push to final list
            if (
              originalPoolObj.order != assignedPoolObj.order ||
              originalPoolObj.wait_time != assignedPoolObj.wait_time
            ) {
              assignedPoolObj.action_flag = 2;
              finalPoolList.push(assignedPoolObj);
            }
          } else {
            // if undefined means pool was deleted from escalation
            const poolToRemove = originalFormData.pool_list.filter(function (obj) {
              return obj.pool_id == originalPoolIds[i];
            })[0];
            const obj = {
              pool_id: poolToRemove.pool_id,
              action_flag: 0,
            };
            finalPoolList.push(obj);
          }
        } // end for update check
        originalFormData.pool_list = finalPoolList;
        return originalFormData;
      },
      formatUpdateEscalationAttribList: function (origAttrib, newAttrib) {
        const origAttribData = angular.copy(origAttrib);
        const newAttribData = angular.copy(newAttrib);
        const originalAttribIds = origAttribData.map(function (obj) {
          return obj.attrib_id;
        });
        const newAttribIds = newAttribData.map(function (obj) {
          return obj.attrib_id;
        });
        const currentlyAssignedAttribs = [];
        const finalAttribList = [];
        // for new attribs
        for (let i = 0; i < newAttribIds.length; i++) {
          if (originalAttribIds.indexOf(newAttribIds[i]) > -1) {
            // create array of selected attrib_ids already assigned to price attrib list
            currentlyAssignedAttribs.push(newAttribIds[i]);
          } else {
            const newAttribObj = newAttribData.filter(function (obj) {
              return obj.attrib_id == newAttribIds[i];
            })[0];
            newAttribObj.action_flag = 1;
            finalAttribList.push(newAttribObj);
          }
        }
        // find updates to already assigned attribs
        for (let i = 0; i < originalAttribIds.length; i++) {
          const assignedAttribObj = newAttribData.filter(function (obj) {
            return obj.attrib_id == originalAttribIds[i];
          })[0];
          // check for differences between assignedAttribObj and origAttribObj
          if (assignedAttribObj) {
            const originalAttribObj = origAttribData.filter(function (obj) {
              return obj.attrib_id == assignedAttribObj.attrib_id;
            })[0];
            // if difference add update action flag and push to final list
            if (JSON.stringify(originalAttribObj) != JSON.stringify(assignedAttribObj)) {
              assignedAttribObj.action_flag = 2;
              finalAttribList.push(assignedAttribObj);
            }
          } else {
            // if undefined means attrib was deleted from list
            const obj = {
              attrib_id: originalAttribIds[i],
              action_flag: 0,
            };
            finalAttribList.push(obj);
          }
        } // end for update check
        return finalAttribList;
      },
    }; // end of return
  },
]);
