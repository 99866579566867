import * as actions from './actions';
import { CommonState, createRealmKey, initialCommonState } from './state';

export function reducer(state = initialCommonState, action: actions.Actions): CommonState {
  switch (action.type) {
    case actions.BRANCH_LIST_FETCHED:
      return {
        ...state,
        branchList: action.result,
      };
    case actions.ENTERPRISE_LIST_FETCHED:
      return {
        ...state,
        enterpriseList: action.result,
      };
    case actions.UPDATE_ENTERPRISE_NAME: {
      const list = state.enterpriseList;
      const index = list.findIndex((x) => x.enterprise_id === action.id);
      if (index > -1) {
        list[index].name = action.newName;
      }
      return {
        ...state,
        enterpriseList: [...list],
      };
    }
    case actions.ADD_NEW_ENTERPRISE: {
      const list = state.enterpriseList;
      list.push(action.enterprise);
      return {
        ...state,
        enterpriseList: [...list],
      };
    }
    case actions.DELETE_ENTERPRISE: {
      const list = state.enterpriseList;
      const index = list.findIndex((x) => x.enterprise_id === action.id);
      if (index > -1) {
        list.splice(index, 1);
      }
      return {
        ...state,
        enterpriseList: [...list],
      };
    }
    case actions.REALM_ATTRIBS_FETCHED: {
      const realmKey = createRealmKey(action.enterpriseId, action.realmId);
      state.realmAttribs[realmKey] = { attrib_list: action.attribs, success: true };
      return {
        ...state,
        realmAttribs: { ...state.realmAttribs },
      };
    }
    case actions.REALM_ATTRIBS_ERROR: {
      const realmKey = createRealmKey(action.enterpriseId, action.realmId);
      state.realmAttribs[realmKey] = { message: action.error, success: false };
      return {
        ...state,
        realmAttribs: { ...state.realmAttribs },
      };
    }
    case actions.CLEAR_REALM_ATTRIB: {
      delete state.realmAttribs[action.realmId];
      return {
        ...state,
        realmAttribs: { ...state.realmAttribs },
      };
    }

    case actions.SET_SELECTED_ENTERPRISE:
      if (action.selection) {
        return {
          ...state,
          selection: action.selection,
        };
      } else {
        return {
          ...state,
          selection: state.enterpriseList[0],
        };
      }

    case actions.SET_SELECTED_BRANCH:
      if (action.selection) {
        return {
          ...state,
          selectedBranch: action.selection,
        };
      } else {
        return {
          ...state,
          selectedBranch: state.branchList[0],
        };
      }

    case actions.INIT_WEBSOCKET_MESSAGES:
      const initialState = {};
      action.enterprises.forEach((id) => (initialState[id] = []));
      return {
        ...state,
        websocketMessages: initialState,
      };

    case actions.SET_WEBSOCKET_MESSAGE_FOR_ENTERPRISE:
      const messages = { ...state.websocketMessages };
      messages[action.enterpriseId].push(action.message);
      return {
        ...state,
        websocketMessages: messages,
      };

    default:
      return state;
  }
}
