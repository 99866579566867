import angular from 'angular';
import module from './module';

export default module.factory('Escalation', [
  'BASE_URL',
  '$q',
  '$http',
  function (BASE_URL, $q, $http) {
    function Escalation(escalationData) {
      if (escalationData) {
        this.setData(escalationData);
      }
    }

    Escalation.prototype = {
      setData: function (escalationData) {
        angular.extend(this, escalationData);
      },
      getPoolNames: function () {
        const names = this.pool_list.map(function (pool) {
          return pool.name;
        });
        return names.join(', ');
      },
      delete: function () {
        const deferred = $q.defer();
        $http
          .post(BASE_URL + '/delete_escalation', { escalation_id: this.escalation_id })
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
        return deferred.promise;
      },
    };

    return Escalation;
  },
]);
