import module from '../module';

export default module.controller('JobRootController', [
  '$scope',
  '$rootScope',
  'AuthenticationService',
  'PERMISSIONS',
  'generalDataService',
  function ($scope, $rootScope, AuthenticationService, PERMISSIONS, generalDataService) {
    $scope.currentUser = AuthenticationService.getCurrentUser();
    $scope.current_user = AuthenticationService.getCurrentUser();
    $scope.selectedTab = 'jobs';
    if ($scope.current_user && $scope.current_user.permission_info) {
      $scope.sessionTabPermission = $scope.current_user.permissionCheck(PERMISSIONS.SESSION_TAB);

      $scope.jobsTabPermission = $scope.current_user.permissionCheck(PERMISSIONS.JOBS);
    }

    $scope.keySession = generalDataService.getKeySession();

    $scope.selectTab = function (tab) {
      $scope.selectedTab = tab;
      if (tab === 'jobs') {
        $scope.pageTitle = 'Jobs';
      } else {
        $scope.pageTitle = `${generalDataService.getKeySession()}s`;
      }
    };

    $scope.goToSessions = function () {
      location.href = '/#/session/true';
    };

    $scope.selectTab($scope.jobsTabPermission ? 'jobs' : 'sessions');
  },
]);
