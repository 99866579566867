import angular from 'angular';
import module from './module';

export default module.factory('jobManagerService', [
  '$http',
  'BASE_URL',
  '$q',
  'Job',
  function ($http, BASE_URL, $q, Job) {
    const jobsManager = {
      _pool: {},
      _jobsObj: {},
      _selectedJob: {},
      _retrieveInstance: function (jobId, jobData, ent_id) {
        let instance = this._pool[jobId];
        if (instance) {
          instance.setData(jobData);
        } else {
          instance = new Job(jobData);
          this._pool[jobId] = instance;
          if (jobData.enterprise_id) {
            if (!this._jobsObj[jobData.enterprise_id]) {
              this._jobsObj[jobData.enterprise_id] = [];
            }
            this._jobsObj[jobData.enterprise_id].push(instance);
          } else {
            if (!this.jobData[-1]) {
              this.jobData[-1] = [];
            }
            this.jobData[-1].push(instance);
          }
        }
        return instance;
      },
      _createJob: function (jobObj, deferred) {
        $http
          .post(BASE_URL + '/add_job', jobObj)
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              deferred.resolve(response);
            } else {
              deferred.reject(response.message);
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _createScheduleJob: function (scheduleJobObj, deferred) {
        $http
          .post(BASE_URL + '/schedule_job', scheduleJobObj)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _updateJob: function (jobObj, deferred) {
        $http
          .post(BASE_URL + '/update_job', jobObj)
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _loadAllJobs: function (ent_id, deferred) {
        const scope = this;
        const params =
          ent_id && parseInt(ent_id) > 0
            ? {
                enterprise_id: ent_id,
              }
            : {};
        $http
          .post(BASE_URL + '/get_jobs', params)
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              angular.forEach(response.job_list, function (jobData) {
                scope._retrieveInstance(jobData.job_id, jobData, ent_id);
              });
              if (ent_id && parseInt(ent_id) > 0) {
                if (!scope._jobsObj[ent_id]) {
                  deferred.resolve([]);
                } else {
                  deferred.resolve(scope._jobsObj[ent_id]);
                }
              } else {
                deferred.resolve(
                  [].concat.apply(
                    [],
                    Object.keys(scope._jobsObj).map((k) => scope._jobsObj[k])
                  )
                );
              }
            } else {
              deferred.reject(response.message);
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _getJobOrderReports: function (params, deferred) {
        $http
          .post(BASE_URL + '/get_job_orders_for_reports', params)
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              deferred.resolve(response);
            } else {
              deferred.reject(response.message);
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _getJobOrderDetailsForReports: function (params, deferred) {
        $http
          .post(BASE_URL + '/get_job_order_details_for_reports', params)
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              deferred.resolve(response.request_list);
            } else {
              deferred.reject(response.message);
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },
      _removeInstance: function (job, deferred) {
        const instance = this._pool[job.job_id];
        if (instance) {
          delete this._pool[job.job_id];
          if (job.enterprise_id) {
            const index = this._jobsObj[job.enterprise_id].indexOf(instance);
            if (index !== -1) {
              this._jobsObj[job.enterprise_id].splice(index, 1);
            }
          } else {
            const index = this._jobsObj[-1].indexOf(instance);
            if (index !== -1) {
              this._jobsObj[-1].splice(index, 1);
            }
          }
        }
        deferred.resolve(this._jobsObj[job.enterprise_id]);
      },
      _scheduleAdhocJob: function (params, deferred) {
        $http
          .post(BASE_URL + '/schedule_adhoc_job', params)
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              deferred.resolve(response);
            } else {
              deferred.reject(response.message);
            }
          })
          .catch(function () {
            deferred.reject();
          });
      },
      /* Public Methods */
      submitJobRequest: function (jobObj) {
        const deferred = $q.defer();
        this._createJob(jobObj, deferred);
        return deferred.promise;
      },
      submitScheduleJobRequest: function (scheduleJobObj) {
        const deferred = $q.defer();
        this._createScheduleJob(scheduleJobObj, deferred);
        return deferred.promise;
      },
      submitEditJob: function (jobObj) {
        const deferred = $q.defer();
        this._updateJob(jobObj, deferred);
        return deferred.promise;
      },
      getAllJobs: function (ent_id) {
        const deferred = $q.defer();
        this._loadAllJobs(ent_id, deferred);
        return deferred.promise;
      },
      getJobReports: function (params) {
        const deferred = $q.defer();
        this._getJobOrderReports(params, deferred);
        return deferred.promise;
      },
      getJobDetailsReports: function (params) {
        const deferred = $q.defer();
        this._getJobOrderDetailsForReports(params, deferred);
        return deferred.promise;
      },
      scheduleAdhocJob: function (params) {
        const deferred = $q.defer();
        this._scheduleAdhocJob(params, deferred);
        return deferred.promise;
      },
      removeDeletedJob: function (job) {
        const deferred = $q.defer();
        this._removeInstance(job, deferred);
        return deferred.promise;
      },
      getJobOrderTracking: function (jobOrderId) {
        const deferred = $q.defer();
        $http
          .post(`${BASE_URL}/get_job_order_tracking`, {
            job_order_id: jobOrderId,
          })
          .then(
            (response) => {
              if (response && response.data && response.data.success) {
                deferred.resolve(response.data);
              } else {
                const message = response && response.data && response.data.message ? response.data.message : '';
                deferred.reject(message);
              }
            },
            (error) => {
              deferred.reject(error);
            }
          );
        return deferred.promise;
      },
    };
    return jobsManager;
  },
]);
