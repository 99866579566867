import module from './module';

export default module.factory('LocationTabService', [
  '$http',
  'BASE_URL',
  '$localStorage',
  'googleLocationService',
  function ($http, BASE_URL, $localStorage, googleLocationService) {
    var current_user = $localStorage.current_user;
    var state_list = [
      { id: 1, name: 'Alabama' },
      { id: 2, name: 'Alaska' },
      { id: 3, name: 'American' },
      { id: 4, name: 'Arizona' },
      { id: 5, name: 'Arkansas' },
      { id: 6, name: 'California' },
      { id: 7, name: 'Colorado' },
      { id: 8, name: 'Connecticut' },
      { id: 9, name: 'Delaware' },
      { id: 10, name: 'District Of Columbia' },
      { id: 11, name: 'Florida' },
      { id: 12, name: 'Georgia' },
      { id: 13, name: 'Guam' },
      { id: 14, name: 'Hawaii' },
      { id: 15, name: 'Idaho' },
      { id: 16, name: 'Illinois' },
      { id: 17, name: 'Indiana' },
      { id: 18, name: 'Iowa' },
      { id: 19, name: 'Kansas' },
      { id: 20, name: 'Kentucky' },
      { id: 21, name: 'Louisiana' },
      { id: 22, name: 'Maine' },
      { id: 23, name: 'Maryland' },
      { id: 24, name: 'Massachusetts' },
      { id: 25, name: 'Michigan' },
      { id: 26, name: 'Minnesota' },
      { id: 27, name: 'Mississippi' },
      { id: 28, name: 'Missouri' },
      { id: 29, name: 'Montana' },
      { id: 30, name: 'Nebraska' },
      { id: 31, name: 'Nevada' },
      { id: 32, name: 'New Hampshire' },
      { id: 33, name: 'New Jersey' },
      { id: 34, name: 'New Mexico' },
      { id: 35, name: 'New York' },
      { id: 36, name: 'North Carolina' },
      { id: 37, name: 'North Dakota' },
      { id: 38, name: 'Northern Marianas Islands' },
      { id: 39, name: 'Ohio' },
      { id: 40, name: 'Oklahoma' },
      { id: 41, name: 'Oregon' },
      { id: 42, name: 'Pennsylvania' },
      { id: 43, name: 'Puerto Rico' },
      { id: 44, name: 'Rhode Island' },
      { id: 45, name: 'South Carolina' },
      { id: 46, name: 'South Dakota' },
      { id: 47, name: 'Tennessee' },
      { id: 48, name: 'Texas' },
      { id: 49, name: 'Utah' },
      { id: 50, name: 'Vermont' },
      { id: 51, name: 'Virginia' },
      { id: 52, name: 'Virgin Islands' },
      { id: 53, name: 'Washington' },
      { id: 54, name: 'West Virginia' },
      { id: 55, name: 'Wisconsin' },
      { id: 56, name: 'Wyoming' },
    ];

    return {
      formatSubmission: function (formData) {
        var data = formData;
        var obj = {
          enterprise_id: data.enterprise_id,
          latitude: data.latitude,
          longitude: data.longitude,
          radius: data.radius,
          name: data.name,
          address_line_1: data.address_line_1,
          address_line_2: data.address_line_2 || '',
          city: data.city,
          state: data.state && data.state.id,
          zip_code: data.zip_code,
          country: data.country,
          external_code: data.external_code,
        };
        if (data.places?.length > 0) {
          obj.place_list = data.places;
        }
        if (data.location_id) {
          obj.location_id = data.location_id;
        }
        return obj;
      },
      // parse passed location data for displaying in edit modal
      parseFormData: function (locationObj) {
        var location = locationObj;
        var formData = {
          latitude: location.latitude,
          longitude: location.longitude,
          radius: location.radius,
          name: location.name,
          address_line_1: location.address_line_1,
          address_line_2: location.address_line_2,
          city: location.city,
          zip_code: parseFloat(location.zip_code),
          country: location.country,
          state: state_list.filter(function (obj) {
            return obj.name == location.state;
          })[0],
          places: location.place_list,
          external_code: location.external_code,
          isForeign: location.city && location.city.indexOf(',') !== -1,
        };
        return formData;
      },
      // Format Updated Data for submission
      formatUpdateLocation: function (formData, originalPlaceList) {
        var submissionPlaces = formData.places;
        var originalPlaces = originalPlaceList;
        var placesToCheckForUpdates = [];
        var finalPLaceList = []; // store final values

        // If there is no place ID then new place to Add. Otherwise already assigned to Location
        for (var i = 0; i < submissionPlaces.length; i++) {
          if (submissionPlaces[i].place_id) {
            // already assigned
            placesToCheckForUpdates.push(submissionPlaces[i]);
          } else {
            // add to finallist and assign action flag for addition
            submissionPlaces[i].action_flag = 1;
            finalPLaceList.push(submissionPlaces[i]);
          }
        }
        //find places no longer in list that need action flag 0: remove
        for (var i = 0; i < originalPlaces.length; i++) {
          var obj = submissionPlaces.filter(function (obj) {
            return obj.place_id == originalPlaces[i].place_id;
          })[0];
          if (!obj) {
            // not in update, means it need to be deleted.
            originalPlaces[i].action_flag = 0;
            finalPLaceList.push(originalPlaces[i]);
          }
        }
        //check places in both original and new for updates
        for (var i = 0; i < placesToCheckForUpdates.length; i++) {
          var originalPlaceObj = originalPlaces.filter(function (obj) {
            return obj.place_id == placesToCheckForUpdates[i].place_id;
          })[0];
          if (originalPlaceObj.name != placesToCheckForUpdates[i].name) {
            //name change so update obj
            placesToCheckForUpdates[i].action_flag = 2;
            finalPLaceList.push(placesToCheckForUpdates[i]);
          }
        }
        formData.places = finalPLaceList;
        return formData;
      },
      getStateList: function () {
        return state_list;
      },
      parseLocation: function (details, state_list) {
        try {
          var address = googleLocationService.parseAddress(details, state_list);
          const result = {
            latitude: address.latitude,
            longitude: address.longitude,
            address_line_1: address.address_line_1,
            state: address.state,
            zip_code: address.zip_code,
            city: address.city,
            isForeign: address.isForeign,
          };

          if (address.isForeign) {
            if (address.city && address.country) {
              result.city = `${address.city},${address.country}`;
            }
          }
          return result;
        } catch (e) {
          if (address.longitude == 0 || address.latitude == 0) {
            return false;
          }
        }
      },
    }; // end of return
  },
]);
