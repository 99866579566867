import module from './module';
import moment from 'moment';

export default module.filter('dateDisplay', [
  'TimeFormatService',
  function (TimeFormatService) {
    return function (value) {
      let momObj = moment(value, TimeFormatService.format('fullDate')).clone();
      if (momObj.isValid()) {
        momObj = momObj.format('ddd MMM DD, YYYY');
      } else {
        momObj = 'N/A';
      }
      return momObj;
    };
  },
]);
