import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseWidget } from './base.widget';
import * as fromRoot from '../store';
import { FilterRequestList } from '../store/actions';
import { RequestListFilter } from '../store/';

@Component({
  selector: 'shifts-accepted-component',
  templateUrl: './common.widget.template.html'
})
export class ShiftsAcceptedComponent extends BaseWidget {
  public customTitle: string;
  public titleValue: string;
  public titleText = 'Total';
  constructor(store: Store<fromRoot.State>) {
    super(store);
  }

  loadDataTemplate(list) {
    if (!list) {
      return;
    }
    this.store
      .select(fromRoot.getKeySession)
      .subscribe(keySession => (this.customTitle = `${keySession}s Accepted`))
      .unsubscribe();
    this.count = list.length;
    let statusMap = {
      Accepted: 0,
      Completed: 0
    };

    list.map(res => res.status).forEach(item => {
      switch (item.type) {
        case 1:
        case 2:
          statusMap.Accepted++;
          break;
        case 5:
          statusMap.Completed++;
          break;
      }
    });

    this.data = Object.keys(statusMap).map(key => {
      let value = statusMap[key];
      return {
        name: key,
        value: value
      };
    });

    this.titleValue = this.data.map(x => x.value).reduce((a, b) => a + b);

    if (!this.data.some(x => x.value)) {
      this.data = [];
    }
  }

  filterAll() {
    let action = new FilterRequestList({
      showAllAccepted: true
    });
    this.store.dispatch(action);
  }

  filterData(point) {
    let filters: any = {};
    if (point.name === 'Accepted') {
      filters.showAccepted = true;
    } else {
      filters.status = 5;
    }
    let action = new FilterRequestList(filters);
    this.store.dispatch(action);
  }
}
