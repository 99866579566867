import module from '../module';
import moment from 'moment';
export default module.controller('RequestScheduleJobModalController', [
  '$scope',
  '$controller',
  '$modalInstance',
  'FormatHelperService',
  'AlertService',
  'EnterpriseFilterService',
  'enterprise_selection',
  'sessionManagerService',
  'start_time',
  function (
    $scope,
    $controller,
    $modalInstance,
    FormatHelperService,
    AlertService,
    EnterpriseFilterService,
    enterprise_selection,
    sessionManagerService,
    start_time
  ) {
    console.log('loaded');
    $scope.attribsLoadedCallback = function (attribs) {
      console.log('attribs loaded');
      if (start_time) {
        const timeAttrib = attribs.find(function (attrib) {
          return attrib.attrib_id === 4;
        });
        if (timeAttrib) {
          timeAttrib.value = start_time;
        }
      }
    };

    $controller('BaseJobModalController', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      FormatHelperService: FormatHelperService,
      AlertService: AlertService,
      EnterpriseFilterService: EnterpriseFilterService,
      enterprise_selection: enterprise_selection,
      sessionManagerService: sessionManagerService,
    });

    $scope.title = 'Add ' + $scope.keySession;
    $scope.requestType = 1;
    $scope.type = 'new';

    $scope.checkForErrors = function (formData) {
      const result = [];
      // Check if session starts in the past
      let date;
      const dateAttrib = formData.find(function (attrib) {
        return attrib.name === 'date_time';
      });
      if ($scope.jobType.selection === 'escalation' && dateAttrib) {
        date = moment(dateAttrib.value);
        if (date.isBefore(moment())) {
          result.push('The time you entered is for a shift starting in the past. Please adjust to start in the future');
        }
      }

      return result;
    };
  },
]);
