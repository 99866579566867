import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  templateUrl: './smartlink-error.component.html',
  styleUrls: ['./smartlink-error.component.scss'],
  selector: 'app-smartlink-error',
  encapsulation: ViewEncapsulation.None,
})
export class SmartlinkErrorComponent {
  @Input() type: 'workn' | 'avionte';
}
