import angular from 'angular';
import module from '../module';
import Swal from 'sweetalert2';

export default module.controller('JobsTabController', [
  '$scope',
  '$rootScope',
  '$modal',
  'AlertService',
  'jobManagerService',
  'AuthenticationService',
  'trackingService',
  'EnterpriseFilterService',
  function (
    $scope,
    $rootScope,
    $modal,
    AlertService,
    jobManagerService,
    AuthenticationService,
    trackingService,
    EnterpriseFilterService
  ) {
    $scope.showSpinner = true;

    $scope.entFilter = EnterpriseFilterService.fetchSelectedEnterprise();

    let unbindHandler = null;

    // Permission Check
    const CURRENT_USER = AuthenticationService.getCurrentUser();
    $scope.jobCrudPermission = CURRENT_USER.permissionCheck(38);

    // Grabbing jobs from API
    function getJobs(ent_id) {
      jobManagerService.getAllJobs(ent_id).then(
        function (jobsArray) {
          $scope.jobs = angular.copy(jobsArray);
          $scope.showSpinner = false;
        },
        function (reason) {
          $scope.showSpinner = false;
          AlertService.serverRequestErrorAlert(reason);
        }
      );
    }

    // Grab the selected enterprise when first come to jobs tab
    if (CURRENT_USER.user_type == 2) {
      $scope.$watch('entFilter', function () {
        if ($scope.entFilter.enterprise_id == -1) {
          $scope.addJobPermission = false;
          $scope.showSpinner = false;
          $scope.hideAddJob = true;
        } else {
          $scope.addJobPermission = true;
          $scope.hideAddJob = false;
          getJobs($scope.entFilter.enterprise_id);
        }
      });
    } else {
      getJobs($scope.entFilter.enterprise_id);
    }

    // Open job Modal
    $scope.openAddJobModal = function () {
      const modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/admin/jobs/jobModal.html',
        controller: 'NewJobModalController',
        keyboard: false,
        size: 'lg',
        backdrop: 'static',
        resolve: {
          enterprise_selection: function () {
            return $scope.entFilter;
          },
        },
      });

      modalInstance.result.then(function (response) {
        if (response.followOnAction === 'scheduleJobs') {
          const modalInstance = $modal.open({
            animation: true,
            templateUrl: 'app/views/schedule/scheduleJobModal.html',
            controller: 'NewScheduleJobModalController',
            keyboard: false,
            size: 'lg',
            backdrop: 'static',
            resolve: {
              enterprise_selection: function () {
                return $scope.entFilter;
              },
              passed_job_id: function () {
                return response.job_id;
              },
              passedSession: function () {
                return null;
              },
              start_time: function () {
                return null;
              },
              passedJobOrderDetails: function () {
                return null;
              },
              passed_current_worker_count: function () {
                return null;
              },
              passed_total_worker_count: function () {
                return null;
              },
            },
          });
          modalInstance.result.then(function () {});
        }
        getJobs($scope.entFilter.enterprise_id);
      });
      trackingService.trackEvent(trackingService.ADMIN, 'jobs', 'new');
    };
    // Open Edit job Modal
    $scope.editJobModal = function (job) {
      const modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/admin/jobs/jobModal.html',
        controller: 'EditJobModalController',
        keyboard: false,
        size: 'lg',
        backdrop: 'static',
        resolve: {
          enterprise_selection: function () {
            return $scope.entFilter;
          },
          job_selection: function () {
            return job;
          },
        },
      });

      modalInstance.result.then(function () {
        getJobs($scope.entFilter.enterprise_id);
      });
      trackingService.trackEvent(trackingService.ADMIN, 'jobs', 'edit');
    };

    // Delete job button
    $scope.deleteJob = async function (job) {
      const config = {
        title: 'Are you sure?',
        text: 'You are about to delete Job Template ' + job.name,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Yes, delete Job Template',
      };

      const returnHandler = function (isConfirm) {
        if (isConfirm) {
          job.delete().then(
            async function (response) {
              if (response.success) {
                jobManagerService.removeDeletedJob(job).then(function (jobsArray) {
                  $scope.jobs = angular.copy(jobsArray);
                });

                const configPositive = {
                  title: 'Deleted!',
                  text: response.message,
                  icon: 'success',
                  timer: 2000,
                  showConfirmButton: false,
                };
                await Swal.fire(configPositive);
              } else {
                const configError = {
                  title: 'Error',
                  text: response.message,
                  icon: 'error',
                  timer: 2000,
                  showConfirmButton: false,
                };
                await Swal.fire(configError);
              }
            },
            async function (reason) {
              const configError = {
                title: 'Error',
                text: 'Error contacting the server',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configError);
            }
          );
        }
      };

      const { value: returnValue } = await Swal.fire(config);
      returnHandler(returnValue);

      trackingService.trackEvent(trackingService.ADMIN, 'jobs', 'delete');
    };

    // Table Sorting
    $scope.sort = function (keyname) {
      $scope.sortKey = keyname;
      $scope.reverse = !$scope.reverse;
    };
    $scope.sortKey = 'name';

    unbindHandler = $rootScope.$on('EnterpriseSelectionChanged', function (data) {
      $scope.entFilter = EnterpriseFilterService.fetchSelectedEnterprise();
      getJobs($scope.entFilter.enterprise_id);
    });

    $scope.$on('$destroy', function () {
      unbindHandler();
    });
  },
]);
