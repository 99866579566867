import angular from 'angular';
import module from '../module';
export default module.controller('EditUserModalController', [
  '$scope',
  '$modalInstance',
  'passedUser',
  'AlertService',
  'roleManagerService',
  'AuthenticationService',
  'adminUserService',
  'EnterpriseFilterService',
  'enterprise_selection',
  'branch_selection',
  'branchFilterService',
  function (
    $scope,
    $modalInstance,
    passedUser,
    AlertService,
    roleManagerService,
    AuthenticationService,
    adminUserService,
    EnterpriseFilterService,
    enterprise_selection,
    branch_selection,
    branchFilterService
  ) {
    $scope.initialize = async function () {
      $scope.CURRENT_USER = AuthenticationService.getCurrentUser();
      $scope.multiSelectTranslation = {
        selectAll: 'Select all',
        selectNone: 'Select none',
        reset: 'Undo all',
        search: 'Type here to search...',
        nothingSelected: 'All',
      };

      $scope.submitBtnDisbaled = false;
      $scope.showSpinner = true;
      $scope.type = 'Edit User'; // Modal Title
      $scope.btnLabel = 'Update User';
      $scope.sortKey = 'name';
      $scope.reverse = false;
      const enterpriseData = $scope.getEnterpriseData();
      $scope.showBranches = !enterpriseData.enterprise && (!enterpriseData.branch || enterpriseData.branch === -1);
      $scope.branches = await branchFilterService.fetchBranches() || [];
      $scope.branches = $scope.branches?.filter((b) => b.id !== -1);

      roleManagerService.getAllRoles().then(function (list) {
        $scope.roles = EnterpriseFilterService.filterManageableItems(angular.copy(list));
        $scope.showSpinner = false;
      });

      adminUserService.getAdminUserDetails(passedUser.id).then(function (user) {
        $scope.formData = angular.copy(user);
        if ($scope.formData) {
          $scope.formData.password = '********';
          $scope.formData.selectedBranches = [];
          $scope.branches = $scope.branches.map(b => ({ ...b, selected: user.admin_of_marketplace_branches_ids.includes(b.id) }));
        }
      });
    };

    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      $scope.editUser();
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      $scope.formData = {};
    };

    $scope.getEnterpriseData = function () {
      const passedEnt = enterprise_selection || {
        enterprise_id: $scope.CURRENT_USER.enterprise_info && $scope.CURRENT_USER.enterprise_info.id,
      };

      let enterprise, marketplace, branch;

      if (!passedEnt.enterprise_id || passedEnt.enterprise_id === -1) {
        enterprise = null;
      } else {
        enterprise = passedEnt.enterprise_id;
      }

      return {
        enterprise,
        marketplace: $scope.CURRENT_USER.user_type == 2 ? $scope.CURRENT_USER.market_place_info.id : null,
        branch: branch_selection && branch_selection.id,
      };
    };


    $scope.formatData = function () {
      Object.keys($scope.formData).forEach(function (key) {
        if ($scope.formData[key] === '') {
          $scope.formData[key] = null;
        }
      });
    };

    $scope.preparePassword = function () {
      // eslint-disable-next-line dot-notation
      if (!$scope.userForm['password'].$dirty) {
        // User hasn't modified the default password, let's not send it
        delete $scope.formData.password;
      }
    };

    $scope.prepareSelectedBranches = function () {
      // Need to populate admin_of_marketplace_branches as API patch call expects this property
      $scope.formData.admin_of_marketplace_branches_ids = $scope.formData.admin_of_marketplace_branches = $scope.formData.selectedBranches.map(b => b.id);
      delete $scope.formData.selectedBranches;
    };

    $scope.editUser = function () {
      $scope.preparePassword();
      $scope.prepareSelectedBranches();
      $scope.formatData();

      adminUserService.editUser($scope.formData).then(
        function () {
          AlertService.successAlert('User was updated.');
          $modalInstance.close();
        },
        function (reason) {
          if (reason.username) {
            $scope.submitBtnDisbaled = false;
            AlertService.errorAlert('Sorry - That username is already in use. Please try an alternate one');
          } else if (reason.length) {
            $scope.submitBtnDisbaled = false;
            AlertService.errorAlert(reason[0]);
          } else {
            AlertService.serverRequestErrorAlert(reason);
            $modalInstance.close();
          }
        }
      );
    };

    $scope.validatePassword = (() => {
      const regex =
        /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[\!\@\#\$\%\^\&\*\(\)\-\_\_\+\.]){1,}).{7,}$/;
      return {
        test: (value) => {
          if ($scope.userForm['password'].$dirty) {
            return regex.test(value);
          } else {
            return true;
          }
        },
      };
    })();

    $scope.initialize();
  },
]);
