import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';

import { Spinner } from 'spin.js';

@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html',
})
export class SpinnerComponent implements AfterViewInit {

  @Input() isLoading: boolean;
  @ViewChild('spinReference') spinReference;

  public ngAfterViewInit() {
    this.initSpinner();
  }

  private initSpinner() {
    if (
      this.spinReference
      && this.spinReference.nativeElement
      && this.spinReference.nativeElement.children
      && this.spinReference.nativeElement.children.length === 0
    ) {
      let spinner = new Spinner().spin();
      this.spinReference.nativeElement.appendChild(spinner.el);
    }
  }

}
