import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DashboardModule } from './dashboard/dashboard.module';
import { NewReportingModule } from './new-reporting/new-reporting.module';
import { AnalyticsComponent } from './analytics-container/analytics.component';
import { SharedModule } from '../shared/shared.module';
import { PAGES } from './analytics-container';
import { COMPONENTS } from './analytics-container/components';

import {
  sessionServiceProvider,
  providerServiceProvider,
  enterpriseFilterServiceProvider,
  branchServiceProvider,
} from '../upgraded.providers';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DashboardModule,
    NewReportingModule,
    SharedModule,
  ],
  declarations: [PAGES, COMPONENTS],
  entryComponents: [PAGES, COMPONENTS],
  providers: [sessionServiceProvider, providerServiceProvider, enterpriseFilterServiceProvider, branchServiceProvider],
})
export class AnalyticsModule {
  constructor() {}
}

// ---------------------------------
// Angular downgrade
// ---------------------------------

import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

angular
  .module('ServetureWebApp')
  .directive('analyticsComponent', downgradeComponent({ component: AnalyticsComponent }));
