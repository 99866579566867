import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * This class provides the App State service temporarily, this should be changed to a Store.
 */
@Injectable()
export class AppStateService {
  constructor() {}

  public initData$ = new Subject<any>();

  // init data
  private _initData;

  public get initData(): any {
    return this._initData;
  }
  public set initData(value: any) {
    this._initData = value;
    this.initData$.next(value);
  }
  // init data

  // active screen
  public activeScreenChanged: EventEmitter<string> = new EventEmitter<string>();
  private _activeScreen;

  public get activeScreen(): string {
    return this._activeScreen;
  }
  public set activeScreen(value: string) {
    this._activeScreen = value;
    this.activeScreenChanged.emit(value);
  }

  // active screen
}
