import module from './module';

export default module
  .directive('dirRemoveSelectAll', [
    function () {
      return {
        restrict: 'E',
        scope: {
          selectall: '&',
          removeall: '&'
        },
        templateUrl: 'app/views/templates/remove_select_all.html',
        link: function (scope, element, attr) {
          scope.selectallClick = function () {
            scope.selectall();
          };

          scope.removeallClick = function () {
            scope.removeall();
          };
        }
      };
    }
  ]);
