import { Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { ScopeProvider } from '../../../app/upgraded.providers';

@Directive({
  selector: 'app-support',
  providers: [ScopeProvider],
})
export class SupportUpgradeDirective extends UpgradeComponent {
  constructor(elementRef: ElementRef, injector: Injector) {
    super('supportDirective', elementRef, injector);
  }
}
