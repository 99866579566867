import angular from 'angular';
import module from '../module';
import * as enums from '../../enums';
import Swal from 'sweetalert2';
import moment from 'moment';

export default module.controller('NewScheduleJobModalController', [
  '$scope',
  '$modalInstance',
  '$localStorage',
  'AlertService',
  'enterprise_selection',
  'jobManagerService',
  'FormatHelperService',
  'passed_job_id',
  'locationManagerService',
  'trackingService',
  'passedSession',
  'sessionManagerService',
  'start_time',
  '$timeout',
  '$modal',
  'AuthenticationService',
  'PERMISSIONS',
  'generalDataService',
  'branchFilterService',
  'timeZoneStore',
  'DOMAIN',
  'TimeFormatService',
  function (
    $scope,
    $modalInstance,
    $localStorage,
    AlertService,
    enterprise_selection,
    jobManagerService,
    FormatHelperService,
    passed_job_id,
    locationManagerService,
    trackingService,
    passedSession,
    sessionManagerService,
    start_time,
    $timeout,
    $modal,
    AuthenticationService,
    PERMISSIONS,
    generalDataService,
    branchFilterService,
    timeZoneStore,
    DOMAIN,
    TimeFormatService
  ) {
    locationManagerService.clearSelectedLocation();
    $scope.submitBtnDisbaled = false;
    $scope.title = 'Add Job';
    $scope.keySession = generalDataService.getKeySession();
    $scope.entFilter = enterprise_selection;
    $scope.pauseJob = false;
    $scope.jobType = {
      selection: '',
    }; // for job type checkboxes
    const CURRENT_USER = AuthenticationService.getCurrentUser();
    $scope.enterpriseId =
      CURRENT_USER.user_type === 2 || CURRENT_USER.user_type === 6 ? $scope.entFilter.enterprise_id : undefined;
    $scope.hasBundlePermission = CURRENT_USER.permissionCheck(PERMISSIONS.SCHEDULE_JOB_ORDER);
    $scope.hasUnbundledPermission = CURRENT_USER.permissionCheck(PERMISSIONS.UNBUNDLED_JOB_ORDER);

    $scope.canSaveWithoutTemplate = CURRENT_USER.permissionCheck(PERMISSIONS.JOB_ORDERS_WITHOUT_TEMPLATE);

    $scope.hasAdhocPermission = CURRENT_USER.permissionCheck(PERMISSIONS.SHOW_ADHOC);

    $scope.onlyBundled = $scope.hasBundlePermission && !$scope.hasUnbundledPermission;
    $scope.onlyUnbundled = !$scope.hasBundlePermission && $scope.hasUnbundledPermission;

    $scope.load_all_jobs = CURRENT_USER.market_place_info ? CURRENT_USER.market_place_info.load_all_jobs : false;

    $scope.formData = {
      startDate: moment(),
      endDate: moment(),
      startTime: new Date().setHours('00', '00'),
      bundled: false,
    };

    $scope.scheduleJobFormSubmission = {
      job_id: passed_job_id,
      end_date: '',
      day_list: [],
      type: 1,
      attrib_list: [],
    };

    $scope.branch = branchFilterService.getSelectedBranch();
    if ($scope.branch && $scope.branch.id !== -1) {
      $scope.scheduleJobFormSubmission.branch_id = $scope.branch.id;
    }

    $scope.dayListArray = [
      {
        id: 7,
        name: 'Select All',
        selected: true,
      },
      {
        id: 3,
        name: 'Wednesday',
        selected: true,
      },
      {
        id: 6,
        name: 'Saturday',
        selected: true,
      },
      {
        id: 1,
        name: 'Monday',
        selected: true,
      },
      {
        id: 4,
        name: 'Thursday',
        selected: true,
      },
      {
        id: 0,
        name: 'Sunday',
        selected: true,
      },
      {
        id: 2,
        name: 'Tuesday',
        selected: true,
      },
      {
        id: 5,
        name: 'Friday',
        selected: true,
      },
    ];

    $scope.affectedDays = [];

    $scope.selectedDays = $scope.dayListArray.map(function (day) {
      return day.id;
    });

    $scope.adhoc = false;

    $scope.toggleAdhoc = () => {
      $scope.adhoc = !$scope.adhoc;
      $scope.cancelPickList();
      $scope.showDateRange = false;
    };

    $scope.adhocSessions = [];

    $scope.adhocOnChange = (adhocData) => {
      const sessions = adhocData?.sessions?.length ? adhocData.sessions.flat(1) : [];
      $scope.adhocValid = adhocData.valid;
      $scope.numberOfSessions = sessions.length;
      $scope.adhocSessions = sessions;
    };

    $scope.warningFields = ['location', $localStorage.keyAttribute.data_content, 'duration', 'others'];

    $scope.showWarning = async function (e) {
      if ($scope.adhoc) {
        e && e.preventDefault && e.preventDefault();
        e && e.target && e.target.blur && e.target.blur();
        window.focus();
        const config = {
          title: 'Are you sure?',
          text: 'You are changing an aspect of the job order form that will invalidate the Ad Hoc Scheduling aspect of this form. If you proceed with this change, the Ad Hoc Scheduling will be reset. You may re-open it and start it over after this change.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#cc514c',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        };

        const returnHandler = function (isConfirm) {
          if (isConfirm) {
            $scope.adhoc = false;
          }
        };

        const { value: returnValue } = await Swal.fire(config);
        returnHandler(returnValue);
      }
    };

    $scope.showOverlapWarning = async function (overlaps) {
      const config = {
        title: 'Error',
        html: `<p>Please note we cannot submit this schedule due to the following overlapping shifts:</p>
        
        ${overlaps
          .map((datetime) => `<p>${moment.utc(datetime).local().format(TimeFormatService.format('jobDate'))} </p>`)
          .join('')}
        
        <div>Please adjust the schedule and resubmit.</div>`,
        icon: 'error',
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Ok',
      };

      return Swal.fire(config);
    };

    $scope.formHelper = {
      markers: [],
      dateOptionsFrom: {
        filter: function (day) {
          return $scope.selectedDays.indexOf(day.day()) !== -1;
        },
      },
      dateOptionsTo: {
        filter: function (day) {
          return $scope.selectedDays.indexOf(day.day()) !== -1;
        },
      },
    };

    $scope.setFormValidity = function (valid) {
      if (valid) {
        $timeout(function () {
          $scope.scheduleJobForm.location.$invalid = false;
          $scope.scheduleJobForm.location.$valid = true;
        });
      } else {
        $timeout(function () {
          $scope.scheduleJobForm.location.$invalid = true;
          $scope.scheduleJobForm.$invalid = true;
        });
      }
    };

    $scope.toggleDateRange = function () {
      if ($scope.adhoc) {
        $scope.showWarning();
      } else {
        $scope.showDateRange = !$scope.showDateRange;
      }
    };

    const unwatchFormDataStartDate = $scope.$watch('formData.startDate', (value) => {
      const formatText = TimeFormatService.format('jobDate');
      const start = moment(value).format(formatText);
      const end = moment($scope.formData.endDate).format(formatText);
      if (moment(value).isAfter(moment($scope.formData.endDate))) {
        $scope.formData.endDate = moment(value);
      }
      $scope.formHelper.formattedDate = `${start} - ${end}`;
      if (value) {
        $scope.calculateSessionNumber();
      }
    });

    const unwatchFormDataEndDate = $scope.$watch('formData.endDate', (value) => {
      const formatText = TimeFormatService.format('jobDate');
      const start = moment($scope.formData.startDate).format(formatText);
      const end = moment(value).format(formatText);
      if (moment(value).isBefore(moment($scope.formData.startDate))) {
        $scope.formData.startDate = moment(value);
      }
      $scope.formHelper.formattedDate = `${start} - ${end}`;
      if (value) {
        $scope.calculateSessionNumber();
      }
    });

    $scope.calculateSessionNumber = function () {
      const start = moment($scope.formData.startDate);
      const end = moment($scope.formData.endDate);

      const deSelectedDays = $scope.dayListArray
        .filter(function (day) {
          return !day.selected;
        })
        .map(function (day) {
          return day.id;
        });

      $scope.numberOfSessions = $scope.calculateDays(start, end, deSelectedDays);
      $scope.affectedDays = $scope.getDayList(start, end, deSelectedDays);
    };

    $scope.calculateDays = function (start, end, deSelectedDays) {
      let result = 0;
      while (start <= end) {
        if (deSelectedDays.indexOf(start.day()) === -1) {
          result++;
        }
        start = start.clone().add(1, 'd');
      }
      return result;
    };

    $scope.getDayList = function (start, end, deSelectedDays) {
      const result = [];
      while (start <= end) {
        if (deSelectedDays.indexOf(start.day()) === -1) {
          result.push(start.toDate());
        }
        start = start.clone().add(1, 'd');
      }
      return result;
    };

    // Get service request attribs 3
    sessionManagerService.getRealmAttribs(enums.Realms.Service).then(
      function (response) {
        if (response.success) {
          const scheduledService = response.attrib_list.filter(function (obj) {
            return obj.type_flags.type == 0 || obj.type_flags.type == 1;
          });
          const formData = scheduledService.sort(function (a, b) {
            return a.order - b.order;
          });
          $scope.originalFormData = angular.copy(formData);
          $scope.attrib_list = angular.copy(formData);
          reorderAttribs($scope.attrib_list);
          // Default to apply if escalation is not present
          if (!$scope.escalationAttrib && $scope.applyAttrib) {
            $scope.applyAttrib.value = true;
          }
          getJobs();
        } else {
          AlertService.errorAlert(reason);
          $modalInstance.close();
        }
      },
      function (reason) {
        AlertService.serverRequestErrorAlert(reason);
        $modalInstance.close();
      }
    );
    // Get jobs
    function getJobs(callback) {
      jobManagerService.getAllJobs($scope.entFilter.enterprise_id).then(
        function (jobsArray) {
          $scope.jobsArray = jobsArray;
          if ($scope.scheduleJobFormSubmission.job_id) {
            $scope.setSelectedJobAttribs($scope.scheduleJobFormSubmission.job_id);
            $scope.attribsLoadedCallback($scope.formData);
          } else {
            $scope.attribsLoadedCallback($scope.formData);
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    }

    function populateAttribs(source, target) {
      switch (source.type) {
        case enums.FilterableTypes.single_list:
          target.value = source.value_list.filter(function (obj) {
            return obj.selected == true;
          })[0];
          break;
        case enums.FilterableTypes.multi_list:
          target.value = source.value_list;
          target.choice_list = source.value_list;
          break;
        case enums.FilterableTypes.text:
          target.value = source.value_text;
          break;
        case enums.FilterableTypes.count:
          target.value = source.value_count;
          break;
        case enums.FilterableTypes.decimal:
          target.value_decimal = source.value_decimal;
          break;
        case enums.FilterableTypes.location:
          if (source.value_location && source.value_location.filter) {
            // Value_location is an array
            target.value = source.value_location.filter(function (obj) {
              return obj.selected == true;
            })[0]; // assign selected location for display
          } else {
            // Value_location is an object
            target.value = source.value_location;
          }

          // var locId = selectedAttrib.value.location_id
          // locationManagerService.getLocation(locId).then(function(response){
          locationManagerService.setLocation(target.value);
          target.value = target.value;
          target.type_flags.other_required = true;

          // });
          break;
        case enums.FilterableTypes.place:
          var selected = source.value_place.filter(function (obj) {
            return obj.selected == true;
          })[0];
          target.value = source.value_place.filter(function (obj) {
            return obj.name == selected.name;
          })[1];
          break;
        case enums.FilterableTypes.image:
          target.default_text = DOMAIN + source.value_text;
          break;
      }
    }

    // populate fields based on Job Selection
    $scope.setSelectedJobAttribs = function (selectedJobId) {
      locationManagerService.clearSelectedLocation();
      $scope.attrib_list = angular.copy($scope.originalSortedAttribList);
      const selectedJob = $scope.jobsArray.filter(function (obj) {
        return obj.job_id == selectedJobId;
      })[0];

      if (!selectedJob) {
        return;
      }

      angular.forEach(selectedJob.attrib_list, function (attrib) {
        const selectedAttrib = $scope.attrib_list.filter(function (obj) {
          return obj.attrib_id == attrib.attrib_id;
        })[0];
        if (selectedAttrib) {
          populateAttribs(attrib, selectedAttrib);
        }
      }); // end for each

      $scope.selectedJob = angular.copy(selectedJob);

      trackingService.trackEvent(trackingService.MODALS, 'schedule job', 'change job');
    };

    // format attrib array
    function reorderAttribs(attrib_list) {
      const _removedAttribsArray = [];
      angular.forEach(attrib_list, function (attrib, index) {
        switch (attrib.name) {
          case 'date_time':
            $scope.dateAttrib = attrib;
            _removedAttribsArray.push(attrib);
            break;
          case 'duration':
            $scope.durationAttrib = attrib;
            $scope.durationAttrib.value = $scope.durationAttrib.default_count || 0;
            _removedAttribsArray.push(attrib);
            $scope.sliderModel = {
              options: {
                floor: $scope.durationAttrib.min_amount,
                ceil: $scope.durationAttrib.max_amount,
                step: $scope.durationAttrib.inc_amount,
                showTicks: false,
                translate: function (value) {
                  return FormatHelperService.generateDurationLabel(value);
                },
                onStart: function (id, modelValue, highValue, pointerType) {
                  if ($scope.adhoc) {
                    const value = $scope.durationAttrib.value;
                    setTimeout(() => {
                      $scope.durationAttrib.value = value;
                    }, 100);

                    $scope.showWarning();
                  }
                  return false;
                },
              },
            };
            break;
          case 'escalation_list':
            $scope.escalationAttrib = attrib;
            $scope.escalationAttrib.value = {
              choice_id: 0,
            };
            _removedAttribsArray.push(attrib);
            break;
          case 'staff_provider_list':
            $scope.staffProviderAttrib = attrib;
            $scope.staffProviderAttrib.value = {
              choice_id: 0,
            };
            _removedAttribsArray.push(attrib);
            break;
          case 'proserv_list':
            $scope.proservAttrib = attrib;
            if (!$scope.proservAttrib.type_flags.required) {
              $scope.proservAttrib.value = {
                choice_id: -1,
              };
            }
            _removedAttribsArray.push(attrib);
            break;
          case 'price_list':
            $scope.priceAttrib = attrib;
            if (!$scope.priceAttrib.type_flags.required) {
              $scope.priceAttrib.value = {
                choice_id: 0,
              };
            }
            _removedAttribsArray.push(attrib);
            break;
          case 'interest_flag':
            $scope.applyAttrib = attrib;
            if (attrib.default_flag) {
              $scope.applyAttrib.value = attrib.default_flag;
              $scope.jobType.selection = '';
            } else {
              $scope.applyAttrib.value = false;
            }
            _removedAttribsArray.push(attrib);
            break;
        }
      });
      removeAttribFromArray(_removedAttribsArray);
      $scope.watchAssignList();
    }

    function removeAttribFromArray(attribsArray) {
      for (let i = 0; i < attribsArray.length; i++) {
        const index = $scope.attrib_list.indexOf(attribsArray[i]);
        $scope.attrib_list.splice(index, 1);
      }
      $scope.originalSortedAttribList = angular.copy($scope.attrib_list);
    }

    // Calendar dtae time selection options and controls
    $scope.startDate = {
      opened: false,
    };
    $scope.openStartDate = function () {
      $scope.startDate.opened = true;
    };
    $scope.endDate = {
      opened: false,
    };
    $scope.openEndDate = function () {
      $scope.endDate.opened = true;
    };

    // Default starting date to today
    $scope.formData.startDate = new Date();

    // Options
    // $scope.minStartDate = moment();
    // $scope.minEndDate = moment();

    $scope.setEndDateMin = function (startDate) {
      $scope.minEndDate = startDate;
      // ensure user does not set end date before start
      if (moment($scope.formData.endDate).isBefore(moment(startDate))) {
        $scope.formData.endDate = startDate;
      }
      trackingService.trackEvent(trackingService.MODALS, 'schedule job', 'change start date');
    };

    $scope.selectAllDayList = function (dayItem) {
      if (dayItem.name === 'Select All' && dayItem.selected == true) {
        angular.forEach($scope.dayListArray, function (obj) {
          obj.selected = true;
        });
      } else if (dayItem.name === 'Select All' && dayItem.selected == false) {
        angular.forEach($scope.dayListArray, function (obj) {
          obj.selected = false;
        });
      } else if (dayItem.name !== 'Select All' && dayItem.selected == false) {
        $scope.dayListArray.filter(function (obj) {
          return obj.name === 'Select All';
        })[0].selected = false;
      }

      $scope.selectedDays = $scope.dayListArray
        .filter(function (day) {
          return day.selected;
        })
        .map(function (day) {
          return day.id;
        });
      $timeout(function () {
        $scope.$apply();
      });
      $scope.formHelper.markers.push({});
      $scope.calculateSessionNumber();
      trackingService.trackEvent(trackingService.MODALS, 'schedule job', 'select all');
    };

    $scope.hstep = 1;
    $scope.mstep = 5;

    function timeErrorChecks(formData) {
      if (moment(formData.endDate).isBefore(formData.startDate, 'day')) {
        return true;
      } else {
        return false;
      }
    }
    // Modal Controls
    $scope.validate = async function (successCb) {
      if ($scope.jobType.pickListRequested && $scope.pickList) {
        const result = $scope.validatePickList();
        if (result) {
          const config = {
            title: 'Are you sure?',
            text: result.message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#cc514c',
            confirmButtonText: 'Continue',
          };

          const returnHandler = function (isConfirm) {
            if (isConfirm) {
              successCb();
            } else {
              $scope.submitBtnDisbaled = false;
              $scope.showSpinner = false;
              $scope.$apply();
            }
          };

          const { value: returnValue } = await Swal.fire(config);
          returnHandler(returnValue);
        } else {
          successCb();
        }
      } else {
        successCb();
      }
    };

    $scope.checkDateCap = function (formData) {
      // A job can be 15 week long tops
      const start = moment(formData.startDate),
        end = moment(formData.endDate);
      return end.diff(start, 'week') > 52;
    };

    $scope.checkPastStart = function (formData) {
      if ($scope.jobType.selection === 'escalation' || $scope.offerWorkers) {
        const startTime = moment(formData.startTime).format('HHmm');
        const startDate = moment(formData.startDate).format(TimeFormatService.format('fullDateNoSeparator'));
        const startDateTime = startDate + ' ' + startTime;

        const localDate = timeZoneStore.calcTime(moment(startDateTime).toDate());
        const builtDateTime = moment(localDate, TimeFormatService.format('fullDateTimeNoSeparator'));

        return moment(builtDateTime).isBefore(moment());
      }
    };

    $scope.checkBundledOpen = function (formData, staffProviderAttrib) {
      if ($scope.jobType.selection === 'assign') {
        const val = staffProviderAttrib && staffProviderAttrib.value && staffProviderAttrib.value;
        return formData.bundled && val && Array.isArray(val) && val.find((x) => x.name === 'Open');
      }
      return false;
    };

    $scope.checkOverlap = () => {
      if ($scope.adhocSessions && $scope.adhocSessions.length > 1) {
        const sessions = $scope.adhocSessions
          .map((arr) => ({
            start: moment(arr[0].value_text, TimeFormatService.format('fullDateTimeNoSeparator')),
            duration: arr[2].value_count,
          }))
          .map((arr) => ({
            start: arr.start,
            end: arr.start.clone().add(arr.duration, 'm'),
          }));

        const overlaps = new Set();

        for (let i = 0; i < sessions.length - 1; i++) {
          for (let j = i + 1; j < sessions.length; j++) {
            const first = sessions[i];
            const second = sessions[j];
            if (first.start.isBefore(second.end) && second.start.isBefore(first.end)) {
              overlaps.add(first.start);
              overlaps.add(second.start);
            }
          }
        }

        return Array.from(overlaps);
      }
    };

    $scope.handleOtherLocation = async function (location) {
      const modalInstance = $modal.open({
        templateUrl: 'app/views/admin/saveJobLocationModal.html',
        size: 'lg',
        backdrop: 'static',
        controller: 'SaveJobLocationModal',
        resolve: {
          passedData: function () {
            return { location };
          },
          enterprise_selection: function () {
            return $scope.entFilter;
          },
        },
      });

      return await modalInstance.result;
    };

    $scope.ok = async function () {
      $scope.submitBtnDisbaled = true;
      $scope.showSpinner = true;
      // Do Error Checking for missing fields
      const _errorMessages = [];
      // day list selection check
      const daySelectionCheck = $scope.dayListArray.filter(function (obj) {
        return obj.selected == true;
      });
      if (daySelectionCheck.length == 0) {
        _errorMessages.push('Missing Day Selection');
      }

      //date and time check
      if ($scope.dateAttrib) {
        timeErrorChecks($scope.formData) && _errorMessages.push('End date cannot be before Start Date');
        !$scope.formData.startDate && _errorMessages.push('Missing Start Date Selection');
        !$scope.formData.endDate && _errorMessages.push('Missing End Date Selection');
        !$scope.formData.startTime && _errorMessages.push('Missing Start Time');
        $scope.checkPastStart($scope.formData) &&
          _errorMessages.push(
            'The time you entered is for a shift starting in the past. Please adjust to start in the future'
          );
        $scope.checkDateCap($scope.formData) && _errorMessages.push("A job's length cannot exceed a year");
      }
      $scope.checkBundledOpen($scope.formData, $scope.staffProviderAttrib) &&
        _errorMessages.push('Sorry, Job Orders to Open status are not yet supported');

      // Multi select error Checking
      angular.forEach($scope.attrib_list, function (attrib) {
        if (attrib.type === 'multi_list' && attrib.type_flags.required == true) {
          if (attrib.value.length == 0) {
            _errorMessages.push('Missing Required ' + attrib.display_name + ' Selection');
          }
        }
      });

      if (_errorMessages.length > 0) {
        AlertService.errorAlert(_errorMessages.join('\n'));
        $scope.submitBtnDisbaled = false;
        $scope.showSpinner = false;
      } else {
        $scope.validate(function () {
          const handleAttribs = () => {
            // no missing fields
            if ($scope.durationAttrib) {
              $scope.attrib_list.push($scope.durationAttrib);
            }
            if ($scope.proservAttrib) {
              $scope.attrib_list.push($scope.proservAttrib);
            }
            if ($scope.priceAttrib) {
              $scope.attrib_list.push($scope.priceAttrib);
            }
            if ($scope.applyAttrib) {
              $scope.attrib_list.push($scope.applyAttrib);
            }
            if (($scope.jobType.selection === 'escalation' || ($scope.applyAttrib && $scope.applyAttrib.value)) && !$scope.jobType.pickListRequested) {
              if ($scope.escalationAttrib) {
                $scope.attrib_list.push($scope.escalationAttrib);
              }
            }
            if ($scope.jobType.selection === 'assign' || ($scope.formData.bundled && $scope.scheduleWorkers)) {
              if ($scope.qualifiedWorkers) {
                const providers = $scope.qualifiedWorkers
                  .filter(function (item) {
                    return item.selected;
                  })
                  .map(function (item) {
                    return {
                      choice_id: item.provider_id,
                    };
                  });
                const orig = $scope.staffProviderAttrib.value;
                $scope.staffProviderAttrib.value = providers.concat(orig);
              }
              if ($scope.staffProviderAttrib) {
                $scope.attrib_list.push($scope.staffProviderAttrib);
              }
            }
            formatDateTimes($scope.formData);
          };

          const overlaps =
            $scope.jobType.selection === 'assign' &&
            (($scope.qualifiedWorkers && $scope.qualifiedWorkers.filter((x) => x.selected).length) ||
              $scope.staffProviderAttrib?.value?.length) &&
            $scope.checkOverlap();

          if (overlaps && overlaps.length) {
            $scope.showOverlapWarning(overlaps).then(() => {
              $scope.submitBtnDisbaled = false;
              $scope.showSpinner = false;
              $scope.$apply();
            });
          } else {
            handleAttribs();
          }
        });
        trackingService.trackEvent(trackingService.MODALS, 'schedule job', 'submit');
      }
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      trackingService.trackEvent(trackingService.MODALS, 'schedule job', 'cancel');
    };

    // format date_time
    const formatDateTimes = function (formData) {
      const startTime = moment(formData.startTime).format('HHmm');
      const startDate = moment(formData.startDate).format(TimeFormatService.format('fullDateNoSeparator'));
      const startDateTime = startDate + ' ' + startTime;
      const builtDateTime = moment(startDateTime, TimeFormatService.format('fullDateTimeNoSeparator'));
      $scope.dateAttrib.value = builtDateTime;
      $scope.attrib_list.push($scope.dateAttrib);
      // format end_date
      const endDate = moment(formData.endDate).format(TimeFormatService.format('fullDateNoSeparator'));
      const endDateTime = endDate + ' ' + startTime;
      const builtEndDateTime = moment(endDateTime, TimeFormatService.format('fullDateTimeNoSeparator'));
      const finalEndDate = moment
        .utc(timeZoneStore.calcTime(builtEndDateTime))
        .format(TimeFormatService.format('apiDate'));
      $scope.scheduleJobFormSubmission.end_date = finalEndDate;
      $scope.scheduleJobFormSubmission.enterprise_id = $scope.enterpriseId;
      // call next formating function
      dayListSelectionCheck();
    };

    // format day selection list
    const dayListSelectionCheck = function () {
      const allSelected = $scope.dayListArray.filter(function (obj) {
        return obj.name === 'Select All';
      })[0];
      if (allSelected.selected == true) {
        $scope.scheduleJobFormSubmission.day_list = [0, 1, 2, 3, 4, 5, 6];
      } else {
        angular.forEach($scope.dayListArray, function (day) {
          if (day.selected == true) {
            $scope.scheduleJobFormSubmission.day_list.push(day.id);
          }
        });
      }
      sendToServer();
    };

    const sendToServer = function () {
      const interestFlag = $scope.attrib_list.filter((a) => a.name === 'interest_flag');

      if (
        ($scope.formData.bundled && $scope.pickList) ||
        (interestFlag && interestFlag.length && interestFlag[0].value == true && $scope.pickList) ||
        ($scope.pickListValid && $scope.jobType.selection === 'escalation')
      ) {
        $scope.scheduleJobFormSubmission.provider_list = $scope.pickList
          .filter(function (item) {
            return item.selected;
          })
          .map(function (item) {
            return item.provider_id;
          });
      }
      if (!$scope.formData.bundled) {
        delete $scope.formData.bundled;
        delete $scope.formData.worker_count;
      }
      const formData = angular.copy($scope.scheduleJobFormSubmission);
      formData.bundled = $scope.formData.bundled;
      formData.worker_count = $scope.formData.worker_count;
      formData.pause_job = $scope.load_all_jobs ? $scope.pauseJob : false;
      const attribList = angular.copy($scope.attrib_list);
      formData.attrib_list = FormatHelperService.format_data(attribList);
      if (!formData.job_id) {
        formData.job_id = 0;
      }

      if (!formData.bundled && $scope.adhoc && $scope.adhocSessions.length) {
        const adhocNames = ['duration', 'location', 'date_time', $localStorage.keyAttribute.data_content];
        formData.attrib_list = formData.attrib_list.filter((x) => adhocNames.indexOf(x.name) === -1);
        formData.req_list = $scope.adhocSessions;

        jobManagerService.scheduleAdhocJob(formData).then(
          function (response) {
            if (response.success) {
              AlertService.successAlert(response.message);
              $modalInstance.close();
            } else {
              AlertService.errorAlert(response.message);
              $scope.submitBtnDisbaled = false;
              $scope.showSpinner = false;
            }
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
            $modalInstance.dismiss('cancel');
          }
        );
      } else {
        jobManagerService.submitScheduleJobRequest(formData).then(
          function (response) {
            if (response.success) {
              AlertService.successAlert(response.message);
              $modalInstance.close();
            } else {
              AlertService.errorAlert(response.message);
              $scope.submitBtnDisbaled = false;
              $scope.showSpinner = false;
            }
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
            $modalInstance.dismiss('cancel');
          }
        );
      }
    };

    // listen for instance_count attrib clicks
    $scope.$on('addAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      const _count = field.instanceCountIndex || 1;
      const _field = angular.copy(
        $scope.originalFormData.filter(function (obj) {
          return obj.attrib_id == field.attrib_id;
        })[0]
      );
      _field.instanceCountIndex = _count + 1;
      _field.showRemoveInstance = true;
      $scope.attrib_list[index].doNotShowAddInstance = true;
      $scope.attrib_list[index].showRemoveInstance = false;
      $scope.attrib_list.splice(index + 1, 0, _field); // add after current field
    });

    $scope.$on('removeAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      $scope.attrib_list[index - 1].doNotShowAddInstance = false;
      $scope.attrib_list[index - 1].showRemoveInstance = true;
      $scope.attrib_list.splice(index, 1);
    });

    // Method used by pick list to request payload from controller
    $scope.preparePickListPayload = function () {
      const startTime = moment($scope.formData.startTime).format('HHmm');
      const startDate = moment($scope.formData.startDate).format(TimeFormatService.format('fullDateNoSeparator'));
      const startDateTime = startDate + ' ' + startTime;
      const builtDateTime = moment(startDateTime, TimeFormatService.format('fullDateTimeNoSeparator'));
      const tempDateAttrib = angular.copy($scope.dateAttrib);
      tempDateAttrib.value = builtDateTime;
      // format end_date
      const endDate = moment($scope.formData.endDate).format(TimeFormatService.format('fullDateNoSeparator'));
      const endDateTime = endDate + ' ' + startTime;
      const builtEndDateTime = moment(endDateTime, TimeFormatService.format('fullDateTimeNoSeparator'));
      const end_date = builtEndDateTime.format(TimeFormatService.format('fullDateTimeNoSeparator'));
      const finalEndDate = moment(end_date, TimeFormatService.format('fullDateTimeNoSeparator'))
        .utc()
        .format(TimeFormatService.format('apiDate'));

      const result = {
        type: 1,
        job_id: passed_job_id,
        end_date: finalEndDate,
        day_list: [],
        // We override bundled to true for non-adhoc unbundled schedule jobs
        bundled: $scope.formData.bundled || (!$scope.adhoc && $scope.jobType.selection === 'assign'),
        branch_id: $scope.scheduleJobFormSubmission.branch_id,
        job_order_id: passedSession && (passedSession.job_order_id || passedSession.jobOrderId),
      };
      const tempAttribs = angular.copy($scope.attrib_list);
      tempAttribs.push(tempDateAttrib);
      tempAttribs.push($scope.durationAttrib);
      result.attrib_list = FormatHelperService.format_data(tempAttribs);

      if (!result.bundled && $scope.adhoc && $scope.adhocSessions.length) {
        const adhocNames = ['duration', 'location', 'date_time', $localStorage.keyAttribute.data_content];
        result.attrib_list = result.attrib_list.filter((x) => adhocNames.indexOf(x.name) === -1);
        result.req_list = $scope.adhocSessions;
      }

      angular.forEach($scope.dayListArray, function (day) {
        if (day.selected == true && day.id !== 7) {
          result.day_list.push(day.id);
        }
      });
      return result;
    };

    let unwatchStaffProviderAttribValue;
    // Removes the default Open option from the staff provider attribute
    $scope.watchAssignList = function () {
      if ($scope.staffProviderAttrib) {
        unwatchStaffProviderAttribValue = $scope.$watch('staffProviderAttrib.value', function (val) {
          // By default the attribute value is an object, do nothing in that case
          if (Array.isArray(val)) {
            // If more items are added, search for the default Open item (0 choice id)
            if (val.length > 1) {
              const openIndex = val.findIndex(function (choice) {
                return choice.choice_id === 0;
              });
              // If Open is present, remove it
              if (openIndex !== -1) {
                val.splice(openIndex, 1);
                // Prevent an infinite digest loop by copying the value
                $scope.staffProviderAttrib.value = val;

                // To update the UI we need to set the choice_list item to not selected
                const choiceItem = $scope.staffProviderAttrib.choice_list.find(function (choice) {
                  return choice.choice_id === 0;
                });
                if (choiceItem) {
                  choiceItem.selected = false;
                }
              }
            }
          }
        });
      }
    };

    $scope.bundledChanged = function () {
      if ($scope.formData.bundled) {
        $scope.adhoc = false;
        $scope.handleBundled();
      } else {
        $scope.jobType.selection = '';
        $scope.jobType.pickListRequested = false;
      }
    };

    let unwatchStaffProviderAttribValue2;
    let unwatchFormDataWorker_count;

    $scope.handleBundled = function () {
      $scope.formData.worker_count = 1;
      $scope.scheduleWorkers = 0;
      $scope.jobType.selection = 'escalation';

      unwatchStaffProviderAttribValue2 = $scope.$watch('staffProviderAttrib.value', function (val, oldval) {
        if (
          val &&
          val.filter &&
          !$scope.bundledAssign &&
          (!$scope.qualifiedWorkers || !$scope.qualifiedWorkers.filter((x) => x.selected).length)
        ) {
          const newLength = val.filter((choice) => choice && choice.choice_id).length;
          $scope.handleScheduleLength(newLength);
        }
      });

      unwatchFormDataWorker_count = $scope.$watch('formData.worker_count', function (val, oldval) {
        if (val < $scope.scheduleWorkers) {
          $scope.formData.worker_count = oldval;
        } else {
          $scope.offerWorkers = Math.max(0, val - $scope.scheduleWorkers);
          $scope.calculateBundlePercents();
        }
      });
    };

    $scope.handleScheduleLength = function (newLength) {
      if (newLength > $scope.formData.worker_count || !$scope.formData.workerCountChanged) {
        $scope.formData.worker_count = newLength || 1;
      }
      $scope.scheduleWorkers = newLength;
      $scope.offerWorkers = Math.max(0, $scope.formData.worker_count - $scope.scheduleWorkers);
      $scope.calculateBundlePercents();
    };

    $scope.calculateBundlePercents = function () {
      $scope.schedulePercent = ($scope.scheduleWorkers / $scope.formData.worker_count) * 100 + '%';
      $scope.offerPercent = ($scope.offerWorkers / $scope.formData.worker_count) * 100 + '%';
    };

    $scope.openBundlePicklist = function () {
      if (
        ($scope.jobType.selection === 'assign' && $scope.jobType.bundledAssign) ||
        (($scope.jobType.selection === 'escalation' || ($scope.applyAttrib && $scope.applyAttrib.value)) &&
          $scope.jobType.bundledPickList)
      ) {
        const modalInstance = $modal.open({
          controller: 'ModalPickListControler',
          templateUrl: 'app/views/schedule/pickListModal.html',
          scope: $scope,
          backdrop: 'static',
          windowClass: 'pick-list-modal',
          resolve: {
            pickList: function () {
              return $scope.pickList;
            },
            qualifiedWorkers: function () {
              return $scope.qualifiedWorkers;
            },
            offerNumber: function () {
              return $scope.offerWorkers;
            },
          },
        });
        modalInstance.result.then(
          (result) => {
            if (
              $scope.jobType.selection === 'escalate' ||
              ($scope.applyAttrib && $scope.applyAttrib.value) ||
              !$scope.pickList
            ) {
              $scope.pickList = result.pickList;
            }
            if ($scope.jobType.selection === 'assign' || !$scope.qualifiedWorkers) {
              $scope.qualifiedWorkers = result.qualifiedWorkers;
            }

            if ($scope.jobType.selection === 'assign') {
              $scope.handleScheduleLength($scope.qualifiedWorkers.filter((x) => x.selected).length);
            }
          },
          () => {
            if ($scope.jobType.selection === 'assign') {
              $scope.jobType.bundledAssign = false;
              $scope.openBundlePicklist();
            } else {
              $scope.jobType.bundledPickList = false;
              $scope.openBundlePicklist();
            }
          }
        );
      } else if ($scope.jobType.selection === 'assign') {
        $scope.qualifiedWorkers && $scope.qualifiedWorkers.forEach((worker) => (worker.selected = false));
        $scope.scheduleWorkers = 0;
        $scope.jobType.pickListRequested = false;
      } else if ($scope.jobType.selection === 'escalation') {
        $scope.pickList && $scope.pickList.forEach((worker) => (worker.selected = false));
        $scope.jobType.pickListRequested = false;
      }
    };

    $scope.editBundlePicklist = function () {
      const modalInstance = $modal.open({
        controller: 'ModalPickListControler',
        templateUrl: 'app/views/schedule/pickListModal.html',
        scope: $scope,
        backdrop: 'static',
        windowClass: 'pick-list-modal',
        resolve: {
          pickList: function () {
            return $scope.pickList;
          },
          qualifiedWorkers: function () {
            return $scope.qualifiedWorkers;
          },
          offerNumber: function () {
            return $scope.offerWorkers;
          },
        },
      });
      modalInstance.result.then((result) => {
        if ($scope.jobType.selection === 'escalate' || !$scope.pickList) {
          $scope.pickList = result.pickList;
        }
        if ($scope.jobType.selection === 'assign' || !$scope.qualifiedWorkers) {
          $scope.qualifiedWorkers = result.qualifiedWorkers;
        }

        if ($scope.jobType.selection === 'assign') {
          $scope.handleScheduleLength($scope.qualifiedWorkers.filter((x) => x.selected).length);
        }
      });
    };

    $scope.removeWorker = function (worker) {
      if ($scope.jobType.selection === 'assign') {
        $scope.scheduleWorkers--;
        $scope.formData.worker_count--;
      }
      worker.selected = false;

      if ($scope.jobType.selection === 'assign') {
        if ($scope.qualifiedWorkers.filter((x) => x.selected).length === 0) {
          $scope.jobType.bundledAssign = false;
          $scope.openBundlePicklist();
        }
      } else {
        if ($scope.pickList.filter((x) => x.selected).length === 0) {
          $scope.jobType.bundledPickList = false;
          $scope.openBundlePicklist();
        }
      }
    };

    $scope.attribsLoadedCallback = function (formData) {
      if (passedSession) {
        $scope.title = 'Clone Job';
        sessionManagerService.setSession(passedSession.session_id).then(function (session) {
          $scope.attrib_list.forEach(function (origAttrib) {
            const loadedAttrib = session.attrib_list.find(function (attrib) {
              return attrib.attrib_id === origAttrib.attrib_id;
            });
            if (loadedAttrib) {
              populateAttribs(loadedAttrib, origAttrib);
            }
          });
        });

        if (passedSession && passedSession.end) {
          if (formData.startDate) {
            formData.startDate = moment(passedSession.end);
          }
          if (formData.startTime) {
            formData.startTime = passedSession.end;
          }
        }
      }
      if (start_time) {
        if (formData.startDate) {
          formData.startDate = moment(start_time);
        }
        if (formData.startTime) {
          formData.startTime = start_time;
        }
      }
    };
    if ($scope.hasBundlePermission) {
      $scope.formData.bundled = true;
    } else {
      if ($scope.hasUnbundledPermission) {
        $scope.formData.bundled = false;
      } else {
        $scope.formData.bundled = true;
      }
    }
    $scope.handleBundled();

    $scope.$on('Picklist.Clear', () => {
      $scope.jobType.pickListRequested = false;
    });

    $scope.jobFormData = {
      name: '',
      desc: '',
      attrib_list: [],
    };
    $scope.jobFormKeyData = {};

    // Save job
    sessionManagerService.getRealmAttribs(enums.Realms.JobCreation).then(
      function (response) {
        if (response.success) {
          $scope.savejob_attrib_list = response.attrib_list;
        }
      },
      function (reason) {
        console.log(reason);
      }
    );

    $scope.onSaveJob = async function () {
      if (!isSaveJobEnabled()) {
        return;
      }
      const config = {
        title: 'Do you want to save this Job',
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Save Job',
        showCancelButton: true,
        input: 'text',
        inputPlaceholder: 'Write a Job name!',
        inputValidator: (value) => {
          if (!value) {
            return 'Job name is required!';
          }
        },
      };

      const returnHandler = function (inputValue) {
        $scope.saveJob(inputValue);
      };

      const { value: returnValue } = await Swal.fire(config);
      returnHandler(returnValue);
    };

    $scope.saveJob = async function (jobName) {
      const locationAttrib = $scope.attrib_list.find((x) => x.type === 'location');
      if (locationAttrib?.value?.location_id === 0) {
        try {
          const result = await $scope.handleOtherLocation(locationAttrib.value);
          if (!result) {
            $scope.submitBtnDisbaled = false;
            $scope.showSpinner = false;
            return;
          } else {
            locationAttrib.value.location_id = result.location_id;
          }
        } catch (e) {
          $scope.submitBtnDisbaled = false;
          $scope.showSpinner = false;
          return;
        }
      }
      $scope.submitBtnDisbaled = true;
      // Market place admin
      if (CURRENT_USER.user_type == 2) {
        $scope.jobFormData.market_place_id = CURRENT_USER.market_place_info.id;
        if ($scope.entFilter.enterprise_id != -1) {
          $scope.jobFormData.enterprise_id = $scope.entFilter.enterprise_id;
        }
      }
      const at_list = getJobFormData();
      const jobDescription = $scope.attrib_list.find((a) => a.name === 'job_description');
      $scope.jobFormData.attrib_list = at_list;
      $scope.jobFormData.name = jobName;
      $scope.jobFormData.desc = jobDescription ? jobDescription.value : '';
      sendJobToServer($scope.jobFormData);
    };

    const getJobFormData = function () {
      const attrib_list = [];
      const attribList = angular.copy($scope.attrib_list);
      const formatedAttribList = FormatHelperService.format_data(attribList);

      $scope.savejob_attrib_list.forEach((jobAttr) => {
        const attr = formatedAttribList.find((item) => item.attrib_id === jobAttr.attrib_id);
        if (attr) {
          attrib_list.push(attr);
        }
      });

      return attrib_list;
    };

    const sendJobToServer = function (jobObj) {
      if ($scope.entFilter.enterprise_id != -1) {
        jobObj.enterprise_id = $scope.entFilter.enterprise_id;
      }

      if (CURRENT_USER.market_place_info) {
        jobObj.market_place_id = CURRENT_USER.market_place_info.id;
      }

      jobManagerService.submitJobRequest(jobObj).then(
        function (response) {
          $scope.submitBtnDisbaled = false;
          if (response.success) {
            AlertService.successAlert(response.message);
            $scope.scheduleJobFormSubmission.job_id = response.job_id;
            getJobs();
          } else {
            AlertService.errorAlert(response.message);
          }
        },
        function (reason) {
          $scope.submitBtnDisbaled = false;

          AlertService.serverRequestErrorAlert(reason);
        }
      );
    };

    $scope.isJobSaveEnabled = false;

    const checkJobFormDataChange = function () {
      if (!$scope.selectedJob) {
        return true;
      }

      const isFormChanged = false;
      const formattedCurrentAttribList = FormatHelperService.format_data($scope.attrib_list);

      for (const origAttr of $scope.selectedJob.attrib_list) {
        const currAttr = formattedCurrentAttribList.find((currentAttr) => currentAttr.attrib_id === origAttr.attrib_id);
        if (currAttr) {
          let origValue;
          switch (origAttr.type) {
            case enums.FilterableTypes.single_list:
              origValue = origAttr.value_list.find((origVal) => origVal.selected);
              if (currAttr.value_single_list !== origValue.choice_id) {
                return true;
              }
              break;

            case enums.FilterableTypes.multi_list:
              break;

            case enums.FilterableTypes.location:
              origValue = origAttr.value_location.find((origVal) => origVal.selected);
              if (currAttr.value_location.location_id !== origValue.location_id) {
                return true;
              }
              break;

            case enums.FilterableTypes.place:
              origValue = origAttr.value_place.find((origVal) => origVal.selected);
              if (currAttr.value_place.place_id !== origValue.place_id) {
                return true;
              }
              break;

            case enums.FilterableTypes.text:
              if (currAttr.value_text !== origAttr.value_text) {
                return true;
              }
              break;

            case enums.FilterableTypes.count:
              if (currAttr.value_count !== origAttr.value_count) {
                return true;
              }
              break;

            case enums.FilterableTypes.image:
              if (currAttr.value_text !== origAttr.value_text) {
                return true;
              }
              break;
          }
        }
      }

      return isFormChanged;
    };

    const isSaveJobEnabled = function () {
      if ($scope.scheduleJobForm.$invalid) {
        $scope.isJobSaveEnabled = false;
        return false;
      }
      if ($scope.scheduleJobFormSubmission && !$scope.scheduleJobFormSubmission.job_id) {
        $scope.isJobSaveEnabled = true;
        return true;
      }
      if (!$scope.selectedJob) {
        $scope.isJobSaveEnabled = true;
        return true;
      }
      const isFormChanged = checkJobFormDataChange();

      $scope.isJobSaveEnabled = isFormChanged;
      return isFormChanged;
    };

    const unwatchScheduleJobFormSubmissionJobId = $scope.$watch(
      'scheduleJobFormSubmission.job_id',
      function (newVal, oldVal) {
        isSaveJobEnabled();
      }
    );

    const unwatchAttribList = $scope.$watch(
      'attrib_list',
      function (newVal, oldVal) {
        isSaveJobEnabled();
      },
      true
    );

    $scope.$on('$destroy', function () {
      if (typeof unwatchFormDataEndDate === 'function') {
        unwatchFormDataEndDate();
      }

      if (typeof unwatchFormDataStartDate === 'function') {
        unwatchFormDataStartDate();
      }
      if (typeof unwatchFormDataWorker_count === 'function') {
        unwatchFormDataWorker_count();
      }
      if (typeof unwatchScheduleJobFormSubmissionJobId === 'function') {
        unwatchScheduleJobFormSubmissionJobId();
      }
      if (typeof unwatchStaffProviderAttribValue === 'function') {
        unwatchStaffProviderAttribValue();
      }
      if (typeof unwatchStaffProviderAttribValue2 === 'function') {
        unwatchStaffProviderAttribValue2();
      }
      if (typeof unwatchAttribList === 'function') {
        unwatchAttribList();
      }
    });
  },
]);
