import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseWidget } from './base.widget';
import * as fromRoot from '../store';
import { FilterRequestList } from '../store/actions';

@Component({
  selector: 'cancel-break-down-component',
  templateUrl: './common.widget.template.html',
})
export class CancelBreakDownComponent extends BaseWidget {
  public chartType = 'pie';

  constructor(store: Store<fromRoot.State>) {
    super(store);
  }

  loadDataTemplate(list) {
    if (!list) {
      return;
    }
    this.count = list.filter((i) => i.cancelled_reason !== 0).length;
    this.titleValue = this.count;

    let statuses = {
      0: 'Not Canceled',
      1: 'Canceled By Admin',
      2: 'Request Timed Out',
      3: 'All Available Providers Declined Request',
      4: 'Canceled By Requesters',
      5: 'Canceled By Worker',
      6: 'Cancelled By System',
      7: 'No Interested Workers',
      8: 'No Decision By Employer',
      9: 'Interest Cancelled By Admin',
      10: 'Interest Cancelled By Requester',
      11: 'Cancelled due to assignment change',
      12: 'No show',
    };
    let statusMap = {};
    list
      .map((res) => res.cancelled_reason)
      .filter((reason) => reason !== 0)
      .forEach((item) => {
        if (statusMap[item]) {
          statusMap[item].count++;
        } else {
          statusMap[item] = {
            count: 1,
            code: item,
          };
        }
      });

    this.data = Object.keys(statusMap).map((key) => {
      let value = statusMap[key];
      return {
        name: statuses[key],
        value: value.count,
        code: value.code,
      };
    });
  }

  filterAll() {
    let action = new FilterRequestList({
      showLate: false,
      showLong: false,
      status: 7,
      cancelCode: null,
    });
    this.store.dispatch(action);
  }

  filterData(data) {
    let point = this.data.find((p) => p.name === data.name);
    if (point) {
      let action = new FilterRequestList({ showLate: false, showLong: false, status: null, cancelCode: point.code });
      this.store.dispatch(action);
    }
  }
}
