import { Component } from '@angular/core';

import { AppConfigService } from '../../shared/services/app-config.service';

@Component({
  selector: 'analyticsComponent',
  templateUrl: 'analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent {
  public selectedTab: string;
  public moduleName: string;

  constructor(public appConfigService: AppConfigService) {
    this.moduleName = this.appConfigService.siteMapConfig.analytics.displayName;
  }

  public onSelectTab(selectedTab): void {
    this.selectedTab = selectedTab;
  }
}
