import module from '../../module';
import Swal from 'sweetalert2';

export default module.directive('dirEditNotification', function () {
  return {
    restrict: 'E',
    scope: {
      selectTemplate: '&',
      id: '=',
    },
    controller: [
      '$scope',
      '$timeout',
      'communicationsService',
      '$modal',
      'AlertService',
      async function ($scope, $timeout, communicationsService, $modal, AlertService) {
        $scope.cancel = async function () {
          if ($scope.emailForm.$dirty) {
            const config = {
              title: 'Discard changes?',
              text: 'You have made changes to the template, do you want to discard them?',
              confirmButtonText: 'Yes, discard the changes',
              cancelButtonText: 'No, go back',
              icon: 'warning',
              showConfirmButton: true,
              showCancelButton: true,
            };
            const { isConfirmed } = await Swal.fire(config);
            if (isConfirmed) {
              $scope.selectTemplate()(null, null);
            }
          } else {
            $scope.selectTemplate()(null, null);
          }
        };

        $scope.revertToDefault = async function () {
          const config = {
            title: 'Revert to the default template?',
            text: 'Are you sure you want to revert to the default notification template?',
            confirmButtonText: 'Yes, revert to default',
            cancelButtonText: 'No, go back',
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
          };
          const { isConfirmed } = await Swal.fire(config);
          if (isConfirmed) {
            const response = await communicationsService.revertNotificationTemplateToDefault($scope.template);
            if (response) {
              AlertService.successAlert('Notification template reverted to the default');
              $scope.selectTemplate()(null, null);
            } else {
              AlertService.errorAlert('There was an error reverting the notification template');
            }
          }
        };

        $scope.revertToCustom = async function () {
          const config = {
            title: 'Revert to the previous custom template?',
            text: 'Are you sure you want to revert to the previous custom notification template?',
            confirmButtonText: 'Yes, revert to previous custom',
            cancelButtonText: 'No, go back',
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
          };
          const { isConfirmed } = await Swal.fire(config);
          if (isConfirmed) {
            const response = await communicationsService.revertNotificationTemplateToCustom($scope.template);
            if (response) {
              AlertService.successAlert('Notification template reverted to the previous custom one.');
              $scope.selectTemplate()(null, null);
            } else {
              AlertService.errorAlert('There was an error reverting the notification template');
            }
          }
        };

        $scope.save = async function () {
          const modalInstance = $modal.open({
            templateUrl: 'app/views/admin/communications/previewNotification.html',
            size: 'xlg',
            backdrop: 'static',
            controller: 'PreviewNotificationController',
            resolve: {
              passedData() {
                return {
                  template: $scope.template,
                  mode: 'save',
                };
              },
            },
          });
          const result = await modalInstance.result;
          if (result) {
            const response = await communicationsService.saveNotificationTemplate($scope.template);
            if (response) {
              AlertService.successAlert('Notification template updated successfully');
              $scope.selectTemplate()(null, null);
            } else {
              AlertService.errorAlert('There was an error updating the notification template');
            }
          }
        };

        $scope.preview = function () {
          $modal.open({
            templateUrl: 'app/views/admin/communications/previewNotification.html',
            size: 'lg',
            backdrop: 'static',
            controller: 'PreviewNotificationController',
            resolve: {
              passedData() {
                return {
                  template: $scope.template,
                };
              },
            },
          });
        };

        $scope.template = await communicationsService.getNotificationTemplate($scope.id);
      },
    ],
    templateUrl: 'app/views/admin/communications/editNotification.html',
  };
});
