import moment from 'moment';
import module from './module';
import * as enums from '../enums';

export default module.service('pleaseReviewService', [
  '$rootScope',
  '$q',
  'sessionManagerService',
  '$localStorage',
  'FormatHelperService',
  'generalDataService',
  'EnterpriseFilterService',
  'AuthenticationService',
  'TimeFormatService',
  function (
    $rootScope,
    $q,
    sessionManagerService,
    $localStorage,
    FormatHelperService,
    generalDataService,
    EnterpriseFilterService,
    AuthenticationService,
    TimeFormatService
  ) {
    let counter = 0;
    let cache = [];
    const CURRENT_USER = AuthenticationService.getCurrentUser();

    $rootScope.$on('PleaseReview.Reviewed.Admin', function (e, id) {
      const justRatedIndex = cache.findIndex(function (unrated) {
        return unrated.request_id === id;
      });
      cache.splice(justRatedIndex, 1);
    });

    $rootScope.$on('PleaseReview.Reviewed.Widget', function (e, id) {
      const justRatedIndex = cache.findIndex(function (unrated) {
        return unrated.request_id === id;
      });
      cache.splice(justRatedIndex, 1);
    });

    function _getRequestTimeDisplayVal(utcString) {
      const _momentObj = moment.utc(utcString, TimeFormatService.format('fullDateTime')).clone();
      const _val = FormatHelperService.returnLocalTimeString(_momentObj, TimeFormatService.format('dateTimeLong'));
      return _val;
    }

    function _get() {
      const deferred = $q.defer();
      const enterprise = EnterpriseFilterService.fetchSelectedEnterprise();
      const entId = enterprise ? enterprise.enterprise_id : -1;

      if (
        (CURRENT_USER.user_type === enums.UserType.MarketPlaceAdmin ||
          CURRENT_USER.user_type === enums.UserType.BranchAdmin) &&
        entId === -1
      ) {
        setTimeout(() => {
          deferred.resolve([]);
        }, 0);
        return deferred.promise;
      }

      if (cache.length) {
        deferred.resolve(cache);
      } else {
        sessionManagerService.getAllIncompleteRequests(entId).then(function (sessions) {
          if (sessions) {
            cache = sessions;
            const filteredSessions = filterOutCanceled(sessions);
            counter = filteredSessions.length;
            $rootScope.$broadcast('PleaseReview.Fetched', counter);
            deferred.resolve(sessions);
          } else {
            deferred.resolve([]);
          }
        });
      }
      return deferred.promise;
    }

    function _review(sessionId, rate) {
      const deferred = $q.defer();
      sessionManagerService
        .endSessionRequest({
          end_type: 1,
          request_id: sessionId,
          attrib_list: [
            {
              attrib_id: 44,
              value_count: rate,
            },
          ],
        })
        .then(function () {
          counter--;
          $rootScope.$broadcast('PleaseReview.Fetched', counter);
          deferred.resolve();
        });
      return deferred.promise;
    }

    function _formatReview(request) {
      const display = request && request.key_attrib ? request.key_attrib : generalDataService.getKeySession();
      const _startTime = _getRequestTimeDisplayVal(request.checkin_date + request.checkin_time);
      return 'Completed  ' + display + ' request for ' + _startTime + ' by ' + request.serv_full_name;
    }

    function filterOutCanceled(services) {
      return services.filter((service) => {
        if (service && service.status) {
          return service.status.type !== enums.RequestStatus.Canceled;
        }
        return false;
      });
    }

    return {
      getUnratedServices: _get,
      review: _review,
      formatReview: _formatReview,
      filterOutCanceled,
    };
  },
]);
