import module from '../module';

export default module.directive('reportingDirective', [
  function () {
    return {
      restrict: 'E',
      templateUrl: 'app/views/reporting/index.html',
      controller: 'ReportingController',
    };
  },
]);
