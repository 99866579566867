import angular from 'angular';
import module from '../module';
import Swal from 'sweetalert2';
import moment from 'moment';
import * as enums from '../../enums';

export default module.controller('ChangeJobOrderModalController', [
  '$scope',
  '$rootScope',
  '$modalInstance',
  'AlertService',
  'enterprise_selection',
  'jobManagerService',
  'FormatHelperService',
  'passed_job_id',
  'passed_job_order_id',
  'locationManagerService',
  'trackingService',
  'passedSession',
  'sessionManagerService',
  '$timeout',
  'AuthenticationService',
  'PERMISSIONS',
  'generalDataService',
  'branchFilterService',
  'passedOperation',
  'timeZoneStore',
  'TimeFormatService',
  function (
    $scope,
    $rootScope,
    $modalInstance,
    AlertService,
    enterprise_selection,
    jobManagerService,
    FormatHelperService,
    passed_job_id,
    passed_job_order_id,
    locationManagerService,
    trackingService,
    passedSession,
    sessionManagerService,
    $timeout,
    AuthenticationService,
    PERMISSIONS,
    generalDataService,
    branchFilterService,
    passedOperation,
    timeZoneStore,
    TimeFormatService
  ) {
    locationManagerService.clearSelectedLocation();
    $scope.submitBtnDisbaled = false;
    $scope.title = 'Schedule Job';
    $scope.keySession = generalDataService.getKeySession();
    $scope.entFilter = enterprise_selection;
    $scope.jobType = {
      selection: '',
    }; // for job type checkboxes
    const CURRENT_USER = AuthenticationService.getCurrentUser();
    $scope.load_all_jobs = CURRENT_USER.market_place_info ? CURRENT_USER.market_place_info.load_all_jobs : false;
    $scope.enterpriseId =
      CURRENT_USER.user_type === 2 || CURRENT_USER.user_type === 6 ? $scope.entFilter.enterprise_id : undefined;
    $scope.hasBundlePermission = CURRENT_USER.permissionCheck(PERMISSIONS.SCHEDULE_JOB_ORDER);
    $scope.dateFormat = TimeFormatService.format('jobDate');

    $scope.formData = {
      startDate: moment(),
      endDate: moment(),
      startTime: new Date().setHours('00', '00'),
    };

    $scope.effectiveDateAttrib = {
      display_name: 'Effective Date',
      type: 'date',
      type_flags: {
        required: true,
      },
    };

    $scope.scheduleJobFormSubmission = {
      job_id: passed_job_id,
      end_date: '',
      day_list: [],
      attrib_list: [],
      job_order_id: passed_job_order_id || (passedSession && (passedSession.job_order_id || passedSession.jobOrderId)),
    };

    $scope.branch = branchFilterService.getSelectedBranch();

    if ($scope.branch && $scope.branch.id !== -1) {
      $scope.scheduleJobFormSubmission.branch_id = $scope.branch.id;
    }

    $scope.dayListArray = [
      {
        id: 7,
        name: 'Select All',
        selected: true,
      },
      {
        id: 3,
        name: 'Wednesday',
        selected: true,
      },
      {
        id: 6,
        name: 'Saturday',
        selected: true,
      },
      {
        id: 1,
        name: 'Monday',
        selected: true,
      },
      {
        id: 4,
        name: 'Thursday',
        selected: true,
      },
      {
        id: 0,
        name: 'Sunday',
        selected: true,
      },
      {
        id: 2,
        name: 'Tuesday',
        selected: true,
      },
      {
        id: 5,
        name: 'Friday',
        selected: true,
      },
    ];

    $scope.selectedDays = $scope.dayListArray.map(function (day) {
      return day.id;
    });

    $scope.formHelper = {
      markers: [],
      dateOptionsFrom: {
        filter: function (day) {
          return $scope.selectedDays.indexOf(day.weekday()) !== -1;
        },
      },
      dateOptionsTo: {
        filter: function (day) {
          return $scope.selectedDays.indexOf(day.weekday()) !== -1;
        },
      },
    };

    $scope.toggleDateRange = function () {
      $scope.showDateRange = !$scope.showDateRange;
      $scope.scheduleJobForm['date_time'].$setDirty();
    };

    $scope.$watch('formData.startDate', (value) => {
      const formatText = TimeFormatService.format('jobDate');
      const start = moment(value, TimeFormatService.format('dateSlash')).format(formatText);
      const end = moment($scope.formData.endDate, TimeFormatService.format('dateSlash')).format(formatText);

      if (moment(value).isAfter(moment($scope.formData.endDate))) {
        $scope.formData.endDate = moment(value);
      }

      $scope.formHelper.formattedDate = `${start} - ${end}`;

      if (value) {
        $scope.calculateSessionNumber();
      }
    });

    $scope.$watch('formData.endDate', (value) => {
      const formatText = TimeFormatService.format('jobDate');
      const start = moment($scope.formData.startDate, TimeFormatService.format('dateSlash')).format(formatText);
      const end = moment(value, TimeFormatService.format('dateSlash')).format(formatText);

      if (moment(value).isBefore(moment($scope.formData.startDate))) {
        $scope.formData.startDate = moment(value);
      }

      $scope.formHelper.formattedDate = `${start} - ${end}`;

      if (value) {
        $scope.calculateSessionNumber();
      }
    });

    $scope.$watch('effectiveDateAttrib.pickerModel', (value) => {
      if (value) {
        const formatText = TimeFormatService.format('middleDate');
        const start = moment($scope.effectiveDateAttrib.pickerModel, formatText).format(formatText);
        setTimeout(() => ($scope.effectiveDateAttrib.value = start));
      }
    });

    $scope.calculateSessionNumber = function () {
      const start = moment($scope.formData.startDate);
      const end = moment($scope.formData.endDate);

      const deSelectedDays = $scope.dayListArray
        .filter(function (day) {
          return !day.selected;
        })
        .map(function (day) {
          return day.id;
        });

      $scope.numberOfSessions = $scope.calculateDays(start, end, deSelectedDays);
    };

    $scope.calculateDays = function (start, end, deSelectedDays) {
      let result = 0;
      while (start <= end) {
        if (deSelectedDays.indexOf(start.day()) === -1) {
          result++;
        }
        start = start.clone().add(1, 'd');
      }
      return result;
    };

    // Get service request attribs 3
    sessionManagerService.getRealmAttribs(enums.Realms.Service).then(
      function (response) {
        if (response.success) {
          const scheduledService = response.attrib_list.filter(function (obj) {
            return obj.type_flags.type == 0 || obj.type_flags.type == 1;
          });

          const formData = scheduledService.sort(function (a, b) {
            return a.order - b.order;
          });

          $scope.originalFormData = angular.copy(formData);
          $scope.attrib_list = angular.copy(formData);
          reorderAttribs($scope.attrib_list);

          // Default to apply if escalation is not present
          if (!$scope.escalationAttrib && $scope.applyAttrib) {
            $scope.applyAttrib.value = true;
          }

          getJobs();
        } else {
          AlertService.errorAlert(reason);
          $modalInstance.close();
        }
      },
      function (reason) {
        AlertService.serverRequestErrorAlert(reason);
        $modalInstance.close();
      }
    );
    // Get jobs
    function getJobs() {
      jobManagerService.getAllJobs($scope.entFilter.enterprise_id).then(
        function (jobsArray) {
          $scope.jobsArray = jobsArray;
          if ($scope.scheduleJobFormSubmission.job_id) {
            $scope.setSelectedJobAttribs($scope.scheduleJobFormSubmission.job_id);
            $scope.attribsLoadedCallback($scope.formData);
          } else {
            $scope.attribsLoadedCallback($scope.formData);
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    }

    function populateAttribs(source, target) {
      switch (source.type) {
        case 'single_list':
          target.value = source.value_list.filter(function (obj) {
            return obj.selected == true;
          })[0];
          break;

        case 'multi_list':
          target.value = source.value_list;
          target.choice_list = source.value_list;
          break;

        case 'text':
          target.value = source.value_text;
          break;

        case 'flag':
          target.value = source.value_flag;
          break;

        case 'count':
          target.value = source.value_count;
          break;

        case 'location':
          if (source.value_location && source.value_location.filter) {
            // Value_location is an array
            target.value = source.value_location.filter(function (obj) {
              return obj.selected == true;
            })[0]; // assign selected location for display
          } else {
            // Value_location is an object
            target.value = source.value_location;
          }

          // var locId = selectedAttrib.value.location_id
          // locationManagerService.getLocation(locId).then(function(response){
          locationManagerService.setLocation(target.value);

          // });
          $scope.selectedLocation = target.value;
          break;

        case 'place':
          var selected = source.value_place.filter(function (obj) {
            return obj.selected == true;
          })[0];

          target.value = source.value_place.filter(function (obj) {
            return obj.name == selected.name;
          })[1];
          break;
      }
    }

    // populate fields based on Job Selection
    $scope.setSelectedJobAttribs = function (selectedJobId) {
      locationManagerService.clearSelectedLocation();
      $scope.attrib_list = angular.copy($scope.originalSortedAttribList);
      const selectedJob = $scope.jobsArray.filter(function (obj) {
        return obj.job_id == selectedJobId;
      })[0];

      if (!selectedJob) {
        return;
      }

      angular.forEach(selectedJob.attrib_list, function (attrib) {
        const selectedAttrib = $scope.attrib_list.filter(function (obj) {
          return obj.attrib_id == attrib.attrib_id;
        })[0];

        if (selectedAttrib) {
          populateAttribs(attrib, selectedAttrib);
        }
      }); // end for each
      trackingService.trackEvent(trackingService.MODALS, 'schedule job', 'change job');
    };

    // format attrib array
    function reorderAttribs(attrib_list) {
      const _removedAttribsArray = [];
      angular.forEach(attrib_list, function (attrib, index) {
        switch (attrib.name) {
          case 'date_time':
            $scope.dateAttrib = attrib;
            _removedAttribsArray.push(attrib);
            break;

          case 'duration':
            $scope.durationAttrib = attrib;
            $scope.durationAttrib.value = $scope.durationAttrib.default_count || 0;
            _removedAttribsArray.push(attrib);
            $scope.sliderModel = {
              options: {
                floor: $scope.durationAttrib.min_amount,
                ceil: $scope.durationAttrib.max_amount,
                step: $scope.durationAttrib.inc_amount,
                showTicks: false,
                onChange: function (_, value) {
                  // rzslider does not support form dirty checks, we need to manually update a field
                  const targetField = $scope.scheduleJobForm[$scope.durationAttrib.name];
                  if (targetField) {
                    targetField.$setDirty();
                  }
                },
                translate: function (value) {
                  return FormatHelperService.generateDurationLabel(value);
                },
              },
            };
            break;

          case 'escalation_list':
            $scope.escalationAttrib = attrib;
            $scope.escalationAttrib.value = {
              choice_id: 0,
            };
            _removedAttribsArray.push(attrib);
            break;

          case 'staff_provider_list':
            $scope.staffProviderAttrib = attrib;
            $scope.staffProviderAttrib.value = {
              choice_id: 0,
            };
            _removedAttribsArray.push(attrib);
            break;

          case 'proserv_list':
            $scope.proservAttrib = attrib;
            $scope.proservAttrib.value = {
              choice_id: -1,
            };
            _removedAttribsArray.push(attrib);
            break;

          case 'price_list':
            $scope.priceAttrib = attrib;
            $scope.priceAttrib.value = {
              choice_id: 0,
            };
            _removedAttribsArray.push(attrib);
            break;

          case 'interest_flag':
            $scope.applyAttrib = attrib;
            $scope.applyAttrib.value = false;
            _removedAttribsArray.push(attrib);
            break;
        }
      });
      removeAttribFromArray(_removedAttribsArray);
    }

    function removeAttribFromArray(attribsArray) {
      for (let i = 0; i < attribsArray.length; i++) {
        const index = $scope.attrib_list.indexOf(attribsArray[i]);
        $scope.attrib_list.splice(index, 1);
      }
      $scope.originalSortedAttribList = angular.copy($scope.attrib_list);
    }

    // Calendar dtae time selection options and controls
    $scope.startDate = {
      opened: false,
    };
    $scope.openStartDate = function () {
      $scope.startDate.opened = true;
    };

    $scope.endDate = {
      opened: false,
    };
    $scope.openEndDate = function () {
      $scope.endDate.opened = true;
    };

    // Default starting date to today
    $scope.formData.startDate = new Date();

    // Options
    // $scope.minStartDate = moment();
    // $scope.minEndDate = moment();

    $scope.setEndDateMin = function (startDate) {
      $scope.minEndDate = startDate;
      // ensure user does not set end date before start
      if (moment($scope.formData.endDate).isBefore(moment(startDate))) {
        $scope.formData.endDate = startDate;
      }
      trackingService.trackEvent(trackingService.MODALS, 'schedule job', 'change start date');
    };

    $scope.selectAllDayList = function (dayItem) {
      if (dayItem.name === 'Select All' && dayItem.selected == true) {
        angular.forEach($scope.dayListArray, function (obj) {
          obj.selected = true;
        });
      } else if (dayItem.name === 'Select All' && dayItem.selected == false) {
        angular.forEach($scope.dayListArray, function (obj) {
          obj.selected = false;
        });
      } else if (dayItem.name !== 'Select All' && dayItem.selected == false) {
        $scope.dayListArray.filter(function (obj) {
          return obj.name === 'Select All';
        })[0].selected = false;
      }

      $scope.selectedDays = $scope.dayListArray
        .filter(function (day) {
          return day.selected;
        })
        .map(function (day) {
          return day.id;
        });

      $timeout(function () {
        $scope.$apply();
      });

      $scope.formHelper.markers.push({});
      $scope.calculateSessionNumber();
      trackingService.trackEvent(trackingService.MODALS, 'schedule job', 'select all');
    };

    // Start time selection
    $scope.hstep = 1;
    $scope.mstep = 5;

    function timeErrorChecks(formData) {
      if (moment(formData.endDate).isBefore(formData.startDate, 'day')) {
        return true;
      } else {
        return false;
      }
    }
    // Modal Controls
    $scope.validate = async function (successCb) {
      if ($scope.jobType.pickListRequested && $scope.pickList) {
        const result = $scope.validatePickList();
        if (result) {
          const config = {
            title: 'Are you sure?',
            text: result.message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#cc514c',
            confirmButtonText: 'Continue',
          };

          const returnHandler = function (isConfirm) {
            if (isConfirm) {
              successCb();
            } else {
              $scope.submitBtnDisbaled = false;
              $scope.showSpinner = false;
              $scope.$apply();
            }
          };

          const { value: returnValue } = await Swal.fire(config);
          returnHandler(returnValue);
        } else {
          successCb();
        }
      } else {
        successCb();
      }
    };

    $scope.checkDateCap = function (formData) {
      // A job can be 15 week long tops
      const start = moment(formData.startDate),
        end = moment(formData.endDate);
      return end.diff(start, 'week') > 52;
    };

    $scope.checkPastStart = function (formData) {
      if ($scope.jobType.selection === 'escalation' || $scope.offerWorkers) {
        const startTime = moment(formData.startTime).format('HHmm');
        const startDate = moment(formData.startDate, TimeFormatService.format('jobDate')).format(
          TimeFormatService.format('fullDateNoSeparator')
        );
        const startDateTime = startDate + ' ' + startTime;

        const localDate = timeZoneStore.calcTime(moment(startDateTime).toDate());
        const builtDateTime = moment(localDate, TimeFormatService.format('fullDateTimeNoSeparator'));

        return moment(builtDateTime).isBefore(moment());
      }
    };

    $scope.checkBundledOpen = function (formData, staffProviderAttrib) {
      if ($scope.jobType.selection === 'assign') {
        const val = staffProviderAttrib && staffProviderAttrib.value && staffProviderAttrib.value;
        return formData.bundled && val && Array.isArray(val) && val.find((x) => x.name === 'Open');
      }
      return false;
    };

    $scope.ok = async function () {
      $scope.submitBtnDisbaled = true;
      $scope.showSpinner = true;
      // Do Error Checking for missing fields
      const _errorMessages = [];

      // day list selection check
      const daySelectionCheck = $scope.dayListArray.filter(function (obj) {
        return obj.selected == true;
      });

      if (daySelectionCheck.length == 0) {
        _errorMessages.push('Missing Day Selection');
      }

      // date and time check
      if ($scope.dateAttrib) {
        timeErrorChecks($scope.formData) && _errorMessages.push('End date cannot be before Start Date');
        !$scope.formData.startDate && _errorMessages.push('Missing Start Date Selection');
        !$scope.formData.endDate && _errorMessages.push('Missing End Date Selection');
        !$scope.formData.startTime && _errorMessages.push('Missing Start Time');

        $scope.checkPastStart($scope.formData) &&
          _errorMessages.push(
            'The time you entered is for a shift starting in the past. Please adjust to start in the future'
          );

        $scope.checkDateCap($scope.formData) && _errorMessages.push("A job's length cannot exceed a year");
      }

      $scope.checkBundledOpen($scope.formData, $scope.staffProviderAttrib) &&
        _errorMessages.push('Sorry, Job Orders to Open status are not yet supported');

      // Multi select erorr Checking
      angular.forEach($scope.attrib_list, function (attrib) {
        if (attrib.type === 'multi_list' && attrib.type_flags.required == true) {
          if (attrib.value.length == 0) {
            _errorMessages.push('Missing Required ' + attrib.display_name + ' Selection');
          }
        }
      });

      if (_errorMessages.length > 0) {
        AlertService.errorAlert(_errorMessages.join('\n'));
        $scope.submitBtnDisbaled = false;
        $scope.showSpinner = false;
      } else {
        $scope.validate(async function () {
          // no missing fields
          if ($scope.durationAttrib) {
            $scope.attrib_list.push($scope.durationAttrib);
          }

          if ($scope.proservAttrib) {
            $scope.attrib_list.push($scope.proservAttrib);
          }

          if ($scope.priceAttrib) {
            $scope.attrib_list.push($scope.priceAttrib);
          }

          if ($scope.applyAttrib) {
            $scope.attrib_list.push($scope.applyAttrib);
          }

          if ($scope.jobType.selection === 'escalation' && !$scope.jobType.pickListRequested) {
            if ($scope.escalationAttrib) {
              $scope.attrib_list.push($scope.escalationAttrib);
            }
          }

          if ($scope.jobType.selection === 'assign' || ($scope.formData.bundled && $scope.scheduleWorkers)) {
            if ($scope.qualifiedWorkers) {
              const providers = $scope.qualifiedWorkers
                .filter(function (item) {
                  return item.selected;
                })
                .map(function (item) {
                  return {
                    choice_id: item.provider_id,
                  };
                });
              const orig = $scope.staffProviderAttrib.value;
              $scope.staffProviderAttrib.value = providers.concat(orig);
            }

            if ($scope.staffProviderAttrib) {
              $scope.attrib_list.push($scope.staffProviderAttrib);
            }
          }
          await formatDateTimes($scope.formData);
        });
        trackingService.trackEvent(trackingService.MODALS, 'schedule job', 'submit');
      }
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      trackingService.trackEvent(trackingService.MODALS, 'schedule job', 'cancel');
    };

    // format date_time
    const formatDateTimes = async function (formData) {
      const _startTime = await TimeFormatService.formatTimezone(
        'fullDateTimeNoSeparator',
        formData.startTime,
        formData.startTime,
        $scope.selectedLocation,
        true
      );

      const startTime = moment.utc(_startTime).format('HHmm');
      const startDate = moment(formData.startDate, TimeFormatService.format('jobDate')).format(
        TimeFormatService.format('fullDateNoSeparator')
      );
      const startDateTime = startDate + ' ' + startTime;
      const result = moment.utc(startDateTime, TimeFormatService.format('fullDateTimeNoSeparator'));
      result.skipTimezoneAlignment = true;
      $scope.dateAttrib.value = result;

      $scope.attrib_list.push($scope.dateAttrib);
      // format end_date
      const endDate = moment(formData.endDate, TimeFormatService.format('jobDate')).format(
        TimeFormatService.format('fullDateNoSeparator')
      );
      const endDateTime = endDate + ' ' + startTime;
      const builtEndDateTime = moment(endDateTime, TimeFormatService.format('fullDateTimeNoSeparator'));

      const endDateAdjusted = await TimeFormatService.formatTimezone(
        'fullDateTimeNoSeparator',
        builtEndDateTime,
        builtEndDateTime,
        $scope.selectedLocation
      );

      const finalEndDate = moment
        .utc(timeZoneStore.calcTime(endDateAdjusted))
        .format(TimeFormatService.format('apiDate'));

      if (finalEndDate !== $scope.originalEndDate) {
        $scope.scheduleJobFormSubmission.end_date = finalEndDate;
      } else {
        delete $scope.scheduleJobFormSubmission.end_date;
      }

      if ($scope.effectiveDateVisible) {
        $scope.scheduleJobFormSubmission.change_date = moment(
          $scope.effectiveDateAttrib.value,
          TimeFormatService.format('middleDate')
        ).format(TimeFormatService.format('apiDate'));
      }
      // call next formating function
      dayListSelectionCheck();
    };

    // format day selection list
    const dayListSelectionCheck = function () {
      const allSelected = $scope.dayListArray.filter(function (obj) {
        return obj.name === 'Select All';
      })[0];

      if (allSelected.selected == true) {
        $scope.scheduleJobFormSubmission.day_list = [0, 1, 2, 3, 4, 5, 6];
      } else {
        angular.forEach($scope.dayListArray, function (day) {
          if (day.selected == true) {
            $scope.scheduleJobFormSubmission.day_list.push(day.id);
          }
        });
      }

      if (_.isEqual(_.sortBy($scope.originalDayList), _.sortBy($scope.scheduleJobFormSubmission.day_list))) {
        delete $scope.scheduleJobFormSubmission.day_list;
      }
      sendToServer();
    };

    
    const sendToServer = function () {
      if (
        ($scope.formData.bundled && $scope.pickList) ||
        ($scope.pickListValid && $scope.jobType.selection === 'escalation')
      ) {
        $scope.scheduleJobFormSubmission.provider_list = $scope.pickList
          .filter(function (item) {
            return item.selected;
          })
          .map(function (item) {
            return item.provider_id;
          });
      }

      const formData = angular.copy($scope.scheduleJobFormSubmission);
      delete formData.job_id;
      formData.pause_job = $scope.load_all_jobs ? $scope.pause_job : false;
      const attribList = angular.copy($scope.attrib_list);

      formData.attrib_list = FormatHelperService.format_data(attribList);
      const dirtyFields = Object.keys($scope.scheduleJobForm)
        .filter((x) => x.indexOf('$') === -1)
        .map((x) => ({
          name: x,
          control: $scope.scheduleJobForm[x],
        }))
        .filter((x) => x.control.$dirty)
        .map((x) => x.name);

      // double check multi list attribs actually changed AZDO 111922
      const multiListAttribs = attribList.filter((attrib) => attrib.type === 'multi_list');
      const multiListDirtyAttribs = multiListAttribs.filter((attrib) => dirtyFields.indexOf(attrib.name) > -1);
      multiListDirtyAttribs.forEach((attrib) => {
        const originalAttrib = $scope.originalAttribs.find((x) => x.attrib_id === attrib.attrib_id);
        if (originalAttrib) {
          const originalValues = originalAttrib.value.filter((x) => x.selected).map((x) => x.choice_id);
          const newValues = attrib.value.filter((x) => x.selected).map((x) => x.choice_id);
          if (_.isEqual(_.sortBy(originalValues), _.sortBy(newValues))) {
            dirtyFields.splice(dirtyFields.indexOf(attrib.name), 1);
          }
        }
      });

      formData.attrib_list = formData.attrib_list
        .filter((attrib) => dirtyFields.indexOf(attrib.name) > -1)
        .map(function (attrib) {
          const newAttrib = angular.copy(attrib);
          newAttrib.action = 1;
          return newAttrib;
        });

      let serverCall = jobManagerService.submitScheduleJobRequest;
      let serviceArg = jobManagerService;

      switch (passedOperation) {
        case 'changeJobOrder':
          serverCall = sessionManagerService.changeJobOrder2;
          serviceArg = sessionManagerService;
          break;
        case 'changeJobAssignment':
          serverCall = sessionManagerService.changeJobAssignment;
          serviceArg = sessionManagerService;
          if ($scope.storedSession) {
            formData.provider_id = $scope.storedSession.serv_provider_id;
          } else if (passedJobOrderDetails) {
            formData.provider_id = passedJobOrderDetails.serv_provider_id;
          }
          break;
      }

      serverCall.call(serviceArg, formData).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
            $modalInstance.close();
            $rootScope.$emit('changeJobOrderSuccess');
          } else {
            AlertService.errorAlert(response.message);
            $modalInstance.dismiss('cancel');
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.dismiss('cancel');
        }
      );
    };

    // listen for instance_count attrib clicks
    $scope.$on('addAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      const _count = field.instanceCountIndex || 1;
      const _field = angular.copy(
        $scope.originalFormData.filter(function (obj) {
          return obj.attrib_id == field.attrib_id;
        })[0]
      );

      _field.instanceCountIndex = _count + 1;
      _field.showRemoveInstance = true;
      $scope.attrib_list[index].doNotShowAddInstance = true;
      $scope.attrib_list[index].showRemoveInstance = false;
      $scope.attrib_list.splice(index + 1, 0, _field); // add after current field
    });

    $scope.$on('removeAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      $scope.attrib_list[index - 1].doNotShowAddInstance = false;
      $scope.attrib_list[index - 1].showRemoveInstance = true;
      $scope.attrib_list.splice(index, 1);
    });

    // Method used by pick list to request payload from controller
    $scope.preparePickListPayload = function () {
      const startTime = moment($scope.formData.startTime).format('HHmm');
      const startDate = moment($scope.formData.startDate).format(TimeFormatService.format('fullDateNoSeparator'));
      const startDateTime = startDate + ' ' + startTime;
      const builtDateTime = moment(startDateTime, TimeFormatService.format('fullDateTimeNoSeparator'));
      const tempDateAttrib = angular.copy($scope.dateAttrib);
      tempDateAttrib.value = builtDateTime;
      // format end_date
      const endDate = moment($scope.formData.endDate).format(TimeFormatService.format('fullDateNoSeparator'));
      const endDateTime = endDate + ' ' + startTime;
      const builtEndDateTime = moment(endDateTime, TimeFormatService.format('fullDateTimeNoSeparator'));
      const finalEndDate = moment
        .utc(timeZoneStore.calcTime(builtEndDateTime))
        .format(TimeFormatService.format('apiDate'));

      const result = {
        job_id: passed_job_id,
        end_date: finalEndDate,
        day_list: [],
        job_order_id: passedSession.job_order_id || passedSession.jobOrderId,
      };

      const tempAttribs = angular.copy($scope.attrib_list);
      tempAttribs.push(tempDateAttrib);
      tempAttribs.push($scope.durationAttrib);
      result.attrib_list = FormatHelperService.format_data(tempAttribs);

      angular.forEach($scope.dayListArray, function (day) {
        if (day.selected == true && day.id !== 7) {
          result.day_list.push(day.id);
        }
      });

      return result;
    };

    $scope.bundledChanged = function () {
      if ($scope.formData.bundled) {
        $scope.handleBundled();
      } else {
        $scope.jobType.selection = '';
        $scope.jobType.pickListRequested = false;
      }
    };

    $scope.handleBundled = function () {
      $scope.formData.worker_count = 1;
      $scope.scheduleWorkers = 0;
      $scope.jobType.selection = 'escalation';
      $scope.$watch('staffProviderAttrib.value', function (val, oldval) {
        if (
          val &&
          val.filter &&
          !$scope.bundledAssign &&
          (!$scope.qualifiedWorkers || !$scope.qualifiedWorkers.filter((x) => x.selected).length)
        ) {
          const newLength = val.filter((choice) => choice && choice.choice_id).length;
          $scope.handleScheduleLength(newLength);
        }
      });
      $scope.$watch('formData.worker_count', function (val, oldval) {
        if (val < $scope.scheduleWorkers) {
          $scope.formData.worker_count = oldval;
        } else {
          $scope.offerWorkers = Math.max(0, val - $scope.scheduleWorkers);
          $scope.calculateBundlePercents();
        }
      });
    };

    $scope.handleScheduleLength = function (newLength) {
      if (newLength > $scope.formData.worker_count) {
        $scope.formData.worker_count = newLength;
      }

      $scope.scheduleWorkers = newLength;
      $scope.offerWorkers = Math.max(0, $scope.formData.worker_count - $scope.scheduleWorkers);
      $scope.calculateBundlePercents();
    };

    $scope.calculateBundlePercents = function () {
      $scope.schedulePercent = ($scope.scheduleWorkers / $scope.formData.worker_count) * 100 + '%';
      $scope.offerPercent = ($scope.offerWorkers / $scope.formData.worker_count) * 100 + '%';
    };

    $scope.attribsLoadedCallback = function (formData) {
      switch (passedOperation) {
        case 'changeJobOrder':
          $scope.title = 'Change Job Order';
          break;
        case 'changeJobAssignment':
          $scope.effectiveDateVisible = true;
          $scope.title = 'Change Job Assignment';
          break;
        default:
          $scope.title = 'Clone Job';
          break;
      }

      if (passedSession) {
        sessionManagerService.setSession(passedSession.session_id).then(async function (session) {
          $scope.unchangedFormData = FormatHelperService.format_data_back(angular.copy(session.attrib_list));
          $scope.storedSession = session;
          $scope.setSelectedJobAttribs(session.job_id);
          $scope.scheduleJobFormSubmission.job_id = session.job_id;
          $scope.pause_job = session.pause_job;

          $scope.attrib_list.forEach(function (origAttrib) {
            const loadedAttrib = session.attrib_list.find(function (attrib) {
              return attrib.name === origAttrib.name;
            });

            if (loadedAttrib) {
              populateAttribs(loadedAttrib, origAttrib);
            }
          });
          $scope.durationAttrib.value = session.attrib_list.find((x) => x.name === 'duration').value_count;
          $scope.originalAttribs = _.cloneDeep($scope.attrib_list);

          const startDate = utcToLocal(session.job_start_date, session.job_start_time);
          const endDate = utcToLocal(session.job_end_date, session.job_start_time);

          formData.startDate = moment(startDate);
          formData.endDate = moment(endDate);
          angular.forEach($scope.dayListArray, function (day) {
            day.selected = session.job_days.indexOf(day.id) > -1;
          });

          $scope.originalDayList = angular.copy(session.job_days);
          $scope.originalEndDate = moment(formData.endDate).format(TimeFormatService.format('apiDate'));
          if ($scope.selectedLocation) {
            const adjustedTime = await TimeFormatService.formatTimezone(
              'fullDateTimeSecs',
              passedSession.start,
              passedSession.start,
              $scope.selectedLocation
            );

            formData.startTime = new Date().setHours(
              moment(adjustedTime, 'h:mm A').format('HH'),
              moment(adjustedTime, 'h:mm A').format('mm'),
              0
            );

            setTimeout(() => {
              $rootScope.$broadcast('locationChanged', $scope.selectedLocation);
            });
          } else {
            formData.startTime = moment.utc(`${session.job_start_date}T${session.request_time}`).local();
          }

          if ($scope.effectiveDateVisible) {
            const effectiveDate = utcToLocal(session.request_date, session.request_time);

            $scope.effectiveDateAttrib.value = moment(effectiveDate).format(TimeFormatService.format('middleDate'));
            $scope.effectiveDateAttrib.pickerModel = moment(effectiveDate);
          }
        });
      }
    };
    if ($scope.hasBundlePermission) {
      $scope.formData.bundled = true;
      $scope.handleBundled();
    }

    $scope.$on('Picklist.Clear', () => {
      $scope.jobType.pickListRequested = false;
    });

    const utcToLocal = (date, time) => moment.utc(`${date} ${time}`).local().format();
  },
]);
