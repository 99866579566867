import module from './module';
import * as enums from '../enums';

export default module.directive('dirJobViewHeader', function () {
  return {
    restrict: 'E',
    scope: {
      request: '=',
      provider: '=',
    },
    controller: [
      '$window',
      '$scope',
      'AuthenticationService',
      '$modal',
      'sessionManagerService',
      '$localStorage',
      'EnterpriseFilterService',
      'DOMAIN',
      'removeSessionService',
      'PERMISSIONS',
      function (
        $window,
        $scope,
        AuthenticationService,
        $modal,
        sessionManagerService,
        $localStorage,
        EnterpriseFilterService,
        DOMAIN,
        removeSessionService,
        PERMISSIONS
      ) {
        var CURRENT_USER = AuthenticationService.getCurrentUser();
        $scope.entFilter = EnterpriseFilterService.fetchSelectedEnterprise();
        $scope.keyProfession = $localStorage.generalData.filter(function (obj) {
          return obj.data_name == 'key_profession';
        })[0];
        $scope.domain = DOMAIN;
        //////////////////////////////////////////////////////////////////////////
        //Edit btn drop down actions
        $scope.status = {
          isopen: false,
        };
        $scope.avatarIcon = `${enums.AssetsFolder.images}avatar.png`;

        var changeStates = ['pending', 'open', 'committed'],
          cancelStates = ['pending', 'open', 'committed'],
          escalationStates = ['open', 'committed'],
          checkinStates = ['committed'],
          checkoutStates = ['active'],
          reviewStates = ['completed'],
          changeVerifyStates = ['completed', 'canceled', 'cancelled'],
          assignStates = ['pending', 'open', 'committed'],
          approvedStates = ['open'],
          canceledStates = ['canceled'],
          scheduleStates = ['pending', 'open', 'committed', 'active', 'canceled', 'completed'];

        $scope.viewEditActions = function (action) {
          var state;
          var isScheduled;
          var passedSession = $scope.request;
          if ($scope.request) {
            state = $scope.request.status.name.toLowerCase();
            isScheduled = $scope.request.type === 1;
          }

          function staffCheck() {
            if ($scope.provider && $scope.provider.enterprise_info != null) {
              return true;
            } else if (!$scope.provider.user_type) {
              //means no provider present
              return true;
            }
          }

          switch (action) {
            case 'escalate':
              if (
                staffCheck() &&
                CURRENT_USER.permissionCheck(PERMISSIONS.ESCALATE) &&
                escalationStates.indexOf(state) != -1
              ) {
                return true;
              }
              break;
            case 'change':
              return (
                CURRENT_USER.permissionCheck(PERMISSIONS.CHANGE) &&
                ((passedSession.bundled && changeStates.indexOf(state) !== -1 && state !== 'pending') ||
                  (!passedSession.bundled && changeStates.indexOf(state) != -1 && passedSession.request_type == 1))
              );
              break;
            case 'cancel':
              if (CURRENT_USER.permissionCheck(PERMISSIONS.CANCEL) && cancelStates.indexOf(state) != -1) {
                return true;
              }
              break;
            case 'checkin':
              if (CURRENT_USER.permissionCheck(PERMISSIONS.CHECKIN) && checkinStates.indexOf(state) != -1) {
                return true;
              }
              break;
            case 'viewProvider':
              if ($scope.request.serv_user_id) {
                return true;
              }
              break;
            case 'transferSplit':
              if (
                staffCheck() &&
                CURRENT_USER.permissionCheck(PERMISSIONS.CHECKOUT) &&
                checkoutStates.indexOf(state) != -1
              ) {
                return true;
              }
              break;
            case 'checkout':
              if (CURRENT_USER.permissionCheck(PERMISSIONS.CHECKOUT) && checkoutStates.indexOf(state) != -1) {
                return true;
              }
              break;
            case 'changeVerify':
              if (CURRENT_USER.permissionCheck(PERMISSIONS.CHANGE_VERIFY) && changeVerifyStates.indexOf(state) != -1) {
                return true;
              }
              break;
            case 'approve':
              if (
                staffCheck() &&
                CURRENT_USER.permissionCheck(PERMISSIONS.APPROVE) &&
                approvedStates.indexOf(state) !== -1
              ) {
                return true;
              }
              break;
            case 'assign':
              if (
                staffCheck() &&
                isScheduled &&
                CURRENT_USER.permissionCheck(PERMISSIONS.ASSIGN) &&
                assignStates.indexOf(state) !== -1
              ) {
                return true;
              }
              break;
            case 'resend':
              if (CURRENT_USER.permissionCheck(PERMISSIONS.REQUEUE) && canceledStates.indexOf(state) !== -1) {
                return true;
              }
              break;
            case 'remove':
              if (CURRENT_USER.permissionCheck(PERMISSIONS.REMOVE) && canceledStates.indexOf(state) !== -1) {
                return true;
              }
              break;
            case 'cancelRemove':
              if (CURRENT_USER.permissionCheck(PERMISSIONS.CANCEL_REMOVE) && cancelStates.indexOf(state) !== -1) {
                return true;
              }
              break;
            case 'showInterested':
              return (
                state === 'interest' ||
                (state === 'waiting decision' && CURRENT_USER.permissionCheck(PERMISSIONS.APPLY_SHOW_DIALOG))
              );
            case 'assignInterested':
              return (
                state === 'interest' ||
                (state === 'waiting decision' && CURRENT_USER.permissionCheck(PERMISSIONS.APPLY_ASSIGN_DIALOG))
              );
            case 'offerInterested':
              return (
                state === 'interest' ||
                (state === 'waiting decision' && CURRENT_USER.permissionCheck(PERMISSIONS.APPLY_OFFER_DIALOG))
              );
            case 'updateOrder':
              return (
                CURRENT_USER.permissionCheck(PERMISSIONS.SCHEDULE_JOB_ORDER) &&
                (passedSession.jobOrderId || passedSession.job_order_id) &&
                passedSession.status.name === 'Pending'
              );
            case 'scheduleJob':
              if (
                passedSession.isJob &&
                CURRENT_USER.permissionCheck(PERMISSIONS.SCHEDULE_JOB) &&
                scheduleStates.indexOf(state) !== -1
              ) {
                return true;
              }
              break;
          }
        };

        $scope.assignText = function (session) {
          if ((session.bundled && isNaN(session.user_id)) || session.status.name === 'Open') {
            return 'Schedule';
          } else if (!isNaN(session.user_id)) {
            return 'Cancel and Schedule';
          } else {
            return 'Cancel and Schedule';
          }
        };

        $scope.escalateText = function (session) {
          return session.status.name === 'Open' ? 'Offer' : 'Cancel and Offer';
        };

        //Schedule actions dropdown
        $scope.editSessionActions = function (action) {
          switch (action) {
            case 'cancel':
              removeSessionService.doCancel($scope.request.request_id, $scope.request);
              break;
            case 'remove':
              removeSessionService.doRemove($scope.request.request_id, $scope.request);
              break;
            case 'cancelRemove':
              removeSessionService.doCancelAndRemove($scope.request.request_id, $scope.request);
              break;
          }
        };

        $scope.openEditModal = function (action) {
          var _controller,
            _templateUrl = 'app/views/schedule/actionsModal.html';
          if (action === 'assign') {
            _controller = 'AssignmentModalController';
          } else if (action === 'escalate') {
            _controller = 'EscalationModalController';
          } else if (action === 'change') {
            _controller = 'ChangeTimeModalController';
          } else if (action === 'checkout') {
            _controller = 'CheckOutModalController';
          } else if (action === 'checkin') {
            _controller = 'CheckInModalController';
          } else if (action === 'showInterested') {
            _controller = 'ShowInterestedProvidersModalController';
            _templateUrl = 'app/views/session/showInterestedModal.html';
          } else if (action === 'offerInterested') {
            _controller = 'OfferInterestedProvidersModalController';
            _templateUrl = 'app/views/session/interestActionModal.html';
          } else if (action === 'assignInterested') {
            _controller = 'AssignInterestedProvidersModalController';
            _templateUrl = 'app/views/session/interestActionModal.html';
          } else if (action === 'updateOrder') {
            _controller = 'EditJobOrderModalController';
            _templateUrl = 'app/views/schedule/editJobOrderModal.html';
          }

          var modalInstance = $modal.open({
            animation: true,
            templateUrl: _templateUrl,
            controller: _controller,
            size: 'lg',
            backdrop: 'static',
            resolve: {
              passedJob: function () {
                return null;
              },
              passedSession: function () {
                $scope.request.session_id = $scope.request.request_id;
                return $scope.request;
              },
              passedEnt: function () {
                return $scope.entFilter;
              },
              passed_job_order_id: function () {
                return null;
              },
              passedJobOrderDetails: function () {
                return null;
              },
              passed_current_worker_count: function () {
                return null;
              },
              passed_total_worker_count: function () {
                return null;
              },
            },
          });
          modalInstance.result.then(function () {});
        };

        $scope.openChangeTimeModal = function () {
          trackingService.trackEvent(trackingService.CALENDAR, 'Session menu', 'change time');
          sessionManagerService.setSession($scope.request.session_id).then(function (serverSession) {
            serverSession.start = $scope.request.start;
            serverSession.end = $scope.request.end;
            $modal.open({
              animation: true,
              templateUrl: 'app/views/schedule/actionsModal.html',
              controller: 'ChangeTimeModalController',
              size: 'lg',
              backdrop: 'static',
              resolve: {
                passedSession: function () {
                  return serverSession;
                },
              },
            });
          });
        };

        //Modal trigger for transfer Split
        $scope.transferSplitModal = function () {
          var modalInstance = $modal.open({
            animation: true,
            templateUrl: 'app/views/schedule/transferSplitModal.html',
            controller: 'TransferSplitModalController',
            size: 'lg',
            backdrop: 'static',
            resolve: {
              passedSession: function () {
                return $scope.request;
              },
              passedEnt: function () {
                return $scope.entFilter;
              },
              passedJobOrderDetails: function () {
                return null;
              },
              passed_current_worker_count: function () {
                return null;
              },
              passed_total_worker_count: function () {
                return null;
              },
            },
          });
          modalInstance.result.then(function () {});
        };

        $scope.approveSession = function () {
          sessionManagerService.setSession($scope.request.request_id).then(function (session) {
            $modal.open({
              animation: true,
              templateUrl: 'app/views/session/requestSessionModal.html',
              controller: 'WaitingApprovalModalController',
              size: 'lg',
              backdrop: 'static',
              resolve: {
                enterprise_selection: function () {
                  return $scope.entFilter;
                },
              },
            });
          });
        };

        $scope.resendSession = function () {
          sessionManagerService.setSession($scope.request.request_id).then(function (session) {
            $modal.open({
              animation: true,
              templateUrl: 'app/views/session/requestSessionModal.html',
              controller: 'ResendModalController',
              size: 'lg',
              backdrop: 'static',
              resolve: {
                enterprise_selection: function () {
                  return $scope.entFilter;
                },
              },
            });
          });
        };

        // Change Verify  Modal
        $scope.openVerifyModal = function (session) {
          var modalInstance = $modal.open({
            animation: $scope.animationsEnabled,
            templateUrl: 'app/views/reporting/verify/changeVerifyModal.html',
            controller: 'ChangeVerifyModalController',
            size: 'lg',
            backdrop: 'static',
            resolve: {
              selectedSession: function () {
                return session;
              },
            },
          });
          modalInstance.result.then(function (request_id) {});
        };
        //////////////////////////////////////////////////////////////////////////
        //Open Provider Modal
        $scope.showProviderModal = function (provider) {
          var modalInstance = $modal.open({
            animation: true,
            templateUrl: 'app/views/job/providerModal.html',
            controller: 'ProviderModalController',
            size: 'lg',
            backdrop: 'static',
            resolve: {
              provider: function () {
                return provider;
              },
            },
          });
          modalInstance.result.then(function () {});
        };
        //////////////////////////////////////////////////////////////////////////
        // Return display value functions
        $scope.returnHeaderMsg = function () {
          var message;
          switch ($scope.request.status.name) {
            case 'Active':
              message = 'Currently in Progress';
              break;
            case 'Open':
              message = 'Is open and awaiting approval';
              break;
            case 'Pending':
              message = `Awaiting ${$scope.keyProfession.data_content}`;
              break;
            case 'Committed':
              message = 'Has been accepted!';
              break;
            case 'Denied':
              message = 'Has been Denied';
              break;
            case 'Canceled':
              message = 'Has been Canceled';
              break;
            case 'Interest':
              message = `Is collecting interested ${$scope.keyProfession.data_content}s`;
              break;
            case 'Waiting Decision':
              message = 'Is waiting for decision';
              break;
            case 'Completed':
              message = 'Complete';
              break;
          }
          return message;
        };

        $scope.returnWellClass = function () {
          var type;
          switch ($scope.request.status.name) {
            case 'Active':
              type = 'jobInProgress';
              break;
            case 'Open':
              type = 'jobOpen';
              break;
            case 'Pending':
              type = 'jobPending';
              break;
            case 'Committed':
              type = 'jobCommitted';
              break;
            case 'Denied':
              type = 'jobCanceled';
              break;
            case 'Canceled':
              type = 'jobCanceled';
              break;
            case 'Completed':
              type = 'jobCompleted';
              break;
            case 'Interest':
              type = 'jobInterest';
              break;
            case 'Waiting Decision':
              type = 'jobWaiting';
              break;
          }
          return type;
        };

        $scope.goBack = function () {
          $window.history.back();
        };
        //////////////////////////////////////////////////////////////////////////
      },
    ],
    templateUrl: 'app/views/templates/job_view_header.html',
  };
});
