import module from '../module';
export default module.controller('SetupController', [
  '$scope',
  '$modalInstance',
  '$location',
  'adminUserService',
  'AlertService',
  'passedData',
  function ($scope, $modalInstance, $location, adminUserService, AlertService, passedData) {
    $scope.formData = {
      email: passedData.email,
      token: passedData.token,
    };

    $scope.ok = function () {
      adminUserService
        .signup($scope.formData)
        .then(function (response) {
          if (response) {
            AlertService.successAlert(response.message);
            location.href = '/#/login';
            $location.search({});
            $modalInstance.close();
          } else {
            $modalInstance.close();
            AlertService.errorAlert(response.message);
          }
        })
        .catch(function (message) {
          AlertService.errorAlert(message);
        });
    };

    $scope.close = function () {
      $modalInstance.close();
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    const unwatchConfirmPassword = $scope.$watch('formData.confirmPassword', function () {
      $scope.userForm.confirmPassword.$setValidity(
        'match',
        $scope.formData.confirmPassword === $scope.formData.password
      );
    });

    const unwatchPassword = $scope.$watch('formData.password', function () {
      $scope.userForm.confirmPassword.$setValidity(
        'match',
        $scope.formData.confirmPassword === $scope.formData.password
      );
    });

    $scope.$on('$destroy', function () {
      unwatchConfirmPassword();
      unwatchPassword();
    });
  },
]);
