import angular from 'angular';
import module from '../module';
export default module.controller('EditPoolModalController', [
  '$scope',
  '$modalInstance',
  '$modal',
  'providerManagerService',
  'AlertService',
  'enterprise_selection',
  '$localStorage',
  'AuthenticationService',
  'passedPool',
  function (
    $scope,
    $modalInstance,
    $modal,
    providerManagerService,
    AlertService,
    enterprise_selection,
    $localStorage,
    AuthenticationService,
    passedPool
  ) {
    $scope.keyProfession = $localStorage.generalData.filter(function (obj) {
      return obj.data_name === 'key_profession';
    })[0];
    const CURRENT_USER = AuthenticationService.getCurrentUser();

    $scope.submitBtnDisbaled = false;
    $scope.showSpinner = true;
    let passedEnt = enterprise_selection;
    if (!passedEnt) {
      passedEnt = {
        enterprise_id: CURRENT_USER.enterprise_info.id,
      };
    }
    $scope.enterpriseId = passedEnt.enterprise_id;

    $scope.pool = angular.copy(passedPool); // pool
    $scope.provider_list = $scope.pool.provider_list;
    $scope.original_provider_list = angular.copy($scope.pool.provider_list);

    $scope.formData = {
      pool_id: $scope.pool.pool_id,
      name: $scope.pool.name,
      ent_only: false,
      provider_list: [],
    };

    // Modal controls
    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      if ($scope.providers) {
        $scope.formData.provider_list = [];
        const selected = $scope.providers.filter(function (obj) {
          return obj.poolSelected == true;
        });

        if (!selected.length && !($scope.selectedProviders && $scope.selectedProviders.length)) {
          $scope.submitBtnDisbaled = false;
          AlertService.warningAlert(
            'Sorry, you cannot have 0 workers in a pool. Please Delete the pool or leave at least one worker in it.'
          );
          return;
        }

        for (let i = 0; i < selected.length; i++) {
          const obj = { provider_id: selected[i].provider_id };
          $scope.formData.provider_list.push(obj);
        }
      }
      formatProviderList($scope.original_provider_list, $scope.selectedProviders);
    };

    function formatProviderList(oldList, newList) {
      const oldListIds = oldList.map(function (obj) {
        return obj.provider_id;
      });
      const newListIds = newList.map(function (obj) {
        return obj.provider_id;
      });
      const removeIdsFromSubmission = [];
      const finalProviderList = [];

      for (let i = 0; i < newListIds.length; i++) {
        if (oldListIds.indexOf(newListIds[i]) > -1) {
          // create array of selected provider_ids already assigned to pool
          removeIdsFromSubmission.push(newListIds[i]);
        } else {
          // add id and flag to finale list
          const obj = {
            provider_id: newListIds[i],
            action_flag: 1,
          };
          finalProviderList.push(obj);
        }
      }
      // find the ids that are no longer checked from the original list
      for (let i = 0; i < oldListIds.length; i++) {
        if (removeIdsFromSubmission.indexOf(oldListIds[i]) < 0) {
          const obj = {
            provider_id: oldListIds[i],
            action_flag: 0,
          };
          finalProviderList.push(obj);
        }
      }
      updatePool(finalProviderList);
    } // end of formatProviderList method

    function updatePool(update_provider_list) {
      $scope.formData.provider_list = update_provider_list;
      $scope.pool.update($scope.formData).then(function (response) {
        if (response.success) {
          AlertService.successAlert(response.message);
        } else {
          AlertService.errorAlert(response.message);
        }
        $modalInstance.close();
      });
    }

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.loadProvider = function (userId) {
      providerManagerService.getProvider(userId).then(function (provider) {
        showProviderModal(provider);
      });
    };

    function showProviderModal(provider) {
      const modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/job/providerModal.html',
        controller: 'ProviderModalController',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          provider: function () {
            provider.hidePermissions = true;
            return provider;
          },
        },
      });
      modalInstance.result.then(function () {});
    }

    $scope.type = 'Edit Pool'; // Modal Title
    $scope.btnLabel = 'Update Pool';
  },
]);
