import module from './module';
import './userModelService';
import './authenticationService';
import './trackingService';
import './websocketService';
import './enterpriseFilterService';
import './enterpriseUtilityService';
import './sessionManagerService';
import './sessionModelService';
import './formatHelperService';
import './locationTabService';
import './providerModelService';
import './locationManagerService';
import './locationModelService';
import './alertService';
import './generalDataService';
import './calendarJobManagerService';
import './calendarJobModelService';
import './calendarProviderManagerService';
import './calendarProviderModelService';
import './providerManagerService';
import './removeSessionService';
import './sessionsInJobService';
import './timeService';
import './escalationManagerService';
import './escalationModelService';
import './escalationTabService';
import './googleLocationService';
import './JobManagerService';
import './jobModelService';
import './pleaseReviewService';
import './poolManagerService';
import './poolModelService';
import './priceManagerService';
import './priceModelService';
import './reportingSectionService';
import './requesterManagerService';
import './requesterModelService';
import './requesterTabService';
import './roleManagerService';
import './roleModelService';
import './splitPriceService';
import './timeConversionService';
import './timeRuleService';
import './overlapService';
import './time-reports/timeReportCalculationService';
import './time-reports/timeReportCsvService';
import './time-reports/timeReportDataService';
import './time-reports/timeReportNetworkService';
import './time-reports/timeReportUIService';
import './scheduleTotalWorkersService';

import './admin-api/adminUserService';
import './admin-api/apiLogsService';

import './interestService';
import './branchFilterService';

import './timeZoneStore';

import './providerExportService';

export default module;
