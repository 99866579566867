import moment from 'moment';
import angular from 'angular';
import module from '../module';
import * as enums from '../../enums';

export default module.controller('SessionDetailsModalController', [
  '$scope',
  '$modal',
  '$modalInstance',
  '$location',
  'AlertService',
  'passedJob',
  'passedSession',
  'sessionManagerService',
  'EnterpriseFilterService',
  'trackingService',
  'generalDataService',
  'TimeFormatService',
  'providerManagerService',
  function (
    $scope,
    $modal,
    $modalInstance,
    $location,
    AlertService,
    passedJob,
    passedSession,
    sessionManagerService,
    EnterpriseFilterService,
    trackingService,
    generalDataService,
    TimeFormatService,
    providerManagerService
  ) {
    $scope.keySession = generalDataService.getKeySession();
    $scope.title = $scope.keySession + ' Details';
    $scope.cancelReason = passedSession.cancel_code ? enums.CancelStatuses[passedSession.cancel_code] : null;
    $scope.showSpinner = false;

    const entFilter = EnterpriseFilterService.fetchSelectedEnterprise();

    if (entFilter.enterprise_id) {
      sessionManagerService.setSession(passedSession.session_id).then(
        function (session) {
          $scope.request = session;
          if (session.cancelled_date && session.cancelled_time) {
            $scope.cancelDateTime = moment
              .utc(`${session.cancelled_date} ${session.cancelled_time}`)
              .local()
              .format(TimeFormatService.format('timeDateLong'));
          }
          if ($scope.request.serv_user_id) {
            providerManagerService.getProvider($scope.request.serv_user_id).then(function (provider) {
              $scope.provider = angular.copy(provider);
            });
          }
          $scope.session = session;
          $scope.session.enterprise = passedSession.enterprise;

          sessionManagerService.getRealmAttribs(enums.Realms.RequesterReview).then(
            function (response) {
              if (response.success) {
                $scope.reviewAttribs = response.attrib_list;
              } else {
                AlertService.errorAlert(response.message);
              }
            },
            function (reason) {
              AlertService.serverRequestErrorAlert(reason);
            }
          );
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.dismiss('cancel');
        }
      );
    } else {
      $modalInstance.dismiss('cancel');
    }

    $scope.showJobReceiptModal = function (review) {
      $scope.requestCompletion = {
        review: review,
        requestDetails: $scope.request,
      };
      // check if late is selected then minutes is required to continue
      if (review.late == true && (review.minutesLate == 0 || review.minutesLate == null)) {
        AlertService.warningAlert('Please enter how many minutes late');
      } else {
        const modalInstance = $modal.open({
          animation: true,
          templateUrl: 'app/views/job/jobReceiptModal.html',
          controller: 'JobReceiptModalController',
          size: 'lg',
          backdrop: 'static',
          resolve: {
            completionDetails: function () {
              return $scope.requestCompletion;
            },
          },
        });
        modalInstance.result.then(function () {
          $scope.goBack();
        });
      }
    };

    // Image Viewer Modal
    $scope.showImageModal = function (imageUrl, base64Image) {
      const modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/job/imageViewerModal.html',
        controller: 'imageViewerModalController',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          passedImage: function () {
            return imageUrl;
          },
          base64Image: function () {
            return base64Image;
          }
        },
      });
      modalInstance.result.then(function () { });
    };

    $scope.ok = function () {
      $modalInstance.close();
      trackingService.trackEvent(trackingService.MODALS, $scope.title, '');
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'cancel');
    };

    $scope.buildSubmitDate = function () {
      if ($scope.session) {
        return moment
          .utc($scope.session.submit_date + ' ' + $scope.session.submit_time)
          .local()
          .format(TimeFormatService.format('timeDateLong'));
      }
    };

    $scope.goToEnterprise = async function () {
      const enterprises = await EnterpriseFilterService.fetchEnterpriseList();
      const targetEnterprise = enterprises.find((x) => x.name === passedSession.enterprise);
      if (targetEnterprise) {
        EnterpriseFilterService.setSelectedEnterprise(targetEnterprise);
      }
      $modalInstance.close();
      location.href = '/#/schedule';
    };

    // Load Supervisor Details
    $scope.loadSupervisorDetails = function () {
      $scope.showSpinner = true;
      let supervisorSigImage = '';
      sessionManagerService
        .getSupervisorSignOffDetails({ svc_req_id: $scope.session.request_id })
        .then(function (response) {
          $scope.showSpinner = false;
          if (response?.success) {
            supervisorSigImage = response.image_attribute?.data ? response.image_attribute.data : '';
            $scope.showImageModal(null, supervisorSigImage);
          }
        });
    };

    $scope.goBack = function () {
      $modalInstance.close();
      location.path('/#/schedule');
    };
  },
]);
