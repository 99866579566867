import module from '../module';
import * as enums from '../../enums';
import moment from 'moment';

export default module.controller('ShowInterestedProvidersModalController', [
  'sessionManagerService',
  '$scope',
  '$modal',
  '$modalInstance',
  'AlertService',
  'generalDataService',
  'interestService',
  'passedSession',
  'Provider',
  'DOMAIN',
  'providerManagerService',
  'passed_job_order_id',
  'TimeFormatService',
  function (
    sessionManagerService,
    $scope,
    $modal,
    $modalInstance,
    AlertService,
    generalDataService,
    interestService,
    passedSession,
    Provider,
    DOMAIN,
    providerManagerService,
    passed_job_order_id,
    TimeFormatService
  ) {
    $scope.domain = DOMAIN;
    var profession = generalDataService.getKeyProfession();
    $scope.keyProfession = (profession && profession.data_content) || 'Worker';
    $scope.keyAttribute = generalDataService.getKeyAttribute();
    $scope.exportExcelIcon = `${enums.AssetsFolder.images}export-excel.ico`;

    if (passed_job_order_id || passedSession.jobOrderId) {
      interestService.showForJobOrder(passed_job_order_id || passedSession.jobOrderId).then(function (result) {
        if (result.success) {
          $scope.providers = result.provider_list.map((prov) => {
            prov.login_status_code = prov.login_status;
            prov.vetting_status_code = prov.status;
            prov.interest_date = moment
              .utc(prov.notify_date_time)
              .local()
              .format(TimeFormatService.format('interestDate'));
            prov.interest_time = moment.utc(prov.notify_date_time).local().format('hh:mma');
            return prov;
          });
        } else {
          AlertService.errorAlert(result.message);
          $modalInstance.dismiss('cancel');
        }
      });
    } else {
      interestService.showForRequest(passedSession.session_id).then(function (result) {
        if (result.success) {
          $scope.providers = result.provider_list.map((prov) => {
            prov.login_status_code = prov.login_status;
            prov.vetting_status_code = prov.status;
            prov.interest_date = moment
              .utc(prov.notify_date_time)
              .local()
              .format(TimeFormatService.format('interestDate'));
            prov.interest_time = moment.utc(prov.notify_date_time).local().format('hh:mma');
            return prov;
          });
        } else {
          AlertService.errorAlert(result.message);
          $modalInstance.dismiss('cancel');
        }
      });
    }

    $scope.getProfileUrl = function (picture) {
      if (picture.data) {
        return DOMAIN + picture.data;
      }
      return `${enums.AssetsFolder.images}avatar.png`;
    };

    $scope.showProvider = function (provider) {
      providerManagerService.getProvider(provider.user_id).then(function (provider) {
        var modalInstance = $modal.open({
          animation: true,
          templateUrl: 'app/views/job/providerModal.html',
          controller: 'ProviderModalController',
          size: 'lg',
          backdrop: 'static',
          resolve: {
            provider: function () {
              provider.hidePermissions = true;
              return provider;
            },
          },
        });
        modalInstance.result.then(function () {});
      });
    };

    $scope.loggedInStatus = function (status_code) {
      if (status_code == 1) {
        return 'logged-in-state';
      } else if (status_code == 0) {
        return 'logged-out-state';
      } else {
        return 'no-logged-in-state';
      }
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.exportToCsv = function () {
      var result = [];
      result.push(`data:text/csv;charset=utf8,Interested ${$scope.keyProfession}s,`);
      result.push('Name,Interest Date,Interest Time,Staff');
      var csvBody = $scope.providers.map(
        (provider) => `${provider.name},${provider.interest_date},${provider.interest_time},${provider.staff}`
      );
      result = result.concat(csvBody);
      let csv = result.join('\n');
      $scope.generateDownload(csv);
    };

    $scope.generateDownload = function (dataUrl) {
      var hiddenElement = document.createElement('a');
      hiddenElement.href = encodeURI(dataUrl);
      hiddenElement.download = `interested_${$scope.keyProfession}.csv`;
      hiddenElement.click();
    };
  },
]);
