import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';
import { Component } from '@angular/core';

@Component({
  selector: 'servAnalyticsPage',
  templateUrl: 'analytics.page.component.html',
  styleUrls: ['analytics.page.component.scss']
})
export class AnalyticsPageComponent {
  constructor() {
    console.log('%cNEW ANALYTICS PAGE', 'background-color: red; color: white; padding: 10px;');
  }
}

angular
  .module('ServetureWebApp')
  .directive('servAnalyticsPage', downgradeComponent({ component: AnalyticsPageComponent }));
