import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

import {
  sessionServiceProvider,
  providerServiceProvider,
  enterpriseFilterServiceProvider,
  timezoneStoreProvider,
  googleLocationServiceProvider,
  sessionManagerServiceProvider,
} from '../upgraded.providers';
import { AdhocScheduleComponent } from './components/ad-hoc-schedule/ad-hoc-schedule.component';
import { BirdsEyeMonthlyComponent } from './components/birds-eye-monthly/birds-eye-monthly.component';
import { BirdsEyeWeeklyComponent } from './components/birds-eye-weekly/birds-eye-weekly.component';
import { BirdsEyeWorkersComponent } from './components/birds-eye-workers/birds-eye-workers.component';
import { SharedModule } from '../shared/shared.module';
import { BirdsEyePage } from './pages/birds-eye/birds-eye.page';
import { BirdsEyeService } from './services/birds-eye.service';
import { WorkerProfilePicturePipe } from './pipes/worker-picture-pipe';
import { MassCancelComponent } from './components/mass-cancel/mass-cancel.component';
import { JobOrderReportPageComponent } from './pages/job-order-report/job-order-report.page';
import { JobOrderReportUpgradeDirective } from './components/job-order-report-upgrade/job-order-report-upgrade.directive';

const COMPONENTS = [
  AdhocScheduleComponent,
  BirdsEyePage,
  BirdsEyeWeeklyComponent,
  BirdsEyeWorkersComponent,
  BirdsEyeMonthlyComponent,
  MassCancelComponent,
  JobOrderReportPageComponent,
  JobOrderReportUpgradeDirective,
];

const PIPES = [WorkerProfilePicturePipe];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    SharedModule,
  ],
  declarations: [...COMPONENTS, ...PIPES],
  exports: [...COMPONENTS, ...PIPES],
  entryComponents: [AdhocScheduleComponent, BirdsEyePage, MassCancelComponent],
  providers: [
    sessionServiceProvider,
    providerServiceProvider,
    enterpriseFilterServiceProvider,
    BirdsEyeService,
    timezoneStoreProvider,
    googleLocationServiceProvider,
    sessionManagerServiceProvider,
  ],
})
export class JobsModule {
  constructor() {}
}
