import module from '../module';
import * as enums from '../../enums';

export default module.controller('BulkInviteWarningController', [
  '$scope',
  '$modalInstance',
  'AlertService',
  'adminUserService',
  'passedData',
  'enterprise_selection',
  'branch_selection',
  'WEB_URL',
  'AuthenticationService',
  function (
    $scope,
    $modalInstance,
    AlertService,
    adminUserService,
    passedData,
    enterprise_selection,
    branch_selection,
    WEB_URL,
    AuthenticationService
  ) {
    $scope.initialize = async function () {
      $scope.icons = {
        warning: `${enums.AssetsFolder.svg}invitations/warning.svg`,
      };
    };

    $scope.getEnterpriseData = function () {
      $scope.CURRENT_USER = AuthenticationService.getCurrentUser();
      var passedEnt = enterprise_selection || {
        enterprise_id: $scope.CURRENT_USER.enterprise_info && $scope.CURRENT_USER.enterprise_info.id,
      };

      var enterprise;

      if (!passedEnt.enterprise_id || passedEnt.enterprise_id === -1) {
        enterprise = null;
      } else {
        enterprise = passedEnt.enterprise_id;
      }

      return {
        enterprise,
        marketplace: $scope.CURRENT_USER.user_type == 2 ? $scope.CURRENT_USER.market_place_info.id : null,
        branch: branch_selection && branch_selection.id,
      };
    };

    $scope.ok = async function () {
      const enterpriseData = $scope.getEnterpriseData();
      if (enterpriseData.branch == -1) {
        delete enterpriseData.branch;
      }
      if (enterpriseData.enterprise == -1) {
        delete enterpriseData.enterprise;
      }

      let branch_id;
      if (passedData.branch?.length > 1) {
        branch_id = passedData.branch.map((x) => x.id);
      } else if (passedData.branch?.length === 1) {
        branch_id = passedData.branch[0].id;
      } else {
        branch_id = enterpriseData.branch;
      }

      const payload = {
        user_ids: passedData.selection,
        role_id: passedData.role,
        web_url: passedData.webUrl,
        enterprise_id: enterpriseData.enterprise ? enterpriseData.enterprise : undefined,
        branch_id,
      };

      if (payload.branch_id === 0 || payload.enterprise_id) {
        delete payload.branch_id;
      }

      adminUserService.referAdmin(payload).then(
        function () {
          AlertService.successAlert('New User invited.');
          $modalInstance.close();
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.initialize();
  },
]);
