import module from '../module';
import Swal from 'sweetalert2';

export default module.controller('PriceTabController', [
  '$scope',
  '$rootScope',
  '$modal',
  'priceManagerService',
  'AlertService',
  'AuthenticationService',
  'trackingService',
  'EnterpriseFilterService',
  function (
    $scope,
    $rootScope,
    $modal,
    priceManagerService,
    AlertService,
    AuthenticationService,
    trackingService,
    EnterpriseFilterService
  ) {
    $scope.entFilter = EnterpriseFilterService.fetchSelectedEnterprise();
    let unbindHandler = null;
    $scope.reverse = true;
    $scope.showSpinner = true;
    const CURRENT_USER = AuthenticationService.getCurrentUser();

    // Permission Check
    $scope.addPricePermission = CURRENT_USER.permissionCheck(18);
    $scope.editPricePermission = CURRENT_USER.permissionCheck(19);
    $scope.deletePermission = CURRENT_USER.permissionCheck(20);

    const getPrices = function (priceParams) {
      priceManagerService.getAllPrices(priceParams).then(
        function (pricesObj) {
          $scope.prices = pricesObj[$scope.entFilter.enterprise_id];
          $scope.showSpinner = false;
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
        }
      );
    };

    const userTypeCheck = function () {
      if (CURRENT_USER.user_type == 2) {
        $scope.$watch('entFilter', function () {
          if ($scope.entFilter.enterprise_id == -1) {
            const priceParams = { market_place_id: CURRENT_USER.market_place_info.id };
            getPrices(priceParams);
          } else {
            const priceParams = {
              market_place_id: CURRENT_USER.market_place_info.id,
              enterprise_id: $scope.entFilter.enterprise_id,
            };
            getPrices(priceParams);
          }
        });
      } else {
        const priceParams = { enterprise_id: $scope.entFilter.enterprise_id };
        getPrices(priceParams);
      }
    };

    // Table Sorting
    $scope.sort = function (keyname) {
      $scope.sortKey = keyname; // set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; // if true make it false and vice versa
    };
    $scope.sort('order');

    // New Price Modal trigger
    $scope.openPriceModal = function (requestAction, price) {
      let ctrlType;
      if (requestAction === 'new') {
        ctrlType = 'NewPriceModalController';
      } else if (requestAction === 'edit') {
        ctrlType = 'EditPriceModalController';
      }

      const modalInstance = $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/views/admin/price/priceModal.html',
        controller: ctrlType,
        size: 'lg',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          entFilter: function () {
            return $scope.entFilter;
          },
          passedPrice: function () {
            return price;
          },
        },
      });

      if (requestAction === 'new') {
        modalInstance.result.then(function () {
          userTypeCheck();
        });
      } else if (requestAction === 'edit') {
        modalInstance.result.then(function () {
          userTypeCheck();
        });
      }
      trackingService.trackEvent(trackingService.ADMIN, 'price', requestAction);
    };

    // delete price
    $scope.deletePrice = async function (price) {
      const config = {
        title: 'Are you sure?',
        text: 'You are about to delete Price ' + price.name,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Yes, delete Price',
      };

      const returnHandler = function (isConfirm) {
        if (isConfirm) {
          price.delete().then(
            async function (response) {
              if (response.success) {
                priceManagerService.removeDeletedPrice(price).then(function (pricesObj) {
                  $scope.prices = pricesObj[$scope.entFilter.enterprise_id];
                });

                const configPositive = {
                  title: 'Deleted!',
                  text: response.message,
                  icon: 'success',
                  timer: 2000,
                  showConfirmButton: false,
                };
                await Swal.fire(configPositive);
              } else {
                const configError = {
                  title: 'Error',
                  text: response.message,
                  icon: 'error',
                  timer: 2000,
                  showConfirmButton: false,
                };
                await Swal.fire(configError);
              }
            },
            async function (reason) {
              const configError = {
                title: 'Error',
                text: 'Error contacting the server',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configError);
            }
          );
        }
      };

      const { value: returnValue } = await Swal.fire(config);
      returnHandler(returnValue);

      trackingService.trackEvent(trackingService.ADMIN, 'price', 'delete');
    };

    userTypeCheck();

    unbindHandler = $rootScope.$on('EnterpriseSelectionChanged', function (data) {
      $scope.entFilter = EnterpriseFilterService.fetchSelectedEnterprise();
      const priceParams = { enterprise_id: $scope.entFilter.enterprise_id };
      getPrices(priceParams);
    });

    $scope.$on('$destroy', function () {
      unbindHandler();
    });
  },
]);
