import module from './module';
export default module.directive('dirPaging', function () {
  return {
    restrict: 'E',
    scope: {
      pageSize: '=?',
      currentPage: '=',
      itemsCount: '=',
      gotoPage: '=',
      paginationRange: '=?',
    },
    controller: [
      '$scope',
      function ($scope) {
        $scope.$watch('itemsCount', function () {
          if (!$scope.pageSize) {
            $scope.pageSize = 25;
          }
          if (!$scope.paginationRange) {
            $scope.paginationRange = 7;
          }

          $scope.totalPages = Math.ceil($scope.itemsCount / $scope.pageSize);
          // $scope.pages = _.range(1, $scope.totalPages + 1);
          $scope.pages = generatePagesArray(
            $scope.currentPage,
            $scope.itemsCount,
            $scope.pageSize,
            $scope.paginationRange
          );
        });

        $scope.checkPage = function (pageNum) {
          if (pageNum >= $scope.pages[0] && $scope.pages[$scope.pages.length - 1] && pageNum != '...') {
            $scope.gotoPage(pageNum);
            $scope.pages = generatePagesArray(pageNum, $scope.itemsCount, $scope.pageSize, $scope.paginationRange);
          }
        };

        /**
         * Generate an array of page numbers (or the '...' string) which is used in an ng-repeat to generate the
         * links used in pagination
         *
         * @param currentPage
         * @param rowsPerPage
         * @param paginationRange
         * @param collectionLength
         * @returns {Array}
         */
        function generatePagesArray(currentPage, collectionLength, rowsPerPage, paginationRange) {
          var pages = [];
          var totalPages = Math.ceil(collectionLength / rowsPerPage);
          var halfWay = Math.ceil(paginationRange / 2);
          var position;

          if (currentPage <= halfWay) {
            position = 'start';
          } else if (totalPages - halfWay < currentPage) {
            position = 'end';
          } else {
            position = 'middle';
          }

          var ellipsesNeeded = paginationRange < totalPages;
          var i = 1;
          while (i <= totalPages && i <= paginationRange) {
            var pageNumber = calculatePageNumber(i, currentPage, paginationRange, totalPages);

            var openingEllipsesNeeded = i === 2 && (position === 'middle' || position === 'end');
            var closingEllipsesNeeded = i === paginationRange - 1 && (position === 'middle' || position === 'start');
            if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
              pages.push('...');
            } else {
              pages.push(pageNumber);
            }
            i++;
          }
          return pages;
        }

        /**
         * Given the position in the sequence of pagination links [i], figure out what page number corresponds to that position.
         *
         * @param i
         * @param currentPage
         * @param paginationRange
         * @param totalPages
         * @returns {*}
         */
        function calculatePageNumber(i, currentPage, paginationRange, totalPages) {
          var halfWay = Math.ceil(paginationRange / 2);
          if (i === paginationRange) {
            return totalPages;
          } else if (i === 1) {
            return i;
          } else if (paginationRange < totalPages) {
            if (totalPages - halfWay < currentPage) {
              return totalPages - paginationRange + i;
            } else if (halfWay < currentPage) {
              return currentPage - halfWay + i;
            } else {
              return i;
            }
          } else {
            return i;
          }
        }
      },
    ],
    templateUrl: 'app/views/templates/paging.html',
  };
});
