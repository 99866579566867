import module from './module';

export default module.directive('filedrop', function () {
  return {
    restrict: 'A',
    link: function (scope, element) {
      element.bind('dragover', (event) => {
        const files = event.dataTransfer.items;
        if (files?.length) {
          const mimeType = files[0].type;
          const allowed = /image\/*/i.test(mimeType);
          event.dataTransfer.dropEffect = allowed ? 'copy' : 'none';
        }

        event.preventDefault();
        event.stopPropagation();
      });
      element.bind('drop', (event) => {
        event.stopPropagation();
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files?.length) {
          const mimeType = files[0].type;
          if (/image\/*/i.test(mimeType)) {
            scope.fileUploaded(event.dataTransfer);
          }
        }
      });
    },
  };
});
