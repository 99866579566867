import moment from 'moment';
import angular from 'angular';
import module from '../module';
import * as enums from '../../enums';

export default module.controller('MapDetailViewModalController', [
  '$scope',
  '$modalInstance',
  'selectedLocation',
  'selectedFilter',
  'sessionManagerService',
  '$location',
  '$localStorage',
  'FormatHelperService',
  'TimeFormatService',
  'AlertService',
  function (
    $scope,
    $modalInstance,
    selectedLocation,
    selectedFilter,
    sessionManagerService,
    $location,
    $localStorage,
    FormatHelperService,
    TimeFormatService,
    AlertService
  ) {
    $scope.keyAttrib = $localStorage.keyAttribute;
    $scope.keyProfession = $localStorage.generalData.filter(function (obj) {
      return obj.data_name === 'key_profession';
    })[0];

    $scope.location = angular.copy(selectedLocation.location);
    if (selectedFilter.id == 0) {
      $scope.sessions = $scope.location.sessions;
    } else {
      $scope.sessions = $scope.location.sessions.filter(function (obj) {
        return obj.status.type == selectedFilter.id;
      });
    }

    function getKeyAttribDisplayName() {
      sessionManagerService.getRealmAttribs(enums.Realms.Service).then(
        function (response) {
          $localStorage.keyAttribute.display_name = response.attrib_list.filter(function (obj) {
            return obj.name == $localStorage.keyAttribute.data_content;
          })[0].display_name;
          $scope.keyAttrib = $localStorage.keyAttribute;
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
        }
      );
    }
    if (!$scope.keyAttrib.display_name) {
      getKeyAttribDisplayName();
    }

    // Table Sorting
    $scope.sort = function (keyname) {
      $scope.sortKey = keyname; // set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; // if true make it false and vice versa
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    // ATTRIBUTE DISPLAY
    $scope.getRequestTimeDisplayVal = function (utcString) {
      const _momentObj = moment.utc(utcString, TimeFormatService.format('fullDateTime')).clone();
      const _val = FormatHelperService.returnLocalTimeString(_momentObj, TimeFormatService.format('dateTimeLong'));
      return _val;
    };
  },
]);
