import { Injectable } from '@angular/core';

import { AuthenticationService } from './../../services/authentication.service';
import { EnumsService } from './../../services/enums.service';

import { IUser } from './../../interfaces/IUser';

interface ICurrentUserState {
  selectionType: string;
  selectionData: string;
}

@Injectable()
export class HeaderTitleService {
  public currentUser: IUser;
  BRANCH_ID: number;

  constructor(private authenticationService: AuthenticationService, private enumsService: EnumsService) {
    this.BRANCH_ID = -2;
    this.currentUser = this.authenticationService.getCurrentUser();
  }

  public getHeaderTitle(branch?: any, enterprise?: any): ICurrentUserState {
    let selectionType: string;
    let selectionData: string;

    if (branch) {
      if (branch.id === -1) {
        selectionType = `Marketplace`;
        selectionData = '';
      } else {
        selectionType = 'Branch';
        selectionData = ` ${branch.name}`;
      }
    }

    if (enterprise) {
      if (enterprise.id !== -1 && enterprise.id !== -2) {
        selectionType = `Enterprise `;
        selectionData = ` ${this.getEnterpriseName(enterprise)}`;
      } else if (this.currentUser.user_type === this.enumsService.UserType.BranchAdmin || enterprise.id === -2) {
        selectionType = 'Branch';
      }
    }

    return { selectionData, selectionType };
  }

  private getEnterpriseName(enterprise): string {
    if (!enterprise) {
      return '';
    }

    if (typeof enterprise.name === 'string' && enterprise.name.length) {
      return enterprise.name;
    }

    if (this.currentUser?.enterprise_info?.name) {
      return this.currentUser.enterprise_info.name;
    }

    return '';
  }
}
