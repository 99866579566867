import module from './module';
import moment from 'moment';

export default module.factory('ReportingSectionService', [
  '$http',
  'BASE_URL',
  '$q',
  'TimeFormatService',
  function ($http, BASE_URL, $q, TimeFormatService) {
    var allLocations = {};
    var allRequests = {};
    var filterObj = {};
    var dateRangeObj = {
      start: '',
      end: '',
    };

    var dateRangeChoices = [
      { value: 'week_to_date', display_name: 'Week To Date' },
      { value: 'month_to_date', display_name: 'Month To Date' },
      { value: 'quarter_to_date', display_name: 'Quarter To Date' },
      { value: 'year_to_date', display_name: 'Year To Date' },
      { value: 'last_week', display_name: 'Last Week' },
      { value: 'last_month', display_name: 'Last Month' },
      { value: 'last_quarter', display_name: 'Last Quarter' },
      { value: 'last_year', display_name: 'Last Year' },
      { value: 'yesterday', display_name: 'Yesterday' },
      { value: 'today', display_name: 'Today' },
      { value: 'custom', display_name: 'Any Day to Any Day' },
    ];

    let changeVerifyDateRangeArray = [];

    var statsObj = [
      { name: 'Requests', count: 0 },
      { name: 'Commits', count: 0 },
      { name: '% Commits', count: 0 },
    ];
    function setReportingNames(keyAttribName, keyProfessionName) {
      var keyName = keyAttribName || 'Key Attribute';
      var professionName = keyProfessionName || 'Worker';

      const reportChoices = [
        {
          value: 'Worker Time Worked',
          display_name: professionName + ' Time Worked',
        },
        {
          value: 'Worker Time Worked By Location',
          display_name: professionName + ' Time Worked by Location',
        },
        // {
        //   value: "Worker Time Worked By Key Attribute",
        //   display_name: professionName + " Time Worked by " + keyName,
        // },
        { value: 'Worker Quality', display_name: professionName + ' Quality' },
        {
          value: 'Worker Punctuality',
          display_name: professionName + ' Punctuality',
        },
        { value: 'Work Distribution', display_name: 'Work Distribution' },
        {
          value: 'Worker GPS Variance',
          display_name: professionName + ' GPS Variance',
        },
        { value: 'Work Distribution', display_name: 'Work Distribution' },
        {
          value: 'Work Distribution By Location',
          display_name: 'Work Distribution By Location',
        },
        // {
        //   value: "Work Distribution By Key Attribute",
        //   display_name: "Work Distribution By " + keyName,
        // },
        { value: 'Location Time Worked', display_name: 'Location Time Worked' },
        // {
        //   value: "Key Attribute Time Worked",
        //   display_name: keyName + " Time Worked",
        // },
      ];
      return reportChoices;
    }

    function setDateRange(time_peroid) {
      var startDateFilter = moment().startOf('day');
      var endDateFilter = moment().endOf('day');

      switch (time_peroid.value) {
        case 'week_to_date':
          startDateFilter = moment().startOf('week');
          break;
        case 'month_to_date':
          startDateFilter = moment().startOf('month');
          break;
        case 'quarter_to_date':
          startDateFilter = moment().startOf('quarter');
          break;
        case 'year_to_date':
          startDateFilter = moment().startOf('year');
          break;
        case 'last_week':
          var lastWeek = moment().week(moment().week() - 1);
          startDateFilter = lastWeek.clone().startOf('week');
          endDateFilter = lastWeek.clone().endOf('week');
          break;
        case 'last_month':
          var lastMonth = moment().month(moment().month() - 1);
          startDateFilter = lastMonth.clone().startOf('month');
          endDateFilter = lastMonth.clone().endOf('month');
          break;
        case 'last_quarter':
          var lastQuarter = moment().quarter(moment().quarter() - 1);
          startDateFilter = lastQuarter.clone().startOf('quarter');
          endDateFilter = lastQuarter.clone().endOf('quarter');
          break;
        case 'last_year':
          startDateFilter = moment().month(0).date(-364);
          endDateFilter = moment().month(0).date(0);
          break;
        case 'yesterday':
          var yesterday = moment().subtract(1, 'd');
          startDateFilter = yesterday.clone().startOf('d');
          endDateFilter = yesterday.clone().endOf('d');
          break;
        case 'today':
          break;
        case 'custom':
          break;
      }

      dateRangeObj = {
        start: startDateFilter,
        end: endDateFilter,
      };
    }

    function filterRequestStats(allRequests, filterObj) {
      const provider = filterObj.selectedFilter;
      const requestList = allRequests.filter(function (obj) {
        return moment(obj.request_date)._d > dateRangeObj.start._d && moment(obj.request_date)._d < dateRangeObj.end._d;
      });
      const provRequests = requestList.filter(function (obj) {
        return obj.serv_user_id == provider.user_id;
      });
      let percentCommits = (provRequests.length / requestList.length) * 100;
      if (percentCommits) {
        percentCommits = percentCommits.toFixed(2);
      } else {
        percentCommits = 0;
      }

      // var completedJobs = provRequests.filter(function(obj){return obj.status.type == 5;});
      statsObj = [
        { name: 'Requests', count: requestList.length },
        { name: 'Commits', count: provRequests.length },
        { name: '% Commits', count: percentCommits },
      ];
    }
    function dateSelectionBuilder(start, end, time) {
      changeVerifyDateRangeArray = [];
      const _start = start.clone();
      const _end = end.clone();
      const _timeSpan = time;

      for (let i = 1; i < 61; i++) {
        const obj = {
          id: i,
          display: _start.local().format('MMM Do hh:mmA') + ' - ' + _end.local().format('MMM Do hh:mmA'),
          start: _start.utc().format(TimeFormatService.format('api')),
          end: _end.utc().format(TimeFormatService.format('api')),
        };
        changeVerifyDateRangeArray.push(obj);
        _start.subtract(1, _timeSpan);
        _end.subtract(1, _timeSpan);
      }
    }

    function dateSelectionSemiMonthly(start) {
      changeVerifyDateRangeArray = [];
      let _start = start.clone();
      let _end;

      function bumpDates(start) {
        if (start.isBefore(moment.utc(start).date(15))) {
          _start = moment.utc(start).startOf('month');
          _end = moment.utc(start).date(15).endOf('day');
        } else {
          _start = moment.utc(start).date(16).startOf('day');
          _end = moment.utc(start).endOf('month');
        }
      }
      bumpDates(start);

      for (let i = 1; i < 61; i++) {
        const obj = {
          id: i,
          display: _start.utc().format('MMM Do YYYY') + ' - ' + _end.utc().format('MMM Do YYYY'),
          start: _start.utc().format(TimeFormatService.format('api')),
          end: _end.utc().format(TimeFormatService.format('api')),
        };
        changeVerifyDateRangeArray.push(obj);

        if (_start.date() === 16) {
          _start = _start.startOf('month');
          _end = moment.utc(_start).date(15).endOf('day');
        } else {
          _end.subtract(1, 'month');
          _end = _end.endOf('month');
          _start = moment.utc(_end).date(16).startOf('day');
        }
      }
    }

    function setVerifyDateRange(billing_info) {
      if (!billing_info) {
        return;
      }

      let start, end;
      const billingObj = billing_info;

      switch (billingObj.frequency) {
        case 1: //Daily
          start = moment().startOf('d');
          end = moment().endOf('d');
          dateSelectionBuilder(start, end, 'd');
          break;
        case 2: // Weekly
          var dayNum = billingObj.start_of_week || 0;
          var tempStart = moment().day(dayNum).clone();
          if (moment().isBefore(tempStart)) {
            //meaning tempStart is in future
            tempStart.subtract(7, 'd');
          }
          start = tempStart.startOf('d').clone();
          var tempEnd = tempStart.add(6, 'd').clone();
          end = tempEnd.endOf('d').clone();
          dateSelectionBuilder(start, end, 'w');
          break;
        case 3: // Bi-Weekly
          break;
        case 4: //Monthly
          start = moment().startOf('M');
          end = moment().endOf('M');
          dateSelectionBuilder(start, end, 'M');
          break;
        case 5: //Semi-Monthly
          dateSelectionSemiMonthly(moment.utc().startOf('d'));
          break;
      }
    }

    return {
      filterAllServiceRequests: function (all_requests, filter_obj, callback) {
        allRequests = all_requests;
        filterObj = filter_obj;
        filterRequestStats(allRequests, filterObj);
        callback(statsObj);
      },
      fetchDateRangeChoices: function () {
        return dateRangeChoices;
      },
      fetchReportChoices: function (keyAttribName, keyProfessionName) {
        const reportChoices = setReportingNames(keyAttribName, keyProfessionName);
        return reportChoices;
      },
      fetchDateRange: function (time_peroid) {
        setDateRange(time_peroid);
        return dateRangeObj;
      },
      buildVerifyChangeDateRange: function (billing_info) {
        setVerifyDateRange(billing_info);
        return changeVerifyDateRangeArray;
      },
      fetchVerifyChangeDateRange: function () {
        return changeVerifyDateRangeArray;
      },
      getPentahoReport: function () {
        const deferred = $q.defer();
        this._verifyServiceRequests(requestList, deferred);
        return deferred.promise;
      },
    }; // end of return
  },
]);
