import module from '../module';
import * as enums from '../../enums';
export default module.controller('LoggedOutController', [
  '$scope',
  '$modalInstance',
  '$location',
  function ($scope, $modalInstance, $location) {
    $scope.icons = {
      warning: `${enums.AssetsFolder.svg}invitations/warning.svg`,
    };

    $scope.ok = function () {
      $location.search({});
      $modalInstance.close();
    };
  },
]);
