import angular from 'angular';
import module from '../module';
export default module.controller('NewUserModalController', [
  '$scope',
  '$modalInstance',
  '$modal',
  'AlertService',
  'enterprise_selection',
  'branch_selection',
  'roleManagerService',
  'AuthenticationService',
  'adminUserService',
  'EnterpriseFilterService',
  'WEB_URL',
  'branchFilterService',
  function (
    $scope,
    $modalInstance,
    $modal,
    AlertService,
    enterprise_selection,
    branch_selection,
    roleManagerService,
    AuthenticationService,
    adminUserService,
    EnterpriseFilterService,
    WEB_URL,
    branchFilterService
  ) {
    $scope.initialize = async function () {
      $scope.CURRENT_USER = AuthenticationService.getCurrentUser();
      $scope.multiSelectTranslation = {
        selectAll: 'Select all',
        selectNone: 'Select none',
        reset: 'Undo all',
        search: 'Type here to search...',
        nothingSelected: 'All',
      };

      $scope.submitBtnDisbaled = false;
      $scope.showSpinner = true;
      $scope.type = 'Invite New User'; // Modal Title
      $scope.btnLabel = 'Invite User';
      $scope.sortKey = 'name';
      $scope.reverse = false;

      const enterpriseData = $scope.getEnterpriseData();
      $scope.showBranches = !enterpriseData.enterprise && (!enterpriseData.branch || enterpriseData.branch === -1);
      $scope.formData = {
        enterprise: enterpriseData.enterprise,
        marketplace: enterpriseData.marketplace,
        branch: enterpriseData.branch,
      };

      $scope.branches = await branchFilterService.fetchBranches();
      $scope.branches = $scope.branches?.filter((b) => b.id !== -1);

      roleManagerService.getAllRoles().then(function (list) {
        if (enterprise_selection) {
          $scope.roles = EnterpriseFilterService.filterManageableItems(angular.copy(list));
        } else {
          $scope.roles = angular.copy(list);
        }
        $scope.showSpinner = false;
      });
    };

    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      const emails = $scope.validateEmails();
      if (emails) {
        $scope.addNewUser(emails);
      } else {
        AlertService.errorAlert('Please make sure that the email addresses you entered are valid.');
        $scope.submitBtnDisbaled = false;
      }
    };

    $scope.validateEmails = function () {
      if (!$scope.formData.emails) {
        return false;
      } else {
        let emails = null;
        if ($scope.formData.emails.indexOf(',') > -1) {
          emails = $scope.formData.emails.split(',');
        } else {
          emails = $scope.formData.emails.split('\n');
        }

        const emailValidator = (email) => {
          return String(email)
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        };

        emails = emails.map((email) => email.trim());

        if (!emails.every(emailValidator)) {
          return false;
        }
        return emails;
      }
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      $scope.formData = {};
    };

    $scope.getEnterpriseData = function () {
      let passedEnt = enterprise_selection || {
        enterprise_id: $scope.CURRENT_USER.enterprise_info && $scope.CURRENT_USER.enterprise_info.id,
      };

      let enterprise, marketplace, branch;

      if (!passedEnt.enterprise_id || passedEnt.enterprise_id === -1) {
        enterprise = null;
      } else {
        enterprise = passedEnt.enterprise_id;
      }

      return {
        enterprise,
        marketplace: $scope.CURRENT_USER.user_type == 2 ? $scope.CURRENT_USER.market_place_info.id : null,
        branch: branch_selection && branch_selection.id,
      };
    };

    $scope.addNewUser = function (emails) {
      if ($scope.formData.enterprise) {
        $scope.formData.branch = null;
      }
      if (!$scope.formData.branch || $scope.formData.branch === -1) {
        $scope.formData.branch = null;
      }

      const enterpriseData = $scope.getEnterpriseData();
      if (enterpriseData.branch == -1) {
        delete enterpriseData.branch;
      }
      if (enterpriseData.enterprise == -1) {
        delete enterpriseData.enterprise;
      }

      let branch_id;
      if ($scope.formData.branch?.length) {
        branch_id = $scope.formData.branch.map((x) => x.id);
      } else {
        branch_id = enterpriseData.branch;
      }

      const payload = {
        to_email_list: emails,
        role_id: $scope.formData.role,
        web_url: window.location.host.indexOf('localhost') > -1 ? WEB_URL : window.location.host,
        enterprise_id: enterpriseData.enterprise ? enterpriseData.enterprise : undefined,
        branch_id,
      };

      if (payload.branch_id === 0 || payload.enterprise_id) {
        delete payload.branch_id;
      }

      adminUserService.referAdmin(payload).then(
        function () {
          AlertService.successAlert('New User invited.');
          $modalInstance.close();
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.close();
        }
      );
    };

    $scope.sort = function (keyname) {
      $scope.sortKey = keyname; // set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; // if true make it false and vice versa
    };

    $scope.openBulkLoaded = async function () {
      const modalInstance = $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/views/admin/user/bulkLoadedModal.html',
        size: 'lg',
        backdrop: 'static',
        controller: 'BulkLoadedModalController',
        resolve: {
          enterprise_selection: function () {
            return enterprise_selection;
          },
          branch_selection: function () {
            return branch_selection;
          },
        },
      });

      modalInstance.result.then(function (selectedItem) {
        $modalInstance.close();
      });
    };

    $scope.initialize();
  },
]);
