import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginPageComponent } from './pages/login-page.component';
import { LoginUpgradeDirective } from './components/login-upgrade.directive';
import { ForgotPasswordPageComponent } from './pages/forgot-password.page.component';
import { ResetPasswordPageComponent } from './pages/reset-password.page.component';
import { AppRoutingModule } from 'app/app.routing.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
  ],
  declarations: [LoginPageComponent, LoginUpgradeDirective, ForgotPasswordPageComponent, ResetPasswordPageComponent],
  entryComponents: [],
  providers: [],
})
export class LoginModule {
  constructor() {}
}
