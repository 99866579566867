import module from '../../module';
import * as enums from '../../../enums';
import Swal from 'sweetalert2';

export default module.controller('ApiLogsTabController', [
  '$scope',
  '$modal',
  '$window',
  'AuthenticationService',
  'apiLogsService',
  'trackingService',
  function ($scope, $modal, $window, AuthenticationService, apiLogsService, trackingService) {
    $scope.enums = enums;

    let savedAttribFilters = null;

    $scope.apiLogs = null;
    $scope.filteredApiLogs = null;

    $scope.initialize = function () {
      const CURRENT_USER = AuthenticationService.getCurrentUser();
      $scope.showSpinner = true;
      $scope.reverse = true;

      // check for admin user
      if (CURRENT_USER.user_type !== 2) {
        $window.location.reload();
      }

      const data = {
        marketplace_id: CURRENT_USER.market_place_info.id,
      };

      apiLogsService.getInboundApiLogs(data).then(
        (response) => {
          $scope.apiLogs = response;
          $scope.filteredApiLogs = response;
          $scope.showSpinner = false;

          if (savedAttribFilters) {
            $scope.applyFilters(savedAttribFilters);
            savedAttribFilters = null;
          }
          // sort the table right after creating it by call_date_local
          $scope.sort('call_date_local');
          $scope.sort('call_date_local');
        },
        async () => {
          $scope.showSpinner = false;

          const config = {
            title: 'Error',
            text: 'There was an error downloading the API Logs',
            icon: 'error',
            showConfirmButton: true,
          };
          await Swal.fire(config);
        }
      );
    };

    $scope.sort = (keyname) => {
      $scope.sortKey = keyname; // set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; // if true make it false and vice versa
    };

    $scope.openModal = (row) => {
      $modal.open({
        animation: true,
        templateUrl: 'app/views/admin/apiLogs/apiLogsDetailModal.html',
        controller: 'apiLogsTabDetailModal',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          passedLog: () => row,
        },
      });
      trackingService.trackEvent(trackingService.ADMIN, 'apilogs', 'modal');
    };

    $scope.applyFilters = (attribFilters) => {
      savedAttribFilters = attribFilters;

      if (!attribFilters || !attribFilters.length) {
        $scope.filteredApiLogs = $scope.apiLogs;
        return;
      }

      if (!$scope.apiLogs) {
        return;
      }

      $scope.filteredApiLogs = apiLogsService.filterApiLogs($scope.apiLogs, attribFilters);
      trackingService.trackEvent(trackingService.ADMIN, 'apilogs', 'filter');
    };

    $scope.exportToCsv = () => {
      let result = [];
      result.push('data:text/csv;charset=utf8,');
      result.push('Id;Username;Endpoint Called;Date Time;Date Time Local;Status;ATS ID;');
      const csvBody = $scope.filteredApiLogs
        .map(function (apiLog) {
          return {
            id: apiLog.log_id,
            username: apiLog.username,
            endpoint_desc: apiLog.endpoint_desc,
            call_date: apiLog.call_date,
            call_date_local: apiLog.call_date_local,
            status: $scope.getStatusText(apiLog.status),
            atsId: apiLog.ext_id,
          };
        })
        .map(function (apiLog) {
          return Object.keys(apiLog)
            .map(function (key) {
              return apiLog[key];
            })
            .join(';');
        });
      result = result.concat(csvBody);
      const csv = result.join('\n');
      $scope.generateDownload(csv);
    };

    $scope.generateDownload = function (dataUrl) {
      const hiddenElement = document.createElement('a');
      hiddenElement.href = encodeURI(dataUrl);
      hiddenElement.download = 'api_logs_report.csv';
      document.body.appendChild(hiddenElement); // Required for FF
      hiddenElement.click();
      document.body.removeChild(hiddenElement);
    };

    $scope.getStatusText = function (status) {
      return Object.keys(enums.ApiLogsState)[status - 1];
    };

    $scope.initialize();
  },
]);
