import { Injectable } from '@angular/core';
import { SecureHttpService } from '../secure.http.service';
import Env from '../../../scripts/env.js';

@Injectable({ providedIn: 'root' })
export class MarketplaceService {
  constructor(private http: SecureHttpService) {}

  public getMarketplace() {
    return this.http.get(`${Env.ADMIN_API}/marketplace/`).toPromise();
  }
}
