import angular from 'angular';
import module from '../module';
import moment from 'moment';
import * as enums from '../../enums';

export default module.controller('TransferSplitModalController', [
  '$scope',
  '$modalInstance',
  'AlertService',
  'passedSession',
  'passedEnt',
  'sessionManagerService',
  '$timeout',
  'FormatHelperService',
  'trackingService',
  'generalDataService',
  'TimeFormatService',
  function (
    $scope,
    $modalInstance,
    AlertService,
    passedSession,
    passedEnt,
    sessionManagerService,
    $timeout,
    FormatHelperService,
    trackingService,
    generalDataService,
    TimeFormatService
  ) {
    let _totalServiceTimeAttrib;
    $scope.keySession = generalDataService.getKeySession();
    $scope.submitBtnDisbaled = false;
    $scope.title = 'Transfer / Split';
    const _passedSession = angular.copy(passedSession);
    $scope.entFilter = angular.copy(passedEnt);
    $scope.type = 'transfer split';
    $scope.jobType = { assign: false, escalation: false, selection: '' };
    $scope.endSessionReview = false;

    sessionManagerService
      .getRealmAttribs(enums.Realms.WorkerReview)
      .then(
        function (response) {
          _totalServiceTimeAttrib = response.attrib_list.filter(function (obj) {
            return obj.name === 'service_total_time';
          })[0];
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.dismiss('cancel');
        }
      )
      .then(function () {
        sessionManagerService.getRealmAttribs(enums.Realms.RequesterReview).then(
          function (response) {
            $scope.reviewAttribs = response.attrib_list;
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
            $modalInstance.dismiss('cancel');
          }
        );
      })
      .then(function () {
        sessionManagerService.setSession(_passedSession.session_id || _passedSession.request_id).then(
          function (setSession) {
            $scope.session = angular.copy(setSession);
            buildCheckin(setSession);
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
            $modalInstance.dismiss('cancel');
          }
        );
      })
      .then(function () {
        sessionManagerService.getRealmAttribs(enums.Realms.Service).then(
          function (response) {
            const scheduledService = response.attrib_list.filter(function (obj) {
              return obj.type_flags.type == 0 || obj.type_flags.type == 1;
            });
            const formData = scheduledService.sort(function (a, b) {
              return a.order - b.order;
            });
            $scope.svcRequestFormData = angular.copy(formData);
            $scope.originalFormData = angular.copy(formData);
            reorderAttribs($scope.svcRequestFormData);
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
            $modalInstance.dismiss('cancel');
          }
        );
      });

    // for new session start time
    $scope.adjustNewSessionStart = function () {
      const durationMinutes = $scope.duration.hours * 60 + $scope.duration.minutes;
      $scope.inputDuration = durationMinutes;
      if (passedSession.start) {
        $scope.session.new_end = moment(passedSession.start, TimeFormatService.format('fullDateTimeSecs'))
          .add(durationMinutes, 'm')
          .clone();
      } else {
        $scope.session.new_end = moment
          .utc(passedSession.start_date_time, TimeFormatService.format('fullDateTimeSecs'))
          .local()
          .add(durationMinutes, 'm')
          .clone();
      }
    };

    $scope.startTimeDisplay = function (start_time) {
      const displayTime = moment(start_time).clone().format('hh:mma ddd MMM YYYY');
      return displayTime;
    };

    // build display in total time block
    function getDurationDisplay() {
      let totalMinuteDuration = moment().diff($scope.checkinMoment, 'm');
      totalMinuteDuration = Math.abs(totalMinuteDuration);
      const hourMinuteCount = $scope.duration.hours * 60;
      if (hourMinuteCount < totalMinuteDuration) {
        $scope.duration.minutes = totalMinuteDuration - hourMinuteCount;
      } else {
        $scope.duration.minutes = hourMinuteCount - totalMinuteDuration;
      }
      $scope.adjustNewSessionStart();
    }
    function buildCheckin(session) {
      const builtCheckInTimeUtc = session.checkin_date + ' ' + session.checkin_time;

      $scope.checkinMoment = moment
        .utc(builtCheckInTimeUtc, TimeFormatService.format('fullDateTimeSecs'))
        .local()
        .clone();
      const _hours = moment().diff($scope.checkinMoment, 'h');

      $scope.duration = {
        minutes: '',
        hours: Math.abs(_hours),
      };
      getDurationDisplay();
    }

    // format service request attrib array
    function reorderAttribs(attrib_list) {
      if (!$scope.session) {
        $timeout(function () {
          reorderAttribs(attrib_list);
        }, 1000);
      } else {
        const _removedAttribsArray = [];
        angular.forEach(attrib_list, function (attrib, index) {
          switch (attrib.name) {
            case 'escalation_list':
              $scope.escalationAttrib = attrib;
              $scope.escalationAttrib.value = { choice_id: 0 };
              _removedAttribsArray.push(attrib);
              break;
            case 'staff_provider_list':
              $scope.staffProviderAttrib = attrib;
              $scope.staffProviderAttrib.value = attrib.choice_list.filter(function (obj) {
                return obj.choice_id == $scope.session.serv_provider_id;
              })[0];
              $scope.jobType = {
                assign: true,
                escalation: false,
                selection: 'assign',
              };
              _removedAttribsArray.push(attrib);
              break;
          }
        });
        removeAttribFromArray(_removedAttribsArray);
      }
    }
    function removeAttribFromArray(attribsArray) {
      for (let i = 0; i < attribsArray.length; i++) {
        const index = $scope.svcRequestFormData.indexOf(attribsArray[i]);
        $scope.svcRequestFormData.splice(index, 1);
      }
      $scope.originalSortedAttribList = angular.copy($scope.svcRequestFormData);
    }

    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      if (durationErrorCheck() && newSessionErrorCheck()) {
        const reviewData = sessionManagerService.formatSessionReviewData($scope.session.review);
        const reivewObj = {
          end_type: 1,
          request_id: $scope.session.request_id,
          attrib_list: reviewData,
        };
        const newSessionDetails = formatNewSessionDetails($scope.svcRequestFormData);
        submitSessionEndToServer(reivewObj, newSessionDetails);
      } else {
        $scope.submitBtnDisbaled = false;
      }
      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'submit');
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'cancel');
    };

    function durationErrorCheck() {
      const durationMinutes = $scope.duration.hours * 60 + $scope.duration.minutes;
      if (durationMinutes > _totalServiceTimeAttrib.max_amount) {
        AlertService.errorAlert('Duration cannot exceed ' + _totalServiceTimeAttrib.max_amount / 60 + ' hours');
        return false;
      } else {
        $scope.checkoutFormData = {
          request_id: $scope.session.request_id,
          end_type: 1,
          for_provider: true,
          attrib_list: [
            {
              attrib_id: _totalServiceTimeAttrib.attrib_id,
              value_count: durationMinutes,
            },
          ],
        };
        return true;
      }
    }

    function newSessionErrorCheck() {
      const _errorMessages = [];
      // Multi select erorr Checking
      angular.forEach($scope.svcRequestFormData, function (attrib) {
        if (attrib.type === 'multi_list' && attrib.type_flags.required == true) {
          if (attrib.value && attrib.value.length == 0) {
            _errorMessages.push('Missing Required ' + attrib.display_name + ' Selection');
          }
        } else if (attrib.name === 'location') {
          if (attrib.value.location_id == 0) {
            if (attrib.value.location.length == 0) {
              _errorMessages.push('Missing Required ' + attrib.display_name + ' Selection');
            }
          }
        }
      });

      if (_errorMessages.length > 0) {
        AlertService.errorAlert(_errorMessages.join('\n'));
        return false;
      } else {
        return true;
      }
    }

    function formatNewSessionDetails(sessionDetails) {
      const _sessionDetails = angular.copy(sessionDetails);
      if ($scope.jobType.selection === 'assign') {
        _sessionDetails.push($scope.staffProviderAttrib);
      } else if ($scope.jobType.selection === 'escalation') {
        _sessionDetails.push($scope.escalationAttrib);
      }
      const attrib_list = FormatHelperService.format_data(_sessionDetails);
      const scheduledServiceRequest = {
        type: 1,
        attrib_list: attrib_list,
      };
      return scheduledServiceRequest;
    }

    function submitSessionEndToServer(reivewObj, newSessionDetails) {
      sessionManagerService.endSessionRequest(reivewObj).then(
        function (response) {
          if (response.success) {
            sessionManagerService.endSessionRequest($scope.checkoutFormData).then(
              function (response) {
                if (response.success) {
                  sendNewSessionToServer(newSessionDetails);
                } else {
                  AlertService.errorAlert(response.message);
                  $modalInstance.dismiss('cancel');
                }
              },
              function (reason) {
                AlertService.serverRequestErrorAlert(reason);
                $modalInstance.dismiss('cancel');
              }
            );
          } else {
            AlertService.errorAlert(response.message);
            $modalInstance.dismiss('cancel');
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.dismiss('cancel');
        }
      );
    }

    function sendNewSessionToServer(newSessionDetails) {
      sessionManagerService.submitSessionRequest(newSessionDetails).then(
        function (response) {
          if (response.success) {
            AlertService.successAlert(response.message);
            $modalInstance.close(response);
          } else {
            AlertService.errorAlert(response.message);
            $modalInstance.dismiss('cancel');
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.dismiss('cancel');
        }
      );
    }

    // listen for instance_count attrib clicks
    $scope.$on('addAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      const _count = field.instanceCountIndex || 1;
      const _field = angular.copy(
        $scope.originalFormData.filter(function (obj) {
          return obj.attrib_id == field.attrib_id;
        })[0]
      );
      _field.instanceCountIndex = _count + 1;
      _field.showRemoveInstance = true;
      $scope.svcRequestFormData[index].doNotShowAddInstance = true;
      $scope.svcRequestFormData[index].showRemoveInstance = false;
      $scope.svcRequestFormData.splice(index + 1, 0, _field); // add after current field
    });

    $scope.$on('removeAttribInstanceClicked', function (e, field, index) {
      e.stopPropagation();
      $scope.svcRequestFormData[index - 1].doNotShowAddInstance = false;
      $scope.svcRequestFormData[index - 1].showRemoveInstance = true;
      $scope.svcRequestFormData.splice(index, 1);
    });
  },
]);
