import * as angular from 'angular';
import { Component, ChangeDetectionStrategy, OnInit, Inject, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Observable, map } from 'rxjs';
import { WorkersSelectors } from '../../store/selectors';
import { Store } from '@ngrx/store';
import { State } from '../../../store';
import { FetchWorkerDetails, LogConfidentialView, SelectTab } from '../../store/actions';
import { AuthenticationService, PermissionsService } from '../../../shared/services';

@Component({
  templateUrl: './worker.page.html',
  styleUrls: ['./worker.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkerPage implements OnInit {
  public tabItems = [{ name: 'Profile' }, { name: 'Worker Schedule' }];

  public selectedTab$: Observable<number>;
  public worker$: Observable<any>;
  @Input() providerId: number;
  @Input() workerId: number;
  @Input() closeCallback: any;

  constructor(
    @Inject('LocationService') private locationService,
    @Inject('ProviderManagerService') private providerManagerService,
    private selectors: WorkersSelectors,
    private store: Store<State>,
    private authenticationService: AuthenticationService,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit() {
    this.selectedTab$ = this.selectors.getSelectedTab();
    const ids = this.tryGetWorkerId();

    if (ids.userId) {
      this.worker$ = this.selectors.getWorkerListItem(ids.userId, ids.providerId);
      this.providerId = ids.providerId;
    }

    if (this.authenticationService.getPermission(this.permissionsService.PERMISSIONS.BLACKLIST)) {
      this.tabItems.push({ name: 'Blocklist' });
    }
  }

  public tabSelectionChanged(index) {
    this.store.dispatch(new SelectTab(index));
  }

  reloadWorker() {
    const ids = this.tryGetWorkerId();
    this.store.dispatch(new FetchWorkerDetails(ids.userId, ids.providerId));
  }

  private tryGetWorkerId() {
    if (this.workerId && this.providerId) {
      return {
        providerId: this.providerId,
        userId: this.workerId,
      };
    }
    const path = this.locationService.path();
    const segments = path.split('/');
    const lastSegment = segments.length && segments[segments.length - 1];
    const providerId = !isNaN(lastSegment) && parseInt(lastSegment);

    const penultimateSegment = segments.length > 1 && segments[segments.length - 2];
    const userId = !isNaN(penultimateSegment) && parseInt(penultimateSegment);

    return { providerId, userId };
  }

  public backClick() {
    this.closeCallback && this.closeCallback.doClose();
  }

  public logConfidentialView(e: Worker) {
    this.store.dispatch(new LogConfidentialView(e));
  }
}

angular.module('ServetureWebApp').directive('workerPage', downgradeComponent({ component: WorkerPage }));
