import module from '../module';
import Swal from 'sweetalert2';

export default module.controller('EscalationTabController', [
  '$scope',
  '$rootScope',
  '$modal',
  'AlertService',
  'escalationManagerService',
  'AuthenticationService',
  'timeConversionService',
  'trackingService',
  'EnterpriseFilterService',
  function (
    $scope,
    $rootScope,
    $modal,
    AlertService,
    escalationManagerService,
    AuthenticationService,
    timeConversionService,
    trackingService,
    EnterpriseFilterService
  ) {
    let unbindHandler = null;
    $scope.showSpinner = true;
    $scope.escalationBtnStatus = true;
    $scope.reverse = true;
    $scope.entFilter = EnterpriseFilterService.fetchSelectedEnterprise();
    const CURRENT_USER = AuthenticationService.getCurrentUser();

    // Permission Check
    $scope.addEscPermission = CURRENT_USER.permissionCheck(28);
    $scope.editEscPermission = CURRENT_USER.permissionCheck(29);
    $scope.deleteEscPermission = CURRENT_USER.permissionCheck(30);

    // Get Escalations
    const getEscalations = function (escalationParams) {
      escalationManagerService.getAllEscalations(escalationParams).then(
        function (escalationsObj) {
          $scope.escalations =
            parseInt($scope.entFilter.enterprise_id) > 0
              ? escalationsObj[$scope.entFilter.enterprise_id]
              : escalationsObj[-1];
          $scope.showSpinner = false;
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $scope.showSpinner = false;
        }
      );
    };

    // market_place enterprise filter
    if (CURRENT_USER.user_type == 2) {
      $scope.$watch('entFilter', function () {
        // if ($scope.entFilter.enterprise_id == -1) {
        //   $scope.escalations = null;
        //   $scope.escalationBtnStatus = false;
        //   $scope.showSpinner = false;
        // } else {
        $scope.escalationBtnStatus = true;

        const marketPlaceId = CURRENT_USER.market_place_info.id;
        let escalationParams = {};
        if (parseInt($scope.entFilter.enterprise_id) > 0) {
          escalationParams = { ...escalationParams, enterprise_id: $scope.entFilter.enterprise_id };
        } else if (marketPlaceId) {
          escalationParams = { ...escalationParams, market_place_id: marketPlaceId };
        }

        getEscalations(escalationParams);
        // }
      });
    } else {
      const marketPlaceId = CURRENT_USER.market_place_info.id;
      let escalationParams = {};
      if (parseInt($scope.entFilter.enterprise_id) > 0) {
        escalationParams = { ...escalationParams, enterprise_id: $scope.entFilter.enterprise_id };
      } else if (marketPlaceId) {
        escalationParams = { ...escalationParams, market_place_id: marketPlaceId };
      }
      getEscalations(escalationParams);
    }

    // Table Sorting
    $scope.sort = function (keyname) {
      $scope.sortKey = keyname; // set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; // if true make it false and vice versa
    };
    $scope.sort('order');

    // Delete escalation button
    $scope.deleteEscalation = async function (escalation) {
      const config = {
        title: 'Are you sure?',
        text: 'You are about to delete ' + escalation.name,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Yes, delete Escalation',
      };

      const returnHandler = function (isConfirm) {
        if (isConfirm) {
          escalation.delete().then(
            async function (response) {
              if (response.success) {
                escalationManagerService.removeDeletedEscalation(escalation).then(function (escalationsObj) {
                  $scope.escalations =
                    parseInt($scope.entFilter.enterprise_id) > 0
                      ? escalationsObj[$scope.entFilter.enterprise_id]
                      : [].concat.apply(
                          [],
                          Object.keys(escalationsObj).map((k) => escalationsObj[k])
                        );
                });

                const configPositive = {
                  title: 'Deleted!',
                  text: response.message,
                  icon: 'success',
                  timer: 2000,
                  showConfirmButton: false,
                };
                await Swal.fire(configPositive);
              } else {
                const configError = {
                  title: 'Error',
                  text: response.message,
                  icon: 'error',
                  timer: 2000,
                  showConfirmButton: false,
                };
                await Swal.fire(configError);
              }
            },
            async function (reason) {
              const configError = {
                title: 'Error',
                text: 'Error contacting the server',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
              };
              await Swal.fire(configError);
            }
          );
        } // if isconfirm
      };

      const { value: returnValue } = await Swal.fire(config);
      returnHandler(returnValue);

      trackingService.trackEvent(trackingService.ADMIN, 'escalations', 'delete');
    }; // delete escalation scope

    // Open Escalation Modal
    $scope.openEscalationModal = function (action, escalation) {
      let controllerType;
      if (action === 'new') {
        controllerType = 'NewEscalationModalController';
      } else if (action === 'edit') {
        controllerType = 'EditEscalationModalController';
      }
      const modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/admin/escalations/escalationModal.html',
        controller: controllerType,
        keyboard: false,
        size: 'lg',
        backdrop: 'static',
        resolve: {
          enterprise_selection: function () {
            return $scope.entFilter;
          },
          passedEscalation: function () {
            return escalation;
          },
        },
      });
      modalInstance.result.then(function () {
        const marketPlaceId = CURRENT_USER.market_place_info.id;
        let escalationParams = {};
        if (parseInt($scope.entFilter.enterprise_id) > 0) {
          escalationParams = { ...escalationParams, enterprise_id: $scope.entFilter.enterprise_id };
        } else if (marketPlaceId) {
          escalationParams = { ...escalationParams, market_place_id: marketPlaceId };
        }
        getEscalations(escalationParams);
      });
      trackingService.trackEvent(trackingService.ADMIN, 'escalations', action);
    };

    $scope.getTotalTime = function (time) {
      return timeConversionService.convertMinutes(time);
    };

    unbindHandler = $rootScope.$on('EnterpriseSelectionChanged', function () {
      $scope.entFilter = EnterpriseFilterService.fetchSelectedEnterprise();

      const marketPlaceId = CURRENT_USER.market_place_info.id;
      let escalationParams = {};
      if (parseInt($scope.entFilter.enterprise_id) > 0) {
        escalationParams = { ...escalationParams, enterprise_id: $scope.entFilter.enterprise_id };
      } else if (marketPlaceId) {
        escalationParams = { ...escalationParams, market_place_id: marketPlaceId };
      }

      getEscalations(escalationParams);
    });

    $scope.$on('$destroy', function () {
      unbindHandler();
    });
  },
]);
