import module from '../module';
import moment from 'moment';
export default module.controller('ReplicateSessionModalController', [
  '$scope',
  '$controller',
  '$modalInstance',
  'FormatHelperService',
  'AlertService',
  'sessionManagerService',
  'enterprise_selection',
  'generalDataService',
  'passedSession',
  'locationManagerService',
  function (
    $scope,
    $controller,
    $modalInstance,
    FormatHelperService,
    AlertService,
    sessionManagerService,
    enterprise_selection,
    generalDataService,
    passedSession,
    locationManagerService
  ) {
    $controller('BaseJobModalController', {
      $scope: $scope,
      $modalInstance: $modalInstance,
      FormatHelperService: FormatHelperService,
      AlertService: AlertService,
      sessionManagerService: sessionManagerService,
      enterprise_selection: enterprise_selection,
    });

    $scope.keySession = generalDataService.getKeySession();
    $scope.title = 'Replicate ' + $scope.keySession;
    $scope.requestType = 1;
    $scope.type = 'new';

    $scope.checkForErrors = function (formData) {
      const result = [];
      // Check if session starts in the past
      let date;
      const dateAttrib = formData.find(function (attrib) {
        return attrib.name === 'date_time';
      });
      if ($scope.jobType.selection == 'escalation' && dateAttrib) {
        date = moment(dateAttrib.value);
        if (date.isBefore(moment())) {
          result.push('The time you entered is for a shift starting in the past. Please adjust to start in the future');
        }
      }

      return result;
    };

    function populateAttribs(source, target) {
      switch (source.type) {
        case 'single_list':
          target.value = source.value_list.filter(function (obj) {
            return obj.selected == true;
          })[0];
          break;
        case 'multi_list':
          target.value = source.value_list;
          target.choice_list = source.value_list;
          break;
        case 'text':
          target.value = source.value_text;
          break;
        case 'count':
          target.value = source.value_count;
          break;
        case 'location':
          if (source.value_location) {
            locationManagerService
              .getAllLocations(enterprise_selection && enterprise_selection.enterprise_id)
              .then(function (locationsObject) {
                const locations = locationsObject[enterprise_selection.enterprise_id];
                const loc = locations.find(function (location) {
                  return location.location_id === source.value_location.location_id;
                });
                target.value = loc;
                $scope.selectedLocation = loc;
              });
          }
          break;
        case 'place':
          $scope.$watch('selectedLocation', function (val) {
            if (val) {
              const selected = source.value_place.filter(function (obj) {
                return obj.selected == true;
              })[0];
              const selectedPlace = $scope.selectedLocation.place_list.find(function (place) {
                return place.place_id === selected.place_id;
              });
              target.value = selectedPlace;
            }
          });
          break;
      }
    }

    $scope.attribsLoadedCallback = function (attribs) {
      if (passedSession && passedSession.start) {
        sessionManagerService.setSession(passedSession.session_id).then(function (session) {
          attribs.forEach(function (origAttrib) {
            const loadedAttrib = session.attrib_list.find(function (attrib) {
              return attrib.attrib_id === origAttrib.attrib_id;
            });
            if (loadedAttrib) {
              populateAttribs(loadedAttrib, origAttrib);
            }
          });
        });

        const dateAttrib = attribs.find(function (attrib) {
          return attrib.attrib_id === 4; // 4 is date and time
        });
        if (dateAttrib) {
          dateAttrib.value = moment(passedSession.start);
        }
      }
    };
  },
]);
