import angular from 'angular';
import module from '../module';
import * as enums from '../../enums';

export default module.controller('ProviderModalController', [
  '$rootScope',
  '$scope',
  '$modalInstance',
  'provider',
  '$timeout',
  'AlertService',
  'providerManagerService',
  'DOMAIN',
  '$modal',
  function (
    $rootScope,
    $scope,
    $modalInstance,
    provider,
    $timeout,
    AlertService,
    providerManagerService,
    DOMAIN,
    $modal
  ) {
    $scope.submitBtnDisbaled = false;
    $scope.formData = {};
    $scope.permissionsDiv = false;
    $scope.emailForm = false;
    $scope.provider = angular.copy(provider);

    if ($scope.provider.profile_info && $scope.provider.profile_info.length) {
      $scope.profileResume = $scope.provider.profile_info.filter((p) => p.display_name === 'Resume')[0];
      $scope.profileImages = $scope.provider.profile_info.filter(
        (p) => p.type === 'image' && typeof p.data === 'string'
      );
    }

    $scope.domain = DOMAIN;

    // logged in status check for class association
    $scope.loggedInStatus = function (status_code) {
      if (status_code == 1) {
        return 'logged-in-state';
      } else if (status_code == 0) {
        return 'logged-out-state';
      } else {
        return 'no-logged-in-state';
      }
    };
    $scope.popover = {
      loggedInStatus: 'app/views/templates/avatar_loggedin_status_legend.html',
    };

    // Nonstaff verification methods
    $scope.showPermissions = function () {
      $scope.permissionsDiv = !$scope.permissionsDiv;
      if ($scope.provider.vetting_status.selected) {
        $scope.formData.newStatus = $scope.provider.vetting_status.selected;
      } else {
        $scope.formData.newStatus = null;
      }
    };
    $scope.updateStatus = function () {
      $scope.submitBtnDisbaled = true;
      const currentChoiceId = $scope.provider.vetting_status.choice_list.filter(function (choice) {
        return choice.selected == true;
      })[0].choice_id;

      if (currentChoiceId && $scope.formData.newStatus.choice_id == currentChoiceId) {
        AlertService.warningAlert('That is already the users current status');
      } else {
        const newStatusObj = {
          user_id: $scope.provider.user_id,
          profile_info: [
            {
              attrib_id: $scope.provider.vetting_status.attrib_id,
              name: $scope.provider.vetting_status.name,
              value_single_list: $scope.formData.newStatus.choice_id,
            },
          ],
        };
        providerManagerService.updateProviderAttribs(newStatusObj).then(
          function (response) {
            if (response.success) {
              AlertService.successAlert(response.message);
              providerManagerService.getProvider($scope.provider.user_id).then(function (provider) {
                $scope.provider = angular.copy(provider);
              });
            } else {
              AlertService.errorAlert(response.message);
            }
            $scope.showPermissions();
            $rootScope.$broadcast('ProviderModalController.ProviderUpdated');
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
          }
        );
      }
      $timeout(function () {
        $scope.submitBtnDisbaled = false;
      }, 300);
    };

    $scope.onProfilePictureClick = function (e) {
      $scope.onImageClick($scope.provider.profile_picture);
    };

    $scope.onImageClick = function (imageUrl) {
      const modalInstance = $modal.open({
        animation: true,
        template: `
        <div style="text-align: center; padding: 20px;">
          <div>
            <button class="close" ng-click="cancelSmallModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <img ng-src="${$scope.getProfileUrl(imageUrl)}" />
        </div> `,
        keyboard: false,
        size: 'lg',
        controller: 'providerImageModalController',
      });
    };

    $scope.getProfileUrl = function (picture) {
      if (!picture) {
        return `${enums.AssetsFolder.images}avatar.png`;
      }
      if (typeof picture === 'string') {
        return DOMAIN + picture;
      } else if (picture.data) {
        return DOMAIN + picture.data;
      }
      return `${enums.AssetsFolder.images}avatar.png`;
    };

    // Rating System for completed jobs
    $scope.ratingStates = [{ stateOn: 'glyphicon-star', stateOff: 'glyphicon-star-empty' }];

    $scope.review = {
      rate: 3,
      max: 5,
      isReadonly: true,
      customerComments: '',
      minutesLate: '',
    };

    // modal close
    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.getReviewers = function (provider) {
      if (!provider.rating_count || (provider.rating_count === 1 && provider.average_rating === 5)) {
        return 'No Reviews Yet (0 reviews)';
      }
      return `${provider.average_rating} (${provider.rating_count} review${provider.rating_count > 1 ? 's' : ''})`;
    };
  },
]);
