import module from '../module';

export default module.service('timeReportNetworkService', [
  '$http',
  'BASE_URL',
  '$q',
  function ($http, BASE_URL, $q) {
    const endPointChoices = `${BASE_URL}/order_timesheet_choices`;
    const endPointShifts = `${BASE_URL}/order_timesheet_shifts`;
    const endPointVerifyShifts = `${BASE_URL}/order_timesheet_verify_shifts`;
    const endPointUnverifyShifts = `${BASE_URL}/order_timesheet_unverify_shift`;
    const endPointRepeatOrders = `${BASE_URL}/order_timesheet_repeat_order`;
    const endpointAdminExtract = `${BASE_URL}/admin_ctm_extract`;
    const endpointSubmitBreaks = `${BASE_URL}/admin_submit_breaks`;
    const endpointModifyBreakPenalties = `${BASE_URL}/modify_break_penalties`;

    const getOrdersBase = (endpoint, data) => {
      const deferred = $q.defer();

      $http
        .post(endpoint, data)
        .then((response) => {
          if (response.data) {
            deferred.resolve(response.data);
          }
        })
        .catch((error) => {
          return deferred.reject(error);
        });

      return deferred.promise;
    };

    return {
      getOrderTimesheetChoices: (data) => {
        return getOrdersBase(endPointChoices, data);
      },
      getOrderTimesheetShifts: (data) => {
        return getOrdersBase(endPointShifts, data);
      },
      postOrderTimesheetVerifyShifts: (data) => {
        return getOrdersBase(endPointVerifyShifts, data);
      },
      postOrderTimesheetUnverifyShifts: (data) => {
        return getOrdersBase(endPointUnverifyShifts, data);
      },
      postOrderTimesheetRepeatOrders: (data) => {
        return getOrdersBase(endPointRepeatOrders, data);
      },
      adminExtract: (data) => {
        return getOrdersBase(endpointAdminExtract, data);
      },
      submitBreaks: (data) => {
        return getOrdersBase(endpointSubmitBreaks, data);
      },
      modifyBreakPenalties: (data) => {
        return getOrdersBase(endpointModifyBreakPenalties, data);
      },
    };
  },
]);
