import module from '../module';

export default module.directive('jobDirective', [
  function () {
    return {
      restrict: 'E',
      templateUrl: 'app/views/job/index.html',
      controller: 'JobController',
    };
  },
]);
