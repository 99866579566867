import { Component, OnInit } from '@angular/core';
import { AdminUserService } from '../../shared/services/api/admin-user.service';
import Swal from 'sweetalert2';
import { AppStateService } from 'app/shared/services';
import { filter, take } from 'rxjs';
import env from '../../scripts/env';

@Component({
  templateUrl: './forgot-password.page.component.html',
})
export class ForgotPasswordPageComponent implements OnInit {
  public username: string;
  public marketplaceId: number;
  public recoverEmailSent = false;
  public webUrl: string = env.WEB_URL || window.location.host;

  constructor(private adminUserService: AdminUserService, private appState: AppStateService) { }

  ngOnInit(): void {
    this.appState.initData$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((initData) => {
        const list: any[] = initData.market_place_list;
        if (list?.length) {
          this.marketplaceId = list[0].market_place_id;
        }
      });
  }

  public async recoverPassword() {
    const result = await this.adminUserService.sendForgotPasswordRequest(
      this.username,
      this.marketplaceId,
      this.webUrl
    );
    if (result.success) {
      this.recoverEmailSent = true;
    } else {
      Swal.fire({
        title: 'Error',
        text: result.message || 'There was an error submitting.',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
      });
    }
  }
}
