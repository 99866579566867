import angular from 'angular';
import module from '../module';
import Swal from 'sweetalert2';
import moment from 'moment';
import * as _ from 'lodash';

export default module.controller('ChangeVerifyModalController', [
  '$scope',
  '$timeout',
  '$modalInstance',
  '$localStorage',
  'FormatHelperService',
  'AlertService',
  'selectedSession',
  'sessionManagerService',
  'priceManagerService',
  'EnterpriseFilterService',
  'trackingService',
  'generalDataService',
  'AuthenticationService',
  'TimeFormatService',
  'PERMISSIONS',
  function (
    $scope,
    $timeout,
    $modalInstance,
    $localStorage,
    FormatHelperService,
    AlertService,
    selectedSession,
    sessionManagerService,
    priceManagerService,
    EnterpriseFilterService,
    trackingService,
    generalDataService,
    AuthenticationService,
    TimeFormatService,
    PERMISSIONS
  ) {
    $scope.keyProfession = generalDataService.getKeyProfession();
    $scope.keySession = generalDataService.getKeySession();
    $scope.keyAttrib = $localStorage.keyAttribute;
    $scope.formData = {
      autoUpdateTimeAmount: false,
    };
    const _passedSession = angular.copy(selectedSession);
    $scope.submitBtnDisbaled = false;
    $scope.title = 'Verify Job Details';
    $scope.statusSelectionList = [
      { name: 'Completed', type: 5 },
      { name: 'Cancelled', type: 7 },
    ];
    $scope.sessionStatusPicker = false;
    $scope.currencySymbol = AuthenticationService.getCurrencySymbol();
    $scope.removeBreakTimes = [];

    $scope.hasAmountPermission = false; // Amount feature is hidden on this pop up

    // status and checkin/out editable checkin
    $scope.fieldExpander = function (field) {
      if (field === 'session' && !$scope.disableStatusSelection) {
        $scope.sessionStatusPicker = !$scope.sessionStatusPicker;
      } else if (field === 'checkinout' && !$scope.disableCheckInOutSelection) {
        $scope.checkInOutPicker = !$scope.checkInOutPicker;
      }
    };

    // auto update check box enabled
    $scope.autoUpdateChecked = function (field) {
      if (field) {
        $scope.adjustedCheckInOut();
      }
    };

    // Grabbing session details from API get_verify_details
    function getVerifySessionDetails(priceParams) {
      priceManagerService
        .getAllPrices(priceParams)
        .then(
          function (pricesObj) {
            $scope.prices = angular.copy(pricesObj[$scope.entFilter.enterprise_id]);
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
          }
        )
        .then(function () {
          sessionManagerService.getVerifySession(_passedSession.request_id || _passedSession.session_id).then(
            function (response) {
              if (response.success) {
                $scope.session = angular.copy(response);
                buildFormData($scope.session);
              } else {
                AlertService.errorAlert(response.message);
                $modalInstance.dismiss('cancel');
              }
            },
            function (reason) {
              AlertService.serverRequestErrorAlert(reason);
              $modalInstance.dismiss('cancel');
            }
          );
        });
    }
    function grabEntFilter() {
      $scope.entFilter = EnterpriseFilterService.fetchSelectedEnterprise();
      if (($scope.entFilter || {}).enterprise_id) {
        const _priceParams = { enterprise_id: $scope.entFilter.enterprise_id };
        getVerifySessionDetails(_priceParams);
      }
    }
    grabEntFilter();

    // TIME and DATE display builders
    function combineDateTime(date, time) {
      // in UTC...this converst to and returns local time
      const _date = date;
      const _time = time;
      const builtDateTimeUtc = _date + ' ' + _time;
      const localDateTimeMoment = moment
        .utc(builtDateTimeUtc, TimeFormatService.format('fullDateTime'))
        .local()
        .clone();

      return localDateTimeMoment;
    }

    function buildLocalTimeObj(localDateTimeMoment) {
      const _localDateTimeMoment = moment(localDateTimeMoment).clone();

      const dateTimeObj = {
        momentObj: moment(_localDateTimeMoment).clone(),
        localDateTime: moment(_localDateTimeMoment).clone().format(TimeFormatService.format('fullDateTime')),
        displayDate: moment(_localDateTimeMoment).clone().format(TimeFormatService.format('shortMonthYear')),
        displayTime: moment(_localDateTimeMoment).clone().format('h:mma'),
      };
      return dateTimeObj;
    }

    function getDurationDiff(start, end) {
      const _timeDuration = end.diff(start, 'm');
      return _timeDuration;
    }

    // Build checkin/out form data objs
    function buildCheckInOutObjs(session) {
      if (session.checkin_date) {
        const _checkinDateTimeLocal = combineDateTime(session.checkin_date, session.checkin_time);
        $scope.formData.checkInObj = buildLocalTimeObj(_checkinDateTimeLocal);
      }

      if (session.checkout_date) {
        const _checkoutDateTimeLocal = combineDateTime(session.checkout_date, session.checkout_time);
        $scope.formData.checkOutObj = buildLocalTimeObj(_checkoutDateTimeLocal);
      }

      if ($scope.formData.checkInObj && $scope.formData.checkOutObj) {
        $scope.formData.checkInOutDiff = getDurationDiff(
          $scope.formData.checkInObj.momentObj,
          $scope.formData.checkOutObj.momentObj
        );

        if ($scope.formData.status.type !== 7 && $scope.formData.checkInOutDiff < 0) {
          $scope.checkInOutWarning = 'Check-In time cannot be after Check-Out time.';
        }
      }
    }

    // Status OBJ
    function buildStatusObj(status) {
      $scope.formData.status = $scope.statusSelectionList.filter(function (obj) {
        return obj.type == status.type;
      })[0];

      if ($scope.formData.status.type == 7) {
        // CANCELED
        $scope.disableStatusSelection = true;
        $scope.disableCheckInOutSelection = true;
      }
    }

    // TIME WORKED OBJ
    function buildTimeWorkedObj(value) {
      $scope.formData.requestTotalTime = {
        total: value,
        hours: moment.duration(value, 'minutes').hours(),
        minutes: moment.duration(value, 'minutes').minutes(),
      };

      if (moment.duration(value, 'minutes').days() > 0) {
        const _days = moment.duration($scope.session.request_total_time, 'minutes').days();
        const _hours = $scope.formData.requestTotalTime.hours;
        $scope.formData.requestTotalTime.hours = _hours + _days * 24;
      }
    }

    // Break Time OBJ
    function buildBreakTimeObj(values) {
      const breakTimes = [];
      if (values?.length > 0) {
        values.forEach((value) => {
          if (value?.actual_break_time > 0 && !value.delete) {
            breakTimes.push({
              total: value.actual_break_time,
              hours: moment.duration(value.actual_break_time, 'minutes').hours(),
              minutes: moment.duration(value.actual_break_time, 'minutes').minutes(),
              start_date: value.actual_break_start ? buildLocalTimeObj(value.actual_break_start) : undefined,
              end_date: value.actual_break_end ? buildLocalTimeObj(value.actual_break_end) : undefined,
              id: value.id,
              delete: false
            });
          }
        });
      }
      $scope.formData.breakTimes = breakTimes;
      $scope.session.break_details = angular.copy($scope.formData.breakTimes);
    }

    // Build Price OBJ
    function buildPricObj(sessionParam) {
      const session = sessionParam;
      if (session.price_structure_id) {
        if ($scope.prices && $scope.prices.length > 0) {
          const selectedPrice = $scope.prices.filter(function (obj) {
            return obj.price_structure_id == session.price_structure_id;
          })[0];

          if (selectedPrice) {
            $scope.formData.priceSelection = selectedPrice;
          } else {
            const priceObj = {
              name: session.price_structure_name,
              price_structure_id: session.price_structure_id,
            };
            $scope.prices.push(priceObj);
            $scope.formData.priceSelection = priceObj;
          }
        } else {
          const priceObj = {
            name: session.price_structure_name,
            price_structure_id: session.price_structure_id,
          };
          $scope.prices = [];
          $scope.prices.push(priceObj);
          $scope.formData.priceSelection = priceObj;
        }
      } else {
        if (!$scope.prices) {
          $scope.prices = [];
        }
      }
    }

    // Form Data Obj for data display
    function buildFormData(session) {
      $scope.formData = {
        totalCost: session.total_cost,
        verified: session.verified,
        notes: session.verify_note,
        autoUpdateTimeAmount: false,
      };
      // time worked
      buildTimeWorkedObj(session.request_total_time);
      // time worked
      buildBreakTimeObj(session.break_details);
      // status obj
      buildStatusObj(session.status);
      // Price Obj
      buildPricObj(session);
      // Reserved Block
      const _reserveDateTimeLocal = combineDateTime(session.request_date, session.request_time);
      $scope.formData.reservedObj = buildLocalTimeObj(_reserveDateTimeLocal);
      $scope.formData.reservedObj.endDisplayTime = _reserveDateTimeLocal
        .add(session.estimated_duration, 'm')
        .clone()
        .format('hh:mma');
      // buildCheckInOut block
      if (session.checkin_date) {
        buildCheckInOutObjs(session);
      }
    }

    // Calendar date time selection options and controls
    $scope.datePickerCheckIn = { opened: false };
    $scope.openDatePickerCheckIn = function () {
      $scope.datePickerCheckIn.opened = true;
    };

    $scope.datePickerCheckOut = { opened: false };
    $scope.openDatePickerCheckOut = function () {
      $scope.datePickerCheckOut.opened = true;
    };

    // Options - time selection
    $scope.hstep = 1;
    $scope.mstep = 5;

    // For building display values on checkin/out and time worked rows
    $scope.returnDurationDisplayValue = function (value) {
      const _val = sessionManagerService.returnDurationDisplayValue(value);
      return _val;
    };

    // CheckinOut CHANGE ADJUSTMENT
    function checkInOutValidity() {
      const _checkIn = ($scope.formData.checkInObj || {}).momentObj;
      const _checkOut = ($scope.formData.checkOutObj || {}).momentObj;
      if (_checkIn && _checkOut && moment(_checkIn).isBefore(_checkOut)) {
        return true;
      } else {
        return false;
      }
    }

    $scope.adjustedCheckInOut = function (field) {
      $timeout(function () {
        if (field === 'checkin') {
          $scope.formData.checkInObj = buildLocalTimeObj($scope.formData.checkInObj.momentObj);
          $scope.formData.checkInObj.touched = true;
        } else {
          $scope.formData.checkOutObj = buildLocalTimeObj($scope.formData.checkOutObj.momentObj);
          $scope.formData.checkOutObj.touched = true;
        }

        if (checkInOutValidity()) {
          delete $scope.checkInOutWarning;

          $scope.formData.checkInOutDiff = getDurationDiff(
            $scope.formData.checkInObj.momentObj,
            $scope.formData.checkOutObj.momentObj
          );

          if ($scope.formData.autoUpdateTimeAmount) {
            const _diff = angular.copy($scope.formData.checkInOutDiff);
            buildTimeWorkedObj(_diff);
          }
        } else {
          $scope.checkInOutWarning = 'Check-In time cannot be after Check-Out time.';
        }
      }, 1500);
    };

    // STATUS CHANGE ADJUSTMENT
    $scope.statusChanged = function (type) {
      // can only change when original status COMPLETED
      if (type == 7) {
        // set to canceled
        $scope.disableCheckInOutSelection = true;
        buildCheckInOutObjs($scope.session);
        delete $scope.checkInOutWarning;
      } else {
        // switched back to completed
        $scope.disableCheckInOutSelection = false;
      }
      $scope.sessionStatusPicker = false;
    };

    // TIME WORKED CHANGE ADJUSTMENT
    $scope.timeWorkedChanged = function () {
      const _hours = 60 * $scope.formData.requestTotalTime.hours;
      const _mins = $scope.formData.requestTotalTime.minutes;

      if (_mins < 60 && _mins > -1 && _hours > -1) {
        $scope.formData.requestTotalTime.total = _hours + _mins;
        delete $scope.invalidDuration;
      } else {
        $scope.invalidDuration = 'Invalid Inputs. Please ensure valid numbers';
      }
    };

    // PRICE STRUCTURE CHANGE
    $scope.priceStructureChanged = function () {
      $scope.newPriceCheck();
    };

    // AMOUNT CHANGE
    $scope.totalCosttouched = function () {
      $scope.formData.totalCostTouched = true;
    };

    // build server obj
    function buildServerObj(form, priceCheck) {
      const _formData = form;
      const _obj = {
        changes: {},
        request_id: $scope.session.request_id,
        verified: _formData.verified,
      };

      if ($scope.session.verify_note !== _formData.notes) {
        _obj.changes.verify_note = _formData.notes;
      }

      // total time worked check
      if ($scope.session.request_total_time !== _formData.requestTotalTime.total) {
        _obj.changes.request_total_time = _formData.requestTotalTime.total;
      }

      // status check
      if ($scope.session.status.type !== _formData.status.type) {
        _obj.changes.status_type = _formData.status.type;
      }

      // price structure
      if (
        _formData.priceSelection &&
        $scope.session.price_structure_id !== _formData.priceSelection.price_structure_id
      ) {
        _obj.changes.price_structure_id = _formData.priceSelection.price_structure_id;
      }

      // total cost check
      if (_formData.totalCostTouched) {
        _obj.changes.total_cost = _formData.totalCost;
      }
      // break times
      if (JSON.stringify($scope.session.break_details) !== JSON.stringify(_formData.breakTimes)) {
        _obj.changes.break_total_time = $scope.formData.breakTimes.reduce((partialSum, a) => partialSum + a.total, 0);

        _obj.changes.breaks = [];
        _formData.breakTimes.forEach((item) => {
          if (item.total > 0) {
            _obj.changes.breaks.push({
              break_end_datetime: item.end_date ? FormatHelperService.returnUtcString(item.end_date.momentObj, TimeFormatService.format('api')) : null,
              break_start_datetime: item.start_date ? FormatHelperService.returnUtcString(item.start_date.momentObj, TimeFormatService.format('api')) : null,
              break_id: item.id,
              break_time: item.total,

            });
          }
        });
        if ($scope.removeBreakTimes?.length) {
          $scope.removeBreakTimes.forEach((item) => {
            _obj.changes.breaks.push({
              break_end_datetime: item.end_date ? FormatHelperService.returnUtcString(item.end_date.momentObj, TimeFormatService.format('api')) : null,
              break_start_datetime: item.start_date ? FormatHelperService.returnUtcString(item.start_date.momentObj, TimeFormatService.format('api')) : null,
              break_id: item.id,
              break_time: item.total,
              delete: true
            });
          });
        }
        _obj.changes.check_cost = true;
      }
      // Checkin and out check
      if (_formData.status.type !== 7 && checkInOutValidity()) {
        if (_formData.checkInObj.touched) {
          _obj.changes.checkin_datetime = FormatHelperService.returnUtcString(_formData.checkInObj.momentObj, TimeFormatService.format('fullDateTime'));
        }

        if (_formData.checkOutObj.touched) {
          _obj.changes.checkout_datetime = FormatHelperService.returnUtcString(_formData.checkOutObj.momentObj, TimeFormatService.format('fullDateTime'));
        }
      } else if (_formData.status.type !== 7 && $scope.checkInOutWarning) {
        return false;
      }

      return _obj;
    }

    // check what New price would be
    $scope.newPriceCheck = function () {
      const _formCopy = angular.copy($scope.formData);

      if (_formCopy.priceSelection && _formCopy.status.type == 5 && !$scope.session.zero_pricing) {
        // completed
        const _obj = buildServerObj(_formCopy);
        _obj.changes.check_cost = true;

        const _requestList = [_obj];
        sendToServer(_requestList, true);
      }
    };

    // BREAK TIME CHANGE ADJUSTMENT
    $scope.breakTimeChanged = _.debounce((breakTime, index) => {
      const _hours = 60 * breakTime.hours;
      const _mins = breakTime.minutes;
      if (_mins < 60 && _mins > -1 && _hours > -1) {
        breakTime.total = _hours + _mins;

        if (breakTime.start_date) {
          const newEndDate = breakTime.start_date.momentObj.clone();
          newEndDate.add(breakTime.total, 'm');
          breakTime.end_date = buildLocalTimeObj(newEndDate);
        }

        $scope.formData.breakTimes[index] = breakTime;
        delete $scope.invalidDuration;
        recalculateTimeWorked();
      } else {
        $scope.invalidDuration = 'Invalid Inputs. Please ensure valid numbers';
      }
    }, 100);

    function recalculateTimeWorked() {
      delete $scope.invalidDuration;

      if (breakTimeValidation()) {
        buildTimeWorkedObj($scope.checkInBreakTimeDiff);
      }
    }

    function breakTimeValidation() {
      let totalBreakTime = 0;

      if ($scope.formData.breakTimes?.length) {
        totalBreakTime = $scope.formData.breakTimes.reduce((partialSum, a) => partialSum + a.total, 0);
      }

      $scope.checkInBreakTimeDiff = angular.copy($scope.formData.checkInOutDiff - totalBreakTime);
      if ($scope.checkInBreakTimeDiff < 0) {
        $scope.invalidDuration = 'Break Times are invalid. Please ensure valid numbers';

        return false;
      }
      return true;
    }
    $scope.addBreakSection = function () {
      delete $scope.invalidDuration;
      const newBreakTime = {
        total: 0,
        hours: 0,
        minutes: 0,
        start_date: null,
        end_date: null,
        delete: false
      };
      $scope.formData.breakTimes.push(newBreakTime);
    };

    $scope.removeBreakSession = function (breakTime, index) {
      if ($scope.formData.breakTimes?.length) {
        $scope.formData.breakTimes.splice(index, 1);
        $scope.removeBreakTimes.push(breakTime);
        recalculateTimeWorked();
      }
    };

    // modal controls
    $scope.ok = function () {
      if (!$scope.formData.notes) {
        AlertService.errorAlert(
          'Note is required',
          3000
        );
        return;
      }

      if (!breakTimeValidation()) {
        AlertService.errorAlert(
          'Break times are invalid. Please ensure valid numbers before you can submit.',
          3000
        );
        return;
      }

      $scope.submitBtnDisbaled = true;
      const _requestList = [];
      const _formCopy = angular.copy($scope.formData);
      const _builtObj = buildServerObj(_formCopy);

      if (_builtObj && $scope.formData.totalCost !== $scope.session.total_cost) {
        priceCheckWarning(_builtObj);
      } else if (_builtObj) {
        _requestList.push(_builtObj);
        sendToServer(_requestList);
      } else {
        $scope.submitBtnDisbaled = false;
      }

      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'submit');
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'cancel');
    };

    function sendToServer(_requestList, pricecheck) {
      sessionManagerService.verifyRequests(_requestList).then(
        function (response) {
          if (response.success) {
            if (pricecheck) {
              $scope.formData.totalCost = response.request_list[0].total_cost;
            } else {
              AlertService.successAlert(response.message);
              $modalInstance.close($scope.session.request_id);
            }
          } else {
            AlertService.errorAlert(response.message);
            if (!pricecheck) {
              $scope.cancel();
            }
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
        }
      );
    }

    async function priceCheckWarning(builtObj) {
      // heck if Total Cost has been changed somehow
      if ($scope.formData.totalCost !== $scope.session.price_structure_id) {
        const config = {
          title: 'Your change will result in a new price for this session of $' + $scope.formData.totalCost,
          showCancelButton: true,
          confirmButtonColor: '#159f7b',
          cancelButtonColor: '#cc514c',
          confirmButtonText: 'Confirm',
        };

        const returnHandler = function (isConfirm) {
          if (isConfirm) {
            const requestList = [];
            requestList.push(builtObj);
            sendToServer(requestList);
          } else {
            $scope.submitBtnDisbaled = false;
          } // end if confirm success
        };

        const { value: returnValue } = await Swal.fire(config);
        returnHandler(returnValue);
      } // end TOTAL COST if check
    } // end function

    // extract date display
    $scope.extractDateDisplay = function () {
      if ($scope.session) {
        const localTimeMoment = combineDateTime($scope.session.extract_date, $scope.session.extract_time);
        return localTimeMoment.format(TimeFormatService.format('fullShortMonthYear'));
      } else {
        return 'N/A';
      }
    };
  },
]);
