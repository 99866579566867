import module from './module';
export default module.filter('providerKeyAttribSearch', [
  function () {
    return function (items, textSearch, ratingSearch, keyAttribute) {
      if (!items && !textSearch && !ratingSearch) {
        return items;
      }

      return items.filter(function (provider) {
        if (textSearch) {
          const keyAttrib = provider.profile_info.filter(function (obj) {
            return obj.name === keyAttribute.data_content;
          })[0];
          if (keyAttrib && keyAttrib.display_value.toLowerCase().indexOf(textSearch.toLowerCase()) !== -1) {
            if (ratingSearch) {
              return provider.average_rating >= ratingSearch;
            }
            return true;
          } else if (provider.full_name.toLowerCase().indexOf(textSearch.toLowerCase()) !== -1) {
            if (ratingSearch) {
              return provider.average_rating >= ratingSearch;
            }
            return true;
          }
        } else if (ratingSearch) {
          return provider.average_rating >= ratingSearch;
        } else {
          return true;
        }
        return false;
      });
    };
  },
]);
