import angular from 'angular';
import module from './module';

export default module.factory('Job', [
  '$http',
  'BASE_URL',
  '$q',
  function ($http, BASE_URL, $q) {
    function Job(jobData) {
      if (jobData) {
        this.setData(jobData);
      }
    }

    Job.prototype = {
      setData: function (jobData) {
        angular.extend(this, jobData);
        // this.attrib_list = sessionData.attrib_list.sort(function(a, b){return a.order - b.order;});
        // this.serviceRequestAttribs = this.attrib_list.filter(function(attrib){return attrib.realm == 3 && attrib.display == true;});
      },
      delete: function () {
        const deferred = $q.defer();
        $http
          .post(BASE_URL + '/delete_job', { job_id: this.job_id })
          .then(function (res) {
            const response = res.data;
            deferred.resolve(response);
          })
          .catch(function () {
            deferred.reject();
          });
        return deferred.promise;
      },
    };

    return Job;
  },
]);
