import module from './module';
import * as enums from '../enums';

export default module.directive('dirJobFilter', [
  function () {
    return {
      restrict: 'E',
      templateUrl: 'app/views/templates/job_filter.html',
      controller: [
        '$scope',
        '$localStorage',
        'sessionManagerService',
        'AlertService',
        ($scope, $localStorage, sessionManagerService, AlertService) => {
          $scope.pickFilterName = 'Pick Filter';
          $scope.filterList = [];

          $scope.prepareFilters = function () {
            sessionManagerService.getRealmAttribs(enums.Realms.Service).then(
              function (response) {
                const keyAttrib = response.attrib_list.find(function (obj) {
                  return obj.name === $localStorage.keyAttribute.data_content;
                });

                $scope.filterList.push({
                  ...keyAttrib,
                  type: enums.FilterableTypes.single_list,
                });
              },
              function (reason) {
                AlertService.serverRequestErrorAlert(reason);
              }
            );

            $scope.filterList.push({
              attrib_id: 2,
              name: 'date',
              isSystem: true,
              systemType: 1,
              display_name: 'Ends After Date',
              type: enums.FilterableTypes.date,
              widget_type: 5,
              type_flags: {
                display: true,
                type: 0,
              },
            });

            $scope.filterList.push({
              attrib_id: -2,
              isSystem: true,
              systemType: 2,
              display_name: 'Job Status',
              name: 'job_status',
              type: 'single_list',
              widget_type: 3,
              type_flags: {
                display: true,
                type: 0,
              },
              choice_list: [
                { choice_id: 1, selected: false, name: 'Active' },
                { choice_id: 2, selected: false, name: 'Committed' },
                { choice_id: 3, selected: false, name: 'Pending' },
                { choice_id: 4, selected: false, name: 'Open' },
                { choice_id: 5, selected: false, name: 'Completed' },
                { choice_id: 6, selected: false, name: 'Denied' },
                { choice_id: 7, selected: false, name: 'Canceled' },
                { choice_id: 8, selected: false, name: 'Interest' },
                { choice_id: 9, selected: false, name: 'Waiting Decision' },
              ],
            });

            $scope.filterList.push({
              attrib_id: -3,
              isSystem: true,
              systemType: 2,
              display_name: 'Picklist Jobs',
              name: 'picklist',
              type: 'single_list',
              widget_type: 3,
              type_flags: {
                display: true,
                type: 0,
              },
              choice_list: [
                { choice_id: 0, selected: false, name: 'No' },
                { choice_id: 1, selected: false, name: 'Yes' },
              ],
            });

            $scope.filterList.push({
              attrib_id: -4,
              isSystem: true,
              systemType: 2,
              display_name: 'Smart Search',
              name: 'smart_search',
              type: 'text',
              widget_type: 1,
              type_flags: {
                display: true,
                type: 0,
              },
              choice_list: [
                { choice_id: 0, selected: false, name: 'No' },
                { choice_id: 1, selected: false, name: 'Yes' },
              ],
            });

            if ($localStorage.enterpriseFilter) {
              $scope.filters = JSON.parse($localStorage.enterpriseFilter);
            } else {
              $scope.filters = [];
            }
            if ($scope.filters.length) {
              $scope.pickFilterName = 'Pick Another Filter';
            }
            $scope.setFilters();
          };

          $scope.addFilter = function (attrib_id) {
            if (attrib_id && attrib_id !== 'none') {
              var filter = $scope.filterList.find(function (item) {
                return item.attrib_id == attrib_id;
              });
              if (
                !$scope.filters.find(function (item) {
                  return item.attrib_id == attrib_id;
                })
              ) {
                $scope.filters.push(filter);
              }
              $scope.selectedFilter = 'none';
              $scope.pickFilterName = 'Pick Another Filter';
            }
          };

          $scope.removeFilter = function (index) {
            $scope.filters.splice(index, 1);
            if (!$scope.filters.length) {
              $scope.pickFilterName = 'Pick Filter';
            }
            $scope.setFilters();
          };

          $scope.clearFilters = function () {
            $scope.filters = [];
            $scope.setFilters();
          };

          $scope.setFilters = function () {
            $scope.filters.forEach(function (filter) {
              if (filter.type === enums.FilterableTypes.single_list) {
                if (filter.value && filter.value.choice_id) {
                  filter.choice_list.forEach(function (choice) {
                    if (choice.choice_id === filter.value.choice_id) {
                      choice.selected = true;
                    } else {
                      choice.selected = false;
                    }
                  });
                }
              }
            });

            $localStorage.enterpriseFilter = JSON.stringify($scope.filters);
            let attribFilters = $scope.filters.map((f) => ({
              ...f,
              name: f.name,
              value: f.value,
            }));

            $scope.applyFilters(attribFilters);
          };

          $scope.keyPressed = function (e) {
            //User pressed enter, submit the filters
            if (e.keyCode === 13) {
              e.preventDefault();
              $scope.setFilters();
            }
          };

          $scope.prepareFilters();
        },
      ],
    };
  },
]);
