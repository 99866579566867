import module from '../module';
import moment from 'moment';

export default module.controller('shiftDetailsModalController', [
  '$scope',
  '$modal',
  '$modalInstance',
  'AlertService',
  'passedSession',
  'sessionManagerService',
  'EnterpriseFilterService',
  'trackingService',
  'generalDataService',
  'TimeFormatService',
  function (
    $scope,
    $modal,
    $modalInstance,
    AlertService,
    passedSession,
    sessionManagerService,
    EnterpriseFilterService,
    trackingService,
    generalDataService,
    TimeFormatService
  ) {
    $scope.keySession = generalDataService.getKeySession();
    $scope.title = $scope.keySession + ' Details';
    $scope.passedSession = passedSession;
    $scope.showSpinner = false;

    const entFilter = EnterpriseFilterService.fetchSelectedEnterprise();

    if (entFilter.enterprise_id) {
      sessionManagerService.setSession(passedSession.request_id).then(
        function (session) {
          $scope.session = session;
          if (session.cancelled_date && session.cancelled_time) {
            $scope.cancelDateTime = moment
              .utc(`${session.cancelled_date} ${session.cancelled_time}`)
              .local()
              .format(TimeFormatService.format('timeDateLong'));
          }
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
          $modalInstance.dismiss('cancel');
        }
      );
    } else {
      $modalInstance.dismiss('cancel');
    }

    $scope.ok = function () {
      $modalInstance.close();
      trackingService.trackEvent(trackingService.MODALS, $scope.title, '');
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      trackingService.trackEvent(trackingService.MODALS, $scope.title, 'cancel');
    };

    $scope.buildSubmitDate = function () {
      if ($scope.session) {
        return moment
          .utc($scope.session.submit_date + ' ' + $scope.session.submit_time)
          .local()
          .format(TimeFormatService.format('timeDateLong'));
      }
    };

    // Load Supervisor Details
    $scope.loadSupervisorDetails = function () {
      $scope.showSpinner = true;
      let supervisorSigImage = '';
      sessionManagerService
        .getSupervisorSignOffDetails({ svc_req_id: $scope.session.request_id })
        .then(function (response) {
          $scope.showSpinner = false;
          if (response?.success) {
            supervisorSigImage = response.image_attribute?.data ? response.image_attribute.data : '';
            $scope.showImageModal(null, supervisorSigImage);
          }
        });
    };

    // Image Viewer Modal
    $scope.showImageModal = function (imageUrl, base64Image) {
      const modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/job/imageViewerModal.html',
        controller: 'imageViewerModalController',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          passedImage: function () {
            return imageUrl;
          },
          base64Image: function () {
            return base64Image;
          }
        },
      });
      modalInstance.result.then(function () { });
    };
  },
]);
