import { Injectable } from '@angular/core';

import { AppStateService } from './app-state.service';
import { EnumsService } from './enums.service';
import env from '../../scripts/env';

/**
* This class provides the BrowserNotification service with methods to show notifications and ask permission to it.
*/
@Injectable()
export class BrowserNotificationService {

  private _enabled: boolean;
  private isNotificationPromiseSupported: boolean = false;

  public get permission(): string {
    return Notification.permission;
  }

  public get enabled(): boolean {
    return this._enabled;
  }
  public set enabled(v: boolean) {
    localStorage.setItem('isBrowserNotificationOn', String(v));
    this._enabled = v;
  }

  constructor(
    private appStateService: AppStateService,
    private enumsService: EnumsService
  ) {
    this.enabled = localStorage.getItem('isBrowserNotificationOn') === 'true';
    this.checkNotificationPromise();
    this.askNotificationPermission();
  }

  // function for creating the notification
  public createNotification(messageBody: string): void {
    if (!this.enabled) {
      return;
    }
    // Create and show the notification
    const initData = this.appStateService.initData;
    const img = `${env.DOMAIN}${initData?.enterprise_list[0]?.favicon}`;
    const title = initData?.enterprise_list[0]?.footer_copyright

    const notification = new Notification(title, { body: messageBody, icon: img });
  }

  public askNotificationPermission() {
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      console.log("This browser does not support notifications.");
    }
    else {
      if (
        Notification.permission === this.enumsService.NotificationPermission.denied
        || Notification.permission === this.enumsService.NotificationPermission.granted
      ) {
        return;
      }

      if (this.isNotificationPromiseSupported) {
        Notification.requestPermission()
          .then((permission) => {
            // console.log(permission)
          })
      }
      else {
        Notification.requestPermission(function (permission) {
          // console.log(permission)
        });
      }
    }
  }

  private checkNotificationPromise() {
    try {
      Notification.requestPermission().then();
      this.isNotificationPromiseSupported = true;
    }
    catch (e) {
      this.isNotificationPromiseSupported = false;
    }
  }
}
