import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { HeaderTitleService } from './header-title.service';
import { CommonSelectors } from 'app/shared/store/selectors';
import { Subject, combineLatest, takeUntil } from 'rxjs';

@Component({
  selector: 'header-title',
  templateUrl: 'header-title.component.html',
})
export class HeaderTitleComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<boolean>();

  public selectionType: string;
  public selectionData: string;

  @Input() moduleName: string;

  constructor(private headerTitleService: HeaderTitleService, private commonSelectors: CommonSelectors) {}

  ngOnInit() {
    combineLatest([this.commonSelectors.getBranchSelection(), this.commonSelectors.getEnterpriseSelection()])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([branch, enterprise]) => this.getHeaderTitle(branch, enterprise));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private getHeaderTitle(branch?: any, enterprise?: any): void {
    const { selectionData, selectionType } = this.headerTitleService.getHeaderTitle(branch, enterprise);
    this.selectionData = selectionData;
    this.selectionType = selectionType;
  }
}
