import module from '../module';
export default module.controller('AdminController', [
  '$scope',
  '$rootScope',
  '$window',
  'AuthenticationService',
  'PERMISSIONS',
  'generalDataService',
  'branchFilterService',
  'EnterpriseFilterService',
  function (
    $scope,
    $rootScope,
    $window,
    AuthenticationService,
    PERMISSIONS,
    generalDataService,
    branchFilterService,
    EnterpriseFilterService
  ) {
    const MARKETPLACE_ADMIN = 2;
    const BRANCH_ADMIN = 6;
    $scope.pageTitle = 'Admin';
    $scope.checkAdminTabPermission = function () {
      const CURRENT_USER = AuthenticationService.getCurrentUser();
      const adminTabPermission = CURRENT_USER.permissionCheck(PERMISSIONS.ADMIN_TAB);
      if (!adminTabPermission) {
        $window.location.reload();
      }
    };

    $scope.getPermissions = function () {
      const CURRENT_USER = AuthenticationService.getCurrentUser();
      const selectedBranch = branchFilterService.getSelectedBranch();
      const selectedEnterprise = EnterpriseFilterService.fetchSelectedEnterprise();
      const workingOnBranch =
        (selectedBranch &&
          selectedBranch.id !== -1 &&
          (!selectedEnterprise || selectedEnterprise.enterprise_id === -1)) ||
        (CURRENT_USER.user_type === 6 && (!selectedEnterprise || selectedEnterprise.enterprise_id === -1));

      const workingOnEnterprise = selectedEnterprise && selectedEnterprise.enterprise_id !== -1;

      $scope.tabPermissions = {
        priceTab: CURRENT_USER.permissionCheck(PERMISSIONS.PRICE_TAB) && !workingOnBranch,
        requesterTab: CURRENT_USER.permissionCheck(PERMISSIONS.REQUESTER_TAB) && !workingOnBranch,
        providerTab: CURRENT_USER.permissionCheck(PERMISSIONS.PROVIDER_TAB),
        poolsTab: CURRENT_USER.permissionCheck(PERMISSIONS.POOL_TAB) && !workingOnBranch,
        escalationsTab: CURRENT_USER.permissionCheck(PERMISSIONS.ESCALATION_TAB) && !workingOnBranch,
        // poolsTab: CURRENT_USER.permissionCheck(PERMISSIONS.POOL_TAB),
        // escalationsTab: CURRENT_USER.permissionCheck(PERMISSIONS.ESCALATION_TAB),
        meetingPlaceTab: CURRENT_USER.permissionCheck(PERMISSIONS.ADD_LOCATION) && !workingOnBranch,
        jobsTab: CURRENT_USER.permissionCheck(PERMISSIONS.JOBS) && !workingOnBranch,
        rolesTab: CURRENT_USER.permissionCheck(PERMISSIONS.ROLE_TAB) && !workingOnBranch,
        usersTab: CURRENT_USER.permissionCheck(PERMISSIONS.USER_TAB),
        enterpriseTab:
          CURRENT_USER.permissionCheck(PERMISSIONS.ENTERPRISE_TAB) &&
          (CURRENT_USER.user_type === MARKETPLACE_ADMIN || CURRENT_USER.user_type === BRANCH_ADMIN) &&
          !workingOnEnterprise,
        branchTab:
          CURRENT_USER.permissionCheck(PERMISSIONS.BRANCH_TAB) &&
          CURRENT_USER.user_type === MARKETPLACE_ADMIN &&
          !workingOnEnterprise &&
          !workingOnBranch,
        apiLogsTab:
          // CURRENT_USER.permissionCheck(PERMISSIONS.BRANCH_TAB) &&
          // CURRENT_USER.user_type === MARKETPLACE_ADMIN &&
          !workingOnEnterprise && !workingOnBranch,
        outboundApiLogsTab:
          // CURRENT_USER.permissionCheck(PERMISSIONS.BRANCH_TAB) &&
          // CURRENT_USER.user_type === MARKETPLACE_ADMIN &&
          !workingOnEnterprise && !workingOnBranch,
        communicationsTab:
          CURRENT_USER.user_type === MARKETPLACE_ADMIN &&
          CURRENT_USER.permissionCheck(PERMISSIONS.CUSTOM_COMM_LIST) &&
          !workingOnBranch &&
          !workingOnEnterprise,
      };
    };

    $scope.initialize = function () {
      $scope.keyProfession = generalDataService.getKeyProfession();
      $scope.tabs = {
        priceTab: false,
        requesterTab: false,
        providerTab: false,
        poolsTab: false,
        escalationsTab: false,
        meetingPlaceTab: false,
        jobsTab: false,
        rolesTab: false,
        usersTab: false,
        enterpriseTab: false,
        branchTab: false,
        apiLogsTab: false,
        outboundApiLogsTab: false,
        communicationsTab: false,
      };
      $scope.checkAdminTabPermission();
      $scope.getPermissions();

      $scope.$on('BranchSelectionChanged', () => {
        $scope.getPermissions();
        $scope.$broadcast('UpdateAdminTabs');
      });
      $scope.$on('EnterpriseSelectionChanged', () => $scope.getPermissions());
    };

    $scope.initialize();
  },
]);
