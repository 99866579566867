import angular from 'angular';
import module from '../module';
import Swal from 'sweetalert2';

export default module.controller('NewBranchModalController', [
  '$scope',
  '$modalInstance',
  '$modal',
  'AlertService',
  'AuthenticationService',
  'enterpriseListService',
  'branchService',
  'EnterpriseFilterService',
  function (
    $scope,
    $modalInstance,
    $modal,
    AlertService,
    AuthenticationService,
    enterpriseListService,
    branchService,
    EnterpriseFilterService
  ) {
    $scope.initialize = function () {
      $scope.CURRENT_USER = AuthenticationService.getCurrentUser();

      $scope.submitBtnDisbaled = false;
      $scope.showSpinner = true;
      $scope.type = 'Add New Branch'; // Modal Title
      $scope.btnLabel = 'Add Branch';
      $scope.sortKey = 'name';
      $scope.reverse = false;

      $scope.formData = {
        name: '',
        marketplace: $scope.CURRENT_USER.market_place_info.id,
        force_update: true,
      };

      $scope.getEnterprises();
    };

    $scope.getEnterprises = function () {
      enterpriseListService.getAllEnterprisesSimple().then(function (enterprises) {
        $scope.enterprises = angular.copy(enterprises);
        $scope.showSpinner = false;
      });
    };

    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;
      $scope.addNewBranch();
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      $scope.formData = {};
    };

    $scope.addNewBranch = async function () {
      const enterpriseList = $scope.enterprises
        .filter((x) => x.selected)
        .filter((x) => !!x.branch)
        .map((x) => x.name)
        .join(', ');
      if (enterpriseList) {
        const config = {
          title: 'Are you sure?',
          text: `Enterprise(s) ${enterpriseList} are currently under different branch(es), do you want to move them?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#cc514c',
          confirmButtonText: 'Yes, move Enterprises',
        };

        const returnHandler = function (isConfirm) {
          if (isConfirm) {
            $scope.formData.enterprises = $scope.enterprises.filter((x) => x.selected).map((x) => x.id);
            branchService.add($scope.formData).then(
              function (res) {
                $scope.enterprises
                  .filter((x) => x.selected)
                  .forEach((ent) => {
                    ent.branch_id = res.data.id;
                    EnterpriseFilterService.updateEnterpriseName(ent);
                  });
                $scope.$emit('Branches.Updated');
                AlertService.successAlert('New Branch was added.');
                $modalInstance.close();
              },
              function (reason) {
                AlertService.serverRequestErrorAlert(reason);
                $modalInstance.close();
              }
            );
          }
        };

        const { value: returnValue } = await Swal.fire(config);
        returnHandler(returnValue);
      } else {
        $scope.formData.enterprises = $scope.enterprises.filter((x) => x.selected).map((x) => x.id);
        branchService.add($scope.formData).then(
          function () {
            $scope.$emit('Branches.Updated');
            AlertService.successAlert('New Branch was added.');
            $modalInstance.close();
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
            $modalInstance.close();
          }
        );
      }
    };

    $scope.sort = function (keyname) {
      $scope.sortKey = keyname; // set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; // if true make it false and vice versa
    };

    $scope.initialize();
  },
]);
