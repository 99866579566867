import { Component, ChangeDetectionStrategy, Input, ViewEncapsulation, Inject, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { CalendarView, DAYS_OF_WEEK, CalendarDateFormatter, CalendarUtils } from 'angular-calendar';
import { MonthView } from 'calendar-utils';
import { Worker } from '../../../shared/models/worker';
import { CustomDateFormatter } from '../../../shared/utils/custom-date-formatter.provider';
import { isSameMonth } from 'ngx-bootstrap/chronos';
import { take, map, filter, tap, takeUntil } from 'rxjs/operators';
import { WorkersSelectors } from '../../store/selectors';
import { Store } from '@ngrx/store';
import { State } from '../../../store';
import { FetchEvents, SelectCalendarView } from '../../store/actions';
import { WorkerEvent } from 'app/shared/models/worker-event';
import * as moment from 'moment';

@Component({
  templateUrl: './current-work.page.html',
  styleUrls: ['./current-work.page.scss', './calendar-overrides.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-current-work-page',
  providers: [{ provide: CalendarDateFormatter, useClass: CustomDateFormatter }],
  encapsulation: ViewEncapsulation.None,
})
export class CurrentWorkPage implements OnInit, OnDestroy {
  @Input() worker: Worker;
  @Input() providerId: number;

  CalendarView = CalendarView;
  viewDate: Date = new Date();
  weekStartsOn = moment.localeData().firstDayOfWeek();

  public events$: Observable<WorkerEvent[]>;
  public view$: Observable<CalendarView>;
  public monthView: MonthView;
  private scopeDeregister = null;

  private readonly stop$ = new Subject<boolean>();

  constructor(
    private selectors: WorkersSelectors,
    private store: Store<State>,
    private utils: CalendarUtils,
    @Inject('ModalService') private modalService,
    @Inject('$rootScope') private rootScope
  ) {}

  ngOnInit() {
    this.view$ = this.selectors.getSelectedCalendarView();
    this.events$ = this.selectors.getEvents();

    this.view$
      .pipe(
        takeUntil(this.stop$),
        tap((view) =>
          this.store.dispatch(
            new FetchEvents({
              date: this.viewDate,
              view: view,
              providerId: this.providerId,
            })
          )
        )
      )
      .subscribe();

    combineLatest([this.events$, this.view$])
      .pipe(
        filter(([events, view]) => !!events),
        map(([events, view]) => {
          const result = events;
          result.sort((a: any, b: any) => {
            if (view === CalendarView.Day || CalendarView.Week) {
              return a.start.getHours() - b.start.getHours();
            } else if (view === CalendarView.Month) {
              return a.start.getDate() - b.start.getDate();
            }
          });
          return result;
        }),
        map((sorted) => sorted[0]),
        map((earliest: any) => earliest && earliest.id),
        tap((id) => {
          setTimeout(() => {
            let element = document.getElementById(id);
            // Default to 8am if there are no events
            if (!element) {
              element = document.querySelectorAll('.cal-hour')[8] as HTMLElement;
            }
            if (element) {
              const yOffset = -125;
              const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
              window.scrollTo({ top: y, behavior: 'smooth' });
            }
          });
        }),
        takeUntil(this.stop$)
      )
      .subscribe();

    this.monthView = this.utils.getMonthView({
      events: [],
      viewDate: this.viewDate,
      weekStartsOn: this.weekStartsOn,
      excluded: [],
      weekendDays: [],
    });

    this.scopeDeregister = this.rootScope.$on('NOTIFICATION_UPDATE', (e, args) => {
      this.view$
        .pipe(
          take(1),
          tap((view) =>
            this.store.dispatch(
              new FetchEvents({
                date: this.viewDate,
                view: view,
                providerId: this.providerId,
              })
            )
          )
        )
        .subscribe();
    });
  }

  ngOnDestroy() {
    this.stop$.next(true);
    this.stop$.complete();

    if (this.scopeDeregister) {
      this.scopeDeregister();
    }
  }

  public dateChange() {
    this.view$
      .pipe(
        take(1),
        tap((view) =>
          this.store.dispatch(
            new FetchEvents({
              date: this.viewDate,
              view: view,
              providerId: this.providerId,
            })
          )
        )
      )
      .subscribe();

    this.monthView = this.utils.getMonthView({
      events: [],
      viewDate: this.viewDate,
      weekStartsOn: this.weekStartsOn,
      excluded: [],
      weekendDays: [],
    });
  }

  public eventClicked(event: any, e?: any) {
    e && e.stopPropagation();
    this.modalService.open({
      animation: true,
      templateUrl: 'app/views/schedule/actionsModal.html',
      controller: 'SessionDetailsModalController',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        passedSession: function () {
          return event;
        },
        passedJob: function () {
          return null;
        },
      },
    });
  }

  public setView(view: CalendarView) {
    this.store.dispatch(new SelectCalendarView(view));
  }

  public isInactive(day: Date) {
    return !isSameMonth(this.viewDate, day);
  }

  public dayClicked(day: Date) {
    this.viewDate = day;
    this.store.dispatch(new SelectCalendarView(CalendarView.Week));
  }

  public changeDay(day: Date) {
    this.viewDate = day;
    this.store.dispatch(new SelectCalendarView(CalendarView.Day));
  }

  public calculateWeekTotal(weekNo: number, events: WorkerEvent[]) {
    let weekStart = moment(this.viewDate).startOf('month').add(weekNo, 'day').startOf('week').format();
    if (moment(weekStart).isBefore(moment(this.viewDate).startOf('month').format())) {
      weekStart = moment(this.viewDate).startOf('month').format();
    }

    let weekEnd = moment(weekStart).endOf('week').format();
    if (moment(weekEnd).isAfter(moment(weekStart).endOf('month').format())) {
      weekEnd = moment(weekStart).endOf('month').format();
    }

    const totalMinutes = events
      .filter((x) => moment(x.start).isBetween(weekStart, weekEnd))
      .filter((x) => this.ignoredStates.indexOf(x.status.name) === -1)
      .map((x) => x.duration)
      .reduce((a, b) => a + b, 0);

    const hours = Math.floor(totalMinutes / 60);
    if (hours === 1) {
      return `${hours} hr`;
    }
    return `${hours} hrs`;
  }

  public calculateDayTotal(day: Date, events: WorkerEvent[]) {
    const totalMinutes = events
      .filter((x) => this.isSameDay(day, x.start))
      .filter((x) => this.ignoredStates.indexOf(x.status.name) === -1)
      .map((x) => x.duration)
      .reduce((a, b) => a + b, 0);

    const hours = Math.floor(totalMinutes / 60);
    if (hours === 1) {
      return `${hours} hr`;
    }
    return `${hours} hrs`;
  }

  private ignoredStates = ['Canceled', 'Open', 'Pending', 'Interest', 'Waiting Decision'];

  private isSameDay(d1: Date, d2: Date) {
    return d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();
  }
}
