import module from './module';

export default module.controller('AnalyticsController', [
  '$window',
  '$scope',
  'AuthenticationService',
  'PERMISSIONS',
  function (
    $window,
    $scope,
    AuthenticationService,
    PERMISSIONS
  ) {
    $scope.checkTabPermission = function () {
      const CURRENT_USER = AuthenticationService.getCurrentUser();
      const analyticsTabPermission = CURRENT_USER.permissionCheck(PERMISSIONS.NEW_ANALYTICS_TAB);

      if (!analyticsTabPermission) {
        $window.location.reload();
      }
    };

    $scope.checkTabPermission();
  }
]);
