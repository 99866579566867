import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SecureHttpService } from './secure.http.service';

@Injectable({
  providedIn: 'root',
})
export class BlacklistService {
  constructor(private httpService: SecureHttpService) {}

  getWorkerBlacklist(user_id: number): Observable<{ id: string; name: string }[]> {
    return this.httpService
      .post(`get_worker_enterprise_blacklist`, { user_id })
      .pipe(map((result) => result.blacklisted_enterprises_list));
  }

  updateWorkerBlacklist(
    user_id: number,
    enterprise_blacklist: number[]
  ): Observable<{ success: boolean; message: string }> {
    return this.httpService.post(`update_user_enterprise_blacklist`, { user_id, enterprise_blacklist });
  }

  getEnterpriseBlacklist(enterpriseId: number): Observable<{ id: number; first_name: string; last_name: string }[]> {
    return this.httpService
      .post(`get_enterprise_worker_blacklist`, { enterprise_id: enterpriseId })
      .pipe(map((result) => result.blacklisted_workers_list));
  }

  updateEnterpriseBlacklist(
    enterprise_id: number,
    worker_blacklist: number[]
  ): Observable<{ success: boolean; message: string }> {
    return this.httpService.post(`update_enterprise_worker_blacklist`, { enterprise_id, worker_blacklist });
  }
}
