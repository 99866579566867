import module from './module';
import * as enums from '../enums';

export default module.service('generalDataService', [
  '$localStorage',
  '$http',
  'BASE_URL',
  '$q',
  'sessionManagerService',
  function ($localStorage, $http, BASE_URL, $q, sessionManagerService) {
    let keyAttributeCachedName;

    return {
      getKeyProfession: function () {
        return (
          $localStorage.generalData &&
          $localStorage.generalData.filter(function (obj) {
            return obj.data_name === 'key_profession';
          })[0]
        );
      },
      getKeySession: function () {
        const keySession =
          $localStorage.generalData &&
          $localStorage.generalData.filter(function (obj) {
            return obj.data_name === 'key_session';
          });
        if (keySession && keySession.length) {
          return keySession[0].data_content;
        }
        return 'Session';
      },
      getKeyAttribute: function () {
        return $localStorage.keyAttribute;
      },
      getKeyAttributeAsync: function () {
        const deferred = $q.defer();
        if (keyAttributeCachedName) {
          deferred.resolve(keyAttributeCachedName);
        } else {
          const name = $localStorage.keyAttribute.data_content;
          sessionManagerService
            .getRealmAttribs(enums.Realms.Service)
            .then(function (res) {
              const response = res.data;
              if (response.success) {
                const attrib = response.attrib_list.find((a) => a.name === name);
                if (attrib) {
                  keyAttributeCachedName = attrib.display_name;
                  return deferred.resolve(attrib.display_name);
                }
              }
            })
            .catch(function () {
              deferred.reject();
            });
        }
        return deferred.promise;
      },
      getMyRequest: function (payload) {
        const deferred = $q.defer();
        $http
          .post(BASE_URL + '/get_my_requests', payload)
          .then(function (res) {
            const response = res.data;
            if (response.success) {
              deferred.resolve(response.request_list);
            } else {
              deferred.reject(response.message);
            }
          })
          .catch(function () {
            deferred.reject();
          });
        return deferred.promise;
      },
    };
  },
]);
